// import { mapKeys } from "lodash";
import { types } from "../actionTypes";

const initialState = {
  lastSyncTime: "",
  langDetail: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SYNC_SUCCESS:
      return { ...state, lastSyncTime: action.payload.data.lastSyncTime };
    case types.FETCH_LAST_SYNC_TIME_SUCCESS:
      return { ...state, lastSyncTime: action.payload.data };
    case types.FETCH_LANGUAGE:
      return { ...state, langDetail: action.payload?.data };
    default:
      state = { ...state };
      break;
  }

  return state;
};
