import * as moment from "moment";

let timeout;
export const debouncing = (funcToExecute = () => {}) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  timeout = setTimeout(() => {
    funcToExecute();
  }, 600);
};

export const toPascalCase = (str) => {
  if (typeof str !== "string" || str?.trim() === "") {
    return;
  }

  return str.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, function(match) {
    return match.toUpperCase();
  }).replace(/\s+/g, '  ')
};

export const toUpperCaseWihSpace = (str) => {
  if (typeof str !== "string" || str?.trim() === "") {
    return;
  }

  return str
  .split('_')
  .map(word => word.toUpperCase())
  .join(' ');
};
export const timeDiff = (startDate, endDate) => {
  // console.log(startDate,endDate)
  const date1 = moment(startDate);
  const date2 = moment(endDate);
  if (date1 && date2) {
    const diffDuration = moment.duration(date2.diff(date1));
    const days = diffDuration.days();
    const hours = diffDuration.hours();
    const minutes = diffDuration.minutes();
    const seconds = diffDuration.seconds();
    // return `Lead Time: ${days}days ${hours}hr ${minutes}min ${seconds}sec`;
    return `${days}days ${hours}hr ${minutes}min`;
  }
  return `${0}days ${0}hr ${0}min`;
};
