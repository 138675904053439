import React, { Component } from "react";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { NotificationManager } from "react-notifications";


// action
import { verifyOtp, sendOtp, sendNewPassword } from "../../store/actions";

// import images
// import logo from "../../assets/images/common-logo.png";

import ForgetPasswordLayout from "./CommonComponents/ForgetPasswordLayout";

// const logo = require("../../assets/images/" + process.env.REACT_APP_LOGO_IMAGE);
class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      otp: "",
      error: "",
      otpEnable: false,
      passwordFields: false,
      btndisable: false,
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  componentDidMount = () => {
    const isLogin = localStorage.getItem("authUser");
    if (isLogin) this.props.history.push("/");
  };

  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.setState({ btndisable: true });
    let data = { phone: values.phone };
    this.props.sendOtp(data, (res) => {
      // console.log("handlevalidsubmit", res);
      if (res.status === false) {
        NotificationManager.error(res["message"], "Not Found");
        this.setState({ error: res["message"], btndisable: false });
      } else {
        NotificationManager.success(res["message"], "OTP sent");
        this.setState({
          otpEnable: true,
          phone: values.phone,
          error: "",
          btndisable: false,
        });
      }
    });
  }

  handleOtpSubmit = (event, values) => {
    this.setState({ btndisable: true, error: "" });
    let data = {
      phone: this.state.phone,
      OTP: values.otp,
    };
    this.props.verifyOtp(data, (res) => {
      // console.log("handleOTPsubmit", res);
      if (res.status === false) {
        this.setState({ error: "Invalid OTP!", btndisable: false });
      } else {
        this.setState({ passwordFields: true, error: "", btndisable: false });
        NotificationManager.success(res["message"], "OTP verified");
      }
    });
  };

  handlePasswordSubmit = (event, values) => {
    // console.log("values", values);
    this.setState({ btndisable: true });
    if (values.password !== values.cpassword) {
      this.setState({ error: "Passwords should be same", btndisable: false });
    } else {
      let data = {
        password: values.password,
        phone: this.state.phone,
      };
      this.props.sendNewPassword(data, (res) => {
        // console.log("handlepasswdSubmit", res);
        if (res.status === true) {
          NotificationManager.success(res["message"], "Success");
          this.props.history.push("/login");
        } else {
          NotificationManager.error(res["message"], "Error");
          this.setState({ error: res["message"], btndisable: false });
        }
      });
    }
  };

  handleBack = () => {
    this.setState({ otpEnable: false });
  };

  render() {
    return (
      <React.Fragment>
        <ForgetPasswordLayout
          State={this.state}
          handleValidSubmit={this.handleValidSubmit}
          handleOtpSubmit={this.handleOtpSubmit}
          handlePasswordSubmit={this.handlePasswordSubmit}
          handleBack={this.handleBack}
        />
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { message, forgetError, loading } = state.Forget;
  return { message, forgetError, loading };
};

export default withRouter(
  connect(mapStatetoProps, { verifyOtp, sendOtp, sendNewPassword })(
    ForgetPasswordPage
  )
);
