export const types = {
  CREATE_LOCATIONS:"CREATE_LOCATIONS",
  CREATE_LOCATIONS_SUCCESS:"CREATE_LOCATIONS_SUCCESS",
  CREATE_LOCATIONS_VIA_EXCEL:"CREATE_LOCATIONS_VIA_EXCEL",
  CREATE_LOCATIONS_VIA_EXCEL_SUCCESS:"CREATE_LOCATIONS_VIA_EXCEL_SUCCESS",
  GET_LOCATIONS:"GET_LOCATIONS",
  GET_LOCATIONS_SUCCESS:"GET_LOCATIONS_SUCCESS",
  GET_LOCATIONS_EXCEL:"GET_LOCATIONS_EXCEL",
  GET_LOCATIONS_SUCCESS_EXCEL:"GET_LOCATIONS_SUCCESS_EXCEL",
  UPDATE_LOCATION:"UPDATE_LOCATION",
  UPDATE_LOCATION_SUCCESS:"UPDATE_LOCATION_SUCCESS",
  DELETE_LOCATION:"DELETE_LOCATION",
  DELETE_LOCATION_SUCCESS:"DELETE_LOCATION_SUCCESS"
    
}