import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import IndentReducer from './reducer';
const Indent = combineReducers({
  indents: IndentReducer,
  form: formReducer
});


export default Indent;
