import axios from "axios";
import { getHeaders } from "./global_req_helper";
import { NotificationManager } from "react-notifications";

const instance = axios.create();
// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser");
  if (user) return { user: JSON.parse(user) };
  return null;
};
const getUserToken = () => {
  let token = localStorage.getItem("token");
  token = token || null;
  return token;
};
//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser().user !== null;
};

// Register Method
const postRegister = (url, data) => {
  return instance
    .post(url, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postLogin = (url, data) => {
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors", // or without this line
    redirect: "follow",
    body: JSON.stringify(data),
  };

  return fetch(url, parameters)
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      throw response;
    })
    .catch((err) => {
      var message =
        "Sorry! something went wrong, please contact our support team";
      throw message;
    });
};

const getUser = (url) => {
  let headers = getHeaders();
  return fetch(url + "/", { headers })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    });
};

// postForgetPwd
const postForgetPwd = (url, values) => {
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify(values),
  };

  return fetch(url, parameters)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    });
};

const patchForgetPwd = (url, values) => {
  const parameters = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify(values),
  };

  return fetch(url, parameters)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    });
};

const getApi = (url) => {
  let headers = getHeaders();
  return fetch(url, { headers })
    .then((response) => {
      const result = tokenValidator(response);
      // if (result) return response.json();
      return result;
    })
    .then((json) => {
      return json;
    });
};
const getApiNoToken = (url) => {
  return fetch(url)
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      return json;
    });
};

const postApi = (url, values) => {
  let headers = getHeaders();

  const parameters = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(values),
  };

  return fetch(url, parameters)
    .then((response) => {
      const result = tokenValidator(response);
      // if (result) return response.json();
      return result;
    })
    .then((json) => {
      return json;
    });
};

const postUploadApi = (url, values) => {
  let headers = getHeaders();
  delete headers["Content-Type"];
  const parameters = {
    method: "POST",
    headers: headers,
    body: values,
  };
  return fetch(url, parameters).then((response) => {
    const result = tokenValidator(response);
    // if (result) return response.json();
    return result;
  });
};

const patchApi = (url, values) => {
  let headers = getHeaders();

  const parameters = {
    method: "PATCH",
    headers: headers,
    //  mode: "cors", // or without this line
    redirect: "follow",
    body: JSON.stringify(values),
  };
  return fetch(url, parameters)
    .then((response) => {
      const result = tokenValidator(response);
      // if (result) return response.json();
      return result;
    })
    .then((json) => {
      return json;
    });
};
const putApi = (url, values) => {
  let headers = getHeaders();

  const parameters = {
    method: "PUT",
    headers: headers,
    //  mode: "cors", // or without this line
    redirect: "follow",
    body: JSON.stringify(values),
  };

  return fetch(url, parameters)
    .then((response) => {
      const result = tokenValidator(response);
      // if (result) return response.json();
      return result;
    })
    .then((json) => {
      return json;
    });
};

const putUploadApi = (url, values, headers) => {
  const parameters = {
    method: "PUT",
    headers: headers,
    body: values,
  };

  return fetch(url, parameters).then((response) => {
    if (response.status === 200) {
      return response;
    }
    throw response;
  });
};

const deleteApi = (url) => {
  let headers = getHeaders();

  const parameters = {
    method: "DELETE",
    headers: headers,
  };

  return fetch(url, parameters)
    .then((response) => {
      const result = tokenValidator(response);
      // if (result) return response.json();
      return result;
    })
    .then((json) => {
      return json;
    });
};

const deleteApiPayload = (url ,values) => {
  let headers = getHeaders();
  const parameters = {
    method: "DELETE",
    headers: headers,
    body: JSON.stringify(values),
  };
  return fetch(url, parameters)
    .then((response) => {
      const result = tokenValidator(response);
      // if (result) return response.json();
      return result;
    })
    .then((json) => {
      return json;
    });
};
const uploadExcel = (url, values) => {
  let headers = getHeaders();
  delete headers["Content-Type"];
  const parameters = {
    method: "POST",
    headers: headers,
    body: values,
  };
  return fetch(url, parameters).then((response) => {
    const result = tokenValidator(response);
    // if (result) return response.json();
    return result;
  });
};

const tokenValidator = async (response) => {
  let responseJson = await response.json();
  if (
    responseJson.statusCode === 404 &&
    responseJson.message === "Unauthorized"
  ) {
    NotificationManager.error(
      "Sorry Token have been expired you have login again",
      "Error"
    );
    let newTokenResponse = await getNewToken();
    // localStorage.removeItem("token");
    if (newTokenResponse.success === true) {
      localStorage.setItem("token", newTokenResponse.data.token);
      localStorage.setItem("refreshToken", newTokenResponse.data.refreshToken);
    }
    NotificationManager.success(
      "Renewed Token. Please repeat the previos operation",
      "Success"
    );
    // localStorage.removeItem("authUser");
    // window.location.href = "/login";
  } else if (response.status === 401 && response.statusText === "Forbidden") {
    window.location.href = "/pages-401";
  } else return responseJson;
};

const getNewToken = async () => {
  let refreshToken = localStorage.getItem("refreshToken");
  let uri =
    process.env.REACT_APP_SERVERURL + `/auth/getAccessTokenByRefreshToken`;
  let refreshTokenPayload = {
    refreshToken: refreshToken,
  };
  let response = await postApi(uri, refreshTokenPayload);
  return response;
};

const postADLogin = (url, token) => {
  const parameters = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    },
    mode: "cors", // or without this line
    redirect: "follow",
  };

  return fetch(url, parameters)
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      throw response;
    })
    .catch((err) => {
      var message =
        err + "Sorry! something went wrong, please contact our support team";
      throw message;
    });
};
const putUploadImagesApi = (url, values, headers) => {
  const parameters = {
    method: "PUT",
    headers: headers,
    //  mode: "cors", // or without this line
    // redirect: "follow",
    body: values,
  };

  return fetch(url, parameters)
    .then((response) => {
      // const result = tokenValidator(response);
      // return result;
      return response;
    })
    .then((json) => {
      return json;
    });
};
export {
  getLoggedInUser,
  isUserAuthenticated,
  postRegister,
  postLogin,
  postForgetPwd,
  patchForgetPwd,
  getUser,
  getUserToken,
  getApi,
  getApiNoToken,
  postApi,
  patchApi,
  putApi,
  putUploadApi,
  deleteApi,
  deleteApiPayload,
  uploadExcel,
  postUploadApi,
  postADLogin,
  putUploadImagesApi,
};
