/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH";
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME";
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE";

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME";

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR";
export const HIDE_RIGHT_SIDEBAR = "HIDE_RIGHT_SIDEBAR";

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER";
export const types = {
  //new
  DATE_CAHNGE: "DATE_CHANGE",
  CLEAR_AGREEMENT_VALUES: "CLEAR_AGREEMENT_VALUES",
  //actual
  STORE_AGREEMENT: "STORE_AGREEMENT",
  STORE_AGREEMENT_SUCCESS: "STORE_AGREEMENT_SUCCESS",
  CREATE_AGREEMENT: "CREATE_AGREEMENT",
  CREATE_AGREEMENT_SUCCESS: "CREATE_AGREEMENT_SUCCESS",
  FETCH_LAST_AGREEMENT_VALUES: "FETCH_LAST_AGREEMENT_VALUES",
  FETCH_LAST_AGREEMENT_VALUES_SUCCESS: "FETCH_LAST_AGREEMENT_VALUES_SUCCESS",
  VIEW_AGREEMENT: "VIEW_AGREEMENT",
  VIEW_AGREEMENT_SUCCESS: "VIEW_AGREEMENT_SUCCESS",
  FETCH_AGREEMENTS_SUCCESS: "FETCH_AGREEMENTS_SUCCESS",
  FETCH_AGREEMENTS: "FETCH_AGREEMENTS",
  FETCH_SIGNED_URL: "FETCH_SIGNED_URL",
  FETCH_SIGNED_URL_SUCCESS: "FETCH_SIGNED_URL_SUCCESS",
  UPLOAD_AGREEMENT: "UPLOAD_AGREEMENT",
  UPLOAD_AGREEMENT_SUCCESS: "UPLOAD_AGREEMENT_SUCCESS",
  UPLOAD_IMAGES_AWS: "UPLOAD_IMAGES_AWS",
  UPLOAD_IMAGES_AWS_SUCCESS: "UPLOAD_IMAGES_AWS_SUCCESS",
  FETCH_INVOICES: "FETCH_INVOICES",
  FETCH_INVOICES_SUCCESS: "FETCH_INVOICES_SUCCESS",
  FETCH_INVOICES_EXCEL: "FETCH_INVOICES_EXCEL",
  FETCH_INVOICES_EXCEL_SUCCESS: "FETCH_INVOICES_EXCEL_SUCCESS",
  CLEAR_LOADED_VALUES: "CLEAR_LOADED_VALUES",
  FETCH_BILLS: "FETCH_BILLS",
  FETCH_BILLS_SUCCESS: "FETCH_BILLS_SUCCESS",
  ACCEPT_INVOICE: "ACCEPT_INVOICE",
  ACCEPT_INVOICE_SUCCESS: "ACCEPT_INVOICE_SUCCESS",

  APPROVE_INVOICE: "APPROVE_INVOICE",
  APPROVE_INVOICE_SUCCESS: "APPROVE_INVOICE_SUCCESS",
  ADD_NEW_BILL: "ADD_NEW_BILL",
  ADD_NEW_BILL_SUCCESS: "ADD_NEW_BILL_SUCCESS",

  REJECT_INVOICE: "REJECT_INVOICE",
  REJECT_INVOICE_SUCCESS: "REJECT_INVOICE_SUCCESS",
  CREATE_INVOICE: "CREATE_INVOICE",
  CREATE_INVOICE_SUCCESS: "CREATE_INVOICE_SUCCESS",
  FETCH_INVOICE_BYID: "FETCH_INVOICE_BYID",
  FETCH_INVOICE_BYID_SUCCESS: "FETCH_INVOICE_BYID_SUCCESS",
  UPDATE_BILL: "UPDATE_BILL",
  UPDATE_BILL_SUCCESS: "UPDATE_BILL_SUCCESS",
  COPY_AGREEMENT_LIST: "COPY_AGREEMENT_LIST",
  CLEAR_BILLS: "CLEAR_BILLS",
  UPDATE_INVOICE: "UPDATE_INVOICE",
  UPDATE_INVOICE_SUCCESS: "UPDATE_INVOICE_SUCCESS",
  // invoice bill delete
  INVOICE_BILL_DELETE: "INVOICE_BILL_DELETE",
  INVOICE_BILL_DELETE_SUCCESS: "INVOICE_BILL_DELETE_SUCCESS",
  // invoice bills for transpoter
  CREATE_INVOICE_BILLS: "CREATE_INVOICE_BILLS",
  CREATE_INVOICE_BILLS_SUCCESS: "CREATE_INVOICE_BILLS_SUCCESS",
  SUBMIT_TO_RAISE_INVOICE: "SUBMIT_TO_RAISE_INVOICE",
  SUBMIT_TO_RAISE_INVOICE_SUCCESS: "SUBMIT_TO_RAISE_INVOICE_SUCCESS",
};
