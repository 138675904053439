import React, { Component } from "react";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { restrict } from "../restrict";
import { Row, Col, Button } from "reactstrap";
import { PERMS } from "../../enum/perms.enum";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import axios from "axios";
import { NotificationManager } from "react-notifications";

import {
  MDBBtn,
  MDBCollapse,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import moment from "moment";

//components
import QuickFilters from "./QuickFilter";
import Index from "../../components/Common/DataTableNeo/Index";
// files
import { HeaderData, searchBar, getLocalStorage } from "./RowHeader.js";
// actions
import {
  requestLogbookReport,
  createLogbookS3,
  getLogbookDataS3Data,
} from "../../store/baxter_logbook/actions";
import {
  fetchLogbookPresignedUrl,
  uploadImagesAws1,
} from "../../store/baxter-uploadModule/actions";
import { reverse } from "lodash";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var darkTheme;
var searchValues = {};
class LogBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      offset: 0,
      excelUrl: "",
      open: false,
      searchFilters: "",
    };
  }
  handleModelOpen = () => {
    this.setState({
      open: true,
    });
  };
  handleModelClose = () => {
    this.setState({
      open: false,
    });
  };

  componentDidMount = () => {
    offset = 0;
    this.setState({ loading: true });
    this.getAllData();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }
  getAllData = () => {
    this.setState({ loading: true }, () => {});

    this.props.getLogbookDataS3Data(
      {
        offset: offset,
      },
      () => {
        this.setState({ loading: false });
      }
    );
  };
  handleChangePage = (event, val) => {
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    this.setState({ loading: true });
    this.getAllData();
  };
  downloadExcelUrlData = async (url) => {
    this.setState({ loading: true });
    const response = await axios.default.get(url, {
      responseType: "arraybuffer",
    });
    let workBook, jsonData, rows;
    workBook = XLSX.read(response?.data, {
      type: "binary",
      cellDates: true,
    });
    jsonData = workBook.SheetNames.reduce((initial, name) => {
      const sheet = workBook.Sheets[name];
      initial[name] = XLSX.utils.sheet_to_json(sheet);

      return initial;
    }, {});
    const dataString = await JSON.stringify(jsonData);
    rows = JSON.parse(dataString);
    // console.log("rows", rows);
    // const workbook = XLSX.read(response.data, { type: "buffer" });
    // const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    // const rows = XLSX.utils.sheet_to_json(worksheet);
    let reportsExportRowsData = [];
    console.log("rows", rows);
    if (rows) {
      rows.data.forEach((ele, i) => {
        reportsExportRowsData.push({
          Month: ele?.month,
          Location: ele?.location,
          "Type of Transaction": ele?.transactionType,
          "Movement Type": ele?.movementType,
          "Business Division Manual": ele?.businessDivisionManual,
          "Business Division System": ele?.businessDivisionSystem,
          "Date of Order/Stk Transfer Advice": ele?.orderDate
            ? moment(ele?.orderDate).format("DD-MMM-YYYY")
            : "",
          "Order Time": ele?.orderTime,
          "Customer PO Number": ele?.customerPONumber,
          "Customer PO Date": ele?.customerPODate
            ? moment(ele?.customerPODate).format("DD-MMM-YYYY")
            : "",
          "JDE Order Type": ele?.JDEOrderType,
          "JDE Order Number": ele?.JDEOrderNumber,
          "JDE Order Date": ele?.JDEOrderDate
            ? moment(ele?.JDEOrderDate).format("DD-MMM-YYYY")
            : "",
          "Distributor Code": ele?.distributorCode,
          "Type of customer": ele?.customerType,
          "Distributor/Customer/CFA/Patient Name": ele?.distributerName,
          "Inv. No.": ele?.invoiceNo,
          "Invoice/ST Date": ele?.invoiceDate
            ? moment(ele?.invoiceDate).format("DD-MMM-YYYY")
            : "",
          "Invoice value (Rs)": ele?.invoiceValue,
          "Ship To Location": ele?.shipToLocation,
          "Ship To State": ele?.shipToState,
          "Distance (Kms)": ele?.distanceinKM,
          "Agreed Transit Time": ele?.agreedTransitTime,
          "Status of  Despatch": ele?.dispatchStatus,
          "Total Bottles / Bags (Units)": ele?.totalBottlesOrBags,
          "Total Cases": ele?.totalCases,
          "Tonnage in Kgs": ele?.tonnageInKG,
          "Wt/ Case": ele?.weightPerCase,
          "Mode of despatch": ele?.dispatchMode,
          "Transporter/ Courier Name": ele?.transporterName,
          "Vehicle Type": ele?.vehicleType,
          "Vehicle Number": ele?.vehicleNumber,
          "LR / GR / Way Bill / Docket No": ele?.lrNo,
          "LR/GR/Docket Date": ele?.lrDate
            ? moment(ele?.lrDate).format("DD-MMM-YYYY")
            : "",
          "Basic Freight (Rs)  (A)": ele?.basicFreightCharges,
          "Loading Charges (If applicable)  (B)": ele?.loadingCharges,
          "Unloading Charges (If applicable)  ( C ) ": ele?.unLoadingCharges,
          "Detention, if Any [D]": ele?.detentionCharges,
          "Total Freight (T= A+B+C+D) ": ele?.totalFreight,
          "Expected Delivery Date": ele?.expectedDeliveryDate
            ? moment(ele?.expectedDeliveryDate).format("DD-MMM-YYYY")
            : "",
          "Delivery Date/POD": ele?.deliveryDate
            ? moment(ele?.deliveryDate).format("DD-MMM-YYYY")
            : "",
          "Freight / Bottle / Bag": ele?.freightOrBottleOrBag,
          "% of Freight on Inv value": ele?.percentageOfFreightOnInvoiceValue,
          "Freight Per KG": ele?.freightPerKg,
          "Loading Charges Case": ele?.loadingChargesCase,
          "Unloading Charges Case": ele?.unLoadingChargesCase,
          "Order Process in days": ele?.orderProcessInDays,
          "Order Service in days": ele?.orderServiceInDays,
          "Dispatch Service in days": ele?.dispatchServiceInDays,
          "Delivery Service in days": ele?.deliveryServiceInDays,
          "Total Serivce in days": ele?.totalServiceInDays,
          "Order Process TAT": ele?.orderProcessTAT,
          "Order to Invoice TAT": ele?.orderToInvoiceTAT,
          "Invoice to Dispatch TAT": ele?.invoiceToDispatchTAT,
          "Dispatch to Delivery TAT": ele?.dispatchToDeliveryTAT,
          "Reason for Delay Order Process in days":
            ele?.reasonForDelayOrderProcessInDays,
          "Reason for Delay Order Service in days":
            ele?.reasonForDelayOrderServiceInDays,
          "Reason for Delay Dispatch Service in days":
            ele?.reasonForDelayDispatchServiceInDays,
          "Reason for Delay Delivery Service in days":
            ele?.reasonForDelayDeliveryServiceInDays,
          "Remarks, If Any": ele?.remarks,
          // otherAnalysis: ele?.otherAnalysis,
        });
      });
    }
    if (rows?.data?.length > 0) {
      this.exportToCSVDownload(
        [...reportsExportRowsData],
        "LOGBOOK_DATA_EXCEL"
      );
    } else {
      let reportsExportRowsData = [
        {
          Month: "",
          Location: "",
          "Type of Transaction": "",
          "Movement Type": "",
          "Business Division Manual": "",
          "Business Division System": "",
          "Date of Order/Stk Transfer Advice": "",
          "Order Time": "",
          "Customer PO Number": "",
          "Customer PO Date": "",
          "JDE Order Type": "",
          "JDE Order Number": "",
          "JDE Order Date": "",
          "Distributor Code": "",
          "Type of customer": "",
          "Distributor/Customer/CFA/Patient Name": "",
          "Inv. No.": "",
          "Invoice/ST Date": "",
          "Invoice value (Rs)": "",
          "Ship To Location": "",
          "Ship To State": "",
          "Distance (Kms)": "",
          "Agreed Transit Time": "",
          "Status of  Despatch": "",
          "Total Bottles / Bags (Units)": "",
          "Total Cases": "",
          "Tonnage in Kgs": "",
          "Wt/ Case": "",
          "Mode of despatch": "",
          "Transporter/ Courier Name": "",
          "Vehicle Type": "",
          "Vehicle Number": "",
          "LR / GR / Way Bill / Docket No": "",
          "LR/GR/Docket Date": "",
          "Basic Freight (Rs)  (A)": "",
          "Loading Charges (If applicable)  (B)": "",
          "Unloading Charges (If applicable)  ( C ) ": "",
          "Detention, if Any [D]": "",
          "Total Freight (T= A+B+C+D+E) ": "",
          "Expected Delivery Date": "",
          "Delivery Date/POD": "",
          "Freight / Bottle / Bag": "",
          "% of Freight on Inv value": "",
          "Freight Per KG": "",
          "Loading Charges Case": "",
          "Unloading Charges Case": "",
          "Order Process in days": "",
          "Order Service in days": "",
          "Dispatch Service in days": "",
          "Delivery Service in days": "",
          "Total Serivce in days": "",
          "Order Process TAT": "",
          "Order to Invoice TAT": "",
          "Invoice to Dispatch TAT": "",
          "Dispatch to Delivery TAT": "",
          "Reason for Delay Order Process in days": "",
          "Reason for Delay Order Service in days": "",
          "Reason for Delay Dispatch Service in days": "",
          "Reason for Delay Delivery Service in days": "",
          "Remarks, If Any": "",
        },
      ];
      this.exportToCSVDownload(
        [...reportsExportRowsData],
        "LOGBOOK_DATA_EXCEL"
      );
    }
  };
  handleChange = (dateValues) => {
    searchValues["FromDate"] =
      dateValues[0] !== null
        ? dateValues[0]
          ? moment(dateValues[0]).format("YYYY-MM-DD")
          : moment(dateValues).format("YYYY-MM-DD")
        : null;
    searchValues["ToDate"] =
      dateValues[1] !== null
        ? moment(dateValues[1]).format("YYYY-MM-DD")
        : null;
    this.setState({ searchFilters: searchValues }, () => {
      offset = 0;
      window.localStorage.setItem("offset", offset);
    });
  };
  reset = () => {
    this.setState({
      searchFilters: "",
    });
  };
  // export report logic from
  exportData = () => {
    this.handleModelOpen();
    // this.setState({ loading: true });

    this.props.requestLogbookReport(this.state.searchFilters, (res) => {
      this.handleModelClose();
      if (res?.success) {
        NotificationManager.success(res["message"], "Success");
        this.getAllData();
        this.setState({ dataLoading: false });
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({ dataLoading: false });
      }
    });
  };

  exportToCSVDownload = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    this.setState({ loading: false });
  };

  // export report logic to
  render() {
    let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkTheme = mode === "dark__mode" ? true : false;
    // const { name, phoneNo, loading } = this.state;
    const { logbookDataExcelUrl } = this.props;
    const localStorageData = JSON.parse(
      localStorage.getItem("LogbookDataS3URL")
    );
    const logbookDataForList = navigator.onLine
      ? logbookDataExcelUrl?.data?.docs
      : localStorageData?.data?.docs;
    let rows = [];
    if (Array.isArray(logbookDataForList)) {
      totCnt = navigator.onLine
        ? logbookDataExcelUrl?.data?.length
        : localStorageData?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = navigator.onLine
        ? logbookDataExcelUrl?.data?.offset +
          logbookDataExcelUrl?.data?.docs?.length
        : localStorageData?.data?.offset + localStorageData?.data?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      logbookDataExcelUrl.data.docs.forEach((e) => {
        rows.push({
          // s3Url: e?.url,
          createdTime:
            // moment(e?.requestedTime).format("DD-MM-YYYY", true)
            e?.requestedTime.substr(0, 10) +
            " " +
            e?.requestedTime.substr(11, 8),
          fromInvoiceDate: e?.fromInvoiceDate
            ? e?.fromInvoiceDate.substr(0, 10)
            : "N/A",
          toInvoiceDate: e?.toInvoiceDate
            ? e?.toInvoiceDate.substr(0, 10)
            : "N/A",
          status: e?.status,
          action: (
            <Button
              className="logbookButtonDownload"
              onClick={() => this.downloadExcelUrlData(e?.url)}
            >
              Download
            </Button>
          ),
        });
      });
    }
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;
    return (
      <React.Fragment>
        <MDBContainer className="invoice WaringModal">
          <MDBModal
            // modalStyle={"warning"}
            isOpen={this.state.open}
            toggle={() => {
              this.handleModelOpen();
            }}
            centered
            size="sm"
            className={` ${this.props.mode ? "invoiceDarkMode" : ""}`}
          >
            <i
              className="fas fa-times closemodalLog pr-5 text-right close pt-4"
              onClick={() => this.handleModelClose()}
            ></i>
            <MDBModalHeader>
              <h4 className="dark__span">Information</h4>
            </MDBModalHeader>
            <MDBModalBody>
              <h5 className="dark__span">
                Your request is in progress, kindly visit after 10 minutes.
              </h5>
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
        {getLocalStorage()}
        <QuickFilters
          mode={darkTheme}
          uploadS3Data={this.exportData}
          excelUrl={this.state.excelUrl}
          handleChange={this.handleChange}
          searchFilters={this.state.searchFilters}
          reset={this.reset}
        />
        <Index
          {...this.props}
          headers={HeaderData}
          data={rows}
          searchable={false}
          searchBar={searchBar}
          fetchId={this.props.fetchId}
          reset={this.reset}
          searchValueAssignerFunction={this.searchValueAssignerFunction}
          getAllData={this.getAllDataReset}
          dataLength={90}
          rowHeight={"58px"}
          dataLoading={this.state.loading}
          dashboard="true"
        />
        {totCnt !== 0 && rows?.length > 0 && !this.state.loading ? (
          <Row
            xs={12}
            style={{ color: darkTheme ? "#9d9d9d" : "#0C0C0C" }}
            className="mb-2 paginationRow"
          >
            <Col xs={7} sm={5} md={4} className="span-col-pagenation">
              <div className="float-left">
                <Pagination
                  className="desktop-pagination"
                  size="medium"
                  shape="rounded"
                  page={offset / 10 + 1}
                  count={totPages}
                  color="primary"
                  onChange={this.handleChangePage}
                  defaultPage={1}
                  siblingCount={1}
                />
                <Pagination
                  className="mobile-pagination"
                  size="small"
                  shape="rounded"
                  page={offset / 10 + 1}
                  count={totPages}
                  color="primary"
                  onChange={this.handleChangePage}
                  defaultPage={1}
                  siblingCount={1}
                />
              </div>
            </Col>
            <Col
              className="mt-2 spanPagenationentries span-col-pagenation"
              xs={5}
              sm={7}
              md={8}
            >
              {" "}
              <span>
                Showing {fromVal} to {toVal} of {totCnt} entries
              </span>
            </Col>
          </Row>
        ) : null}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    logbookDataExcelUrl: state?.logbookReducer?.getlogbookS3ExcelUrlsData,
    // fetchId: state.ShipmentMaster?.ShipmentMaster?.fetchId,
  };
};

export default connect(mapStateToProps, {
  requestLogbookReport,
  createLogbookS3,
  fetchLogbookPresignedUrl,
  uploadImagesAws1,
  getLogbookDataS3Data,
})(LogBook);
