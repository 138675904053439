import React from "react";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: (
          <label>
            <input
              type="checkbox"
              name="checkbox"
              class="square-checkbox"
              value={false}
              disabled
            />
          </label>
        ),
        field: "Select",
        sort: "asc",
        width: 1,
      },
      {
        label: "Order No",
        field: "orderNumber",
        sort: "asc",
        width: 2,
      },
      {
        label: "Order Type",
        field: "orderType",
        sort: "asc",
        width: 1,
      },
      {
        label: "Invoice No",
        field: "legalInvoiceNo",
        sort: "asc",
        width: 3,
      },
      {
        label: "Billed To Name",
        field: "billedToName",
        sort: "asc",
        width: 6,
      },
      {
        label: "Billed To City",
        field: "billedToCity",
        sort: "asc",
        width: 3,
      },
      {
        label: "WareHouse",
        field: "businessUnit",
        sort: "asc",
        width: 2,
      },
      {
        label: "Transporter",
        field: "transporter",
        sort: "asc",
        width: 4,
      },
      {
        label: "MoT",
        field: "moT",
        sort: "asc",
        width: 2,
      },
      {
        label: "Vehicle No",
        field: "vehicleNo",
        sort: "asc",
        width: 3,
      },
      // {
      //   label: "LR Date",
      //   field: "LRDate",
      //   sort: "asc",
      //   width: 2,
      // },
      {
        label: "Invoice Date",
        field: "InvoiceDate",
        sort: "asc",
        width: 3,
      },
  
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 2,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var AdminHeaderData = {
  columns: [
    {
      label: (
        <label>
          <input
            type="checkbox"
            name="checkbox"
            class="square-checkbox"
            value={false}
            disabled
          />
        </label>
      ),
      field: "Select",
      sort: "asc",
      width: 1,
    },
    {
      label: "Order No",
      field: "orderNumber",
      sort: "asc",
      width: 2,
    },
    {
      label: "Invoice No",
      field: "legalInvoiceNo",
      sort: "asc",
      width: 2,
    },
    {
      label: "Billed Name",
      field: "billed Name",
      sort: "asc",
      width: 2,
    },
    {
      label: "Billed City",
      field: "billed City",
      sort: "asc",
      width: 2,
    },
    {
      label: "Transporter",
      field: "transporter",
      sort: "asc",
      width: 3,
    },
    {
      label: "MoT",
      field: "moT",
      sort: "asc",
      width: 1,
    },
    {
      label: "Vehicle No",
      field: "vehicleNo",
      sort: "asc",
      width: 2,
    },
    {
      label: "LR Date",
      field: "LRDate",
      sort: "asc",
      width: 2,
    },
    {
      label: "Invoice Date",
      field: "InvoiceDate",
      sort: "asc",
      width: 3,
    },

    {
      label: "Action",
      field: "action",
      sort: "asc",
      width: 1,
    },
  ],
};
export var searchBar;
searchBar = {
  searchBarData: [
    {
      label: "Order No",
      field: "Order Number",
      type: "text",
    },
    {
      label: "Order Type",
      field: "Order Type",
      type: "text",
    },
    {
      label: "Invoice No",
      field: "Legal Invoice No",
      type: "text",
    },
    {
      label: "Billed To Name",
      field: "billed To Name",
      type: "text",
    },
    {
      label: "Billed To City",
      field: "billed To City",
      type: "text",
    },
   
    {
      label: "Business Unit",
      field: "Warehouse",
      type: "text",
    },
    {
      label: "Transporter",
      field: "transporter",
      type: "text",
    },

    {
      label: "MoT",
      field: "Mode Of Transport",
      type: "text",
    },
    {
      label: "Vehicle No",
      field: "Vehicle No",
      type: "text",
    },
    {
      label: "LR No",
      field: "Lr No",
      type: "text",
    },
    {
      label: "EWay Bill No",
      field: "EWay Bill No",
      type: "text",
    },
    {
      label: "Cancelled Invoice",
      field: "Cancelled Invoice",
      type: "select",
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      type: "text",
    },
  ],
};
export var TripCreatioModalHeaders = {
  columns: [
    {
      label: "Transporter",
      field: "transporter",
      sort: "asc",
      width: 3,
    },
    {
      label: "Invoice No",
      field: "legalInvoiceNo",
      sort: "asc",
      width: 2,
    },
    {
      label: "MoT",
      field: "modeofTransport",
      sort: "asc",
      width: 1,
    },
    {
      label: "Vehicle No",
      field: "vehicleNo",
      sort: "asc",
      width: 2,
    },
    {
      label: "LR No",
      field: "lrNo",
      sort: "asc",
      width: 1,
    },

    {
      label: "Action",
      field: "action",
      sort: "asc",
      width: 1,
    },
  ],
};
