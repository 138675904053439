import React, { Component } from "react";

import { Row, Col, Alert, Container, FormGroup } from "reactstrap";
import Button from "@material-ui/core/Button";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { checkLogin, checkADLogin, apiError } from "../../store/actions";

//Notifications
import { NotificationManager } from "react-notifications";

//Otp API
import { verifyOtp, sendOtp, sendNewPassword } from "../../store/actions";
import { fetchLastSyncTime, fetchLanguage } from "../../store/common/actions";
import { AzureLoginConfig } from "./AzureLoginConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { ENGLISH } from "../../language/language";
// import axios from "axios";
import mobileSpinClab from "../../assets/images/mobile-spin-clabs.png";
import mobileTranspoterTextLogo from "../../assets/images/mobileTranspoter.png";
import mobileCopyRights from "../../assets/images/mobileCopyRights.png";

const mobileSpinLogo = require("../../assets/images/" + process.env.REACT_APP_DASHBOARD_LOGO_IMAGE)
const mobileLogo = require("../../assets/images/" + process.env.REACT_APP_DASHBOARD_LOGO_MINI_IMAGE)

const logo = require("../../assets/images/" + process.env.REACT_APP_LOGO_IMAGE);
const authImage = require("../../assets/images/" +
  process.env.REACT_APP_AUTH_IMAGE);

const spinClabImg = process.env.REACT_APP_SPINCLAB_LOGO
  ? require("../../assets/images/" + process.env.REACT_APP_SPINCLAB_LOGO)
  : "";

const transportImg = process.env.REACT_APP_TRANSPORTER_IMAGE
  ? require("../../assets/images/" + process.env.REACT_APP_TRANSPORTER_IMAGE)
  : "";

const copyRight = process.env.REACT_APP_COPYRIGHT
  ? require("../../assets/images/" + process.env.REACT_APP_COPYRIGHT)
  : "";

const microsoftLogo = process.env.REACT_APP_MICROSOFT_IMAGE
  ? require("../../assets/images/" + process.env.REACT_APP_MICROSOFT_IMAGE)
  : "";
const atflUsers = process.env.REACT_APP_ATFL_USERS
  ? process.env.REACT_APP_ATFL_USERS
  : "";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      email: "",
      password: "",
      remember: false,
      otpSwitch: false,
      error: false,
      errorMessage: "",
      onSubmitDisable: false,
      onSubmitDisableAzure: false,
      isAuthenticated: false,
      parentCode: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.publicClientApplication = new PublicClientApplication({
      auth: {
        clientId: AzureLoginConfig.appId,
        redirectUri: AzureLoginConfig.redirectUri,
        authority: AzureLoginConfig.authority,
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
      },
    });
  }

  handleSubmit(event, values) {
    // console.log("values", values);
    if (process.env.REACT_APP_CLIENT === "3_BAXTER") {
      let data = {
        parentCode: values.parentCode,
        password: values.password,
      };
      this.props.checkLogin(data, this.props.history, (res) => {
        if (res.success === false) {
          this.setState({
            errorMessage: "Login Failed",
            error: true,
            onSubmitDisable: false,
          });
        } else {
          NotificationManager.success(res["message"], "Logged In");
          this.setState({ onSubmitDisable: true });
          if (this.state.error === true)
            this.setState({
              error: false,
              errorMessage: "",
            });
        }
      });
    } else {
      if (!this.state.onSubmitDisableAzure) {
        this.setState({
          onSubmitDisable: true,
          error: false,
          errorMessage: "",
        });
        if (this.state.phone) {
          if (this.state.otpSwitch === false) {
            this.props.checkLogin(values, this.props.history, (res) => {
              if (res.success === false) {
                this.setState({
                  error: true,
                  errorMessage: res.message,
                  onSubmitDisable: false,
                });
                NotificationManager.error(res["message"], "Failed");
              } else {
                NotificationManager.success(res["message"], "Logged In");
              }
            });
          } else {
            let data = {
              phone: values.phone,
              OTP: values.password,
            };
            this.props.checkLogin(data, this.props.history, (res) => {
              if (res.status === false) {
                this.setState({
                  errorMessage: "Invalid OTP",
                  error: true,
                  onSubmitDisable: false,
                });
              } else {
                NotificationManager.success(res["message"], "Logged In");
                this.setState({ onSubmitDisable: false });
                if (this.state.error === true)
                  this.setState({
                    error: false,
                    errorMessage: "",
                  });
              }
            });
          }
        } else {
          let data = {
            email: values.phone,
            password: values.password,
          };
          this.props.checkLogin(data, this.props.history, (res) => {
            if (res.success === false) {
              this.setState({
                errorMessage: "Login Failed",
                error: true,
                onSubmitDisable: false,
              });
            } else {
              NotificationManager.success(res["message"], "Logged In");
              this.setState({ onSubmitDisable: false });
              if (this.state.error === true)
                this.setState({
                  error: false,
                  errorMessage: "",
                });
            }
          });
        }
      }
    }
  }

  componentDidMount() {
    let userLangDetail = localStorage.getItem("userLangDetail");
    if (userLangDetail === null) {
      localStorage.setItem("userPreferredLang", "EN");
      localStorage.setItem("userLangDetail", JSON.stringify(ENGLISH));
      this.props.fetchLanguage(ENGLISH);
    }
    const isLogin = localStorage.getItem("authUser");
    if (isLogin) this.props.history.push("/");
    document.body.classList.add("auth-body-bg");
  }

  handleRemember = () => {
    this.setState({ remember: !this.state.remember }, () => {
      this.state.remember
        ? localStorage.setItem("remember", true)
        : localStorage.removeItem("remember");
    });
  };

  componentWillUnmount() {
    document.body.classList.remove("auth-body-bg");
  }

  otpLogin = () => {
    let phoneCheck = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    let emailCheck =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.state.phone.match(phoneCheck) && this.state.error === false) {
      this.setState({ error: false });
      if (this.state.otpSwitch === false) {
        this.setState({ otpSwitch: true });
        let data = { phone: this.state.phone };

        //Api call method
        this.props.sendOtp(data, (res) => {
          if (res.success === false) {
            this.setState({
              errorMessage: res["message"],
              error: true,
              otpSwitch: false,
            });
            NotificationManager.error(res["message"], "OTP sent");
          }
          //Notify otp sent
          else {
            NotificationManager.success(res["message"], "OTP sent");
          }
        });
      } else {
        this.setState({ otpSwitch: false });
      }
    } else if (!this.state.phone.match(phoneCheck)) {
      this.setState({
        error: true,
        errorMessage: "Enter valid Phone",
        otpSwitch: false,
      });
    } else if (
      this.state.phone.match(phoneCheck) &&
      this.state.error === true
    ) {
      if (this.state.otpSwitch === true)
        this.setState({ otpSwitch: false, error: false, errorMessage: "" });
      else this.setState({ otpSwitch: true, error: false, errorMessage: "" });
    }
  };

  storePhone = (e) => {
    const parentregex = /\d{0,}/;
    let phoneCheck = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    let emailCheck =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (process.env.REACT_APP_CLIENT === "3_BAXTER") {
      if (e.target.value.match(parentregex)) {
        this.setState({
          parentCode: e.target.value,
          error: false,
        });
      } else {
        this.setState({
          error: true,
          errorMessage: "Enter valid parent code",
          otpSwitch: false,
        });
      }
    } else {
      if (e.target.value.match(phoneCheck)) {
        this.setState({ phone: e.target.value, email: "", error: false });
      } else if (e.target.value.match(emailCheck)) {
        this.setState({ email: e.target.value, phone: "", error: false });
      } else {
        this.setState({
          error: true,
          errorMessage: "Enter valid Phone number or Email",
          otpSwitch: false,
        });
      }
    }
  };

  azureLogin = async () => {
    if (!this.state.onSubmitDisable) {
      this.setState({ onSubmitDisableAzure: true });
      try {
        await this.publicClientApplication
          .loginPopup({
            scopes: AzureLoginConfig.scopes,
            prompt: "select_account",
          })
          .then((loginResponse) => {
            this.props.checkADLogin(
              loginResponse.idToken,
              this.props.history,
              (res) => {
                if (res.success === false) {
                  this.setState({
                    error: true,
                    errorMessage: res.message,
                    onSubmitDisableAzure: false,
                  });
                  NotificationManager.error(res["message"], "Failed");
                } else {
                  NotificationManager.success(res["message"], "Logged In");
                }
              }
            );
          });
        this.setState({ isAuthenticated: true });
      } catch (err) {
        this.setState({
          isAuthenticated: false,
          user: {},
          error: err,
          onSubmitDisableAzure: false,
        });
      }
    }
  };

  render() {
    // console.log("test image", mobileSpinClab);
    let placeholder, changeText;
    if (this.state.otpSwitch === true) {
      placeholder = "Enter OTP";
      changeText = "Password";
    } else {
      placeholder = "Password";
      changeText = "OTP";
    }
    const isBaxter = process.env.REACT_APP_CLIENT === "3_BAXTER"
    const logoWidth = isBaxter ? '150px' : '57px'
    const logoHeight = isBaxter ? '200px' : '87px'
    const smallLogoHeight = isBaxter ? '160px' : '87px'
    const copyrightWidth = isBaxter ? '400px' : '276px'
    const signInWith = isBaxter ? 'Trakezie by Baxter International' :'TMS'
    const mobileSmallLogoHeight =  isBaxter ? '120px' : '87px'
    const mobileSmallLogoWidth =  isBaxter ? '120px' : '57px'
    return (
      <React.Fragment>
        <div className="login-page-descktop-div">
          <Container
            fluid
            style={{ maxWidth: "100%", maxHeight: "100%", margin: "-12px" }}
          >
            <Row className="no-gutters d-flex">
              <Col
                xs={12}
                md={8}
                lg={8}
                className="authentication-bg-new vh-100"
              >
                {spinClabImg ? (
                  <img src={authImage} alt="auth logo" className="img-fluid" />
                ) : (
                  <img
                    src={authImage}
                    alt="auth logo"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    className="img-fluid"
                  />
                )}

                {spinClabImg ? (
                  <img src={spinClabImg} style={{
                    height:'87px'
                  }} className="img-spinclabs" alt="" />
                ) : (
                  ""
                )}
                {transportImg ? (
                  <img
                    src={transportImg}
                    style={{ width: "auto", height: "50px" }}
                    className="img-transport-system"
                    alt=""
                  />
                ) : (
                  ""
                )}
                {copyRight && !isBaxter ? (
                  <img
                    className="copy-rights bottom-left "
                    src={copyRight}
                    style={{width:copyrightWidth}}
                    alt=""
                  />
                ) 
                : isBaxter ?
                 <span className="copy-rights bottom-left " style={{width:copyrightWidth}}>&copy; Copyright - Powered by Spinclabs Pvt Ltd. <a href="https://www.spinclabs.com" target="_blank">www.spinclabs.com</a></span>
                :(
                  ""
                )}
              </Col>

              <Col
                xs={12}
                md={4}
                lg={4}
                className="authentication-page-content d-flex align-items-center min-vh-100 flex-grow-1"
              >
                <div style={{ width: "100%", maxHeight: "100%" }}>
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" className="logo">
                              <img
                                src={logo}
                                alt="logo"
                                style={{
                                  position: "relative",
                                  width: logoWidth,
                                  height: smallLogoHeight,
                                }}
                              />
                            </Link>
                          </div>
                        </div>
                        <div className="text-center">
                          <h5>
                            <strong>{process.env.REACT_APP_SIGN_IN}</strong>
                          </h5>
                        </div>
                        {this.props?.loggedIn || this.props?.loginError ? (
                          <Alert color="danger">{this.props?.loginError}</Alert>
                        ) : null}

                        <div className="p-2 mt-4">
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={this.handleSubmit}
                          >
                            <FormGroup className="auth-form-group-custom-login mb-4">
                              <i className="ri-user-2-line auti-custom-input-icon"></i>

                              {process.env.REACT_APP_CLIENT === "3_BAXTER" ? (
                                <AvField
                                  name="parentCode"
                                  value={
                                     this.state.parentCode
                                  }
                                  className="form-control login-form-control"
                                  onChange={this.storePhone}
                                  errorMessage="Enter valid User Id"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  placeholder="User Id"
                                />
                              ) : (
                                <AvField
                                  name="phone"
                                  value={this.state.phone || this.state.email}
                                  className="form-control login-form-control"
                                  onChange={this.storePhone}
                                  errorMessage="Enter valid phone/Email"
                                  validate={{
                                    required: { value: true },
                                    pattern: {
                                      value:
                                        "/^(?:[0-9])|([a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+)$/",
                                    },
                                  }}
                                  placeholder="Username"
                                />
                              )}
                            </FormGroup>

                            <FormGroup className="auth-form-group-custom-login mb-3">
                              <i className="ri-lock-2-line auti-custom-input-icon"></i>

                              <AvField
                                name="password"
                                value={this.state.password}
                                type="password"
                                className="form-control login-form-control"
                                id="userpassword"
                                placeholder={placeholder}
                                errorMessage="This field cannot be empty"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </FormGroup>

                            <div className="mt-2 text-center">
                              <Button
                                variant="contained"
                                color="primary"
                                className="mt-1 btn-block"
                                type="submit"
                                onClick={this.handleRemember}
                                disabled={this.state.onSubmitDisable}
                              >
                                {process.env.REACT_APP_LOGIN_BUTTON_TEXT}
                              </Button>
                            </div>
                            {process.env.REACT_APP_CLIENT !== "3_BAXTER" ?
                            <>
                             <div
                              className="text-center"
                              style={{ marginTop: "18px" }}
                            >
                              <span>OR</span>
                            </div>
                            <div
                              className="mt-2 text-center text-capitalize"
                              style={{
                                minWidth: "100%",
                                backgroundColor: "white",
                                minHeight: "30px",
                              }}
                            >
                              <Button
                                className="button btn-block"
                                style={{
                                  minWidth: "100%",
                                  backgroundColor: "white",
                                }}
                                variant="contained"
                                onClick={() => {
                                  this.azureLogin();
                                }}
                                disabled={this.state.onSubmitDisableAzure}
                              >
                                {atflUsers}
                                {microsoftLogo ? (
                                  <img
                                    className="ml-2"
                                    height="30px"
                                    width="30px"
                                    src={microsoftLogo}
                                    alt=""
                                  />
                                ) : (
                                  ""
                                )}
                              </Button>
                            </div>

                            <div className="mt-4 text-center ">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock mr-1"></i> Forgot
                                your password?
                              </Link>
                            </div>
                            </>
                            :""
                            }
                           
                          </AvForm>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="login-page-mobile-div">
          <img width="100%" height="auto" src={mobileSpinClab} alt=""></img>
          <Row className="d-flex align-items-center  flex-grow-1 mobile-login-page-row">
            <Col xs={12} className="w-100">
              <div className="mobile-login-img-logo-div">
                <img
                  src={mobileSpinLogo}
                  className="mobile-login-page-img"
                  style = {{
                    ...(
                      isBaxter && {
                        width:'250px',
                        marginTop: '-50px',
                        marginBottom: '-60px',
                      }
                    )
                  }}
                  alt=""
                ></img>
              </div>
            </Col>
            <Col xs={12} className="mt-4 mobile-login-col">
              <div className="mobile-login-div">
                <div style={{ width: "100%", maxHeight: "100%" }}>
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" className="logo">
                              {console.log(mobileLogo)}
                              <img
                                src={mobileLogo}
                                alt="logo"
                                style={{
                                  position: "relative",
                                  width: mobileSmallLogoWidth,
                                  height: mobileSmallLogoHeight,
                                }}
                              />
                            </Link>
                          </div>
                        </div>
                        <div className="text-center">
                          <h5>
                            <strong>Sign in to continue to {signInWith}</strong>
                          </h5>
                        </div>
                        {this.props?.loggedIn || this.props?.loginError ? (
                          <Alert color="danger">{this.props?.loginError}</Alert>
                        ) : null}

                        <div className="p-2 mt-4">
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={this.handleSubmit}
                          >
                            <FormGroup className="auth-form-group-custom-login mb-4">
                              <i className="ri-user-2-line auti-custom-input-icon"></i>

                              {/* <AvField
                                name="phone"
                                value={this.state.phone || this.state.email}
                                className="form-control login-form-control"
                                onChange={this.storePhone}
                                errorMessage="Enter valid phone/Email"
                                validate={{
                                  required: { value: true },
                                  pattern: {
                                    value:
                                      "/^(?:[0-9])|([a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+)$/",
                                  },
                                }}
                                placeholder="Username"
                              /> */}
                                {process.env.REACT_APP_CLIENT === "3_BAXTER" ? (
                                <AvField
                                  name="parentCode"
                                  value={
                                     this.state.parentCode
                                  }
                                  className="form-control login-form-control"
                                  onChange={this.storePhone}
                                  errorMessage="Enter valid User Id"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  placeholder="User Id"
                                />
                              ) : (
                                <AvField
                                  name="phone"
                                  value={this.state.phone || this.state.email}
                                  className="form-control login-form-control"
                                  onChange={this.storePhone}
                                  errorMessage="Enter valid phone/Email"
                                  validate={{
                                    required: { value: true },
                                    pattern: {
                                      value:
                                        "/^(?:[0-9])|([a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+)$/",
                                    },
                                  }}
                                  placeholder="Username"
                                />
                              )}
                            </FormGroup>

                            <FormGroup className="auth-form-group-custom-login mb-3">
                              <i className="ri-lock-2-line auti-custom-input-icon"></i>

                              <AvField
                                name="password"
                                value={this.state.password}
                                type="password"
                                className="form-control login-form-control"
                                id="userpassword"
                                placeholder={placeholder}
                                errorMessage="This field cannot be empty"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </FormGroup>

                            <div className="mt-2 text-center">
                              <Button
                                variant="contained"
                                color="primary"
                                className="mt-1 btn-block"
                                type="submit"
                                onClick={this.handleRemember}
                                disabled={this.state.onSubmitDisable}
                              >
                                {process.env.REACT_APP_LOGIN_BUTTON_TEXT}
                              </Button>
                            </div>
                            {process.env.REACT_APP_CLIENT !== "3_BAXTER" ?
                            <>
                            <div
                              className="text-center"
                              style={{ marginTop: "18px" }}
                            >
                              <span>OR</span>
                            </div>
                            <div
                              className="mt-2 text-center text-capitalize"
                              style={{
                                minWidth: "100%",
                                backgroundColor: "white",
                                minHeight: "30px",
                              }}
                            >
                              <Button
                                className="button btn-block"
                                style={{
                                  minWidth: "100%",
                                  backgroundColor: "white",
                                }}
                                variant="contained"
                                onClick={() => {
                                  this.azureLogin();
                                }}
                                disabled={this.state.onSubmitDisableAzure}
                              >
                                {atflUsers}
                                {microsoftLogo ? (
                                  <img
                                    className="ml-2"
                                    height="30px"
                                    width="30px"
                                    src={microsoftLogo}
                                    alt=""
                                  />
                                ) : (
                                  ""
                                )}
                              </Button>
                            </div>

                            <div className="mt-4 text-center ">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock mr-1"></i> Forgot
                                your password?
                              </Link>
                            </div>
                            </>
                            :""
                            }
                          </AvForm>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col xs={12}>
              <div className="mobile-login-page-img-trans">
                <img
                  src={mobileTranspoterTextLogo}
                  className="pl-2 pr-2"
                  width="262px"
                  height="48px"
                  alt=""
                />
              </div>
            </Col>
            <Col xs={12}>
              <div className="mobile-login-page-img-copy">
                {!isBaxter ? <img
                  src={mobileCopyRights}
                  width="270px"
                  height="14px"
                  className="pl-2 pr-2"
                  alt=""
                /> :
                <span 
                  style={{
                    textAlign:'center',
                    color: '#fff'
                  }}
                  >&copy; Copyright - Powered by Spinclabs Pvt Ltd. <a href="https://www.spinclabs.com" target="_blank">www.spinclabs.com</a></span>
                }
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(
  connect(mapStatetoProps, {
    checkLogin,
    checkADLogin,
    apiError,
    verifyOtp,
    sendOtp,
    sendNewPassword,
    fetchLastSyncTime,
    fetchLanguage,
  })(Login)
);
