import { types } from "../actionTypes";
const initialState = {
  vehicleDetailPlacementData: {},
  fetchId: "",
  vehicleDetailPlacementDataForExcel: {},
};
export default (state = { initialState }, action) => {
  switch (action.type) {
    case types.VEHICLE_PLACEMENT_DETAIL_REPORT_SUCCESS:
      return {
        ...state,
        vehicleDetailPlacementData: action.payload,
        fetchId: "id" + new Date().getTime(),
      };
    case types.VEHICLE_PLACEMENT_DETAIL_REPORT_FOR_EXCEL_SUCCESS:
      return {
        ...state,
        vehicleDetailPlacementDataForExcel: action.payload,
        fetchId: "id" + new Date().getTime(),
      };
    default:
      state = { ...state };
      break;
  }

  return state;
};
