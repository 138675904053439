let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: "Order Number",
        field: "orderNumber",
        sort: "asc",
        width: 1,
      },
      {
        label: "Order LineNo",
        field: "orderLineNo",
        sort: "asc",
        width: 1,
      },
      // {
      //   label: "Second ItemNumber",
      //   field: "secondItemNumber",
      //   sort: "asc",
      //   width: 1,
      // },
      {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 1,
      },
      {
        label: "Quantity Ordered",
        field: "quantityOrdered",
        sort: "asc",
        width: 1,
      },
      // {
      //   label: "UnitPrice",
      //   field: "unitPrice",
      //   sort: "asc",
      //   width: 1,
      // },
      // {
      //   label: "UnitWeight",
      //   field: "unitWeight",
      //   sort: "asc",
      //   width: 1,
      // },
      // {
      //   label: "CustomerPoNoDate",
      //   field: "customerPoNoDate",
      //   sort: "asc",
      //   width: 1,
      // },
      // {
      //   label: "UnitVolume",
      //   field: "unitVolume",
      //   sort: "asc",
      //   width: 1,
      // },
      // {
      //   label: "OrderCo",
      //   field: "orderCo",
      //   sort: "asc",
      //   width: 1,
      // },
      {
        label: "Order Type",
        field: "orderType",
        sort: "asc",
        width: 1,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 1,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var AdminHeaderData = {
  columns: [
    {
      label: "Order Number",
      field: "orderNumber",
      sort: "asc",
      width: 1,
    },
    {
      label: "Order LineNo",
      field: "orderLineNo",
      sort: "asc",
      width: 1,
    },
    {
      label: "Second ItemNumber",
      field: "secondItemNumber",
      sort: "asc",
      width: 1,
    },
    {
      label: "Description",
      field: "description",
      sort: "asc",
      width: 1,
    },
    {
      label: "Quantity Ordered",
      field: "quantityOrdered",
      sort: "asc",
      width: 1,
    },
    {
      label: "UnitPrice",
      field: "unitPrice",
      sort: "asc",
      width: 1,
    },
    {
      label: "UnitWeight",
      field: "unitWeight",
      sort: "asc",
      width: 1,
    },
    {
      label: "CustomerPoNoDate",
      field: "customerPoNoDate",
      sort: "asc",
      width: 1,
    },
    {
      label: "UnitVolume",
      field: "unitVolume",
      sort: "asc",
      width: 1,
    },
    {
      label: "OrderCo",
      field: "orderCo",
      sort: "asc",
      width: 1,
    },
    {
      label: "OrderType",
      field: "orderType",
      sort: "asc",
      width: 1,
    },
    {
      label: "Action",
      field: "action",
      sort: "asc",
      width: 1,
    },
  ],
};
export var searchBar;
searchBar = {
  searchBarData: [
    {
      label: "Order Number",
      field: "Order Number",
      type: "text",
    },
    {
      label: "Order LineNo",
      field: "Order Line No",
      type: "text",
    },
    {
      label: "Description",
      field: "description",
      type: "text",
    },
    {
      label: "Quantity Ordered",
      field: "Quantity Ordered",
      type: "text",
    },
    {
      label: "OrderType",
      field: "Order Type",
      type: "text"
    },
    // {
    //   label: "Second ItemNumber",
    //   field: "secondItemNumber",
    //   type: "text",
    // },
  
    {
      
    },
 
    // {
    //   label: "UnitPrice",
    //   field: "unitPrice",
    //   type: "text",
    // },
    // {
    //   label: "UnitWeight",
    //   field: "unitWeight",
    //   type: "text",
    // },
    // {
    //   label: "CustomerPoNoDate",
    //   field: "customerPoNoDate",
    //   type: "text",
    // },
    // {
    //   label: "OrderCo",
    //   field: "orderCo",
    //   type: "text"
    // },
    // {
    //   label: "UnitVolume",
    //   field: "unitVolume",
    //   type: "text"
    // },
    
  ],
};
