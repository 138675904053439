export const CHECK_LOGIN = "check_login";
export const LOGIN_USER_SUCCESSFUL = "login_user_successfull";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";

export const CHECK_LOAD = "CHECK_LOAD";
export const LOAD_USER_SUCCESSFUL = "LOAD_USER_SUCCESSFUL";
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESSFUL = "GET_USER_SUCCESSFUL";
export const AD_LOGIN = "AD_LOGIN";
export const AD_LOGIN_SUCCESS = "AD_LOGIN_SUCCESS";

export const API_ERROR = "api_failed";
export const FETCH_ALL_NOTIFICATION_DATA = "FETCH_ALL_NOTIFICATION_DATA";
export const FETCH_ALL_NOTIFICATION_DATA_SUCCESS =
  "FETCH_ALL_NOTIFICATION_DATA_SUCCESS";
export const UPDATE_ALL_NOTIFICATION_DATA = "UPDATE_ALL_NOTIFICATION_DATA";
export const UPDATE_ALL_NOTIFICATION_DATA_SUCCESS =
  "UPDATE_ALL_NOTIFICATION_DATA_SUCCESS";

export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";

//Baxter API user update
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_USER_DATA_SUCCESS = "UPDATE_USER_DATA_SUCCESS";
