export const types = {
  GET_ORDERS: "GET_ORDERS",
  GET_ORDERS_SUCCESS: "GET_ORDERS_SUCCESS",
  GET_ORDERS_FOR_EXCEL: "GET_ORDERS_FOR_EXCEL",
  GET_ORDERS_FOR_EXCEL_SUCCESS: "GET_ORDERS_FOR_EXCEL_SUCCESS",

  GET_ORDERPRODUCT: "GET_ORDERPRODUCT",
  GET_ORDERPRODUCT_SUCCESS: "GET_ORDERPRODUCT_SUCCESS",

  VIEW_ORDERSBY_ID: "VIEW_ORDERSBY_ID",
  VIEW_ORDERSBY_ID_SUCCESS: "VIEW_ORDERSBY_ID_SUCCESS",

  VIEW_PRODUCTBY_ORDERID: "VIEW_PRODUCTBY_ORDERID",
  VIEW_PRODUCTBY_ORDERID_SUCCESS: "VIEW_PRODUCTBY_ORDERID_SUCCESS",

  VIEW_INVOICEBY_ORDERID: "VIEW_INVOICEBY_ORDERID",
  VIEW_INVOICEBY_ORDERID_SUCCESS: "VIEW_INVOICEBY_ORDERID_SUCCESS",

  VIEW_ORDER_LIFE: "VIEW_ORDER_LIFE",
  VIEW_ORDER_LIFE_SUCCESS: "VIEW_ORDER_LIFE_SUCCESS",

  TRACK_ORDER: "TRACK_ORDER",
  TRACK_ORDER_SUCCESS: "TRACK_ORDER_SUCCESS",

  RESYNC_ORDER: "RESYNC_ORDER",
  RESYNC_ORDER_SUCCESS: "RESYNC_ORDER_SUCCESS",

  CLOSE_TRIP: "CLOSE_TRIP",
  CLOSE_TRIP_SUCCESS: "CLOSE_TRIP_SUCCESS",

  ORDER_TRACKING_BY_EWAYBILL: "ORDER_TRACKING_BY_EWAYBILL",
  ORDER_TRACKING_BY_EWAYBILL_SUCCESS: "ORDER_TRACKING_BY_EWAYBILL_SUCCESS",
};
