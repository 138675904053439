import React, { Component } from "react";
import { Label, FormGroup, Row, Col } from "reactstrap";
import Select from "react-select";
import { ErrorMessage } from "formik";
import { fetchAllSourceDestination } from "../../../store/hop/actions";
import { connect } from "react-redux";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class SourceDestination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      hops: [],
      hopsDest: [],
      source: "",
      destination: "",
      sourceSet: false,
    };
  }

  componentDidMount = () => {
    this.fetcher();
  };

  sourceSetter = (e) => {
    this.setState({ source: e, sourceSet: true }, () => {
      this.fetcher();
    });
  };

  fetcher = () => {
    let hops = [];
    let source = this.state.sourceSet ? this.state.source?.value?.id : "";
    this.props.fetchAllSourceDestination((res) => {
      if (res?.data?.hops.length > 0) {
        res.data.hops.forEach((ele) => {
          hops.push({
            label: ele.hopCode + " (" + ele.label + ")",
            value: {
              name:
                ele.address.addressLine1 + ele.address.city + ele.address.state,
              hopCode: ele.hopCode,
              id: ele._id,
            },
          });
        });
        if (source === "") this.setState({ hops: hops });
        else this.setState({ hopsDest: hops });
      }
    });
  };

  render() {
 userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

    const customStyles = {
      indicatorsContainer: (style) => {
        return {
          ...style,
          backgroundColor: "#8e9af8",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          backgroundColor: "#8e9af8",
        };
      },
    };

    return (
      <React.Fragment>
        <Row>
          <Col xs={12} md={6}>
            <FormGroup className="select2-container required darkAgreementVehicleCharge">
              <Label className="col-form-label required-field light__span">
                {" "}
                {userLang?.common?.SOURCE}{" "}
              </Label>
              <Select
                styles={customStyles}
                value={this.props.sourceVal}
                name="source"
                onChange={(e) => {
                  this.props.setFieldValue("source", e);
                  this.sourceSetter(e);
                }}
                options={this.state.hops}
                // onInputChange={(e) => {
                //   this.handleBlurFilter({
                //     target: {
                //       name: "source",
                //       value: e,
                //     },
                //   });
                // }}
                classNamePrefix="select2-selection"
                id="source"
                className={
                  this.props.errors.source && this.props.touched.source
                    ? "input-error"
                    : null
                }
              />

              <ErrorMessage name="source" component="span" className="error" />
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <div className="d-flex">
              <FormGroup className="select2-container required darkAgreementVehicleCharge">
                <Label className="col-form-label required-field light__span">
                {userLang?.common?.DESTINATION}{" "}
                </Label>
                <Select
                  name="destination"
                  value={this.props.destinationVal}
                  styles={customStyles}
                  onChange={(e) => {
                    this.props.setFieldValue("destination", e);
                  }}
                  options={this.state.hops}
                  onBlur={this.props.handleBlur}
                  classNamePrefix="select2-selection"
                  id="destination"
                  //   onInputChange={(e) => {
                  //     this.handleBlurFilter({
                  //       target: {
                  //         name: "destination",
                  //         value: e,
                  //       },
                  //     });
                  //   }}
                  className={
                    this.props.errors.destination &&
                    this.props.touched.destination
                      ? "input-error"
                      : null
                  }
                  // isDisabled={this.state.disableHop}
                />
                <ErrorMessage
                  name="destination"
                  component="span"
                  className="error"
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  indents: state.Indent.indents,
});

export default connect(mapStateToProps, { fetchAllSourceDestination })(
  SourceDestination
);
