export let headersData = [
    {
      B: "Date of Dispatch",
      C: "TMS trip ID",
      D: "Location From",
      E: "Location To",
      F: "Route",
      G: "LR Number",
      H: "TPT Bill Number",
      I: "Vehicle Type",
      J: "PF Number",
      K: "Freight Cost as per TPT Bill",
      L: "Freight Cost as per Oracle",
      M: "Diff. Amount",
      N: "Additional Payment",
      O: "",
      P: "",
      Q: "",
      R: "",
      S: "",
      T: "Deductions",
      U: "",
      V: "",
      W: "Total ",
      X: "",
      Y: "",
    },
    {
      B: "Dispatch",
      C: "Trip ID",
      D: "From",
      E: " To",
      F:"",
      G: "Number", //
      H: " Number",
      I: "Type",
      J: "Number", //
      K: "as per TPT Bill", //
      L: "as per Oracle",
      M: "Amount",
      N: "Un Loading point detention",
      O: "Loading Point detention",
      P: "Loading Charges",
      Q: "Unloading Charges",
      R: "Multi Point Pickup and Delivery charges",
      S: "Others",
      T: "Late Delivery",
      U: "Damages or Shortages",
      V: "Any other deduction",
      W: "Amount",
      X: "Credit Note no. (in case of FCM) (GST)",
      Y: "Comments",
    },
  ];
 export let invoiceDetailsHeaderData=[
  {
    A:"Transporter Name",
    B:"Supplier Tax Invoice No",
    C:"TMS Invoice No",
    D:"State",
    E:"No Of Bills",
    F:"Comment"
  }
 ]