import React, { Component } from "react";
import { Label, FormGroup } from "reactstrap";
import Select from "react-select";
import { fetchAllVehicleTypes } from "../../../store/vehicle/actions";
import { ErrorMessage } from "formik";
import { connect } from "react-redux";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class TruckTypeSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      vehicleType: [],
      selectedVehicleType: "",
    };
  }

  componentDidMount = () => {
    // console.log("mounted");
    this.fetchVehicleType();
  };

  fetchVehicleType = () => {
    this.props.fetchAllVehicleTypes(0, 45, "", (res) => {
      // console.log(res);
      let vehicleType = [];
      if (res.data?.docs.length > 0) {
        res.data.docs.forEach((ele) => {
          vehicleType.push({
            label: ele.label,
            value: ele._id,
          });
        });
      }
      this.setState({ vehicleType: vehicleType });
    });
  };

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    const customStyles = {
      indicatorsContainer: (style) => {
        return {
          ...style,
          backgroundColor: "#8e9af8",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          backgroundColor: this.props.mode ? "#9d9d9d" : "#8e9af8",
        };
      },
    };
    return (
      <React.Fragment>
        <FormGroup className="select2-container required darkAgreementVehicleCharge">
          <Label className="col-form-label required-field light__span">
            {userLang?.common?.VEHICLE_TYPE}
          </Label>
          <Select
            value={this.props.val}
            name="vehicleType"
            styles={customStyles}
            onChange={(e) => {
              this.props.setFieldValue("vehicleType", e);
            }}
            options={this.state.vehicleType}
            classNamePrefix="select2-selection"
            id="vehicleType"
            onBlur={this.props.handleBlur}
            className={
              this.props.errors.vehicleType && this.props.touched.vehicleType
                ? "input-error"
                : null
            }
          />
          <ErrorMessage name="vehicleType" component="span" className="error" />
        </FormGroup>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  indents: state.Indent.indents,
});

export default connect(mapStateToProps, {
  fetchAllVehicleTypes,
})(TruckTypeSelect);
