import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Container, CardBody } from "reactstrap";
import moment from "moment";
//actions
import { getInvoiceList } from "../../store/baxter-orderInvoice/actions";
import {
  getWeightOfInvoiceItems,
  FetchBaxLoadOptimizationData,
} from "../../store/baxter-loadOptimization/actions";
import {
  VehicleIcon1,
  VehicleIcon2,
  VehicleIcon3,
  VehicleIcon4,
} from "../../pages/LoadOptimization/VehicleIcon";
// component
import Index from "../../components/Common/DataTableNeo/Index";
import {
  AdminHeaderData,
  getLocalStorage,
  SimulationHeaderData,
} from "./RowHeaders/RowHeaders";
import { Button, Card } from "reactstrap";
import { CheckBoxItem } from "../../pages/baxter_orderInvoiceList/CheckBoxComponent";
import OptimizationHeader from "./optimizationHeader";
import { toPascalCase } from "../../components/Common/utils";
import { NotificationManager } from "react-notifications";
import { fetchVehicleTypesList } from "../../store/vehicleType/actions";
import { WaveLoader } from "../../components/Common/Loader/LoaderIndex";
import plusIcon from "../baxter_orders/assets/plus.png";
import plusDarkIcon from "../baxter_orders/assets/plusDark.png";
import minusIcon from "../baxter_orders/assets/minus.png";
let VehicleIconsArr = [1, 2, 3, 4];
export const randomVehiclesTypesIcons = () => {
  let random = Math.round(Math.random() * VehicleIconsArr.length);
  return random;
};

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var searchValues = {};
let pathName;
let key;
let keyOffset;
const sortInvoiceOption = [
  { label: "All Invoice", value: "" },
  { label: "Pending Invoice", value: true },
];
class LoadOptimization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataLoading: false,
      offset: 0,
      currentInvoice: "",
      searchFilters: {},
      modalView: false,
      topFilters: true,
      selectedInvoices: new Map(),
      selectedInvoicesData: [],
      selectedInvoiceSimulation: [],
      selectedInvoicesForDisplay: [],
      invoiceWeightForSelected: [],
      selectedInvoiceStates: false, 
      sortInvoiceVal: sortInvoiceOption[1],
      selectedCity: "",
      customerCounts: {},
      optimizedShow: false,
      weightOfInvoiceItem: "",
      invoiceListData: "",
      cities: "",
      selectedCustomer: "",
      invoiceListUpdatedData: "",
      filteredSelectedInvoice: "",
      vehicleTypeData: "",
      randomNumer: "",
      resData: "",
      runSimulationPayload: "",
      currentIndex: "",
    };
  }

  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }

  runSimulation = () => {
    const selectedInvoiceData = [];
    const { weightOfInvoiceItem } = this.state;
    let randomNumer = randomVehiclesTypesIcons();

    this.setState(
      { optimizedShow: true, randomNumer: randomNumer, dataLoading: true },
      () => {
        for (let data of this.state.selectedInvoices.values()) {
          console.log('invoicessss', this.state.selectedInvoices);
          // console.log(data?.legalInvoiceNo, data, "legalInvoiceNom");
          selectedInvoiceData.push(data);
        }
        console.log( selectedInvoiceData,"filteredSelectedInvoice");
        if (
          weightOfInvoiceItem &&
          Array.isArray(weightOfInvoiceItem) &&
          weightOfInvoiceItem.length
        ) {
          let filteredSelectedInvoice = weightOfInvoiceItem.filter((item1) => {
              const hasMatchingItem = selectedInvoiceData?.some((item2) =>
              { 
                console.log('item2', item2);
                return item2?.legalInvoiceNo === item1?.invoiceNo
              });
              console.log('checkCondition', hasMatchingItem);
              console.log('invoice', item1?.invoiceNo);
              console.log('item1', item1);
              console.log('selectedInvoicesForSimulation', this.state.selectedInvoices)
              return hasMatchingItem;
          });
          
          this.setState(
            { filteredSelectedInvoice: filteredSelectedInvoice },
            () => {
              if (this.state.filteredSelectedInvoice) {
                console.log('filteredSelctedInvoice', this.state.filteredSelectedInvoice);
                const { filteredSelectedInvoice } = this.state;
                let runSimulationPayload = [];
                filteredSelectedInvoice.map((invoice) => {
                  const invoiceNo = invoice?.invoiceNo;
                  const totalWeight = invoice?.totalWeightOfInvoice;

                  invoice &&
                    invoice.invoiceItemWiseInfo.map((item) => {
                      const itemId = item?._id;
                      const weight = item?.weight;
                      console.log('itemsPayload', item);
                      console.log('weightPayload', itemId, weight);
                      
                      item.products.map((product) => {
                        const h = product?.hInCMS;
                        const l = product?.lInCMS;
                        const w = product?.wInCMS;
                        const weightPerCase = product.weightPerCaseInKGS;
                        runSimulationPayload.push({
                          width: w,
                          length: l,
                          height: h,
                          weight: weightPerCase,
                        });

                        // console.log("Product", runSimulationPayload);
                        this.setState(
                          {
                            runSimulationPayload: runSimulationPayload,
                          },
                          () => {}
                        );
                      });

                      // console.log("---");
                    });

                  // console.log("===");
                });
              }
            }
          );
          // console.log(filteredSelectedInvoice,"filteredSelectedInvoice");
        }
        this.setState({
          dataLoading: false,
        });
      }
    );
  };
  LoadOptimizationfetchData = () => {
    const { runSimulationPayload, vehicleTypeData } = this.state;
    console.log('vehicleTypeData', vehicleTypeData);
    console.log('runSimulationPayload', runSimulationPayload)
    let vehicleTyData = [];
    vehicleTypeData &&
      vehicleTypeData.forEach((ele) => {
        vehicleTyData.push({
          width: ele?.value?.width,
          length: ele?.value?.length,
          height: ele?.value?.height,
          maxWeight: ele?.value?.maxWeight,
        });
      });

      // const filteredrunSimulationPayloadArray = runSimulationPayload.filter((item) => {
      //   return (
      //     item?.width !== undefined ||
      //     item?.length !== undefined ||
      //     item?.height !== undefined ||
      //     item?.weight !== undefined
      //   );
      // });
    // console.log(vehicleTyData, "io");
    if (runSimulationPayload) {
      let payloadValue = {
        containerData: vehicleTyData,
        itemData: [...runSimulationPayload],
      };
      // console.log("dataTest",payloadValue)
      this.setState({
        loading: true,
      });
      this.props.FetchBaxLoadOptimizationData(payloadValue, (res) => {
        if (res && res.status === 200) {
          NotificationManager.success(res?.message, "Success");

          this.setState({
            resData: res?.data,
            loading: false,
          });
        } else {
          NotificationManager.error(res?.message, "Error");
          this.setState({
            resData: "",
            loading: false,
          });
        }
      });
    }
  };
  componentDidMount() {
    offset = 0;
    this.getAllData();
    this.fetchVehicleTypesList();
  }

  fetchVehicleTypesList = () => {
    this.props.fetchVehicleTypesList(offset, (res) => {
      // console.log("res", res?.data?.docs);
      let vehicleTypeData = [];
      if (res?.data?.length > 0) {
        res.data.docs.forEach((ele) => {
          vehicleTypeData.push({
            label: ele.label,
            id: ele._id,
            value: {
              width: ele?.specs?.width !== undefined ? ele?.specs?.width : 0,
              length: ele?.specs?.length !== undefined ? ele?.specs?.length : 0,
              height: ele?.specs?.height !== undefined ? ele?.specs?.height : 0,
              maxWeight:
                ele?.specs?.weight !== undefined ? ele?.specs?.weight : 0,
            },
          });
        });
        // console.log("ele data1", vehicleTypeData);

        let vehicleTypeDt = vehicleTypeData.filter(
          (ele, i) => vehicleTypeData.indexOf(ele?.value?.id) !== i
        );
        const filteredvehicleTypeDtArray = vehicleTypeDt.filter((item) => {
          if(item){
            return (
              item?.value?.width !== 0 ||
              item?.value?.length !== 0 ||
              item?.value?.height !== 0 ||
              item?.value?.maxWeight !== 0
            );
          }
        
        });
        
        // console.log(filteredArray);
        this.setState({
          vehicleTypeData: filteredvehicleTypeDtArray,
        });
      }
    });
  };

  getAllData = () => {
    this.setState({ dataLoading: true }, () => {});

    this.props.getInvoiceList(
      {
        offset: 0,
        limit: 10,
        // nonInvoiceGeneratedOrders: true,
      },
      "",
      this.state.topFilters,
      this.state.searchFilters,
      (res) => {
        if (res) {
          this.setState({
            dataLoading: false,
            invoiceListData: res,
            selectedInvoices: new Map(),
          });
          this.getWeightOfInvoiceItems();
          this.getCityCounts(res);
        }
      }
    );
  };

  getInvoiceDataFilter = () => {
    // console.log(this.state.selectedCity, this.state.selectedCustomer, "11");
    const invoiceAllList = this.state.invoiceListData?.data?.docs;

    let filtereddata = invoiceAllList.filter((ele, index, array) => {
      return (
        ele?.billToDetails?.billedToCity === this.state.selectedCity &&
        (ele?.shipToDetails?.shipToName ===
            this.state.selectedCustomer)
      );
    });
    // console.log(filtereddata, "filtereddata");
    if (Array.isArray(filtereddata)) {
      let legalInvoiceArray =
        filtereddata && filtereddata?.map((ele) => ele?.legalInvoiceNo);

      this.props.getWeightOfInvoiceItems(
        {
          legalInvoiceArray: legalInvoiceArray,
        },
        (invoiceWeights) => {
          this.setState({ invoiceWeightsApi: invoiceWeights, resData: "" });
          const totalWeights = [];
          invoiceWeights.data.forEach((invoiceItem) => {
            const invoiceNo = invoiceItem ? Object.keys(invoiceItem)[0] : ""; // Get the invoice number
            const invoiceDetails = invoiceItem[invoiceNo];

            if (
              invoiceNo &&
              invoiceDetails?.totalWeightOfInvoice !== undefined
            ) {
              // totalWeights[invoiceNo] = invoiceDetails.totalWeightOfInvoice; // Store the totalWeight in the object using invoiceNo as the key
              totalWeights.push({
                invoiceNo: invoiceNo,
                totalWeightOfInvoice: invoiceDetails?.totalWeightOfInvoice,
                invoiceItemWiseInfo: invoiceDetails?.invoiceItemWiseInfo,
              });
            }
          });
          // let totalWeightsVal = Object.values(totalWeights);
          console.log('totalWeights', totalWeights);
          this.setState(
            {
              weightOfInvoiceItem: [...this.state.weightOfInvoiceItem, ...totalWeights],
              dataLoading: false,
              invoiceListUpdatedData: filtereddata,


              // totalWeightsVal: totalWeightsVal,
            },
            () => {
             
            }
          );

         
        }
      );
    } else {
      this.setState({
        dataLoading: false,
      });
    }
  };
  getCityCounts = (getInvoiceData) => {
    if (!getInvoiceData || !getInvoiceData.data) return {};
    const cityCounts = getInvoiceData.data.docs.reduce((acc, item) => {
      const city = item?.billToDetails?.billedToCity;
      acc[city] = (acc[city] || 0) + 1;
      return acc;
    }, {});
    this.setState({ cities: cityCounts }, () => {
      this.firstCustomerCity();
    });
  };

  firstCustomerCity = () => {
    if (Object.keys(this.state.cities).length) {
      const keys = Object.keys(this.state.cities);
      if (keys?.length > 0) {
        const firstCity = keys[0];
        const firstCityValue = this.state.cities[firstCity];
        this.setState({ selectedCity: firstCity, dataLoading: true }, () => {
          if (this.state.selectedCity) {
            const filteredData = this.state.invoiceListData?.data?.docs.filter(
              (item) => item?.billToDetails?.billedToCity === firstCity
            );
            //customer Counts
            const customerCounts = filteredData.reduce((acc, item) => {
              const customer = item?.shipToDetails?.shipToName;

              acc[customer] = (acc[customer] || 0) + 1;
              return acc;
            }, {});

            this.setState({ customerCounts }, () => {
              const firstCustomer = Object.keys(customerCounts)[0];

              this.setState({ selectedCustomer: firstCustomer }, () => {
                if (this.state.selectedCity && this.state.selectedCustomer) {
                  this.getInvoiceDataFilter();
                } else {
                  this.setState({
                    dataLoading: false,
                  });
                }
              });
            });
          }
        });
      }
    } else {
      this.setState({ customerCounts: {}, dataLoading: false }, () => {
        this.getInvoiceDataFilter();
      });
    }
    // this.setState({dataLoading:false})
  };
  getCustomerCounts = (selectedCity, selectedCustomer) => {
    // const { getInvoiceData } = this.props;
    // if (!getInvoiceData || !getInvoiceData.data) return {};
    // const filteredData = getInvoiceData.data.docs.filter(
    //   (item) => item.billToDetails.billedToCity === selectedCity
    // );
    // const customerCounts = filteredData.reduce((acc, item) => {
    //   const customer = item.shipToDetails.shipToName;
    //   console.log("customer", customer);
    //   acc[customer] = (acc[customer] || 0) + 1;
    //   return acc;
    // }, {});
    // return customerCounts;
  };

  getWeightOfInvoiceItems = (ele) => {};
  // ********************************
  onClickCity = (city) => {
    this.setState({ selectedCity: city, dataLoading: true }, () => {
      if (this.state.selectedCity) {
        const filteredData = this.state.invoiceListData.data.docs.filter(
          (item) =>
            item?.billToDetails?.billedToCity === this.state.selectedCity
        );

        const customerCounts = filteredData.reduce((acc, item) => {
          const customer = item?.shipToDetails?.shipToName;

          acc[customer] = (acc[customer] || 0) + 1;
          return acc;
        }, {});
        // console.log(customerCounts, "cust");
        this.setState({ customerCounts }, () => {
          if (this.state.customerCounts) {
            const firstCustomer = Object.keys(customerCounts)[0];

            this.setState({ selectedCustomer: firstCustomer }, () => {
              if (this.state.selectedCity && this.state.selectedCustomer) {
                this.getInvoiceDataFilter();
              } else {
                this.setState({ dataLoading: false });
              }
            });
          }
        });
      }
    });
  };
  // ********************************
  onClickCustomer = (customer) => {
    this.setState({ selectedCustomer: customer, dataLoading: true }, () => {
      if (this.state.selectedCustomer) {
        this.getInvoiceDataFilter();
      } else {
        this.setState({ dataLoading: false });
      }
    });
  };

  handleChange = (dateValues) => {
    // console.log("date values", dateValues);
    searchValues["invoiceStartDate"] =
      dateValues[0] !== null
        ? dateValues[0]
          ? moment(dateValues[0]).format("YYYY-MM-DD")
          : moment(dateValues).format("YYYY-MM-DD")
        : null;
    searchValues["invoiceEndsDate"] =
      dateValues[1] !== null
        ? moment(dateValues[1]).format("YYYY-MM-DD")
        : null;
    pathName = window.location.pathname;
    key = `mySearchFilter_${pathName}`;
    keyOffset = `myOffsetFilter_${pathName}`;
    this.setState({ searchFilters: searchValues }, () => {
      offset = 0;
      window.localStorage.setItem(
        key,
        JSON.stringify(this.state.searchFilters)
      );
      window.localStorage.setItem(keyOffset, offset);
      this.getAllData();
    });
  };
  onSelectValue = (selectKey, selectValue) => {
    searchValues[selectKey] = selectValue;
    this.setState({ searchFilters: searchValues }, () => {
      offset = 0;
      this.getAllData();
    });
  };

  reset = () => {
    offset = 0;
    searchValues = {};
    this.setState({ searchFilters: {}, topFilters: "true" }, () => {
      let selectedInvoices = this.state.selectedInvoices;
      if (selectedInvoices) {
        selectedInvoices.clear();
      }

      this.setState(
        {
          selectedInvoices: selectedInvoices,
          selectedInvoicesData: [],
          selectedInvoicesForDisplay: [],
          sortInvoiceVal: sortInvoiceOption[1],
          optimizedShow: false,
          resData: "",
          currentIndex: "",
        },
        () => {
          this.getAllData();
        }
      );
    });
  };

  SelectedInvoicesSimulation = (selectedInvoice, index, array1, rows) => {
    // console.log('selectedInvoicesss', selectedInvoice, index, array1);
    // console.log('rows', rows);

    // console.log('weightOfInvoiceItem', this.state.weightOfInvoiceItem);
    // console.log('prevState', this.state.selectedInvoicesForDisplay)
    // console.log('[selectedInvoice]', selectedInvoice)
    this.setState((prevState) => {
      const isSelected = prevState.selectedInvoicesForDisplay.some(
        (invoice) => invoice.legalInvoiceNo === selectedInvoice.legalInvoiceNo
      );
      this.setState({selectedInvoiceStates: isSelected})
      if (isSelected) {
        return {
          selectedInvoicesForDisplay: prevState.selectedInvoicesForDisplay.filter(
            (invoice) => invoice.legalInvoiceNo !== selectedInvoice.legalInvoiceNo
          ),
        };
      } else {
        const matchingRow = rows.find(
          (row) => row.legalInvoiceNo === selectedInvoice.legalInvoiceNo
        );

        if (matchingRow) {
          const newItem = {
            ...matchingRow,
            _id: selectedInvoice.legalInvoiceNo,
          };
          return {
            selectedInvoicesForDisplay: [
              ...prevState.selectedInvoicesForDisplay,
              newItem,
            ],
          };
        } else {
          return prevState;
        }
      }
    });
  };

  SelectedInvoices = (ele, e) => {
    let selectedInvoices = this.state.selectedInvoices;
    if (selectedInvoices) {
      if (e.target.checked) {
        selectedInvoices.set(ele?._id, ele);
      } else if (
        !e.target.checked &&
        this.state.selectedInvoices.has(ele?._id)
      ) {
        selectedInvoices.delete(ele?._id);
      }
      this.setState({ selectedInvoices: selectedInvoices }, () => {
        this.runSimulation();
      });
    }
  };
  removeSelectedInvoices = (id) => {
    let selectedInvoicesData = this.state.selectedInvoicesData;
    let selectedInvoices = this.state.selectedInvoices;
    if (id) {
      selectedInvoices.delete(id);
      selectedInvoicesData = selectedInvoicesData.filter(
        (item) => item?._id !== id
      );
    }
    this.setState(
      {
        selectedInvoices: selectedInvoices,
        selectedInvoicesData: selectedInvoicesData,
      },
      () => {
        this.getAllData();
      }
    );
  };

  sortColumn = (item) => {
    if (item) {
      searchValues["sortBy"] = item;
      this.setState({ searchFilters: searchValues }, () => {
        this.getAllData();
      });
    }
  };
  handleSortInvoice = (sortData) => {
    this.setState(
      (prevState) => ({
        sortInvoiceVal: sortData,
        topFilters: sortData?.value,
      }),
      () => {
        window.localStorage.setItem(
          "myFilterData",
          JSON.stringify(this.state.searchFilters)
        );
        window.localStorage.setItem("offset", offset);
        this.getAllData();
      }
    );
  };

  render() {
    let rows = [];
    if (
      Array.isArray(this.state.invoiceListUpdatedData) &&
      Array.isArray(this.state.weightOfInvoiceItem)
    ) {
      rows = this.state.invoiceListUpdatedData
        .filter((ele, index, array) => {
          return (
            ele?.billToDetails?.billedToCity ===
              this.state.selectedCity &&
            (ele?.shipToDetails?.shipToName ===
                this.state.selectedCustomer)
          );
        })
        .map((ele, index, array1) => {
          const isSelected = this.state.selectedInvoicesForDisplay.some(
            (invoice) => invoice.legalInvoiceNo === ele.legalInvoiceNo
          );
          return {
          Select: (
            <span
              onClick={() => {
                this.SelectedInvoicesSimulation(ele, index, array1, rows);
              }}
              style={{
                cursor: "pointer",
                fontSize: "24px",
                paddingLeft: "40px",
                color: isSelected ? 'red' : 'green'
              }}
            >
              {isSelected ? "-" : "+"}
            </span>
            // <CheckBoxItem
            //   key={ele._id}
            //   item={ele}
            //   selectedInvoices={this.state.selectedInvoices}
            //   SelectedInvoices={this.SelectedInvoicesSimulation}
            //   isDisabled={
            //     ele?.isAvailableForTripCreation == false ||
            //     ele?.isAvailableForTripCreation == ""
            //       ? true
            //       : false
            //   }
            // />
          ),
          sl: index + 1,
          legalInvoiceNo: ele?.legalInvoiceNo ? ele?.legalInvoiceNo : "N/A",
          invoiceWeight:
            this.state.weightOfInvoiceItem &&
            this.state.weightOfInvoiceItem.length > 0
              ? this.state.weightOfInvoiceItem.map((data) => {
                  if (data?.invoiceNo === array1[index]?.legalInvoiceNo) {
                    return Number(data?.totalWeightOfInvoice).toFixed(2);
                  }
                })
              : "N/A",
          // invoiceWeight: this.getInvoiceWeight(array1[index]?.legalInvoiceNo, weightOfInvoiceItem),
          InvoiceDate: ele?.InvoiceDate
            ? moment(ele?.InvoiceDate).format("DD-MMM-YYYY")
            : "N/A",

          orderNumber: ele?.order?.orderNumber
            ? ele?.order?.orderNumber
            : "N/A",
          orderCo: ele?.order?.orderCo ? ele?.order?.orderCo : "N/A",
          orderType: ele?.order?.orderType ? ele?.order?.orderType : "N/A",
          businessUnit: ele?.order?.businessUnit
            ? ele?.order?.businessUnit
            : "N/A",
          // action: this.actions(ele),
        }});
    }
    let selectedInvoicee = [];
    if (Array.isArray(this.state.selectedInvoicesForDisplay)) {
      selectedInvoicee = this.state.selectedInvoicesForDisplay.map(
        (ele, index) => ({
          Select: (
            <CheckBoxItem
              key={index}
              item={ele}
              selectedInvoices={this.state.selectedInvoices}
              SelectedInvoices={this.SelectedInvoices}
              isDisabled={
                ele?.isAvailableForTripCreation == false ||
                ele?.isAvailableForTripCreation == ""
                  ? true
                  : false
              }
            />
          ),
          sl: index + 1,
          legalInvoiceNo: ele?.legalInvoiceNo ? ele?.legalInvoiceNo : "N/A",
          invoiceWeight: ele?.invoiceWeight ? ele?.invoiceWeight : "N/A",
          // invoiceWeight: this.getInvoiceWeight(array1[index]?.legalInvoiceNo, weightOfInvoiceItem),
          InvoiceDate: ele?.InvoiceDate
            ? moment(ele?.InvoiceDate).format("DD-MMM-YYYY")
            : "N/A",

          orderNumber: ele?.orderNumber ? ele?.orderNumber : "N/A",
          orderCo: ele?.order?.orderCo ? ele?.order?.orderCo : "N/A",
          orderType: ele?.order?.orderType ? ele?.order?.orderType : "N/A",
          businessUnit: ele?.businessUnit ? ele?.businessUnit : "N/A",
          // action: this.actions(ele),
        })
      );
    }
    const bodyElement = document.querySelector("body");
    const darkState = bodyElement.classList.contains("dark__mode");
    return (
      <>
        {getLocalStorage()}
        <Container
          aria-hidden="true"
          fluid
          className={`${
            darkState ? "darkBackgroundList mobilepPadding" : "mobilepPadding"
          }`}
        >
          <OptimizationHeader
            {...this.props}
            selectedTranspoterValues={this.state.selectedTranspoterValues}
            transporterOptions={this.state.transpoter}
            onChangeTranspoter={this.onChangeTranspoter}
            selectedHopValues={this.state.selectedHopValues}
            hopOptions={this.state.hops}
            onChangeHop={this.onChangeHop}
            datevalues={this.state.datevalues}
            handleChange={this.handleChange}
            onReset={this.reset}
            filtersTranspoter={this.filtersTranspoter}
            filtersHops={this.filtersHops}
            onSelectValue={this.onSelectValue}
          />

          {/* <div className="OptParentDiv">
            <div className="OptChildSub">
              <span className="Heading">Cities</span>
              <div className="OptChild">
                {Object.entries(this.state.cities).map(
                  ([cityKey, cityValue]) => (
                    <Card
                      className="OptCard"
                      key={cityKey}
                      onClick={() => {
                        let selectedInvoices = this.state.selectedInvoices;
                        if (selectedInvoices) {
                          selectedInvoices.clear();
                        } 
                        this.setState(
                          { selectedInvoices, selectedCity: cityKey },
                          () => {
                            this.onClickCity(cityKey); 
                          }
                        );
                      }}
                      style={{
                        backgroundColor:
                          cityKey === this.state.selectedCity ? "#DFF9DA" : "",
                        cursor: "pointer",
                      }}
                    >
                      <span>
                        <span className="h">{cityKey} &nbsp;-&nbsp;</span>
                        <span>{cityValue}</span>
                      </span>
                    </Card>
                  )
                )}
              </div>
            </div>
            <div className="OptChildSub">
              <span className="Heading">Customer Names</span>
              <div className="OptChild">
                {Object.entries(this.state.customerCounts).map(
                  ([customer, custValue]) => (
                    <Card
                      className="OptCard"
                      key={customer}
                      style={{
                        backgroundColor:
                          customer === this.state.selectedCustomer
                            ? "#DFF9DA"
                            : "",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        let selectedInvoices = this.state.selectedInvoices;
                        if (selectedInvoices) {
                          selectedInvoices.clear();
                        }
                        this.setState(
                          { selectedInvoices, selectedCustomer: customer },
                          () => {
                            this.onClickCustomer(customer);
                          }
                        );
                      }}
                    >
                      <span>
                        <span className="h">{customer} &nbsp;-&nbsp;</span>
                        <span>{custValue}</span>
                      </span>
                    </Card>
                  )
                )}
              </div>
            </div>
            <div className="OptChild1">
              <div className="invoiceList">
                <Index
                  {...this.props}
                  headers={AdminHeaderData}
                  data={rows}
                  fetchId={this.props.fetchId}
                  reset={this.reset}
                  searchValueAssignerFunction={this.searchValueAssignerFunction}
                  getAllData={this.getAllDataReset}
                  dataLength={90}
                  rowHeight={"68px"}
                  dataLoading={this.state.dataLoading}
                  sortColumn={this.sortColumn}
                  classList="3"
                  textInput="inputIndex"
                  resetCol="3"
                />
              </div>
              <Card>
                <CardBody className="LoadGridHeight">
                  <Row>
                    <Col xs={12}>
                      <span
                        className="fontRoboto15 spanAddItemName"
                        style={{
                          color: "#05284E",
                        }}
                      >
                        Selected Invoice
                      </span>
                    </Col>
                    <Col xs={12}>
                      <hr className="mt-3 Container-hr-line" />
                    </Col>
                  </Row>
                  <Index
                    {...this.props}
                    headers={SimulationHeaderData}
                    data={selectedInvoicee}
                    fetchId={this.props.fetchId}
                    reset={this.reset}
                    searchValueAssignerFunction={
                      this.searchValueAssignerFunction
                    }
                    getAllData={this.getAllDataReset}
                    dataLength={90}
                    rowHeight={"68px"}
                    dataLoading={this.state.dataLoading}
                    sortColumn={this.sortColumn}
                    classList="3"
                    textInput="inputIndex"
                    resetCol="3"
                  />
                </CardBody>
              </Card>
              <Card>
                <CardBody className="LoadGridHeight">
                  <div></div>
                  <Row className="rowPadding">
                    <Col x={12}>
                      <div className="float-right">
                        <Button
                          className="RunStimulationButton  fontRoboto1541"
                          onClick={() => this.LoadOptimizationfetchData()}
                          style={{
                            height: "40px",
                            backgroundColor: this.state.optimizedShow
                              ? ""
                              : "#05284e",
                          }}
                          disabled={!this.state.selectedInvoices?.size}
                        >
                          Run Simulation
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <span
                        className="fontRoboto15 spanAddItemName"
                        style={{
                          color: "#05284E",
                        }}
                      >
                        Optimized Result
                      </span>
                    </Col>
                    <Col xs={12}>
                      <hr className="mt-3 Container-hr-line" />
                    </Col>
                  </Row>
                  {this.state.loading ? (
                    <WaveLoader />
                  ) : (
                    <Card className="shadow optimizerCard pl-2 pr-2">
                      {this.state.optimizedShow &&
                      this.state.resData?.reportList ? (
                        <>
                          <div>
                            {this.state.resData?.reportList ? (
                              this.state.resData.reportList.map((ele, i) => {
                                return (
                                  <>
                                    <div
                                      key={i}
                                      style={{
                                        backgroundColor: "#F1F5F7",
                                        height: "70px",
                                      }}
                                      className="text-left mt-2"
                                    >
                                      <Row>
                                        <Col xs={6} md={9} lg={10}>
                                          <Row className="paddingContainer">
                                            <Col xs={12}>
                                              <span className="fontRoboto127">
                                                Vehicle-
                                              </span>
                                              <span className="fontRoboto127">
                                                {i + 1}
                                              </span>
                                            </Col>
                                            <Col xs={12}>
                                              <span className="fontRoboto12">
                                                {toPascalCase(
                                                  ele?.containerTypeName
                                                )}{" "}
                                                ({ele?.container?.length}in X{" "}
                                                {ele?.container?.width}in X{" "}
                                                {ele?.container?.height}in X{" "}
                                                {ele?.container?.weight}kg)
                                                
                                              </span>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col xs={6} md={3} lg={2}>
                                          <Row>
                                            <Col xs={8}>
                                              <span>
                                                {(this.state.randomNumer ===
                                                  0 ||
                                                  this.state.randomNumer ===
                                                    4) && <VehicleIcon1 />}
                                                {this.state.randomNumer ===
                                                  1 && <VehicleIcon2 />}
                                                {this.state.randomNumer ===
                                                  2 && <VehicleIcon3 />}
                                                {this.state.randomNumer ===
                                                  3 && <VehicleIcon4 />}
                                              </span>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </div>
                                  </>
                                );
                              })
                            ) : (
                              <span></span>
                            )}
                          </div>
                        </>
                      ) : (
                        <span
                          className="fontRoboto1541 align-items-center d-flex justify-content-center light__span"
                          style={{
                            color: "#05284E",
                            backgroundColor: "#F1F5F7",
                          }}
                        >
                          Run Simulation to Show Loading Plan
                        </span>
                      )}
                    </Card>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>  */}

          <Row>
            <Col xl="12" className="p-1">
              <div>
                <section class="column-container">
                  <Row className="mt-3">
                    <Col lg={5} md={6} xs={12} sm={12} className="mt-0">
                      <Card style={{ height: "1000px", overflowY: "scroll" }}>
                        <CardBody>
                          <Row>
                            <Col xs={12}>
                              <span
                                className="fontRoboto15 spanAddItemName"
                                style={{
                                  color: "#05284E",
                                }}
                              >
                               Select City
                              </span>
                            </Col>
                            <Col xs={12}>
                              <hr className="mt-3 Container-hr-line" />
                            </Col>
                          </Row>
                          <div>
                            {Object.entries(this.state.cities).map(
                              ([cityKey, cityValue], index) => (
                                <div className="accordion">
                                  <div
                                    className="accordion-item barDropDark"
                                    style={{
                                      backgroundColor:
                                        cityKey === this.state.selectedCity
                                          ? "#DFF9DA"
                                          : "",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <>
                                      <div
                                        className="accordion-item-header"
                                        key={index}
                                        onClick={() => {
                                          if (
                                            this.state.currentIndex === index
                                          ) {
                                            this.setState(
                                              {
                                                currentIndex: "",
                                              },
                                              () => {}
                                            );
                                          } else {
                                            this.setState(
                                              {
                                                currentIndex: index,
                                              },
                                              () => {
                                                let selectedInvoices =
                                                  this.state.selectedInvoices;
                                                if (selectedInvoices) {
                                                  selectedInvoices.clear();
                                                } // Create an empty Set to clear the state
                                                this.setState(
                                                  {
                                                    selectedInvoices,
                                                    selectedCity: cityKey,
                                                  },
                                                  () => {
                                                    this.onClickCity(cityKey); // Call the function after state is updated
                                                  }
                                                );
                                              }
                                            );
                                          }
                                        }}
                                      >
                                        <Row style={{ width: "100%" }}>
                                          <Col xs={10}>
                                            <Row className="p-2">
                                              <span className="light__span">
                                                <span className="h">
                                                  {cityKey} &nbsp;-&nbsp;
                                                </span>
                                                <span>{cityValue}</span>
                                              </span>
                                            </Row>
                                          </Col>
                                          <Col xs={2}>
                                            <div className="">
                                              {this.state.currentIndex ===
                                              index ? (
                                                <Button
                                                  outline
                                                  color="primary"
                                                  className="accordionIcon"
                                                  onClick={() => {
                                                    this.setState({
                                                      currentIndex: "",
                                                    });
                                                  }}
                                                >
                                                  <img
                                                    style={{ margin: "-4px" }}
                                                    src={
                                                      darkState
                                                        ? minusIcon
                                                        : minusIcon
                                                    }
                                                    alt="exp"
                                                  />
                                                </Button>
                                              ) : (
                                                <Button
                                                  outline
                                                  color="primary"
                                                  className="accordionIcon"
                                                  onClick={() => {
                                                    this.setState({
                                                      currentIndex: index,
                                                    });
                                                  }}
                                                >
                                                  <img
                                                    style={{ margin: "-4px" }}
                                                    src={
                                                      darkState
                                                        ? plusDarkIcon
                                                        : plusIcon
                                                    }
                                                    alt="exp"
                                                  />
                                                </Button>
                                              )}
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                      <div class="accordion-item-body">
                                        {this.state.currentIndex === index && (
                                          <div class="accordion-item-body-content darkCardStyle">
                                            <section>
                                              {Object.entries(
                                                this.state.customerCounts
                                              ).map(([customer, custValue]) => (
                                                <Card
                                                  className="OptCard"
                                                  key={customer}
                                                  style={{
                                                    backgroundColor:
                                                      customer ===
                                                      this.state
                                                        .selectedCustomer
                                                        ? "#DFF9DA"
                                                        : "",
                                                    cursor: "pointer",
                                                    padding: "1%",
                                                  }}
                                                  onClick={() => {
                                                    let selectedInvoices =
                                                      this.state
                                                        .selectedInvoices;
                                                    if (selectedInvoices) {
                                                      selectedInvoices.clear();
                                                    }
                                                    this.setState(
                                                      {
                                                        selectedInvoices,
                                                        selectedCustomer:
                                                          customer,
                                                      },
                                                      () => {
                                                        this.onClickCustomer(
                                                          customer
                                                        );
                                                      }
                                                    );
                                                  }}
                                                >
                                                  <span>
                                                    <span className="h">
                                                      {customer} &nbsp;-&nbsp;
                                                    </span>
                                                    <span>{custValue}</span>
                                                  </span>
                                                </Card>
                                              ))}
                                            </section>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={7} md={6} xs={12} sm={12} >
                      <div>
                      <div className="optiInvoiceList">
                        <Index
                          {...this.props}
                          headers={AdminHeaderData}
                          data={rows}
                          fetchId={this.props.fetchId}
                          reset={this.reset}
                          searchValueAssignerFunction={
                            this.searchValueAssignerFunction
                          }
                          getAllData={this.getAllDataReset}
                          dataLength={90}
                          rowHeight={"68px"}
                          dataLoading={this.state.dataLoading}
                          sortColumn={this.sortColumn}
                          classList="3"
                          textInput="inputIndex"
                          resetCol="3"
                        />
                      </div>
                      <Card>
                        <CardBody className="LoadGridHeight">
                          <Row>
                            <Col xs={12}>
                              <span
                                className="fontRoboto15 spanAddItemName"
                                style={{
                                  color: "#05284E",
                                }}
                              >
                                Selected Invoice
                              </span>
                            </Col>
                            <Col xs={12}>
                              <hr className="mt-3 Container-hr-line" />
                            </Col>
                          </Row>
                          <Index
                            {...this.props}
                            headers={SimulationHeaderData}
                            data={selectedInvoicee}
                            fetchId={this.props.fetchId}
                            reset={this.reset}
                            searchValueAssignerFunction={
                              this.searchValueAssignerFunction
                            }
                            getAllData={this.getAllDataReset}
                            dataLength={90}
                            rowHeight={"68px"}
                            dataLoading={this.state.dataLoading}
                            sortColumn={this.sortColumn}
                            classList="3"
                            textInput="inputIndex"
                            resetCol="3"
                          />
                        </CardBody>
                      </Card>
                      </div>
                    </Col>
                  </Row>
                  <Card>
                    <CardBody className="LoadGridHeight">
                      <Row className="rowPadding">
                        <Col x={12}>
                          <div className="float-right">
                            <Button
                              className="RunStimulationButton  fontRoboto1541"
                              onClick={() => this.LoadOptimizationfetchData()}
                              style={{
                                height: "40px",
                                backgroundColor: this.state.optimizedShow
                                  ? ""
                                  : "#05284e",
                              }}
                              disabled={!this.state.selectedInvoices?.size}
                            >
                              Run Simulation
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <span
                            className="fontRoboto15 spanAddItemName"
                            style={{
                              color: "#05284E",
                            }}
                          >
                            Optimized Result
                          </span>
                        </Col>
                        <Col xs={12}>
                          <hr className="mt-3 Container-hr-line" />
                        </Col>
                      </Row>
                      {this.state.loading ? (
                        <WaveLoader />
                      ) : (
                        <Card className="shadow optimizerCard pl-2 pr-2">
                          {this.state.optimizedShow &&
                          this.state.resData?.reportList ? (
                            <>
                              <div>
                                {this.state.resData?.reportList ? (
                                  this.state.resData.reportList.map(
                                    (ele, i) => {
                                      return (
                                        <>
                                          <div
                                            key={i}
                                            style={{
                                              backgroundColor: "#F1F5F7",
                                              height: "70px",
                                            }}
                                            className="text-left mt-2"
                                          >
                                            <Row>
                                              <Col xs={6} md={9} lg={10}>
                                                <Row className="paddingContainer">
                                                  <Col xs={12}>
                                                    <span className="fontRoboto127">
                                                      Vehicle-
                                                    </span>
                                                    <span className="fontRoboto127">
                                                      {i + 1}
                                                    </span>
                                                  </Col>
                                                  <Col xs={12}>
                                                    <span className="fontRoboto12">
                                                      {toPascalCase(
                                                        ele?.containerTypeName
                                                      )}{" "}
                                                      ({ele?.container?.length}
                                                      in X{" "}
                                                      {ele?.container?.width}
                                                      in X{" "}
                                                      {ele?.container?.height}
                                                      in X{" "}
                                                      {ele?.container?.weight}
                                                      kg)
                                                      {/* CONTAINER 32 FT MXL 14.5 MT (5,758cm x 2,352cm x
                                  2,385cm) */}
                                                    </span>
                                                  </Col>
                                                </Row>
                                              </Col>
                                              <Col xs={6} md={3} lg={2}>
                                                <Row>
                                                  <Col xs={8}>
                                                    <span>
                                                      {(this.state
                                                        .randomNumer === 0 ||
                                                        this.state
                                                          .randomNumer ===
                                                          4) && (
                                                        <VehicleIcon1 />
                                                      )}
                                                      {this.state
                                                        .randomNumer === 1 && (
                                                        <VehicleIcon2 />
                                                      )}
                                                      {this.state
                                                        .randomNumer === 2 && (
                                                        <VehicleIcon3 />
                                                      )}
                                                      {this.state
                                                        .randomNumer === 3 && (
                                                        <VehicleIcon4 />
                                                      )}
                                                    </span>
                                                  </Col>
                                                </Row>
                                              </Col>
                                            </Row>
                                          </div>
                                        </>
                                      );
                                    }
                                  )
                                ) : (
                                  <span></span>
                                )}
                              </div>
                            </>
                          ) : (
                            <span
                              className="fontRoboto1541 align-items-center d-flex justify-content-center light__span"
                              style={{
                                color: "#05284E",
                                backgroundColor: "#F1F5F7",
                              }}
                            >
                              Run Simulation to Show Loading Plan
                            </span>
                          )}
                        </Card>
                      )}
                    </CardBody>
                  </Card>
                </section>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    getInvoiceData: state?.invoiceListReducer?.getInvoiceListData,
    fetchId: state.invoiceListReducer?.fetchId,
    getWeightOfInvoicesitems:
      state?.weightOfInvoiceItemsReducer?.getWeightOfInvoiceItemsData,
    weightOfInvoiceItemsFetchId: state.weightOfInvoiceItemsReducer?.fetchId,
  };
};

export default connect(mapStateToProps, {
  getInvoiceList,
  getWeightOfInvoiceItems,
  fetchVehicleTypesList,
  FetchBaxLoadOptimizationData,
})(LoadOptimization);
