import { types } from "../actionTypes";
const initialState = {
    vehiclePlacementData: [],
    fetchId:"",
    vehiclePlacementExport:[]
};
export default (state = { initialState }, action) => {
  switch (action.type) {
    case types.VEHICLE_PLACEMENT_REPORT_SUCCESS:
      return { ...state, vehiclePlacementData: action.payload ,fetchId: "id" + new Date().getTime()};
      case types.VEHICLE_PLACEMENT_REPORT_EXPORT_SUCCESS:
      return { ...state, vehiclePlacementExport: action.payload};

    default:
      state = { ...state };
      break;
  }

  return state;
};
