import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import { fetchAllTransporterSearch } from "../../store/transporter/actions";
import { fetchTransporterPerformance } from "../../store/actions";
import { connect } from "react-redux";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  AdminHeaderData,
  searchBar,
  getLocalStorage,
} from "./RowHeaders/RowHeaders.js";
import QuickFilters from "./QuickFilters";
import Index from "../../components/Common/DataTableNeo/Index";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var darkMode
export class Reports_New extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoading : false,
      loading: false,
      FromDate: "",
      ToDate: "",
      offset: 0,
      transpoter: [],
      selectedTranspoterValues: [],
      filterTranspoter: "",
      limit: 10,
      transporterValues: [],
      searchvalue: "",
      disableSearch: true,
    };
    this.fromRef = React.createRef();
    this.toRef = React.createRef();
  }
  componentDidMount = () => {
    this.setState({dataLoading : true})
    this.getAllTransporterData()
    this.getAllData();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };

  getAllTransporterData = () => {
    let filtersHopsData ={
      offset:0,
      limit:10,
      q:""
    }
    this.fetchAllTransporterSearch(filtersHopsData)
  }
  // calling transpoter api
  fetchAllTransporterSearch = (filtersData) =>{
    this.props.fetchAllTransporterSearch(
      filtersData.offset,
      filtersData.limit,
      filtersData.q,
      (res) => {
        let transporter = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            transporter.push({
              value: ele._id ? ele._id : "",
              label: ele.name ? ele.name : "",
            });
          });
        }
        this.setState(
          {
            transpoter: transporter,
          },
          () => {}
        );
      }
      )
  }
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }
  getAllData = () => {
    this.setState({dataLoading : true})
    let limit = 10;
    let values = {
      transporter: this.state.transporterValues,
      FromDate: this.state.FromDate,
      ToDate: this.state.ToDate,
    };
    this.props.fetchTransporterPerformance(offset, limit, values, (res) => {
      this.setState({dataLoading : false})
    });
  };

  enterCheck = (event) => {
    if (event.key === "Enter") this.searchFilter();
  };
  // get transpoter name by filters
  filtersTranspoter=(e)=>{
    if (e?.length > 0) {
      let filtersData={
        offset:this.state.offset,
        limit:this.state.limit,
         q:e
      }
      this.fetchAllTransporterSearch(filtersData)
    }
    if (e?.length === 0) {
      let filtersData={
        offset:this.state.offset,
        limit:this.state.limit,
         q:""
      }
      this.fetchAllTransporterSearch(filtersData)
      }
  }
  handleChange = (dateValues) => {
    // console.log(dateValues)
    this.setState(
      {
        FromDate:dateValues[0]!== null ? moment(dateValues[0]).format("MM-DD-YYYY") : null,
        ToDate: dateValues[1] !== null ? moment(dateValues[1]).format("MM-DD-YYYY") : null,
      },
      () => {
        this.getAllData();
      }
    );
  };
  onChangeTranspoter = (selectedTranspoterValues) => {
    this.setState({ dataLoading : true})
    if (selectedTranspoterValues?.length <= 3) {
      this.setState({ selectedTranspoterValues }, () => {
        if (this.state.selectedTranspoterValues?.length <= 3) {
          var transporterValues =
            this.state.selectedTranspoterValues === null
              ? []
              : this.state.selectedTranspoterValues?.map((ele) => {
                return ele?.value;
              });
          this.setState({ transporterValues }, () => {
            // if (this.state.transporterValues.length >= 0) {
            this.getAllData();
            // }
          });
        } else if (this.state.selectedTranspoterValues?.length === undefined) {
          this.setState(
            {
              transporterValues: [],
              selectedTranspoterValues: [],
            },
            () => {
              this.getAllData();
            }
          );
        }
      });
    } else if (selectedTranspoterValues?.length === undefined) {
      this.setState(
        {
          transporterValues: [],
          selectedTranspoterValues: [],
        },
        () => {
          this.getAllData();
        }
      );
    }
  };
  handleChangePage = (event, val) => {
    this.setState({ dataLoading : true})
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    toVal = offset + process.env.REACT_APP_PERPAGE;
    // let limit = 10;
    this.getAllData();
    // let values = { transporter: this.state.transporter,
    //   FromDate:this.state.FromDate,
    //   ToDate:this.state.ToDate };

    // this.props.fetchTransporterPerformance(offset, limit, values);
  };
  reset = () => {
    this.setState(
      { FromDate: "", ToDate: "", transporterValues: [], selectedTranspoterValues: [] , dataLoading : true},
      () => {
        let offset = 0;
        let limit = 10;
        let values = {
          transporter: this.state.transporterValues,
          FromDate: this.state.FromDate,
          ToDate: this.state.ToDate,
        };
        this.props.fetchTransporterPerformance(offset, limit, values, (response) => {
          this.setState({ dataLoading : false})
        });
      }
    );
  };

  searchFilter = () => {
    this.setState({dataLoading : true})
    let limit = 10;
    let offset = 0;
    let values = {
      transporter: this.state.transporterValues,
      FromDate: this.state.FromDate,
      ToDate: this.state.ToDate,
    };
    this.props.fetchTransporterPerformance(offset, limit, values,(response) => {
      this.setState({ dataLoading : false})
    });
  };
  exportToCSV = (csvData, fileName) => {
    csvData = csvData?.map(function (item) {
      for (var key in item) {
        var upper = key.toUpperCase();
        // check if it already wasn't uppercase
      }
      return item;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  calculatePerformance = ({ performanceCount, totalTrips }) => {
    if (performanceCount === 0)
      return 0;
    else {
      let performance = (performanceCount / totalTrips).toFixed(2)
      return (performance * 100).toFixed(2)
    }
  }


  render() {
    const localStorageData = JSON.parse(localStorage.getItem("transporterPerformanceData"));
    const reportData = navigator.onLine
      ? this.props.reports?.data?.docs
      : localStorageData?.data?.docs;
    let mode = localStorage.getItem('Theme') ? localStorage.getItem('Theme') : null
    darkMode = ((mode === "dark__mode") ? true : false);
    let reports = [];
    if (Array.isArray(reportData)) {
      offset = navigator.onLine
        ? this.props.reports?.data?.offset
        : localStorageData?.data?.offset;
      totCnt = navigator.onLine
        ? this.props.reports?.data?.length
        : localStorageData?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = navigator.onLine
        ? this.props.reports?.data?.offset +
        this.props.reports?.data?.docs?.length
        : localStorageData?.data?.offset + localStorageData?.data?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      // let vehiclePlacement =0, qualityRejection =0, onTimeDelivery=0, damage=0, shortages, acceptance, totalTrips;
      // console.log("report Data" , reportData)

      reportData.forEach((ele) => {
        let totalTrips = "N/A" , qualityRejection="N/A", onTimeDelivery="N/A", vehiclePlacement="N/A", damage="N/A", shortages = "N/A";
        if (ele?.performance?.length > 0) {
          ele.performance.forEach((e) => {
           switch(e.performanceType){
            case "trip":
              totalTrips = e.totalTrips
              return;
            case "quality":
              qualityRejection = this.calculatePerformance(e)
              return;
            case "onTimeDelivery":
              onTimeDelivery = this.calculatePerformance(e)
              return;
            case "placement":
              vehiclePlacement = this.calculatePerformance(e)
              return;
            case "damage":
              damage = this.calculatePerformance(e)
              return;
            case "shortage":
              shortages = this.calculatePerformance(e)
              return;
            default:
                return null
           }
          })
        }
        reports.push({
          transporter: ele?.transporterDetails?.length ? ele.transporterDetails[0].name : "N/A",
          totalTrips :  totalTrips,
          vehiclePlacement: vehiclePlacement,
          qualityRejection: qualityRejection,
          onTimeDelivery: onTimeDelivery,
          damage: damage,
          shortages: shortages,
          transporterScore : ele?.overAllScore ? ele.overAllScore : "N/A"
          // totalTrips : ele?.totalTrips ? ele.totalTrips : 0,
          // vehiclePlacement: ele?.placementPerformance?.length ? this.calculatePerformance(ele.placementPerformance[0]) : 0,
          // qualityRejection: ele?.qualityPerformance?.length ? this.calculatePerformance(ele.qualityPerformance[0]) : 0,
          // onTimeDelivery: ele?.onTimeDeliveryPerformance?.length ? this.calculatePerformance(ele.onTimeDeliveryPerformance[0]) : 0,
          // damage: ele?.damagePerformance?.length ? this.calculatePerformance( ele.damagePerformance[0]) : 0,
          // shortages: ele?.shortagePerformance?.length ? this.calculatePerformance(ele.shortagePerformance[0]) : 0
        });
      });
      
      // console.log("final data ", reports)
    }
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;
   
    return (
      <React.Fragment>
        {getLocalStorage()}
        <div>
          <QuickFilters
            searchFilter={this.searchFilter}
            reset={this.reset}
            selectedTranspoterValues={this.state.selectedTranspoterValues}
            transpoterOptions={this.state.transpoter}
            onChangeTranspoter={this.onChangeTranspoter}
            enterCheck={this.enterCheck}
            reportsData={reports}
            exportToCSV={this.exportToCSV}
            handleChange={this.handleChange}
            mode={darkMode}
            filtersTranspoter={this.filtersTranspoter}
          />
          <Container fluid>
            {userType && (userType !== "admin" || userType !== "Admin") ? (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={reports}
                searchBar={searchBar}
                searchable={false}
                getAllData={this.getAllData}
                fetchId={this.props.fetchId}
                rowHeight={"56px"}
                dataLoading = {this.state.dataLoading}
              />
            ) : (
              <Index
                {...this.props}
                headers={AdminHeaderData}
                data={reports}
                searchBar={searchBar}
                searchable={false}
                getAllData={this.getAllData}
                fetchId={this.props.fetchId}
                rowHeight={"56px"}
                dataLoading = {this.state.dataLoading}
              />
            )}

            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{ color: darkMode ? "#9D9D9D" : "#0C0C0C" }}
                className="mb-2 paginationRow"
              >
                <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                  <div className="float-left">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={7}
                  md={8}
                >
                  <span>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
              </Row>
            ) : null}
          </Container>
          {/* <Popup
          title="Add User"
          openPopup={this.state.setOpenPopup}
          setOpenPopupfalse={this.setOpenPopupfalse}
        >
          <UserForm_New handleSubmit={this.handleSubmit} />
        </Popup> */}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  fetchId: state.Reports.reports.fetchId,
  reports: state.Reports.reports.reports,
});

export default connect(mapStateToProps, {
  fetchAllTransporterSearch,
  fetchTransporterPerformance,
})(Reports_New);
