import React, { Component } from "react";
// import from libraries
import { Row, Col, Card, CardBody } from "reactstrap";
import { Button, Grid, ButtonGroup } from "@material-ui/core";
import { connect } from "react-redux";
import moment from "moment";
// css
import "./Styles/invoice-dashboard.css";
// import from redux
import { fetchAllTransporterSearch } from "../../../store/transporter/actions";
import { fetchHops } from "../../../store/hop/actions";
import { fetchInvoiceDashboard } from "../../../store/dashboard/actions";
// files
import CostToRevenueRatioCard from "./CostToRevenueRatioCard";
import DashboardHeader from "../CommonHeader/DashboardHeader";
import FreightCostCard from "./FreightCostCard";
import { roleCheck } from "../../restrict";
// icons
import paymentIcon from "../assets/pendingPayment.png";
import approvalIcon from "../assets/pending.png";
import acceptenceIcon from "../assets/paidInvoice.png";
import unpaidInvoiceIcon from "../assets/unpaidInvoiceIcon.png";
import { WaveLoader } from "../../../components/Common/Loader/LoaderIndex";

// import from localstorage
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

// drop down options for freight cost and cost to revenue ratio
var filteredOptions = [
  {
    value: "month",
    label: userLang?.common?.MONTHLY ?? "Monthly",
  },
  {
    value: "year",
    label: userLang?.common?.YEARLY ?? "Yearly",
  },
  {
    value: "weekDay",
    label: userLang?.common?.WEEK_DAY ?? "Week Day",
  },
  {
    value: "quarter",
    label: userLang?.common?.QUARTERLY ?? "Quarterly",
  },
  {
    value: "date",
    label: userLang?.common?.DATE ?? "Date",
  },
  {
    value: "week",
    label: userLang?.common?.WEEK ?? "Week",
  },
];
var darkMode;
var perBgClr;
class InvoiceDashboardCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataLoading: false,
      transpoter: [],
      selectedTranspoterValues: [],
      selectedHopValues: [],
      transporterValues: [],
      hopValues: [],
      trips: {},
      indents: {},
      hops: [],
      datevalues: [null, null],
      offset: 0,
      limit: 10,
      filterTranspoter: "",
      invoiceDetailsData: {},
      totalTrips: "",
      totalShipment: "",
      tripCharge: "",
      perTrip: false,
      perShiment: true,
      menu: false,
      costToRevenueChartType: "line",
      freightCostChartType: "area",
      costToRevenueDropdownOpen: false,
      freightCostDropdownOpen: false,
      selectedFilteredFreightCost: {
        value: "month",
        label: "Monthly",
      },
      selectedFilteredCostToRevenueRatio: {
        value: "month",
        label: "Monthly",
      },
    };
    (async () => {
      let redirect = await roleCheck("transporter");
      if (redirect === true) {
        this.props.history.push("/assigned-indent");
      }
    })();
  }
  componentDidMount() {
    this.getAllFetchInvoices();
    this.getTAllranspoterData();
    this.getAllLocationsStateData();
  }
  // fetch invoice data
  getAllFetchInvoices = () => {
    this.setState({ dataLoading: true });
    this.props.fetchInvoiceDashboard(
      this.state.transporterValues,
      this.state.hopValues,
      this.state.datevalues[0] === null
        ? ""
        : moment(this.state.datevalues[0]).format("MM-DD-YYYY"),
      this.state.datevalues[1] === null
        ? ""
        : moment(this.state.datevalues[1]).format("MM-DD-YYYY"),
      this.state.selectedFilteredFreightCost
        ? this.state.selectedFilteredFreightCost.value
        : "",
      this.state.selectedFilteredCostToRevenueRatio
        ? this.state.selectedFilteredCostToRevenueRatio?.value
        : "",
      () => {
        this.setState({ dataLoading: false });
      }
    );
  };
  // fetch transpoters
  getTAllranspoterData = () => {
    let filtersData = {
      offset: this.state.offset,
      limit: this.state.limit,
      q: "",
    };
    this.fetchAllTransporterSearch(filtersData);
  };
  // calling transpoter api
  fetchAllTransporterSearch = (filtersData) => {
    this.props.fetchAllTransporterSearch(
      filtersData.offset,
      filtersData.limit,
      filtersData.q,
      (res) => {
        let transporter = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            transporter.push({
              value: ele._id ? ele._id : "",
              label: ele.name ? ele.name : "",
            });
          });
        }
        this.setState(
          {
            transpoter: transporter,
          },
          () => {}
        );
      }
    );
  };
  // filters transpoters data
  filtersTranspoter = (e) => {
    if (e?.length > 0) {
      let filtersData = {
        offset: this.state.offset,
        limit: this.state.limit,
        q: e,
      };
      this.fetchAllTransporterSearch(filtersData);
    }
    if (e?.length === 0) {
      let filtersData = {
        offset: this.state.offset,
        limit: this.state.limit,
        q: "",
      };
      this.fetchAllTransporterSearch(filtersData);
    }
  };
  // get all locations data
  getAllLocationsStateData = () => {
    let filtersHopsData = {
      offset: 0,
      limit: 10,
      q: "",
    };
    this.fetchHops(filtersHopsData);
  };
  // calling locations api
  fetchHops = (filtersHopsData) => {
    this.props.fetchHops(
      {
        offset: filtersHopsData.offset,
        limit: filtersHopsData.limit,
        label: filtersHopsData.q,
      },
      (res) => {
        let hops = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            hops.push({
              label: ele?.label ? ele?.label : "",
              value: ele?._id ? ele?._id : "",
            });
          });
        }
        this.setState(
          {
            hops: hops,
          },
          () => {},
          () => {}
        );
      }
    );
  };
  // get location by filter data
  filtersHops = (e) => {
    if (e?.length > 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: e,
      };
      this.fetchHops(filtersHopsData);
    }
    if (e.length === 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: "",
      };
      this.fetchHops(filtersHopsData);
    }
  };

  toggle = () => {
    this.setState((prevState) => ({
      menu: false,
    }));
  };
  toggleCostToRevenue = () => {
    this.setState((prevState) => ({
      costToRevenueDropdownOpen: !prevState.costToRevenueDropdownOpen,
    }));
  };
  toggleFreightCost = () => {
    this.setState((prevState) => ({
      freightCostDropdownOpen: !prevState.freightCostDropdownOpen,
    }));
  };
  // here reseting all the filters
  onReset = () => {
    this.setState(
      {
        selectedTranspoterValues: [],
        selectedHopValues: [],
        transporterValues: [],
        hopValues: [],
        datevalues: [null, null],
      },
      () => {
        this.getAllFetchInvoices();
      }
    );
  };
  onChangeTranspoter = (selectedTranspoterValues) => {
    if (selectedTranspoterValues?.length <= 3) {
      this.setState({ selectedTranspoterValues }, () => {
        if (this.state.selectedTranspoterValues?.length <= 3) {
          var transporterValues =
            this.state.selectedTranspoterValues === null
              ? []
              : this.state.selectedTranspoterValues?.map((ele) => {
                  return ele?.value;
                });
          this.setState({ transporterValues }, () => {
            // if (this.state.transporterValues.length >= 0) {
            this.getAllFetchInvoices();
            // }
          });
        } else if (this.state.selectedTranspoterValues?.length === undefined) {
          this.setState(
            {
              transporterValues: [],
              selectedTranspoterValues: [],
            },
            () => {
              this.getAllFetchInvoices();
            }
          );
        }
      });
    } else if (selectedTranspoterValues?.length === undefined) {
      this.setState(
        {
          transporterValues: [],
          selectedTranspoterValues: [],
        },
        () => {
          this.getAllFetchInvoices();
        }
      );
    }
  };

  onChangeHop = (selectedHopValues) => {
    if (selectedHopValues?.length <= 3) {
      this.setState({ selectedHopValues }, () => {
        if (this.state.selectedHopValues?.length <= 3) {
          var hopValues =
            this.state.selectedHopValues === null
              ? []
              : this.state.selectedHopValues?.map((ele) => {
                  return ele?.value;
                });
          this.setState({ hopValues }, () => {
            this.getAllFetchInvoices();
          });
        } else if (this.state.selectedHopValues?.length === undefined) {
          this.setState(
            {
              hopValues: [],
              selectedHopValues: [],
            },
            () => {
              this.getAllFetchInvoices();
            }
          );
        }
      });
    } else if (selectedHopValues?.length === undefined) {
      this.setState(
        {
          hopValues: [],
          selectedHopValues: [],
        },
        () => {
          this.getAllFetchInvoices();
        }
      );
    }
  };
  onChangeDatePicker = (datevalues) => {
    this.setState({ datevalues }, () => {
      // if (this.state.dates.length >= 0) {
      this.getAllFetchInvoices();
      // }
    });
  };
  onChangeFreightCost = (e) => {
    // console.log("filter type", e);
    this.setState(
      {
        selectedFilteredFreightCost: e,
      },
      () => {
        this.getAllFetchInvoices();
        // console.log("selectedFilte", this.state.selectedFilteredFreightCost);
      }
    );
  };
  onChangeCostToRevenue = (e) => {
    // console.log("filter type", e);
    this.setState(
      {
        selectedFilteredCostToRevenueRatio: e,
      },
      () => {
        this.getAllFetchInvoices();
        // console.log(
        //   "selectedFilte",
        //   this.state.selectedFilteredCostToRevenueRatio
        // );
      }
    );
  };
  onClickPerTrip = () => {
    this.setState({ perTrip: true, perShiment: false });
  };
  onClickPerShipment = () => {
    this.setState({ perShiment: true, perTrip: false });
  };
  costTorevenueDropDownLine = () => {
    this.setState({
      costToRevenueChartType: "line",
    });
  };
  costTorevenueDropDownBar = () => {
    this.setState({
      costToRevenueChartType: "bar",
    });
  };
  freightCostChartTypeLine = () => {
    this.setState({ freightCostChartType: "line" });
  };
  freightCostChartTypeBar = () => {
    this.setState({ freightCostChartType: "bar" });
  };
  componentDidUpdate(prevProps) {
    if (prevProps.invoiceDetails !== this.props.invoiceDetails) {
      this.setState({ invoiceDetailsData: this.props.invoiceDetails }, () => {
        if (this.state.invoiceDetailsData) {
          let freightAndShipmentCost =
            this.state.invoiceDetailsData?.invoiceInformation;

          let tripInvoice = freightAndShipmentCost?.totalTrips
            ? freightAndShipmentCost?.totalTrips
            : "00";
          let shipmentCosts = freightAndShipmentCost?.totalShipments
            ? freightAndShipmentCost?.totalShipments
            : "00";
          let freightCosts = freightAndShipmentCost?.allTripsCharges
            ? freightAndShipmentCost?.allTripsCharges
            : "00";

          this.setState({
            totalTrips: tripInvoice,
            tripCharge: freightCosts,
            totalShipment: shipmentCosts,
          });
        }
      });
    }
  }

  render() {
    // console.log("invoice", this.props.invoiceDetails);
    //user language
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    // let userPreferredLang = window.localStorage.getItem("userPreferredLang");
    // theme
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkMode = mode === "dark__mode" ? true : false;
    // invoice details getting from local storage
    const localStorageData = JSON.parse(
      localStorage.getItem("invoiceDashboardData")
    );
    // invoice details
    const invoice = navigator.onLine
      ? this.props.invoiceDetails
      : localStorageData?.data;
    let paidInvoices,
      unpaidInvoices,
      pendingApprovalInvoices,
      pendingAcceptenceInvoices;

    if (invoice !== undefined && invoice?.invoiceInformation?.invoiceDetails?.length>0) {
      invoice.invoiceInformation.invoiceDetails.forEach((item) => {
        switch (item?.status) {
          case "paid":
            paidInvoices = item;
            break;
          case "approved":
            unpaidInvoices = item;
            break;
          case "submitted":
            pendingAcceptenceInvoices = item;
            break;
          case "approval_pending":
            pendingApprovalInvoices = item;
            break;
          default:
            break;
        }
      });
    }

    // conditionally changing buttons colors
    if (this.state.perTrip === true && this.state.perShiment === false) {
      perBgClr = "#0AB38C";
      var perClr = "#FFFFFF";
    } else {
      perBgClr = darkMode ? "#171722" : "#F1F5F7";
      perClr = darkMode ? "#ffffff" : "#0C0C0C";
    }
    if (this.state.perShiment === true && this.state.perTrip === false) {
      var perShipBgClr = "#0AB38C";
      var perShipClr = "#FFFFFF";
    } else {
      perShipBgClr = darkMode ? "#171722" : "#F1F5F7";
      perShipClr = darkMode ? "#ffffff" : "#0C0C0C";
    }

    return (
      <React.Fragment>
        <div>
          <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
            className="mt-1 pt-1 mb-1 invoiceDashboardDiv padt38 invoiceDarkMode"
          >
            <Grid
              item
              xs={12}
              style={{
                width: "100%",
              }}
            >
              <div>
                <DashboardHeader
                  {...this.props}
                  selectedTranspoterValues={this.state.selectedTranspoterValues}
                  transporterOptions={this.state.transpoter}
                  onChangeTranspoter={this.onChangeTranspoter}
                  selectedHopValues={this.state.selectedHopValues}
                  hopOptions={this.state.hops}
                  onChangeHop={this.onChangeHop}
                  datevalues={this.state.datevalues}
                  onChangeDatePicker={this.onChangeDatePicker}
                  onReset={this.onReset}
                  userLang={userLang}
                  opCardStyle={darkMode ? darkMode : ""}
                  filtersTranspoter={this.filtersTranspoter}
                  filtersHops={this.filtersHops}
                />
              </div>
            </Grid>

            {this.state.dataLoading ? (
              <WaveLoader />
            ) : (
              <>
                <Grid item xs={12} className="pr-2 pl-2">
                  <div className="dashboard-header-invoice-div">
                    <Grid
                      container
                      spacing={2}
                      // justifyContent="center"
                      // alignItems="center"
                      className="mb-1"
                    >
                      <Grid item xs={12} md={5} lg={4}>
                        <Grid
                          container
                          spacing={2}
                          justifyContent="center"
                          alignItems="center"
                          className=""
                        >
                          <Grid item xs={6}>
                            <Card
                              className="darkInvoiceCard CardBordersDashboard"
                              style={{
                                width: "100%",
                                height: "145px",
                              }}
                            >
                              <Row>
                                <Col xs={9} className="span-invoice-pd">
                                  <span
                                    className=" freight-cost-span-names light__span"
                                    style={{
                                      color: "#05284E",
                                    }}
                                  >
                                    {userLang?.dashboard?.PAID_INVOICES}
                                  </span>
                                </Col>
                                <Col
                                  xs={3}
                                  style={{
                                    top: "12%",
                                  }}
                                >
                                  <img
                                    width="30px"
                                    height="30px"
                                    src={acceptenceIcon}
                                    alt=""
                                  ></img>
                                </Col>
                                <Col xs={10} className="span-invoice-pd">
                                  <span
                                    className="freight-cost-span-values dark__span "
                                    style={{
                                      color: "#0C0C0C",
                                    }}
                                  >
                                    {paidInvoices?.totalInvoices > 9
                                      ? paidInvoices?.totalInvoices
                                        ? paidInvoices.totalInvoices
                                        : "00"
                                      : paidInvoices?.totalInvoices
                                      ? "0" + paidInvoices.totalInvoices
                                      : "00"}
                                  </span>
                                </Col>
                              </Row>
                            </Card>
                            <Card
                              className="darkInterInvoice CardBordersDashboard"
                              style={{
                                width: "100%",
                                height: "145px",
                                backgroundColor: "#F1F5F7",
                              }}
                            >
                              <Row>
                                <Col xs={10} className="span-invoice-pd">
                                  <span
                                    className=" freight-cost-span-names light__span "
                                    style={{
                                      color: "#05284E",
                                    }}
                                  >
                                    {userLang?.dashboard?.AMOUNT}
                                  </span>{" "}
                                </Col>
                                <Col xs={10} className="span-invoice-pd">
                                  <span
                                    className=" freight-cost-span-values dark__span"
                                    style={{
                                      color: "#0C0C0C",
                                    }}
                                  >
                                    {paidInvoices?.totalAmount
                                      ? paidInvoices.totalAmount + " ₹"
                                      : "00 ₹"}
                                  </span>
                                </Col>
                              </Row>
                            </Card>
                          </Grid>
                          <Grid item xs={6}>
                            <Card
                              className="darkInvoiceCard CardBordersDashboard"
                              style={{
                                width: "100%",
                                height: "145px",
                              }}
                            >
                              <Row>
                                <Col xs={9} className="span-invoice-pd">
                                  <span
                                    className=" freight-cost-span-names light__span"
                                    style={{
                                      color: "#05284E",
                                    }}
                                  >
                                    {userLang?.dashboard?.UNPAID_INVOICE}
                                  </span>
                                </Col>
                                <Col
                                  xs={3}
                                  style={{
                                    top: "12%",
                                  }}
                                >
                                  <img
                                    width="30px"
                                    height="30px"
                                    src={unpaidInvoiceIcon}
                                    alt=""
                                  ></img>
                                </Col>
                                <Col xs={10} className="span-invoice-pd">
                                  <span
                                    className="freight-cost-span-values dark__span "
                                    style={{
                                      color: "#0C0C0C",
                                    }}
                                  >
                                    {unpaidInvoices?.totalInvoices > 9
                                      ? unpaidInvoices?.totalInvoices
                                        ? unpaidInvoices.totalInvoices
                                        : "00"
                                      : unpaidInvoices?.totalInvoices
                                      ? "0" + unpaidInvoices.totalInvoices
                                      : "00"}
                                  </span>
                                </Col>
                              </Row>
                            </Card>
                            <Card
                              className="darkInterInvoice CardBordersDashboard"
                              style={{
                                width: "100%",
                                height: "145px",
                                backgroundColor: "#F1F5F7",
                              }}
                            >
                              <Row>
                                <Col xs={9} className="span-invoice-pd">
                                  <span
                                    className=" freight-cost-span-names light__span "
                                    style={{
                                      color: "#05284E",
                                    }}
                                  >
                                    {userLang?.dashboard?.AMOUNT}
                                  </span>
                                </Col>

                                <Col xs={10} className="span-invoice-pd">
                                  <span
                                    className=" freight-cost-span-values dark__span"
                                    style={{
                                      color: "#0C0C0C",
                                    }}
                                  >
                                    {unpaidInvoices?.totalAmount
                                      ? unpaidInvoices.totalAmount + " ₹"
                                      : "00 ₹"}
                                  </span>
                                </Col>
                              </Row>
                            </Card>
                          </Grid>
                          <Grid item xs={6}>
                            <Card
                              className="darkInvoiceCard CardBordersDashboard"
                              style={{
                                width: "100%",
                                height: "145px",
                              }}
                            >
                              <Row>
                                <Col xs={12} className="span-invoice-pd">
                                  <span
                                    className=" freight-cost-span-names light__span"
                                    style={{
                                      color: "#05284E",
                                    }}
                                  >
                                    {
                                      userLang?.dashboard
                                        ?.SUBMITTED_INVOICE_COUNT
                                    }
                                  </span>
                                </Col>
                                {/* <Col
                                  xs={3}
                                  style={{
                                    top: "12%",
                                  }}
                                >
                                  <img
                                    width="30px"
                                    height="30px"
                                    src={acceptenceIcon}
                                    alt=""
                                  ></img>
                                </Col> */}
                                <Col xs={10} className="span-invoice-pd">
                                  <span
                                    className="freight-cost-span-values dark__span "
                                    style={{
                                      color: "#0C0C0C",
                                    }}
                                  >
                                    {pendingAcceptenceInvoices?.totalInvoices >
                                    9
                                      ? pendingAcceptenceInvoices?.totalInvoices
                                        ? pendingAcceptenceInvoices.totalInvoices
                                        : "00"
                                      : pendingAcceptenceInvoices?.totalInvoices
                                      ? "0" +
                                        pendingAcceptenceInvoices.totalInvoices
                                      : "00"}
                                  </span>
                                </Col>
                              </Row>
                            </Card>
                            <Card
                              className="darkInterInvoice CardBordersDashboard"
                              style={{
                                width: "100%",
                                height: "145px",
                                backgroundColor: "#F1F5F7",
                              }}
                            >
                              <Row>
                                <Col
                                  xs={12}
                                  className="span-invoice-pd p-2 mt-2 ml-1"
                                >
                                  <span
                                    className=" freight-cost-span-names light__span "
                                    style={{
                                      color: "#05284E",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {
                                      userLang?.dashboard
                                        ?.SUBMITED_INVOICE_PAYMENT
                                    }
                                  </span>{" "}
                                </Col>
                                <Col xs={10} className="span-invoice-pd">
                                  <span
                                    className=" freight-cost-span-values dark__span"
                                    style={{
                                      color: "#0C0C0C",
                                    }}
                                  >
                                    {pendingAcceptenceInvoices?.totalAmount
                                      ? pendingAcceptenceInvoices.totalAmount +
                                        " ₹"
                                      : "00 ₹"}
                                  </span>
                                </Col>
                              </Row>
                            </Card>
                          </Grid>
                          <Grid item xs={6}>
                            <Card
                              className="darkInvoiceCard CardBordersDashboard"
                              style={{
                                width: "100%",
                                height: "145px",
                              }}
                            >
                              <Row>
                                <Col xs={9} className="span-invoice-pd">
                                  <span
                                    className=" freight-cost-span-names light__span"
                                    style={{
                                      color: "#05284E",
                                    }}
                                  >
                                    {userLang?.dashboard?.PENDING_APPROVAL}
                                  </span>
                                </Col>
                                <Col
                                  xs={3}
                                  style={{
                                    top: "12%",
                                  }}
                                >
                                  {/* <img
                                    width="30px"
                                    height="30px"
                                    src={unpaidInvoiceIcon}
                                    alt=""
                                  ></img> */}
                                </Col>
                                <Col xs={10} className="span-invoice-pd">
                                  <span
                                    className="freight-cost-span-values dark__span "
                                    style={{
                                      color: "#0C0C0C",
                                    }}
                                  >
                                    {pendingApprovalInvoices?.totalInvoices > 9
                                      ? pendingApprovalInvoices?.totalInvoices
                                        ? pendingApprovalInvoices.totalInvoices
                                        : "00"
                                      : pendingApprovalInvoices?.totalInvoices
                                      ? "0" +
                                        pendingApprovalInvoices.totalInvoices
                                      : "00"}
                                  </span>
                                </Col>
                              </Row>
                            </Card>
                            <Card
                              className="darkInterInvoice CardBordersDashboard"
                              style={{
                                width: "100%",
                                height: "145px",
                                backgroundColor: "#F1F5F7",
                              }}
                            >
                              <Row>
                                <Col xs={9} className="span-invoice-pd">
                                  <span
                                    className=" freight-cost-span-names light__span "
                                    style={{
                                      color: "#05284E",
                                    }}
                                  >
                                    {
                                      userLang?.dashboard
                                        ?.PENDING_APPROVAL_TOTAL
                                    }
                                  </span>
                                </Col>

                                <Col xs={10} className="span-invoice-pd">
                                  <span
                                    className=" freight-cost-span-values dark__span"
                                    style={{
                                      color: "#0C0C0C",
                                    }}
                                  >
                                    {`${
                                      pendingApprovalInvoices?.totalAmount
                                        ? pendingApprovalInvoices?.totalAmount
                                        : "00"
                                    } ₹`}
                                  </span>
                                </Col>
                              </Row>
                            </Card>
                          </Grid>
                          {/* new start change */}
                          <Grid item xs={12}>
                            <Card
                              className="shadow barBgDarkCard CardBordersDashboard"
                              style={{
                                width: "100%",
                              }}
                            >
                              <Grid
                                style={{
                                  padding: "25px",
                                }}
                                container
                                spacing={1}
                                justifyContent="center"
                                alignItems="center"
                                className=""
                              >
                                <Grid item xs={12}>
                                  <Row>
                                    <Col xl="12" className="p-0">
                                      <Card className="shadow  darkInterInvoice">
                                        <CardBody>
                                          <Row className="">
                                            <Col
                                              md="2"
                                              xs={4}
                                              className="imgInvoiceCol1"
                                            >
                                              <img
                                                width="30px"
                                                height="30px"
                                                src={acceptenceIcon}
                                                alt=""
                                              ></img>
                                            </Col>
                                            <Col
                                              md="5"
                                              xs={4}
                                              className="imgInvoiceCol2"
                                            >
                                              <span
                                                className="freight-cost-span-names InvoiceCol2Child light__span"
                                                style={{
                                                  color: "#05284E",
                                                  lineHeight: "22px",
                                                }}
                                              >
                                                Total Trip
                                              </span>
                                            </Col>
                                            <Col
                                              md="5"
                                              xs={4}
                                              className="imgInvoiceCol3"
                                            >
                                              <span
                                                className=" freight-cost-span-values dark__span p-0"
                                                style={{
                                                  color: "#0C0C0C",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                {this.state.totalTrips}
                                              </span>
                                            </Col>
                                          </Row>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </Grid>
                                <Grid item xs={12}>
                                  <Row>
                                    <Col xl="12" className="p-0">
                                      <Card className="shadow  darkInterInvoice">
                                        <CardBody>
                                          <Row className="">
                                            <Col
                                              md="2"
                                              xs={4}
                                              className="imgInvoiceCol1"
                                            >
                                              <img
                                                width="30px"
                                                height="30px"
                                                src={paymentIcon}
                                                alt=""
                                              ></img>
                                            </Col>
                                            <Col
                                              md="5"
                                              xs={4}
                                              className="imgInvoiceCol2"
                                            >
                                              <span
                                                className="freight-cost-span-names InvoiceCol2Child light__span"
                                                style={{
                                                  color: "#05284E",
                                                  lineHeight: "22px",
                                                }}
                                              >
                                                Total Shipment
                                              </span>
                                            </Col>
                                            <Col
                                              md="5"
                                              xs={4}
                                              className="imgInvoiceCol3"
                                            >
                                              <span
                                                className=" freight-cost-span-values dark__span p-0"
                                                style={{
                                                  color: "#0C0C0C",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                {this.state.totalShipment}
                                              </span>
                                            </Col>
                                          </Row>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </Grid>
                                <Grid item xs={12}>
                                  <Row>
                                    <Col xl="12" className="p-0">
                                      <Card className="shadow  darkInterInvoice">
                                        <CardBody>
                                          <Row className="">
                                            <Col
                                              md="2"
                                              xs={4}
                                              className="imgInvoiceCol1"
                                            >
                                              <img
                                                width="30px"
                                                height="30px"
                                                src={approvalIcon}
                                                alt=""
                                              ></img>
                                            </Col>
                                            <Col
                                              md="5"
                                              xs={4}
                                              className="imgInvoiceCol2"
                                            >
                                              <span
                                                className="freight-cost-span-names InvoiceCol2Child light__span"
                                                style={{
                                                  color: "#05284E",
                                                  lineHeight: "22px",
                                                }}
                                              >
                                                Trip Charges
                                              </span>
                                            </Col>
                                            <Col
                                              md="5"
                                              xs={4}
                                              className="imgInvoiceCol3"
                                            >
                                              <span
                                                className=" freight-cost-span-values dark__span p-0"
                                                style={{
                                                  color: "#0C0C0C",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                {`${this.state.tripCharge} ₹`}
                                              </span>
                                            </Col>
                                          </Row>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </Row>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                          {/* new endchange */}
                          <Grid item xs={12}>
                            <Card
                              className="shadow barBgDarkCard CardBordersDashboard"
                              style={{
                                width: "100%",
                                height: "240px",
                              }}
                            >
                              <Row>
                                <Col
                                  xs="12"
                                  lg="12"
                                  style={{
                                    top: "18%",
                                  }}
                                >
                                  <div className="pt-2 pl-2">
                                    <ButtonGroup
                                      size="medium"
                                      style={{
                                        width: "100%",
                                        height: "38px",
                                        padding: "0px 20px",
                                        top: "10%",
                                      }}
                                      // variant="outlined"
                                    >
                                      <Button
                                        onClick={() =>
                                          this.onClickPerShipment()
                                        }
                                        style={{
                                          backgroundColor: `${perShipBgClr}`,
                                          width: "50%",
                                        }}
                                      >
                                        <span
                                          className="span-invoice-perShipment"
                                          style={{
                                            color: `${perShipClr}`,
                                          }}
                                        >
                                          {userLang?.dashboard?.PER_SHIPMENT}
                                        </span>
                                      </Button>
                                      <Button
                                        onClick={() => this.onClickPerTrip()}
                                        style={{
                                          width: "50%",
                                          backgroundColor: `${perBgClr}`,
                                        }}
                                      >
                                        <span
                                          className="span-invoice-perTrip"
                                          style={{
                                            color: `${perClr}`,
                                          }}
                                        >
                                          {userLang?.dashboard?.PER_TRIP}
                                        </span>
                                      </Button>
                                    </ButtonGroup>
                                  </div>
                                  <span
                                    className="freight-cost-name light__span"
                                    style={{
                                      padding: "35px",
                                    }}
                                  >
                                    {userLang?.dashboard?.FREIGHT_COST}
                                  </span>
                                  <div className="">
                                    <span className=" freight-cost-span-values dark__span freight-cost-amount ">
                                      {this.state.perShiment
                                        ? invoice?.invoiceInformation
                                            ?.perShipment !== null
                                          ? invoice?.invoiceInformation?.perShipment?.toFixed(
                                              2
                                            ) + " ₹"
                                          : "00  ₹"
                                        : invoice?.invoiceInformation
                                            ?.perTrip !== null
                                        ? invoice?.invoiceInformation?.perTrip?.toFixed(
                                            2
                                          ) + " ₹"
                                        : "00  ₹"}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </Card>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={7} lg={8}>
                        <Grid
                          container
                          spacing={2}
                          justifyContent="center"
                          alignItems="center"
                          className="pt-2"
                        >
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            className="freight-costPd"
                            style={{
                              padding: "0px 18px",
                            }}
                          >
                            <CostToRevenueRatioCard
                              {...this.props}
                              userLang={userLang}
                              menu={this.state.menu}
                              toggle={this.toggle}
                              costToRevenueDropdownOpen={
                                this.state.costToRevenueDropdownOpen
                              }
                              toggleCostToRevenue={this.toggleCostToRevenue}
                              costToRevenueChartType={
                                this.state.costToRevenueChartType
                              }
                              costTorevenueDropDownLine={
                                this.costTorevenueDropDownLine
                              }
                              costTorevenueDropDownBar={
                                this.costTorevenueDropDownBar
                              }
                              darkMode={darkMode}
                              invoice={
                                invoice?.invoiceInformation
                                  ?.freightAndShipmentCost
                              }
                              options={filteredOptions}
                              onChangeCostToRevenue={this.onChangeCostToRevenue}
                              selectedFilteredCostToRevenueRatio={
                                this.state.selectedFilteredCostToRevenueRatio
                              }
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            className="freight-costPd"
                            style={{
                              padding: "0px 18px",
                            }}
                          >
                            <FreightCostCard
                              {...this.props}
                              userLang={userLang}
                              darkMode={darkMode}
                              invoice={invoice?.invoiceInformation?.freightCost}
                              menu={this.state.menu}
                              toggle={this.toggle}
                              freightCostDropdownOpen={
                                this.state.freightCostDropdownOpen
                              }
                              toggleFreightCost={this.toggleFreightCost}
                              freightCostChartType={
                                this.state.freightCostChartType
                              }
                              freightCostChartTypeLine={
                                this.freightCostChartTypeLine
                              }
                              freightCostChartTypeBar={
                                this.freightCostChartTypeBar
                              }
                              options={filteredOptions}
                              selectedFilteredFreightCost={
                                this.state.selectedFilteredFreightCost
                              }
                              onChangeFreightCost={this.onChangeFreightCost}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    invoiceDetails:
      state.TripCountStatus.tripCountStatus?.invoiceDashboardDetails?.data,
  };
};
export default connect(mapStateToProps, {
  fetchHops,
  fetchAllTransporterSearch,
  fetchInvoiceDashboard,
})(InvoiceDashboardCard);
