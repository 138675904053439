import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { fetchHops, createIndent } from "../../store/indent/actions";
import { fetchProductNames } from "../../store/order/actions";
import IndentDetails from "./CommonResources/Indent/indentDetails";

class IndentForm extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        {/* <IndentLayout /> */}
        <IndentDetails {...this.props} />
      </React.Fragment>
    );
  }
}

export default connect(null, {
  fetchHops,
  fetchProductNames,
  createIndent,
})(IndentForm);
