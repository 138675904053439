let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: "Order No",
        field: "orderNo",
        sort: "asc",
        width: 1,
      },
      {
        label: "Order Type",
        field: "orderType",
        sort: "asc",
        width: 1,
      },
      {
        label: "Billed To Name",
        field: "billedToName",
        sort: "asc",
        width: 2,
      },
      {
        label: "Billed To City",
        field: "billedToCity",
        sort: "asc",
        width: 2,
      },
      {
        label: "Billed To State",
        field: "billedToStateName",
        sort: "asc",
        width: 2,
      },
      
      {
        label: "Order Date",
        field: "orderDate",
        sort: "asc",
        width: 1,
      },
      //Baxter Unit is a Warehouse
      {
        label: "Warehouse",
        field: "businessUnit",
        sort: "asc",
        width: 1,
      },
      //hdcd is a status
      {
        label: "Status",
        field: "orderStage",
        sort: "asc",
        width: 2,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 1,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var AdminHeaderData = {
  columns: [
    {
      label: "Order No",
      field: "orderNo",
      sort: "asc",
      width: 1,
    },
    {
      label: "Order Type",
      field: "orderType",
      sort: "asc",
      width: 1,
    },
    {
      label: "Billed To Name",
      field: "billedToName",
      sort: "asc",
      width: 2,
    },
    {
      label: "Billed To City",
      field: "billedToCity",
      sort: "asc",
      width: 2,
    },
    {
      label: "Billed To State",
      field: "billedToStateName",
      sort: "asc",
      width: 2,
    },
    
    {
      label: "Order Date",
      field: "orderDate",
      sort: "asc",
      width: 1,
    },
    //Baxter Unit is a Warehouse
    {
      label: "Warehouse",
      field: "businessUnit",
      sort: "asc",
      width: 1,
    },
    //hdcd is a status
    {
      label: "Status",
      field: "orderStage",
      sort: "asc",
      width: 2,
    },
    {
      label: "Action",
      field: "action",
      sort: "asc",
      width: 1,
    },
  ],
};

export var searchBar;
searchBar = {
  searchBarData: [
    {
      label: "Order No",
      field: "Order No",
      type: "text",
    },
    {
      label: "Order Type",
      field: "Order Type",
      type: "text",
     },
    // {
    //   label: "order Co",
    //   field: "OrderCo",
    //   type: "text",
    // },
    {
      label: "Billed To Name",
      field: "billed To Name",
      type: "text",
    },
    {
      label: "Billed To City",
      field: "billed To City",
      type: "text",
    },
    {
      label: "Billed To State",
      field: "billed To State",
      type: "text",
    },
    {
      label: "Warehouse",
      field: "warehouse",
      type: "text",
    },
    {
      label: "Status",
      field: "Status",
      type: "select",
    },
    {},
  ],
};
