import React from "react";
import { Label, FormGroup, Input } from "reactstrap";
import Select from "react-select";

export const tableColumn = () => {
  return [
    {
      label: "FullName",
      field: "fullName",
      sort: "asc",
      width: 300,
    },
    {
      label: "Phone",
      field: "phone",
      sort: "asc",
      width: 300,
    },
    {
      label: "Role",
      field: "role",
      sort: "asc",
      width: 300,
    },
    {
      label: "Email",
      field: "email",
      sort: "asc",
      width: 300,
    },
    {
      label: "Hop",
      field: "hop",
      sort: "asc",
      width: 300,
    },
    // {
    //   label: "State",
    //   field: "state",
    //   sort: "asc",
    //   width: 300,
    // },
    // {
    //   label: "City",
    //   field: "city",
    //   sort: "asc",
    //   width: 300,
    // },
    // {
    //   label: "Address",
    //   field: "address",
    //   sort: "asc",
    //   width: 300,
    // },
    // {
    //   label: "ZipCode",
    //   field: "zipCode",
    //   sort: "asc",
    //   width: 300,
    // },
    {
      label: "Status",
      field: "status",
      sort: "asc",
      width: 100,
    },
  ];
};

export const renderStateList = () => {
  return [
    {
      label: "Andaman and Nicobar Islands",
      value: "Andaman and Nicobar Islands",
    },
    { label: "Andhra Pradesh", value: "Andhra Pradesh" },
    { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
    { label: "Assam", value: "Assam" },
    { label: "Bihar", value: "Bihar" },
    { label: "Chandigarh", value: "Chandigarh" },
    { label: "Chhattisgarh", value: "Chhattisgarh" },
    {
      label: "Dadra and Nagar Haveli and Daman and Diu",
      value: "Dadra and Nagar Haveli and Daman and Diu",
    },
    { label: "Delhi", value: "Delhi" },
    { label: "Goa", value: "Goa" },
    { label: "Gujarat", value: "Gujarat" },
    { label: "Haryana", value: "Haryana" },
    { label: "Himachal Pradesh", value: "Himachal Pradesh" },
    { label: "Jammu and Kashmir", value: "Jammu and Kashmir" },
    { label: "Jharkhand", value: "Jharkhand" },
    { label: "Karnataka", value: "Karnataka" },
    { label: "Kerala", value: "Kerala" },
    { label: "Ladakh", value: "Ladakh" },
    { label: "Lakshadweep", value: "Lakshadweep" },
    { label: "Madhya Pradesh", value: "Madhya Pradesh" },
    { label: "Maharashtra", value: "Maharashtra" },
    { label: "Manipur", value: "Manipur" },
    { label: "Meghalaya", value: "Meghalaya" },
    { label: "Mizoram", value: "Mizoram" },
    { label: "Nagaland", value: "Nagaland" },
    { label: "Odisha", value: "Odisha" },
    { label: "Puducherry", value: "Puducherry" },
    { label: "Punjab", value: "Punjab" },
    { label: "Rajasthan", value: "Rajasthan" },
    { label: "Sikkim", value: "Sikkim" },
    { label: "Tamil Nadu", value: "Tamil Nadu" },
    { label: "Telangana", value: "Telangana" },
    { label: "Tripura", value: "Tripura" },
    { label: "Uttar Pradesh", value: "Uttar Pradesh" },
    { label: "Uttarakhand", value: "Uttarakhand" },
    { label: "West Bengal", value: "West Bengal" },
  ];
};

const validateEmail = (val) => {
  return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(val);
};

export const validateField = (name, value, refValue) => {
  let errorMsg = null;
  switch (name) {
    case "name":
      if (!value) errorMsg = "Please Enter Full Name.";
      break;
    case "reportsTo":
      if (!value) errorMsg = "Please Select Reporting Authority.";
      break;
    case "role":
      if (!value) errorMsg = "Please Select Role.";
      break;
    case "email":
      if (!value) errorMsg = "Please Enter Email.";
      else {
        if (!validateEmail(value)) errorMsg = "Please Enter valid Email.";
      }
      break;
    case "phone":
      if (!value) errorMsg = "Please Enter Phone Number.";
      if (value.length !== 10) errorMsg = "Please Enter Valid Phone Number.";
      break;
    case "zipCode":
      if (!value) errorMsg = "Please Enter ZipCode.";
      else if (value.length < 6 || value.length > 6)
        errorMsg = "ZipCode Must Have 6 Digits.";
      break;
    case "city":
      if (!value) errorMsg = "Please Enter City.";
      break;
    case "state":
      if (!value) errorMsg = "Please Enter State.";
      break;
      case "userType":
        if (!value) errorMsg = "Please Select User Type.";
        break;
    // case "address":
    //   if (!value) errorMsg = "Please Enter Address.";
    //   break;

    default:
      break;
  }
  return errorMsg;
};

export const RenderInputControl = (props) => {
  const { controlType, inputName, labelValue, isRequired, errors } = props;
  return (
    <FormGroup className="select2-container">
      {
        <Label className={isRequired ? "required-field" : ""}>
          {labelValue}
        </Label>
      }
      {controlType === "input" && <RenderInput {...props} />}
      {controlType === "select" && <RenderSelect {...props} />}
      {errors[inputName] && <RenderFormError error={errors[inputName]} />}
    </FormGroup>
  );
};

export const RenderFormError = ({ error }) => {
  return <span className="err">{error}</span>;
};

const RenderInput = (inputProps) => {
  const {
    inputType,
    inputName,
    inputValue,
    onChangeMethod,
    placeholderValue,
    handleKeyPress,
    handleBlur,
  } = inputProps;
  return (
    <Input
      className="form-control"
      type={inputType}
      value={inputValue}
      onChange={(event) =>
        onChangeMethod({
          target: {
            name: inputName,
            value: event.target.value,
          },
        })
      }
      placeholder={placeholderValue}
      onKeyPress={handleKeyPress && handleKeyPress}
      onBlur={(e) => handleBlur && handleBlur(e, inputName)}
    />
  );
};

const RenderSelect = (selectProps) => {
  const {
    inputValue,
    inputName,
    onChangeMethod,
    onInputChange,
    optionList,
    handleScrollBottom,
    handleMenuClose,
    isLoading,
  } = selectProps;
  return (
    <Select
      value={inputValue}
      onChange={(e) =>
        onChangeMethod({
          target: {
            name: inputName,
            value: e.value,
          },
        })
      }
      options={optionList}
      classNamePrefix="select2-selection"
      onMenuScrollToBottom={(event) =>
        handleScrollBottom && handleScrollBottom(event)
      }
      onInputChange={onInputChange}
      onMenuClose={(event) => handleMenuClose && handleMenuClose(event)}
      isLoading={isLoading}
    />
  );
};
