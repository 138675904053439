import { types } from "../actionTypes";

const initialState = {
  reports: [],
  fetchId: "",
  shareOfBusiness: {},
};

export default (state = { initialState }, action) => {
  switch (action.type) {
    case types.FETCH_REPORTS_SUCCESS:
      return { reports: action.payload, fetchId: "id" + new Date().getTime() };

    case types.FETCH_INDENTLANE_REPORTS_SUCCESS:
      return {
        laneIndentReport: action.payload,
        fetchId: "id" + new Date().getTime(),
      };

    case types.FETCH_INDENTSTATUS_REPORTS_SUCCESS:
      return {
        statusIndentReport: action.payload,
        fetchId: "id" + new Date().getTime(),
      };

    case types.FETCH_INDENTTRANSPORTER_REPORTS_SUCCESS:
      return {
        transporterIndentReport: action.payload,
        fetchId: "id" + new Date().getTime(),
      };

    case types.FETCH_TRANSPORTER_PERFORMANCE_SUCCESS:
      return { reports: action.payload, fetchId: "id" + new Date().getTime() };
    case types.FETCH_SHARE_OF_BUSINESS_SUCCESS:
      return {
        shareOfBusiness: action.payload,
        fetchId: "id" + new Date().getTime(),
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};
