import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";
import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  CardHeader,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import {
  fetchTripById,
  trackLocationTripById,
  fetchSignedURL_ShipmentDamaged,
  fetchSignedURL_ShimentShortage,
} from "../../../store/trip/actions";
import { uploadAgreement } from "../../../store/actions";
// import formatDate from "../../DateFormatter";
import { MDBBtn, MDBCollapse } from "mdbreact";
import { Grid } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
// import files
import IsItemDamaged from "./IsItemDamaged";
import IsItemShortage from "./IsItemShortage";
import { ThreeSixtySharp } from "@material-ui/icons";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
//global varibles
var fileObj = [];
var fileArray = [];
var fileObjShortage = [];
var fileArrayShortage = [];
class DamageShortageDetails extends Component {
  constructor(props) {
    super();
    this.state = {
      tripDetail: [],
      tripId: "",
      trackTripById: [],
      shipment: [],
      tripStatusTransitionLogs: [],
      qualityImage: [],
      qualities: {},
      collapse: false,
      activeTab: "1",
      TripShipId: "",
      sourceObjectId: "",
      isDamaged: true,
      isShortage: true,
      // signedURL: "",
      // selectedFile: [],
      uploadData: "",
      fileFlag: false,
      baseImage: [],
      images: [],
      selectedFile: [],
      selectedFileShortage: [],
      baseImage: [],
      baseImageShortage: [],
      imagesDamaged: [],
      imagesShortage: [],
      damagedQuantity: "",
      shortageQuantity: "",
      damagedDescription: "",
      shortageDescription: "",
      errors: {},
    };
    this.toggle = this.toggle.bind(this);

    // console.log(this, "stateDataship");
  }
  uploadImage = async (e) => {
    const name = e.target.name;
    // console.log("e", e);
    const file = e.target.files[0];
    if (name === "IsItemDamaged") {
      const base64 = await this.convertBase64(file);
      this.setState({
        baseImage: [...this.state.baseImage, base64],
      });
    } else if (name === "IsItemShortage") {
      // console.log("Calling shortage");
      const base64 = await this.convertBase64(file);
      this.setState(
        {
          baseImageShortage: [...this.state.baseImageShortage, base64],
        },
        () => [console.log("baseImageShortage", this.state.baseImageShortage)]
      );
    }

    // console.log("base Image", this.state.baseImage);
  };

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  S3Upload = async (type) => {
    const shipmentId = this.props.shipmentDataDamaged?.shipmentId;
    const itemId = this.props.shipmentItemData?.itemId;
    // console.log("shipmentId", shipmentId);
    // console.log("item ID", itemId);
    if (type.name === "IsItemDamaged") {
      this.props.fetchSignedURL_ShipmentDamaged(shipmentId, itemId, (res) => {
        for (
          let i = this.state.selectedFile.length - 1;
          i < this.state.selectedFile.length;
          i++
        ) {
          let softCopy = "";
          // console.log("Calling Signed",res)
          softCopy = res.presignedS3Url.split("?")[0];
          // console.log("uploadURL", softCopy);
          this.setState(
            {
              imagesDamaged: [...this.state.imagesDamaged, softCopy],
            },
            () => {
              // console.log("images Front Copy", this.state.imagesDamaged);
              const formData = new FormData();
              formData.append(
                this.state.selectedFile[i],
                this.state.selectedFile[i].name
              );
              // console.log("--------", formData);
              const myHeaders = new Headers();
              myHeaders.append("Content-Type", this.state.selectedFile[i].type);
              this.props.uploadAgreement(
                res.presignedS3Url,
                this.state.selectedFile[i],
                myHeaders
              );
            }
          );
        }
      });
    } else if (type.name === "IsItemShortage") {
      this.props.fetchSignedURL_ShimentShortage(shipmentId, itemId, (res) => {
        for (
          let i = this.state.selectedFileShortage.length - 1;
          i < this.state.selectedFileShortage.length;
          i++
        ) {
          let softCopy = "";
          // console.log("Calling Signed",res)
          softCopy = res.presignedS3Url.split("?")[0];
          // console.log("uploadURL", softCopy);
          this.setState(
            {
              imagesShortage: [...this.state.imagesShortage, softCopy],
            },
            () => {
              // console.log(" ", this.state.imagesShortage);
              const formData = new FormData();
              formData.append(
                this.state.selectedFileShortage[i],
                this.state.selectedFileShortage[i].name
              );
              // console.log("--------", formData);
              const myHeaders = new Headers();
              myHeaders.append(
                "Content-Type",
                this.state.selectedFileShortage[i].type
              );
              this.props.uploadAgreement(
                res.presignedS3Url,
                this.state.selectedFileShortage[i],
                myHeaders
              );
            }
          );
        }
      });
    }
  };
  fileUpload = (event) => {
    // console.log("event", event);
    // // console.log("EVENT console", event.target.files);
    let name = event.target.name;
    if (name === "IsItemDamaged") {
      if (event.target.files.length) {
        this.uploadImage(event);
        fileObj.push(event.target.files);
        for (let i = 0; i < fileObj[0].length; i++) {
          fileArray.push(event.target.files["0"]);
        }
        this.setState({ selectedFile: fileArray, fileFlag: true }, () => {
          // console.log("selectedFile damaged", this.state.selectedFile);
          this.S3Upload({ name: "IsItemDamaged" });
        });
      }
    } else if (name === "IsItemShortage") {
      if (event.target.files.length) {
        this.uploadImage(event);
        fileObjShortage.push(event.target.files);
        for (let i = 0; i < fileObjShortage[0].length; i++) {
          fileArrayShortage.push(event.target.files["0"]);
        }
        this.setState({ selectedFileShortage: fileArrayShortage }, () => {
          // console.log(
          //   "selectedFile shoratged",
          //   this.state.selectedFileShortage
          // );
          this.S3Upload({ name: "IsItemShortage" });
        });
      }
    }
  };
  deleteImage = (item, index, type) => {
    if (type?.name === "IsItemDamaged") {
      let deleteImage = this.state.baseImage.filter((image, i) => index !== i);
      let deleteImageSelectedFiles = this.state.selectedFile.filter(
        (image, i) => index !== i
      );
      let deleteSignedUrl = this.state.imagesDamaged.filter(
        (image, i) => index !== i
      );
      this.setState({
        baseImage: deleteImage,
        selectedFile: deleteImageSelectedFiles,
        imagesDamaged: deleteSignedUrl,
      });
    } else if (type?.name === "IsItemShortage") {
      let deleteImage = this.state.baseImageShortage.filter(
        (image, i) => index !== i
      );
      let deleteImageSelectedFiles = this.state.selectedFileShortage.filter(
        (image, i) => index !== i
      );
      let deleteSignedUrl = this.state.imagesShortage.filter(
        (image, i) => index !== i
      );
      this.setState({
        baseImageShortage: deleteImage,
        selectedFileShortage: deleteImageSelectedFiles,
        imagesShortage: deleteSignedUrl,
      });
    }
  };
  onChangeDamaged = (e) => {
    // console.log("e", e);
    let name = e.target.name;
    let value = e.target.value;
    if (name === "damagedDescription") {
      this.setState({
        damagedDescription: e.target.value,
      });
    } else if (name === "damagedQuantity") {
      this.setState({
        damagedQuantity: e.target.value,
      });
    }
  };
  onChangeShortage = (e) => {
    // console.log("e", e.target.value);
    let name = e.target.name;
    let value = e.target.value;
    if (name === "shortageQuantity") {
      this.setState({
        shortageQuantity: e.target.value,
      });
    } else if (name === "shortageDescription") {
      this.setState({
        shortageDescription: e.target.value,
      });
    }
  };
  validateValAdd = () => {
    const errorsAdd = {};
    let {
      isDamaged,
      isShortage,
      shortageDescription,
      damagedDescription,
      shortageQuantity,
      damagedQuantity,
      imagesDamaged,
      imagesShortage,
      images,
    } = this.state;
    // let image = this.state.urlArrayValues;
    if (isDamaged === true || isShortage === true) {
      errorsAdd.isDamaged = "Please Select Either Yes Or No!";
      NotificationManager.error("Please Select Either Yes Or No!", "failure");
    }
    if (!shortageDescription && isShortage === "true") {
      errorsAdd.shortageDescription = "Shortage Description Is Required!";
    }

    if (!shortageQuantity && isShortage === "true") {
      errorsAdd.shortageQuantity = "Shortage Quantity Is required!";
    }

    if (!damagedDescription && isDamaged === "true") {
      errorsAdd.damagedDescription = "Damaged Description Is Required!";
    }

    if (!damagedQuantity && isDamaged === "true") {
      errorsAdd.damagedQuantity = "Damaged Quantity Is Required!";
    }

    if (!imagesDamaged.length && isDamaged === "true") {
      errorsAdd.imagesDamaged = "Damaged Image Is Required!";
    }

    if (!imagesShortage.length && isShortage === "true") {
      errorsAdd.imagesShortage = "Shortage Image Is Required!";
    }

    return errorsAdd;
  };
  handleSubmit = () => {
    // console.log("Calling submit");
    let itemDetails;
    let shipmentId = this.props.shipmentDataDamaged?.shipmentId;

    this.setState({ errors: this.validateValAdd() }, () => {
      // console.log("call", Object.keys(this.state.errors).length);
      if (Object.keys(this.state.errors).length === 0) {
        if (
          shipmentId !== undefined &&
          this.state.shipmentItemData !== undefined
        ) {
          const itemData = this.state.shipmentItemData;
          itemDetails = {
            shipmentId: shipmentId,
            itemDetails: {
              cost: itemData?.cost,
              description: itemData?.description,
              itemId: itemData?.itemId,
              itemNo: itemData?.itemNo,
              lotNo: itemData?.lotNo,
              orgID: itemData?.orgID,
              quantity: itemData?.quantity,
              _id: itemData?._id,
              epod: {
                damagedQuantity: this.state.damagedQuantity,
                shortageQuantity: this.state.shortageQuantity,
                damageImages: this.state.imagesDamaged,
                shortageImages: this.state.imagesShortage,
                damageComment: this.state.damagedDescription,
                shortageComment: this.state.shortageDescription,
              },
            },
          };
        }

        // console.log("item Deatils", itemDetails);
        this.props.handleSubmitDamged(itemDetails);
        this.handleReset();
        this.props.toggle();
      
      
      }
    });
  };
  handleReset = () => {
    this.setState({
      baseImage: [],
      baseImageShortage: [],
      selectedFile: [],
      selectedFileShortage: [],
      imagesDamaged: [],
      imagesShortage: [],
      damagedQuantity: "",
      damagedDescription: "",
      shortageDescription: "",
      shortageQuantity: "",
      shipmentItemData: {},
      isDamaged: true,
      isShortage: true,
      errors: {},
    });
  };
  componentDidMount = () => {
    // console.log(this.props.editTrip, "tripdatapage");
    let tripId = this.props?.id;
    let trip = [];
    this.setState(
      {
        shipmentItemData: this.props.shipmentItemData,
      },
      () => {
        // console.log("this.state.shipmentItemData", this.state.shipmentItemData);
      }
    );
    this.setState({ tripId: tripId }, () => {
      // this.props.fetchTripById(tripId, (ele) => {
      //   this.setState({
      //     TripShipId: ele?.data[0]?.tripId ? ele?.data[0]?.tripId : "",
      //   });
      //   if (ele.data[0].epodDetails.length) {
      //     this.setState(
      //       {
      //         qualities: ele.data[0].qualityCheck.answers,
      //         qualityImage: ele.data[0]?.qualityCheck?.images || [],
      //         shipment: ele.data[0].epodDetails
      //           ? ele.data[0].epodDetails[0].shipments
      //           : "---",
      //         tripStatusTransitionLogs: ele?.data[0]?.tripStatusTransitionLogs
      //           ? ele?.data[0]?.tripStatusTransitionLogs
      //           : "",
      //       },
      //       () => {
      //         console.log(this.state.qualities, "callbackqualityState");
      //       }
      //     );
      //   } else {
      //     this.setState(
      //       {
      //         qualities: ele.data[0].qualityCheck.answers,
      //         qualityImage: ele.data[0]?.qualityCheck?.images || [],
      //         tripStatusTransitionLogs: ele?.data[0]?.tripStatusTransitionLogs,
      //       },
      //       () => {
      //         console.log(ele.data[0].qualityCheck, "callbackqualityCheck");
      //       }
      //     );
      //   }
      //   trip.push({
      //     quality: ele.data[0].qualityCheck,
      //     shipment:
      //       ele.data[0].epodDetails.length > 0
      //         ? ele.data[0].epodDetails[0].shipments
      //         : [],
      //   });
      //   this.setState({ tripDetail: trip });
      // });
    });
  };
  handleChangeAnswers = (e) => {
    // console.log("Under Testing", e.target.value, e.target.name);
    const { name, value } = e.target;
    if (name === "isDamaged") {
      this.setState(
        {
          isDamaged: value,
        },
        () => {
          if (
            this.state.isDamaged === "false" &&
            this.state.isShortage === "false"
          ) {
            this.setState({ errors: {} });
          }
        }
      );
    } else if (name === "isShortaged") {
      this.setState({
        isShortage: value,
      },
      () => {
        if (
          this.state.isDamaged === "false" &&
          this.state.isShortage === "false"
        ) {
          this.setState({ errors: {} });
        }
      }
      );
    }
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps?.shipmentItemData !== this.state.shipmentItemData) {
      this.setState(
        {
          shipmentItemData: this.props?.shipmentItemData,
        },
        () => {
          // console.log(
          //   "this.props?.shipmentItemData",
          //   this.props?.shipmentItemData
          // );
        }
      );
    }
    // if (this.props.editTrip && !this.state.tripId) {
    //   this.setState(
    //     { tripDetail: this.props.editTrip, tripId: this.props.editTrip._id },
    //     () => {
    //       console.log(
    //         "TRIPDETAIL",
    //         this.state.tripDetail,
    //         "+++++",
    //         this.state.tripId
    //       );
    //     }
    //   );
    // }
  };

  downloadImage = (image) => {
    fetch(image[0])
      .then((res) => res.blob())
      .then((blob) => {
        var element = document.createElement("a");
        element.href = URL.createObjectURL(blob);
        element.download = "image.jpg";
        element.click();
      });
  };
  toggle = (index) => {
    this.setState((prevState) => ({
      expandIndex: prevState.expandIndex !== index ? index : "",
    }));
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  isDamaged = (e) => {
    // console.log(e.target.value, "RADIO");
  };

  render() {
    const { errors } = this.state;
    // console.log(
    //   "Damaged Images",
    //   this.state.shipmentItemData?.epod?.damageImages
    // );
    const shipmentId = this.props.shipmentDataDamaged?.shipmentId;
    const itemId = this.props.shipmentItemData?.itemId;
    // console.log("shipmentId", shipmentId);
    // console.log("item deatils", this.props.shipmentItemData);
    // console.log("item ID", itemId);
    // console.log("TRIP BY ID", this.props?.tripDetails);
    // console.log("images", this.state.imagesDamaged, this.state.imagesShortage);
    let rows = [];
    var locationRows = [];
    var lrCopy = [];
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let darkState = this.props?.darkTheme;

    // if (Array.isArray(this.props.tripDetails?.data)) {
    //   this.props.tripDetails.data.forEach((ele) => {
    //     if (ele.epodDetails.length > 0) {
    //       lrCopy.push({
    //         lrFront:
    //           ele.epodDetails.length > 0
    //             ? ele.epodDetails[0]?.lrImages?.front
    //             : "---",
    //         lrBack:
    //           ele.epodDetails.length > 0
    //             ? ele.epodDetails[0]?.lrImages?.back
    //             : "---",
    //         unloadingReceiptImages:
    //           ele.epodDetails.length > 0
    //             ? ele.epodDetails[0].unloadingReceiptImages
    //             : [],
    //         qualityImage: ele.qualityCheck ? ele.qualityCheck.images : "----",
    //         unloaded:
    //           ele.epodDetails.length > 0
    //             ? ele.epodDetails[0]?.createdBy?.name
    //             : "---",
    //       });
    //     }
    //   });
    // }

    let shipped = [];

    let ship =
      this.props.shipmentData?.length > 0
        ? this.props.shipmentData?.map((t1) => {
            if (t1) {
              let verifyData = [];
              let curStatus = this.state?.tripStatusTransitionLogs?.filter(
                (logs) => logs.curStatus == "shipment_verified"
              );
              let match = curStatus?.filter(
                (logs1) => logs1?.hop?._id == t1?.source?._id
              );
              if (match) {
                match.map((updatedByData) =>
                  verifyData.push({
                    updatedBy: updatedByData?.updatedBy?.name
                      ? updatedByData?.updatedBy?.name
                      : "----",
                    updatedAt:
                      updatedByData?.hop.label +
                      "-" +
                      updatedByData?.hop.hopCode
                        ? updatedByData?.hop.label +
                          "-" +
                          updatedByData?.hop.hopCode
                        : "---",
                  })
                );
              }

              shipped = [
                ...shipped,
                {
                  shipid: t1.shipmentId,
                  shipSourceId: t1.source._id,
                  updatedverifyBy: verifyData ? verifyData : "",
                  source: t1.source.label,
                  destination: t1.destination.label,
                  items: t1.items,
                  copy: lrCopy,
                },
              ];
            }
          })
        : null;

    // console.log(shipped, "shipped");
    const evenRowStyle = {
      minHeight: "57px",
      width: "101%",
      backgroundColor: darkState ? "#2E2E46" : "#F1F5F7",
      border: "0.5px solid rgba(185, 185, 185, 0.5)",
      borderRadius: "5px",
      margin: "auto",
      marginTop: "4px",
      marginRight: "3px",
    };
    const modalStyle = {
      height: "100%",
      width: "100%",
      backgroundColor: darkState ? "#1B1C31" : "#ffffff",
      padding: "25px",
      position: "relative",
      border: darkState ? "border: 0.5px solid #616161" : "#ffffff",
    };
    const headerStyle = {
      position: "relative",
      fontFamily: "Roboto",
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "23px",
      color: darkState ? "#9D9D9D" : "#0C0C0C",
      left: "0px",
    };
    const closeStyle = {
      position: "relative",
      color: darkState ? "#B7A9FF" : "#2800fc",
      width: "20px",
      marginRight: "0px",
    };
    const rowsTopSpaceStyle = { marginTop: "0px" };
    const dataBoxStyle = {
      height: "400px",
      overflowY: "scroll",
      overflowX: "clip",
    };
    const oddRowStyle = {
      minHeight: "57px",
      width: "101%",
      backgroundColor: "#ffffff",
      border: "0.5px solid rgba(185, 185, 185, 0.5)",
      borderRadius: "5px",
      margin: "auto",
      marginTop: "4px",

      marginRight: "3px",
      //position:'relative',
    };

    const rowHeaderStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "15px",
      color: "#05284E",
      lineHeight: "18px",
      margin: "auto",
      paddingLeft: "1.5vw",
    };
    const rowSeparaterStyle = {
      borderLeft: "0.5px solid #B9B9B9",
      height: "100%",
    };
    const rowValueStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      color: "#0C0C0C",
      margin: "auto",
      flex: "wrap",
    };

    // console.log("SHIPMENT", this.props.lrStatus.lrCopy);

    return (
      <React.Fragment>
        <MDBContainer className="text-capitalize DamagedShortageDetails">
          <MDBModal
            isOpen={this.props.isOpen}
            toggle={() => this.props.toggle()}
            size="xl"
          >
            <MDBModalBody>
              <div className="indentModal">
                <Card style={modalStyle}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <span style={headerStyle}>
                        <p>Damage & Shortage Details</p>
                      </span>
                    </div>
                    <svg
                      onClick={() => this.props.closeModal()}
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 1L1 13"
                        stroke="#2800FC"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1 1L13 13"
                        stroke="#2800FC"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {/* <CloseIcon
                      style={closeStyle}
                      onClick={() => this.props.closeModal()}
                    />{" "} */}
                  </div>

                  <Grid style={rowsTopSpaceStyle}></Grid>
                  <Row>
                    <Col xs={12}>
                      <div
                        className="fontRoboto1541"
                        style={{
                          color: "#05284E",
                        }}
                      >
                        <div className="">
                          <span className="fontRoboto154 light__span">
                            {userLang?.trip?.TRIP_ID} :{" "}
                          </span>
                          <span className="fontRoboto154 dark__span">
                            {this.props?.tripDetailsForEPOD
                              ? this.props?.tripDetailsForEPOD?.tripId
                              : ""}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyContent="start"
                      className="mt-1 mb-2"
                    >
                      <Grid item xs={11} md={3}>
                        <span className="shipmentHeadingText darkText">
                          <span className="fontRoboto154 light__span">
                            Lot No:
                          </span>
                          <span className="fontRoboto154 dark__span">
                            {this.state.shipmentItemData?.lotNo}
                          </span>
                        </span>
                      </Grid>
                      <Grid
                        item
                        // xs={1}
                        style={{
                          padding: "2vh 0px",
                          marginLeft: "5px",
                          marginTop: "11px",
                          backgroundColor: "#B9B9B9",
                        }}
                      >
                        <div
                          className="desktopDisplay"
                          style={rowSeparaterStyle}
                        ></div>
                      </Grid>
                      <Grid item xs={11} md={3}>
                        <span className="shipmentHeadingText darkText">
                          <span className="fontRoboto154 light__span">
                            Item No:
                          </span>
                          <span className="fontRoboto154 dark__span">
                            {this.state.shipmentItemData?.itemNo}
                          </span>
                        </span>
                      </Grid>
                      <Grid
                        item
                        // xs={1}
                        style={{
                          padding: "2vh 0px",
                          marginLeft: "-2px",
                          marginTop: "11px",
                          backgroundColor: "#B9B9B9",
                        }}
                      >
                        <div
                          className="desktopDisplay"
                          style={rowSeparaterStyle}
                        ></div>
                      </Grid>
                      <Grid item xs={11} md={3}>
                        {" "}
                        <span className="fontRoboto154 light__span">
                          Item Description
                        </span>
                        <span className="fontRoboto154 dark__span">
                          {this.state.shipmentItemData?.description}{" "}
                        </span>
                      </Grid>
                      <Grid
                        item
                        // xs={1}

                        style={{
                          padding: "2vh 0px",
                          marginLeft: "-2px",
                          marginTop: "11px",
                          backgroundColor: "#B9B9B9",
                        }}
                      >
                        <div
                          className="desktopDisplay"
                          style={rowSeparaterStyle}
                        ></div>
                      </Grid>
                      <Grid item xs={11} md={2}>
                        {" "}
                        <span className="fontRoboto154 light__span">
                          Item Quatity :
                        </span>
                        <span className="fontRoboto154 dark__span">
                          {this.state.shipmentItemData?.quantity}
                        </span>
                      </Grid>
                    </Grid>
                  </div>

                  <div>
                    <Row className="mt-3">
                      <Col xs={12} md={9}>
                        <div style={{ marginRight: "2vw" }}>
                          <Grid
                            className="shadow indentData-hover"
                            container
                            style={evenRowStyle}
                          >
                            <Grid
                              className=""
                              item
                              xs={7}
                              md={9}
                              style={rowHeaderStyle}
                            >
                              <span className="fontRoboto15 dark__span">
                                Is Item Damaged?
                              </span>
                            </Grid>
                            <Grid
                              item
                              // xs={1}
                              style={{
                                padding: "2vh 0px",
                                marginLeft: "-20px",
                              }}
                            >
                              <div style={rowSeparaterStyle}></div>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              md={2}
                              style={rowValueStyle}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <>
                                <ul
                                  style={{
                                    marginTop: "1rem",
                                    marginLeft: "-13px",
                                  }}
                                >
                                  <div className="form-check form-check-inline">
                                    <Row>
                                      <Col
                                        xs="12"
                                        md={6}
                                        className="text-nowrap"
                                      >
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={"isDamaged"}
                                          // id={data.key}
                                          value="true"
                                          onChange={(e) => {
                                            this.handleChangeAnswers({
                                              target: {
                                                name: "isDamaged",
                                                value: e.target.value,
                                              },
                                            });
                                          }}
                                        />
                                        <label
                                          className="form-check-label dark__span"
                                          // htmlFor={data.key}
                                        >
                                          Yes
                                        </label>
                                      </Col>
                                      <Col
                                        xs="12"
                                        md={6}
                                        className="text-nowrap"
                                      >
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          value="false"
                                          name={"isDamaged"}
                                          onChange={(e) => {
                                            this.handleChangeAnswers({
                                              target: {
                                                name: "isDamaged",
                                                value: e.target.value,
                                              },
                                            });
                                          }}
                                        />
                                        <label className="form-check-label dark__span">
                                          No
                                        </label>
                                      </Col>
                                    </Row>
                                  </div>
                                </ul>
                              </>
                            </Grid>
                          </Grid>
                        </div>
                        <IsItemDamaged
                          key={this.state.itemId}
                          fileUpload={this.fileUpload}
                          baseImage={
                            this.state.shipmentItemData?.epod?.damageImages
                              ?.length > 0
                              ? this.state.shipmentItemData?.epod?.damageImages
                              : this.state.baseImage
                          }
                          deleteImage={this.deleteImage}
                          isDamaged={this.state.isDamaged}
                          error={this.state.errors}
                        ></IsItemDamaged>
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        className="pdt10"
                        disabled={
                          this.state.isDamaged === "false" ? true : false
                        }
                      >
                        <Grid item>
                          <Card className="addAgreementCard darkMultipleCharges">
                            <div>
                              <span className="fontRoboto15 light__span">
                                Enter the Damaged Quantity
                              </span>
                            </div>
                            <div>
                              <Row>
                                <Col xs={12} md={12}>
                                  <input
                                    className="inputBox"
                                    type="number"
                                    value={
                                      this.state.damagedQuantity ||
                                      this.state.shipmentItemData?.epod
                                        ?.damagedQuantity
                                    }
                                    onChange={(e) => {
                                      this.onChangeDamaged({
                                        target: {
                                          name: "damagedQuantity",
                                          value: e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                  {errors?.damagedQuantity && (
                                    <div className="error">
                                      {errors?.damagedQuantity}{" "}
                                    </div>
                                  )}
                                </Col>
                                {/* <Col xs={3} md={2}>
                                  <div>
                                    <Button
                                      className="dark__span"
                                      style={{
                                        height: "30px",
                                        marginBottom: "10px",
                                        backgroundColor: "#05284E",
                                        color: "white",
                                        position: "relative",
                                        left: "-11px",
                                      }}
                                    >
                                      Yes
                                    </Button>
                                  </div>
                                </Col> */}
                              </Row>
                              <Row className="mt-2">
                                <Col xs={12}>
                                  <textarea
                                    style={{
                                      resize: "none",
                                    }}
                                    value={
                                      this.state.damagedDescription ||
                                      this.state.shipmentItemData?.epod
                                        ?.damageComment
                                    }
                                    onChange={(e) => {
                                      this.onChangeDamaged({
                                        target: {
                                          name: "damagedDescription",
                                          value: e.target.value,
                                        },
                                      });
                                    }}
                                    className="form-control fontRoboto12 darkTexBox"
                                    id="exampleFormControlTextarea1"
                                    rows="6"
                                    placeholder="Type Damaged   Discription Here"
                                  />
                                </Col>
                                {errors?.damagedDescription && (
                                  <div className="error">
                                    {errors?.damagedDescription}{" "}
                                  </div>
                                )}
                              </Row>
                            </div>
                          </Card>
                        </Grid>
                      </Col>
                    </Row>
                    <Row className="mt-3 mobilePaddingBox">
                      <Col xs={12} md={9}>
                        <div style={{ marginRight: "2vw" }}>
                          <Grid
                            className="shadow indentData-hover"
                            container
                            style={evenRowStyle}
                          >
                            <Grid
                              className=""
                              item
                              xs={7}
                              md={9}
                              style={rowHeaderStyle}
                            >
                              <span className="fontRoboto15 dark__span">
                                Is Item Shortage?
                              </span>
                            </Grid>
                            <Grid
                              item
                              // xs={1}
                              style={{
                                padding: "2vh 0px",
                                marginLeft: "-20px",
                              }}
                            >
                              <div style={rowSeparaterStyle}></div>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              md={2}
                              style={rowValueStyle}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <>
                                <ul
                                  style={{
                                    marginTop: "1rem",
                                    marginLeft: "-13px",
                                  }}
                                >
                                  <div className="form-check form-check-inline">
                                    <Row>
                                      <Col
                                        xs="12"
                                        md={6}
                                        className="text-nowrap"
                                      >
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name={"isShortaged"}
                                          value="true"
                                          onChange={(e) => {
                                            this.handleChangeAnswers({
                                              target: {
                                                name: "isShortaged",
                                                value: e.target.value,
                                              },
                                            });
                                          }}
                                        />
                                        <label className="form-check-label dark__span">
                                          Yes
                                        </label>
                                      </Col>
                                      <Col
                                        xs="12"
                                        md={6}
                                        className="text-nowrap"
                                      >
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          value="false"
                                          name={"isShortaged"}
                                          onChange={(e) => {
                                            this.handleChangeAnswers({
                                              target: {
                                                name: "isShortaged",
                                                value: e.target.value,
                                              },
                                            });
                                          }}
                                        />
                                        <label className="form-check-label dark__span">
                                          No
                                        </label>
                                      </Col>
                                    </Row>
                                  </div>
                                </ul>
                              </>
                            </Grid>
                          </Grid>
                        </div>
                        <IsItemShortage
                          key={this.state.itemId}
                          fileUpload={this.fileUpload}
                          baseImageShortage={
                            this.state.shipmentItemData?.epod?.shortageImages
                              ? this.state.shipmentItemData?.epod
                                  ?.shortageImages
                              : this.state.baseImageShortage
                          }
                          deleteImage={this.deleteImage}
                          isShortage={this.state.isShortage}
                          error={this.state.errors}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={3}
                        className="pdt10"
                        disabled={
                          this.state.isShortage === "false" ? true : false
                        }
                      >
                        <Grid item>
                          <Card className="addAgreementCard darkMultipleCharges mt-4">
                            <div>
                              <span className="fontRoboto15 light__span">
                                Enter the Shortage Quantity
                              </span>
                            </div>
                            <div>
                              <Row>
                                <Col xs={12} md={12}>
                                  <input
                                    className="inputBox"
                                    type="number"
                                    value={
                                      this.state.shortageQuantity ||
                                      this.state.shipmentItemData?.epod
                                        ?.shortageQuantity
                                    }
                                    onChange={(e) => {
                                      this.onChangeShortage({
                                        target: {
                                          name: "shortageQuantity",
                                          value: e.target.value,
                                        },
                                      });
                                    }}
                                  />
                                  {errors?.shortageQuantity && (
                                    <div className="error">
                                      {errors?.shortageQuantity}{" "}
                                    </div>
                                  )}
                                </Col>
                                {/* <Col xs={3} md={2}>
                                  <div>
                                    <Button
                                      className="dark__span"
                                      style={{
                                        height: "30px",
                                        marginBottom: "10px",
                                        backgroundColor: "#05284E",
                                        color: "white",
                                        position: "relative",
                                        left: "-11px",
                                      }}
                                    >
                                      Yes
                                    </Button>
                                  </div>
                                </Col> */}
                              </Row>
                              <Row className="mt-2">
                                <Col xs={12}>
                                  <textarea
                                    style={{
                                      resize: "none",
                                    }}
                                    value={
                                      this.state.shortageDescription ||
                                      this.state.shipmentItemData?.epod
                                        ?.shortageComment
                                    }
                                    onChange={(e) => {
                                      this.onChangeShortage({
                                        target: {
                                          name: "shortageDescription",
                                          value: e.target.value,
                                        },
                                      });
                                    }}
                                    className="form-control fontRoboto12 darkTexBox"
                                    id="exampleFormControlTextarea1"
                                    rows="6"
                                    placeholder="Type  Shortage Discription Here"
                                  />
                                  {errors?.shortageDescription && (
                                    <div className="error">
                                      {errors?.shortageDescription}{" "}
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        </Grid>
                      </Col>
                    </Row>

                    <div className=" mb-3 mt-3 mobilePaddingBox">
                      <MDBBtn
                        className="resetButtonStyle fontRoboto15"
                        style={{ backgroundColor: "#0AB38C", color: "white" }}
                        onClick={() => this.handleReset()}
                      >
                        Reset
                      </MDBBtn>
                      <MDBBtn
                        className="submitButtonStyle fontRoboto15"
                        style={{
                          backgroundColor: "#05284E",
                          color: "white",
                        }}
                        onClick={() => {
                          this.handleSubmit();
                        }}
                      >
                        Submit
                      </MDBBtn>
                    </div>
                  </div>
                </Card>
              </div>
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    tripById: state.Trip.trips.editTrip,
    locationtripById: state.Trip.trips.locationTrip,
  };
};
export default connect(mapStateToProps, {
  fetchTripById,
  trackLocationTripById,
  fetchSignedURL_ShimentShortage,
  fetchSignedURL_ShipmentDamaged,
  uploadAgreement,
})(DamageShortageDetails);
