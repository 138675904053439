import React, { Component } from "react";
import { Container, Card, CardBody, Row, Col, Button } from "reactstrap";
import { Grid } from "@material-ui/core";
import BillToDetails from "./BillToDetails";
import ShipToDetails from "./ShipToDetails";
import ChargesDetails from "./ChargesDetails";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
class InvoiceDetail extends Component {
  constructor(props) {
    super();
    this.state = {
      mode: "",
      invoiceData: "",
    };
  }

  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    if (this.props?.location?.state?.currentInvoice) {
      let data = this.props?.location?.state?.currentInvoice;
      // console.log(data, "data");
      this.setState(
        {
          invoiceData: data,
        },
        () => {
          // console.log(this.state.invoiceData, "invoice");
        }
      );
    }
  };
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let darkState = this.props.darkTheme;

    const rowsTopSpaceStyle = { marginTop: "0px" };
    const dataBoxStyle = {
      height: "100%",
      overFlowY: "auto",
      padding: "0%",
    };
    const oddRowStyle = {
      minHeight: "27px",
      width: "101%",
      margin: "auto",
      marginTop: "4px",
      marginRight: "0px",
    };
    const evenRowStyle = {
      minHeight: "27px",
      width: "101%",
      margin: "auto",
      marginTop: "4px",
      marginRight: "0px",
    };

    const rowHeaderStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "15px",
      color: darkState ? "#9D9D9D" : "#05284E",
      lineHeight: "18px",
      margin: "auto",
      paddingLeft: "1.5vw",
    };
    const rowSeparaterStyle = {
      borderLeft: "0.5px solid rgba(185, 185, 185, .5)",
      height: "172px",
      float: "left",
    };
    const rowSeparaterStyle1 = {
      borderLeft: "0.5px solid rgba(185, 185, 185, .5)",
      height: "355px",
      marginTop: "53px",
      float: "left",
    };
    const rowSeparaterStyle2 = {
      borderLeft: "0.5px solid rgba(185, 185, 185, .5)",
      height: "210px",
      marginTop: "53px",
      float: "left",
    };
    const rowValueStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      color: darkState ? "#9D9D9D" : "#0C0C0C",
      margin: "auto",
      flex: "wrap",
      alignContent: "flex-start",
    };

    const { invoiceData } = this.state;
    // console.log(">>>>>>>>>>>>>>>>>>>>>>>>INVOICE", invoiceData);
    return (
      <React.Fragment>
        <Container fluid={true} className="mt-2">
          <Row>
            <Col xs={9} className="d-flex flex-row bd-highlight mb-2 ">
              <Button
                style={{
                  width: "60px",
                  height: "36px",
                  backgroundColor: "#3e3e3e",
                }}
                color="secondary"
                size="sm"
                onClick={() =>
                  this.props.history.push({
                    pathname: "/InvoiceList",
                  })
                }
              >
                <i className="fas fa-arrow-circle-left fa-2x"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xl="12" className="p-1">
              <Card>
                <CardBody style={{ padding: "2%" }}>
                  <div style={dataBoxStyle}>
                    <div
                      style={{
                        height: "100%",
                        paddingBottom: "12px",
                      }}
                    >
                      <section className="column-container">
                        <div className="col-1-3 pad color3">
                          <Grid className="" container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                Transporter <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.transporter}
                            </Grid>
                          </Grid>

                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                Mode Of Transport{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.modeofTransport}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                Vehicle No <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.vehicleNo}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                Lr No <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.lrNo}
                            </Grid>
                          </Grid>
                          {invoiceData?.isInvoiceCancelled ? (
                            <Grid
                              className=""
                              container
                              spacing={0}
                              style={oddRowStyle}
                            >
                              <Grid
                                // className=""
                                item
                                xs={3}
                                md={6}
                                style={rowHeaderStyle}
                                className="MobilefontRoboto127"
                              >
                                <span>
                                  Status <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={5}
                                style={rowValueStyle}
                                className="MobilefontRoboto124"
                              >
                                <span className="failure">
                                  {invoiceData?.isInvoiceCancelled
                                    ? "Cancelled"
                                    : ""}
                                </span>
                              </Grid>
                            </Grid>
                          ) : (
                            ""
                          )}
                          {invoiceData?.isInvoiceCancelled ? (
                            <Grid
                              className=""
                              container
                              spacing={0}
                              style={oddRowStyle}
                            >
                              <Grid
                                // className=""
                                item
                                xs={3}
                                md={6}
                                style={rowHeaderStyle}
                                className="MobilefontRoboto127"
                              >
                                <span>
                                  Remark <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={5}
                                style={rowValueStyle}
                                className="MobilefontRoboto124"
                              >
                                <span className="failure">
                                  {invoiceData?.isInvoiceCancelled
                                    ? invoiceData?.cancellationComment
                                    : ""}
                                </span>
                              </Grid>
                            </Grid>
                          ) : (
                            ""
                          )}
                        </div>

                        <div style={rowSeparaterStyle}></div>
                        <div className="col-1-3 pad color3">
                          <Grid className="" container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                Preparation DateTime{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.preparationDateTime}
                            </Grid>
                          </Grid>

                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                Removal DateTime{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.removalDateTime}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                Ack NO <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.ackNO}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                AckDt <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.ackDt}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                EWay Bill No{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.eWayBillNo}
                            </Grid>
                          </Grid>
                        </div>
                        <div style={rowSeparaterStyle}></div>
                        <div className="col-1-3 pad color2">
                          <Grid className=" " container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                Legal Invoice No{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.legalInvoiceNo}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                Due Date <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.dueDate}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                IRN No <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.irn}
                            </Grid>
                          </Grid>
                          <Grid className="" container style={evenRowStyle}>
                            <Grid
                              // className=""
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                Lr Date <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.lrDate}
                            </Grid>
                          </Grid>
                        </div>
                      </section>

                      <section class="column-container color1 mt-4">
                        <div className="col-1-3 pad mt-4">
                          <span className="fontRoboto177 text-center shipTo">
                            Order Info
                          </span>
                          <Grid className="mt-4" container style={oddRowStyle}>
                            <Grid
                              // className=""
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                Order Co <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.orders?.orderCo}
                            </Grid>
                          </Grid>

                          <Grid className="" container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                Order Type <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.orders?.orderType}
                            </Grid>
                          </Grid>

                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                Order Number{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.orders?.orderNumber}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                Order Date <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.orders?.orderDate}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                Warehouse <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.orders?.businessUnit}
                            </Grid>
                          </Grid>
                          <Grid container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                Address Number{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.orders?.addressNumber}
                            </Grid>
                          </Grid>
                        </div>

                        <div style={rowSeparaterStyle2}></div>

                        <div class="col-1-3 pad mt-4 mb-2">
                          <Grid className="mt-5" container style={oddRowStyle}>
                            <Grid
                              // className=""
                              item
                              xs={3}
                              md={5}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                Ship To Number{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={7}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.orders?.shipToNumber}
                            </Grid>
                          </Grid>

                          <Grid className="" container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={5}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                Request Date{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={7}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.orders?.requestDate}
                            </Grid>
                          </Grid>

                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={5}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                Original Promised Date{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={7}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.orders?.originalPromisedDate}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={5}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                Reference <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={7}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.orders?.reference}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={5}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                Status <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={7}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.orders?.hdCd}
                            </Grid>
                          </Grid>
                          <Grid container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={5}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                Total Cost <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={7}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.orders?.totalCost}
                            </Grid>
                          </Grid>
                        </div>
                        <div style={rowSeparaterStyle2}></div>

                        <div class="col-1-3 pad mt-4 mb-2">
                          <Grid className="mt-5" container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={5}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                Weight Display Um{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={7}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.orders?.weightDisplayUm}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={5}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                Status <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={7}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.orders?.hdCd}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={5}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                Total Cost <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={7}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.orders?.totalCost}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={5}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span>
                                {" "}
                                Weight Display Um{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={7}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              {invoiceData?.orders?.weightDisplayUm}
                            </Grid>
                          </Grid>
                        </div>
                      </section>

                      <section class="column-container color1 mt-4">
                        <div className="col-1-2 pad ">
                          <span className="fontRoboto177 text-center shipTo mt-4">
                            Bill To Details
                          </span>
                          <BillToDetails currentInvoice={invoiceData} />
                        </div>

                        <div style={rowSeparaterStyle1}></div>

                        <div class="col-1-2 pad">
                          <span className="fontRoboto177  shipTo text-center mt-4">
                            Ship To Details
                          </span>
                          <ShipToDetails currentInvoice={invoiceData} />
                        </div>
                      </section>

                      {/* CHARGES */}
                      {invoiceData?.charges && (
                        <section class="column-container color1 mt-4">
                          <div className="col-1-2 pad ">
                            <span className="fontRoboto177 text-center shipTo mt-4">
                              Charges and Trucktype
                            </span>
                            <ChargesDetails currentInvoice={invoiceData} />
                          </div>
                        </section>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default InvoiceDetail;
