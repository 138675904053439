import { types } from "./actionTypes";
const initialState = {
  getDispatchLeadTimeReport: null,
  fetchId: "",
  bifurcationReportData: {},
  truckUtilizationReportData: {}
};
const dispatchLeadTimeReportReducer = (state = initialState, action) => {
  // console.log(`DispatchLeadTimeReportReducer`,action)
  switch (action.type) {
    case types.DIAPATCH_LEAD_TIME_REPORT_SUCCESS:
      state = {
        ...state,
        getDispatchLeadTimeReport: action.payload,
        fetchId: "id" + new Date().getTime(),
      };
      return state;
    case types.BIFURCATION_REPORT_SUCCESS:
      state = {
        ...state,
        bifurcationReportData: action.payload,
        fetchId: "id" + new Date().getTime(),
      };
      return state;
    case types.TRUCKUTILIZATION_REPORT_SUCCESS:
      state = {
        ...state,
        truckUtilizationReportData: action.payload,
        fetchId: "id" + new Date().getTime(),
      };
      return state;
    default:
      return state;
  }
};
export default dispatchLeadTimeReportReducer;
