import React from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import { connect } from "react-redux";
import { compose } from "redux";

import ThreeDRender from "./ThreeDRender";

class ThreeDModal extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="AddressModalContainer">
          <MDBContainer>
            <MDBModal
              isOpen={this.props.isOpen}
              toggle={() => this.props.toggle()}
              centered
              className=""
            >
              <MDBModalHeader
                toggle={this.toggle}
                style={{
                  borderBottom: "0 none",
                  padding: "15px 0px 0px 20px",
                }}
              >
                <i
                  className="addressModalCloseIcon close"
                  onClick={() => this.props.toggle()}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 1L1 13"
                      stroke="#2800FC"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1 1L13 13"
                      stroke="#2800FC"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </i>
                <span className="modalTitle fontRoboto">
                  {"3 Dimensional Model"}
                </span>
              </MDBModalHeader>
              <MDBModalBody>
                <ThreeDRender modalData={this.props.modalData} />
              </MDBModalBody>
            </MDBModal>
          </MDBContainer>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default compose(connect(mapStateToProps, {}))(ThreeDModal);
