import React, { Component } from "react";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { createVehicle } from "../../store/vehicle/actions";
import { NotificationManager } from "react-notifications";
import { Formik, ErrorMessage } from "formik";
import { fetchAllTransporterSearch } from "../../store/transporter/actions";
import * as Yup from "yup";

import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { connect } from "react-redux";

//Import Breadcrumb
import formatInput from "../InputFormatter";
import { getTransporters } from "../../store/transporter/actions";
import { fetchVehicleTypesList } from "../../store/vehicleType/actions";
import { restrict } from "../restrict";
import { PERMS } from "../../enum/perms.enum";

var offset = 0;
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

const initialState = () => ({
  onSubmitDisable: false,
  offset: 0,
  limit: 20,
  transporter: {},
  transporterData: "",
  breadcrumbItems: [
    { title: "Vehicle", link: "vehicles" },
    { title: "Add Vehicle", link: "#" },
  ],
  form: {
    VehicleOwner: "",
    VehicleRegistrationNumber: "",
    type: "",
    transporter: "",
  },
  isVehicleNoValid: true,
});

class VehicleForm extends Component {
  constructor(props) {
    super(props);
    this.state = initialState();
    if(process.env.REACT_APP_CLIENT != "3_BAXTER"){ 
    (async () => {
      let redirect = await restrict(PERMS.VEHICLE_TRANSPORTER_READ);

      if (redirect === false) {
        this.props.history.push("/operationsDashboard");
      }
    })();
  }
  }

  componentDidMount = () => {
    this.fetchVehicleType();
    this.getAllData();
  };
  getAllData = () => {
    this.props.fetchAllTransporterSearch(
      this.state.offset,
      this.state.limit,
      "",
      (res) => {
        let transporter = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            transporter.push({
              value: ele._id ? ele._id : "",
              label: ele.name ? ele.name : "",
            });
          });
        }
        this.setState(
          {
            transpoter: transporter,
            transporterData: transporter,
          },
          () => {}
        );
      }
    );
  };

  filterTransporterList = (e) => {
    if (e.length > 2) {
      this.props.fetchAllTransporterSearch(
        this.state.offset,
        this.state.limit,
        e,
        (res) => {
          let transporters = [];
          if (res.data?.docs?.length > 0) {
            res.data.docs.forEach((ele) => {
              transporters.push({
                value: ele._id ? ele._id : "",
                label: ele.name ? ele.name : "",
              });
            });
          }
          this.setState(
            {
              transpoter: transporters,
            },
            () => {}
          );
        }
      );
    }
    if (e.length === 0) {
      this.setState(
        {
          transpoter: this.state.transporterData,
        },
        () => {}
      );
    }
  };

  fetchVehicleType = (data) => {
    data = data || {};
    this.props.fetchVehicleTypesList(
      {
        offset,
      },
      (res) => {}
    );
  };
  handleSubmitChange = (e) => {
    //console.log("handleSubmitChange", typeof e.target.value);
    // console.log(e);
    let name = e.target.name;
    let value;
    if (name === "VehicleRegistrationNumber") {
      value = e.target.value.toString().toUpperCase();
    } else {
      value = e.target.value;
    }
    let formData = this.state.form;
    formData[`${name}`] = value;

    this.setState({ form: formData }, () => {});
  };
  validateNumber = (evt) => {
    var theEvent = evt || window.event;

    // Handle paste
    if (theEvent.type === "paste") {
      var key = theEvent.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /^[0-9]*$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };
  trimSpace = (event, name) => {
    const { form } = this.state;
    let formObj = formatInput(event, name, form);
    this.setState({ form: formObj });
  };
  reset = (cb) => {
    // this.setState(initialState(), () => {
    //   cb();
    // });
    this.setState(
      {
        form: {
          VehicleOwner: "",

          VehicleRegistrationNumber: "",
          // type: "",
          transporter: "",
        },
        isVehicleNoValid: true,
      },
      () => {
        this.getAllData();
      }
    );
  };
  handleSubmitSave = (formValues) => {
    let {
      VehicleOwner,

      VehicleRegistrationNumber,
      type,
      transporter,
    } = this.state.form;
    let formObj = {
      ownerName: VehicleOwner,

      regNo: VehicleRegistrationNumber,
      type: type.value?.id,
      transporter: transporter.value,
    };
    // console.log(VehicleRegistrationNumber, "handleSubmitSave");

    if (
      !(
        VehicleRegistrationNumber.match(/^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4}$/) ||
        VehicleRegistrationNumber.match(/^[A-Z]{2}[0-9]{2}[A-Z]{1}[0-9]{4}$/) ||
        VehicleRegistrationNumber.match(/^[A-Z]{2}[0-9]{6}$/) ||
        VehicleRegistrationNumber.match(/^[0-9]{3}[A-Z]{2}[0-9]{3}$/) ||
        VehicleRegistrationNumber.match(/^[0-9]{3}[A-Z]{2}[0-9]{1}$/) ||
        VehicleRegistrationNumber.match(/^[A-Z0-9]{10}$/) ||
        VehicleRegistrationNumber.match(/^[A-Z]{2}[0-9]{2}[A-Z]{3}[0-9]{4}$/)
      )
    ) {
      this.setState({ isVehicleNoValid: false });
      return false;
    } else {
      this.setState({
        isVehicleNoValid: true,
      });
    }
    this.props.createVehicle(formObj, (res) => {
      if (res.success) {
        NotificationManager.success(res["message"], "Success");
        this.props.handleSubmit(false);
        this.props.history.push("/vehicles");
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({ onSubmitDisable: false });
      }
    });
  };
  render() {
    const customStyles1 = {    
      input: base => ({
        ...base,
        color: this.props.mode?"#9d9d9d":"#505d69"
      }),
      
    };
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    const validationSchema = Yup.object({
      VehicleOwner: Yup.string()
        .min(2, userLang?.masters?.TOO_SHORT)
        .required(userLang?.masters?.VEHICLE_OWNER_IS_REQUIRED),
      VehicleRegistrationNumber: Yup.string().required(
        userLang?.masters?.VEHICLE_REGISTRATION_IS_REQUIRED
      ),

      type: Yup.object().required(userLang?.masters?.VEHICLE_TYPE_IS_REQUIRED),
    });
    const { form } = this.state;
    const vehicleType = this.props;
    const role = JSON.parse(localStorage.getItem("authUser"))?.data?.role
      ?.label;
    let type = [];
    let data = vehicleType.vehicleType.vehicleType?.data?.docs;
    if (Array.isArray(data)) {
      if (data.length) {
        data.forEach((ele) => {
          type.push({
            label: ele.label + " - " + ele.vehicleTypeCode,
            value: {
              id: ele._id,
              // latitude: ele.cordinates ? ele.cordinates[0] : null,
              // longitude: ele.cordinates ? ele.cordinates[1] : null,
            },
          });
        });
      }
    }
    // console.log(type, vehicleType.vehicleType.vehicleType);
    return (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <Row>
              <Formik
                md="12"
                initialValues={
                  this.props?.location?.mode === "edit" ? this.formSet() : form
                }
                validationSchema={validationSchema}
                onSubmit={this.handleSubmitSave}
                validateOnChange={false}
              >
                {(formik) => {
                  const {
                    handleSubmit,
                    errors,
                    touched,
                    resetForm,
                  } = formik;

                  return (
                    <form
                      className="form text-capitalize"
                      onSubmit={handleSubmit}
                      style={{
                        width: " 330px",
                        height: "380px",
                        marginTop: "6px",
                      }}
                    >
                      <Row>
                        <Col lg="12">
                          <FormGroup className="select2-container">
                            <Label
                              className={`required-field  mt-2 ${
                                this.props.mode ? "light__span" : ""
                              }`}
                              style={{
                                color: "#05284E",
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: "bold",
                                fontSize: "15px",
                                lineHeight: "18px",
                              }}
                            >
                              {userLang?.tracking?.VEHICLE_OWNER}
                            </Label>

                            <Input
                              type="text"
                              value={form.VehicleOwner}
                              onChange={(e) =>
                                this.handleSubmitChange({
                                  target: {
                                    name: "VehicleOwner",
                                    value: e.target.value,
                                  },
                                })
                              }
                              name="VehicleOwner"
                              id="VehicleOwner"
                              className={
                                errors.VehicleOwner && touched.VehicleOwner
                                  ? "input-error"
                                  : null
                              }
                              placeholder="Vehicle Owner Name"
                              style={{
                                height: "50px",
                                width: " 400px",
                                backgroundColor: this.props.mode
                                  ? "#2E2E46"
                                  : "#FFFFFF",
                                border: "0.5px solid #B9B9B9",
                                boxSizing: "border-box",

                                borderRadius: "4px",
                              }}
                            />
                            <ErrorMessage
                              name="VehicleOwner"
                              component="span"
                              className="error"
                            />
                          </FormGroup>

                          <FormGroup className="select2-container">
                            <Label
                              className={`required-field  mt-2 ${
                                this.props.mode ? "light__span" : ""
                              }`}
                              style={{
                                color: "#05284E",
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: "bold",
                                fontSize: "15px",
                                lineHeight: "18px",
                              }}
                            >
                              {" "}
                              {userLang?.tracking?.VEHCILE_REG_NO}
                            </Label>
                            <Input
                              type="text"
                              value={form.VehicleRegistrationNumber}
                              maxLength="11"
                              onChange={(e) => {
                                this.handleSubmitChange({
                                  target: {
                                    name: "VehicleRegistrationNumber",
                                    value: e.target.value,
                                  },
                                });
                              }}
                              name="VehicleRegistrationNumber"
                              id="VehicleRegistrationNumber"
                              className={
                                errors.VehicleRegistrationNumber &&
                                touched.VehicleRegistrationNumber
                                  ? "input-error"
                                  : null
                              }
                              placeholder="MH01AV4346,MH014346"
                              style={{
                                height: "50px",
                                width: " 400px",
                                backgroundColor: this.props.mode
                                  ? "#2E2E46"
                                  : "#FFFFFF",
                                border: "0.5px solid #B9B9B9",
                                boxSizing: "border-box",

                                borderRadius: "4px",
                              }}
                            />

                            {!this.state.isVehicleNoValid && (
                              <span
                                style={{
                                  fontStyle: "12px",
                                  color: "red",
                                }}
                              >
                                Please enter in a given format: MH01AV4346,
                                MH01446
                              </span>
                            )}
                            <ErrorMessage
                              name="VehicleRegistrationNumber"
                              component="span"
                              className="error"
                            />
                          </FormGroup>

                          <FormGroup className="select2-container required">
                            <Label
                              className={`col-form-label required-field  mt-1 ${
                                this.props.mode ? "light__span" : ""
                              }`}
                              style={{
                                color: "#05284E",
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: "bold",
                                fontSize: "15px",
                                lineHeight: "18px",
                              }}
                            >
                              {userLang?.common?.VEHICLE_TYPE}{" "}
                            </Label>
                            <Select
                              style={{
                                height: "40px",
                                width: " 404px",
                                backgroundColor: this.props.mode
                                  ? "#2E2E46"
                                  : "#FFFFFF",
                                border: "0.5px solid #B9B9B9",
                                boxSizing: "border-box",

                                borderRadius: "4px",
                              }}
                              styles={customStyles1}
                              // value={this.state.source.find(
                              //   (x) => x.value === values.source
                              // )}
                              classNamePrefix="select2-selection"
                              name="type"
                              value={form?.type || null}
                              onChange={(e) => {
                                this.handleSubmitChange({
                                  target: {
                                    name: "type",
                                    value: e,
                                  },
                                });
                              }}
                              options={type}
                              id="typeVehicle"
                              className={
                                errors.type && touched.type
                                  ? "input-error"
                                  : null
                              }
                            />

                            <ErrorMessage
                              name="type"
                              component="span"
                              className="error"
                            />
                          </FormGroup>

                          {role !== "transporter" ? (
                            <FormGroup className="select2-container">
                              <Label
                                className={`control-label mt-2 ${
                                  this.props.mode ? "light__span" : ""
                                }`}
                                style={{
                                  color: "#05284E",
                                  fontFamily: "Roboto",
                                  fontStyle: "normal",
                                  fontWeight: "bold",
                                  fontSize: "15px",
                                  lineHeight: "18px",
                                }}
                              >
                                {userLang?.common?.TRANSPORTER}
                              </Label>
                              <Select
                                value={form.transporter}
                                onInputChange={this.filterTransporterList}
                                id="typeVehicle"
                                isMulti={false}
                                name="transporter"
                                onChange={(e) => {
                                  this.handleSubmitChange({
                                    target: {
                                      name: "transporter",
                                      value: e,
                                    },
                                  });
                                }}
                                options={this.state.transpoter}
                                classNamePrefix="select2-selection"
                                style={{
                                  height: "40px",
                                  width: " 400px",
                                  backgroundColor: this.props.mode
                                    ? "#2E2E46"
                                    : "#FFFFFF",
                                  border: "0.5px solid #B9B9B9",
                                  boxSizing: "border-box",

                                  borderRadius: "4px",
                                }}
                                styles={customStyles1}
                              />
                            </FormGroup>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>

                      <Row
                        className="mt-2"
                        style={{ marginRight: "128px", float: "right" }}
                      >
                        <Col className="text-center mt-4">
                          <Button
                            type="submit"
                            color="primary"
                            className=" mr-2 btn-md button-wide"
                            style={{
                              height: "40px",
                              width: "90px",
                              // left: "518px",
                              top: " 549px",
                              borderRadius: " 0px",
                              backgroundColor: this.props.mode
                                ? "#5E40FF"
                                : "#05284E",
                            }}
                          >
                            {userLang?.common?.SUBMIT}
                          </Button>

                          <Button
                            type="button"
                            color="secondary"
                            className=" btn-md button-wide"
                            onClick={() => {
                              this.reset(resetForm);
                            }}
                            style={{
                              height: "40px",
                              width: "90px",
                              // left: "518px",
                              top: " 549px",
                              borderRadius: " 0px",
                              backgroundColor: "#0AB38C",
                            }}
                          >
                            {userLang?.common?.RESET}
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  );
                }}
              </Formik>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStatetoProps = (state) => {
  const user = state.Login.user;
  const vehicleType = state.VehicleType.vehicleType;
  return { user, vehicleType };
};

export default connect(mapStatetoProps, {
  createVehicle,
  getTransporters,
  fetchVehicleTypesList,
  fetchAllTransporterSearch,
})(VehicleForm);
