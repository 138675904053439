import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
//medthods
import { getApi, postApi } from "../../helpers/auth_helper";

// workers
function* workdispatchLeadTimeReport({ filters, searchFilter, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  // console.log(searchFilter, "seacrchfilter");
  let filterString = "";
  const keyMap = {
    "Warehouse Number": "warehouseNumber",
    "Order Number": "orderNumber",
    "Invoice Number": "invoiceNumber",
    "Invoice Generated Time": "invoiceGeneratedTime",
    "Invoice Dispatched Time": "invoiceDispatchedTime",
    // Add more key-value pairs as needed
  };

  if (searchFilter !== null && searchFilter !== undefined) {
    for (const [key, value] of Object.entries(searchFilter)) {
      let filterKey = keyMap[key] || key;
      let filterValue = value;
      filterString = filterString + "&" + filterKey + "=" + filterValue;
    }
  }

  try {
    const url =
      process.env.REACT_APP_SERVERURL +
      "/report/fetch/dispatchLeadTime?offset=" +
      offset +
      "&limit=" +
      limit +
      filterString;

    const response = yield call(getApi, url);
    callback(response);
    // localStorage.setItem(
    //   "LeadTimeReport",
    //   response ? JSON.stringify(response) : null
    // );
    yield put({
      type: types.DIAPATCH_LEAD_TIME_REPORT_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

//Dispatch lead time excel
function* workdispatchLeadTimeReportExcel({ filters, searchFilter, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  console.log("IN WORKER FUNCTION")
  console.log(offset)
  console.log(searchFilter, "seacrchfilter");
  let filterString = "";
  const keyMap = {
    "Warehouse Number": "warehouseNumber",
    "Order Number": "orderNumber",
    "Invoice Number": "invoiceNumber",
    "Invoice Generated Time": "invoiceGeneratedTime",
    "Invoice Dispatched Time": "invoiceDispatchedTime",
    // Add more key-value pairs as needed
  };

  if (searchFilter !== null && searchFilter !== undefined) {
    for (const [key, value] of Object.entries(searchFilter)) {
      let filterKey = keyMap[key] || key;
      let filterValue = value;
      filterString = filterString + "&" + filterKey + "=" + filterValue;
    }
  }

  try {
    const url =
      process.env.REACT_APP_SERVERURL +
      "/report/fetch/dispatchLeadTime?offset=" +
      offset +
      "&limit=" +
      limit +
      filterString;

    const response = yield call(getApi, url);
    callback(response);
    // localStorage.setItem(
    //   "LeadTimeReport",
    //   response ? JSON.stringify(response) : null
    // );
    yield put({
      type: types.DIAPATCH_LEAD_TIME_REPORT_EXCEL,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}


function* workBifurcationRevenueReport({ filters, searchFilter, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let filterString = "";
  const keyMap = {};
  if (searchFilter !== null && searchFilter !== undefined) {
    for (const [key, value] of Object.entries(searchFilter)) {
      let filterKey = keyMap[key] || key;
      let filterValue = value;
      filterString = filterString + "&" + filterKey + "=" + filterValue;
    }
  }

  try {
    const url =
      process.env.REACT_APP_SERVERURL +
      "/report/fetch/orderBifurcationReport?offset=" +
      offset +
      "&limit=" +
      limit +
      filterString;

    const response = yield call(getApi, url);
    callback(response);
    // localStorage.setItem(
    //   "LeadTimeReport",
    //   response ? JSON.stringify(response) : null
    // );
    yield put({
      type: types.BIFURCATION_REPORT_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workBifurcationRevenueReportExcel({ filters, searchFilter, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let filterString = "";
  const keyMap = {};
  if (searchFilter !== null && searchFilter !== undefined) {
    for (const [key, value] of Object.entries(searchFilter)) {
      let filterKey = keyMap[key] || key;
      let filterValue = value;
      filterString = filterString + "&" + filterKey + "=" + filterValue;
    }
  }

  try {
    const url =
      process.env.REACT_APP_SERVERURL +
      "/report/fetch/orderBifurcationReport?offset=" +
      offset +
      "&limit=" +
      limit +
      filterString;

    const response = yield call(getApi, url);
    callback(response);
    // localStorage.setItem(
    //   "LeadTimeReport",
    //   response ? JSON.stringify(response) : null
    // );
    yield put({
      type: types.BIFURCATION_REPORT_EXCEL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workTruckUtilizationReport({ filters, searchFilter, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let filterString = "";
  const keyMap = {};
  if (searchFilter !== null && searchFilter !== undefined) {
    for (const [key, value] of Object.entries(searchFilter)) {
      let filterKey = keyMap[key] || key;
      let filterValue = value;
      filterString = filterString + "&" + filterKey + "=" + filterValue;
    }
  }

  try {
    const url =
      process.env.REACT_APP_SERVERURL +
      "/report/fetch/truckUtilizationReport?offset=" +
      offset +
      "&limit=" +
      limit +
      filterString;

    const response = yield call(getApi, url);
    callback(response);
    // localStorage.setItem(
    //   "LeadTimeReport",
    //   response ? JSON.stringify(response) : null
    // );
    yield put({
      type: types.TRUCKUTILIZATION_REPORT_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workTruckUtilizationReportExcel({ filters, searchFilter, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let filterString = "";
  const keyMap = {};
  if (searchFilter !== null && searchFilter !== undefined) {
    for (const [key, value] of Object.entries(searchFilter)) {
      let filterKey = keyMap[key] || key;
      let filterValue = value;
      filterString = filterString + "&" + filterKey + "=" + filterValue;
    }
  }

  try {
    const url =
      process.env.REACT_APP_SERVERURL +
      "/report/fetch/truckUtilizationReport?offset=" +
      offset +
      "&limit=" +
      limit +
      filterString;

    const response = yield call(getApi, url);
    callback(response);
    // localStorage.setItem(
    //   "LeadTimeReport",
    //   response ? JSON.stringify(response) : null
    // );
    yield put({
      type: types.TRUCKUTILIZATION_REPORT_EXCEL,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

// watchers
export function* watchdispatchLeadTimeReport() {
  yield takeEvery(types.DIAPATCH_LEAD_TIME_REPORT, workdispatchLeadTimeReport);
}

export function* watchdispatchLeadTimeReportExcel() {
  yield takeEvery(types.DIAPATCH_LEAD_TIME_REPORT_EXCEL, workdispatchLeadTimeReportExcel);
}

export function* watchBifurcationRevenueReport() {
  yield takeEvery(types.BIFURCATION_REPORT, workBifurcationRevenueReport);
}

export function* watchBifurcationRevenueReportExcel() {
  yield takeEvery(types.BIFURCATION_REPORT_EXCEL, workBifurcationRevenueReportExcel);
}

export function* watchTruckUtilizationReport() {
  yield takeEvery(types.TRUCKUTILIZATION_REPORT, workTruckUtilizationReport);
}

export function* watchTruckUtilizationReportExcel() {
  yield takeEvery(types.TRUCKUTILIZATION_REPORT_EXCEL, workTruckUtilizationReportExcel);
}

function* dispatchLeadTimeReportSaga() {
  yield all([fork(watchdispatchLeadTimeReport)]);
  yield all([fork(watchBifurcationRevenueReport)]);
  yield all([fork(watchTruckUtilizationReport)]);
  yield all([fork(watchdispatchLeadTimeReportExcel)]);
  yield all([fork(watchBifurcationRevenueReportExcel)]);
  yield all([fork(watchTruckUtilizationReportExcel)]);
}
export default dispatchLeadTimeReportSaga;
