// import { mapKeys } from "lodash";
import { types } from "../actionTypes";
const initialState = {
  routeData: [],
  fetchId: "",
};
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ROUTE_SUCCESS:
      //   // Create a new state object that uses an AJAX request response and grabs the 'id' property from each object in the response to use as its key
      return action.payload;
    case types.FETCH_ROUTES_FOR_MASTER_SUCCESS:
      //   // Create a new state object that uses an AJAX request response and grabs the 'id' property from each object in the response to use as its key
      return {
        ...state,
        routeData: action.payload,
        fetchId: "id" + new Date().getTime(),
      };
    default:
      state = { ...state };
      break;
  }

  return state;
};
