import {types} from "./actionTypes";
export const fetchEmployees = () => ({ type: types.FETCH_EMPLOYEES });

export const fetchAvailEmployees = () => ({ type: types.FETCH_AVAIL_EMPLOYEES });
// export const fetchPost = id => ({ type: types.FETCH_POST, id });
export const createEmployee = (values, callback) => ({
  type: types.CREATE_EMPLOYEE,
  values,
  callback
});
export const deleteEmployee = (id, callback) => ({
  type: types.DELETE_EMPLOYEE,
  id,
  callback
});