import { types } from "./actionTypes";

// creating action
export const getOrders = (
  offset,
  limit,
  customerCode,
  cfaCodes,
  topFilters,
  filters,
  callback
) => {
  return {
    type: types.GET_ORDERS,
    offset,
    limit,
    customerCode,
    cfaCodes,
    topFilters,
    filters,
    callback,
  };
};
export const getOrdersForExcel = (
  offset,
  limit,
  customerCode,
  cfaCodes,
  topFilters,
  filters,
  callback
) => {
  return {
    type: types.GET_ORDERS_FOR_EXCEL,
    offset,
    limit,
    customerCode,
    cfaCodes,
    topFilters,
    filters,
    callback,
  };
};

export const getOrderProduct = (offset, limit, searchFilter, callback) => {
  return {
    type: types.GET_ORDERPRODUCT,
    offset,
    limit,
    searchFilter,
    callback,
  };
};

export const viewOrderById = (filters, Id, callback) => {
  return {
    type: types.VIEW_ORDERSBY_ID,
    filters,
    Id,
    callback,
  };
};

export const viewProductByOrderId = (Id, callback) => {
  return {
    type: types.VIEW_PRODUCTBY_ORDERID,
    Id,
    callback,
  };
};

export const viewInvoiceByOrderId = (Id, callback) => {
  return {
    type: types.VIEW_INVOICEBY_ORDERID,
    Id,
    callback,
  };
};

export const viewOrderLifeCycle = (Id, paginationStatus, callback) => {
  return {
    type: types.VIEW_ORDER_LIFE,
    Id,
    paginationStatus,
    callback,
  };
};

export const trackOrders = (Id, callback) => {
  return {
    type: types.TRACK_ORDER,
    Id,
    callback,
  };
};
export const reSyncOrder = (Id, callback) => {
  return {
    type: types.RESYNC_ORDER,
    Id,
    callback,
  };
};

export const closeOrderTrip = (Value, callback) => {
  return {
    type: types.CLOSE_TRIP,
    Value,
    callback,
  };
};

export const orderTrackingByEwayBill = (Value, callback) => {
  return {
    type: types.ORDER_TRACKING_BY_EWAYBILL,
    Value,
    callback,
  };
};
