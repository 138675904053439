/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH";
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME";
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE";

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME";

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR";
export const HIDE_RIGHT_SIDEBAR = "HIDE_RIGHT_SIDEBAR";

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER";
export const types = {
  FETCH_HOPS: "FETCH_HOPS",
  FETCH_HOPS_SUCCESS: "FETCH_HOPS_SUCCESS",
  FETCH_FILTER_HOPS: "FETCH_FILTER_HOPS",
  FETCH_FILTER_HOPS_SUCCESS: "FETCH_FILTER_HOPS_SUCCESS",

  FETCH_AVAIL_HOPS: "FETCH_AVAIL_HOPS",
  FETCH_AVAIL_HOPS_SUCCESS: "FETCH_AVAIL_HOPS_SUCCESS",
  CREATE_HOP: "CREATE_HOP",
  CREATE_HOP_SUCCESS: "CREATE_HOP_SUCCESS",
  FETCH_HOP_BYID: "FETCH_HOP_BYID",
  FETCH_HOP_BYID_SUCCESS: "FETCH_HOP_BYID_SUCCESS",
  EDIT_HOP: "EDIT_HOP",
  EDIT_HOP_SUCCESS: "EDIT_HOP_SUCCESS",
  // FETCH_POSTS: 'FETCH_POSTS',
  // FETCH_POSTS_SUCCESS: 'FETCH_POSTS_SUCCESS',
  // CREATE_POST: 'CREATE_POST',
  // CREATE_POST_SUCCESS: 'CREATE_POST_SUCCESS',
  DELETE_HOP: "DELETE_HOPS",
  DELETE_HOP_SUCCESS: "DELETE_HOPS_SUCCESS",
  FETCH_SOURCE_DESTINATION: "FETCH_SOURCE_DESTINATION",
  FETCH_SOURCE_DESTINATION_SUCCESS: "FETCH_SOURCE_DESTINATION_SUCCESS",
  FETCH_ALL_SOURCE_DESTINATION: "FETCH_ALL_SOURCE_DESTINATION",
  FETCH_ALL_SOURCE_DESTINATION_SUCCESS: "FETCH_ALL_SOURCE_DESTINATION_SUCCESS",
};
//   export
// export const FETCH_HOPS = "FETCH_HOPS";
