import React, { Component } from "react";
import {
  Card,
  CardHeader,
  Container,
  CardBody,
  Row,
  Col,
  Button,
} from "reactstrap";
import {
  updateBill,
  fetchBills,
  acceptInvoice,
  fetchInvoiceById,
  rejectInvoice,
  invoiceBillAddOrDelete,
  submitToRaisedInvoice,
} from "../../../store/billing/actions";
import { connect } from "react-redux";
import { PERMS } from "../../../enum/perms.enum";
import { restrict } from "../../restrict";
import BillItems from "./BillItems";
import { TextField } from "@material-ui/core";
import {
  MDBBtn,
  MDBCollapse,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import RaiseInvoceModal from "./RaiseInvoiceModal";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class InvoiceEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      bills: [],
      viewInvoiceDetail: "",
      comment: "",
      //toggles
      revisedFreightCostToggle: false,
      //expand-Collapse
      expandIndex: "",
      userLangs: undefined,
      damageComments: [],
      shortageComments: [],
      epodComment: "",
      invoiceDeleteModal: false,
      deleteBill: "",

      //invoice modal
      raiseinvoiceModal: false,
      raiseInvoice: {
        gstNo: "",
        FromDate: "",
      },
      resetDate: true,
      errorMessage: "",
      documentArray: [],
    };
    (async () => {
      let redirect = await restrict(PERMS.TRIP_READ);
      // console.log(redirect, "redirect");

      if (redirect === false) {
        this.props.history.push("/operationsDashboard");
      }
    })();
  }

  componentDidMount = () => {
    // console.log("mounted invoice editor");
    this.setState({ bills: this.props.location?.state?.bills }, () => {
      // console.log("BILLARRAY=>", this.state.bills);
    });
    // this.props.fetchInvoiceById(this.props.location.state.id, (res) => {
    //   this.setState({ viewInvoiceDetail: res }, () => {
    //     // console.log("BILLARRAY=>", this.state.bills);
    //   });
    // });
    this.getInvoiceData();
  };
  getInvoiceData = () => {
    this.props.fetchInvoiceById(this.props.location.state.id, (res) => {
      this.setState({ viewInvoiceDetail: res }, () => {
        // console.log("BILLARRAY=>", this.state.bills);
      });
    });
  };

  commentAssigner = (e) => {
    this.setState({ comment: e.target.value });
  };
  submitForApprovalForSubmit = () => {
    let values = {
      invoiceId: this.props.location.state.id,
      status: "submitted",
      comment: this.state.comment,
    };
    this.props.acceptInvoice(values, (res) => {
      if (res) {
        NotificationManager.success(res["message"], "Success");
        this.getInvoiceData();
      }
    });

    this.props.history.push("/Billing/InvoiceList");
  };
  submitForApproval = () => {
    let values = {
      invoiceId: this.props.location.state.id,
      status: "approval_pending",
      comment: this.state.comment,
    };
    this.props.acceptInvoice(values, (res) => {
      if (res) {
        NotificationManager.success(res["message"], "Success");
        this.getInvoiceData();
      }
    });

    this.props.history.push("/Billing/InvoiceList");
  };

  toggle = (index, item) => {
    this.setState(
      (prevState) => ({
        expandIndex: prevState.expandIndex !== index ? index : "",
      }),
      () => {
        // console.log("item", item, this.props.viewInvoice,item?.indentId);
        const commentDetails = this.props.viewInvoice;

        // console.log("commentDetails", commentDetails);

        let shortageComment = [];
        let damagedComment = [];
        if (this.props.viewInvoice !== undefined) {
          if (Array.isArray(commentDetails.bills)) {
            commentDetails.bills.map((ele) => {
              if (ele?.indentId == item?.indentId) {
                this.setState(
                  {
                    epodComment: ele?.trip?.qualityCheck?.comment,
                  },
                  () => {
                    // console.log("epod comment",this.state.epodComment)
                  }
                );
                if (Array.isArray(ele.epods)) {
                  ele.epods.map((eleEpod) => {
                    if (Array.isArray(eleEpod.shipments)) {
                      eleEpod.shipments.map((eleShip) => {
                        if (Array.isArray(eleShip.items)) {
                          eleShip.items.map((eleItems) => {
                            // shortageComment = [];
                            // damagedComment = [];
                            // if (item.indentId === ele?.indentId) {
                            if (eleItems?.epod?.shortageComment) {
                              shortageComment.push({
                                id: eleItems?.itemId,
                                shortageComment:
                                  eleItems?.epod?.shortageComment,
                              });
                            }
                            if (eleItems?.epod?.damageComment) {
                              damagedComment.push({
                                id: eleItems?.itemId,
                                damageComment: eleItems?.epod?.damageComment,
                              });
                            }
                            // }
                          });
                        }
                      });
                    }
                  });
                }
              }
            });
            // console.log(
            //   "commentDetails",
            //   commentDetails?.bills,
            //   shortageComment,
            //   damagedComment
            // );
            this.setState(
              {
                damageComments: damagedComment,
                shortageComments: shortageComment,
              },
              () => {
                // console.log("damagedComment",this.state.damageComments,this.state.shortageComments)
              }
            );
          }
        }
      }
    );
  };
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (userLang !== this.state.userLangs || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  handleDeleteBill = (billId) => {
    this.props.invoiceBillAddOrDelete(
      {
        invoiceId: this.state.viewInvoiceDetail?.result?._id,
        billId: [billId],
        addOrDeleteBill: 0,
      },
      (res) => {
        if (res) {
          NotificationManager.success(res["message"], "Success");
          // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>EEYDGWYDGWYGDYWSGDUWS", this.state.viewInvoiceDetail)
          this.handleCloseDeleteBill();
          this.getInvoiceData();
          if (this.state.viewInvoiceDetail?.result?.bills.length === 1) {
            // Last card deleted, go back to the previous page
            // console.log(">>>>>>>>>>>>>Zero items remaining")
            this.props.history.goBack();
          }
          // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>After fetching invoices", this.state.viewInvoiceDetail)
        }
      }
    );
  };
  handleOpenDeleteBill = () => {
    this.setState({
      invoiceDeleteModal: true,
    });
  };
  handleCloseDeleteBill = () => {
    this.setState({
      invoiceDeleteModal: false,
    });
  };
  // invoice modal
  validateVal = (values) => {
    const errors = {};
    if (!values.gstNo) {
      errors.gstNo = "Gst No is required!";
    }
    if (!values.fromDate) {
      errors.fromDate = "Date is required!";
    }
    if (values.documents.length === 0) {
      errors.documents = "File is required!";
    }
    return errors;
  };
  imageReset = () => {
    this.setState({ documentArray: [] });
  };
  openRaiseInvoiceModal = () => {
    this.setState(
      { raiseinvoiceModal: !this.state.raiseinvoiceModal },
      () => {}
    );
  };
  reset = () => {
    this.setState(
      {
        raiseinvoiceModal: false,
        raiseInvoice: {
          gstNo: "",
          FromDate: "",
        },
        resetDate: true,
        errorMessage: "",
        documentArray: [],
      },
      () => {}
    );
  };
  invoiceSubmit = async (value) => {
    this.setState({ documentArray: value }, () => {});

    let values = {
      invoiceId: this.state.viewInvoiceDetail?.result?._id,
      gstNo: this.state.raiseInvoice?.gstNo,
      fromDate: this.state.raiseInvoice?.FromDate,
      documents: this.state.documentArray,
    };
    this.setState({ errorMessage: this.validateVal(values) });
    if (Object.keys(this.state.errorMessage).length === 0) {
      this.setState({ dataLoading: true });
      this.props.submitToRaisedInvoice(values, (res) => {
        if (
          res["result"] &&
          res["message"] === "Invoice submitted successfully"
        ) {
          NotificationManager.success(res["message"], "Success");
          this.setState({ dataLoading: false, defaultCheckedBills: [] }, () => {
            this.reset();
          });
          this.setState({
            raiseinvoiceModal: false,
          });
          this.props.history.push({
            pathname: "/Billing/InvoiceList",
            state: { invoiceId: res["result"]._id },
          });
        } else {
          NotificationManager.error(res["message"], "Error");
          this.setState({ dataLoading: false });
          this.props.history.push("/InvoiceEdit");
        }
      });
    }
  };
  documentAssigner = (value) => {
    this.setState({ documentArray: value }, () => {});
  };

  raiseChange = (e) => {
    let { name, value } = e.target;
    let raise = this.state.raiseInvoice;
    raise[name] = value;
    this.setState({ raiseInvoice: raise });
  };
  handleResetInvoiceInput = () => {
    this.setState(
      {
        resetDate: false,
        documentArray: [],
        errorMessage: {},
        raiseInvoice: {
          gstNo: "",
          FromDate: "",
        },
      },
      () => {
        this.setState({
          resetDate: true,
        });
      }
    );
  };
  rejectInvoice = () => {
    let data = {
      invoiceId: this.props.location.state.id,
      status: "sent_for_revision",
      comment: this.state.comment,
    };
    this.setState({ dataLoading: true });

    this.props.rejectInvoice(data, (res) => {
      if (res) {
        NotificationManager.success(res["message"], "Success");
        this.props.history.push("/Billing/InvoiceList");
        this.getInvoiceData();
      }
    });
  };
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkMode = mode === "dark__mode" ? true : false;
    let bills = this.props.location?.state?.bills;
    let viewInvoiceData = this.state.viewInvoiceDetail?.result;
    let authUserData = JSON.parse(window.localStorage.getItem("authUser"));

    return (
      <React.Fragment>
        <RaiseInvoceModal
          isOpen={this.state.raiseinvoiceModal}
          toggle={this.openRaiseInvoiceModal}
          submit={this.invoiceSubmit}
          onChange={this.raiseChange}
          FromDate={this.state.raiseInvoice.FromDate}
          gstNo={this.state.raiseInvoice.gstNo}
          documentAssigner={this.documentAssigner}
          mode={darkMode}
          handleResetInvoiceInput={this.handleResetInvoiceInput}
          resetDate={this.state.resetDate}
          errorMessage={this.state.errorMessage}
          documentArray={this.state.documentArray}
          imageReset={this.imageReset}
        />
        <MDBContainer className="invoice WaringModal">
          <MDBModal
            modalStyle={"warning"}
            isOpen={this.state.invoiceDeleteModal}
            toggle={() => {
              this.handleOpenDeleteBill();
            }}
            centered
            size="sm"
            className={` ${this.props.mode ? "invoiceDarkMode" : ""}`}
          >
            <MDBModalHeader>
              <h2 className="dark__span">Warning</h2>
            </MDBModalHeader>
            <MDBModalBody>
              <h5 className="dark__span">
                <span>
                  <b>Trip ID :</b>{" "}
                  {this.state.deleteBill?.trip?.tripId
                    ? this.state.deleteBill?.trip?.tripId
                    : ""}
                </span>
              </h5>
              <h5 className="dark__span">
                <span>
                  <b>Indent ID :</b>{" "}
                  {this.state.deleteBill?.indentId
                    ? this.state.deleteBill?.indentId
                    : ""}
                </span>
              </h5>
              <h5 className="dark__span">
                Do you want to remove this item from an invoice?
              </h5>
              {viewInvoiceData?.bills?.length <= 1 ? (
                <h5 className="dark__span">
                  <span>
                    <b>Note:</b>
                  </span>{" "}
                  <span
                    style={{
                      color: "#13549b",
                      fontWeight: 700,
                    }}
                  >
                    Upon removal of this item, the invoice would be in a
                    cancelled state.
                  </span>
                </h5>
              ) : (
                ""
              )}
              <Button
                className="mt-4 dark__span buttons2"
                onClick={() =>
                  this.handleDeleteBill(this.state.deleteBill?._id)
                }
              >
                Ok
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    invoiceDeleteModal: false,
                  });
                }}
                className="ml-2 mt-4 buttons1"
              >
                Cancel
              </Button>
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>
        {/* <div className="page-content"> */}
        <Container
          className="invoiceEditParent"
          fluid
          style={{
            backgroundColor: darkMode ? "#1B1C31" : "white",
            border: darkMode ? "2px solid #616161" : "none",
          }}
        >
          <Row>
            <Col xs={9} className="d-flex flex-row bd-highlight mb-2 mt-2">
              <Button
                style={{
                  width: "60px",
                  height: "36px",
                  backgroundColor: "#3e3e3e",
                }}
                color="secondary"
                size="sm"
                onClick={() => this.props.history.goBack()}
              >
                <i className="fas fa-arrow-circle-left fa-2x"></i>
              </Button>
            </Col>
          </Row>
          <Card className="darkBackgroundList Card1">
            <div className="d-flex Card1">
              <div md={2} className="float-left" style={{ width: "50%" }}>
                <h5
                  className="mt-1 light__span"
                  style={{
                    fontWeight: "700",
                    lineHeight: "24px",
                    size: "16px",
                    color: "#252B3B",
                  }}
                >
                  {this.state.userLangs?.invoice?.EDIT_INVOICE}{" "}
                </h5>
              </div>
              <div className="float-right mt-1" style={{ width: "50%" }}>
                <span className="light__span">
                  {this.state.userLangs?.common?.TRANSPORTER}:{" "}
                  <span className="font-weight-bold dark__span">
                    {this.props.location?.state?.transporter?.name}
                  </span>
                </span>
              </div>
            </div>

            <div>
              {viewInvoiceData?.bills.map((item, index) => {
                return (
                  <>
                    {/* {(item.status === "cancelled") ? (
                      <>
                        {history.goBack()} 
                      </>
                    ) : ( */}
                    <Card key={index}>
                      <CardHeader className="darkMultipleCharges Card1">
                        <div className="d-flex justify-content-between  flex-wrap align-items-start">
                          <div className="ml-3">
                            <span className="font-weight-bold">
                              {this.state.userLangs?.planning?.INDENT_ID}:{" "}
                              {item.indentId}
                            </span>
                          </div>

                          <div className="ml-3 ">
                            <span className="font-weight-bold">
                              {this.state.userLangs?.trip?.TRIP_ID}:{" "}
                              {item.trip?.tripId ? item.trip?.tripId : "N/A"}
                            </span>
                          </div>
                          <div className="ml-3  align-items-start">
                            <span className="font-weight-bold">
                              {this.state.userLangs?.invoice?.LR_NO ?? "Lr No"}:{" "}
                              {item?.shipments
                                ? item?.shipments?.map((ele) => {
                                    return ele?.lrNo && <div>{ele.lrNo}</div>;
                                  })
                                : "N/A"}
                            </span>
                          </div>

                          <div
                            className="ml-3  align-items-start"
                            style={{ width: "15%" }}
                          >
                            <span className="font-weight-bold">
                              {this.state.userLangs?.trip?.TRIP_DATE}:{" "}
                              {item.trip?.startTime
                                ? moment(item.trip?.startTime).format(
                                    "DD/MM/YYYY"
                                  )
                                : "N/A"}
                            </span>
                          </div>

                          <div style={{ width: "20%", float: "left" }}>
                            <span className="font-weight-bold text-capitalize">
                              {this.state.userLangs?.invoice?.BILL_TYPE}:{" "}
                              {item.type.replace(/_/g, " ")}
                            </span>
                          </div>
                          <div style={{ width: "20%", float: "left" }}>
                            <span className="font-weight-bold text-capitalize">
                              {this.state.userLangs?.invoice?.TOTAL_COST}:{" "}
                              {item.calculations.netPayble}
                            </span>
                          </div>
                          {authUserData?.data?.role?.label == "transporter" &&
                            viewInvoiceData?.status !== "sent_for_revision" && (
                              <div>
                                <div className="tooltipIcon ">
                                  <MDBBtn
                                    onClick={() => {
                                      this.setState(
                                        {
                                          invoiceDeleteModal: true,
                                        },
                                        () => {
                                          // this.handleDeleteBill(item?._id);
                                          this.setState({
                                            deleteBill: item,
                                          });
                                        }
                                      );
                                    }}
                                  >
                                    <i
                                      onClick={() => {
                                        this.setState(
                                          {
                                            invoiceDeleteModal: true,
                                          },
                                          () => {
                                            // this.handleDeleteBill(item?._id);
                                            this.setState(
                                              {
                                                deleteBill: item,
                                              },
                                              () => {
                                                console.log(
                                                  "bill",
                                                  this.state.deleteBill
                                                );
                                              }
                                            );
                                          }
                                        );
                                      }}
                                      className="fas fa-trash"
                                      style={{ color: "red" }}
                                    ></i>
                                  </MDBBtn>
                                  <span
                                    className="tooltipIcontext"
                                    style={{ width: "115px", left: "-8px" }}
                                  >
                                    Romove this bill from draft
                                  </span>
                                </div>
                              </div>
                            )}

                          <div
                            onClick={() => {
                              this.toggle(index, item);
                            }}
                          >
                            <MDBBtn>
                              <i className="fas fa-chevron-down"></i>
                            </MDBBtn>
                          </div>
                        </div>
                      </CardHeader>
                      <MDBCollapse
                        id={index}
                        isOpen={this.state.expandIndex === index}
                      >
                        <CardBody className="darkInvoiceEdit Card1">
                          <BillItems
                            invoiceId={this.props.location?.state?.id}
                            index={index}
                            item={item}
                            mode={darkMode}
                            damagedComments={this.state.damageComments}
                            shortageComments={this.state.shortageComments}
                            epodComment={this.state.epodComment}
                            getInvoiceData={this.getInvoiceData}
                            status={viewInvoiceData?.status}
                          />
                        </CardBody>
                      </MDBCollapse>
                    </Card>
                    {/* )} */}
                  </>
                );
              })}
            </div>
          </Card>
          <Card>
            <div className="darkBackgroundList ">
              {authUserData?.data?.role?.label !== "transporter" && (
                <div>
                  <TextField
                    className="mb-1 darkCommentText"
                    style={{ width: "100%" }}
                    id="outlined-basic"
                    label={this.state.userLangs?.planning?.COMMENTS}
                    variant="outlined"
                    onChange={this.commentAssigner}
                    multiline
                    required
                  />
                </div>
              )}

              <div className="text-center mb-2">
                {authUserData?.data?.role?.label === "billing_team_member" && (
                  <MDBBtn
                    disabled={this.state.comment?.length > 0 ? false : true}
                    className="rejectBtn"
                    onClick={this.rejectInvoice}
                  >
                    Reject
                  </MDBBtn>
                )}

                <MDBBtn
                  style={{ width: "35%" }}
                  color="primary"
                  onClick={() => {
                    if (authUserData?.data?.role?.label === "transporter") {
                      // this.submitForApprovalForSubmit();
                      this.openRaiseInvoiceModal();
                    } else {
                      this.submitForApproval();
                    }
                  }}
                  disabled={
                    authUserData?.data?.role?.label !== "transporter"
                      ? !this.state.comment
                      : false
                  }
                >
                  {authUserData?.data?.role?.label == "transporter"
                    ? this.state.userLangs?.invoice?.SUBMIT_TO_RAISE_INVOICE
                      ? this.state.userLangs?.invoice?.SUBMIT_TO_RAISE_INVOICE
                      : "Submit to raise invoice"
                    : this.state.userLangs?.invoice?.SUBMIT_APPROVAL
                    ? this.state.userLangs?.invoice?.SUBMIT_APPROVAL
                    : "Submit Approval"}
                </MDBBtn>
              </div>
            </div>
          </Card>
        </Container>
        {/* </div> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log("state", state);
  return {
    viewInvoice: state?.Billing?.billing?.invoice,
  };
};

export default connect(mapStateToProps, {
  updateBill,
  fetchBills,
  acceptInvoice,
  fetchInvoiceById,
  invoiceBillAddOrDelete,
  submitToRaisedInvoice,
  rejectInvoice,
})(InvoiceEdit);
