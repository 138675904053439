// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

import { getApi } from "../../helpers/auth_helper";
// import { getDataGroupBy } from "rsuite/esm/utils";

function* workfetchVehiclePlacementDetailReport({ values, callback }) {
  // console.log("values",values)
  let offset = values?.offset ? values?.offset : 0;
  let limit = values?.limit ? values?.limit : process.env.REACT_APP_PERPAGE;
  let transpoterId = values?.transpoterId ? values.transpoterId : "";

  let sourceHop = values?.sourceHop ? values?.sourceHop : "";
  let destinationHop = values?.destinationHop ? values?.destinationHop : "";

  let startDate = values?.startDate ? values?.startDate : "";
  let endDate = values?.endDate ? values?.endDate : "";

  let isExport = values?.isExport ? values?.isExport : false;

  try {
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/report/fetch/placementAnalysisReport?offset=` +
      offset +
      `&limit=` +
      Number(limit) +
      `&transporterId=` +
      transpoterId +
      `&sourceHop=` +
      sourceHop +
      `&destinationHop=` +
      destinationHop +
      `&startDate=` +
      startDate +
      `&endDate=` +
      endDate;
    const response = yield call(getApi, uri);
    // console.log("placementAnalysisReport",response);
    callback(response);
    yield put({
      type: types.VEHICLE_PLACEMENT_DETAIL_REPORT_SUCCESS,
      payload: response,
    });
    localStorage.setItem(
      "VehicleplacementDetailData",
      JSON.stringify(response)
    );
    // console.log("new_Api",JSON.stringify(response))
  } catch (error) {
    // Act on the error
    console.countlog("Request failed! Could not fetch posts.", error);
  }
}
function* workfetchVehiclePlacementDetailReportForExcel({ values, callback }) {
  // console.log("values",values)
  let offset = values?.offset ? values?.offset : 0;
  let limit = values?.limit ? values?.limit : process.env.REACT_APP_PERPAGE;
  let transpoterId = values?.transpoterId ? values.transpoterId : "";

  let sourceHop = values?.sourceHop ? values?.sourceHop : "";
  let destinationHop = values?.destinationHop ? values?.destinationHop : "";

  let startDate = values?.startDate ? values?.startDate : "";
  let endDate = values?.endDate ? values?.endDate : "";

  let isExport = values?.isExport ? values?.isExport : false;

  try {
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/report/fetch/placementAnalysisReport?offset=` +
      offset +
      `&limit=` +
      Number(limit) +
      `&transporterId=` +
      transpoterId +
      `&sourceHop=` +
      sourceHop +
      `&destinationHop=` +
      destinationHop +
      `&startDate=` +
      startDate +
      `&endDate=` +
      endDate +
      `&isExport=` +
      isExport;
    const response = yield call(getApi, uri);
    // console.log("placementAnalysisReport",response);
    callback(response);
    yield put({
      type: types.VEHICLE_PLACEMENT_DETAIL_REPORT_FOR_EXCEL_SUCCESS,
      payload: response,
    });
    localStorage.setItem(
      "VehicleplacementDetailData",
      JSON.stringify(response)
    );
    // console.log("new_Api",JSON.stringify(response))
  } catch (error) {
    // Act on the error
    console.countlog("Request failed! Could not fetch posts.", error);
  }
}

/**
 * Watchers
 */

export function* watchfetchVehiclePlacementDetailReport() {
  yield takeEvery(
    types.VEHICLE_PLACEMENT_DETAIL_REPORT,
    workfetchVehiclePlacementDetailReport
  );
}
export function* watchfetchVehiclePlacementDetailReportForExcel() {
  yield takeEvery(
    types.VEHICLE_PLACEMENT_DETAIL_REPORT_FOR_EXCEL,
    workfetchVehiclePlacementDetailReportForExcel
  );
}

function* vehiclePlacementDetailReportSaga() {
  yield all([fork(watchfetchVehiclePlacementDetailReport)]);
  yield all([fork(watchfetchVehiclePlacementDetailReportForExcel)]);
}

export default vehiclePlacementDetailReportSaga;
