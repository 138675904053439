import {types } from "./actionTypes";

// creating action
export const getLocations = (filters, callback) =>{
    return {
        type:types.GET_LOCATIONS,
        filters,
        callback
    }
}

export const getLocationsExcel = (filters, callback) =>{
    return {
        type:types.GET_LOCATIONS_EXCEL,
        filters,
        callback
    }
}

export const createLocation = (payload, callback) =>{
    return {
        type:types.CREATE_LOCATIONS,
        payload,
        callback
    }
}
export const createLocationViaExcel = (payload, callback) =>{
    return {
        type:types.CREATE_LOCATIONS_VIA_EXCEL,
        payload,
        callback
    }
}
export const updateLocation = (payload, callback) =>{
    return {
        type:types.UPDATE_LOCATION,
        payload,
        callback
    }
}
export const deleteLocation = (payload, callback) =>{
    return {
        type:types.DELETE_LOCATION,
        payload,
        callback
    }
}