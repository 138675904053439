let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: "Order No",
        field: "orderNumber",
        sort: "asc",
        width: 2,
      },
      {
        label: "Warehouse No",
        field: "warehouseNumber",
        sort: "asc",
        width: 3,
      },
     
      {
        label: "Invoice number",
        field: "invoiceNumber",
        sort: "asc",
        width: 3,
      },
      {
        label: "Invoice generated time",
        field: "invoiceGeneratedTime",
        sort: "asc",
        width: 3,
      },
      {
        label: "Invoice Dispatched time",
        field: "invoiceDispatchedTime",
        sort: "asc",
        width: 3,
      },
      {
        label: "Lead time TAT",
        field: "leadTimeTAT",
        sort: "asc",
        width: 2,
      },
    
      // {
      //   label: "Action",
      //   field: "action",
      //   sort: "asc",
      //   width: 1,
      // },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var AdminHeaderData = {
  columns: [
    {
      label: "Order No",
      field: "orderNumber",
      sort: "asc",
      width: 1,
    },
    {
      label: "Warehouse No",
      field: "warehouseNumber",
      sort: "asc",
      width: 2,
    },
    {
      label: "Invoice number",
      field: "invoiceNumber",
      sort: "asc",
      width: 2,
    },
    {
      label: "Invoice generated time",
      field: "invoiceGeneratedTime",
      sort: "asc",
      width: 4,
    },
    {
      label: "Invoice Dispatched time",
      field: "invoiceDispatchedTime",
      sort: "asc",
      width: 3,
    },
    {
      label: "Lead time TAT",
      field: "leadTimeTAT",
      sort: "asc",
      width: 2,
    },
  
    // {
    //   label: "Action",
    //   field: "action",
    //   sort: "asc",
    //   width: 1,
    // },
  ],
};
export var searchBar;
searchBar = {
  searchBarData: [
    {
      label: "Order number",
      field: "Order Number",
      type: "text",
    },
    {
      label: "Warehouse No",
      field: "Warehouse Number",
      type: "text",
    },
    {
      label: "Invoice number",
      field: "Invoice Number",
      type: "text",
    },
    // {    
    //   label: "Invoice generated time",
    //   field: "Invoice Generated Time",
    //   type: "singleDate",
    // },
    // {
    //   label: "Invoice Dispatched time",
    //   field: "Invoice Dispatched Time",
    //   type: "singleDate"
    // },
    {}
  ],
};
