import React, { Component } from "react";
import { Card } from "@material-ui/core";
import { Row, Col } from "reactstrap";
import OperationalTat from "./OperationalTat";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Select from "react-select";

var chartTypeStore;
export default class OperationalTATCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      export: false,
      // chartType: "line",
      chartType: chartTypeStore?.charTat,
      dropdownOpen: false,
    };
  }
  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };
  componentDidMount() {
    this.setState({ chartType: chartTypeStore });
  }
  toggleExcel = () => {
    setTimeout(async () => {
      let arrTat = [];
      let operationalTat = this.props?.operationalTat;
      // console.log("operationalTat operational TATt", operationalTat);

      operationalTat.forEach((ele) => {
        // console.log("testing ele", ele);
        if (this.props.selectedFilterOperational.value === "month") {
          arrTat.push({
            "Average Loading Time": Number(ele?.averageLoadingTime.toFixed(2)),
            "Average UnLoading Time": Number(
              ele?.averageUnloadingTime.toFixed(2)
            ),
            "Quality Check Time": Number(
              ele?.averageQualityCheckTime.toFixed(2)
            ),
            Month: ele?.tenure,
          });
        } else if (this.props.selectedFilterOperational.value === "year") {
          arrTat.push({
            "Average Loading Time": Number(ele?.averageLoadingTime.toFixed(2)),
            "Average UnLoading Time": Number(
              ele?.averageUnloadingTime.toFixed(2)
            ),
            "Quality Check Time": Number(
              ele?.averageQualityCheckTime.toFixed(2)
            ),
            Year: ele?.tenure,
          });
        } else if (this.props.selectedFilterOperational.value === "weekDay") {
          arrTat.push({
            "Average Loading Time": Number(ele?.averageLoadingTime.toFixed(2)),
            "Average UnLoading Time": Number(
              ele?.averageUnloadingTime.toFixed(2)
            ),
            "Quality Check Time": Number(
              ele?.averageQualityCheckTime.toFixed(2)
            ),
            "Week Day": ele?.tenure,
          });
        } else if (this.props.selectedFilterOperational.value === "week") {
          arrTat.push({
            "Average Loading Time": Number(ele?.averageLoadingTime.toFixed(2)),
            "Average UnLoading Time": Number(
              ele?.averageUnloadingTime.toFixed(2)
            ),
            "Quality Check Time": Number(
              ele?.averageQualityCheckTime.toFixed(2)
            ),
            Week: ele?.tenure,
          });
        } else if (this.props.selectedFilterOperational.value === "date") {
          arrTat.push({
            "Average Loading Time": Number(ele?.averageLoadingTime.toFixed(2)),
            "Average UnLoading Time": Number(
              ele?.averageUnloadingTime.toFixed(2)
            ),
            "Quality Check Time": Number(
              ele?.averageQualityCheckTime.toFixed(2)
            ),
            Date: ele?.tenure,
          });
        } else if (this.props.selectedFilterOperational.value === "quarter") {
          arrTat.push({
            "Average Loading Time": Number(ele?.averageLoadingTime.toFixed(2)),
            "Average UnLoading Time": Number(
              ele?.averageUnloadingTime.toFixed(2)
            ),
            "Quality Check Time": Number(
              ele?.averageQualityCheckTime.toFixed(2)
            ),
            Quater: ele?.tenure,
          });
        }
      });

      await this.exportToCSV([...arrTat], "OPERATIONALTAT_DATA_EXCEL");
    }, 1000);

    this.setState({ loading: true, export: true });
  };

  exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    this.setState({ export: false });
  };

  render() {
    var darkMode = this.props?.darkTheme;
    chartTypeStore = localStorage.getItem("charTat")
      ? localStorage.getItem("charTat")
      : "line";
    const customStyles = {
      option: (styles, state) => ({
        ...styles,
        cursor: "pointer",
      }),
      menu: ({ width, ...css }) => ({
        ...css,
        cursor: "pointer",
        width: "100%",
        backgroundColor: darkMode ? "#171722" : "#ffffff",
        color: darkMode ? "#B7A9FF" : "#505d69",
        border: darkMode
          ? "0.5px solid #616161"
          : "0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode
          ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
          : "0px 3px 12px rgba(0, 0, 0, 0.12)",
        // minWidth: "100%",
      }),
      control: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          width:
            this.props.selectedTranspoterValues?.length >= 2 ? "100%" : "100%",
          minWidth: "100%",
          minHeight: "26px",
          flexWrap: "nowrap",
          backgroundColor: this.props?.opCardStyle ? "#2E2E46" : "",
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          cursor: "pointer",
          marginTop: "2px",
          height: "26px",
          minHeight: "26px",
          fontSize: "13px",
          fontFamily: "Roboto",
          fontWeight: 400,
          fontStyle: "normal",
          paddingLeft: "10px",
          paddingTop: "5px",
          color: this.props?.opCardStyle ? "#9D9D9D" : "#0C0C0C",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          cursor: "pointer",
          // backgroundColor: "#8e9af8",
          height: "24px",
          minHeight: "24px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "8px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          minHeight: "26px",
          color: this.props?.opCardStyle ? "#b7a9ff" : "#0066cc",
        };
      },
    };

    return (
      <>
        <Card
          className="operational-Tat-parent darkCardStyle"
          // style={{
          //   height: "239px",
          // }}
        >
          <div className="mt-2 pl-3">
            <Row className="pt-2">
              <Col xs={8} md={9} lg={10} className="pdt5">
                <span className="roboto-font-700 span-shipment-name light__span">
                  {this.props?.userLang?.dashboard?.OPERATIONAL_TAT}
                </span>
              </Col>
              <Col xs={4} md={3} lg={2}>
                {/* <span
                  className="pr-3 light__span"
                  style={{
                    float: "right",
                    marginLeft: "535px",
                  }}
                > */}
                <div className="d-flex justify-content-end pr-2 OperationaExport">
                  <div className="pr-2">
                    <div className="">
                      <Select
                        style={{
                          width: "100px",
                          height: "26px",
                        }}
                        className=""
                        classNamePrefix="react-select"
                        placeholder="Select"
                        autoFocus={false}
                        isSearchable
                        styles={customStyles}
                        value={this.props.selectedFilterOperational}
                        options={this.props.corbonFootOptions}
                        onChange={this.props.onChangeOperationalTat}
                      />
                    </div>
                  </div>
                  <div className="pr-2">
                    <div className="darkDrop">
                      <Dropdown
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggle}
                        className="d-inline-block user-dropdown"
                      >
                        <DropdownToggle
                          style={{
                            width: "100%",
                            display: "flex",
                            height: "26px",
                            border: darkMode
                              ? "0.5px solid #616161"
                              : "1px solid blue",
                            justifyContent: "center",
                          }}
                          tag="button"
                          className="btn mt-0 pt-0"
                          // id="page-header-user-dropdown"
                        >
                          <span
                            style={{
                              display: "flex",
                              marginTop: "5px",
                            }}
                            className="d-xl-inline-block ml-1 text-transform profile-name light__span"
                          >
                            {this.state.chartType
                              ? this.state.chartType
                              : "line"}
                          </span>
                          <i
                            style={{
                              fontSize: "17px",
                            }}
                            className="mdi mdi-chevron-down ml-1 pl-1 d-xl-inline-block mt-0 profile-chervon"
                          ></i>
                        </DropdownToggle>
                        <DropdownMenu right className="barDropDark">
                          <DropdownItem
                            onClick={() => {
                              this.setState({ chartType: "line" });
                              localStorage.setItem("charTat", "line");
                            }}
                          >
                            <span className="actionDspan">Line</span>
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              this.setState({ chartType: "bar" });
                              localStorage.setItem("charTat", "bar");
                            }}
                          >
                            <span className="actionDspan">Bar</span>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>

                  <div className="">
                    <div
                      className={
                        navigator.onLine
                          ? "tooltipIcon"
                          : "tooltipIcon disable-export"
                      }
                    >
                      <svg
                        className="cursor-pointer exportDark"
                        onClick={() => {
                          this.toggleExcel();
                        }}
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.6299 0.0490417H4.62988C2.42074 0.0490417 0.629883 1.8399 0.629883 4.04904V22.049C0.629883 24.2582 2.42074 26.049 4.62988 26.049H22.6299C24.839 26.049 26.6299 24.2582 26.6299 22.049V4.04904C26.6299 1.8399 24.839 0.0490417 22.6299 0.0490417Z"
                          fill="#F1F5F7"
                        />
                        <path
                          d="M22.6299 0.299042H4.62988C2.55882 0.299042 0.879883 1.97797 0.879883 4.04904V22.049C0.879883 24.1201 2.55882 25.799 4.62988 25.799H22.6299C24.701 25.799 26.3799 24.1201 26.3799 22.049V4.04904C26.3799 1.97797 24.701 0.299042 22.6299 0.299042Z"
                          stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                          strokeWidth="0.5"
                        />
                        <path
                          d="M18.7559 14.133V18.983C18.7559 19.4119 18.5855 19.8232 18.2823 20.1264C17.979 20.4297 17.5677 20.6 17.1389 20.6H8.24688C7.81803 20.6 7.40674 20.4297 7.10349 20.1264C6.80024 19.8232 6.62988 19.4119 6.62988 18.983V10.091C6.62988 9.66219 6.80024 9.2509 7.10349 8.94765C7.40674 8.64441 7.81803 8.47404 8.24688 8.47404H13.0969"
                          stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.3311 6.04904H21.1811V10.899"
                          stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.9058 15.325L20.9058 6.32504"
                          stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span className="tooltipIcontext1" style={{}}>
                        &nbsp;
                        {this.props?.userLang?.invoice?.DOWNLOAD_EXCEL ??
                          "Download Excel"}
                        &nbsp;
                      </span>
                    </div>
                  </div>
                </div>

                {/* </span> */}
              </Col>
            </Row>
          </div>
          <hr className="mt-3 operationalTat-hr-line" />
          <Row>
            <Col
              xs="11"
              md="8"
              lg="8"
              style={{
                paddingLeft: "24px",
              }}
            >
              <Card
                className="mt-0 mb-3 operational-Tat-card"
                style={{
                  width: "100%",
                  height: "220px",
                  backgroundColor: `${darkMode ? "#171722" : "#F1F5F7"}`,
                }}
              >
                <OperationalTat
                  operationalTat={this.props?.operationalTat}
                  chartType={this.state.chartType}
                  userLang={this.props.userLang}
                  darkTheme={darkMode}
                  onChangeOperationalTat={this.props.onChangeOperationalTat}
                  selectedFilterOperational={
                    this.props.selectedFilterOperational
                  }
                />
              </Card>
            </Col>
            <Col xs="12" md="4" lg="4">
              <ul className="d-flex justify-content-sm-around OperationalTatLabelsMobileDiv">
                <li className="OperationalTatLi">
                  <div>
                    <span className="span-operationalTat-cirlce1 span-operTat-avg-out-circle">
                      <span className="span-operationalTat-cirlce2 span-operTat-avg-small-circle"></span>
                    </span>
                  </div>
                  <div className="mobileSpanOperationalTat">
                    <span className="mt-0 roboto-font-400-12 span-operational light__span">
                      Average Loading Time
                    </span>
                  </div>
                </li>
                <li className="OperationalTatLi">
                  <div>
                    <span className="span-operationalTat-cirlce1 span-operTat-aug-out-circle">
                      <span className="span-operationalTat-cirlce2 span-operTat-aug-small-circle"></span>
                    </span>
                  </div>
                  <div className="mobileSpanOperationalTat">
                    <span className="mt-0 pt-0 roboto-font-400-12 span-operational light__span">
                      Average Unloading Time
                    </span>
                  </div>
                </li>
                <li className="OperationalTatLi">
                  <div>
                    <span className="span-operationalTat-cirlce1 span-operTat-qc-out-circle">
                      <span className="span-operationalTat-cirlce2 span-operTat-qc-small-circle"></span>
                    </span>
                  </div>
                  <div className="mobileSpanOperationalTat">
                    <span className="mt-0 pt-0 roboto-font-400-12 span-operational light__span">
                      Quality Check Time Average
                    </span>
                  </div>
                </li>
              </ul>
              {/* <div className="mobile-operational-tat-labels d-flex flex-row"> */}
              <div className="pt-3 mobile-operational-tat-labels operational-label-div">
                <Row className="p-1 opeartionalTAtLabels" style={{}}>
                  <Col xs="2" className="">
                    <span className="span-operationalTat-cirlce1 span-operTat-avg-out-circle">
                      <span className="span-operationalTat-cirlce2 span-operTat-avg-small-circle"></span>
                    </span>
                  </Col>
                  <Col xs="10" className="mobile-operational-Tat-labels">
                    <span className="mt-0 roboto-font-400-12 span-operational light__span">
                      {this.props?.userLang?.dashboard?.AVERAGE_LOADING_TIME}
                    </span>
                  </Col>
                </Row>

                <Row className=" p-1 opeartionalTAtLabels">
                  <Col xs="2" className="">
                    <span className="span-operationalTat-cirlce1 span-operTat-aug-out-circle">
                      <span className="span-operationalTat-cirlce2 span-operTat-aug-small-circle"></span>
                    </span>
                  </Col>
                  <Col xs="10" className="mobile-operational-Tat-labels">
                    <span className="mt-0 pt-0 roboto-font-400-12 span-operational light__span">
                      {this.props?.userLang?.dashboard?.AVERAGE_UNLOADING_TIME}
                    </span>
                  </Col>
                </Row>

                <Row className="p-1">
                  <Col xs="2" className="">
                    <span className="span-operationalTat-cirlce1 span-operTat-qc-out-circle">
                      <span className="span-operationalTat-cirlce2 span-operTat-qc-small-circle"></span>
                    </span>
                  </Col>
                  <Col xs="10" className="mobile-operational-Tat-labels">
                    <span className="mt-0 pt-0 roboto-font-400-12 span-operational light__span">
                      {
                        this.props?.userLang?.dashboard
                          ?.QUALITY_CHECK_TIME_AVERAGE
                      }
                    </span>
                  </Col>
                </Row>
              </div>
              {/* </div> */}
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}
