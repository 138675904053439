import React, { Component } from "react";
import { Row, Col, Container } from "reactstrap";
import { connect } from "react-redux";
import {
  fetchUsers,
  fetchUsersExcel,
  fetchUsersfilters,
  UpdateUser,
  fetchUserDetails,
  createUsersViaExcel,
} from "../../store/auth/register/actions";
import Pagination from "@material-ui/lab/Pagination";
import GetAppIcon from "@material-ui/icons/GetApp";
import { getAccessForEntity } from "../../pages/policies";
import Switch from "@material-ui/core/Switch";
import { tableColumn } from "../Utility/userUtils";
import { NotificationManager } from "react-notifications";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { WaveLoader } from "../../components/Common/Loader/LoaderIndex";
import QuickFilters from "./QuickFilters";
import Index from "../../components/Common/DataTableNeo/Index";
import {
  HeaderData,
  AdminHeaderData,
  searchBar,
  searchBarBaxUser,
  getLocalStorage,
  AdminHeaderDataParentCode,
} from "./RowHeaders/RowHeaders";
import { fetchHops } from "../../store/hop/actions";
import { fetchRoles } from "../../store/accessPolicy/actions";
import actionVerticleDots from "./assets/more-vertical.png";
import { Button } from "reactstrap";
import UserDetails from "./UserDetails";
import * as FileSaver from "file-saver";
import {
  fetchLocationPresignedUrl,
  uploadImagesAws,
} from "../../store/baxter-uploadModule/actions";
import { exportToCSV } from "../common/exportToCsv";

// import Popup from "../../components/Common/Popup";
// import UserForm_New from "../Forms/UserForm_New";

var data = {};
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var darkTheme;

class Users_New extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoading: false,
      idToDelete: "",
      open: false,
      rows: [],
      loading: true,
      policies: [],
      breadcrumbItems: [
        { title: "User", link: "user" },
        { title: "User Details", link: "#" },
      ],
      filters: "",
      key: "fullName",
      searchOn: false,
      nameFilter: "",
      emailFilter: "",
      phoneFilter: "",
      setOpenPopup: false,
      hops: [],
      selectedHopValues: "",
      roleArr: [],
      selectedRoleValue: "",
      user: "",
      hopModal: false,
      loadingForExcel: false,
      isOpen: false,
      selectedFile: "",
      excelUrl: "",
      parentCode: "",
    };
    this.fileInput = React.createRef();
  }
  fileUploadTrigger = () => {
    this.fileInput.current.click();
  };
  componentDidMount = () => {
    this.setState({ dataLoading: true });
    this.getAllData();
    this.getAllLocationsData();
    this.getRolesOffset();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }

  componentDidUpdate(prevProps) {
    if (this.props.policies !== prevProps.policies) {
      this.getAllData();
    }
  }

  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };
  // calling hops api
  fetchHops = (filtersHopsData) => {
    this.props.fetchHops(
      {
        offset: filtersHopsData.offset,
        limit: filtersHopsData.limit,
        label: filtersHopsData.q,
      },
      (res) => {
        let hops = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            hops.push({
              label: ele?.label ? ele?.label : "",
              value: ele?._id ? ele?._id : "",
            });
          });
        }
        this.setState(
          {
            hops: hops,
          },
          () => {},
          () => {}
        );
      }
    );
  };
  // get location name by filter
  filtersHops = (e) => {
    if (e?.length > 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: e,
      };
      this.fetchHops(filtersHopsData);
    }
    if (e.length === 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: "",
      };
      this.fetchHops(filtersHopsData);
    }
  };
  onChangeHop = (selectedHopValues) => {
    this.setState({ selectedHopValues }, () => {
      // console.log("selectedHopValues", this.state.selectedHopValues);
      this.filterList();
    });
  };
  onChangeRole = (selectedRoleValue) => {
    this.setState({ selectedRoleValue }, () => {
      // console.log("selectedHopValues", this.state.selectedRoleValue);
      this.filterList();
    });
  };
  getRolesOffset = () => {
    let offset = 0;
    this.props.fetchRoles(offset, "", (res) => {
      // console.log("res",res.data)
      let roleArr = [];
      if (Array.isArray(res.data)) {
        res.data.forEach((ele) => {
          roleArr.push({
            value: ele._id,
            label:
              ele.label.replace(/_/g, " ").charAt(0).toUpperCase() +
              ele.label.replace(/_/g, " ").slice(1),
          });
        });
        this.setState({ roleArr }, () => {
          // console.log("role Arr", roleArr);
        });
      }
    });
  };
  getAllLocationsData = () => {
    let filtersHopsData = {
      offset: 0,
      limit: 10,
      q: "",
    };
    this.fetchHops(filtersHopsData);
  };
  setOpenPopupfalse = (props) => {
    this.setState({ setOpenPopup: props });
  };
  handleSubmit = (props) => {
    this.setState({ setOpenPopup: props });
    this.setState({ dataLoading: true });
    this.getAllData();
  };

  hasAccessPolicy(val, entity) {
    const { policies } = this.props;
    let hasAccess = false;
    if (policies && policies.length > 0) {
      hasAccess = getAccessForEntity(policies, entity, val);
    }
    return hasAccess;
  }

  getAllData = () => {
    this.setState({ dataLoading: true });
    let filters = {
      role: this.state.selectedRoleValue.value,
      hop: this.state.selectedHopValues.value,
      name: this.state.nameFilter,
      email: this.state.emailFilter,
      phone: this.state.phoneFilter,
      parentCode: this.state.parentCode ? this.state.parentCode : "",
    };

    this.props.fetchUsers(offset, filters, (res) => {
      // this.refineData(res);
      this.setState({ dataLoading: false });
    });
    if (!navigator.onLine) {
      this.setState({ dataLoading: false }, () => {
        // this.refineData(localStorageData);
      });
    }
  };

  // Baxter
  handleSubmitExcel = () => {
    if (this.state.excelUrl) {
      // console.log("excelUrl", this.state.excelUrl);
      this.setState({
        loadingForExcel: true,
      });
      this.props.createUsersViaExcel(
        { excelFileURL: this.state.excelUrl },
        (res) => {
          if (res.success) {
            this.setState({
              loadingForExcel: false,
            });
            NotificationManager.success(res["message"], "Success");
            this.getAllData();
            this.closeModal();
          } else {
            NotificationManager.error(res["message"], "Error");
          }
        }
      );
    }
  };
  handledownloadTemplate = () => {
    let userUploadTemplate =
      "https://baxterfiles.s3.ap-south-1.amazonaws.com/15-11-2023/location_master/main/LOCATION_MASTER/3894a926-f693-4e2a-b79a-136614258ef4_09d30a96-0b8d-4ad9-911b-0f443fd129ae.vnd.ms-excel";
    let filename = "User Upload Template.xlsx";
    FileSaver.saveAs(userUploadTemplate, filename);
  };
  fileUpload = (event) => {
    this.setState(
      {
        selectedFile: event.target.files[0],
      },
      () => {
        this.S3Upload();
      }
    );
  };
  S3Upload = async () => {
    this.props.fetchLocationPresignedUrl((res) => {
      // console.log("res", res.presignedS3Url);
      let softCopy = "";
      softCopy = res.presignedS3Url.split("?")[0];
      // console.log("SoftCopy", softCopy);
      this.setState(
        {
          excelUrl: softCopy,
        },
        () => {
          // console.log("images", this.state.images);
          const formData = new FormData();
          formData.append(
            this.state.selectedFile,
            this.state.selectedFile.name
          );
          // console.log("--------", formData);
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", this.state.selectedFile.type);
          this.props.uploadImagesAws(
            res.presignedS3Url,
            this.state.selectedFile,
            myHeaders
          );
        }
      );
    });
  };

  handleUserActivation = (userId, index, e) => {
    const { rows } = this.state;
    const rowData = [...rows];
    rowData[index].status = e.target.checked
      ? this.renderUserStatus("Active")
      : this.renderUserStatus("Inactive");
    rowData[index].action = this.renderSwitch(userId, index, e.target.checked);
    this.setState({ dataLoading: true });
    this.props.UpdateUser(userId, { isActive: e.target.checked }, () => {
      this.setState({ rows: rowData, dataLoading: false });
    });
  };

  renderSwitch = (id, index, isActive) => {
    return (
      <Switch
        checked={isActive}
        onChange={(e) => this.handleUserActivation(id, index, e)}
        color="primary"
        name="userActivation"
      />
    );
  };

  renderUserStatus = (type) => {
    return (
      <span
        style={{
          color: "#2800FC",
          fontFamily: "Roboto",
          fontSize: "13px",
          fontStyle: "normal",
          fontWeight: "700",
        }}
      >
        {type}
      </span>
    );
  };

  handleClickOpen = (id) => {
    this.setState({ open: true, idToDelete: id });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChangePage = (event, val) => {
    this.setState({ dataLoading: true });
    if (this.state.searchOn !== true) {
      offset = (val - 1) * process.env.REACT_APP_PERPAGE;
      this.getAllData();
    } else {
      offset = (val - 1) * process.env.REACT_APP_PERPAGE;
      this.getAllData();
      // this.props.fetchUsersfilters(
      //   offset,
      // {
      //   role: this.state.selectedRoleValue.value,
      //   hop: this.state.selectedHopValues.value,
      //   name: this.state.nameFilter,
      //   email: this.state.emailFilter,
      //   phone: this.state.phoneFilter,
      // },
      //   (res) => {
      //     // this.refineData(res);
      //     this.setState({ dataLoading: false });
      //   }
      // );
    }
  };

  enterCheck = (event) => {
    if (event.key === "Enter") this.filterList();
  };

  filterList = () => {
    // console.log("called");
    // if (this.state.filters.length > 2) {
    this.setState({ dataLoading: true }, () => {
      if (this.state.searchOn !== true) this.setState({ searchOn: true });
      offset = 0;
      // console.log(this.state , "MY CALL")
      this.getAllData();
      // this.props.fetchUsersfilters(
      //   offset,
      //   // this.state.filters.trim(),
      //   {
      //     role: this.state.selectedRoleValue.value,
      //     hop: this.state.selectedHopValues.value,
      //     name: this.state.nameFilter,
      //     email: this.state.emailFilter,
      //     phone: this.state.phoneFilter,
      //   },
      //   (res) => {
      //     // this.refineData(res);
      //     // console.log(res, "compo");
      //     this.setState({ dataLoading: false });
      //     if (res.message && res.status) {
      //       if (res.status === "success")
      //         NotificationManager.success(res["message"], "Success");
      //       else NotificationManager.warning(res["message"], "Warning");
      //     }
      //   }
      // );
    });
    // }
  };

  change = (e) => {
    let filters = e.target.value;
    this.setState({ filters });
  };

  filterAssigner = (e, filter) => {
    this.setState({ [filter]: e.target.value }, () => {
      // console.log(this.state.hopFilter, "test1");
    });
  };

  changed = (e) => {
    let key = e.target.value;
    this.setState({ key });
  };

  reset = (e) => {
    this.setState({ loading: true, searchOn: false });
    offset = 0;
    this.setState(
      {
        nameFilter: "",
        phoneFilter: "",
        emailFilter: "",
        selectedHopValues: "",
        selectedRoleValue: "",
        parentCode: "",
      },
      () => {
        this.getAllData();
      }
    );
  };
  // onClickAddUser = () => {
  //   this.setState({ setOpenPopup: true });
  // };

  closeHopModalBody = (ele) => {
    this.setState({
      hopModal: !this.state.hopModal,
      user: ele,
    });
  };
  openModal = () => {
    this.setState({
      isOpen: true,
    });
  };
  closeModal = () => {
    this.setState({
      isOpen: false,
      excelUrl: "",
    });
  };

  exportExcelData = () => {
    this.setState({ dataLoading: true });
    let filters = {
      role: this.state.selectedRoleValue.value,
      hop: this.state.selectedHopValues.value,
      name: this.state.nameFilter,
      email: this.state.emailFilter,
      phone: this.state.phoneFilter,
      parentCode: this.state.parentCode ? this.state.parentCode : "",
    };
    this.props.fetchUsersExcel(0, filters, (res) => {
      this.setState({ dataLoading: false });
      // this.setState({ isExportingExcel: false });
      // let exdata=[]
      console.log(res);
      let exportData = [];
      if (res?.data?.docs?.length > 0) {
        if (process.env.REACT_APP_CLIENT === "3_BAXTER") {
          res.data.docs.forEach((ele, index) => {
            // console.log("RES", ele)
            let hopslist = "";
            for (let i = 0; i < ele?.hops?.length; i++) {
              hopslist += ele?.hops[i]?.hopCode + ",";
            }
            exportData.push({
              "Parent Code": ele?.parentCode,
              Email: ele?.email ? ele.email : "-",
              Role: ele.role
                ? ele?.role?.label.replace(/_/g, " ").charAt(0).toUpperCase() +
                  ele?.role?.label.replace(/_/g, " ").slice(1)
                : "Not Assigned",
              // hop: (
              //   <div className="tooltip2">
              //     {ele?.hops[0]?.label}
              //     <span className="tooltiptext">
              //       {hopslist.substring(0, hopslist?.length - 1)}
              //     </span>
              //   </div>
              // ),
              //hop: ele?.hops[0]?.label,
              Status: ele?.isActive ? "Active" : "Inactive",
            });
          });
        } else {
          res.data.docs.forEach((ele, index) => {
            // console.log("RES", ele)
            let hopslist = "";
            for (let i = 0; i < ele?.hops?.length; i++) {
              hopslist += ele?.hops[i]?.hopCode + ",";
            }
            exportData.push({
              "Parent Code": ele?.parentCode,
              Email: ele?.email ? ele.email : "-",
              Role: ele.role
                ? ele?.role?.label.replace(/_/g, " ").charAt(0).toUpperCase() +
                  ele?.role?.label.replace(/_/g, " ").slice(1)
                : "Not Assigned",
              // hop: (
              //   <div className="tooltip2">
              //     {ele?.hops[0]?.label}
              //     <span className="tooltiptext">
              //       {hopslist.substring(0, hopslist?.length - 1)}
              //     </span>
              //   </div>
              // ),
              //hop: ele?.hops[0]?.label,
              Status: ele?.isActive ? "Active" : "Inactive",
            });
          });
        }
      }
      // console.log("export Data", exportData);
      if (exportData?.length > 0) {
        exportToCSV([...exportData], "Users");
      } else {
        exportData.push({
          "Parent Code": "",
          Email: "",
          Role: "",
          // hop: (
          //   <div className="tooltip2">
          //     {ele?.hops[0]?.label}
          //     <span className="tooltiptext">
          //       {hopslist.substring(0, hopslist?.length - 1)}
          //     </span>
          //   </div>
          // ),
          //hop: ele?.hops[0]?.label,
          Status: "",
        });
        exportToCSV([...exportData], "Users");
      }
    });
  };

  render() {
    let authUser1 = JSON.parse(localStorage.getItem("authUser")).data.userType;
    let baxterUserList = [];
    let initTogglestorage = localStorage.getItem("Theme");
    if (
      this.props.switchsTheme === "dark__mode" ||
      initTogglestorage === "dark__mode"
    ) {
      darkTheme = true;
    } else if (
      this.props.switchsTheme === "light__mode" ||
      initTogglestorage === "light__mode"
    ) {
      darkTheme = false;
    }
    // console.log(AdminHeaderData, "AdminHeaderData");
    const { user } = this.props;
    let rows = [];
    let res = this.props.fetchUser;
    // debugger
    // console.log("RES", res)
    if (res !== undefined) {
      if (Array.isArray(res?.data?.docs)) {
        // console.log("RES", res.data)
        totCnt = res?.data?.length;
        fromVal = totCnt !== 0 ? offset + 1 : fromVal;
        toVal = res.data.offset + res.data?.docs?.length;
        totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
        if (process.env.REACT_APP_CLIENT === "3_BAXTER") {
          res.data.docs.forEach((ele, index) => {
            // console.log("RES", ele)
            let hopslist = "";
            for (let i = 0; i < ele?.hops?.length; i++) {
              hopslist += ele?.hops[i]?.hopCode + ",";
            }
            baxterUserList.push({
              contact: ele?.phone ? ele.phone : "-",
              parentCode: ele?.parentCode,
              address: ele?.address ? ele.address : "-",
              city: ele?.city ? ele.city : "-",
              state: ele?.state ? ele.state : "-",
              zipCode: ele?.zipCode ? ele.zipCode : "-",
              email: ele?.email ? ele.email : "-",
              role: ele.role
                ? ele?.role?.label.replace(/_/g, " ").charAt(0).toUpperCase() +
                  ele?.role?.label.replace(/_/g, " ").slice(1)
                : "Not Assigned",
              hop: (
                <div className="tooltip2">
                  {ele?.hops[0]?.label}
                  <span className="tooltiptext">
                    {hopslist.substring(0, hopslist?.length - 1)}
                  </span>
                </div>
              ),
              //hop: ele?.hops[0]?.label,
              status: ele?.isActive
                ? this.renderUserStatus("Active")
                : this.renderUserStatus("Inactive"),
              action:
                authUser1 === "external" ? (
                  <div className="dropdown" disabled={true}>
                    <Button
                      className="iconButtons dropbtn"
                      style={{
                        backgroundColor: darkTheme ? "#BDBDBD" : "#24243C",
                        border: darkTheme ? "0.5px solid #B7A9FF" : "",
                      }}
                    >
                      <img
                        style={{ margin: "-4px" }}
                        src={actionVerticleDots}
                        alt="actions"
                      />
                    </Button>
                    <div className="dropdown-content contentDark">
                      <div
                        className="action-button actionDspan"
                        onClick={() => {
                          this.closeHopModalBody(ele);
                        }}
                      >
                        Edit
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="dropdown" disabled={false}>
                    <Button className="iconButtons dropbtn actionButtonsDark">
                      <img
                        style={{ margin: "-4px" }}
                        src={actionVerticleDots}
                        alt="actions"
                      />
                    </Button>
                    <div className="dropdown-content contentDark">
                      <div
                        className="action-button"
                        onClick={() => {
                          this.closeHopModalBody(ele);
                        }}
                      >
                        Edit
                      </div>
                    </div>
                  </div>
                ),
            });
          });
        } else {
          res.data.docs.forEach((ele, index) => {
            // console.log("RES", ele)
            let hopslist = "";
            for (let i = 0; i < ele?.hops?.length; i++) {
              hopslist += ele?.hops[i]?.hopCode + ",";
            }
            let itemObject = {
              contact: ele?.phone ? ele.phone : "-",
              userName: (
                <div
                  className="text-capitalize textALignUser"
                  // style={{ textAlign: 'start', marginLeft: '28%' }}
                >
                  {ele?.name ? ele.name.replace(/_/g, " ") : "-"}
                </div>
              ),
              address: ele?.address ? ele.address : "-",
              city: ele?.city ? ele.city : "-",
              state: ele?.state ? ele.state : "-",
              zipCode: ele?.zipCode ? ele.zipCode : "-",
              email: ele?.email ? ele.email : "-",
              role: ele?.role
                ? ele?.role?.label?.replace(/_/g, " ").charAt(0).toUpperCase() +
                  ele?.role?.label?.replace(/_/g, " ").slice(1)
                : "Not Assigned",
              hop: (
                <div className="tooltip2">
                  {ele?.hops[0]?.label}
                  <span className="tooltiptext">
                    {hopslist.substring(0, hopslist?.length - 1)}
                  </span>
                </div>
              ),
              //hop: ele?.hops[0]?.label,
              status: ele?.isActive
                ? this.renderUserStatus("Active")
                : this.renderUserStatus("Inactive"),
              action:
                authUser1 === "external" ? (
                  <div className="dropdown" disabled={true}>
                    <Button
                      className="iconButtons dropbtn"
                      style={{
                        backgroundColor: darkTheme ? "#BDBDBD" : "#24243C",
                        border: darkTheme ? "0.5px solid #B7A9FF" : "",
                      }}
                    >
                      <img
                        style={{ margin: "-4px" }}
                        src={actionVerticleDots}
                        alt="actions"
                      />
                    </Button>
                    <div className="dropdown-content contentDark">
                      <div
                        className="action-button actionDspan"
                        onClick={() => {
                          this.closeHopModalBody(ele);
                        }}
                      >
                        Edit
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="dropdown" disabled={false}>
                    <Button className="iconButtons dropbtn actionButtonsDark">
                      <img
                        style={{ margin: "-4px" }}
                        src={actionVerticleDots}
                        alt="actions"
                      />
                    </Button>
                    <div className="dropdown-content contentDark">
                      <div
                        className="action-button"
                        onClick={() => {
                          this.closeHopModalBody(ele);
                        }}
                      >
                        Edit
                      </div>
                    </div>
                  </div>
                ),
            };
            if (
              this.props.user &&
              this.props.user.roleId &&
              this.props.user.roleId.name === "superadmin"
            )
              itemObject.action = this.renderSwitch(
                ele._id,
                index,
                ele.isActive
              );
            rows.push(itemObject);
          });
        }
      }
    }

    // if (loading) {
    //   return (
    //     <div className="page-content">
    //       <Col xs={12} className="loader">
    //         <MoonLoader size={45} color={"#123abc"} />
    //       </Col>
    //     </div>
    //   );
    // }
    data = { columns: tableColumn(), rows: rows };
    if (user && typeof user === "object") {
      if (user.roleId && user.roleId.name && user.roleId.name === "superadmin")
        data.columns.push({
          label: "Action",
          field: "action",
          sort: "asc",
          width: 100,
        });
    }
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;
    return (
      <React.Fragment>
        {process.env.REACT_APP_CLIENT === "3_BAXTER" && (
          <Modal
            isOpen={this.state.isOpen}
            toggle={this.closeModal}
            size="lg"
            className="CustomerModal"
          >
            <ModalHeader>
              <div className="Heading">
                <h3>
                  <span className="dark__span">Upload Users Data</span>
                </h3>
                <svg
                  onClick={this.closeModal}
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.9092 1.82141L1.90918 13.8214"
                    stroke="#2800FC"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.90918 1.82141L13.9092 13.8214"
                    stroke="#2800FC"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </ModalHeader>
            <ModalBody>
              {this.state.loadingForExcel ? (
                <div
                  style={{
                    height: "200px",
                  }}
                >
                  <WaveLoader />
                </div>
              ) : (
                <div className="imageDiv darkCardStyle">
                  <div className="d-block">
                    <svg
                      onClick={() => this.fileUploadTrigger()}
                      width="150"
                      height="150"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 31.6534C0.0758962 31.2107 0.126494 30.768 0.227689 30.3253C0.809559 27.6309 2.23894 25.5185 4.51582 23.9753C4.65497 23.8741 4.80676 23.7729 4.9712 23.659C4.17429 20.9521 4.57907 18.4855 6.60297 16.4616C8.61422 14.4503 11.0808 13.9949 13.7372 14.8045C17.0893 8.92253 22.0225 6.15232 28.7773 7.22752C33.9256 8.04973 39.1245 12.4517 39.9214 19.3709C40.2376 19.4468 40.5665 19.4974 40.8827 19.5986C44.8167 20.7876 47.511 23.3428 48.8265 27.2009C50.6607 32.5515 48.7633 37.9781 44.0704 41.1531C42.2995 42.3548 40.3262 42.9873 38.1631 42.9873C35.0008 42.9873 31.8511 42.9873 28.6888 42.9873C28.499 42.9873 28.3093 42.9873 28.0942 42.9873C28.0942 39.3949 28.0942 35.8657 28.0942 32.2733C28.2587 32.2606 28.4105 32.248 28.5623 32.248C29.4351 32.248 30.3079 32.2353 31.168 32.248C31.7246 32.2606 32.2432 32.2227 32.5342 31.6534C32.8378 31.0842 32.5595 30.6415 32.2306 30.1988C31.0415 28.5543 29.8525 26.9099 28.6635 25.2655C27.7021 23.9373 26.7408 22.5965 25.7794 21.2683C25.1343 20.3829 24.35 20.3702 23.7176 21.2683C21.5419 24.2662 19.3788 27.2768 17.2031 30.2747C17.1525 30.3379 17.1146 30.4012 17.064 30.4771C16.811 30.8565 16.7351 31.2487 16.9501 31.6661C17.1652 32.0709 17.5194 32.2606 17.9874 32.2606C19.1132 32.2606 20.239 32.2606 21.4027 32.2606C21.4027 35.853 21.4027 39.3949 21.4027 42.9999C21.2003 42.9999 21.0106 42.9999 20.8209 42.9999C17.3043 42.9999 13.7878 42.9873 10.2839 42.9999C6.78006 43.0126 4.11104 41.4314 2.0745 38.6865C0.974001 37.1939 0.328883 35.5115 0.0885456 33.6647C0.0758962 33.5761 0.0252987 33.4876 0 33.3991C0 32.8172 0 32.2353 0 31.6534Z"
                        fill="#C6C6C6"
                      />
                    </svg>
                    <input
                      type="file"
                      name="Image"
                      multiple={true}
                      accept=".xlsx"
                      style={{ display: "none" }}
                      ref={this.fileInput}
                      onChange={(e) => {
                        this.fileUpload(e);
                      }}
                    />
                    <span className="clickText">
                      {this.state.selectedFile?.name
                        ? this.state.selectedFile?.name
                        : "Click here to upload Excel"}
                    </span>
                  </div>
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <button
                className="uploadTemplateButton"
                onClick={() => {
                  this.handledownloadTemplate();
                }}
              >
                <span className="light__span">
                  <i
                    className="fas fa-file-excel"
                    style={{ color: "#20511f" }}
                  ></i>
                  Template
                </span>
                <GetAppIcon fontSize="xs" className="downloadIcon" />
              </button>
              <button
                className="Submit"
                onClick={() => {
                  this.handleSubmitExcel();
                }}
              >
                Submit
              </button>
            </ModalFooter>
          </Modal>
        )}
        {getLocalStorage()}
        {this.state.hopModal && (
          <UserDetails
            user={this.state.user}
            getAlldata={this.getAllData}
            addHop={this.openHopModalToggle}
            isOpen={this.state.hopModal}
            closeHopModal={this.closeHopModalBody}
            mode={darkTheme}
          />
        )}
        <div>
          <QuickFilters
            // onClickAddUser={this.onClickAddUser}
            nameFilter={this.state.nameFilter}
            toggleExcel={this.exportExcelData}
            parentCode={this.state.parentCode}
            enterCheck={this.enterCheck}
            filterAssigner={this.filterAssigner}
            emailFilter={this.state.emailFilter}
            phoneFilter={this.state.phoneFilter}
            reset={this.reset}
            filterList={this.filterList}
            selectedHopValues={this.state.selectedHopValues}
            hopOptions={this.state.hops}
            onChangeHop={this.onChangeHop}
            selectedRoleValue={this.state.selectedRoleValue}
            roleOptions={this.state.roleArr}
            onChangeRole={this.onChangeRole}
            mode={darkTheme}
            filtersHops={this.filtersHops}
            openModal={this.openModal}
          />
          <Container fluid>
            {userType && userType !== "admin" && userType !== "Admin" ? (
              <Index
                {...this.props}
                headers={
                  process.env.REACT_APP_CLIENT === "3_BAXTER"
                    ? AdminHeaderDataParentCode
                    : AdminHeaderData
                }
                data={
                  process.env.REACT_APP_CLIENT === "3_BAXTER"
                    ? baxterUserList
                    : rows
                }
                searchBar={searchBar}
                searchable={false}
                getAllData={this.getAllData}
                rowHeight={"56px"}
                dataLoading={this.state.dataLoading}
                fetchId={this.props.fetchId}
              />
            ) : (
              <Index
                {...this.props}
                headers={
                  process.env.REACT_APP_CLIENT === "3_BAXTER"
                    ? AdminHeaderDataParentCode
                    : AdminHeaderData
                }
                data={
                  process.env.REACT_APP_CLIENT === "3_BAXTER"
                    ? baxterUserList
                    : rows
                }
                searchBar={searchBar}
                searchable={false}
                getAllData={this.getAllData}
                rowHeight={"56px"}
                dataLoading={this.state.dataLoading}
                fetchId={this.props.fetchId}
              />
            )}

            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{ color: darkTheme ? "#ffffff" : "#0C0C0C" }}
                className="mb-2 paginationRow"
              >
                <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                  <div className="float-left">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={7}
                  md={8}
                >
                  <span>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
              </Row>
            ) : null}
          </Container>
          {/* <Popup
            title="Add User"
            openPopup={this.state.setOpenPopup}
            setOpenPopupfalse={this.setOpenPopupfalse}
          >
            <UserForm_New handleSubmit={this.handleSubmit} />
          </Popup> */}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  // console.log(state.Account.fetchUser)
  return {
    ...state,
    user: state.Login.user,
    fetchId: state.Account.fetchId,
    fetchUser: state.Account.fetchUser,
  };
};

export default connect(mapStateToProps, {
  fetchUsers,
  fetchUsersExcel,
  fetchUsersfilters,
  UpdateUser,
  fetchHops,
  fetchRoles,
  fetchUserDetails,
  createUsersViaExcel,
  fetchLocationPresignedUrl,
  uploadImagesAws,
})(Users_New);
