import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";

//i18n
import { withNamespaces } from "react-i18next";

class Breadcrumbs extends Component {
  render() {
    const itemsLength = this.props.breadcrumbItems.length;

    return (
      <React.Fragment>
        <Row className="page-title-box d-flex align-items-left ml-2">
          <Col xs={12} md={12} lg={12} className="pl-2">
            <div className="page-title-right">
              <Breadcrumb listClassName="mt-2">
                <span className="h3 mb-0 font-weight-bold">
                  {this.props.t(this.props.title)}
                </span>
                <div className="d-flex align-items-center px-4">
                  <span style={{ fontSize: "1em" }}>
                    {this.props.breadcrumbItems.map((item, key) =>
                      key + 1 === itemsLength ? (
                        <BreadcrumbItem
                          key={key}
                          className="d-inline-block text-uppercase"
                          active
                        >
                          <strong>{this.props.t(item.title)}</strong>
                        </BreadcrumbItem>
                      ) : (
                        <BreadcrumbItem
                          className="d-inline-block text-uppercase"
                          key={key}
                        >
                          <Link to={item.link}>
                            <strong>{this.props.t(item.title)}</strong>
                          </Link>
                        </BreadcrumbItem>
                      )
                    )}
                  </span>
                </div>
              </Breadcrumb>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(Breadcrumbs);
