import React from "react";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

import moment from "moment";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { headersData, invoiceDetailsHeaderData } from "./ExcelHeader";
import { auth } from "firebase";
import { Comment } from "@material-ui/icons";
let dataArry = [];

const ExportButton = ({ data }) => {
  const [exceldata, setExcelData] = React.useState({});
  const [exceldataArray, setExceldataArray] = React.useState([]);

  React.useEffect(() => {
    setExcelData(data);
  }, [data]);

  const createDownLoadData = () => {
    handleExport().then((url) => {
      // console.log(url);
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", "invoiceReport.xlsx");
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
    dataArry = [];
    setExceldataArray([]);
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    // The application/octet-stream MIME type is used for unknown binary files.
    // It preserves the file contents, but requires the receiver to determine file type,
    // for example, from the filename extension.
    const blob = new Blob([s2ab(wbout)], {
      // type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    // The ArrayBuffer() constructor is used to create ArrayBuffer objects.
    // create an ArrayBuffer with a size in bytes
    const buf = new ArrayBuffer(s.length);

    // console.log(buf);

    //create a 8 bit integer array
    const view = new Uint8Array(buf);

    // console.log(view);
    //charCodeAt The charCodeAt() method returns an integer between 0 and 65535 representing the UTF-16 code
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  let table1 = [...headersData];
  const handleExport = () => {
    dataArry = exceldataArray;
    // setExceldataArray([]);
    let oracleTotalAMount = 0;
    exceldata.rows.map((row) => {
      let finalComents = [
        row?.qualityCheck ? "Quality Check Comment:" + row?.qualityCheck : "",
      ];
      row.comment.map((comment) => {
        finalComents = [
          ...finalComents,
          comment?.shipmentId + "|" + comment?.id + "|" + comment?.comment,
        ];
      });
      oracleTotalAMount = oracleTotalAMount + row?.asPerOracleAmount;
      // if (!processed)

      dataArry.push({
        B: row?.dispatchedDate ? row?.dispatchedDate : "-",
        C: row?.tripID ? row?.tripID : "-",
        D: row?.source,
        E: row?.destination,
        F: row?.route,
        G: row?.lrNumber,
        H: exceldata?.invoice?.gstNo,
        I: row?.truckType,
        J: row?.pfNo ? row?.pfNo : "",
        K: row?.freightAmount,
        L: row?.asPerOracleAmount ? row?.asPerOracleAmount : 0,
        M:
          (row?.freightAmount ? row?.freightAmount : 0) -
          (row?.asPerOracleAmount ? row?.asPerOracleAmount : 0),
        N: row?.destinationDetentionCharges || 0,
        O: row?.sourceDetentionCharges || 0,
        P: row?.loadingCharge || 0,
        Q: row?.UnLoadingCharges || 0,
        R:
          (row?.multipointPickupCharges ? row?.multipointPickupCharges : 0) +
          (row?.multipointDropCharges ? row?.multipointDropCharges : 0),
        S: row?.otherCharges ? row?.otherCharges : 0,
        T: row?.lateDeliveryCharges || 0,
        U: row?.damageCharges || 0 + row?.shortageCharges || 0,
        V: row?.revisedOtherDeductionsCharges
          ? row?.revisedOtherDeductionsCharges
          : 0,
        W: row?.total?.props?.children ? row?.total?.props?.children : 0,
        X: row?.creditNoteNoGST,
        Y: finalComents.join(","),
      });
    });
    let row = exceldata?.finalRow;
    dataArry.push({
      B: "Total",
      C: "   -   ",
      D: "   -   ",
      E: "   -   ",
      F: "   -   ",
      G: "   -   ",
      H: "   -   ",
      I: "   -   ",
      J: "    -   ",
      K: row?.freightAmount || 0,
      L: oracleTotalAMount ? oracleTotalAMount : 0,
      M:
        (row?.freightAmount ? row?.freightAmount : 0) -
        (oracleTotalAMount ? oracleTotalAMount : 0),
      N: row?.destinationDetentionCharges
        ? row?.destinationDetentionCharges
        : 0,
      O: row?.sourceDetentionCharges ? row?.sourceDetentionCharges : 0,
      P: row?.loadingCharge || 0,
      Q: row?.UnLoadingCharges || 0,
      R:
        row?.multipointPickupCharges || 0 + row?.MultiPointDropChargesData || 0,
      S: row?.otherCharges ? row?.otherCharges : 0,
      T: row?.lateDeliveryCharges || 0,
      U: row?.damageCharges || 0 + row?.shortageCharges || 0,
      V: row?.revisedOtherDeductionsCharges
        ? row?.revisedOtherDeductionsCharges
        : 0,
      W: row?.total ? row?.total : 0,
      X: row?.creditNoteNoGST,
      Y: "-",
    });
    setExceldataArray(dataArry);

    table1 = [{ B: `${exceldata?.titleHeader}` }]
      .concat(table1)
      .concat(exceldataArray);
    const title = [
      {
        A: `${moment(new Date(exceldata?.invoice?.createdAt)).format(
          "DD-MMM-YYYY"
        )}`,
      },
      { A: `Statement - ${exceldata?.invoice?.gstNo}` },
      { A: "FCM @ 12%" },
      { A: "" },
    ];

    // const finalData = [...title, ...table1];
    let finalData = [];
    finalData = finalData.concat(title).concat(table1);
    let invoiceDetails = {
      A: exceldata?.titleHeader,
      B:
        exceldata?.invoice?.invoiceId +
        "," +
        moment(new Date(exceldata?.invoice?.createdAt)).format("DD-MMM-YYYY"),
      C:
        exceldata?.invoice?.gstNo +
        "," +
        moment(new Date(exceldata?.invoice?.fromDate)).format("DD-MMM-YYYY"),
      D: exceldata?.invoice?.state?.stateCode,
      E: exceldata?.invoice?.bills?.length,
      F: exceldata?.invoice?.comment,
    };
    let finalData2 = [...invoiceDetailsHeaderData, invoiceDetails];
    const fileExtension = ".xlsx";

    const wb = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });
    const sheet1 = XLSX.utils.json_to_sheet(finalData2, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet1, "invoice_details");
    // if (!processed) {
    XLSX.utils.book_append_sheet(wb, sheet, "invoice_report");

    // const workbookBlob1 = workbook2blob(wb1);
    // }
    const workbookBlob = workbook2blob(wb);
    return addStyle(workbookBlob, dataArry, sheet);
  };

  const addStyle = (workbookBlob, dataArry) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      if (workbook.sheets()[1]) {
        let sheet = workbook.sheets()[1];
        let HedaerB = dataArry?.length + 7;

        let customBorder = {
          border: true,
          borderStyle: "double",
          borderColor: "FF0000",
        };
        let customLeftBorder = {
          leftBorder: true,
          leftBorderStyle: "double",
          leftBorderColor: "FF0000",
        };
        let customRightBorder = {
          rightBorder: true,
          rightBorderStyle: "double",
          rightBorderColor: "FF0000",
        };
        let customBottomBorder = {
          bottomBorder: true,
          bottomBorderStyle: "double",
          bottomBorderColor: "FF0000",
        };
        let customTopBorder = {
          topBorder: true,
          topBorderStyle: "double",
          topBorderColor: "FF0000",
        };
        let customALignment = {
          horizontalAlignment: "center",
          verticalAlignment: "center",
        };
        sheet.usedRange().style({
          fontFamily: "Arial",
          verticalAlignment: "left",
        });

        sheet.row("7").height(60);
        for (let j = 8; j <= HedaerB; j++) {
          sheet.row(`${j}`).height(20);
        }

        for (let i = 66; i <= 74; i++) {
          let Char = String.fromCharCode(i);
          sheet.column(`${Char}`).width(10);
        }

        for (let i = 66; i <= 77; i++) {
          let Char = String.fromCharCode(i);
          sheet.range(`${Char}6`, `${Char}7`).merged(true).style({
            wrapText: true,
          });
        }
        sheet.range('A1:AF33').style({
          fontSize: 8,
          wrapText: true,
          fontFamily: 'Calibri'
        })
        sheet.row("5").style({
          bold: true,
          ...customALignment,
        });
        sheet.row("7").style({
          bold: true,
          ...customALignment,
        });
        sheet.row("6").style({
          bold: true,
          ...customALignment,
        });

        sheet.range("B5", "Y5").style({
          ...customBorder,
          leftBorder: false,
          rightBorder: false,
        });
        sheet.range("B7", "Y7").style({
          ...customBorder,
          leftBorder: false,
          rightBorder: false,
          topBorder: false,
          wrapText: true,
        });

        sheet.range("N6", "N7").style({
          ...customLeftBorder,
        });
        sheet.range("T6", "T7").style({
          ...customLeftBorder,
          ...customALignment,
        });
        sheet
          .range("N6", "S6")
          .merged(true)
          .style({
            ...customALignment,
          });
        sheet.range("B6", "B7").merged(true).style({
          wrapText: true,
        });
        sheet
          .range("T6", "V6")
          .merged(true)
          .style({
            ...customALignment,
          });
        sheet.range("N6", "V6").style({
          ...customBottomBorder,
          ...customALignment,
        });
        sheet.range("B5", `B${HedaerB}`).style({
          ...customLeftBorder,
        });
        sheet.range("X6", `Y${HedaerB}`).style({
          ...customLeftBorder,
        });
        sheet.range("W6", `W${HedaerB}`).style({
          ...customLeftBorder,
        });
        sheet.range("X5", `Y${HedaerB}`).style({
          ...customRightBorder,
          // wrapText: true,
        });
        sheet.range("V5", "V7").style({
          ...customRightBorder,
        });
        sheet.column("Y").width(20);
        sheet.range(`B${HedaerB}`, `Y${HedaerB}`).style({
          ...customTopBorder,
          ...customBottomBorder,
        });
      }

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };
  let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  return (
    <button
      onClick={() => {
        createDownLoadData();
      }}
      className="btn btn-primary float-end"
    >
      {userLang?.common?.DOWNLOAD_EXCEL || 'Download Excel'}
    </button>
  );
};

export default ExportButton;
