import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import LoadOptimizationReducer from './reducer';
const LoadOptimization = combineReducers({
    LoadOptimization: LoadOptimizationReducer,
  form: formReducer
});


export default LoadOptimization;
