let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: "State",
        field: "state",
        sort: "asc",
        width: 2,
      },
      {
        label: "Zone",
        field: "zone",
        sort: "asc",
        width: 3,
      },
      {
        label: "Cost/kg",
        field: "cost",
        sort: "asc",
        width: 3,
      },
      {
        label: "Warehouse",
        field: "businessUnit",
        sort: "asc",
        width: 2,
      },
      {
        label: "Courier",
        field: "courier",
        sort: "asc",
        width: 2,
      },
      {
        label: "Action",
        field: "actions",
        sort: "asc",
        width: 1,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var AdminHeaderData = {
  columns: [
    {
      label: "State",
      field: "state",
      sort: "asc",
      width: 2,
    },
    {
      label: "Zone",
      field: "zone",
      sort: "asc",
      width: 3,
    },
    {
      label: "Cost/kg",
      field: "cost",
      sort: "asc",
      width: 3,
    },
    {
      label: "Warehouse",
      field: "businessUnit",
      sort: "asc",
      width: 2,
    },
    {
      label: "Courier",
      field: "courier",
      sort: "asc",
      width: 2,
    },
    {
      label: "Action",
      field: "action",
      sort: "asc",
      width: 1,
    },
  ],
};
export var searchBar;
searchBar = {
};
