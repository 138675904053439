import React, { Component } from "react";
import { connect } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { Row, Col, Container } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { NotificationManager } from "react-notifications";
import OrderProductViewModel from "./OrderProductViewModel";
//actions
import { getOrderProduct } from "../../store/baxter-order/actions";

import Index from "../../components/Common/DataTableNeo/Index";
import {
  AdminHeaderData,
  getLocalStorage,
  searchBar,
} from "./RowHeaders/RowHeaders";
import QuickFilters from "./quckFilters";
import { Button } from "reactstrap";
import moment from "moment";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var limit = 10;
var searchValues = {};
var darkTheme;
let pathName;
let key;
let keyOffset;
class OrderProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataLoading: false,
      offset: 0,
      searchFilters: {},
    };
  }

  componentDidMount() {
    offset = 0;
    this.getAllData();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }
  getAllData = () => {
    pathName = window.location.pathname;
    key = `mySearchFilter_${pathName}`;
    keyOffset = `myOffsetFilter_${pathName}`;

    let storedData = localStorage.getItem(key)
      ? JSON.parse(window.localStorage.getItem(key))
      : "";
    searchValues = storedData || {};
    let offsetData = localStorage.getItem(keyOffset)
      ? localStorage.getItem(keyOffset)
      : window.localStorage.setItem(keyOffset, offset);
    this.setState({ dataLoading: true }, () => {});
    let searchFilters = this.state.searchFilters;

    this.props.getOrderProduct(
      offsetData ? offsetData : offset,
      limit,
      Object.keys(searchFilters).length > 0 ? searchFilters : storedData,
      (res) => {
        // console.log(res,"ios")
        this.setState({ dataLoading: false });
      }
    );
  };

  getAllDataReset = () => {
    offset = 0;
    window.localStorage.setItem(keyOffset, offset);
    this.getAllData();
  };
  handleChangePage = (event, val) => {
    // console.log(event, val, "handlepage");
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    window.localStorage.setItem(keyOffset, offset);
    this.setState({ loading: true });
    this.getAllData();
  };
  handleChange = (dateValues) => {
    // this.setState({ dataLoading: true });
    // console.log("date values", dateValues);
    this.setState(
      {
        FromDate:
          dateValues[0] !== null
            ? moment(dateValues[0]).format("MM-DD-YYYY")
            : null,
        ToDate:
          dateValues[1] !== null
            ? moment(dateValues[1]).format("MM-DD-YYYY")
            : null,
      },
      () => {}
    );
  };

  viewOrderProductData = (ele) => {
    this.props.history.push({
      pathname: "/viewOrderProductDetail",
      state: { currentProduct: ele },
    });
  };

  actions = (actionString) => {
    // console.log(actionString, "--- status");
    var darkMode = document.body.classList.contains("dark__mode");
    // console.log("darkMode", darkMode);
    return (
      <div className="dropdown ">
        <Button className="iconButtons dropbtn actionButtonsDark">
          <svg
            width="18"
            height="4"
            viewBox="0 0 18 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 2C10 1.44772 9.55228 1 9 1C8.44772 1 8 1.44772 8 2C8 2.55228 8.44772 3 9 3C9.55228 3 10 2.55228 10 2Z"
              stroke="#2800FC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3C2.55228 3 3 2.55228 3 2Z"
              stroke="#2800FC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 2C17 1.44772 16.5523 1 16 1C15.4477 1 15 1.44772 15 2C15 2.55228 15.4477 3 16 3C16.5523 3 17 2.55228 17 2Z"
              stroke="#2800FC"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
        <div className="dropdown-content contentDark">
          <div
            className="action-button actionDspan"
            onClick={() => {
              this.viewOrderProductData(actionString, "view");
            }}
          >
            View Details
          </div>
        </div>
      </div>
    );
  };
  searchValueAssignerFunction = (name, value, type) => {
    if (typeof value === "text" || type === "text") {
      searchValues[name] = value?.replace(/^\s+/, "");
      this.setState({ searchFilters: searchValues }, () => {});
    }
  };
  reset = () => {
    offset = 0;
    searchValues = {};
    this.setState({ searchFilters: {} }, () => {
      this.getAllData();
    });
  };
  render() {
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkTheme = mode === "dark__mode" ? true : false;

    const { getOrderProductsData } = this.props;
    const localStorageData = JSON.parse(
      localStorage.getItem("OrderProductsData")
    );
    const orderProdOffset = localStorage.getItem(keyOffset)
      ? JSON.parse(localStorage.getItem(keyOffset))
      : "";
    const orderProducts = navigator.onLine
      ? getOrderProductsData?.data?.docs
      : localStorageData?.data?.docs;
    let rows = [];
    if (Array.isArray(orderProducts)) {
      // console.log("orderData : ", orderProducts);
      offset = orderProdOffset ? orderProdOffset : offset;
      totCnt = navigator.onLine
        ? getOrderProductsData?.data?.length
        : localStorageData?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = navigator.onLine
        ? getOrderProductsData?.data?.offset +
          getOrderProductsData?.data?.docs?.length
        : localStorageData?.data?.offset + localStorageData?.data?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      orderProducts.forEach((ele) => {
        rows.push({
          orderNumber: ele?.orders?.orderNumber
            ? ele?.orders?.orderNumber
            : "N/A",
          quantityOrdered: ele?.quantityOrdered ? ele?.quantityOrdered : "N/A",
          orderLineNo: ele?.orderLineNo ? ele?.orderLineNo : "N/A",
          orderType: ele?.orders?.orderType ? ele?.orders?.orderType : "N/A",
          description: ele?.description ? ele?.description : "N/A",
          action: this.actions(ele),
        });
      });
    }
    return (
      <>
        {getLocalStorage()}
        <QuickFilters
          openModal={this.openModal}
          reset={this.reset}
          handleChange={this.handleChange}
        />
        <Container
          aria-hidden="true"
          fluid
          className={`${
            darkTheme ? "darkBackgroundList mobilepPadding" : "mobilepPadding"
          }`}
        >
          <div className="orderList">
            <Index
              {...this.props}
              headers={AdminHeaderData}
              data={rows}
              searchable={true}
              searchBar={searchBar}
              fetchId={this.props.fetchId}
              reset={this.reset}
              searchValueAssignerFunction={this.searchValueAssignerFunction}
              getAllData={this.getAllDataReset}
              dataLength={90}
              rowHeight={"56px"}
              dataLoading={this.state.dataLoading}
              classList="3"
              textInput="inputIndex"
              resetCol="9"
            />
          </div>

          {totCnt !== 0 && !this.state.dataLoading ? (
            <Row
              xs={12}
              style={{ color: darkTheme ? "#9d9d9d" : "#0C0C0C" }}
              className="mb-2 paginationRow"
            >
              <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                <div className="float-left">
                  <Pagination
                    className="desktop-pagination"
                    size="medium"
                    shape="rounded"
                    page={offset / 10 + 1}
                    count={totPages}
                    color="primary"
                    onChange={this.handleChangePage}
                    defaultPage={1}
                    siblingCount={1}
                  />
                  <Pagination
                    className="mobile-pagination"
                    size="small"
                    shape="rounded"
                    page={offset / 10 + 1}
                    count={totPages}
                    color="primary"
                    onChange={this.handleChangePage}
                    defaultPage={1}
                    siblingCount={1}
                  />
                </div>
              </Col>
              <Col
                className="mt-2 spanPagenationentries span-col-pagenation"
                xs={5}
                sm={7}
                md={8}
              >
                {" "}
                <span>
                  Showing {fromVal} to {toVal} of {totCnt} entries
                </span>
              </Col>
            </Row>
          ) : null}
        </Container>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  // console.log("state", state);
  return {
    ...state,
    getOrderProductsData: state?.orderListReducer?.getProductOrderListData,
    fetchId: state.orderListReducer?.fetchId,
  };
};

export default connect(mapStateToProps, {
  getOrderProduct,
})(OrderProduct);
