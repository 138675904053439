export const types = {
  DIAPATCH_LEAD_TIME_REPORT: "DIAPATCH_LEAD_TIME_REPORT",
  DIAPATCH_LEAD_TIME_REPORT_SUCCESS: "DIAPATCH_LEAD_TIME_REPORT_SUCCESS",
  DIAPATCH_LEAD_TIME_REPORT_EXCEL: "DIAPATCH_LEAD_TIME_REPORT_EXCEL",
  DIAPATCH_LEAD_TIME_REPORT_EXCEL_SUCCESS: "DIAPATCH_LEAD_TIME_REPORT_EXCEL_SUCCESS",
  BIFURCATION_REPORT: "BIFURCATION_REPORT",
  BIFURCATION_REPORT_SUCCESS: "BIFURCATION_REPORT_SUCCESS",
  BIFURCATION_REPORT_EXCEL: "BIFURCATION_REPORT_EXCEL",
  BIFURCATION_REPORT_EXCEL_SUCCESS: "BIFURCATION_REPORT_EXCEL_SUCCESS",
  TRUCKUTILIZATION_REPORT: "TRUCKUTILIZATION_REPORT",
  TRUCKUTILIZATION_REPORT_SUCCESS: "TRUCKUTILIZATION_REPORT_SUCCESS",
  TRUCKUTILIZATION_REPORT_EXCEL: "TRUCKUTILIZATION_REPORT_EXCEL",
  TRUCKUTILIZATION_REPORT_EXCEL_SUCCESS: "TRUCKUTILIZATION_REPORT_EXCEL_SUCCESS",
};
