import React, { Component } from "react";

import {
  Map,
  InfoWindow,
  Polyline,
  Marker,
  GoogleApiWrapper,
} from "google-maps-react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
//Import Breadcrumb
import startIcon from "../../assets/images/warehouse.png";
import dealerIcon from "../../assets/images/yellow.png";
import driverIcon from "../../assets/images/truck.png";
// import destinationIcon from "../../assets/images/blue.png";
const LoadingContainer = (props) => <div>Loading...</div>;

class TrackTripMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      coords: [
        { lat: 37.778519, lng: -122.40564 },
        { lat: 37.77739731, lng: -122.40412116 },
        { lat: 37.7755656, lng: -122.40639567 },
        { lat: 37.77383561, lng: -122.4085629 },
        { lat: 37.77383561, lng: -122.4085629 },
        { lat: 37.77383561, lng: -122.4085629 },
        { lat: 37.77478541, lng: -122.41925955 },
      ],
    };
  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  onClose = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  activateStreetView = (position) => {
    const mapObj = this.mapRef.map.getStreetView();
    mapObj.setPov({ heading: 34, pitch: 10 });
    mapObj.setPosition(position);
    mapObj.setVisible(true);
  };

  render() {
    const { trackPoints, driverLocations } = this.props;
    // console.log(trackPoints);
    const { latitude, longitude } = false || {
      latitude:
        trackPoints && trackPoints.startingLocation
          ? trackPoints.startingLocation.latitude
          : 12.98114043,
      longitude:
        trackPoints && trackPoints.startingLocation
          ? trackPoints.startingLocation.longitude
          : 77.59128571,
    }; //initial map render if driver not started
    return (
      <React.Fragment>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {/* <CardTitle>Markers</CardTitle>
                  <CardSubtitle className="mb-3">
                    Example of google maps.
                  </CardSubtitle> */}
                  <div
                    id="gmaps-markers"
                    className="gmaps"
                    style={{ position: "relative" }}
                  >
                    <Map
                      google={this.props.google}
                      initialCenter={{
                        lat: latitude,
                        lng: longitude,
                      }}
                      style={{ width: "100%", height: "100%" }}
                      zoom={8}
                    >
                      <Polyline
                        path={driverLocations || []}
                        strokeColor="red"
                        strokeOpacity={0.8}
                        strokeWeight={8}
                      />
                      {trackPoints && trackPoints.startingLocation && (
                        <Marker
                          onClick={this.onMarkerClick}
                          cursor={"pointer"}
                          title={`${trackPoints.startingLocation.name}`}
                          position={{
                            lat: trackPoints.startingLocation.latitude,
                            lng: trackPoints.startingLocation.longitude,
                          }}
                          name={`Starting location: ${trackPoints.startingLocation.name}`}
                          icon={{
                            url: startIcon,
                            anchor: new window.google.maps.Point(32, 32),
                            scaledSize: new window.google.maps.Size(32, 32),
                          }}
                        />
                      )}

                      {trackPoints &&
                        trackPoints.dealerAddress &&
                        trackPoints.dealerAddress.map((dealer) =>
                          trackPoints.tripType ===
                          "Last Mile" ? null : dealer.latitude &&
                            dealer.longitude ? (
                            <Marker
                              key={dealer.dealerId}
                              onClick={this.onMarkerClick}
                              cursor={"pointer"}
                              title={`${dealer.address}`}
                              position={{
                                lat: dealer.latitude,
                                lng: dealer.longitude,
                              }}
                              name={`Dealer: ${dealer.address}`}
                              icon={{
                                url: dealerIcon,
                                anchor: new window.google.maps.Point(32, 32),
                                scaledSize: new window.google.maps.Size(37, 37),
                              }}
                            />
                          ) : null
                        )}

                      {trackPoints.tripType === "Last Mile"
                        ? trackPoints.dealerAddress.map((dealer) =>
                            dealer.latitude && dealer.longitude ? (
                              <Marker
                                key={dealer.dealerId}
                                onClick={this.onMarkerClick}
                                cursor={"pointer"}
                                title={`${dealer.address}`}
                                position={{
                                  lat: dealer.latitude,
                                  lng: dealer.longitude,
                                }}
                                name={`Dealer: ${dealer.address}`}
                                icon={{
                                  url: dealerIcon,
                                  anchor: new window.google.maps.Point(32, 32),
                                  scaledSize: new window.google.maps.Size(
                                    37,
                                    37
                                  ),
                                }}
                              />
                            ) : null
                          )
                        : trackPoints &&
                          trackPoints.destination && (
                            <Marker
                              onClick={this.onMarkerClick}
                              title={`${trackPoints.destination.name}`}
                              name={`Destination: ${trackPoints.destination.name}`}
                              position={{
                                lat: trackPoints.destination.latitude,
                                lng: trackPoints.destination.longitude,
                              }}
                              // icon={{
                              //   url: destinationIcon,
                              //   anchor: new window.google.maps.Point(32, 32),
                              //   scaledSize: new window.google.maps.Size(32, 32),
                              // }}
                            />
                          )}
                      {driverLocations.length !== 0 ? (
                        <Marker
                          onClick={this.onMarkerClick}
                          title={"Driver"}
                          name={"Driver location"}
                          position={{
                            lat: driverLocations[driverLocations.length - 1]
                              .lat,
                            lng: driverLocations[driverLocations.length - 1]
                              .lng,
                          }}
                          icon={{
                            url: driverIcon,
                            anchor: new window.google.maps.Point(32, 32),
                            scaledSize: new window.google.maps.Size(32, 32),
                          }}
                        />
                      ) : null}
                      <InfoWindow
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}
                        onClose={this.onClose}
                      >
                        <div>
                          <span>{this.state.selectedPlace.name}</span>
                        </div>
                      </InfoWindow>
                    </Map>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  LoadingContainer: LoadingContainer,
  v: "3",
})(TrackTripMap);
