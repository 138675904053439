import React, { Component } from 'react'
import TripStatusDountChart from "./TripStatusDountChart";
import { Card } from "reactstrap";
export default class TripStatusChildCard extends Component {
  render() {
      const trips = this.props?.trips
       var darkMode = this.props.mode;

    return (
     <>
     <Card
            className="ml-4 "
            style={{
              width: "93%",
              height: "300px",
              backgroundColor:darkMode?"#171722":"#F1F5F7",
            }}
          >
            <div
              className=" mb-1 mt-1 justify-content-center TripstatusCardChild darkdountchart"
            >
              <TripStatusDountChart 
              data={trips} 
              userLang={this.props?.userLang}
              tripData={this.props.tripData}
              />
            </div>
          </Card></>
    )
  }
}
