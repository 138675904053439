// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

import {
  deleteApi,
  getApi,
  patchApi,
  postApi,
} from "../../helpers/auth_helper";

function* workfetchVehicles({ offset, filter, callback }) {
  let filterString = filter?.q ? filter?.q : "";
  let transporterId = filter?.transporterId ? filter?.transporterId : "";

  let uriAdditionalParameters = filter?.transporterId
    ? "&transporterId=" + filter?.transporterId
    : "";
  uriAdditionalParameters += filter?.vehicleTypeId
    ? "&vehicleTypeId=" + filter.vehicleTypeId
    : "";
  uriAdditionalParameters += filter?.ownerName
    ? "&ownerName=" + filter?.ownerName
    : "";
  uriAdditionalParameters += filter?.regNo ? "&regNo=" + filter?.regNo : "";

  try {
    // console.log("called", offset, filter);
    // let offset=0;
    // Try to call the API
    const limit = filter?.noLimit ? 0 : process.env.REACT_APP_PERPAGE;
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/vehicle/vehicles?offset=` +
      Number(offset) +
      `&limit=` +
      limit +
      `&q=` +
      filterString +
      uriAdditionalParameters;
    //  (filter?.q || "");
    const response = yield call(getApi, uri);
    yield put({
      type: types.FETCH_VEHICLES_SUCCESS,
      payload: response,
    });
    callback(response);
    localStorage.setItem("vehiclesListData", JSON.stringify(response));
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch vehicle.", error);
  }
}

function* workfetchVehiclesExcel({ offset, filter, callback }) {
  let filterString = filter?.q ? filter?.q : "";
  let transporterId = filter?.transporterId ? filter?.transporterId : "";

  let uriAdditionalParameters = filter?.transporterId
    ? "&transporterId=" + filter?.transporterId
    : "";
  uriAdditionalParameters += filter?.vehicleTypeId
    ? "&vehicleTypeId=" + filter.vehicleTypeId
    : "";
  uriAdditionalParameters += filter?.ownerName
    ? "&ownerName=" + filter?.ownerName
    : "";
  uriAdditionalParameters += filter?.regNo ? "&regNo=" + filter?.regNo : "";

  try {
    // console.log("called", offset, filter);
    // let offset=0;
    // Try to call the API
    const limit = filter?.noLimit ? 0 : process.env.REACT_APP_PERPAGE;
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/vehicle/vehicles?offset=` +
      Number(offset) +
      `&limit=100000` +
      limit +
      `&q=` +
      filterString +
      uriAdditionalParameters;
    //  (filter?.q || "");
    const response = yield call(getApi, uri);
    yield put({
      type: types.FETCH_VEHICLES_SUCCESS_EXCEL,
      payload: response,
    });
    callback(response);
    // localStorage.setItem("vehiclesListData", JSON.stringify(response));
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch vehicle.", error);
  }
}

function* workfetchAllVehicles({ offset, callback }) {
  try {
    // let offset=0;
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/vehicle/vehicles?offset=` +
      Number(offset) +
      `&limit=noLimit`;
    //  (filter?.q || "");
    const response = yield call(getApi, uri);
    callback(response);
    // yield put({
    //   type: types.FETCH_VEHICLES_SUCCESS,
    //   payload: response,
    // });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch vehicle.", error);
  }
}
function* workfetchAvailVehicles({ callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/vehicle/filterVehicles?status=AVAILABLE`;
    const response = yield call(getApi, uri);
    //console.log('FETCH_VEHICLES',response);
    //   Dispatch the action to the reducers
    callback(response);
    yield put({
      type: types.FETCH_AVAIL_VEHICLES_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch vehicle.", error);
  }
}
function* workCreateVehicle({ values, callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/vehicle/create`;
    const response = yield call(postApi, uri, values);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.CREATE_VEHICLE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch vehicle.", error);
  }
}

function* workFetchVehicleById({ id, callback }) {
  try {
    //console.log('values', values);
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/vehicle/vehicle/${id}`;
    const response = yield call(getApi, uri);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.FETCH_VEHICLE_BYID_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch vehicle.", error);
  }
}

function* workEditVehicleById({ values, callback }) {
  try {
    //console.log('values', values);
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + `/vehicle/update/${values.Id}`;
    const response = yield call(patchApi, uri, values);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.EDIT_VEHICLE_BYID_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch vehicle.", error);
  }
}

function* workDeleteVehicle({ id, callback }) {
  try {
    // Try to call the API
    //console.log('inside',id);
    const uri = process.env.REACT_APP_SERVERURL + `/vehicle/delete/` + id;
    const response = yield call(deleteApi, uri);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.DELETE_VEHICLE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch vehicle.", error);
  }
}

function* workfetchVehicleType({ callback, source, destination, route }) {
  try {
    // Try to call the API
    let uri =
      process.env.REACT_APP_SERVERURL +
      `/vehicleType/vehicleTypesBySpecs?source=` +
      source +
      `&destination=` +
      destination;
    uri += route ? `&route=` + route : ``;
    const response = yield call(getApi, uri);
    //console.log('FETCH_VEHICLES',response);
    //   Dispatch the action to the reducers
    callback(response);
    yield put({
      type: types.FETCH_VEHICLES_TYPE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch vehicleType.", error);
  }
}

function* workfetchAllVehicleTypes({ offset, limit, filter, callback }) {
  try {
    let uri =
      process.env.REACT_APP_SERVERURL +
      `/vehicleType/vehicleTypes?offset=` +
      offset +
      `&limit=` +
      process.env.REACT_APP_PERPAGE;
    const response = yield call(getApi, uri);
    //console.log('FETCH_VEHICLES',response);
    //   Dispatch the action to the reducers
    callback(response);
    yield put({
      type: types.FETCH_ALL_VEHICLE_TYPES_SUCCESS,
      payload: response,
    });
  } catch (error) {}
}

/**
 * Watchers
 */

export function* watchFetchVehicles() {
  yield takeEvery(types.FETCH_VEHICLES, workfetchVehicles);
}
export function* watchFetchVehiclesExcel() {
  yield takeEvery(types.FETCH_VEHICLES_EXCEL, workfetchVehiclesExcel);
}
export function* watchFetchAllVehicles() {
  yield takeEvery(types.FETCH_ALL_VEHICLES, workfetchAllVehicles);
}
export function* watchFetchAvailVehicles() {
  yield takeEvery(types.FETCH_AVAIL_VEHICLES, workfetchAvailVehicles);
}
export function* watchCreateVehicle() {
  yield takeEvery(types.CREATE_VEHICLE, workCreateVehicle);
}
export function* watchFetchVehicleById() {
  yield takeEvery(types.FETCH_VEHICLE_BYID, workFetchVehicleById);
}
export function* watchEditVehicleById() {
  yield takeEvery(types.EDIT_VEHICLE_BYID, workEditVehicleById);
}
export function* watchDeleteVehicle() {
  yield takeEvery(types.DELETE_VEHICLE, workDeleteVehicle);
}
export function* watchfetchVehicleType() {
  yield takeEvery(types.FETCH_VEHICLES_TYPE, workfetchVehicleType);
}
export function* watchAllVehicleTypes() {
  yield takeEvery(types.FETCH_ALL_VEHICLE_TYPES, workfetchAllVehicleTypes);
}
function* VehicleSaga() {
  yield all([
    fork(watchFetchVehicles),
    fork(watchFetchVehiclesExcel),
    fork(watchFetchAllVehicles),
    fork(watchFetchAvailVehicles),
    fork(watchCreateVehicle),
    fork(watchDeleteVehicle),
    fork(watchEditVehicleById),
    fork(watchFetchVehicleById),
    fork(watchfetchVehicleType),
    fork(watchAllVehicleTypes),
  ]);
}

export default VehicleSaga;
