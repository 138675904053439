import React, { Component } from "react";
import { WaveLoader } from "../../../components/Common/Loader/LoaderIndex";
import { Card, CardImg, Container, Row, Col, Button, Modal, ModalHeader, ModalBody, FormGroup, Label } from "reactstrap";
import { connect } from "react-redux";
import { tripHistory } from "../../../store/baxter-trip/actions";
import Index from "../../../components/Common/DataTableNeo/Index";
import { StatusIndicator, SubStatusIndicator } from "../StatusIndicator";
import { AdminHeaderData, getLocalStorage } from "./RowHeaders/RowHeaders";
import maskGroup from ".././assets/MaskGroup31.png";
import roadMap from ".././assets/RoadMap.png";

import moment from "moment";
import SingleValueOrArraysValue from "../../../components/Common/SingleValueOrArraysValue";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
class TripHistory extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      tripTransitionLogs: [],
      plannedIndex: "",
      arrivedAtSourceIndex: "",
      qualityCheckCompletedIndex: "",
      loadingInProgressIndex: "",
      inTransitIndex: "",
      completedIndex: "",

      showTripDetailModel: false,
      showShipDetailModel: false,
      showTripHistoryModel: false,
      tripDetailId: " ",
      lrStatus: " ",
      driverName: " ",
      transporterName: " ",
      tripIdHistory: " ",
      tripData:"",
      tripCompletedDate:'',
      isOpen: false,
      completeToggle: false,
      tripCompletionDetails: {},
    };
  }
  componentDidMount = () => {
    // console.log(this.props.history.location.state?.tripDetails?._id, "id");
    let id = this.props.history.location.state?.tripDetails?._id;
    this.props.tripHistory(id, (res) => {
      let ress = res?.data?.docs[0]
      // console.log("RESPONSE", ress.triptransitionlogs);
      this.setState(
        {
          tripTransitionLogs: ress?.triptransitionlogs,
          loading: false,
          tripData:  res && ress,
          tripCompletedDate: ress?.tripCompletionDetails?.tripCompletedDate,
          tripCompletionDetails: ress?.tripCompletionDetails
        },
        () => {
          // console.log("LOGS - TRIP TRANSITION", this.state.tripTransitionLogs);
          this.organizer();
        }
      );
    });
  };
  tripDetailToggleModel = async (id) => {
    this.setState({ showTripDetailModel: !this.state.showTripDetailModel });
    this.setState({ tripDetailId: id });
  };
  organizer = () => {
    this.state.tripTransitionLogs.forEach((ele, index) => {
      if (ele.subStatus === "planned") {
        this.setState({ plannedIndex: index });
      } else if (ele.subStatus === "arrived_at_source") {
        this.setState({ arrivedAtSourceIndex: index });
      } else if (ele.subStatus === "quality_check_completed") {
        this.setState({ qualityCheckCompletedIndex: index });
      } else if (ele.subStatus === "loading_in_progress") {
        this.setState({ loadingInProgressIndex: index });
      } else if (ele.subStatus === "in_transit") {
        this.setState({ inTransitIndex: index });
      } else if (ele.subStatus === "completed") {
        this.setState({ completedIndex: index });
      }
    });
  };
  tripHistoryToggleModel = (id ,data)=>{
    this.props.history.push({pathname: "/trackOrders",
    state: id,
  });
  }
  tripQualityDetailToggleModel = (data )=>{
    this.props.history.push({pathname: "/qualityCheckDetail",
    state: {logs:data},
  });
  }
  viewCompleteDetails =()=>{
  
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme");
    var darkState = mode === "dark__mode" ? true : false;

    let tripDetailArray = [];
    let tripDetails = this.props.history.location.state?.tripDetails;
    tripDetailArray.push(tripDetails);
    let data = [];
    tripDetailArray.forEach((ele) => {
      data.push({
        id: ele?._id,
        tripId: ele?.tripId ? ele?.tripId : "N/A",
        orderNumber: Array.isArray(ele?.order) 
        ? ele?.order[0]?.orderNumber
        : ele?.order?.orderNumber 
        ? ele?.order?.orderNumber 
        : "N/A",
        driver: ele?.driver?.name ? ele?.driver?.name : "N/A",
        source: Array.isArray(ele?.source) 
        ? `${ele?.source[0]?.state}(${ele?.source[0]?.city})`
        : ele?.source?.state
        ? ele?.source?.state
        : "N/A",
        eWayBillNo: ele?.eWayBillNo?.length ? (
          <>
            <SingleValueOrArraysValue
              ele={ele?.eWayBillNo}
              name='EWaybill'
              maxWidth="90px"
            />
          </>
        ) : "N/A",
        destination: Array.isArray(ele?.destination) 
        ? `${ele?.destination[0]?.shipToStateName}(${ele?.destination[0]?.shipToCity})`
        : ele?.destination
        ? `${ele?.destination?.shipToStateName}(${ele?.destination?.shipToCity})` 
        : "N/A",
        vehicle:
          (
            <>
              <p style={{ padding: "0px", margin: "0px" }}>
                {ele?.vehicle?.regNo}
              </p>
            </>
          ) || "N/A",
        transporter: Array.isArray(ele?.transporter) ? ele?.transporter[0] : ele?.transporter ? ele?.transporter : "N/A",
        description: ele?.description,
        substatus: ele?.subStatus ? <SubStatusIndicator subStatus={ele?.subStatus}/>:"N/A",
      });
    });
   
    return (
      <React.Fragment>
        {getLocalStorage()}
        <Container fluid style={{ margin: "8px" }} className="TripDetailsDiv">
          <Row>
            <Col xs={9} className="d-flex flex-row bd-highlight mb-2 ">
              <Button
                style={{
                  width: "60px",
                  height: "36px",
                  backgroundColor: "#3e3e3e",
                }}
                color="secondary"
                size="sm"
                onClick={() =>
                  this.props.history.push({
                    pathname: "/tripList",
                  })
                }
              >
                <i className="fas fa-arrow-circle-left fa-2x"></i>
              </Button>
            </Col>
          </Row>

          <Index
            {...this.props}
            headers={AdminHeaderData}
            data={data}
            searchable={false}
            rowHeight={"90px"}
          />
          <Card
            className="shadow mobileCardRoute"
            style={{
              justifyContent: "center",
              height: "640px",
              background: `url(${maskGroup})`,
            }}
          >
            {this.state.loading ? (
              <WaveLoader />
            ) : (
              <div className="row">
                <div
                  className="col-md-12"
                  style={{
                    position: "relative",
                  }}
                >
                  <div className="row topPosition d-flex align-items-center">
                    <div className="col-md-2"></div>
                    <div className="col-md-2 MobileArrived">
                      {this.state.tripTransitionLogs[
                        this.state.arrivedAtSourceIndex
                      ]?.subStatus === "arrived_at_source" ? (
                        <div
                          className={`square bottom frist bordering d-flex align-items-center ${
                            darkState ? "darkTripDetails" : ""
                          }`}
                        >
                          <div className="one">
                            <p
                              className={`m-0 paraHeadSource ${
                                darkState ? "light__span" : ""
                              }`}
                            >
                              {userLang?.dashboard?.ARRIVED_AT_SOURCE}
                            </p>
                            {/* <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.894 12.1299V10.8929C10.894 10.5681 10.83 10.2464 10.7057 9.94631C10.5813 9.64621 10.3991 9.37355 10.1693 9.14389C9.93958 8.91424 9.66684 8.7321 9.36668 8.60788C9.06653 8.48366 8.74484 8.41979 8.42 8.41992H3.473C2.81712 8.41992 2.1881 8.68047 1.72432 9.14425C1.26055 9.60802 1 10.237 1 10.8929V12.1299"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.89404 6C7.27476 6 8.39404 4.88071 8.39404 3.5C8.39404 2.11929 7.27476 1 5.89404 1C4.51333 1 3.39404 2.11929 3.39404 3.5C3.39404 4.88071 4.51333 6 5.89404 6Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {this.state.tripTransitionLogs[
                                  this.state.arrivedAtSourceIndex
                                ]?.updatedBy?.name
                                  ? this.state.tripTransitionLogs[
                                      this.state.arrivedAtSourceIndex
                                    ]?.updatedBy?.name
                                  : "N/A"}
                              </span>
                            </p> */}
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6 4.5V7.433L7.955 8.411"
                                  stroke="#2800FC"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {this.state.tripTransitionLogs[
                                  this.state.arrivedAtSourceIndex
                                ]?.updatedAt
                                  ? moment(
                                      this.state.tripTransitionLogs[
                                        this.state.arrivedAtSourceIndex
                                      ]?.updatedAt
                                    ).format("MM-DD-YYYY HH:mm:ss")
                                  : "N/A"}
                              </span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-2 MobileLoading">
                      {this.state.tripTransitionLogs[
                        this.state.loadingInProgressIndex
                      ]?.subStatus === "loading_in_progress" ? (
                        <div
                          className={`square bottom second bordering d-flex align-items-center ${
                            darkState ? "darkTripDetails" : ""
                          }`}
                        >
                          <div className="two">
                            <p
                              className={`m-0 paraHeadSource ${
                                darkState ? "light__span" : ""
                              }`}
                            >
                              {userLang?.dashboard?.LOADING_IN_PROGRESS}
                            </p>
                            {/* <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.894 12.1299V10.8929C10.894 10.5681 10.83 10.2464 10.7057 9.94631C10.5813 9.64621 10.3991 9.37355 10.1693 9.14389C9.93958 8.91424 9.66684 8.7321 9.36668 8.60788C9.06653 8.48366 8.74484 8.41979 8.42 8.41992H3.473C2.81712 8.41992 2.1881 8.68047 1.72432 9.14425C1.26055 9.60802 1 10.237 1 10.8929V12.1299"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.89404 6C7.27476 6 8.39404 4.88071 8.39404 3.5C8.39404 2.11929 7.27476 1 5.89404 1C4.51333 1 3.39404 2.11929 3.39404 3.5C3.39404 4.88071 4.51333 6 5.89404 6Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {this.state.tripTransitionLogs[
                                  this.state.loadingInProgressIndex
                                ]?.updatedBy?.name
                                  ? this.state.tripTransitionLogs[
                                      this.state.loadingInProgressIndex
                                    ]?.updatedBy?.name
                                  : "N/A"}
                              </span>
                            </p> */}
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6 4.5V7.433L7.955 8.411"
                                  stroke="#2800FC"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {this.state.tripTransitionLogs[
                                  this.state.loadingInProgressIndex
                                ]?.updatedAt
                                  ? moment(
                                      this.state.tripTransitionLogs[
                                        this.state.loadingInProgressIndex
                                      ]?.updatedAt
                                    ).format("MM-DD-YYYY HH:mm:ss")
                                  : "N/A"}
                              </span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-2 MobileIntransist">
                      {this.state.tripTransitionLogs[this.state.inTransitIndex]
                        ?.subStatus === "in_transit" ? (
                        <div
                          className={`square bottom third bordering d-flex align-items-center ${
                            darkState ? "darkTripDetails" : ""
                          }`}
                        >
                          <div className="thr">
                            <div className="d-flex align-items-center justify-content-between">
                              <p
                                className={`m-0 paraHeadSource ${
                                  darkState ? "light__span" : ""
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.tripHistoryToggleModel(
                                    data[0]?.id,
                                    data
                                    // data[0]?.transporter,
                                    // data[0]?.driver,
                                    // data[0]?.tripId
                                  );
                                }}
                              >
                                {userLang?.dashboard?.IN_TRANSIT}
                              </p>
                              <svg
                                width="26"
                                height="16"
                                viewBox="0 0 26 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.tripHistoryToggleModel(
                                    data[0]?.id,
                                    data[0]?.transporter,
                                    data[0]?.driver,
                                    data[0]?.tripId
                                  );
                                }}
                              >
                                <path
                                  d="M22 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H22C24.2091 16 26 14.2091 26 12V4C26 1.79086 24.2091 0 22 0Z"
                                  fill="#05284E"
                                />
                                <path
                                  d="M22 0.25H4C1.92893 0.25 0.25 1.92893 0.25 4V12C0.25 14.0711 1.92893 15.75 4 15.75H22C24.0711 15.75 25.75 14.0711 25.75 12V4C25.75 1.92893 24.0711 0.25 22 0.25Z"
                                  stroke="#2800FC"
                                  strokeWidth="0.5"
                                />
                                <path
                                  d="M14 8C14 7.44772 13.5523 7 13 7C12.4477 7 12 7.44772 12 8C12 8.55228 12.4477 9 13 9C13.5523 9 14 8.55228 14 8Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7 8C7 7.44772 6.55228 7 6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9C6.55228 9 7 8.55228 7 8Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21 8C21 7.44772 20.5523 7 20 7C19.4477 7 19 7.44772 19 8C19 8.55228 19.4477 9 20 9C20.5523 9 21 8.55228 21 8Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            {/* <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.894 12.1299V10.8929C10.894 10.5681 10.83 10.2464 10.7057 9.94631C10.5813 9.64621 10.3991 9.37355 10.1693 9.14389C9.93958 8.91424 9.66684 8.7321 9.36668 8.60788C9.06653 8.48366 8.74484 8.41979 8.42 8.41992H3.473C2.81712 8.41992 2.1881 8.68047 1.72432 9.14425C1.26055 9.60802 1 10.237 1 10.8929V12.1299"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.89404 6C7.27476 6 8.39404 4.88071 8.39404 3.5C8.39404 2.11929 7.27476 1 5.89404 1C4.51333 1 3.39404 2.11929 3.39404 3.5C3.39404 4.88071 4.51333 6 5.89404 6Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {" "}
                                {this.state.tripTransitionLogs[
                                  this.state.inTransitIndex
                                ]?.updatedBy?.name
                                  ? this.state.tripTransitionLogs[
                                      this.state.loadingCompletedIndex
                                    ]?.updatedBy?.name
                                  : "N/A"}
                              </span>
                            </p> */}
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6 4.5V7.433L7.955 8.411"
                                  stroke="#2800FC"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {" "}
                                {this.state.tripTransitionLogs[
                                  this.state.inTransitIndex
                                ]?.updatedAt
                                  ? moment(
                                      this.state.tripTransitionLogs[
                                        this.state.inTransitIndex
                                      ]?.updatedAt
                                    ).format("MM-DD-YYYY HH:mm:ss")
                                  : "N/A"}
                              </span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-2 MobileCompletedCard">
                      {this.state.tripTransitionLogs[this.state.completedIndex]
                        ?.subStatus === "completed" ? (
                        <div
                          className={`square bottom fourth bordering d-flex align-items-center ${
                            darkState ? "darkTripDetails" : ""
                          }`}
                        >
                          <div className="four">
                            <div className="d-flex align-items-center justify-content-between">
                              <p
                                className={`m-0 paraHeadSource ${
                                  darkState ? "light__span" : ""
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.viewCompleteDetails();
                                }}
                              >
                                {userLang?.dashboard?.COMPLETED}
                              </p>
                              <svg
                                width="26"
                                height="16"
                                viewBox="0 0 26 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.viewCompleteDetails();
                                }}
                              >
                                <path
                                  d="M22 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H22C24.2091 16 26 14.2091 26 12V4C26 1.79086 24.2091 0 22 0Z"
                                  fill="#05284E"
                                />
                                <path
                                  d="M22 0.25H4C1.92893 0.25 0.25 1.92893 0.25 4V12C0.25 14.0711 1.92893 15.75 4 15.75H22C24.0711 15.75 25.75 14.0711 25.75 12V4C25.75 1.92893 24.0711 0.25 22 0.25Z"
                                  stroke="#2800FC"
                                  strokeWidth="0.5"
                                />
                                <path
                                  d="M14 8C14 7.44772 13.5523 7 13 7C12.4477 7 12 7.44772 12 8C12 8.55228 12.4477 9 13 9C13.5523 9 14 8.55228 14 8Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7 8C7 7.44772 6.55228 7 6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9C6.55228 9 7 8.55228 7 8Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21 8C21 7.44772 20.5523 7 20 7C19.4477 7 19 7.44772 19 8C19 8.55228 19.4477 9 20 9C20.5523 9 21 8.55228 21 8Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            {/* <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.894 12.1299V10.8929C10.894 10.5681 10.83 10.2464 10.7057 9.94631C10.5813 9.64621 10.3991 9.37355 10.1693 9.14389C9.93958 8.91424 9.66684 8.7321 9.36668 8.60788C9.06653 8.48366 8.74484 8.41979 8.42 8.41992H3.473C2.81712 8.41992 2.1881 8.68047 1.72432 9.14425C1.26055 9.60802 1 10.237 1 10.8929V12.1299"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.89404 6C7.27476 6 8.39404 4.88071 8.39404 3.5C8.39404 2.11929 7.27476 1 5.89404 1C4.51333 1 3.39404 2.11929 3.39404 3.5C3.39404 4.88071 4.51333 6 5.89404 6Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {this.state.tripTransitionLogs[
                                  this.state.completedIndex
                                ]?.updatedBy?.name
                                  ? this.state.tripTransitionLogs[
                                      this.state.completedIndex
                                    ]?.updatedBy?.name
                                  : "N/A"}
                              </span>
                            </p> */}
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6 4.5V7.433L7.955 8.411"
                                  stroke="#2800FC"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {" "}
                                {this.state.tripTransitionLogs[
                                  this.state.completedIndex
                                ]?.updatedAt
                                  ? moment(
                                      this.state.tripTransitionLogs[
                                        this.state.completedIndex
                                      ]?.updatedAt
                                    ).format("MM-DD-YYYY HH:mm:ss")
                                  : "N/A"}
                              </span>
                            </p>
                            {
                              !(/dnx|safe|blue/i).test(this.state.tripData?.transporter) ? 
                              <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6 4.5V7.433L7.955 8.411"
                                  stroke="#2800FC"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {" "}
                                {this.state.tripCompletedDate
                                  ? moment(
                                      this.state.tripCompletedDate
                                    ).format("MM-DD-YYYY HH:mm:ss")
                                  : "N/A"}
                              </span>
                            </p> 
                            : ''
                            }
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="img">
              <img src="https://www.cloudesign.com/wp-content/uploads/2022/03/Road-image.png" />
            </div> */}
                <div className="roadMobileImg">
                  <CardImg src={roadMap} className="CardMobileImage"></CardImg>
                  {/* <div>
                <Card
                  style={{ backgroundColor: "black", height: "400px" }}
                ></Card>
              </div> */}
                </div>
                <div
                  className="col-md-12"
                  style={{
                    position: "relative",
                  }}
                >
                  <div className="row bottomPosition">
                    <div className="col-md-2 MobilePlannedCard">
                      {true ? (
                        <div
                          className={`square top fifth bordering d-flex align-items-center ${
                            darkState ? "darkTripDetails" : ""
                          }`}
                        >
                          <div className="five">
                            <p
                              className={`m-0 paraHeadSource ${
                                darkState ? "light__span" : ""
                              }`}
                            >
                              {userLang?.dashboard?.PLANNED}
                            </p>
                            {/* <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.894 12.1299V10.8929C10.894 10.5681 10.83 10.2464 10.7057 9.94631C10.5813 9.64621 10.3991 9.37355 10.1693 9.14389C9.93958 8.91424 9.66684 8.7321 9.36668 8.60788C9.06653 8.48366 8.74484 8.41979 8.42 8.41992H3.473C2.81712 8.41992 2.1881 8.68047 1.72432 9.14425C1.26055 9.60802 1 10.237 1 10.8929V12.1299"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.89404 6C7.27476 6 8.39404 4.88071 8.39404 3.5C8.39404 2.11929 7.27476 1 5.89404 1C4.51333 1 3.39404 2.11929 3.39404 3.5C3.39404 4.88071 4.51333 6 5.89404 6Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {tripDetails?.createdBy
                                  ? tripDetails?.createdBy
                                  : "N/A"}
                              </span>
                            </p> */}
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6 4.5V7.433L7.955 8.411"
                                  stroke="#2800FC"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {tripDetails?.createdAt
                                  ? moment(tripDetails?.createdAt).format(
                                      "MM-DD-YYYY HH:mm:ss"
                                    )
                                  : "N/A"}
                              </span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-2 MobilbeQualityCheckCard ">
                      {this.state.tripTransitionLogs[
                        this.state.qualityCheckCompletedIndex
                      ]?.subStatus === "quality_check_completed" ||
                      this.state.tripTransitionLogs[this.state.rejectedIndex]
                        ?.subStatus === "rejected" ? (
                        <div
                          className={`square top bordering sixth d-flex align-items-center ${
                            darkState ? "darkTripDetails" : ""
                          }`}
                        >
                          <div className="six">
                            <div className="d-flex align-items-center justify-content-between">
                              <p
                                className={`m-0 paraHeadSource ${
                                  darkState ? "light__span" : ""
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.tripQualityDetailToggleModel(this.state.tripData);
                                }}
                              >
                                {this.state.tripTransitionLogs[
                                  this.state.rejectedIndex
                                ] ? (
                                  <div
                                    // className="actionText"
                                    style={{ color: "#ed2045" }}
                                  >
                                    {
                                      userLang?.dashboard
                                        ?.QUALITY_CHECK_COMPLETED
                                    }
                                  </div>
                                ) : (
                                  userLang?.dashboard?.QUALITY_CHECK_COMPLETED
                                )}
                              </p>
                              <svg
                                width="26"
                                height="16"
                                viewBox="0 0 26 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  cursor: "pointer",
                                  width: "40px",
                                  marginTop: "-9px",
                                }}
                                onClick={() => {
                                  this.tripQualityDetailToggleModel(this.state.tripData);
                                }}
                              >
                                <path
                                  d="M22 0H4C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H22C24.2091 16 26 14.2091 26 12V4C26 1.79086 24.2091 0 22 0Z"
                                  fill="#05284E"
                                />
                                <path
                                  d="M22 0.25H4C1.92893 0.25 0.25 1.92893 0.25 4V12C0.25 14.0711 1.92893 15.75 4 15.75H22C24.0711 15.75 25.75 14.0711 25.75 12V4C25.75 1.92893 24.0711 0.25 22 0.25Z"
                                  stroke="#2800FC"
                                  strokeWidth="0.5"
                                />
                                <path
                                  d="M14 8C14 7.44772 13.5523 7 13 7C12.4477 7 12 7.44772 12 8C12 8.55228 12.4477 9 13 9C13.5523 9 14 8.55228 14 8Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7 8C7 7.44772 6.55228 7 6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9C6.55228 9 7 8.55228 7 8Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21 8C21 7.44772 20.5523 7 20 7C19.4477 7 19 7.44772 19 8C19 8.55228 19.4477 9 20 9C20.5523 9 21 8.55228 21 8Z"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            {/* <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.894 12.1299V10.8929C10.894 10.5681 10.83 10.2464 10.7057 9.94631C10.5813 9.64621 10.3991 9.37355 10.1693 9.14389C9.93958 8.91424 9.66684 8.7321 9.36668 8.60788C9.06653 8.48366 8.74484 8.41979 8.42 8.41992H3.473C2.81712 8.41992 2.1881 8.68047 1.72432 9.14425C1.26055 9.60802 1 10.237 1 10.8929V12.1299"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5.89404 6C7.27476 6 8.39404 4.88071 8.39404 3.5C8.39404 2.11929 7.27476 1 5.89404 1C4.51333 1 3.39404 2.11929 3.39404 3.5C3.39404 4.88071 4.51333 6 5.89404 6Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {" "}
                                {this.state.tripTransitionLogs[
                                  this.state.qualityCheckCompletedIndex
                                ]?.updatedBy?.name
                                  ? this.state.tripTransitionLogs[
                                      this.state.qualityCheckCompletedIndex
                                    ]?.updatedBy?.name
                                  : this.state.tripTransitionLogs[
                                      this.state.rejectedIndex
                                    ]?.updatedBy?.name
                                  ? this.state.tripTransitionLogs[
                                      this.state.rejectedIndex
                                    ]?.updatedBy?.name
                                  : "N/A"}
                              </span>
                            </p> */}
                            <p className="m-0">
                              <svg
                                width="12"
                                height="13"
                                viewBox="0 0 12 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z"
                                  stroke="#2800FC"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6 4.5V7.433L7.955 8.411"
                                  stroke="#2800FC"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              &nbsp;&nbsp;
                              <span
                                className={`${darkState ? "dark__span" : ""}`}
                              >
                                {" "}
                                {this.state.tripTransitionLogs[
                                  this.state.qualityCheckCompletedIndex
                                ]?.updatedAt
                                  ? moment(
                                      this.state.tripTransitionLogs[
                                        this.state.qualityCheckCompletedIndex
                                      ]?.updatedAt
                                    ).format("MM-DD-YYYY HH:mm:ss")
                                  : this.state.tripTransitionLogs[
                                      this.state.rejectedIndex
                                    ]?.updatedAt
                                  ? moment(
                                      this.state.tripTransitionLogs[
                                        this.state.rejectedIndex
                                      ]?.updatedAt
                                    ).format("MM-DD-YYYY HH:mm:ss")
                                  : "N/A"}
                              </span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                   
                  
                    <div className="col-md-2"></div>
                  </div>
                </div>
              </div>
            )}
          </Card>
          <Modal
            isOpen={this.state.isOpen}
            toggle={this.viewCompleteDetails}
            size="lg"
            centered
            className="CreateTripModal"
            style={{ maxWidth: "48%" }}
          >
            <ModalHeader>
              <div className="Heading">
                <h4 className="fontRoboto">Completed Trip</h4>
                <svg
                  onClick={() => this.viewCompleteDetails()}
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.9092 1.82141L1.90918 13.8214"
                    stroke="#2800FC"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.90918 1.82141L13.9092 13.8214"
                    stroke="#2800FC"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </ModalHeader>

            <ModalBody>
              <Card className="p-1">
                <div>
                  <>
                  <Row className="mt-2">
                      <Col md="12">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto154 mb-2"
                          >
                            Date and Time : {moment(this.state.tripCompletionDetails?.tripCompletedDate).format("MM-DD-YYYY HH:mm:ss")}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md="12">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto154 mb-2"
                          >
                            Remark : {this.state.tripCompletionDetails?.remark}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col md="12">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto154 mb-2"
                          >
                            Proof : 
                          </Label>
                          <div><img className="img-fluid" src={this.state.tripCompletionDetails?.url} alt='Proof'/></div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                </div>
              </Card>
            </ModalBody>
          </Modal>
          
        </Container>
      </React.Fragment>
    );
  }
}
export default connect(null, {
  tripHistory,
})(TripHistory);
