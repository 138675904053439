import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  CREATE_USER,
  LOAD_USERS,
  LOAD_USERS_EXCEL,
  LOAD_FILTER_USERS,
  UPDATE_USER,
  USER_DETAILS,
  CREATE_USERS_VIA_EXCEL
} from "./actionTypes";

export const registerUser = (values, callback) => {
  return {
    type: REGISTER_USER,
    values,
    callback,
  };
};
export const createUser = (values, callback) => {
  return {
    type: CREATE_USER,
    values,
    callback,
  };
};

export const registerUserSuccessful = (user) => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  };
};

export const registerUserFailed = (error) => {
  return {
    type: REGISTER_USER_FAILED,
    payload: error,
  };
};

export const fetchUsers = (offset, filter, callback) => ({
  type: LOAD_USERS,
  offset,
  filter,
  callback,
});

export const fetchUsersExcel = (offset, filter, callback) => ({
  type: LOAD_USERS_EXCEL,
  offset,
  filter,
  callback,
});


export const fetchUsersfilters = (offset, filters, callback) => ({
  type: LOAD_FILTER_USERS,
  offset,
  filters,
  callback,
});
export const UpdateUser = (userId, data, callback) => ({
  type: UPDATE_USER,
  userId,
  data,
  callback,
});

export const fetchUserDetails = (userId, callback) => ({
  type: USER_DETAILS,
  userId,
  callback,
});
//Baxter
export const createUsersViaExcel = (payload, callback) =>{
  return {
      type:CREATE_USERS_VIA_EXCEL,
      payload,
      callback
  }
}