import { types } from "../actionTypes";
const initialState = {
  ShipmentMasterData: [],
  fetchId: "",
};
export default (state = { initialState }, action) => {
  switch (action.type) {
    case types.SHIPMENT_MASTER_SUCCESS:
      return {
        ...state,
        ShipmentMasterData: action.payload,
        fetchId: "id" + new Date().getTime(),
      };
    default:
      state = { ...state };
      break;
  }

  return state;
};
