import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Card } from "@material-ui/core";
import { HINDI } from "../../../language/language";
import { connect } from "react-redux";
const trackStatusStyle = { 
  height: '60px', 
  backgroundColor: '#f1f5f7', 
  width: '45%', 
  borderRadius: '6px', 
  margin: '2px' 
}
let userPreferredLang = localStorage.getItem("userPreferredLang");
class DashboardCard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    const windowSize = window.innerWidth
    if(windowSize <= 1500) this.props.nameStyle.fontSize = '17px'
    userPreferredLang = localStorage.getItem("userPreferredLang") || {};
    const { numberStyle, cardStyle, nameStyle } = this.props;
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    var darkMode = mode === "dark__mode" ? true : false;
    // let paddingIcon;
    // if (nameStyle === "Trips Delayed" || nameStyle === "Untracked Trips") {
    //   paddingIcon = "10px !important";
    // }
    return (
      <>
        {nameStyle === "Pending" || nameStyle === "रूका हुआ" ? (
          <Card
            className="shadow justify-content-center dashboard-card-div"
            style={cardStyle}
          >
            <Row
              className="invoice-overflow d-flex justify-content-between flex-nowrap  mt-1"
              style={{
                width: "100%",
                paddingLeft: "12px",
              }}
            >
              <Col
                xs="10"
                md="10"
                lg="9"
                className="pt-2 mt-1"
                style={{}}
              >
                <span
                  className="dashboard-name"
                  style={{ ...this.props.nameStyle }}
                >
                  {this.props.name}
                </span>
              </Col>
              <Col xs="2" md="2" lg="3" className="mt-3 dashboard-icon">
                <div
                  className={
                    this.props.name === "Pending" ? "pending-card" : ""
                  }
                >
                  <img 
                    className="dashboard-cursor-eye pl-2"
                    src={this.props.icon}
                    alt=""
                  ></img>
                </div>
              </Col>
            </Row>
            <Row
              style={{
                paddingTop: "10px",
                paddingLeft: "12px",
              }}
            >
              <Col
                xs="9"
                md="10"
                lg="9"
                className="ml-0 pl-0 dashboard-number-col"
              >
                <span
                  className="pl-2 ml-1 dashboard-number"
                  style={{
                    ...numberStyle,
                    cursor: "default",
                  }}
                >
                  {this.props.value ? this.props.value : "0"}
                </span>
              </Col>
              <Col
                xs="3"
                md="2"
                lg="3"
                className="mt-2 pt-2 dashboard-icon dashboard-icon-bottom"
              >
                <span
                  className={
                    this.props.name === "Pending" ? "pending-card" : ""
                  }
                  style={{
                    fontSize: "15px",
                    cursor: "pointer",
                    paddingLeft: "8px",
                  }}
                  onClick={() => this.props.cardClick(this.props.name)}
                >
                  <svg
                    className="dashboard-cursor-eye svgicon customDashboardIcon"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_2588_8)">
                      <path
                        d="M0.708496 8.49992C0.708496 8.49992 3.54183 2.83325 8.50016 2.83325C13.4585 2.83325 16.2918 8.49992 16.2918 8.49992C16.2918 8.49992 13.4585 14.1666 8.50016 14.1666C3.54183 14.1666 0.708496 8.49992 0.708496 8.49992Z"
                        stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.5 10.625C9.6736 10.625 10.625 9.6736 10.625 8.5C10.625 7.32639 9.6736 6.375 8.5 6.375C7.32639 6.375 6.375 7.32639 6.375 8.5C6.375 9.6736 7.32639 10.625 8.5 10.625Z"
                        stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2588_8">
                        <rect width="17" height="17" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </Col>
            </Row>
          </Card>
        ) : this.props.name !== "Untracked Trips" ? (
          <Card
            className="shadow justify-content-center dashboard-card-div"
            style={cardStyle}
          >
            <Row
              className="invoice-overflow d-flex justify-content-between flex-nowrap mt-1"
              style={{
                width: "100%",
                // paddingLeft: "12px",
              }}
            >
              <div
                // xs="9"
                // md="10"
                // lg="9"
                className="pl-3 pt-2 mt-1 "
                style={{}}
              >
                <span
                  className="dashboard-name"
                  style={{ ...this.props.nameStyle }}
                >
                {userPreferredLang === "HI" ? this.props.nameForHindiLan ?? this.props.name  : this.props.name}
                </span>
              </div>
              <div  className="pr-2 mt-2 dashboard-icon">
                <div
                  className={
                    this.props.name === "Pending" ? "pending-card" : ""
                  }
                >
                  {this.props.icon}
                  {/* <img className="" src={this.props.icon}></img> */}
                </div>
              </div>
            </Row>
            {this.props.name !== "Tracking Status" && this.props.name !=="In Transit"
              ? <Row
                className="invoice-overflow d-flex justify-content-between flex-nowrap"
                style={{
                  paddingTop: "10px",
                  paddingLeft: "12px",
                }}
              >
                <div
                  className="ml-0 dashboard-number-col"
                >
                  <span
                    className="pl-2 ml-1 dashboard-number"
                    style={{
                      ...numberStyle,
                      cursor: "default",
                    }}
                  >
                    {typeof this.props?.value === 'object'
                    ? this.props?.value?.value ?this.props?.value?.value:0
                    : this.props?.value? this.props?.value:0}

                  </span>
                </div>
                {(this.props.name && this.props.name !== "Punch To Dispatch" && this.props.name !== "Dispatch To Delivery") ? (
                <div
                  className="mt-2 pt-1  pr-3 dashboard-icon dashboard-icon-bottom"
                >
                  <span
                    className={
                      this.props.name === "Pending" ? "pending-card" : ""
                    }
                    style={{
                      fontSize: "15px",
                      cursor: "pointer",
                      paddingLeft: "8px",
                    }}
                    onClick={() => this.props.cardClick(this.props.name)}
                  >
                    {/* <img src={eyeIcon} className="dashboard-cursor-eye">
                </img> */}
                    <svg
                      className="dashboard-cursor-eye svgicon customDashboardIcon"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_2588_8)">
                        <path
                          d="M0.708496 8.49992C0.708496 8.49992 3.54183 2.83325 8.50016 2.83325C13.4585 2.83325 16.2918 8.49992 16.2918 8.49992C16.2918 8.49992 13.4585 14.1666 8.50016 14.1666C3.54183 14.1666 0.708496 8.49992 0.708496 8.49992Z"
                          stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.5 10.625C9.6736 10.625 10.625 9.6736 10.625 8.5C10.625 7.32639 9.6736 6.375 8.5 6.375C7.32639 6.375 6.375 7.32639 6.375 8.5C6.375 9.6736 7.32639 10.625 8.5 10.625Z"
                          stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2588_8">
                          <rect width="17" height="17" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </div>): ""}
              </Row>
              : <Row
                className="invoice-overflow d-flex justify-content-between flex-nowrap"
                style={{
                  // paddingTop: "10px",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                }}
              >
                <div style={trackStatusStyle} className="consentBackground">
                  <div className='pl-2 ml-1 consentText light__span'>{this.props.name === "Tracking Status"? userPreferredLang === "HI" ? HINDI?.baxterdDashboard?.TRACKING : "Tracking" : userPreferredLang === "HI" ? HINDI?.baxterdDashboard?.COURIER : "Courier"}</div>
                  <div className="d-flex justify-content-between flex-nowrap" >
                    <div
                      className="ml-0 dashboard-number-col"
                    >
                      <span
                        className="pl-2 ml-1 dashboard-number"
                        style={{
                          ...numberStyle,
                          cursor: "default",
                        }}
                      >
                        {this.props.name === "Tracking Status" ? (this.props.value?.trackedTrips ? this.props.value?.trackedTrips : "0"): (this.props.value?.courier ? this.props.value?.courier : "0")}
                      </span>
                    </div>
                    <div>
                       <span
                    className={
                      this.props.name === "Pending" ? "pending-card" : ""
                    }
                    style={{
                      fontSize: "15px",
                      cursor: "pointer",
                      paddingLeft: "8px",
                    }}
                    onClick={() => this.props.cardClick(this.props.name === "Tracking Status"? "Tracking" : "Courier")}
                  >
                    {/* <img src={eyeIcon} className="dashboard-cursor-eye">
                </img> */}
                    <svg
                      className="dashboard-cursor-eye svgicon customDashboardIcon"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_2588_8)">
                        <path
                          d="M0.708496 8.49992C0.708496 8.49992 3.54183 2.83325 8.50016 2.83325C13.4585 2.83325 16.2918 8.49992 16.2918 8.49992C16.2918 8.49992 13.4585 14.1666 8.50016 14.1666C3.54183 14.1666 0.708496 8.49992 0.708496 8.49992Z"
                          stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.5 10.625C9.6736 10.625 10.625 9.6736 10.625 8.5C10.625 7.32639 9.6736 6.375 8.5 6.375C7.32639 6.375 6.375 7.32639 6.375 8.5C6.375 9.6736 7.32639 10.625 8.5 10.625Z"
                          stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2588_8">
                          <rect width="17" height="17" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                    </div>
                  </div>
                  <div className="mt-2 pt-1 pr-3 dashboard-icon dashboard-icon-bottom">
                  <span
                    className={this.props.name === "Pending" ? "pending-card" : ""}
                    style={{
                      fontSize: "15px",
                      cursor: "pointer",
                      paddingLeft: "8px",
                    }}
                    onClick={() => this.props.cardClick(this.props.name === "Tracking Status"? "Tracking" : "Courier")}
                  >
                    <svg
                      className="dashboard-cursor-eye svgicon customDashboardIcon"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {/* Your eye icon SVG path data here */}
                    </svg>
                  </span>
                </div>
                </div>
                <div style={trackStatusStyle} className="consentBackground">
                  <div className='pl-1 ml-1 consentText light__span'>
                    {this.props.name === "Tracking Status"? userPreferredLang === "HI" ? HINDI?.baxterdDashboard?.NON_TRACKING : "Non Tracking" : userPreferredLang === "HI" ? HINDI?.baxterdDashboard?.TRUCK : "Truck"}
                    </div>
                  <div className="d-flex justify-content-between flex-nowrap" >
                    <div
                      className="ml-0 dashboard-number-col"
                    >
                      <span
                        className="pl-2 ml-1 dashboard-number"
                        style={{
                          ...numberStyle,
                          cursor: "default",
                        }}
                      >
                        {this.props.name === "Tracking Status" ? (this.props.value?.untrackedTrips ? this.props.value?.untrackedTrips : "0"): (this.props.value?.truck ? this.props.value?.truck : "0")}
                      </span>
                    </div>
                    <div>
                    <span
                    className={
                      this.props.name === "Pending" ? "pending-card" : ""
                    }
                    style={{
                      fontSize: "15px",
                      cursor: "pointer",
                      paddingLeft: "8px",
                    }}
                    onClick={() => this.props.cardClick(this.props.name === "Tracking Status"? "Non Tracking" : "Truck")}
                  >
                    {/* <img src={eyeIcon} className="dashboard-cursor-eye">
                </img> */}
                    <svg
                      className="dashboard-cursor-eye svgicon customDashboardIcon"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_2588_8)">
                        <path
                          d="M0.708496 8.49992C0.708496 8.49992 3.54183 2.83325 8.50016 2.83325C13.4585 2.83325 16.2918 8.49992 16.2918 8.49992C16.2918 8.49992 13.4585 14.1666 8.50016 14.1666C3.54183 14.1666 0.708496 8.49992 0.708496 8.49992Z"
                          stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.5 10.625C9.6736 10.625 10.625 9.6736 10.625 8.5C10.625 7.32639 9.6736 6.375 8.5 6.375C7.32639 6.375 6.375 7.32639 6.375 8.5C6.375 9.6736 7.32639 10.625 8.5 10.625Z"
                          stroke={darkMode ? "#B7A9FF" : "#2800FC"}
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2588_8">
                          <rect width="17" height="17" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                    </div>
                  </div>
                </div>
              </Row>}
            
          </Card>
        ) : (
        ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps, {})(DashboardCard);
