// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { types } from "./actionTypes";

import {
  deleteApi,
  getApi,
  patchApi,
  postApi,
} from "../../helpers/auth_helper";
import { getPolicies } from "../../pages/policies";

function* workfetchRoleGrpPolicies({ callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/role/getRGPFrmToken`;
    var res = yield call(getApi, uri);
    let policiesList = getPolicies(res);

    // return policiesList;
    callback(policiesList);
    yield put({
      type: types.FETCH_ROLE_GRP_POLICIES_SUCCESS,
      payload: policiesList,
    });
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch hop.", error);
  }
}

function* workfetchRoles({ offset, filter ,callback }) {
  
  let role = filter?.role ? filter?.role : "";
  let description = filter?.description ? filter?.description : "";
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      "/role/roles?offset=" +
      offset +
      "&limit=" +
      process.env.REACT_APP_PERPAGE +
      "&q=" +
       role +
       "&description=" +
       description;
    var res = yield call(getApi, uri);
    localStorage.setItem("rolesListData", JSON.stringify(res));
    // return policiesList;
    callback(res);
    yield put({
      type: types.FETCH_ROLES_SUCCESS,
      payload: res,
    });
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch hop.", error);
  }
}

function* workfetchRolesExcel({ offset, filter ,callback }) {
  
  let role = filter?.role ? filter?.role : "";
  let description = filter?.description ? filter?.description : "";
  console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<",filter)
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      "/role/roles?offset=" +
      offset +
      "&limit=100000" +
      process.env.REACT_APP_PERPAGE +
      "&q=" +
       role +
       "&description=" +
       description;
    var res = yield call(getApi, uri);
    // localStorage.setItem("rolesListData", JSON.stringify(res));
    // return policiesList;
    callback(res);
    yield put({
      type: types.FETCH_ROLES_SUCCESS_EXCEL,
      payload: res,
    });
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch hop.", error);
  }
}

function* workfetchRolesfilter({ offset, filters, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      "/role/getRoleGroup?offset=" +
      offset +
      `&filters=` +
      filters;
    var res = yield call(getApi, uri);

    // return policiesList;
    callback(res);
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch hop.", error);
  }
}
function* workfetchAcessPolicies({ callback }) {
  try {
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/role/getAccessPolicies`;
    var res = yield call(getApi, uri);
    // console.log("rrrrrrrrr", res);

    callback(res);
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch hop.", error);
  }
}

function* workCreateRolePolicy({ values, callback }) {
  try {
    //console.log('values', values);
    // Try to call the API
    const uri = process.env.REACT_APP_SERVERURL + `/role/addRoleGroup`;
    const response = yield call(postApi, uri, values);
    // console.log("response", response);
    callback(response);
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch hop.", error);
  }
}
function* workEditRole({ id, values, callback }) {
  try {
    //console.log('values', values);
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/role/editRoleGroupPolicies?roleGroupID=${id}`;
    const response = yield call(patchApi, uri, values);
    callback(response);
    // //   Dispatch the action to the reducers
    // yield put({
    //   type: types.EDIT_ROLE_SUCCESS,
    //   payload: response,
    // });
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch hop.", error);
  }
}
function* workFetchRoleById({ id, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/role/getPolicesForRole?groupRoleID=` +
      id;
    const response = yield call(getApi, uri);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    // yield put({
    //   type: types.FETCH_ROLE_BYID_SUCCESS,
    //   payload: response,
    // });
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch hop.", error);
  }
}
function* workDeleteRole({ id, callback }) {
  try {
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/role/deleteRoleGroup?roleGroupID=` +
      id;
    const response = yield call(deleteApi, uri);
    // console.log("response", response);
    callback(response);
    //   Dispatch the action to the reducers
    // yield put({
    //   type: types.DELETE_ROLE_SUCCESS,
    //   payload: response
    // });
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch hop.", error);
  }
}
/**
 * Watchers
 */

export function* watchFetchRoleGrpPolicies() {
  yield takeEvery(types.FETCH_ROLE_GRP_POLICIES, workfetchRoleGrpPolicies);
}
export function* watchFetchRoles() {
  yield takeEvery(types.FETCH_ROLES, workfetchRoles);
}
export function* watchFetchRolesExcel() {
  yield takeEvery(types.FETCH_ROLES_EXCEL, workfetchRolesExcel);
}
export function* watchFetchRolesFilter() {
  yield takeEvery(types.FETCH_FILTER_ROLES, workfetchRolesfilter);
}
export function* watchFetchDropPolicies() {
  yield takeEvery(types.FETCH_POLICIES_LIST, workfetchAcessPolicies);
}
export function* watchCreateAcessPolicy() {
  yield takeEvery(types.CREATE_ROLE_POLICY, workCreateRolePolicy);
}
export function* watchEditRole() {
  yield takeEvery(types.EDIT_ROLE, workEditRole);
}
export function* watchFetchRoleById() {
  yield takeEvery(types.FETCH_ROLEBYID, workFetchRoleById);
}
export function* watchDeleteRole() {
  yield takeEvery(types.DELETE_ROLE, workDeleteRole);
}
function* AcessPolicySaga() {
  yield all([
    fork(watchFetchRoles),
    fork(watchFetchRolesExcel),
    fork(watchFetchRolesFilter),
    fork(watchFetchDropPolicies),
    fork(watchFetchRoleGrpPolicies),
    fork(watchCreateAcessPolicy),
    fork(watchEditRole),
    fork(watchFetchRoleById),
    fork(watchDeleteRole),
  ]);
}

export default AcessPolicySaga;
