export const types = {
  GET_CUSTOMER_PRESIGNED_URL: "GET_CUSTOMER_PRESIGNED_URLL",
  GET_CUSTOMER_PRESIGNED_URL_SUCCESS: "GET_CUSTOMER_PRESIGNED_URL_SUCCESS",
  GET_LOCATION_PRESIGNED_URL: "GET_LOCATION_PRESIGNED_URL",
  GET_LOCATION_PRESIGNED_URL_SUCCESS: "GET_LOCATION_PRESIGNED_URL_SUCCESS",
  GET_PRODUCT_PRESIGNED_URL: "GET_PRODUCT_PRESIGNED_URL",
  GET_PRODUCT_PRESIGNED_URL_SUCCESS: "GET_PRODUCT_PRESIGNED_URL_SUCCESS",
  COMPLETE_TRIP_PRESISNED_URL: "COMPLETE_TRIP_PRESISNED_URL",
  COMPLETE_TRIP_PRESISNED_URL_SUCCESS: "COMPLETE_TRIP_PRESISNED_URL_SUCCESS",
  UPLOAD_IMAGE1: "UPLOAD_IMAGE1",
  UPLOAD_IMAGE_SUCCESS1: "UPLOAD_IMAGE_SUCCESS1",
  UESER_EXCEL_PRESIGNED_URL: "UESER_EXCEL_PRESIGNED_URL",
  UESER_EXCEL_PRESIGNED_URL_SUCCESS: "UESER_EXCEL_PRESIGNED_URL_SUCCESS",
  GET_LOGBOOK_PRESIGNED_URL: "GET_LOGBOOK_PRESIGNED_URL",
  GET_LOGBOOK_PRESIGNED_URL_SUCCESS: "GET_LOGBOOK_PRESIGNED_URL_SUCCESS",
  UPLOAD_IMAGE_CALLBACK: "UPLOAD_IMAGE_CALLBACK",
  UPLOAD_IMAGE_SUCCESS_CALLBACK: "UPLOAD_IMAGE_SUCCESS_CALLBACK",
};
