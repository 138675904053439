const useStyles = (darkStatus, controlProps = "") => ({
  plannedCardStyles: {
    height: "120px",
    backgroundColor: `${darkStatus ? "#24243C" : "#FFFFFF"}`,
    border: `${darkStatus ? "0.5px solid #616161" : "1px solid rgb(212,212, 212) "}`,
  },
  plannedNameStyles: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "19px",
    color: `${darkStatus ? "#9D9D9D" : "#05284E"}`,
    // top: "19px",
    // marginTop: "19px",
  },
  plannedIconStyles: {
    color: "rgba(225, 150, 0, 1)",
    fontSize: "30px",
  },
  plannedNumberStyle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "30px",
    color: `${darkStatus ? "#FFFFFF" : "#05284E"}`,
  },
  transitNumberStyle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    color: `${darkStatus ? "#FFFFFF" : "#05284E"}`,
  },
  plannedEye: {
    color: "#2800FC",
  },

  tripDelayedCardStyle: {
    fontFamily: "Roboto",
    height: "120px",
    backgroundColor: `${darkStatus ? "#24243C" : "#FFFFFF"}`,
    border: `${
      darkStatus
        ? "1px solid #E53D2C"
        : controlProps === "none"
        ? ""
        : "1px solid #E53D2C"
    }`,
    borderRadius: "5px",
  },
  tripDelayedNameStyle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "19px",
    color: `${darkStatus ? "#9D9D9D" : "#05284E"}`,
  },
  tripDelayedIconStyle: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: "30px",
  },
  tripDelayedNumberStyle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "30px",
    color: `${darkStatus ? "#FFFFFF" : "#05284E"}`,
  },
  tripEye: {
    color: "#E19600",
  },
  intranEye: {
    color: "#A020F0",
  },
  completeEye: {
    color: "#006600",
  },
  pendingEye: {
    color: "#05284E",
  },
  tripDelayEye: {
    color: "#FFFFFF",
  },
  inTransistCardStyle: {
    height: "120px",
    // width: "298px",
    // backgroundColor:"FFFFFF",
    backgroundColor: `${darkStatus ? "#24243C" : "#FFFFFF"}`,
    border: `${darkStatus ? "0.5px solid #616161" : " 1px solid rgb(212,212, 212) "}`,
  },
  inTransistNameStyle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "19px",
    color: `${darkStatus ? "#9D9D9D" : "#05284E"}`,
    top: "19px",
    // marginTop: "19px",
  },
  inTransistIconStyle: {
    // marginLeft: "150px",
    // marginTop: "10px",
    paddingTop: "100px",
    zIndex: 100,
    color: "rgba(160, 32, 240, 1)",
    fontSize: "30px",
  },
  inTransistNumberStyle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    color: `${darkStatus ? "#FFFFFF" : "#05284E"}`,
  },
  completedCardStyle: {
    height: "120px",
    // width: "299px",
    backgroundColor: `${darkStatus ? "#24243C" : "#FFFFFF"}`,
    border: `${darkStatus ? "0.5px solid #616161" : " 1px solid rgb(212,212, 212)"}`,
  },
  completedNameStyle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "19px",
    color: `${darkStatus ? "#9D9D9D" : "#05284E"}`,
  },
  completedIconStyle: {
    color: "rgba(0, 100, 0, 1)",
    fontSize: "30px",
  },
  completedNummberStyle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "30px",
    color: `${darkStatus ? "#FFFFFF" : "#05284E"}`,
  },
  untrackedtripsCardStyle: {
    height: "120px",
    backgroundColor: `${darkStatus ? "#24243C" : "#FFFFFF"}`,
    border: `${darkStatus ? "1px solid #E53D2C" : "1px solid #AC1000"}`,
    borderRadius: "5px",
  },
  untrackedtripsNameStyle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "19px",
    color: `${darkStatus ? "#9D9D9D" : "#05284E"}`,
    // top: "19px",
    // marginTop: "19px",
  },
  untrackedtripsIconStyle: {
    // marginLeft: "10px",
    // marginTop: "15px",
    // paddingTop:"15px !important",
    color: "rgba(255, 255, 255, 1)",
    fontSize: "30px",
  },
  untrackedtripsNumberStyle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",

    // marginBottom:"10px",
    fontSize: "30px",
    color: `${darkStatus ? "#FFFFFF" : "#05284E"}`,
  },
  totalCardStyles: {
    height: "120px",
    // width: "292px",
    backgroundColor: `${darkStatus ? "#24243C" : "#FFFFFF"}`,
    border: `${darkStatus ? "0.5px solid #616161" : " 1px solid rgb(212,212, 212) "}`,
  },
  totalNameStyles: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "19px",
    color: `${darkStatus ? "#9D9D9D" : "#05284E"}`,
    // top: "19px",
    // marginTop: "19px",
  },
  totalIconStyles: {
    color: "#2800FC",
    fontSize: "30px",
    // marginLeft: "150px",
    // marginTop: "15px",
  },
  totalNumberStyle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "30px",
    color: `${darkStatus ? "#FFFFFF" : "#05284E"}`,
    paddingLeft: "10px",
  },
  totalTimeStyle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    color: `${darkStatus ? "#FFFFFF" : "#05284E"}`,
    paddingLeft: "10px !important",
    display: "flex",
    justifyContent: "start",
    marginTop: "11px"
  },
  pendingCardStyles: {
    height: "120px",
    // width: "292px",
    backgroundColor: `${darkStatus ? "#24243C" : "#FFFFFF"}`,
    border: `${darkStatus ? "0.5px solid #616161" : "1px solid rgb(212,212, 212)"}`,
  },
  pendingNameStyles: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "19px",
    color: `${darkStatus ? "#9D9D9D" : "#05284E"}`,
  },
  pendingIconStyles: {
    color: "#05284E",
    fontSize: "30px",
  },
  pendingNumberStyle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "30px",
    color: `${darkStatus ? "#FFFFFF" : "#05284E"}`,
  },
  orderStatus:{
    backgroundColor: `${darkStatus ? "#24243C" : "#FFFFFF"}`,
  }
});
export default useStyles;
