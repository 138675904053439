/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH";
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME";
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE";

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME";

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR";
export const HIDE_RIGHT_SIDEBAR = "HIDE_RIGHT_SIDEBAR";

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER";
export const types = {
  FETCH_DRIVERS: "FETCH_DRIVERS",
  FETCH_DRIVERS_SUCCESS: "FETCH_DRIVERS_SUCCESS",
  FETCH_DRIVERS_EXCEL: "FETCH_DRIVERS_EXCEL",
  FETCH_DRIVERS_SUCCESS_EXCEL: "FETCH_DRIVERS_SUCCESS_EXCEL",
  FETCH_FILTER_DRIVERS: "FETCH_FILTER_DRIVERS",
  FETCH_FILTER_DRIVERS_SUCCESS: "FETCH_FILTER_DRIVERS_SUCCESS",
  FETCH_DRIVER_BYID: "FETCH_DRIVER_BYID",
  FETCH_DRIVER_BYID_SUCCESS: "FETCH_DRIVER_BYID_SUCCESS",
  EDIT_DRIVER_BYID: "EDIT_DRIVER_BYID",
  EDIT_DRIVER_BYID_SUCCESS: "EDIT_DRIVER_BYID_SUCCESS",
  FETCH_AVAIL_DRIVERS: "FETCH_AVAIL_DRIVERS",
  FETCH_AVAIL_DRIVERS_SUCCESS: "FETCH_AVAIL_DRIVERS_SUCCESS",
  CREATE_DRIVER: "CREATE_DRIVER",
  CREATE_DRIVER_SUCCESS: "CREATE_DRIVER_SUCCESS",
  DELETE_DRIVER: "DELETE_DRIVER",
  DELETE_DRIVER_SUCCESS: "DELETE_DRIVER_SUCCESS",
  UPLOAD_DRIVER: "UPLOAD_DRIVER",
  UPLOAD_DRIVER_SUCCESS: "UPLOAD_DRIVER_SUCCESS",
  SUBSCRIBE_CONSENT: "SUBSCRIBE_CONSENT",
  SUBSCRIBE_CONSENT_SUCCESS: "SUBSCRIBE_CONSENT_SUCCESS",
  UNSUBSCRIBE_CONSENT: "UNSUBSCRIBE_CONSENT",
  UNSUBSCRIBE_CONSENT_SUCCESS: "UNSUBSCRIBE_CONSENT_SUCCESS",
  // REQUEST_CONSENT: "REQUEST_CONSENT",
  // ADD_CONSENT: "ADD_CONSENT",
};
