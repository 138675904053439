import React, { Component } from "react";
//import from libraries
import { Row, Col, Card, Button, InputGroup, Input } from "reactstrap";
import { Grid } from "@material-ui/core";
import Select, { components } from "react-select";
import refreshIcon from "./assets/refresh-cw.png";
import userIcon from "./assets/user.png";
import UserIconDark from "./assets/userDark.png";
import searchIcon from "./assets/search.png";
import searchDark from "./assets/searchDrk.png";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var darkMode;
const DropdownIndicator = (props) => {
  const iconSvg = props.selectProps.selectProps;
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {iconSvg}
      </components.DropdownIndicator>
    )
  );
};
class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hops: [],
      vehicleTypes: [],
      // searchBar: [],
      searchFilters: {},
      userLangs: undefined,
    };
  }

  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }

  render() {
    darkMode = this.props?.mode;
    const customStyles1 = {
      option: (styles, state) => ({
        ...styles,
        cursor: "pointer",
      }),
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      menu: ({ width, ...css }) => ({
        ...css,
        cursor: "pointer",
        width: "100%",
        backgroundColor: darkMode ? "#171722" : "#ffffff",
        border: darkMode
          ? "0.5px solid #616161"
          : "0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode
          ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
          : "0px 3px 12px rgba(0, 0, 0, 0.12)",
        // minWidth: "100%",
        zIndex: 99999,
      }),
      control: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          width:
            this.props.selectedTranspoterValues?.length >= 2 ? "100%" : "100%",
          minWidth: "100%",
          minHeight: "26px",
          flexWrap: "nowrap",
          backgroundColor: darkMode ? "#2E2E46" : "",
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          cursor: "pointer",
          marginTop: "2px",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          cursor: "pointer",
          // backgroundColor: "#8e9af8",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "8px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          minHeight: "26px",
          // backgroundColor: "#0066cc",
        };
      },
    };
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    return (
      <React.Fragment>
        <Card className="darkBgQuickFilter">
          <div
            className="quickStylesBar reportQuickStyleBar"
            style={{
              width: "105vw",
            }}
          >
            <Row className="vehiclAnalysisRow">
              {/* <Col xs={0} md={4} lg={2}></Col> */}
              <Col xs={12} md={12} lg={12} className="">
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={1}
                    className="GridNone"
                  ></Grid>
                  <Grid item xs={12} sm={12} md={2} lg={6}></Grid>
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <div className="input-border ">
                      <InputGroup>
                        <Input
                          className="form-control"
                          type="text"
                          name="shipmentId"
                          value={this.props.filterNames?.shipmentId ?this.props.filterNames?.shipmentId:""}
                          onChange={(e) =>
                            this.props.handleChangeFilters({
                              name: "shipmentId",
                              value: e.target.value,
                            })
                          }
                          onKeyDown={(event) => this.props?.enterCheck(event)}
                          placeholder="Search By Shipment ID"
                          aria-label="Search"
                          icon="fas fa-times-circle"
                        />
                        <span className="pt-2 pb-2 input-line lineDark  pl-1 ml-1"></span>
                        <span
                          className="span-img"
                          onClick={() => this.props?.filterList()}
                        >
                          <img
                            className=""
                            src={darkMode ? searchDark : searchIcon}
                            alt=""
                          ></img>
                        </span>
                      </InputGroup>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={2}>
                    <div className="input-border ">
                      <InputGroup>
                        <Input
                          className="form-control"
                          type="text"
                          name="tripId"
                          value={this.props.filterNames?.tripId ?this.props.filterNames?.tripId:""}
                          onChange={(e) =>
                            this.props.handleChangeFilters({
                              name: "tripId",
                              value: e.target.value,
                            })
                          }
                          onKeyDown={(event) => this.props?.enterCheck(event)}
                          placeholder="Search By Trip ID"
                          aria-label="Search"
                          icon="fas fa-times-circle"
                        />
                        <span className="pt-2 pb-2 input-line lineDark  pl-1 ml-1"></span>
                        <span
                          className="span-img"
                          onClick={() => this.props?.filterList()}
                        >
                          <img
                            className=""
                            src={darkMode ? searchDark : searchIcon}
                            alt=""
                          ></img>
                        </span>
                      </InputGroup>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={2} lg={1}>
                    <Button
                      onClick={() => {
                        this.props.reset();
                      }}
                      className="reset"
                      style={{
                        float: "right",
                      }}
                    >
                      {this.state.userLangs?.common?.RESET}
                    </Button>
                  </Grid>
                </Grid>
              </Col>
            </Row>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickFilters;
