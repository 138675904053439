import moment from "moment";
import React from "react";

const formatDate = (date) => {
  return (
    <div>
      <div>{moment(date).format("DD-MM-YYYY ")}</div>
      <div>{moment(date).format("h:mm A")}</div>
    </div>
  );
};
export default formatDate;
