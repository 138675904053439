import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import dotGroup from "./assets/dot.png";
import Vector from "./assets/Vector.png";
import dotLast from "./assets/dotLast.png";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

export default class OrderPunch extends Component {
  constructor(props) {
    super();
    this.state = {};
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let darkState = this.props.darkTheme;
    return (
      <>
        {/*ORDER PUNCHED*/}
        <div>
          <Grid
            container
            // columnSpacing={3}
            style={{
              marginBottom: "-24px ",
            }}
          >
            <Grid item xs={4}>
              <div className="orderHistoryDateAlignment">
                <span className="dateTextStyle orderStageDate light__span">
                  {Object.entries(this.props?.groupedItems).map(
                    ([status, items]) =>
                      status === "ORDER_PUNCHED" ? (
                        <>
                          <div>
                            {items[0]?.updatedDate}
                          </div>
                          <div>
                            {items[0]?.updateTime}
                          </div>

                        </>
                      ) : (
                        ""
                      )
                  )}
                </span>
              </div>

              <br></br>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                backgroundColor: darkState
                  ? "#2E2E46"
                  : "#F1F5F7",
              }}
            >
              <div>
                <img
                  src={dotGroup}
                  style={{
                    width: " 15%",
                    height: "80%",
                    float: "right",
                    marginRight: "0px",
                  }}
                  className=" shadow-1-strong rounded mb-4 "
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <span className="orderStageStyle dark__span">
                  Order Punch
                </span>
              </div>

              <br></br>
            </Grid>
          </Grid>
        </div>

        <div>

          <Grid
            container
            style={{
              marginBottom: "-24px ",
            }}
          >
            <Grid item xs={4}>

              <div className="orderHistoryDateAlignment">

                <span className="dateTextStyle orderStageDate light__span">

                  {Object.entries(this.props?.groupedItems).map(
                    ([status, items]) =>
                      status === "ORDER_RELEASED" ? (
                        <>

                          <div>
                            {items[0]?.updatedDate}
                          </div>
                          <div>
                            {items[0]?.updateTime}
                          </div>


                        </>
                      ) : (
                        ""
                      )
                  )}
                </span>

              </div>

              <br></br>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                backgroundColor: darkState
                  ? "#2E2E46"
                  : "#F1F5F7",
              }}
            >
              <div>
                <img
                  src={dotGroup}
                  style={{
                    width: " 15%",
                    height: "80%",
                    float: "right",
                    marginRight: "0px",
                    // marginTop: "60px",
                  }}
                  className=" shadow-1-strong rounded mb-4 "
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <span className="orderStageStyle">
                  <span className={this.props.curOrderStatus === "ORDER_PUNCHED" ? "inctiveStatus" : "dark__span"}>
                    Order Release
                  </span>
                </span>
              </div>
              <br></br>
            </Grid>
          </Grid>
        </div>

        <div>
          <Grid
            container
            // columnSpacing={3}
            style={{
              marginBottom: "-24px ",
              height: "auto",
            }}
          >
            <Grid item xs={4}>
              <div className="orderHistoryDateAlignment">
                <span className="dateTextStyle orderStageDate light__span">
                  {Object.entries(this.props?.groupedItems).map(
                    ([status, items]) =>
                      status === "INVOICE_GENERATED" ? (
                        <>
                          <div>
                            {items[0]?.updatedDate}
                          </div>
                          <div>
                            {items[0]?.updateTime}
                          </div>
                        </>
                      ) : (
                        ""
                      )
                  )}
                </span>
              </div>

              <br></br>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                backgroundColor: darkState
                  ? "#2E2E46"
                  : "#F1F5F7",
              }}
            >
              <div>
                <img
                  src={dotGroup}
                  style={{
                    width: " 15%",
                    height: "80%",
                    float: "right",
                    marginRight: "0px",
                    // marginTop: "60px",
                  }}
                  className=" shadow-1-strong rounded "
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>

                <span className="orderStageStyle">
                  <span className="inctiveStatus">
                    Invoice Generated
                    {`(${this.props?.countInvoiceItem
                      ? this.props?.countInvoiceItem
                      : 0
                      }/${this.props.productLength})`}
                  </span>

                  {this.props?.finalDocumentNumber2[0] &&
                    this.props?.finalDocumentNumber2[0]?.map(
                      (item) => (
                        <>
                          <div
                            style={{ padding: "5px" }}
                          >
                            <span className="OrderLineHead">
                              <span>
                                <img src={Vector} />
                              </span>{" "}
                              Invoice No
                            </span>{" "}
                            :{" "}
                            <span
                              style={{
                                color: "GrayText",
                              }}
                            >
                              {item?.id}
                            </span>
                          </div>
                          <div>
                            {item?.values?.map(
                              (item) => (
                                <>
                                  <div
                                    style={{
                                      color: "GrayText",
                                      padding: "1%",
                                      marginLeft:
                                        "40px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color:
                                          "GrayText",
                                      }}
                                    >
                                      Order Line No
                                    </span>{" "}
                                    :{" "}
                                    <span
                                      style={{
                                        color:
                                          "GrayText",
                                      }}
                                    >
                                      {
                                        item?.orderLineNo
                                      }
                                    </span>
                                  </div>
                                </>
                              )
                            )}
                          </div>
                        </>
                      )
                    )}
                </span>

              </div>
              <br></br>
            </Grid>
          </Grid>
        </div>

        <div>
          <Grid
            container
            style={{
              marginBottom: "-24px ",
              marginTop: "40px",
            }}
          >
            <Grid item xs={4}>
              <div className="orderHistoryDateAlignment">
                <span className="dateTextStyle orderStageDate light__span">
                  {Object.entries(this.props?.groupedItems).map(
                    ([status, items]) =>
                      status === "ORDER_DISPATCHED" ? (
                        <>
                          <div>
                            {items[0]?.updatedDate}
                          </div>
                          <div>
                            {items[0]?.updateTime}
                          </div>
                        </>
                      ) : (
                        ""
                      )
                  )}
                </span>
              </div>

              <br></br>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                backgroundColor: darkState
                  ? "#2E2E46"
                  : "#F1F5F7",
              }}
            >
              <div>
                <img
                  src={dotGroup}
                  style={{
                    width: " 15%",
                    height: "80%",
                    float: "right",
                    marginRight: "0px",
                  }}
                  className=" shadow-1-strong rounded mb-4 "
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <span className="orderStageStyle mt-4 inctiveStatus">
                  <span>
                    Order Dispatch
                    {`(${this.props?.countEway ? this.props?.countEway : 0}/ ${this.props.productLength
                      })`}
                  </span>
                  {this.props?.finalEwayBillNo2[0] &&
                    this.props?.finalEwayBillNo2[0].map((item) => (
                      <>
                        <div style={{ padding: "5px" }}>
                          <span className="OrderLineHead">
                            <span>
                              <img src={Vector} />
                            </span>{" "}
                            EWay Bill No
                          </span>{" "}
                          :{" "}
                          <span
                            style={{
                              color: "GrayText",
                            }}
                          >
                            {item?.id}
                          </span>
                        </div>
                        <div>
                          {item?.values?.map((item) => (
                            <>
                              <div
                                style={{
                                  color: "GrayText",
                                  padding: "1%",
                                  marginLeft: "40px",
                                  fontSize: "12px",
                                }}
                              >
                                <span
                                  style={{
                                    color: "GrayText",
                                  }}
                                >
                                  Order Line No
                                </span>{" "}
                                :{" "}
                                <span
                                  style={{
                                    color: "GrayText",
                                  }}
                                >
                                  {item?.orderLineNo}
                                </span>
                              </div>
                            </>
                          ))}
                        </div>
                      </>
                    ))}
                </span>
              </div>
              <br></br>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid
            container
            style={{
              marginBottom: "-24px ",
              marginTop: "10px",
            }}
          >
            <Grid item xs={4}>
              <div className="orderHistoryDateAlignment">
                <span className="dateTextStyle orderStageDate light__span">
                  {Object.entries(this.props?.groupedItems).map(
                    ([status, items]) =>
                      status === "ORDER_DELIVERED" ? (
                        <>
                          <div>
                            {items[0]?.updatedDate}
                          </div>
                          <div>
                            {items[0]?.updateTime}
                          </div>
                        </>
                      ) : (
                        ""
                      )
                  )}
                </span>
              </div>

              <br></br>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                backgroundColor: darkState
                  ? "#2E2E46"
                  : "#F1F5F7",
              }}
              className="mb-4"
            >
              <div>
                <img
                  src={dotLast}
                  style={{
                    width: " 15%",
                    height: "80%",
                    float: "right",
                    marginRight: "0px",
                    // marginTop: "60px",
                  }}
                  className=" shadow-1-strong rounded mb-4 "
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <span className="orderStageStyle  inctiveStatus">
                  Order Delivered
                </span>
              </div>
              <br></br>
            </Grid>
          </Grid>
        </div>
      </>
    )
  }
}
