import React, { Component } from "react";

import { DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import calendarDarkIcon from "../../../pages/Trips/assets/calendarDark.png";
import calendarIcon from "../../../pages/Trips/assets/calendar.png";
import "../../../pages/Dashboard/OperationsDashboard/Styles/dashboard.css";

var classname, darkMode, isDashboard;

const MyIcon = React.forwardRef((props, ref) => {
  if (isDashboard) {
    return (
      <span style={{ position: "absolute", top: "2px", right: "0px" }}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.9985 2H2.99854C1.89397 2 0.998535 2.89543 0.998535 4V13C0.998535 14.1046 1.89397 15 2.99854 15H12.9985C14.1031 15 14.9985 14.1046 14.9985 13V4C14.9985 2.89543 14.1031 2 12.9985 2Z"
            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.9985 1V3"
            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.99854 1V3"
            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M0.998535 6H14.9985"
            stroke={darkMode ? "#B7A9FF" : "#2800FC"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    );
  } else {
    return (
      <img
        className={classname}
        src={darkMode ? calendarDarkIcon : calendarIcon}
        alt=""
      />
    );
  }
});

class Datepicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    darkMode = this.props?.mode;
    classname = this.props?.classname ? this.props?.classname : null;
    isDashboard = this.props?.dashboard ? this.props?.dashboard : false;
    let placeholder = isDashboard
      ? this.props?.placeholder
      : this.props?.myPlaceholder;

    return (
      <React.Fragment>
        <DatePicker
          size="md"
          placeholder={placeholder}
          block
          format="dd MMM yy"
          showOneCalendar
          placement="auto"
          //  preventOverflow
          value={this.props.value || null}
          onChange={(newValue) => {
            // console.log(newValue);
            this.props.fromDate
              ? this.props.onChangeDatePicker(newValue, "fromDate")
              : this.props.onChangeDatePicker(newValue, "toDate");
          }}
          caretAs={MyIcon}
        />
      </React.Fragment>
    );
  }
}

export default Datepicker;
