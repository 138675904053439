import * as React from "react";
import completed from "../../../src/assets/images/Complete truck 1.svg";
import delay from "../../../src/assets/images/Delayed truck 1.svg";
import intransit from "../../../src/assets/images/Intransit truck 1.svg";

export class HereMapCore extends React.Component {
  mapRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      map: null,
    };
  }

  componentDidMount() {
    this.H = window.H;
    this.platform = new this.H.service.Platform({
      // apikey: "VEC8h8HpsD6wiIcbZejvbz7Z3LZ5iwoltTALdu0I-EA",
      apikey: "jowmbkzR9OryNaR82Pe5SHmNI_BpminelQmAzBmGPs4",
    });

    const defaultLayers = this.platform.createDefaultLayers();
    // console.log("MODE", this.props.mode);
    // Create an instance of the map
    this.map = new this.H.Map(
      this.mapRef.current,
      defaultLayers.vector.normal.map,
      {
        center: { lat: 21.1458, lng: 79.0882 },
        zoom: 5,
        pixelRatio: window.devicePixelRatio || 1,
      }
    );

    if (this.props.mode) {
      var provider = this.map.getBaseLayer().getProvider();
      let style = new this.H.map.Style(
        "https://heremaps.github.io/maps-api-for-javascript-examples/change-style-at-load/data/dark.yaml",
        "https://js.api.here.com/v3/3.1/styles/omv/"
      );
      // set the style on the existing layer
      provider.setStyle(style);
    }

    const behavior = new this.H.mapevents.Behavior(
      new this.H.mapevents.MapEvents(this.map)
    );
    const ui = this.H.ui.UI.createDefault(this.map, defaultLayers);

    // this.props.vehicleCoordinate.map(() => {});

    this.setState({ map: this.map });
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.mode !== this.props.mode) {
      var provider = this.map.getBaseLayer().getProvider();
      if (!this.props.mode) {
        let style = new this.H.map.Style(
          "https://js.api.here.com/v3/3.1.17.2/styles/omv/miami/normal.day.yaml"
        );
        // set the style on the existing layer
        provider.setStyle(style);
      } else {
        let style = new this.H.map.Style(
          "https://heremaps.github.io/maps-api-for-javascript-examples/change-style-at-load/data/dark.yaml",
          "https://js.api.here.com/v3/3.1/styles/omv/"
        );
        // set the style on the existing layer
        provider.setStyle(style);
      }
    }
    if (
      prevProps.sourceCoordinates !== this.props.sourceCoordinates ||
      prevProps.destinationCoordinates !== this.props.destinationCoordinates ||
      prevProps.vehicleCoordinate !== this.props.vehicleCoordinate ||
      prevProps.coordinateOrder !== this.props.coordinateOrder ||
      prevProps.currentLocation !== this.props.currentLocation ||
      prevProps.coordinateArrayOrder !== this.props.coordinateArray
    ) {
      // console.log(this.map.getObjects())
      this.map.removeObjects(this.map.getObjects());
      if (
        !(
          this.props.sourceCoordinates &&
          Object.keys(this.props.sourceCoordinates).length === 0 &&
          Object.getPrototypeOf(this.props.sourceCoordinates) ===
            Object.prototype
        )
      ) {
        let url = "https://maps.google.com/mapfiles/ms/icons/green-dot.png";

        if (
          this.props?.sourceCoordinates?.coordinates?.lat &&
          this.props?.sourceCoordinates?.coordinates?.lng
        ) {
          let icon = new this.H.map.Icon(url);
          let marker = new this.H.map.Marker(
            this.props?.sourceCoordinates?.coordinates,
            {
              icon: icon,
            }
          );
          this.map.addObject(marker);
        }
        // this.setState({ map: this.map });
      }

      if (
        !(
          this.props.destinationCoordinates &&
          Object.keys(this.props.destinationCoordinates).length === 0 &&
          Object.getPrototypeOf(this.props.destinationCoordinates) ===
            Object.prototype
        )
      ) {
        let url = "https://maps.google.com/mapfiles/ms/icons/red-dot.png";

        if (
          this.props.destinationCoordinates?.coordinates?.lat &&
          this.props.destinationCoordinates?.coordinates?.lng
        ) {
          let icon = new this.H.map.Icon(url),
            marker = new this.H.map.Marker(
              this.props.destinationCoordinates?.coordinates,
              {
                icon: icon,
              }
            );
          this.map.addObject(marker);
        }
        // this.setState({ map: this.map });
      }
      if (this.props.vehicleCoordinate?.length) {
        this.props.vehicleCoordinate.map((item, index) => {
          // console.log("ftrdesfygjujugtfr",item)
          if (item?.coordinate.lat && item?.coordinate.lng) {
            if (item.status === "in_transit") {
              let url = item?.delayedStatus === false ? intransit : delay;
              let icon = new this.H.map.Icon(url),
                marker = new this.H.map.Marker(item?.coordinate, {
                  icon: icon,
                });
              this.map.addObject(marker);
              // this.setState({ map: this.map });
            } else if (item.status === "completed") {
              let url = completed;
              let icon = new this.H.map.Icon(url),
                marker = new this.H.map.Marker(item?.coordinate, {
                  icon: icon,
                });
              this.map.addObject(marker);
              // this.setState({ map: this.map });
            }
          }
        });
      }

      if (this.props?.coordinateOrder?.length) {
        this.props.coordinateOrder.map((item, index) => {
          if (item.status === "in_transit") {
            let url = item?.status === "in_transit" ? intransit : delay;
            let icon = new this.H.map.Icon(url),
              marker = new this.H.map.Marker(item?.coordinates, { icon: icon });
            this.map.addObject(marker);
            this.setState({ map: this.map });
          } else if (item?.status === "completed") {
            let url = completed;
            let icon = new this.H.map.Icon(url),
              marker = new this.H.map.Marker(item?.coordinates, { icon: icon });
            this.map.addObject(marker);
            this.setState({ map: this.map });
          }
        });
      }
      if (this.props.currentLocation?.lat && this.props.currentLocation?.lng) {
        let icon = new this.H.map.Icon(intransit),
          marker = new this.H.map.Marker(this.props.currentLocation, {
            icon: icon,
          });
        this.map.addObject(marker);
        // this.setState({ map: this.map });
      }

      // console.log(this.props.coordinateArray);
      if (this.props.coordinateArray.length > 0) {
        var lineString = new this.H.geo.LineString();

        this.props.coordinateArray[0].map((item) => {
          // console.log(item);
          lineString.pushPoint(item);
        });
        // console.log(lineString, "Res");
        let poly = new this.H.map.Polyline(lineString, {
          style: {
            strokeColor: "#05284E",
            fillColor: "#05284E",
            lineWidth: 2,
            miterLimit: 10,
          },
          arrows: { width: 3, frequency: 2, length: 2, fillColor: "red" },
        });
        this.map.addObject(poly);
      }
      // var lineString1 = new this.H.geo.LineString();
      // lineString1.pushPoint({ lat: 53.3477, lng: -6.2597 });
      // lineString1.pushPoint({ lat: 51.5008, lng: -0.1224 });
      // lineString1.pushPoint({ lat: 48.8567, lng: 2.3508 });
      // lineString1.pushPoint({ lat: 52.5166, lng: 13.3833 });
      // console.log(lineString1, "Res 1");
    }
    if (
      process.env.REACT_APP_CLIENT === "3_BAXTER" &&
      this.props.sourceCoordinatesOrder &&
      this.props.sourceCoordinatesOrder.lat &&
      this.props.sourceCoordinatesOrder.lng &&
      // Object.keys(this.props.sourceCoordinatesOrder).length === 0
      Object.getPrototypeOf(this.props.sourceCoordinatesOrder) ===
        Object.prototype
    ) {
      // console.log(">>>>>>>TO MARK")
      let url = "https://maps.google.com/mapfiles/ms/icons/green-dot.png";
      let icon = new this.H.map.Icon(url);
      let marker = new this.H.map.Marker(this.props.sourceCoordinatesOrder, {
        icon: icon,
      });
      this.map.addObject(marker);
    }
    if (
      process.env.REACT_APP_CLIENT === "3_BAXTER" &&
      this.props.destinationCoordinatesOrder &&
      this.props.destinationCoordinatesOrder.lat &&
      this.props.destinationCoordinatesOrder.lng &&
      // Object.keys(this.props.destinationCoordinatesOrder).length === 0
      Object?.getPrototypeOf(this.props?.destinationCoordinatesOrder) ===
        Object?.prototype
    ) {
      // console.log(">>>>>>>TO MARK")
      let url = "https://maps.google.com/mapfiles/ms/icons/red-dot.png";
      let icon = new this.H.map.Icon(url),
        marker = new this.H.map.Marker(this.props.destinationCoordinatesOrder, {
          icon: icon,
        });
      this.map.addObject(marker);
    }

    if (
      process.env.REACT_APP_CLIENT === "3_BAXTER" &&
      this.props.currentLocationOrder[0]?.lat &&
      this.props.currentLocationOrder[0]?.lng
    ) {
      let icon = new this.H.map.Icon(intransit),
        marker = new this.H.map.Marker(this.props.currentLocationOrder[0], {
          icon: icon,
        });
      this.map.addObject(marker);
    }

    if (this.props.coordinateArrayOrder.length > 0) {
      const lineString = new this.H.geo.LineString();

      const arr = this.props?.coordinateArrayOrder?.map((item) => {
        // console.log(item);
        lineString.pushPoint(item);
      });
      // console.log(lineString, "Res");
      let poly = new this.H.map.Polyline(lineString, {
        style: {
          strokeColor: "#05284E",
          fillColor: "#05284E",
          lineWidth: 2,
          miterLimit: 10,
        },
        arrows: { width: 3, frequency: 2, length: 2, fillColor: "red" },
      });
      this.map.addObject(poly);
    }
  };
  componentWillUnmount() {
    this.state.map !== null && this.state.map.dispose();
  }

  render() {
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>PROPS", this.props);
    return (
      <div
        ref={this.mapRef}
        className={"HereMaps"}
        style={{
          display: "flex",
          height: "800px",
          margin: "0",
          padding: "0",
          width: "100%",
        }}
      ></div>
    );
  }
}
