import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
//medthods
import { getApi, postApi,patchApi } from "../../helpers/auth_helper";

// workers
function* workerGetInvoiceList({
  filters,
  passSupervisor,
  topFilters,
  searchFilter,
  callback,
}) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  // console.log(searchFilter, "seacrchfilter");
  let cfaCode = passSupervisor ? passSupervisor : "";
  let filterString = "";
  const keyMap = {
    Warehouse: "businessUnit",
    "Order Number": "orderNumber",
    "Legal Invoice No": "legalInvoiceNo",
    "Order Co": "orderCo",
    "Order Type": "orderType",
    "Mode Of Transport": "modeofTransport",
    "Vehicle No": "vehicleNo",
    "Lr No": "lrNo",
    "EWay Bill No": "eWayBillNo",
    "billed To Name": "billedToName",
    "billed To City": "billedToCity",
    pendingInvoice: "pendingInvoice",
    "Cancelled Invoice" :"cancelledInvoice"
    // Add more key-value pairs as needed
  };

  if (searchFilter !== null && searchFilter !== undefined) {
    for (const [key, value] of Object.entries(searchFilter)) {
      let filterKey = keyMap[key] || key;
      let filterValue = value;
      filterString = filterString + "&" + filterKey + "=" + filterValue;
    }
  }

  try {
    const url =
      process.env.REACT_APP_SERVERURL +
      "/order/invoices?offset=" +
      offset +
      "&limit=" +
      limit +
      "&cfaCodes=" +
      cfaCode +
      // "&pendingInvoice=" +
      // topFilters +
      filterString;

    const response = yield call(getApi, url);
    callback(response);
    localStorage.setItem(
      "InvoiceData",
      response ? JSON.stringify(response) : null
    );
    yield put({
      type: types.GET_INVOICELIST_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerGetInvoiceListForExcel({
  filters,
  passSupervisor,
  topFilters,
  searchFilter,
  callback,
}) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  // console.log(searchFilter, "seacrchfilter");
  let cfaCode = passSupervisor ? passSupervisor : "";
  let filterString = "";
  const keyMap = {
    Warehouse: "businessUnit",
    "Order Number": "orderNumber",
    "Legal Invoice No": "legalInvoiceNo",
    "Order Co": "orderCo",
    "Order Type": "orderType",
    "Mode Of Transport": "modeofTransport",
    "Vehicle No": "vehicleNo",
    "Lr No": "lrNo",
    "EWay Bill No": "eWayBillNo",
    "billed To Name": "billedToName",
    "billed To City": "billedToCity",
    pendingInvoice: "pendingInvoice",
    "Cancelled Invoice" :"cancelledInvoice"
    // Add more key-value pairs as needed
  };

  if (searchFilter !== null && searchFilter !== undefined) {
    for (const [key, value] of Object.entries(searchFilter)) {
      let filterKey = keyMap[key] || key;
      let filterValue = value;
      filterString = filterString + "&" + filterKey + "=" + filterValue;
    }
  }

  try {
    const url =
      process.env.REACT_APP_SERVERURL +
      "/order/invoices?offset=" +
      offset +
      "&limit=" +
      limit +
      "&cfaCodes=" +
      cfaCode +
      // "&pendingInvoice=" +
      // topFilters +
      filterString;

    const response = yield call(getApi, url);
    callback(response);
    localStorage.setItem(
      "InvoiceData",
      response ? JSON.stringify(response) : null
    );
    yield put({
      type: types.GET_INVOICELIST_FOR_EXCEL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workCheckTripCreation({ orderIdArray, callback }) {
  // console.log(orderIdArray, "orderIdArray");
  let orderIDString = "";
  if (orderIdArray.length && orderIdArray !== undefined) {
    orderIdArray.forEach((ele, Index) => {
      if (Index === 0) {
        orderIDString = ele;
      } else orderIDString = orderIDString + "," + ele;
    });
  }

  try {
    const url =
      process.env.REACT_APP_SERVERURL +
      "/trip/baxter/isTripCreationPossible?orderInvoiceIds=" +
      orderIDString;

    const response = yield call(getApi, url);
    callback(response);
    localStorage.setItem(
      "InvoiceData",
      response ? JSON.stringify(response) : null
    );
    yield put({
      type: types.CHECK_TRIP_CREATION_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workTripCreation({ orderIdArray, callback }) {
  // console.log(orderIdArray, "orderIdArray");
  // let orderIDString = "";
  // if (orderIdArray.length && orderIdArray !== undefined) {
  //   orderIdArray.forEach((ele, Index) => {
  //     if (Index === 0) {
  //       orderIDString = ele;
  //     } else orderIDString = orderIDString + "," + ele;
  //   });
  // }

  try {
    const url = process.env.REACT_APP_SERVERURL + "/trip/baxter/CreateTrip";

    const response = yield call(postApi, url, {
      orderInvoiceIds: orderIdArray,
    });
    callback(response);
    localStorage.setItem(
      "InvoiceData",
      response ? JSON.stringify(response) : null
    );
    yield put({
      type: types.CREATE_TRIP_SUCCESS_BAXTER,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workCancelInvoice({ payload, callback }) {
  try {
    const url = process.env.REACT_APP_SERVERURL + "/order/cancelInvoice";

    const response = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CANCEL_INVOICE_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workAddCharges({ Values, callback }) {
  try {
    //console.log('values', values);
    // Try to call the API
    const uri =
      process.env.REACT_APP_SERVERURL + "/order/updateChargesAndTruckTypeToInvoice";
    console.log("addddd charges payload",Values)
    const response = yield call(patchApi, uri, Values);
    //console.log('response',response);
    callback(response);
    //   Dispatch the action to the reducers
    yield put({
      type: types.ADD_CHARGES_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log("Request failed! Could not fetch vehicle.", error);
  }
}
// watchers
export function* watchInvoiceList() {
  yield takeEvery(types.GET_INVOICELIST, workerGetInvoiceList);
}
export function* watchInvoiceListForExcel() {
  yield takeEvery(
    types.GET_INVOICELIST_FOR_EXCEL,
    workerGetInvoiceListForExcel
  );
}
export function* watchCheckTripCreation() {
  yield takeEvery(types.CHECK_TRIP_CREATION, workCheckTripCreation);
}
export function* watchTripCreation() {
  yield takeEvery(types.CREATE_TRIP_BAXTER, workTripCreation);
}

export function* watchCancelInvoice() {
  yield takeEvery(types.CANCEL_INVOICE, workCancelInvoice);
}
export function* watchAddCharges() {
  yield takeEvery(types.ADD_CHARGES, workAddCharges);
}

function* invoiceListSaga() {
  yield all([fork(watchInvoiceList)]);
  yield all([fork(watchInvoiceListForExcel)]);
  yield all([fork(watchCheckTripCreation)]);
  yield all([fork(watchTripCreation)]);
  yield all([fork(watchCancelInvoice)]);
  yield all([fork(watchAddCharges)]);
}
export default invoiceListSaga;
