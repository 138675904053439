import React from "react";
import { Label, FormGroup, Input } from "reactstrap";
import Select, { components } from "react-select";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const DropdownIndicator = (props) => {
  const iconSvg = props.selectProps.selectProps;
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {iconSvg}
      </components.DropdownIndicator>
    )
  );
};
const MyIcon = React.forwardRef((props, ref) => {
  return (
    <span style={{ position: "absolute", top: "2px", right: "10px" }}></span>
  );
});

export const InputField = (props) => {
  // console.log(props,"selectProps1")
  const { controlType, labelStyle = {}, labelClass = "", labelValue, isRequired } = props;
  return (
    <FormGroup className="select2-container" >
      <Label style={labelStyle} className={(isRequired ? 'required-field ' : "") + labelClass}>
        {labelValue}
      </Label>
      {controlType === "text" && <RenderInput {...props} />}
      {controlType === "select" && <RenderSelect {...props} />}
      {controlType === "date" && <RenderDate {...props} />}
      {/* {errors[inputName] && <RenderFormError error={errors[inputName]} />} */}
    </FormGroup>
  );
};

export const RenderFormError = ({ error }) => {
  return <span className="err">{error}</span>;
};

const RenderInput = (inputProps) => {
  let pathName = window.location.pathname;
  let keyFilterExists
  const key = `mySearchFilter_${pathName}`;
  if(pathName != "/tripList" && pathName !="/ordersList"){
     keyFilterExists = localStorage.getItem(key)
    ? JSON.parse(localStorage.getItem(key))
    : null
  }else{
    keyFilterExists = localStorage.getItem("tripsFilterData") && pathName === "/tripList"
    ? JSON.parse(localStorage.getItem("tripsFilterData"))
    : localStorage.getItem("myFilterData") && pathName === "/ordersList" ?
      JSON.parse(localStorage.getItem("myFilterData"))
      : "";
  }

     
  // console.log(pathName === window.location.pathname, pathName, "keyfilter1")
  const {
    // inputType,
    inputName,
    value,
    onChangeMethod,
    placeholderValue,
    handleKeyPress,
    handleBlur,
    innerRef,
  } = inputProps;
  let isBaxter = process.env.REACT_APP_CLIENT === "3_BAXTER"
  return (
    <div className="RenderInput baxterCapitalize">
      <Input
        style={{ height: "26px", margin: "12px", fontSize: "12px",boxSizing: 'border-box', border: '1px solid #E6E6E6', ...(isBaxter && { textTransform: 'uppercase' ,fontWeight:"700" }) }}
        className="form-control"
        type={
          inputName === "indentId" || inputName === "tripId" ? "number" : "text"
        }
        // value={value}
        defaultValue={keyFilterExists && isBaxter? keyFilterExists[`${inputName}`] : ""}
        onChange={(event) =>
          onChangeMethod({
            target: {
              name: inputName,
              value: event.target.value,
            },
          })
        }
        placeholder={placeholderValue}
        onKeyPress={handleKeyPress && handleKeyPress}
        onBlur={(e) => handleBlur && handleBlur(e, inputName)}
        innerRef={innerRef}
      />
    </div>
  );
};

const RenderSelect = (selectProps) => {
  // console.log(selectProps,"selectProps")
  let mode = localStorage.getItem("Theme")
    ? localStorage.getItem("Theme")
    : null;
  let darkMode = mode === "dark__mode" ? true : false;
  let pathName = window.location.pathname;
  let key = `myStatusFilter_${pathName}`;
  let innerKey = `${selectProps?.inputName}_${pathName}`;
  const storedStatusObjects = JSON.parse(localStorage.getItem(key)) || {};
  // console.log(storedStatusObjects,"21")

  const customStyles = {
    indicatorsContainer: (style) => {
      return {
        ...style,
        width: "20px",
        height: "22px",
        textAlign: "center",
        justifyContent: "center",
        borderRadius: "0px 4px 4px 0px",
      };
    },
    control: (style) => {
      return {
        ...style,
        textAlign: "center",
        alignSelf: "flex-end",
        height: "26px",
        minHeight: "26px",
        margin: "10px",
        marginRight: "6px",
        backgroundColor: darkMode ? "#2E2E46" : "#ffffff",
        border: "1px solid #E6E6E6"
      };
    },
    input: (style, state) => {
      return {
        ...style,
        width: "20%",
        textAlign: "center",
        fontSize: "10",
        color: darkMode ? "#9d9d9d" : "#505d69",
      };
    },

    dropdownIndicator: (style) => {
      return {
        ...style,
        svg: {
          fill: darkMode ? "#2E2E46" : "#ffffff",
          marginRight: "5px",
          marginBottom: "3px"
        },
      };
    },
    placeholder: (style) => {
      return {
        ...style,
        fontSize: "12px",
        position: "absolute",
        left: "28px"
      };
    },
    valueContainer: (style) => {
      return {
        ...style,
        height: "22px",
        minHeight: "22px",
        alignContent: "center",
      };
    },
    container: (style) => {
      return {
        ...style,
        marginTop: "12px",
      };
    },
    menu: (style) => {
      return {
        ...style,
        backgroundColor: darkMode ? "#171722" : "#ffffff",
        color: darkMode ? "#B7A9FF" : "rgb(51, 51, 51)",
        border: darkMode
          ? "0.5px solid #616161"
          : "0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode
          ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
          : "0px 3px 12px rgba(0, 0, 0, 0.12)",
        // zIndex: "22px",
        // position: "",
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: darkMode
          ? darkMode && isFocused
            ? "#1b1c31"
            : null
          : isFocused
            ? "#cce9ff"
            : "",
        color: darkMode ? "#B7A9FF" : "rgb(51, 51, 51)",
      };
    },
  };
  const {
    value,
    inputName,
    onChangeMethod,
    onInputChange,
    optionList,
    handleScrollBottom,
    handleMenuClose,
    isLoading,
    handleKeyPress,
    innerRef,
    selectval
  } = selectProps;
  let isBaxter = process.env.REACT_APP_CLIENT === "3_BAXTER"
  return (
    <Select
      value={selectval}
      defaultValue={storedStatusObjects  &&  isBaxter? storedStatusObjects[innerKey]: selectval}
      onChange={(e) =>
        onChangeMethod({
          target: {
            name: inputName,
            value: e.value,
            label:e.label
          },
        })
      }
      options={optionList}
      classNamePrefix="select2-selection"
      placeholder={inputName === "subStatus" ? inputName.slice(3) : inputName}
      styles={customStyles}
      onMenuScrollToBottom={(event) =>
        handleScrollBottom && handleScrollBottom(event)
      }
      onInputChange={onInputChange}
      onMenuClose={(event) => handleMenuClose && handleMenuClose(event)}
      isLoading={isLoading}
      onKeyPress={handleKeyPress}
      innerRef={innerRef}
      components={{ DropdownIndicator }}
      selectProps={
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.7751 13.6C11.2545 13.6 14.0751 10.7794 14.0751 7.3C14.0751 3.82061 11.2545 1 7.7751 1C4.2957 1 1.4751 3.82061 1.4751 7.3C1.4751 10.7794 4.2957 13.6 7.7751 13.6Z"
            stroke="#2800FC"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.475 15L13.375 12.9"
            stroke="#2800FC"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
    />
  );
};

const RenderDate = (inputProps) => {
  // console.log(inputProps, "renderDate");
  const {
    // inputType,
    inputName,
    inputValue,
    onChangeMethod,
    placeholderValue,
    handleKeyPress,
    handleBlur,
    dateBg,
  } = inputProps;
  return (
    <Input
      style={{
        height: "22px",
        margin: "12px",
        fontSize: "12px",
        backgroundColor: `${dateBg}`,
      }}
      className="form-control"
      type="date"
      value={inputValue}
      onChange={(event) =>
        onChangeMethod({
          target: {
            name: inputName,
            value: event.target.value,
          },
        })
      }
      placeholder={placeholderValue.charAt(0).toUpperCase() + placeholderValue.slice(1)}
      onKeyPress={handleKeyPress && handleKeyPress}
      onBlur={(e) => handleBlur && handleBlur(e, inputName)}
    />
  );
};

export const RenderNewDate = (inputProps) => {
  const {
    // inputType,
    inputName,
    inputValue,
    onChangeMethod,
    // onCloseMethod,
    // placeholderValue,
    // handleKeyPress,
    // handleBlur,
    dateBg,
    dateClr,
  } = inputProps;
  return (
    // <LocalizationProvider dateAdapter={AdapterDateFns} utils={MomentUtils}>
    //   <DateRangePicker
    //     calendars={1}
    //     startText=""
    //     endText=""
    //     format={"MM-DD-YYYY"}
    //     // onClose={(event) => onCloseMethod(event)}
    //     // value={this.props.datevalues || [null, null]}
    //     value={inputValue || [null, null]}
    //     onChange={(event) => onChangeMethod(event)}
    //     renderInput={(startProps, endProps) => (
    //       <React.Fragment>
    //         <input
    //           style={{ width: "30%", margin: "auto" ,backgroundColor:`${dateBg}`,color:`${dateClr}`}}
    //           ref={startProps.inputRef}
    //           {...startProps.inputProps}
    //         />
    //         <Box> - </Box>
    //         <input
    //           style={{ width: "30%", margin: "auto" ,backgroundColor:`${dateBg}`,color:`${dateClr}`}}
    //           ref={endProps.inputRef}
    //           {...endProps.inputProps}
    //         />
    //       </React.Fragment>
    //     )}
    //   />
    // </LocalizationProvider>
    <div
      className="FieldsClass"
      style={{
        backgroundColor: `${dateBg}`,
        color: `${dateClr}`,
        height: "22px",
        margin: "14px",
        fontSize: "12px",
        width: "95%"
      }}
    >
      <DateRangePicker
        // className="RsuiteDateRange"
        size="md"
        placeholder={inputName}
        block
        format="dd MMM yy"
        showOneCalendar
        placement="bottomStart"
        preventOverflow
        value={inputValue || [null, null]}
        onChange={(datevalues) => {
          onChangeMethod(datevalues);
          // console.log(datevalues);
        }}
        caretAs={MyIcon}
      />
    </div>
  );
};

export const RenderNewDate2 = (inputProps) => {
  // const {
  //   // inputType,
  //   inputName,
  //   inputValue,
  //   onChangeMethod,
  //   placeholderValue,
  //   handleKeyPress,
  //   handleBlur,
  //   dateBg,
  // } = inputProps;
  return (
    <div></div>
    // <LocalizationProvider dateAdapter={AdapterDateFns} utils={MomentUtils}>
    //   <DateRangePicker
    //     label="Advanced keyboard"
    //     value={inputValue}
    //     onChange={(event) => onChangeMethod(event)}
    //     renderInput={(startProps, endProps) => (
    //       <React.Fragment>
    //         <input
    //           style={{ width: "30%", margin: "auto" ,backgroundColor:`${dateBg}` }}
    //           ref={startProps.inputRef}
    //           {...startProps.inputProps}
    //         />
    //         <Box> - </Box>
    //         <input
    //           style={{ width: "30%", margin: "auto" }}
    //           ref={endProps.inputRef}
    //           {...endProps.inputProps}
    //         />
    //       </React.Fragment>
    //     )}
    //   />
    // </LocalizationProvider>
  );
};
