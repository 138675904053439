import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Box } from "@material-ui/core";
// import {Grid} from "reactstrap"
import { Card } from "@material-ui/core";
// files
import useStylesTest from "./Styles/DashboardCardStyles.js";
import DashboardCard from "./DashboardCard.js";
import { CardHeader } from "reactstrap";
import TransportCard from "./TransportCard";

class OrdersCards extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  cardClick = (name) => {
    // console.log(name, "name");
    let filter;
    if (name === "Order Punch") filter = "ORDER_PUNCHED";
    if (name === "Order Released") filter = "ORDER_RELEASED";
    if (name === "Invoice Generated") filter = "INVOICE_GENERATED";
    if (name === "Order Dispatch") filter = "ORDER_DISPATCHED";
    if (name === "Order Delivered") filter = "ORDER_DELIVERED";
    if (name === "Order Cancelled") filter = "ORDER_CANCELLED";
    if (name === "Back Order") filter = "BACK_ORDER";

    this.props.history.push({
      pathname: "/ordersList",
      state: {
        filter: filter,
        topFilters: this.props?.topFilters,
      },
    });
  };

  render() {
    const orders = this.props.orders;
    const darkTheme = this.props?.opCardStyle;
    const classes = useStylesTest(this.props?.opCardStyle);
    // const orderCancelled = this.props?.orderCancelled?.data?.length ?? 0;
    return (
      <>
        <Grid item xs={12} sm={12} md={12}>
          <div className="desktopDisplay">
            <Grid
              container
              spacing={1}
              justifyContent="center"
              alignItems="center"
              direction="row"
              className="dashboard-card-spacing dashContainerDiv"
              style={{
                width: "100%",
                // padding:"0px 5px"
                display: "flex",
                justifyContent: "space-between",
                //   marginTop:"-20%"
              }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader style={classes.orderStatus}>
                    <span className="roboto-font-700 span-order-status light__span">
                      {this.props?.userLang?.baxterdDashboard?.ORDER_STATUS ??
                        "Order Status"}
                    </span>
                  </CardHeader>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DashboardCard
                  cardStyle={classes.totalCardStyles}
                  nameStyle={classes.totalNameStyles}
                  iconStyle={classes.totalIconStyles}
                  numberStyle={classes.totalNumberStyle}
                  name="Total"
                  nameForHindiLan={
                    this.props?.userLang?.dashboard?.TOTAL ?? "Total"
                  }
                  value={orders?.total}
                  iconClr={classes.plannedEye}
                  color={"#2800FC"}
                  cardClick={this.cardClick}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DashboardCard
                  cardStyle={classes.totalCardStyles}
                  nameStyle={classes.totalNameStyles}
                  iconStyle={classes.totalIconStyles}
                  numberStyle={classes.totalNumberStyle}
                  name="Order Punch"
                  nameForHindiLan={
                    this.props?.userLang?.baxterdDashboard
                      ?.TOTAL_ORDER_PUNCHED ?? "Order Punch"
                  }
                  value={orders?.orderPunched}
                  iconClr={classes.plannedEye}
                  color={"#2800FC"}
                  cardClick={this.cardClick}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DashboardCard
                  cardStyle={classes.totalCardStyles}
                  nameStyle={classes.totalNameStyles}
                  iconStyle={classes.totalIconStyles}
                  numberStyle={classes.totalNumberStyle}
                  name="Order Released"
                  nameForHindiLan={
                    this.props?.userLang?.baxterdDashboard?.ORDER_RELEASE ??
                    "Order Released"
                  }
                  value={orders?.orderRelease}
                  iconClr={classes.plannedEye}
                  color={"#2800FC"}
                  cardClick={this.cardClick}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DashboardCard
                  cardStyle={classes.totalCardStyles}
                  nameStyle={classes.totalNameStyles}
                  iconStyle={classes.totalIconStyles}
                  numberStyle={classes.totalNumberStyle}
                  name="Invoice Generated"
                  nameForHindiLan={
                    this.props?.userLang?.baxterdDashboard?.INVOICE_GENERATED ??
                    "Invoice Generated"
                  }
                  value={orders?.invoiceGenerated}
                  iconClr={classes.plannedEye}
                  color={"#2800FC"}
                  cardClick={this.cardClick}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DashboardCard
                  cardStyle={classes.totalCardStyles}
                  nameStyle={classes.totalNameStyles}
                  iconStyle={classes.totalIconStyles}
                  numberStyle={classes.totalNumberStyle}
                  name="Order Dispatch"
                  nameForHindiLan={
                    this.props?.userLang?.baxterdDashboard?.ORDER_DISPATCHED ??
                    "Order Dispatch"
                  }
                  value={orders?.orderDispatch}
                  iconClr={classes.plannedEye}
                  color={"#2800FC"}
                  cardClick={this.cardClick}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DashboardCard
                  cardStyle={classes.totalCardStyles}
                  nameStyle={classes.totalNameStyles}
                  iconStyle={classes.totalIconStyles}
                  numberStyle={classes.totalNumberStyle}
                  name="Order Delivered"
                  nameForHindiLan={
                    this.props?.userLang?.baxterdDashboard?.ORDER_DELIVERED ??
                    "Order Delivered"
                  }
                  value={orders?.orderDeliver}
                  iconClr={classes.plannedEye}
                  color={"#2800FC"}
                  cardClick={this.cardClick}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} className="">
                <DashboardCard
                  cardStyle={classes.totalCardStyles}
                  nameStyle={classes.totalNameStyles}
                  iconStyle={classes.totalIconStyles}
                  numberStyle={classes.totalNumberStyle}
                  name="Order Cancelled"
                  nameForHindiLan={
                    this.props?.userLang?.baxterdDashboard?.ORDER_CANCELLED ??
                    "Order Cancelled"
                  }
                  value={orders?.orderCancelled}
                  iconClr={classes.plannedEye}
                  color={"#2800FC"}
                  cardClick={this.cardClick}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} className="">
                <DashboardCard
                  cardStyle={classes.totalCardStyles}
                  nameStyle={classes.totalNameStyles}
                  iconStyle={classes.totalIconStyles}
                  numberStyle={classes.totalNumberStyle}
                  name="Back Order"
                  nameForHindiLan={
                    this.props?.userLang?.baxterdDashboard?.BACK_ORDER ??
                    "Back Order"
                  }
                  value={orders?.backOrders}
                  iconClr={classes.plannedEye}
                  color={"#2800FC"}
                  cardClick={this.cardClick}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} className="">
                <DashboardCard
                  cardStyle={classes.totalCardStyles}
                  nameStyle={classes.totalNameStyles}
                  iconStyle={classes.totalIconStyles}
                  numberStyle={classes.totalTimeStyle}
                  name="Punch To Dispatch"
                  nameForHindiLan={
                    this.props?.userLang?.baxterdDashboard?.PUNCH_TO_DISPATCH ??
                    "Punch To Dispatch"
                  }
                  value={orders?.punchToDispatch}
                  iconClr={classes.plannedEye}
                  color={"#2800FC"}
                  cardClick={this.cardClick}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} className="">
                <DashboardCard
                  cardStyle={classes.totalCardStyles}
                  nameStyle={classes.totalNameStyles}
                  iconStyle={classes.totalIconStyles}
                  numberStyle={classes.totalTimeStyle}
                  name="Dispatch To Delivery"
                  nameForHindiLan={
                    this.props?.userLang?.baxterdDashboard
                      ?.DISPATCH_TO_DILEVERY ?? "Dispatch To Delivery"
                  }
                  value={orders?.dispatchToDelivery}
                  iconClr={classes.plannedEye}
                  color={"#2800FC"}
                  cardClick={this.cardClick}
                />
              </Grid>
           
            </Grid>
          </div>
          <Box sx={{ flexGrow: 1 }} className="mobileDisplayShpment">
            <Grid
              container
              spacing={1}
              justifyContent="center"
              alignItems="center"
              className="dashboard-card-spacing dashContainerDiv"
              style={{
                width: "100%",
                // padding:"0px 5px"
              }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader style={{ background: "white" }}>
                    <span className="roboto-font-700 span-order-status light__span">
                      {this.props?.userLang?.baxterdDashboard?.ORDER_STATUS ??
                        " Order Status"}
                    </span>
                  </CardHeader>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DashboardCard
                  cardStyle={classes.totalCardStyles}
                  nameStyle={classes.totalNameStyles}
                  iconStyle={classes.totalIconStyles}
                  numberStyle={classes.totalNumberStyle}
                  name="Total"
                  nameForHindiLan={
                    this.props?.userLang?.dashboard?.TOTAL ?? "Total"
                  }
                  value={orders?.total}
                  iconClr={classes.plannedEye}
                  color={"#2800FC"}
                  cardClick={this.cardClick}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DashboardCard
                  cardStyle={classes.totalCardStyles}
                  nameStyle={classes.totalNameStyles}
                  iconStyle={classes.totalIconStyles}
                  numberStyle={classes.totalNumberStyle}
                  name="Order Punch"
                  nameForHindiLan={
                    this.props?.userLang?.baxterdDashboard
                      ?.TOTAL_ORDER_PUNCHED ?? "Order Punch"
                  }
                  value={orders?.orderPunched}
                  iconClr={classes.plannedEye}
                  color={"#2800FC"}
                  cardClick={this.cardClick}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DashboardCard
                  cardStyle={classes.totalCardStyles}
                  nameStyle={classes.totalNameStyles}
                  iconStyle={classes.totalIconStyles}
                  numberStyle={classes.totalNumberStyle}
                  name="Order Released"
                  nameForHindiLan={
                    this.props?.userLang?.baxterdDashboard?.ORDER_RELEASE ??
                    "Order Released"
                  }
                  value={orders?.orderRelease}
                  iconClr={classes.plannedEye}
                  color={"#2800FC"}
                  cardClick={this.cardClick}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DashboardCard
                  cardStyle={classes.totalCardStyles}
                  nameStyle={classes.totalNameStyles}
                  iconStyle={classes.totalIconStyles}
                  numberStyle={classes.totalNumberStyle}
                  name="Invoice Generated"
                  nameForHindiLan={
                    this.props?.userLang?.baxterdDashboard?.INVOICE_GENERATED ??
                    "Invoice Generated"
                  }
                  value={orders?.invoiceGenerated}
                  iconClr={classes.plannedEye}
                  color={"#2800FC"}
                  cardClick={this.cardClick}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DashboardCard
                  cardStyle={classes.totalCardStyles}
                  nameStyle={classes.totalNameStyles}
                  iconStyle={classes.totalIconStyles}
                  numberStyle={classes.totalNumberStyle}
                  name= "Order Dispatch"
                  nameForHindiLan={
                    this.props?.userLang?.baxterdDashboard?.ORDER_DISPATCHED ??
                    "Order Dispatch"
                  }
                  value={orders?.orderDispatch}
                  iconClr={classes.plannedEye}
                  color={"#2800FC"}
                  cardClick={this.cardClick}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DashboardCard
                  cardStyle={classes.totalCardStyles}
                  nameStyle={classes.totalNameStyles}
                  iconStyle={classes.totalIconStyles}
                  numberStyle={classes.totalNumberStyle}
                  name="Order Delivered"
                  nameForHindiLan={
                    this.props?.userLang?.baxterdDashboard?.ORDER_DELIVERED ??
                    "Order Delivered"
                  }
                  value={orders?.orderDeliver}
                  iconClr={classes.plannedEye}
                  color={"#2800FC"}
                  cardClick={this.cardClick}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DashboardCard
                  cardStyle={classes.totalCardStyles}
                  nameStyle={classes.totalNameStyles}
                  iconStyle={classes.totalIconStyles}
                  numberStyle={classes.totalNumberStyle}
                  name="Order Cancelled"
                  nameForHindiLan={
                    this.props?.userLang?.baxterdDashboard?.ORDER_CANCELLED ??
                    "Order Cancelled"
                  }
                  value={orders?.orderCancelled}
                  iconClr={classes.plannedEye}
                  color={"#2800FC"}
                  cardClick={this.cardClick}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DashboardCard
                  cardStyle={classes.totalCardStyles}
                  nameStyle={classes.totalNameStyles}
                  iconStyle={classes.totalIconStyles}
                  numberStyle={classes.totalTimeStyle}
                  name="Punch to dispatch"
                  nameForHindiLan={
                    this.props?.userLang?.baxterdDashboard?.PUNCH_TO_DISPATCH ??
                    "Punch to dispatch"
                  }
                  value={orders?.punchToDispatch}
                  iconClr={classes.plannedEye}
                  color={"#2800FC"}
                  cardClick={this.cardClick}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DashboardCard
                  cardStyle={classes.totalCardStyles}
                  nameStyle={classes.totalNameStyles}
                  iconStyle={classes.totalIconStyles}
                  numberStyle={classes.totalTimeStyle}
                  name= "Dispatch to delivery"
                  nameForHindiLan={
                    this.props?.userLang?.baxterdDashboard
                      ?.DISPATCH_TO_DILEVERY ?? "Dispatch to delivery"
                  }
                  value={orders?.dispatchToDelivery}
                  iconClr={classes.plannedEye}
                  color={"#2800FC"}
                  cardClick={this.cardClick}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {})(OrdersCards);
