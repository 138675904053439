export const types = {
  CREATE_CUSTOMER:"CREATE_CUSTOMER",
  CREATE_CUSTOMER_SUCCESS:"CREATE_CUSTOMER_SUCCESS",
  CREATE_CUSTOMER_VIA_EXCEL:"CREATE_CUSTOMER_VIA_EXCEL",
  CREATE_CUSTOMER_VIA_EXCEL_SUCCESS:"CREATE_CUSTOMER_VIA_EXCEL_SUCCESS",
  GET_CUSTOMERS:"GET_CUSTOMERS",
  GET_CUSTOMERS_SUCCESS:"GET_CUSTOMERS_SUCCESS",
  GET_CUSTOMERS_EXCEL:"GET_CUSTOMERS_EXCEL",
  GET_CUSTOMERS_SUCCESS_EXCEL:"GET_CUSTOMERS_SUCCESS_EXCEL",
  UPDATE_CUSTOMER:"UPDATE_CUSTOMER",
  UPDATE_CUSTOMER_SUCCESS:"UPDATE_CUSTOMER_SUCCESS",
  DELETE_CUSTOMER:"DELETE_CUSTOMER",
  DELETE_CUSTOMER_SUCCESS:"DELETE_CUSTOMER_SUCCESS"
    
}