import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
//medthods
import {
  deleteApi,
  getApi,
  patchApi,
  postApi,
  uploadExcel,
  putApi
} from "../../helpers/auth_helper";

// workers
function* workerGetLocations({ filters, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let searchFilters = ''
  for(let key in filters){
    if(!['offset','limit'].includes(key))
    searchFilters += `&${key}=${filters[key]}`   
  }
  try {
    const url = `${process.env.REACT_APP_SERVERURL}/location/locations?offset=${offset}&limit=${limit}${searchFilters}` 
    const response = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_LOCATIONS_SUCCESS,
      payload: response,
    });
    localStorage.setItem("LocationsData", response ? JSON.stringify(response):null);
  } catch (e) {
    console.log(e);
  }
}

function* workerGetLocationsExcel({ filters, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 100000;
  let searchFilters = ''
  for(let key in filters){
    if(!['offset','limit'].includes(key))
    searchFilters += `&${key}=${filters[key]}`   
  }
  try {
    const url = `${process.env.REACT_APP_SERVERURL}/location/locations?offset=${offset}&limit=${limit}${searchFilters}` 
    const response = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_LOCATIONS_SUCCESS_EXCEL,
      payload: response,
    });
    // localStorage.setItem("LocationsData", response ? JSON.stringify(response):null);
  } catch (e) {
    console.log(e);
  }
}
function* workerCreateLocation({ payload, callback }) {
  try {
    const url = process.env.REACT_APP_SERVERURL + "/location/create";
    const response = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CREATE_LOCATIONS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerCreateLocationViaExcel({ payload, callback }) {
  try {
    const url = process.env.REACT_APP_SERVERURL + "/location/addViaExcel";
    const response = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CREATE_LOCATIONS_VIA_EXCEL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerUpdateLocation({ payload, callback }) {
  try {
    const url = process.env.REACT_APP_SERVERURL + "/location/update";
    const response = yield call(putApi, url, payload);
    callback(response);
    yield put({
      type: types.UPDATE_LOCATION_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerDeleteLocation({ payload, callback }) {
    try {
      const url = process.env.REACT_APP_SERVERURL + "/location/delete";
      const response = yield call(deleteApi, url, payload);
      callback(response);
      yield put({
        type: types.DELETE_LOCATION_SUCCESS,
        payload: response,
      });
    } catch (e) {
      console.log(e);
    }
  }

// watchers
export function* watchGetLocations() {
  yield takeEvery(types.GET_LOCATIONS, workerGetLocations);
}

export function* watchGetLocationsExcel() {
  yield takeEvery(types.GET_LOCATIONS_EXCEL, workerGetLocationsExcel);
}
export function* watchCreateLocation() {
  yield takeEvery(types.CREATE_LOCATIONS, workerCreateLocation);
}
export function* watchCreateLocationViaExcel() {
  yield takeEvery(types.CREATE_LOCATIONS_VIA_EXCEL, workerCreateLocationViaExcel);
}
export function* watchUpdateLocation() {
  yield takeEvery(types.UPDATE_LOCATION, workerUpdateLocation);
}
export function* watchDeleteLocation() {
    yield takeEvery(types.DELETE_LOCATION, workerDeleteLocation);
  }

function* locationSaga() {
  yield all([fork(watchGetLocations)]);
  yield all([fork(watchGetLocationsExcel)]);
  yield all([fork(watchCreateLocation)]);
  yield all([fork(watchCreateLocationViaExcel)]);
  yield all([fork(watchUpdateLocation)]);
  yield all([fork(watchDeleteLocation)]);
}
export default locationSaga;
