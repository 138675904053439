import React, { Component } from "react";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
} from "mdbreact";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import { NotificationManager } from "react-notifications";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { finalizeIndent } from "../store/indent/actions";
import { updateTrip } from "../store/actions";
import { connect } from "react-redux";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class ApproveModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      buttonContent: "",
      chevronVehicle: "down",
      chevronDriver: "down",
      vehicleDropDown: false,
      driverDropDown: false,

      isVehicleNoValid: true,
      isVehicleTypeValid: true,

      isPhoneNoValid: true,
    };
  }

  vehicleDropDownChange = () => {
    let direction = "";
    if (this.state.chevronVehicle === "down") {
      direction = "up";
    } else {
      direction = "down";
    }
    this.setState({
      vehicleDropDown: !this.state.vehicleDropDown,
      driverDropDown: false,
      chevronVehicle: direction,
      chevronDriver: "down",
    });
  };
  driverDropDownChange = () => {
    let direction = "";
    if (this.state.chevronDriver === "down") {
      direction = "up";
    } else {
      direction = "down";
    }
    this.setState({
      vehicleDropDown: false,
      driverDropDown: !this.state.driverDropDown,
      chevronVehicle: "down",
      chevronDriver: direction,
    });
  };
  handleSubmit = () => {
    this.setState({
      submitDisable: true,
      buttonContent: "Processing...",
      isSubmitting: true,
    });
    if (!this.props.isTrip) {
      let finalValues = {
        indent: this.props.indentIndex,
        vehicle: this.props.vehicle.value,
        driver: this.props.driver.value,
        transporter: this.props.transporter,
      };
      this.props.finalizeIndent({
        values: finalValues,
        callback: (res) => {
          if (res.success) {
            this.props.refreshData();
            this.props.toggle();
            NotificationManager.success("Finalized Successfully", "Success");
            this.setState({
              isSubmitting: !this.state.isSubmitting,
              buttonContent: userLang?.common?.SUBMIT,
            });
          } else {
            NotificationManager.error("Please Check the Data Again", "Error");
            this.setState({
              isSubmitting: !this.state.isSubmitting,
              buttonContent: userLang?.common?.SUBMIT,
            });
          }
        },
      });
    } else {
      let values = {
        tripId: this.props.tripIndex,
      };
      if (this.props.driver?.value !== undefined)
        values.driver = {
          id: this.props.driver.value,
        };
      if (this.props.vehicle?.value !== undefined)
        values.vehicle = {
          id: this.props.vehicle.value,
        };

      this.props.updateTrip(values, (res) => {
        // console.log(res, "here is the response log");
        if (res.success) {
          this.props.refreshData();
          this.props.toggle();
          NotificationManager.success("Updated Successfully", "Success");
          this.setState({
            isSubmitting: !this.state.isSubmitting,
            buttonContent: "Submit",
          });
        } else {
          NotificationManager.error("Please Check the Data Again", "Error");
          this.setState({
            isSubmitting: !this.state.isSubmitting,
            buttonContent: "Submit",
          });
        }
      });
    }
  };
  checkSubmitVehicleForm = () => {
    // console.log("CHECK");
    let vehicleRegex1 = /^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4}$/;
    let vehicleRegx2 = /^[A-Z]{2}[0-9]{2}[A-Z]{1}[0-9]{4}$/;
    let vehicleRegx3 = /^[A-Z]{2}[0-9]{6}$/;
    let vehicleRegx4 = /^[0-9]{3}[A-Z]{2}[0-9]{3}$/;
    let vehicleRegx5 = /^[0-9]{3}[A-Z]{2}[0-9]{1}$/;
    let vehicleRegx6 = /^[A-Z0-9]{10}$/;
    let vehicleRegx7 = /^[A-Z]{2}[0-9]{2}[A-Z]{3}[0-9]{4}$/;
    if (
      vehicleRegex1.test(this.props.vehicleForm?.VehicleRegistrationNumber) ||
      vehicleRegx2.test(this.props.vehicleForm?.VehicleRegistrationNumber) ||
      vehicleRegx3.test(this.props.vehicleForm?.VehicleRegistrationNumber) ||
      vehicleRegx4.test(this.props.vehicleForm?.VehicleRegistrationNumber) ||
      vehicleRegx5.test(this.props.vehicleForm?.VehicleRegistrationNumber) ||
      vehicleRegx6.test(this.props.vehicleForm?.VehicleRegistrationNumber) ||
      vehicleRegx7.test(this.props.vehicleForm?.VehicleRegistrationNumber)
    ) {
      this.setState({ isVehicleNoValid: true });
    } else {
      this.setState({
        isVehicleNoValid: false,
      });
    }
    if (this.props.vehicleForm?.type?.value) {
      this.setState(
        {
          isVehicleTypeValid: true,
        },
        () => {}
      );
    } else {
      this.setState(
        {
          isVehicleTypeValid: false,
        },
        () => {}
      );
    }

    if (
      (vehicleRegex1.test(this.props.vehicleForm?.VehicleRegistrationNumber) ||
        vehicleRegx2.test(this.props.vehicleForm?.VehicleRegistrationNumber) ||
        vehicleRegx3.test(this.props.vehicleForm?.VehicleRegistrationNumber) ||
        vehicleRegx4.test(this.props.vehicleForm?.VehicleRegistrationNumber) ||
        vehicleRegx5.test(this.props.vehicleForm?.VehicleRegistrationNumber) ||
        vehicleRegx6.test(this.props.vehicleForm?.VehicleRegistrationNumber) ||
        vehicleRegx7.test(this.props.vehicleForm?.VehicleRegistrationNumber)) &&
      this.props.vehicleForm?.type?.value
    ) {
      // console.log("Submit verified");
      this.props.submitVehicleForm();
    } else {
      // console.log("Submit not verified");
      NotificationManager.error("Please Check the Data Again", "Error");
    }
  };
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    var darkMode = this.props?.mode;
    const customStyles = {
      indicatorsContainer: (style) => {
        return {
          ...style,
          backgroundColor: darkMode ? "#2E2E46" : "#8e9af8",
          width: "54px",
          height: "37px",
          textAlign: "center",
          justifyContent: "center",
          borderRadius: "0px 4px 4px 0px",
        };
      },
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      indicatorSeparator: (style) => {
        return {
          ...style,
          backgroundColor: darkMode ? "#B9B9B9" : "#8e9af8",
        };
      },
      control: (style) => {
        return {
          ...style,
          height: "50px",
        };
      },
      menu: (style) => {
        return {
          ...style,
          backgroundColor: darkMode ? "#171722" : "#ffffff",
          color: darkMode ? "#B7A9FF" : "rgb(51, 51, 51)",
          opacity: darkMode ? "1" : "",
          border: darkMode
            ? "0.5px solid #616161"
            : "0.5px solid rgb(204, 204, 204)",
          boxShadow: darkMode
            ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
            : "0px 3px 12px rgba(0, 0, 0, 0.12)",
          // zIndex: "22px",
        };
      },
    };
    const customStyles1 = {
      indicatorsContainer: (style) => {
        return {
          ...style,
          backgroundColor: darkMode ? "#2E2E46" : "#8e9af8",
          width: "54px",
          height: "26px",
          textAlign: "center",
          justifyContent: "center",
          borderRadius: "0px 4px 4px 0px",
        };
      },
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      indicatorSeparator: (style) => {
        return {
          ...style,
          backgroundColor: darkMode ? "#B9B9B9" : "#8e9af8",
          margin: "4px",
        };
      },
      control: (style) => {
        return {
          ...style,
          height: "26px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          alignSelf: "start",
        };
      },
      menu: (style) => {
        return {
          ...style,
          backgroundColor: darkMode ? "#171722" : "#ffffff",
          color: darkMode ? "#B7A9FF" : "rgb(51, 51, 51)",
          opacity: darkMode ? "1" : "",
          border: darkMode
            ? "0.5px solid #616161"
            : "0.5px solid rgb(204, 204, 204)",
          boxShadow: darkMode
            ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
            : "0px 3px 12px rgba(0, 0, 0, 0.12)",
          // zIndex: "22px",
        };
      },
    };
    return (
      <React.Fragment>
        <MDBContainer className="text-capitalize">
          <MDBModal
            isOpen={this.props.isOpen}
            toggle={() => {
              this.setState(
                {
                  isVehicleNoValid: true,
                  isVehicleTypeValid: true,
                  isPhoneNoValid: true,
                },
                () => {
                  this.props.toggle();
                }
              );
            }}
            centered
            className="tripsDetailsModal AprovalModal modelDark"
          >
            {/* <i
              className="fas fa-times closemodal p-3 closeDark"
              onClick={() => {
                this.setState(
                  {
                    isVehicleNoValid: true,
                    isVehicleTypeValid: true,
                    isPhoneNoValid: true,
                  },
                  () => {
                    this.props.toggle();
                  }
                );
              }}
              className="modal-head"
            > </i> */}{" "}
            <Row
              style={{
                color: "#0C0C0C",
                position: "relative",
                left: "18px",
                top: "9px",
              }}
            >
              <Col xs="10">
                <div
                  className="fontRoboto light__span"
                  style={{
                    color: "#0C0C0C",
                  }}
                >
                  {/* {this.props.indentPageRoute
                    ? "Add Vehicle & Driver Details"
                    : "Edit Vehicle & Driver Details "} */}
                  {this.props.indentPageRoute
                    ? userLang?.trip?.ADD_VEHICLE_DRIVER_DETAILS
                    : userLang?.trip?.EDIT_VEHICLE_DRIVER_DETAILS}
                  {/* {userLang?.tracking?.ENTER} {userLang?.tracking?.DRIVER_VEHICLE} */}
                </div>
              </Col>
              <Col xs="2">
                <i
                  className="fas fa-times closemodalAproval closeDark p-3"
                  onClick={() => {
                    this.setState(
                      {
                        isVehicleNoValid: true,
                        isVehicleTypeValid: true,
                        isPhoneNoValid: true,
                      },
                      () => {
                        this.props.resetSelection();
                        this.props.toggle();
                      }
                    );
                  }}
                ></i>
              </Col>
              <Col xs="12" className="pb-2">
                <div
                  className="fontRoboto1541 pt-2"
                  style={{
                    color: "#05284E",
                  }}
                >
                  {this.props.indentPageRoute ? (
                    <div className="dark__span">
                      {userLang?.planning?.INDENT_ID} :{this.props?.indent_id}
                    </div>
                  ) : (
                    <div className="dark__span">
                      {userLang?.tracking?.TRACK_TRIP_ID} :
                      {this.props?.tripIdEditPop}
                    </div>
                  )}
                </div>{" "}
              </Col>
            </Row>
            <MDBModalBody>
              <Row>
                <Col lg="12" className="a-jumbo">
                  <FormGroup className="select2-container">
                    <Label className="control-label light__span fontRoboto15">
                      {userLang?.tracking?.VEHICLE_PLATE}
                    </Label>
                    <Select
                      value={this.props.vehicle}
                      isMulti={false}
                      onInputChange={this.props.filterVehicleList}
                      styles={customStyles}
                      onChange={(e) => {
                        this.props.onChange({
                          target: {
                            name: "vehicle",
                            value: e,
                          },
                        });
                      }}
                      options={this.props.options}
                      classNamePrefix="select2-selection"
                    />
                    {/* <RenderInputControl
                      controlType="select"
                      inputName="vehicleLicensePlate"
                      inputValue={this.state.reportoArr.find(
                        (x) => x.value === form.reportsTo
                      )}
                      
                     // onInputChange={this.filterList}
                      onChangeMethod={this.handleChange}
                      optionList={this.props.filterVehicleList}
                      handleScrollBottom={this.scrollSelectBottom}
                      labelValue="Vehicle License Plates"
                      isRequired={true}
                      handleMenuClose={this.menuClose}
                      //errors={formErrors}
                      errors={false}
                      //isLoading={loading}
                      isLoading={false}
                    /> */}
                  </FormGroup>
                  <div className="AddNewSpan addNewBg">
                    <Row>
                      <Col xs={11}>
                        <span
                          className="textHead ptL10"
                          style={{
                            color: "#2800FC",
                          }}
                        >
                          {userLang?.common?.ADD_NEW}{" "}
                        </span>
                      </Col>
                      <Col xs={1}>
                        <i
                          className={`fa fa-chevron-${this.state.chevronVehicle} bt AddNewSpanIcon actionDspan`}
                          onClick={() => this.vehicleDropDownChange()}
                        ></i>
                      </Col>
                    </Row>
                  </div>
                  {/* <span className="drop-down-custom">
                    {userLang?.common?.ADD_NEW}{" "}
                    <i
                      className={`fa fa-chevron-${this.state.chevronVehicle} bt`}
                      onClick={() => this.vehicleDropDownChange()}
                    ></i>
                  </span> */}
                  {this.state.vehicleDropDown ? (
                    <Col lg="12" className="jumbo AddDropDown addNewBg">
                      <Row>
                        <Col xs="5">
                          <FormGroup>
                            <Label className="control-label fontRoboto12 light__span ptL5">
                              {userLang?.tracking?.VEHCILE_REG_NO}{" "}
                              <span className="req">*</span>
                            </Label>
                            <Input
                              type="text"
                              value={
                                this.props.vehicleForm
                                  ?.VehicleRegistrationNumber
                              }
                              placeholder="Type here..."
                              onChange={(e) =>
                                this.props.onVehicleChange({
                                  target: {
                                    name: "VehicleRegistrationNumber",
                                    value: e.target.value,
                                  },
                                })
                              }
                              maxLength="11"
                            />
                            {!this.state.isVehicleNoValid && (
                              <span style={{ fontStyle: "12px", color: "red" }}>
                                {userLang?.trip?.PLEASE_ENTER_FORMAT}:
                                MH01AV4346,MH014465
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col xs="5">
                          <FormGroup>
                            <Label className="control-label fontRoboto12 light__span">
                              {userLang?.common?.VEHICLE_TYPE}{" "}
                              <span className="req">*</span>
                            </Label>
                            <Select
                              value={
                                this.props.vehicleForm?.type
                                  ? this.props.vehicleForm?.type
                                  : this.props.vehicleLabel
                              }
                              isMulti={false}
                              onInputChange={this.props.vehicleType}
                              styles={customStyles1}
                              onChange={(e) => {
                                this.props.onVehicleChange({
                                  target: {
                                    name: "vehicleType",
                                    value: e,
                                  },
                                });
                              }}
                              options={this.props.type}
                              classNamePrefix="select2-selection1"
                              placeholder="select..."
                            />
                            {!this.state.isVehicleTypeValid && (
                              <span style={{ fontStyle: "12px", color: "red" }}>
                                {userLang?.trip?.PLEASE_SELECT_TYPE}:
                                {this.props.toBeSelectedVehicleType?.label ??
                                  "CONTAINER 32 FT MXL 14.5 MT"}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col xs="2">
                          {/* <span className="driverSaveBtn">
                          <div className="marginSaveDiv"> */}
                          <MDBBtn
                            style={{
                              backgroundColor: "#05284E",
                            }}
                            color="primary"
                            className="jumboButton saveButtonPadding"
                            onClick={() => {
                              // console.log(
                              //   "under test",
                              //   this.props.vehicleForm?.type?.value,
                              //   this.props.toBeSelectedVehicleType?.value
                              // );
                              // if (
                              //   this.props.vehicleForm?.type?.value !=
                              //   this.props.toBeSelectedVehicleType?.value
                              // ) {
                              //   this.setState({
                              //     isVehicleTypeValid: false,
                              //   });
                              // } else {
                              //   this.setState({ isVehicleTypeValid: true });
                              // }

                              this.checkSubmitVehicleForm();
                            }}
                            disabled={this.props.disabledVehicle}
                          >
                            {" "}
                            {userLang?.common?.SAVE}
                          </MDBBtn>
                          {/* </div>
                        </span> */}
                        </Col>
                      </Row>
                    </Col>
                  ) : null}
                </Col>
                <Col lg="12">
                  <FormGroup className="select2-container ">
                    <Label className="control-label fontRoboto15 light__span">
                      {userLang?.trip?.DRIVER}
                    </Label>
                    <CreatableSelect
                      value={this.props?.driver ? this.props?.driver : ""}
                      onInputChange={this.props.filterDriverList}
                      styles={customStyles}
                      isMulti={false}
                      onChange={(e) => {
                        this.props.onChange({
                          target: {
                            name: "driver",
                            value: e,
                          },
                        });
                      }}
                      options={this.props.optionsdriver}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                  <div className="AddNewSpan addNewBg">
                    <Row>
                      <Col xs={11}>
                        <span
                          className="textHead ptL10"
                          style={{
                            color: "#2800FC",
                          }}
                        >
                          {userLang?.common?.ADD_NEW}{" "}
                        </span>
                      </Col>
                      <Col xs={1}>
                        <i
                          className={`fa fa-chevron-${this.state.chevronDriver} bt AddNewSpanIcon actionDspan`}
                          onClick={() => this.driverDropDownChange()}
                        ></i>
                      </Col>
                    </Row>
                  </div>
                  {/* <span className="drop-down-custom">
                    {userLang?.common?.ADD_NEW}{" "}
                    <i
                      className={`fa fa-chevron-${this.state.chevronDriver} bt`}
                      onClick={() => this.driverDropDownChange()}
                    ></i>
                  </span> */}
                  {this.state.driverDropDown ? (
                    <Col lg="12" className="jumbo addNewBg">
                      <Row>
                        <Col xs="5">
                          <FormGroup>
                            <Label className="control-label fontRoboto12 light__span ptL5">
                              {userLang?.masters?.PHONE}{" "}
                              <span className="req">*</span>
                            </Label>
                            <Input
                              value={this.props.driverForm?.phone}
                              name="phone"
                              placeholder="Type here..."
                              onChange={(e) =>
                                this.props.onDriverChange({
                                  target: {
                                    name: "phone",
                                    value: e.target.value,
                                  },
                                })
                              }
                              maxLength="10"
                            />
                            {!this.state.isPhoneNoValid && (
                              <span style={{ fontStyle: "12px", color: "red" }}>
                                {userLang?.trip?.PLEASE_ENTER_FORMAT}:
                                9988776655
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col xs="5">
                          <FormGroup>
                            <Label className="control-label fontRoboto12 light__span">
                              <span>{userLang?.common?.FULL_NAME}</span>
                            </Label>
                            <Input
                              value={this.props.driverForm?.fullName}
                              name="fullName"
                              placeholder="Type here..."
                              onChange={(e) =>
                                this.props.onDriverChange({
                                  target: {
                                    name: "fullName",
                                    value: e.target.value,
                                  },
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="2">
                          <MDBBtn
                            style={{
                              backgroundColor: "#05284E",
                            }}
                            color="primary"
                            className="jumboButton saveButtonPadding"
                            onClick={() => {
                              if (
                                !this.props.driverForm?.phone?.match(
                                  /^[0-9]{10}$/
                                )
                              ) {
                                this.setState({ isPhoneNoValid: false });
                                return false;
                              } else {
                                this.setState(
                                  {
                                    isPhoneNoValid: true,
                                  },
                                  () => {
                                    this.props.submitDriverForm();
                                  }
                                );
                              }
                            }}
                            disabled={this.props.disabledDriver}
                          >
                            {" "}
                            {userLang?.common?.SAVE}
                          </MDBBtn>
                        </Col>
                      </Row>
                      <Row>
                        {/* <Col lg="6">
                         <FormGroup>
                            <Label className="control-label">
                              <span>{userLang?.common?.DRIVER_LICENSE_NO}</span>
                            </Label>
                            <Input
                              value={this.props.driverForm?.driverLicenseNo}
                              name="driverLicenseNo"
                              placeholder="Type here..."
                              onChange={(e) =>
                                this.props.onDriverChange({
                                  target: {
                                    name: "driverLicenseNo",
                                    value: e.target.value,
                                  },
                                })
                              }
                            />
                          </FormGroup>
                        </Col> */}
                      </Row>
                    </Col>
                  ) : null}
                </Col>
                {/* <Col lg="12">
                  <FormGroup className="select2-container">
                    <Label className="control-label ">Driver Contact</Label>
                    <Input
                      name="driverContact"
                      value={""}
                      placeholder="Type...."
                      onChange={(e) => {
                        this.props.onChange({
                          target: {
                            name: "driverContact",
                            value: e,
                          },
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup className="select2-container">
                    <Label className="control-label ">Driver License</Label>
                    <Input
                      name="driverLicense"
                      value={""}
                      placeholder="Type...."
                      onChange={(e) => {
                        this.props.onChange({
                          target: {
                            name: "driverLicense",
                            value: e,
                          },
                        });
                      }}
                    />
                  </FormGroup>
                </Col> */}
              </Row>
            </MDBModalBody>
            <Row
              style={{
                position: "relative",
                left: "17px",
                paddingTop: "10px",
              }}
            >
              <Col xs="3">
                <MDBBtn
                  className="fontRoboto1541"
                  style={{
                    width: "100%",
                    height: "50px",
                    backgroundColor: "#0AB38C",
                    color: "#FFFFFF",
                  }}
                  color="secondary"
                  onClick={() => this.props.handleResetEdit()}
                >
                  {userLang?.common?.RESET}
                </MDBBtn>
              </Col>
              <Col xs="3">
                <MDBBtn
                  className="fontRoboto1541"
                  style={{
                    width: "100%",
                    height: "50px",
                    backgroundColor: "#05284E",
                    color: "#FFFFFF",
                  }}
                  color="primary"
                  onClick={this.handleSubmit}
                  disabled={this.state.isSubmitting}
                >
                  {this.state.buttonContent.length > 0
                    ? this.state.buttonContent
                    : userLang?.common?.SUBMIT}
                </MDBBtn>
              </Col>
              <Col xs="6"></Col>
            </Row>
            <MDBModalFooter></MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    indents: state.Indent.indents.assignedIndents,
    trips: state.Trip.trips.trips,
    policies: state.AccessPolicy.accessPolicies.policies,
  };
};

export default connect(mapStateToProps, {
  finalizeIndent,
  updateTrip,
})(ApproveModal);
