import React, { Component } from "react";
import {
  Row,
  Col,
  Alert,
  Button,
  Container,
  Label,
} from "reactstrap";

import EditIcon from "@material-ui/icons/Edit";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import { AvForm } from "availity-reactstrap-validation";

// action
import { verifyOtp, sendOtp, sendNewPassword } from "../../../store/actions";

// import images
// import logo from "../../assets/images/common-logo.png";

import Fields from "./Fields";

const logo = require("../../../assets/images/" +
  process.env.REACT_APP_LOGO_IMAGE);

class ForgetPasswordLayout extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white"></i>
          </Link>
        </div>
        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col lg={4}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="/" className="logo">
                                <img src={logo} height="40" alt="logo" />
                              </Link>
                            </div>

                            <h4 className="font-size-18 mt-4">
                              Reset Password
                            </h4>
                            <p className="text-muted">
                              {/* Reset your password to CloudTrack */}
                              Reset your password to TMS
                            </p>
                          </div>

                          <div className="p-2 mt-5">
                            {this.props.forgetError &&
                            this.props.forgetError ? (
                              <Alert color="danger" className="mb-4">
                                {this.props.forgetError}
                              </Alert>
                            ) : null}
                            {this.props.message ? (
                              <Alert color="success" className="mb-4">
                                {this.props.message}
                              </Alert>
                            ) : null}
                            {this.props.State.error ? (
                              <Alert color="danger" className="mb-4">
                                {this.props.State.error}
                              </Alert>
                            ) : null}
                            {!this.props.State.passwordFields ? (
                              !this.props.State.otpEnable ? (
                                <AvForm
                                  className="form-horizontal"
                                  onValidSubmit={this.props.handleValidSubmit}
                                >
                                  <Fields
                                    Label="Phone"
                                    name="phone"
                                    value={this.props.State.phone}
                                    errorMessage="Enter valid phone"
                                    validate={{
                                      required: { value: true },
                                      pattern: {
                                        value: "^[0-9]+$",
                                      },
                                    }}
                                    placeholder="Enter Phone number"
                                  />

                                  <div className="mt-4 text-center">
                                    <Button
                                      color="primary"
                                      disabled={this.props.State.btndisable}
                                      className="w-md waves-effect waves-light"
                                      type="submit"
                                    >
                                      Get OTP
                                    </Button>
                                  </div>
                                </AvForm>
                              ) : (
                                <>
                                  <Label>
                                    OTP sent to {this.props.State.phone}
                                  </Label>
                                  &nbsp;&nbsp;
                                  <EditIcon onClick={this.props.handleBack} />
                                  <AvForm
                                    className="form-horizontal"
                                    onValidSubmit={this.props.handleOtpSubmit}
                                  >
                                    <Fields
                                      Label="OTP"
                                      name="otp"
                                      value={this.props.State.otp}
                                      errorMessage="Enter valid OTP"
                                      validate={{
                                        required: { value: true },
                                        pattern: {
                                          value: "^[0-9]+$",
                                        },
                                      }}
                                      placeholder="Enter OTP"
                                    />
                                    <div className="mt-4 text-center">
                                      <Button
                                        color="primary"
                                        disabled={this.props.State.btndisable}
                                        className="w-md waves-effect waves-light"
                                        type="submit"
                                      >
                                        Submit
                                      </Button>
                                    </div>
                                  </AvForm>
                                </>
                              )
                            ) : (
                              <AvForm
                                className="form-horizontal"
                                onValidSubmit={this.props.handlePasswordSubmit}
                              >
                                <Fields
                                  Label="Password"
                                  name="password"
                                  type="passowrd"
                                  value={this.props.State.password}
                                  errorMessage="Password required"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  placeholder="Enter Password"
                                />

                                <Fields
                                  Label="Confirm Password"
                                  name="cpassword"
                                  type="passowrd"
                                  value={this.props.State.cpassword}
                                  errorMessage="Confirm password required"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  placeholder="Enter Confirm Password"
                                />
                                <div className="mt-4 text-center">
                                  <Button
                                    color="primary"
                                    disabled={this.props.State.btndisable}
                                    className="w-md waves-effect waves-light"
                                    type="submit"
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </AvForm>
                            )}
                          </div>
                          <div className="mt-4 text-center">
                            <Link to="/login" className="text-muted">
                              <i className="mdi mdi-lock mr-1"></i> Login
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className="authentication-bg">
                  <div className="bg-overlay"></div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { message, forgetError, loading } = state.Forget;
  return { message, forgetError, loading };
};

export default withRouter(
  connect(mapStatetoProps, { verifyOtp, sendOtp, sendNewPassword })(
    ForgetPasswordLayout
  )
);
