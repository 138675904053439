let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: "Trip Id",
        field: "tripId",
        sort: "asc",
        width: 1,
      },
      {
        label: "Order No",
        field: "orderNumber",
        sort: "asc",
        width: 2,
      },
      {
        label: "Warehouse",
        field: "businessUnit",
        sort: "asc",
        width: 2,
      },
      {
        label: "Invoice Number",
        field: "legalInvoiceNo",
        sort: "asc",
        width: 2,
      },
      {
        label: "Transporter",
        field: "transporter",
        sort: "asc",
        width: 2,
      },
      {
        label: "Order Type",
        field: "orderType",
        sort: "asc",
        width: 2,
      },
      {
        label: "Bill to Name",
        field: "billedToName",
        sort: "asc",
        width: 2,
      },
      {
        label: "Bill To City",
        field: "billedToCity",
        sort: "asc",
        width: 2,
      },
      {
        label: "Vehicle",
        field: "vehicleNo",
        sort: "asc",
        width: 2,
      },
      // {
      //   label: "Driver",
      //   field: "driverNo",
      //   sort: "asc",
      //   width: 1,
      // },
      // {
      //   label: "Status",
      //   field: "status",
      //   sort: "asc",
      //   width: 2,
      // },
      {
        label: "Status",
        field: "substatus",
        sort: "asc",
        width: 2,
      },
      {
        label: "EWaybill",
        field: "eWayBillNo",
        sort: "asc",
        width: 2,
      },
      {
        label: "Dispatch Date",
        field: "dispatchDate",
        sort: "asc",
        width: 1,
      },
      {
        label: "Action",
        field: "actions",
        sort: "asc",
        width: 1,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var AdminHeaderData = {
  columns: [
    {
      label: "Trip Id",
      field: "tripId",
      sort: "asc",
      width: 1,
    },
    {
      label: "Order No",
      field: "orderNumber",
      sort: "asc",
      width: 1,
    },
    {
      label: "Warehouse",
      field: "businessUnit",
      sort: "asc",
      width: 2,
    },
    {
      label: "Transporter",
      field: "transporter",
      sort: "asc",
      width: 1,
    },
    {
      label: "Vehicle",
      field: "vehicleNo",
      sort: "asc",
      width: 1,
    },
    {
      label: "Driver",
      field: "driverNo",
      sort: "asc",
      width: 1,
    },
    // {
    //   label: "Status",
    //   field: "status",
    //   sort: "asc",
    //   width: 1,
    // },
    {
      label: "Substatus",
      field: "substatus",
      sort: "asc",
      width: 1,
    },
    {
      label: "EWaybill",
      field: "eWayBillNo",
      sort: "asc",
      width: 1,
    },
    {
      label: "Dispatch Date",
      field: "dispatchDate",
      sort: "asc",
      width: 1,
    },
    {
      label: "Action",
      field: "action",
      sort: "asc",
      width: 2,
    },
  ],
};
export var searchBar;

searchBar = {
  searchBarData: [
    {
      label: "Trip Id",
      field: "Trip Id",
      type: "text",
    },
    {
      label: "Order No",
      field: "Order Number",
      type: "text",
    },
    {
      label: "Warehouse",
      field: "Warehouse",
      type: "text",
    },
    {
      label: "Invoice No",
      field: "Legal Invoice No",
      type: "text",
    },
    {
      label: "Transporter",
      field: "transporter",
      type: "text",
    },
    // {
    //   label: "order Co",
    //   field: "Order Co",
    //   type: "text",
    // },
    {
      label: "Order Type",
      field: "Order Type",
      type: "text",
    },
    {
      label: "Billed To Name",
      field: "billed To Name",
      type: "text",
    },
    {
      label: "Billed To City",
      field: "billed To City",
      type: "text",
    },
    {
      label: "Vehicle",
      field: "Vehicle",
      type: "text",
    },
    {
      label: "Driver",
      field: "Driver",
      type: "text",
    },

    // {
    //   label: "Status",
    //   field: "Status",
    //   type: "select",
    // },
    {
      label: "Substatus",
      field: "SubStatus",
      type: "select",
    },
    {
      label: "EWay Bill No",
      field: "EWay Bill No",
      type: "text",
    },
    {
      label: "Trip Type",
      field: "tripType",
      type: "select",
    },
    {
      label: "Trip Without Eway Bill",
      field: "Is Ewaybill Not Exist",
      type: "select",
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      type: "text",
    },
  ],
};
