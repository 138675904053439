import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import AccessPolicysReducer from './reducer';
const AccessPolicy = combineReducers({
  accessPolicies: AccessPolicysReducer,
  form: formReducer
});


export default AccessPolicy;
