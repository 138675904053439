import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import OrderReducer from './reducer';
const Order = combineReducers({
  orders: OrderReducer,
  form: formReducer
});


export default Order;
