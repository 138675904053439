import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Button,
} from "reactstrap";
import { connect } from "react-redux";
import QuickFilters from "./QuickFilters"
import TrackingMap from "../TrackingMap";
import img from "../../assets/images/download.jpg";
import ToggleAddAddressModal from "./AddressModal"
// import { fetchVehicleTypesList } from "../../store/vehicleType/actions";
// import Autocomplete from "./Autocomplete"
import { NotificationManager } from "react-notifications";
// import { HexColorPicker } from "react-colorful";


const leftArrowImage = require("../../assets/images/navigation/left arrow icon.svg");
const rightArrowImage = require("../../assets/images/navigation/right arrow icon.svg");
const upArrowImage = require("../../assets/images/navigation/upArrow.svg");
const downArrowImage = require("../../assets/images/navigation/downArrow.svg");
const sourceImg = require("../../assets/images/navigation/address.svg");
// const closeImg = require("../../assets/images/navigation/closeIcon.svg");

const routeOptimizationUrl = process.env.REACT_APP_ROUTE_OPTIMIZATION_URL
// console.log(routeOptimizationUrl)

class RouteOptimization extends Component {
  constructor(props) {
    super(props)
    this.state = {
      vehicleRoutes: [],
      openModal: true,
      addressModal: false,
      openDropdown: false,
      vehicleList: [],

      form: {
        vehicleNo: "",
        source: {
          source: "",
          sourceAlias: "",
          srcLat: "",
          srcLng: ""
        },
        destination: {
          destination: "",
          destinationAlias: "",
          destLat: "",
          destLng: ""
        },
        destinationList: []
      },

      srcChange: false,
      destChange: false,
      resetFlag: false,
      allLocations: [],
      lineCoordinates: [],
      isVehicleSelected: false,
      vehicleSelected: [],
      showPicker: false,


    }
  }
  randomColor = () => {
    
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    

  }
  calcCrow = (lat1, lon1, lat2, lon2) => {
    var R = 6371;
    var dLat = this.toRad(lat2 - lat1);
    var dLon = this.toRad(lon2 - lon1);
     lat1 = this.toRad(lat1);
     lat2 = this.toRad(lat2);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }
  toRad = (Value) => {
    return (Value * Math.PI) / 180;
  }
  submit = () => {
    let form = this.state.form
    if (form.vehicleNo !== "" && form.source.source !== "" && form.destinationList.length) {
      let totalVehicles = this.state.form.vehicleNo;
      let source = this.state.form.source.srcLat + "," + this.state.form.source.srcLng;
      let destinationList = this.state.form.destinationList
      let destinations = destinationList.map((e) => {
        let latlng = e.destLat + "," + e.destLng
        return latlng
      })
      let allLocations = [source, ...destinations]

      this.setState({
        allLocations: allLocations,
      })

      let distanceMatrixArray = [], distanceMatrixArrayParsed;

      for (let i = 1; i <= allLocations.length; i++) {
        let distanceArray = [];
        let srcLat = allLocations[i - 1].split(",")[0];
        let srcLng = allLocations[i - 1].split(",")[1];
        for (let k of allLocations) {
          let destLat = k.split(",")[0];
          let destLng = k.split(",")[1];
          let distance = this.calcCrow(srcLat, srcLng, destLat, destLng).toFixed(2);
          distanceArray.push(parseInt(distance));
        }
        distanceMatrixArray.push(distanceArray);
        distanceMatrixArrayParsed = JSON.stringify(distanceMatrixArray);
        distanceMatrixArrayParsed = distanceMatrixArrayParsed.replaceAll(
          "],[",
          "]|["
        );
      }
      let body = {
        totalVehicles: totalVehicles,
        distanceMatrixArrayParsed: distanceMatrixArrayParsed
      }
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST,PATCH,OPTIONS'
      }

      fetch(routeOptimizationUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body)
      })
        .then((data) => {
          data.json().then((info) => {
            this.receiveData(info)
            this.toggleAddressModal()
          })
            .catch((e) => console.log(e));
        })
        .catch((err) => console.log(err));
    }
    else {
      NotificationManager.error("Please add complete information", "failure");
    }
  }
  getAllLocationsInfo = () => {
    let source = [{ "label": "source", "city": this.state.form.source.source, "lat": this.state.form.source.srcLat, "lng": this.state.form.source.srcLng, }]
    let destinations = {};
    let destinationList = this.state.form.destinationList
    destinationList.forEach((item, index) => {
      let i = index + 1;
      destinations[i] = { "label": "destination", "city": item.destination, "lat": item.destLat, "lng": item.destLng, }
    })
    this.setState({ allLocationInfo: { ...source, ...destinations } }, () => {
      // console.log(this.state.allLocationInfo)
    })
  }
  receiveData = (info) => {
    this.getAllLocationsInfo()

    let vehicleList = info.vrp.map((item, index) => {
      let vehicleListObj = {}
      let list = item.split("\n")
      vehicleListObj["distance"] = list[2]
      list[1] = list[1].replaceAll(" ", "")
      let path = list[1].split("->");
      const convertedPath = path.map((i) => Number(i));
      vehicleListObj["path"] = convertedPath
      vehicleListObj["color"] = this.randomColor()
      vehicleListObj["openDropdown"] = false
      let linePath = []
      for (let i = 0; i < path.length - 1; i++) {
        linePath.push(this.state.allLocations[path[i]])
      }
      vehicleListObj["selected"] = false
      vehicleListObj["linePath"] = JSON.stringify(linePath);
      vehicleListObj["openPicker"] = false;
      return vehicleListObj
    })
    this.setState({ vehicleList: vehicleList }, () => {
      // console.log(this.state.vehicleList)
    })
    this.getDirections(this.state.vehicleList)
  }
  getDirections = (list) => {
    this.setState({ lineCoordinates: [] })
    list.map((item, index) => {
      let route = item.linePath
      route = route.replace('["', "").replace('"]', "");
      route = route.split('","');
      this.initializeMap(route, item.color);
      return true;
    })
  }
  initializeMap = (route, color) => {
    let latLng = route.map((item) => {
      let coords = item.split(",")
      return (new window.google.maps.LatLng(parseFloat(coords[0]), parseFloat(coords[1])))
    })
    let waypoints = latLng.map((item, index) => {
      return {
        "location": item,
        "stopover": true,
      }
    })
    var directionsService = new window.google.maps.DirectionsService();
    var request = {
      origin: latLng[0],
      destination: latLng[latLng.length - 1],
      waypoints: waypoints,
      travelMode: window.google.maps.DirectionsTravelMode.DRIVING,
    };
    let overViewCoords;
    directionsService.route(request, function (response, status) {
      if (status === window.google.maps.DirectionsStatus.OK) {
        overViewCoords = response.routes[0].overview_path;
        // console.log(overViewCoords)
      }
    }).then(() => {
      let item = {
        "color": color,
        "lines": overViewCoords
      }
      this.setState({
        lineCoordinates: [...this.state.lineCoordinates, item],

      }, () => {
        // console.log(this.state.lineCoordinates)
      });

    });
  }
  toggleAddressModal = () => {
    this.setState(
      {
        addressModal: !this.state.addressModal,
      }
    );
  };

  changeResetFlag = () => {
    this.setState({ resetFlag: false })
  }

  handleChange = (item, type) => {
    let formData = this.state.form
    if (type === "vehicle") {
      this.setState({ form: { ...this.state.form, vehicleNo: item } }, () => {
        // console.log(this.state.form)
      })
    }
    else if (type === "sourceAlias") {
      formData["source"].sourceAlias = item
      this.setState({ form: formData }, () => {
        // console.log(this.state.form)
      })
    }
    else if (type === "destinationAlias") {
      formData["destination"].destinationAlias = item
      this.setState({ form: formData }, () => {
        // console.log(this.state.form)
      })
    }
    else if (type === "source") {
      formData["source"] = item
      this.setState({ form: formData, srcChange: true }, () => {
        // console.log(this.state.form)
      })
    }
    else if (type === "destination") {
      formData["destination"] = item
      this.setState({ form: formData }, () => {
        // console.log(this.state.form)
      })
    }

  }
  removeDestination = (item) => {
    // console.log(item)
    let destinationList = this.state.form.destinationList
    let index;
    for (let i = 0; i < destinationList.length; i++) {
      if (destinationList[i].destLat === item.destLat && destinationList[i].destLng === item.destLng) {
        index = i;
        break;
      }
    }
    destinationList.splice(index, 1)
    // let formData = this.state.form.slice(0)
    // formData["destinationList"] = destinationList
    this.setState({ form: { ...this.state.form, destinationList }, destChange: true })

  }

  addDestination = () => {
    let form = Object.assign({}, this.state.form)
    if (form["destination"].destinationAlias === "" || form["destination"].destination === "") {
      NotificationManager.error("Please add complete destination", "failure");
    }
    else {
      let destinationList = form.destinationList
      let destinationMatch = destinationList.find((item) => {
        if (item.destLat === form["destination"].destLat && item.destLng === form["destination"].destLng)
          return item
      })
      if (destinationMatch === undefined) {
        form["destinationList"].push(form.destination)
        form["destination"] = {
          destination: "",
          destinationAlias: "",
          destLat: "",
          destLng: ""
        }
        this.setState({ form: form, destChange: true })
        NotificationManager.success("Destination Added Successfully", "Success");


      }
      else {
        form["destination"] = {
          destination: "",
          destinationAlias: "",
          destLat: "",
          destLng: ""
        }
        this.setState({ form: form }, () => {
          // console.log(this.state.form)
        })
        NotificationManager.error("Destination already exists, select other one", "failure");

      }
    }
  }
  reset = () => {
    this.setState({
      resetFlag: true,
      srcChange: false,
      destChange: false,
      vehicleList: [],

      form: {
        vehicleNo: "",
        source: {
          source: "",
          sourceAlias: "",
          srcLat: "",
          srcLng: ""
        },
        destination: {
          destination: "",
          destinationAlias: "",
          destLat: "",
          destLng: ""
        },
        destinationList: []
      },
      allLocations: [],
      lineCoordinates: [],
      isVehicleSelected: false,
      vehicleSelected: []
    })
  }
  resetSelection = () => {
    let list = this.state.vehicleList
    list.map((item) => {
      item["selected"] = false
      return true
    })
    this.setState({ vehicleList: list, isVehicleSelected: false, vehicleSelected: [] }, () => {
      this.getDirections(this.state.vehicleList)
    })

  }
  selectVehicle = (item, index) => {
    // debugger
    if (this.state.isVehicleSelected) {
      if (item.color === this.state.vehicleSelected[0].color) {
        let list = this.state.vehicleList
        list[index].selected = false
        this.setState({
          isVehicleSelected: false,
          vehicleSelected: [],
          vehicleList: list
        }, () => {
          this.getDirections(this.state.vehicleList)
        })

      }
      else {
        let list = this.state.vehicleList
        list.find((item) => {
          if (item.color === this.state.vehicleSelected[0].color)
            item["selected"] = false
        })
        list[index].selected = true
        this.setState({
          isVehicleSelected: true,
          vehicleSelected: [item],
          vehicleList: list
        }, () => {
          this.getDirections(this.state.vehicleSelected)
        })

      }
    }
    else {
      let list = this.state.vehicleList
      list[index].selected = true
      this.setState({
        isVehicleSelected: true,
        vehicleSelected: [item],
        vehicleList: list
      }, () => {
        this.getDirections(this.state.vehicleSelected)
      })

    }
  }
  render() {
    // console.log("ISVEHCLESELECTED", this.state.isVehicleSelected)
    // let mode = localStorage.getItem("Theme")
    //   ? localStorage.getItem("Theme")
    //   : null;
    //  var darkMode = mode === "dark__mode" ? true : false;


    // let authUser = JSON.parse(localStorage.getItem("authUser"));


    return (
      <React.Fragment>
        <div>
          <QuickFilters
            toggleAddressModal={this.toggleAddressModal}
          />


          <Row>
            <Col xs={12}>
              <div className="mapFullHeightDiv">
                <div className="trackingBackground">
                  <TrackingMap
                    id={"RouteOptimization"}
                    detailToggler={() => { }}
                    handleVehicleHistory={() => { }}
                    source={this.state.form.source}
                    destinationList={this.state.form.destinationList}
                    resetFlag={this.state.resetFlag}
                    changeResetFlag={this.changeResetFlag}
                    srcChange={this.state.srcChange}
                    destChange={this.state.destChange}
                    lineCoordinates={this.state.lineCoordinates}
                  />
                  <img
                    src={rightArrowImage}
                    onClick={() => {
                      this.setState({ openModal: !this.state.openModal });
                    }}
                    className="mapRightArrowImg"
                    alt=""
                  />



                  {this.state.openModal && (
                    <>
                      {this.state.vehicleList.length > 0 ? (

                        <div className="trackingAbosulteRoute">
                          <div className="d-flex justify-content-start">
                            <Button
                              className="buttonStyle"
                              style={{
                                minWidth: "30%",
                                marginBottom: "5px"
                              }}
                              onClick={() => {
                                this.resetSelection()
                              }}
                            >
                              Reset
                            </Button>
                          </div>

                          {/* //show vehicle list */}
                          {this.state.vehicleList.map((item, index) => {
                            return (
                              <>

                                <div className="containerDiv" style={item.selected ? { border: "1px solid #2800FC", cursor: "pointer" } : { border: "none", cursor: "pointer" }}
                                  onClick={() => {
                                    this.selectVehicle(item, index)
                                  }}
                                >
                                  <div>
                                    <div className="titleClass">Vehicle-{index + 1}</div>
                                    <div className="subtitleClass" style={{ marginTop: "3px" }}>
                                      Color of the route is :

                                      <div style={{
                                        float: "right", position: "relative",
                                        right: "-4px",
                                        top: "-6px",
                                      }} onClick={($event) => {
                                        $event.stopPropagation()
                                      }}>
                                        <input type="color" value={item.color} onChange={(e) => {
                                          let list = this.state.vehicleList.slice()
                                          list[index].openPicker = !list[index].openPicker
                                          list[index].color = e.target.value
                                          // console.log("LIST", list)
                                          this.setState({ vehicleList: list });
                                        }}
                                          onBlur={(e) => {
                                            this.state.isVehicleSelected ? this.getDirections(this.state.vehicleSelected) : this.getDirections(this.state.vehicleList)
                                          }}
                                        />
                                      </div>

                                    </div>
                                    {/* <div className="subtitleClass">{item.passDistance}</div> */}
                                  </div>
                                  <div>
                                    <img
                                      src={item.openDropdown ? upArrowImage : downArrowImage}
                                      onClick={($event) => {
                                        let list = this.state.vehicleList
                                        list[index].openDropdown = !list[index].openDropdown
                                        // console.log(list)
                                        this.setState({ vehicleList: list });
                                        $event.stopPropagation()
                                      }}
                                      alt=""
                                    />
                                  </div>
                                </div>
                                {
                                  item.openDropdown && (
                                    <div style={{ maxHeight: "300px", overflowY: "auto", padding: "10px" }}>
                                      {item.path.map((item, index) => {
                                        return (
                                          <Card style={{
                                            padding: "10px",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            flexDirection: "row"
                                          }}>
                                            <div>
                                              <div>
                                                <label className="titleClass">{this.state.allLocationInfo[item].label} - {this.state.allLocationInfo[item].city}</label>

                                                <img className="sourceImgStyle" src={sourceImg} alt=""/>
                                              </div>
                                              <label className="subtitleClass"> {this.state.allLocationInfo[item].lat},{this.state.allLocationInfo[item].lng}</label>


                                            </div>
                                            <div>
                                            </div>
                                          </Card>
                                        )
                                      })}
                                    </div>
                                  )
                                }
                              </>
                            )
                          })}
                        </div>
                      ) : (
                        <>
                          {/* //show click to add vehicle */}
                          <div className="trackingAbosulteRoute" onClick={() => { this.toggleAddressModal() }}>
                            <div className="containerDiv1" >
                              <div className="titleClass">* Click to add address</div>
                              <div className="subtitleClass">to add stops to your route</div>
                            </div>
                          </div>
                        </>
                      )}
                      <img
                        src={leftArrowImage}
                        onClick={() => {
                          this.setState({ openModal: false }, () => {
                            // this.reset();
                          });
                        }}

                        className="mapLeftArrowImg"
                        alt=""
                      />

                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          {this.state.addressModal &&
            <ToggleAddAddressModal
              isOpen={this.state.addressModal}
              toggle={this.toggleAddressModal}
              form={this.state.form}
              handleChange={this.handleChange}
              addDestination={this.addDestination}
              removeDestination={this.removeDestination}
              reset={this.reset}
              srcChange={this.state.srcChange}
              destChange={this.state.destChange}
              resetFlag={this.state.resetFlag}
              changeResetFlag={this.changeResetFlag}
              submit={this.submit}
            />
          }

        </div >
      </React.Fragment >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {

})(RouteOptimization);


