export const processMapLocations = (data) => {
  let lat;
  let lng;
  
  let locationArray = data.data.reverse();
  
  let latestLocation = locationArray[0];
  
  let recentLocations = locationArray.slice(0, 10);
 
  let coordinateArray = locationArray.map((ele) => {
  
    lat = parseFloat(ele.coordinates.lat);
    lng = parseFloat(ele.coordinates.lng);
  
    return { lat: lat, lng: lng };
  });
  let recentLocationsArray  = recentLocations.map((ele)=>{
    lat = parseFloat(ele.coordinates.lat);
    lng = parseFloat(ele.coordinates.lng);
    return { lat: lat, lng: lng };
  })
  let locationObject = {
    latestLocation,
    recentLocations,
    coordinateArray,
    recentLocationsArray
  };
  return locationObject;
};
