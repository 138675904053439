import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Card,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import { WaveLoader } from "../../components/Common/Loader/LoaderIndex";
import { updateMobileOnTripTrack } from "../../store/baxter-trip/actions";

class UpdatePhoneOnTrack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoading: false,
      driverPhone: "",
      errorMessage: "",
      tripId: "",
    };
  }
  componentDidUpdate = (prevProps) => {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.setState({
        driverPhone: this.props.driverPhone,
        tripId: this.props?.tripId,
      });
    }
  };

  onChangeInput = (name, val) => {
    const phoneRegex = /^\d{10}$/; // regular expression to match 10 digit phone number
    this.setState(
      {
        [name]: val,
      },
      () => {
        if (!phoneRegex.test(val)) {
          this.setState({ errorMessage: "Please enter a valid phone number" });
        } else {
          this.setState({ errorMessage: "" });
        }
      }
    );
  };
  reset = () => {
    this.setState({ driverPhone: "" ,errorMessage:""});
  };
  handleSubmit = () => {
    const { tripId, driverPhone, errorMessage } = this.state;
    let payload = {
      tripId: tripId ? tripId : "",
      mobileNo: driverPhone ? driverPhone : "",
    };
    if (!errorMessage) {
      this.setState({ dataLoading: true });
      this.props.updateMobileOnTripTrack(payload, (res) => {
        if (res.success) {
          NotificationManager.success(res["message"], "Success");
          this.setState({ dataLoading: false });
          this.props.data();
          this.props.toggleModel();
        } else {
          NotificationManager.error(res["message"], "Error");
          this.setState({ dataLoading: false });
        }
      });
    }
  };
  render() {
    const { driverPhone, errorMessage, dataLoading } = this.state;
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggleModel}
          size="lg"
          centered
          className="CreateTripModal"
          style={{ maxWidth: "48%" }}
        >
          <ModalHeader>
            <div className="Heading">
              <h4 className="fontRoboto">Update Driver Phone</h4>
              <svg
                onClick={() => this.props.toggleModel()}
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.9092 1.82141L1.90918 13.8214"
                  stroke="#2800FC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.90918 1.82141L13.9092 13.8214"
                  stroke="#2800FC"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </ModalHeader>

          <ModalBody>
            <Card className="p-1">
              {dataLoading ? (
                <WaveLoader />
              ) : (
                <div>
                  <>
                    <Row className="mt-2">
                      <Col md="12">
                        <FormGroup>
                          <Label
                            htmlFor="example-text-input"
                            className="fontRoboto154 mb-2"
                          >
                            Driver Phone Number
                          </Label>
                          <Input
                            name="driverPhone"
                            placeholder=" Driver Phone Number"
                            type="phone"
                            value={this.state.driverPhone}
                            defaultValue={this.props.driverPhone}
                            className="form-control-lg"
                            id="exampleFormControlInput1"
                            onChange={(e) =>
                              this.onChangeInput("driverPhone", e.target.value)
                            }
                          />
                          {errorMessage && (
                            <p className="error">{errorMessage}</p>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Button
                      color="primary"
                      // type="submit"
                      className="mt-4"
                      onClick={this.handleSubmit}
                    >
                      Confirm
                    </Button>
                    <Button
                      color="success"
                      // type="submit"
                      className="mt-4 ml-2"
                      onClick={() => this.reset()}
                    >
                      Reset
                    </Button>
                  </>
                </div>
              )}
            </Card>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default connect(null, {
  updateMobileOnTripTrack,
})(UpdatePhoneOnTrack);
