let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

export const getLocalStorage=()=>{
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  HeaderData = {
    columns: [
      {
        label: userLang?.common?.INDENT,
        field: "indentId",
        sort: "asc",
        width: 1,
    },
      {
        label: userLang?.common?.SOURCE,
        field: "source",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.DESTINATION,
        field: "destination",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.ROUTE,
        field: "route",
        sort: "asc",
        width: 3,
      },
      {
        label: userLang?.common?.VEHICLE,
        field: "vehicle",
        sort: "asc",
        width: 3,
      },
  
      {
        label: userLang?.common?.START_TIME,
        field: "startTime",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.EXPIRY_TIME,
        field: "expiryTime",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.STATUS,
        field: "status",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.ACTIONS,
        field: "actions",
        sort: "asc",
        width: 2,
      },
    ],
    // rows: trips,
  };
  AdminHeaderData = {
    columns: [
      {
        label: userLang?.common?.INDENT,
        field: "indentId",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.common?.SOURCE,
        field: "source",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.DESTINATION,
        field: "destination",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.ROUTE,
        field: "route",
        sort: "asc",
        width: 3,
      },
      {
        label: userLang?.common?.VEHICLE,
        field: "vehicle",
        sort: "asc",
        width: 3,
      },
  
      {
        label: userLang?.common?.START_TIME,
        field: "startTime",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.EXPIRY_TIME,
        field: "expiryTime",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.STATUS,
        field: "status",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.common?.ACTIONS,
        field: "actions",
        sort: "asc",
        width: 2,
      },
    ],
    // rows: trips,
  };
}
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
  
}
export var HeaderData = {
  columns: [
    {
      label: userLang?.common?.INDENT,
      field: "indentId",
      sort: "asc",
      width: 1,
  },
    {
      label: userLang?.common?.SOURCE,
      field: "source",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.DESTINATION,
      field: "destination",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.ROUTE,
      field: "route",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.common?.VEHICLE,
      field: "vehicle",
      sort: "asc",
      width: 3,
    },

    {
      label: userLang?.common?.START_TIME,
      field: "startTime",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.EXPIRY_TIME,
      field: "expiryTime",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.STATUS,
      field: "status",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      sort: "asc",
      width: 2,
    },
  ],
  // rows: trips,
};
export var AdminHeaderData = {
  columns: [
    {
      label: userLang?.common?.INDENT,
      field: "indentId",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.common?.SOURCE,
      field: "source",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.DESTINATION,
      field: "destination",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.ROUTE,
      field: "route",
      sort: "asc",
      width: 3,
    },
    {
      label: userLang?.common?.VEHICLE,
      field: "vehicle",
      sort: "asc",
      width: 3,
    },

    {
      label: userLang?.common?.START_TIME,
      field: "startTime",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.EXPIRY_TIME,
      field: "expiryTime",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.STATUS,
      field: "status",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      sort: "asc",
      width: 2,
    },
  ],
  // rows: trips,
};

export const searchBar = {
  searchBarData: [
    {
      label: userLang?.planning?.INDENT_ID,
      field: "indentId",
      type: "text",
    },
    {
      label: userLang?.common?.SOURCE,
      field: "source",
      type: "select",
    },
    {
      label: userLang?.common?.DESTINATION,
      field: "destination",
      type: "select",
    },
    {
      label: userLang?.common?.ROUTE,
      field: "route",
      type: "text",
    },
    {
      label: userLang?.common?.VEHICLE_TYPE,
      field: "vehicleType",
      type: "select",
    },
    {
      label:userLang?.common?.START_TIME,
      field: "startTime",
      type: "date",
    },
    {
      label:userLang?.common?.EXPIRY_TIME,
      field: "expiryTime",
      type: "date",
    },
    {
      label: userLang?.common?.STATUS,
      field: "status",
      type: "select",
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      type: "text",
    },
  ],
  // rows: trips,
};
