import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import HopsReducer from './reducer';
const Hop = combineReducers({
  locations: HopsReducer,
  form: formReducer
});


export default Hop;
