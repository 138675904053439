import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import EmployeesReducer from './reducer';
const Employee = combineReducers({
  employees: EmployeesReducer,
  form: formReducer
});


export default Employee;
