// import { mapKeys } from "lodash";
import { types } from "../actionTypes";

const initialState = {
  routeDetail: {
    loadingPoint: "",
    destination: [],
    reportTime: "",
    checkDestinationOmit: false,
  },
  orderDetail: {
    orderID: "",
    invoiceID: "",
    materialType: "",
    materialQuantity: "",
    unit: "",
    customerName: "",
    customerContact: "",
  },
  vehicleDetail: {
    vehicleType: "",
    vehicleQuantity: "",
    transporterSelection: "",
    cost: "",
  },
  indentsForMaster: "",
  assignedIndents: [
    {
      Source: "asd",
      Destination: "ahgvs",
      vehicleType: "truck",
      transporter: "uhf",
      route: "abc",
      status: "Assigned",
    },
    {
      Source: "asd",
      Destination: "ahgvs",
      vehicleType: "truck",
      transporter: "uhf",
      route: "abc",
      status: "Accepted",
    },
  ],
  creatorIndents: [],
  excelIndents: [],
  fetchId: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_INDENTS_FOR_MASTER_FOR_SUCCESS:
      return { ...state, indentsForMaster: action.payload };
    // case types.FETCH_POST_SUCCESS:
    //   // Copy the current state and set a new property with a dynamic key value and the payload as the value
    //   return { ...state, [action.payload.id]: action.payload };
    case types.FETCH_INDENTS_SUCCESS:
      return {
        ...state,
        creatorIndents: action.payload,
        fetchId: "id" + new Date().getTime(),
      };

    case types.FETCH_INDENTS_EXCEL_SUCCESS:
      return { ...state, excelIndents: action.payload };

    case types.FETCH_INDENTS_ASSIGNED_SUCCESS:
      //   // Create a new state object that uses an AJAX request response and grabs the 'id' property from each object in the response to use as its key
      return {
        ...state,
        assignedIndents: action.payload,
        fetchId: "id" + new Date().getTime(),
      };

    case types.FETCH_CREATED_INDENT_INFO_SUCCESS:
      return state;

    case types.CREATE_INDENT_SUCCESS:
      //console.log('state', state);
      // Copy the current state and set a new property with a dynamic key value and the payload as the value
      return { ...state, [action.payload.id]: action.payload };
    case types.ASSIGN_INDENT_SUCCESS:
      //console.log('state', state);
      // Copy the current state and set a new property with a dynamic key value and the payload as the value
      return { ...state };

    case types.FETCH_HOPS_SUCCESS:
      return { ...state, hops: action.payload };

    case types.ROUTE_DETAILS:
      return { ...state, routeDetail: action.payload };

    case types.ORDER_DETAILS:
      return { ...state, orderDetail: action.payload };

    case types.VEHICLE_DETAILS:
      return { ...state, vehicleDetail: action.payload };

    case types.CHANGE_ASSIGNED_INDENT_STATUS:
      let { index, status } = action.payload;
      let indents = [...state.assignedIndents];
      indents[index].status = status;
      return { ...state, assignedIndents: indents };

    case types.INDENT_STATUS_ACCEPT_SUCCESS:
      let newstate = { ...state };
      let assignedIndents = newstate.assignedIndents.data.docs;
      for (let indent of assignedIndents) {
        if (indent._id === action.payload.indentID) {
          indent.status = "Accepted";
          break;
        }
      }
      return newstate;

    default:
      state = { ...state };
      break;
  }

  return state;
};
