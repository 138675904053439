import React, { Component } from "react";
import { Card } from "reactstrap";
import { Grid } from "@material-ui/core";
import { Progress } from "reactstrap";
import { Row, Col } from "reactstrap";
import IndentDetailDonutChart from "./IndentDetailDountChart";


export default class indentDetailsCards extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    const indents = this.props.indents;
    // console.log("this.props.indents", this.props.indents);
    var darkMode = this.props.mode;
    return (
      <>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          className="mt-1 pd18"
        >
          <Grid item xs={12} md={8} className="pl-1 pr-1">
            <div className="IndentDetailsCardDiv">
              <Grid container spacing={0} className="">
                <Grid item xs={6}>
                  <Card className="shadow darkInternalcard">
                    <div
                      style={{
                        width: "100%",
                        height: "149px",
                        float: "left",
                        paddingTop:"10px"
                      }}
                    >
                      <span className="pt-2 ml-4 span-indentAcceptance-name light__span">
                        {this.props?.userLang?.dashboard?.TOTAL_INDENTS}
                      </span>
                      <br></br>
                      <span className="pt-2 ml-4 span-indentAcceptance-value dark__span">
                        {indents?.TotalIndents}
                      </span>
                      <br></br>
                      <span className="pt-3 ml-4 span-indent-rejection-name light__span">
                      {this.props?.userLang?.dashboard?.INDENT_ACCEPTANCE}
                      </span>
                      <br></br>
                      <span className="pt-2 ml-4 span-indent-rejection-value dark__span">
                        {Number((100-indents?.IndentAcceptance).toFixed(2)) + " %"}
                      </span>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={6} className="">
                  <Card className=" card-indent-dountchart shadow darkdountchart">
                    <IndentDetailDonutChart
                      data={indents}
                      indentAcc={this.props.indentAcc}
                    />
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} md={4} className="pl-1 pt-0 mt-0 pr-1">
            <Grid container spacing={1}>
              <Grid item xs={6} md={12} className="pr-2 pt-0 mt-0">
                <Card className="card-expiry shadow indentDexpiry">
                  <div
                    style={{
                      position: "relative",
                      left: "5px",
                    }}
                  >
                    <Row className="pt-2 card-expiry-row mobileCancel">
                    <Col xs="1" className="ml-2">
                        <svg
                          width="33"
                          height="34"
                          viewBox="0 0 33 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g filter="url(#filter0_d_2969_2582)">
                            <rect
                              x="4"
                              y="4.49902"
                              width="25"
                              height="25"
                              rx="4"
                              fill={darkMode?"#171722":"#EAC8FF"}
                            />
                          </g>
                          <path
                            d="M21.9644 16.2979H11.0356C10.365 16.2979 9.82129 16.6283 9.82129 17.0358V22.9398C9.82129 23.3473 10.365 23.6778 11.0356 23.6778H21.9644C22.635 23.6778 23.1787 23.3473 23.1787 22.9398V17.0358C23.1787 16.6283 22.635 16.2979 21.9644 16.2979Z"
                            fill="#A020F0"
                            stroke="#A020F0"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M21.9644 11.5347H11.0356C10.365 11.5347 9.82129 11.6614 9.82129 11.8177V14.082C9.82129 14.2384 10.365 14.3651 11.0356 14.3651H21.9644C22.635 14.3651 23.1787 14.2384 23.1787 14.082V11.8177C23.1787 11.6614 22.635 11.5347 21.9644 11.5347Z"
                            fill="#A020F0"
                            stroke="#A020F0"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.9282 10.3203V12.1418"
                            stroke="#A020F0"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.4644 10.3203V12.1418"
                            stroke="#A020F0"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <defs>
                            <filter
                              id="filter0_d_2969_2582"
                              x="0"
                              y="0.499023"
                              width="33"
                              height="33"
                              filterUnits="userSpaceOnUse"
                              colorInterpolationFilters="sRGB"
                            >
                              <feFlood
                                floodOpacity="0"
                                result="BackgroundImageFix"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset />
                              <feGaussianBlur stdDeviation="2" />
                              <feComposite in2="hardAlpha" operator="out" />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_2969_2582"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_2969_2582"
                                result="shape"
                              />
                            </filter>
                          </defs>
                        </svg>
                      </Col>
                      <Col className="pl-4 light__span" xs="5">
                        <div className="fontRoboto134 light__span pt-1 pl-2">
                        {this.props?.userLang?.dashboard?.EXPIRY}

                        </div>
                      
                      </Col>
                      <Col xs={5} className="expiryVal">
                      <div className="fontRoboto light__span">
                      {
                      indents?.TotalIndents === 0 ? 0 :
                      Number(
                          (
                            (indents?.ExpiryIndents / indents?.TotalIndents) *
                            100
                          ).toFixed(2)
                        ) + " %"
                        
                      }
                      </div>
                      </Col>
                     
                    </Row>
                    <div className="pt-2 pl-2 mt-0 card-expiry-value mobileCancel1">
                      {/* <UncontrolledTooltip
                        style={{
                          position: "relative",
                          top: "80%",
                        }}
                        placement="bottom"
                        autohide={true}
                        target="ExpiryCard"
                      >
                        {Number(
                          (
                            (indents?.ExpiryIndents / indents?.TotalIndents) *
                            100
                          ).toFixed(2)
                        ) + " " + "%"}
                      </UncontrolledTooltip> */}
                      <Progress
                        id="ExpiryCard"
                        className="card-exipry-progress"
                        barStyle={{
                          borderRadius: "20px",
                        }}
                        color="rgba(160, 32, 240, 1)"
                        value={Number(
                          (
                            (indents?.ExpiryIndents / indents?.TotalIndents) *
                            100
                          ).toFixed(2)
                        )}
                      />
                    </div>
                  </div>
                  {/* </div> */}
                </Card>
              </Grid>
              <Grid item xs={6} md={12} className="pr-2 pt-0 mt-0">
                <Card className="card-cancelled shadow indentDexpiry">
                  <div
                    style={{
                      position: "relative",
                      left: "5px",
                    }}
                  >
                    <Row className=" CardRownoWrap pt-2 mobileCancel">
                    <Col xs="1" className="ml-2">
                        <svg
                          width="33"
                          height="34"
                          viewBox="0 0 33 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g filter="url(#filter0_d_2969_2583)">
                            <rect
                              x="4"
                              y="4.2334"
                              width="25"
                              height="25"
                              rx="4"
                              fill={darkMode?"#171722":"#FFE3E1"}
                            />
                          </g>
                          <path
                            d="M11.7637 10.6437H21.2366C21.984 10.6437 22.5898 11.2496 22.5898 11.9969V21.4698C22.5898 22.2172 21.984 22.8231 21.2366 22.8231H11.7637C11.0163 22.8231 10.4105 22.2172 10.4105 21.4698V11.9969C10.4105 11.2496 11.0163 10.6437 11.7637 10.6437Z"
                            fill="#AC1000"
                            stroke="#AC1000"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19.5498 13.6836L13.4502 19.7832"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.4502 13.6836L19.5498 19.7832"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <defs>
                            <filter
                              id="filter0_d_2969_2583"
                              x="0"
                              y="0.233398"
                              width="33"
                              height="33"
                              filterUnits="userSpaceOnUse"
                              colorInterpolationFilters="sRGB"
                            >
                              <feFlood
                                floodOpacity="0"
                                result="BackgroundImageFix"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset />
                              <feGaussianBlur stdDeviation="2" />
                              <feComposite in2="hardAlpha" operator="out" />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_2969_2583"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_2969_2583"
                                result="shape"
                              />
                            </filter>
                          </defs>
                        </svg>
                      </Col>
                      <Col className="pl-4 card-cancel-name light__span" xs="5">
                        <div className="fontRoboto134 light__span pt-2 pl-2">
                        {this.props?.userLang?.dashboard?.CANCELLED}
                         </div>
                      </Col>
                      <Col xs={5} className="cancelVal">
                      <div className="fontRoboto light__span">

                      {
                      indents?.TotalIndents === 0 ? 0 :
                      Number(
                          (
                            (indents?.cancelledIndents /
                              indents?.TotalIndents) *
                            100
                          ).toFixed(2)
                        ) +  " %"}
                      </div>
                      </Col>
                      
                    </Row>
                    <div className="pt-2 pl-2 mt-0 mobileCancel1">
                      {/* <span className="pl-1 card-cancel-value">
                      {(indents?.cancelledIndentPercentage
                        ? indents?.cancelledIndentPercentage
                        : 0) + "%"}
                    </span> */}
                      {/* <UncontrolledTooltip
                        placement="bottom"
                        autohide={true}
                        target="CancelProgressBar"
                      >
                        {Number(
                          (
                            (indents?.cancelledIndents /
                              indents?.TotalIndents) *
                            100
                          ).toFixed(2)
                        ) + " " + "%"}
                      </UncontrolledTooltip> */}
                      {/* <span className="fontRoboto127 light__span">
                      {Number(
                          (
                            (indents?.cancelledIndents /
                              indents?.TotalIndents) *
                            100
                          ).toFixed(2))+ " " + "%"}
                      </span> */}
                      <Progress
                        id="CancelProgressBar"
                        className="cancelProgress"
                        style={{
                          border: darkMode
                            ? "3px solid #171722"
                            : "3px solid #EDEDED",
                          width: "90%",
                        }}
                        barStyle={{
                          borderRadius: "20px",
                          backgroundColor: "#AC1000",
                        }}
                        // color="danger"
                        value={Number(
                          (
                            (indents?.cancelledIndents /
                              indents?.TotalIndents) *
                            100
                          ).toFixed(2)
                        )}
                      />
                    </div>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}
