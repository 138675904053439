import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withNamespaces } from "react-i18next";

// users
import avatar2 from "../../../assets/images/user-logo.png";
import { Link } from "react-router-dom";
import { HINDI, ENGLISH } from "../../../language/language";
// import Button from "../../Common/Controls/Button";
import AppTheme from "../../AppTheme";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
let userPreferredLang = localStorage.getItem("userPreferredLang");
class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      userLangs: undefined,
      langaugeOpen: false,
    };
    this.toggle = this.toggle.bind(this);
    this.toggleLanguage = this.toggleLanguage.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }
  toggleLanguage() {
    this.setState({
      langaugeOpen: !this.state.langaugeOpen,
    });
  }
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    userPreferredLang = localStorage.getItem("userPreferredLang");
    const darkTheme = this.props?.darkThemeProfile;
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let username = authUser?.data?.name;
    let clr, bgcolor;
    if (userPreferredLang === "EN") {
      clr = "#F1F5F7";
      bgcolor = darkTheme ? "#B7A9FF" : "#2800FC";
    }
    if (userPreferredLang === "HI") {
      clr = "#F1F5F7";
      bgcolor = darkTheme ? "#B7A9FF" : "#2800FC";
    }
    // console.log(this.props);
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block user-dropdown"
        >
          <DropdownToggle
            style={{
              height: "40px",
            }}
            tag="button"
            className="btn mt-0 pt-0 MobileProfileParent"
            // id="page-header-user-dropdown"
          >
            <img
              className=" mr-1"
              src={avatar2}
              alt="Header Avatar"
              width="26px"
              height="26px"
            />
            <p className="d-xl-inline-block ml-1 text-transform profile-name">
              <span className="dark__span">
                {
                  username?.length > 20
                  ? username.slice(0, 19) + "..."
                  : username
                  ? username 
                  : ''
                }
             
              </span>
            </p>
            <i className="mdi mdi-chevron-down ml-1 pl-1 d-xl-inline-block mt-0 profile-chervon"></i>
          </DropdownToggle>
          <DropdownMenu right className="darkCardStyle dropDownRightProfile">
            <DropdownItem className="text-danger">
              <Link
                className="nav-link cursor dropdown-toggle arrow-none"
                to="/profileSettings"
                id="topnav-apps"
                role="button"
              >
                <svg
                  className="pr-2 mr-2"
                  width="50"
                  height="50"
                  viewBox="0 0 33 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_5658_527)">
                    <rect
                      x="4"
                      y="4"
                      width="25"
                      height="25"
                      rx="4"
                      fill="#D5CDFF"
                    />
                  </g>
                  <path
                    d="M21.583 22.906V21.583C21.583 20.8812 21.3042 20.2082 20.808 19.712C20.3118 19.2158 19.6388 18.937 18.937 18.937H13.646C12.9442 18.937 12.2712 19.2158 11.775 19.712C11.2788 20.2082 11 20.8812 11 21.583V22.906"
                    fill="#2800FC"
                  />
                  <path
                    d="M21.583 22.906V21.583C21.583 20.8812 21.3042 20.2082 20.808 19.712C20.3118 19.2158 19.6388 18.937 18.937 18.937H13.646C12.9442 18.937 12.2712 19.2158 11.775 19.712C11.2788 20.2082 11 20.8812 11 21.583V22.906H21.583Z"
                    stroke="#2800FC"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.083 16C17.4637 16 18.583 14.8807 18.583 13.5C18.583 12.1193 17.4637 11 16.083 11C14.7023 11 13.583 12.1193 13.583 13.5C13.583 14.8807 14.7023 16 16.083 16Z"
                    fill="#2800FC"
                    stroke="#2800FC"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <defs>
                    <filter
                      id="filter0_d_5658_527"
                      x="0"
                      y="0"
                      width="33"
                      height="33"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_5658_527"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_5658_527"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
                <span className="light__span">
                  {this.props.t(this.state.userLangs?.common?.PROFILE_SETTINGS)}
                  {/* Profile */}
                </span>
              </Link>
            </DropdownItem>
            <hr className="hrLineProfile"></hr>
            <DropdownItem className="text-danger">
              {" "}
              <Link
                className="nav-link cursor dropdown-toggle arrow-none"
                to="/logout"
                id="topnav-apps"
                role="button"
              >
                <svg
                  className="pr-2 mr-2"
                  width="50"
                  height="50"
                  viewBox="0 0 33 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_5132_102)">
                    <rect
                      x="4"
                      y="4"
                      width="25"
                      height="25"
                      rx="4"
                      fill="#D5CDFF"
                    />
                  </g>
                  <path
                    d="M14.6667 22H12.2222C11.8981 22 11.5872 21.8712 11.358 21.642C11.1288 21.4128 11 21.1019 11 20.7778V12.2222C11 11.8981 11.1288 11.5872 11.358 11.358C11.5872 11.1288 11.8981 11 12.2222 11H14.6667"
                    stroke="#2800FC"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18.9443 19.5556L21.9999 16.5L18.9443 13.4445"
                    stroke="#2800FC"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22.0003 16.5H14.667"
                    stroke="#2800FC"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <defs>
                    <filter
                      id="filter0_d_5132_102"
                      x="0"
                      y="0"
                      width="33"
                      height="33"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_5132_102"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_5132_102"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>

                {/* <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>{" "} */}
                <span className="light__span">
                  {this.props.t(this.state.userLangs?.common?.LOGOUT)}
                </span>
              </Link>
            </DropdownItem>
            <hr className="hrLineProfile"></hr>
            <DropdownItem className="text-danger">
              <Row className="m-2">
                <Col xs={5}>
                  <Button
                    className=""
                    style={{
                      minWidth: "40px",
                      height: "40px",
                      border: "0.5px solid #2800FC",
                      borderRadius: "10px",
                      backgroundColor:
                        userPreferredLang === "EN" ? `${bgcolor}` : "white",
                    }}
                    onClick={() => {
                      if (userPreferredLang !== "EN") {
                        localStorage.setItem("userPreferredLang", "EN");
                        localStorage.setItem(
                          "userLangDetail",
                          JSON.stringify(ENGLISH)
                        );
                        window.location.reload();
                      }
                      // this.toggleLanguage();
                    }}
                  >
                    <svg
                      width="18"
                      height="11"
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.26923 10.8999V10.2587C7.26661 10.1163 7.33785 9.97378 7.12413 9.33261C6.9104 8.83392 6.69668 8.05026 6.41171 7.33785H3.20586C2.99213 7.83654 2.77841 8.40647 2.63592 9.11888C2.35096 9.97378 2.4222 10.1875 3.6333 10.33V10.9712H0V10.33C1.06862 10.1875 1.28234 10.045 1.85227 8.69143L5.12937 0.213724L5.77054 0C6.76792 2.77841 7.83654 5.84178 8.83391 8.62019C9.3326 10.045 9.54633 10.1875 10.6149 10.33V10.9712H6.26923V10.8999ZM4.84441 2.63593C4.34572 3.91827 3.91827 5.20061 3.41958 6.41171H6.12675L4.84441 2.63593Z"
                        // fill="#F1F5F7"
                        fill={userPreferredLang === "EN" ? `${clr}` : "#2800FC"}
                      />
                    </svg>
                  </Button>
                </Col>
                <Col xs={2}>
                  <div
                    style={{
                      width: "1px",
                      height: "100%",
                      backgroundColor: "#B9B9B9",
                    }}
                  ></div>
                </Col>

                <Col xs={5}>
                  <Button
                    style={{
                      minWidth: "40px",
                      height: "40px",
                      border: "0.5px solid #2800FC",
                      borderRadius: "10px",
                      backgroundColor:
                        userPreferredLang === "HI" ? `${bgcolor}` : "white",
                    }}
                    onClick={() => {
                      if (userPreferredLang !== "HI") {
                        localStorage.setItem("userPreferredLang", "HI");
                        localStorage.setItem(
                          "userLangDetail",
                          JSON.stringify(HINDI)
                        );
                        window.location.reload();
                      }
                      // this.toggleLanguage();
                    }}
                  >
                    <svg
                      width="18"
                      height="11"
                      viewBox="0 0 18 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.6495 10.8898L11.4807 10.9742L9.79234 10.4677V7.00659C9.62351 7.00659 9.53909 7.00659 9.45468 7.00659C8.94818 7.00659 8.27284 6.83776 7.93517 6.58451H7.85076C7.93517 6.83776 7.93517 7.09101 7.93517 7.34426C7.93517 8.77934 6.75334 9.87676 5.23384 9.87676C3.12342 9.87676 1.5195 8.10401 0 5.14942V5.06501L0.759751 4.5585C1.94159 6.75334 2.87017 8.01959 4.5585 8.01959C5.40267 8.01959 6.50009 7.34426 6.50009 6.33126C6.50009 5.82476 6.16242 5.48709 5.48709 5.48709C4.98059 5.48709 4.30525 5.65592 3.5455 5.99359L2.61692 4.38967L2.70134 4.22084C3.46109 3.96759 4.13642 3.71434 4.89617 3.5455C5.57151 3.29225 5.90917 2.87017 5.90917 2.44809C5.90917 2.11042 5.74034 1.77275 4.98059 1.77275C4.38967 1.68833 3.5455 2.026 2.61692 2.61692L1.60392 1.013V0.844167C2.36367 0.337667 3.20784 0 3.96759 0C5.14942 0 5.90917 0.506501 6.66892 1.5195C7.09101 2.19484 7.34426 2.70134 7.34426 3.37667C7.34426 3.79875 7.17542 4.30525 6.83776 4.72734C7.00659 4.89617 7.17542 5.06501 7.25984 5.23384C7.42867 5.40267 7.85076 5.48709 8.27284 5.48709C8.86376 5.48709 9.45468 5.31825 9.96118 4.98059V1.77275H8.35726L7.59751 0.337667L7.76634 0.168834H17.3054L17.9808 1.60392L17.8119 1.77275H16.0392V10.8898L15.8703 10.9742L14.182 10.4677V1.68833H11.4807L11.6495 10.8898Z"
                        // fill="#2800FC"
                        fill={userPreferredLang === "HI" ? `${clr}` : "#2800FC"}
                      />
                    </svg>
                  </Button>
                </Col>
              </Row>
            </DropdownItem>
            <hr className="hrLineProfile"></hr>
            <DropdownItem>
              <AppTheme />
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(ProfileMenu);
