import React, { Component } from "react";
import { Card } from "@material-ui/core";
import { Row, Col } from "reactstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import OnTimeDeliverCards from "./OnTimeDeliverCards";
import { ExportIcon } from "../../../components/Common/CommonIcons";

export default class OnTimeDelivery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: false,
    };
  }
  toggle = () => {
    this.setState((prevState) => ({
      menu: false,
    }));
  };
  toggleExcel = () => {
    setTimeout(async () => {
      let arrTat = [];
      let onTimeDelivery = this.props?.performance;
      // console.log("OnTimedelivery", onTimeDelivery);
      // var performanceObj = {
      //   onTimeDeliveries:0,
      //   onTimeDeliverisPercentage:0,
      //  };
      if(onTimeDelivery  !== undefined){
        onTimeDelivery.map((ele)=>{
          // console.log("ele tes", ele)
          if(ele?.performanceType === "onTimeDelivery"){
            arrTat.push({
              "Total Deliveries": ele?.totalTrips,
              "OnTime Deliveries(%)": Number((ele?.performancePercentage*100).toFixed(2)),
              "Rejected Deliveries(%)": Number(100-Number((ele?.performancePercentage*100).toFixed(2))),
            });
           
          }
          return null
        })
      }
      await this.exportToCSV([...arrTat], "On_Time_Delivery_DATA_EXCEL");
    }, 1000);

    this.setState({ loading: true, export: true });
  };
  exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    this.setState({ export: false });
  };
  render() {
    const indents = this.props.indents;
    var darkMode = this.props.darkTheme
    return (
      <Card className="onTimeDelivery darkCardStyle">
        <div className="mt-2 pl-3">
          <Row className="pt-2">
            <Col xs="9" className="pdt5">
              <span className="roboto-font-700 span-indent-name light__span">
                {this.props?.userLang?.dashboard?.ON_TIME_DELIVERY}
                {/* On time delivery */}
              </span>
            </Col>
            <Col xs="3">
              <span
                className="pr-3"
                style={{
                  float: "right",
                  marginLeft: "535px",
                }}
              >
                <div
                  className="d-flex justify-content-end"
                  style={{
                    position: "relative",
                    right: "6px",
                  }}
                >
                  <div className="pr-2">
                    {/* <div className=''>
                        <Dropdown
                          isOpen={this.state.menu}
                          toggle={this.toggle}
                          className='d-none d-sm-inline-block'
                        >
                          <DropdownToggle
                            style={{
                              width: '100%',
                              display: 'flex',
                              height: '26px',
                              border: '1px solid blue',

                              justifyContent: 'center',
                            }}
                            tag='button'
                            className='btn mt-0 pt-0'
                          // id="page-header-user-dropdown"
                          >
                            <span
                              style={{
                                display: 'flex',
                                marginTop: '5px',
                              }}
                              className='d-xl-inline-block ml-1 text-transform profile-name'
                            >
                              Monthly
                            </span>
                            <i
                              style={{
                                fontSize: '17px',
                              }}
                              className='mdi mdi-chevron-down ml-1 pl-1 d-xl-inline-block mt-0 profile-chervon'
                            ></i>
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem></DropdownItem>

                            <DropdownItem
                              href=''
                              className='notify-item'
                            ></DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div> */}
                  </div>

                  <div>
                    <div className={navigator.onLine ? "tooltipIcon" : "tooltipIcon disable-export"}>
                       
                        <i className="cursor-pointer exportDark"
                        onClick={() => this.toggleExcel()}><ExportIcon color={darkMode?"#B7A9FF":"#2800FC"}/></i>
                      <span
                        className="tooltipIcontext1"
                        // style={{ width: "55px" }}
                      >
                        {this.props?.userLang?.invoice?.DOWNLOAD_EXCEL ?? "Download Excel"}
                      </span>
                    </div>
                  </div>
                </div>
              </span>
            </Col>
          </Row>
        </div>
        <hr className="mt-3 indent-hr-line" />
        <OnTimeDeliverCards 
         performance={this.props?.performance}
        indents={indents} 
        userLang={this.props.userLang} />
      </Card>
    );
  }
}
