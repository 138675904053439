import React, { Component } from 'react';

export class StatusIndicator extends Component {
  toPascalCase = (str) => (
    str.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, function(match) {
     return match.toUpperCase();
   }).replace(/\s+/g, '  ')
 )
  render() {
    let color;
  console.log(this.props.status)
    switch (this.props.status) {
      case "arrived_at_source":
        color = "rgb(255, 111, 0)";
        break;
      case "planned":
        color = "#E19600";
        break;
      case "Planned":
        color = "#E19600";
        break;
      case "loading_in_progress":
        color = "#28A34C";
        break;
      case "shipment_verified":
        color = "#f75c5c";
        break;
      case "arrived_at_via":
        color = "#006400";
        break;
        case "epod_in_progress":
        color = "#28A34C";
        break;
      case "completed":
        color = "rgb(0, 100, 0)";
        break;
      case "arrived_at_via":
        color = "#006400";
        break;
      case "quality_check_completed":
        color = "#006400";
        break;
        case "in_transit":
        color = "rgb(160, 32, 240)";
        break;
       
      default:
        color = "black";
        break;
    }
    return <span style={{ color: color ,fontWeight:"900",fontSize:"18px"}} className="text-bold ml-4">{this.toPascalCase(this.props.status)}</span>;
  }
}


