import React, { Component } from "react";
//import from libraries
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
//import files
import { searchBar, getLocalStorage, HeadersData } from "./RowHeader/RowHeader";
import QuickFilters from "./QuickFilters";
import Index from "../../components/Common/DataTableNeo/Index";
// import from store
import { fetchAllTransporterSearch } from "../../store/transporter/actions";
import {
  FetchVehiclePlacementDetailReports,
  FetchVehiclePlacementDetailReportsForExcel,
} from "../../store/vehiclePlacementDetail/actions";
import { fetchHops } from "../../store/hop/actions";

var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;
var darkMode;

class vehiclePlacementDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transpoterId: "",
      FromDate: "",
      ToDate: "",
      offset: 0,
      limit: 10,
      dataLoading: false,
      selectedTranspoterValues: "",

      transpoter: [],
      transporterValues: "",
      selectedSourceHopValues: "",
      hopValues: "",
      hops: [],

      selectedDistinationHopValues: "",

      isExport: false,
      isExport: false,
    };
  }
  componentDidMount() {
    this.getAllData();
    this.getAllSourceHopData();
    this.getAllTransporterData();
    // this.getAllExportData();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    else document.body.style.zoom = 0.9;
  }
  getAllData = () => {
    this.setState({
      dataLoading: true,
    });
    let values = {
      offset: offset,
      transpoterId: this.state.selectedTranspoterValues?.value,
      // transpoterId: "618a618f29e8452750ab03a8",
      sourceHop: this.state.selectedSourceHopValues?.value?.id,
      destinationHop: this.state.selectedDistinationHopValues?.value?.id,
      startDate: this.state.FromDate,
      endDate: this.state.ToDate,
      // startDate: "02-01-2022",
      // endDate: "02-06-2022",
      isExport: false,
    };
    this.props.FetchVehiclePlacementDetailReports(values, (response) => {
      this.setState({
        dataLoading: false,
      });
    });
  };
  getAllExportData = () => {
    this.setState({
      dataLoading: true,
    });
    let values = {
      offset: offset,
      transpoterId: this.state.selectedTranspoterValues?.value,
      sourceHop: this.state.selectedSourceHopValues?.value?.id,
      destinationHop: this.state.selectedDistinationHopValues?.value?.id,
      startDate: this.state.FromDate,
      endDate: this.state.ToDate,
      isExport: true,
    };

    this.props.FetchVehiclePlacementDetailReportsForExcel(
      values,
      (response) => {
        this.setState({
          dataLoading: false,
        });
        // console.log("respons1",response)
        let exprotDataReport = [];
        let exportData = response?.data?.docs;

        if (exportData?.length == 0) {
          exprotDataReport.push({
            "Sl no.": "",
            "Indent Id": "",
            "Source Code": "",
            "Source Name": "",
            "Destination Code": "",
            "Destination Name": "",
            "Route Name": "",
            "Vehicle Type": "",
            "Transporter Name": "",
            "Lane  Name": "",
            "Lot Name": "",
            "Indent Creation Date": "",
            "Transporter Acceptance Expiry Date Time": "",
            "Transporters Actual Accept Rej Date Time": "",
            "Transporters Acceptance Event Action Taken": "",
            "Vehicle Reporting Time At Factory": "",
            "Trip ID": "",
            "Trip Creation Date": "",
            "Mark as Arrival Date At Source": "",
            "Quality Event Action Date": "",
            "Quality Event Action taken": "",
            "Trip Shipment Verification Start DateTime From Source": "",
            "Reporting DateTime At Vialocation": "",
            "Via Location Verification date": "",
            "Mark as Arrival At Destination": "",
            "EPOD Completed date": "",
            "Damage Count": "",
            "Shortage Count": "",
            Comments: "",
          });
          // exportData.
        }
        if (response !== undefined) {
          if (Array.isArray(exportData)) {
            exportData.forEach((ele, i) => {
              // let shortageComments=ele?.trip?.comments[0]?.shortageComment;
              // let shortageComments=ele?.trip?.comments[0]?.shortageComment;

              let damageCountSum = ele?.trip?.damageDetails.reduce(
                (accumulator, currentValue) =>
                  accumulator + currentValue.damageCount,
                0
              );
              let shortageCountSum = ele?.trip?.shortageDetails.reduce(
                (accumulator, currentValue) =>
                  accumulator + currentValue.shortageCount,
                0
              );

              // console.log("comment",JSON.stringify(ele?.trip?.comments))
              let userComment = "";
              ele.trip.comments.forEach(function (comment) {
                if (comment?.shortageComment) {
                  userComment +=
                    "Shortage_Comment_" + comment?.shortageComment + "|";
                }
                if (comment?.damageComment) {
                  userComment +=
                    "Damage_comment_" + comment?.damageComment + "|";
                }
                // console.log("userComment",userComment)
              });

              let status;
              let indentStatus;
              if (
                ele?.indent?.status === "Accepted" ||
                ele?.indent?.status === "Rejected"
              ) {
                status = ele?.indent?.status;
              }
              if (
                ele?.indent?.status === "Expired" ||
                ele?.indent?.status === "Cancelled" ||
                ele?.indent?.status === "Finalized" ||
                ele?.indent?.status === "Accepted" ||
                ele?.indent?.status === "Rejected"
              ) {
                indentStatus = ele?.indent?.status;
              }
              let shipmentNumbers = ele?.trip?.shipmentNumbers || [];
              let shipmentNumbersText =
                shipmentNumbers.length > 0 ? shipmentNumbers.join(", ") : " ";
              let lrNumbersArray = ele?.trip?.LRNumbers || [];
              let lrNumbers =
                lrNumbersArray.length > 0 ? lrNumbersArray.join(", ") : " ";
              let CFCValues = ele?.trip?.CFC || [];
              let sumCFC = CFCValues.reduce((accumulator, currentValue) => {
                if (currentValue !== null && currentValue !== undefined) {
                  return accumulator + currentValue;
                } else {
                  return accumulator;
                }
              }, 0);
              let CFCResult =
                CFCValues.length > 0
                  ? sumCFC === 0
                    ? "0"
                    : sumCFC.toFixed(2)
                  : " ";

              exprotDataReport.push({
                "Sl no.": i + 1,
                "Indent Id": ele?.indent?.indentId ? ele?.indent.indentId : " ",
                "Source Code": ele?.source?.hopCode ? ele?.source.hopCode : " ",
                "Source Name": ele?.source?.label ? ele?.source.label : " ",
                "Destination Code": ele?.destination?.hopCode
                  ? ele?.destination.hopCode
                  : " ",
                "Destination Name": ele?.destination?.label
                  ? ele?.destination.label
                  : " ",
                "Route Name": ele?.route?.name ? ele?.route.name : " ",
                "Vehicle Type": ele?.vehicle?.label ? ele?.vehicle.label : " ",
                "Transporter Name": ele?.transporter?.name
                  ? ele?.transporter.name
                  : "",
                "Lane  Name": ele?.indent?.laneLot ? ele?.indent.laneLot : " ",
                "Lot Name": ele?.indent?.lotName ? ele?.indent.lotName : " ",
                "Indent Creation Date": ele?.indent?.indentCreationDate
                  ? moment(new Date(ele?.indent?.indentCreationDate)).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  : "",
                "Transporter Acceptance Expiry Date Time":
                  ele?.actualAcceptanceOrRejectionTime
                    ? moment(
                        new Date(ele?.actualAcceptanceOrRejectionTime)
                      ).format("DD/MM/YYYY HH:mm")
                    : " ",
                "Transporters Actual Accept Rej Date Time": ele?.trip
                  ?.tripCreatedDate
                  ? moment(new Date(ele?.trip?.tripCreatedDate)).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  : " ",

                "Indent Status": indentStatus ? indentStatus : "",
                "Transporters Acceptance Event Action Taken": status
                  ? status
                  : "",
                "Vehicle Reporting Time At Factory": ele?.trip
                  ?.arrivedAtSourceLog
                  ? moment(new Date(ele?.trip?.arrivedAtSourceLog)).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  : " ",
                "Trip ID": ele?.trip?.tripId ? ele?.trip.tripId : " ",
                "Trip Creation Date": ele?.trip?.tripCreatedDate
                  ? moment(new Date(ele?.trip?.tripCreatedDate)).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  : " ",
                "Mark as Arrival Date At Source": ele?.trip?.arrivedAtSourceLog
                  ? moment(new Date(ele?.trip?.arrivedAtSourceLog)).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  : " ",
                "Quality Event Action Date": ele?.trip?.qualityCheckLog
                  ? moment(new Date(ele?.trip?.qualityCheckLog)).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  : " ",
                "Quality Event Action taken": ele?.trip?.qualityCheckActionTaken
                  ? ele?.trip?.qualityCheckActionTaken
                  : " ",
                "Trip Shipment Verification Start DateTime From Source": ele
                  ?.trip?.shipmentVerifiedAtSource
                  ? moment(
                      new Date(ele?.trip?.shipmentVerifiedAtSource)
                    ).format("DD/MM/YYYY HH:mm")
                  : " ",
                "Reporting DateTime At Vialocation": ele?.trip
                  ?.arrivedAtLastViaPoint
                  ? moment(new Date(ele?.trip?.arrivedAtLastViaPoint)).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  : " ",
                vialocationVerificationdate: ele?.trip?.LeftLastViaPoint
                  ? moment(new Date(ele?.trip?.LeftLastViaPoint)).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  : " ",
                "Mark as Arrival At Destination": ele?.trip
                  ?.arrivedAtDestination
                  ? moment(new Date(ele?.trip?.arrivedAtDestination)).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  : " ",
                "EPOD Completed date": ele?.trip?.EPODCompletedAtDestination
                  ? moment(
                      new Date(ele?.trip?.EPODCompletedAtDestination)
                    ).format("DD/MM/YYYY HH:mm")
                  : " ",
                "Damage Count": damageCountSum ? damageCountSum : " ",
                "Shortage Count": shortageCountSum ? shortageCountSum : " ",
                "Damage and Shortage Comment": userComment,
                "Shipment Number": shipmentNumbersText,
                "LR Number": lrNumbers,
                CFC: CFCResult,
                "Quality Check Comment": ele?.trip?.qualityCheckComments ? (
                  <div className="tooltipIconComment">
                    <div className="ellipsis-text ">
                      {ele?.trip?.qualityCheckComments}
                    </div>
                    <div className="tooltipIcontext">
                      {ele?.trip?.qualityCheckComments}
                    </div>
                  </div>
                ) : (
                  ""
                ),
                "Trip Status": ele?.trip?.tripStatus
                  ? ele?.trip?.tripStatus
                  : "",
              });
            });
          }
        }

        this.exportToCSV([...exprotDataReport], "Excel_Reports_Data");
      }
    );
  };
  getAllTransporterData = () => {
    let filtersHopsData = {
      offset: 0,
      limit: 10,
      q: "",
    };
    this.fetchAllTransporterSearch(filtersHopsData);
  };
  // calling transpoter api
  fetchAllTransporterSearch = (filtersData) => {
    this.props.fetchAllTransporterSearch(
      filtersData.offset,
      filtersData.limit,
      filtersData.q,
      (res) => {
        let transporter = [];
        if (res.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            transporter.push({
              value: ele._id ? ele._id : "",
              label: ele.name ? ele.name : "",
            });
          });
        }
        this.setState(
          {
            transpoter: transporter,
          },
          () => {}
        );
      }
    );
  };
  enterCheck = (event) => {
    if (event.key === "Enter") this.searchFilter();
  };
  // get transpoter name by filters
  filtersTranspoter = (e) => {
    if (e?.length > 2) {
      let filtersData = {
        offset: this.state.offset,
        limit: this.state.limit,
        q: e,
      };
      this.fetchAllTransporterSearch(filtersData);
    }
  };

  getAllSourceHopData = () => {
    let filtersHopsData = {
      offset: 0,
      limit: 10,
      q: "",
    };
    this.fetchHops(filtersHopsData);
  };

  getAllDestinationHopData = () => {
    let filtersHopsData = {
      offset: 0,
      limit: 10,
      q: "",
    };
    this.fetchHops(filtersHopsData);
  };
  // calling hops api
  fetchHops = (filtersHopsData) => {
    // console.log("fetchhops", filtersHopsData);
    this.props.fetchHops(
      {
        offset: filtersHopsData.offset,
        limit: filtersHopsData.limit,
        label: filtersHopsData.q,
      },
      (res) => {
        this.hops = [];
        if (res?.data?.docs?.length > 0) {
          // this.hops = [this.initialVal("Location")];
          res.data.docs.forEach((ele) => {
            this.hops.push({
              label: ele.hopCode + " (" + ele.label + ")",
              value: {
                name:
                  ele.address.addressLine1 +
                  ele.address.city +
                  ele.address.state,
                hopCode: ele.hopCode,
                id: ele._id,
              },
            });
          });
          this.setState({ hops: this.hops }, () => {
            searchBar.searchBarData.forEach((obj) => {
              if (obj.field === "source" || obj.field === "destination") {
                obj.options = this.state.hops;
              }
            });
          });
        }
      }
    );
  };
  // get location name by filter
  filtersHops = (e) => {
    if (e?.length > 2) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: e,
      };
      this.fetchHops(filtersHopsData);
    }
  };

  filterDestinationHops = (e) => {
    if (e?.length > 2) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: e,
      };
      this.fetchHops(filtersHopsData);
    }
    if (e.length === 0) {
      let filtersHopsData = {
        offset: 0,
        limit: 10,
        q: "",
      };
      this.fetchHops(filtersHopsData);
    }
  };
  handleChange = (dateValues) => {
    this.setState(
      {
        FromDate:
          dateValues[0] !== null
            ? moment(dateValues[0]).format("MM-DD-YYYY")
            : null,
        ToDate:
          dateValues[1] !== null
            ? moment(dateValues[1]).format("MM-DD-YYYY")
            : null,
      },
      () => {
        offset = 0;
        this.getAllData();
      }
    );
  };
  onChangeHop = (selectedSourceHopValues) => {
    this.setState({ selectedSourceHopValues }, () => {
      this.filterList();
    });
  };

  onChangeTranspoter = (selectedTranspoterValues) => {
    this.setState(
      {
        selectedTranspoterValues: selectedTranspoterValues,
      },
      () => {
        this.getAllData();
      }
    );
  };

  onChangeSourceHop = (selectedSourceHopValues) => {
    this.setState(
      {
        selectedSourceHopValues: selectedSourceHopValues,
      },
      () => {
        this.getAllData();
      }
    );
  };

  onChangeDestinationHop = (selectedDistinationHopValues) => {
    this.setState(
      {
        selectedDistinationHopValues: selectedDistinationHopValues,
      },
      () => {
        this.setState({
          dataLoading: true,
        });
        this.getAllData();
      }
    );
  };
  handleChangePage = (event, val) => {
    this.setState({ dataLoading: true });
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    toVal = offset + process.env.REACT_APP_PERPAGE;
    this.getAllData();
  };
  reset = () => {
    this.setState(
      {
        FromDate: "",
        ToDate: "",
        transporterValues: [],
        selectedTranspoterValues: "",
        dataLoading: true,
        Loading: true,
        selectedDistinationHopValues: "",
        selectedSourceHopValues: "",
      },
      () => {
        this.getAllData();
      }
    );
  };
  exportToCSV = (csvData, fileName) => {
    // console.log("Calling excel")
    csvData = csvData?.map(function (item) {
      for (var key in item) {
        var upper = key.toUpperCase();
        // check if it already wasn't uppercase
      }
      return item;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  render() {
    // console.log("Render state", this.state.hops);
    const localStorageData = JSON.parse(
      localStorage.getItem("VehicleplacementDetailData")
    );
    // console.log("json", localStorageData);
    const vehicleReportData = navigator.onLine
      ? this.props.vehicleDetailPlacementData?.data?.docs
      : localStorageData?.data?.docs;
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkMode = mode === "dark__mode" ? true : false;
    let vehiclesReports = [];
    console.log(vehicleReportData, "vehicleReportData works");
    if (Array.isArray(vehicleReportData)) {
      offset = navigator.onLine
        ? this.props.vehicleDetailPlacementData?.data?.offset
        : localStorageData?.data?.offset;
      totCnt = navigator.onLine
        ? this.props.vehicleDetailPlacementData?.data?.length
        : localStorageData?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = navigator.onLine
        ? this.props.vehicleDetailPlacementData?.data?.offset +
          this.props.vehicleDetailPlacementData?.data?.docs?.length
        : localStorageData?.data?.offset + localStorageData?.data?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      // console.log("vehiicleReportData", vehiicleReportData);

      // shortageComment=[]
      vehicleReportData.forEach((ele, i) => {
        let commentsArray = {
          shortComments: [],
          damagedComments: [],
        };
        // let comments =
        ele.trip.comments.map((c) => {
          if (c.shortageComment?.length > 0) {
            commentsArray.shortComments.push(c.shortageComment);
          } else if (c.damageComment?.length > 0) {
            commentsArray.damagedComments.push(c.damageComment);
          }
        });
        let shipmentNumbers = ele?.trip?.shipmentNumbers || [];
        let shipmentNumbersText =
          shipmentNumbers.length > 0 ? shipmentNumbers.join(", ") : "N/A";
        let lrNumbersArray = ele?.trip?.LRNumbers || [];
        let lrNumbers =
          lrNumbersArray.length > 0 ? lrNumbersArray.join(", ") : "N/A";
        let CFCValues = ele?.trip?.CFC || [];
        let sumCFC = CFCValues.reduce((accumulator, currentValue) => {
          if (currentValue !== null && currentValue !== undefined) {
            return accumulator + currentValue;
          } else {
            return accumulator;
          }
        }, 0);
        let CFCResult =
          CFCValues.length > 0
            ? sumCFC === 0
              ? "0"
              : sumCFC.toFixed(2)
            : "N/A";

        vehiclesReports.push({
          // transporterId: ele?.transporterId ? ele?.transporterId : "",
          indentId: ele?.indent?.indentId ? ele?.indent.indentId : "N/A",
          sourceCode: ele?.source?.hopCode ? ele?.source.hopCode : "N/A",
          sourceName: ele?.source?.label ? ele?.source.label : "N/A",
          destinationCode: ele?.destination?.hopCode
            ? ele?.destination.hopCode
            : "N/A",
          destinationName: ele?.destination?.label
            ? ele?.destination.label
            : "N/A",
          routeName: ele?.route?.name ? ele?.route.name : "N/A",
          vehicleType: ele?.vehicle?.label ? ele?.vehicle.label : "N/A",
          transporterName: ele?.transporter?.name
            ? ele?.transporter.name
            : "N/A",
          laneName: ele?.indent?.laneLot ? ele?.indent.laneLot : "N/A",
          lotName: ele?.indent?.lotName ? ele?.indent.lotName : "N/A",
          indentCreationDate: ele?.indent?.indentCreationDate
            ? moment(new Date(ele?.indent.indentCreationDate)).format(
                "DD/MM/YYYY"
              )
            : "N/A",
          transporterAcceptanceExpiryDateTime:
            ele?.actualAcceptanceOrRejectionTime
              ? ele?.actualAcceptanceOrRejectionTime
                  .substring(0, ele?.actualAcceptanceOrRejectionTime.length - 5)
                  ?.replace("T", " ")
              : "N/A",
          trransportersActualAcceptRejDateTime: ele?.trip?.tripCreatedDate
            ? ele?.trip.tripCreatedDate
                ?.substring(0, ele?.trip.tripCreatedDate.length - 5)
                ?.replace("T", " ")
            : "N/A",
          transportersAcceptanceEventActiontaken: ele?.indent?.status
            ? ele?.indent?.status
            : "N/A",
          vehicleReportingTimeAtFactory: ele?.trip?.arrivedAtSourceLog
            ? ele?.trip?.arrivedAtSourceLog
                .substring(0, ele?.trip.arrivedAtSourceLog.length - 5)
                ?.replace("T", " ")
            : "N/A",
          tripID: ele?.trip?.tripId ? ele?.trip?.tripId : "N/A",
          tripCreationDate: ele?.trip?.tripCreatedDate
            ? ele?.trip?.tripCreatedDate
                ?.substring(0, ele?.trip.tripCreatedDate.length - 5)
                ?.replace("T", " ")
            : "N/A",
          markasArrivalDateAtSource: ele?.trip?.arrivedAtSourceLog
            ? ele?.trip?.arrivedAtSourceLog
                ?.substring(0, ele?.trip.arrivedAtSourceLog.length - 5)
                ?.replace("T", " ")
            : "N/A",
          qualityEventActionDate: ele?.trip?.qualityCheckLog
            ? ele?.trip.qualityCheckLog
                ?.substring(0, ele?.trip.qualityCheckLog?.length - 5)
                ?.replace("T", " ")
            : "N/A",
          qualityEventActiontaken: ele?.trip?.qualityCheckActionTaken
            ? ele?.trip?.qualityCheckActionTaken
            : "N/A",
          tripShipmentVerificationStartdateTimefromsource: ele?.trip
            ?.shipmentVerifiedAtSource
            ? ele?.trip?.shipmentVerifiedAtSource
                ?.substring(0, ele?.trip.shipmentVerifiedAtSource.length - 5)
                ?.replace("T", " ")
            : "N/A",
          reportingDateTimeatvialocation: ele?.trip?.arrivedAtLastViaPoint
            ? ele?.trip?.arrivedAtLastViaPoint
                ?.substring(0, ele?.trip.arrivedAtLastViaPoint?.length - 5)
                ?.replace("T", " ")
            : "N/A",
          vialocationVerificationdate: ele?.trip?.LeftLastViaPoint
            ? ele?.trip?.LeftLastViaPoint?.substring(
                0,
                ele?.trip.LeftLastViaPoint.length - 5
              )?.replace("T", " ")
            : "N/A",
          markasArrivalAtDestination: ele?.trip?.arrivedAtDestination
            ? ele?.trip?.arrivedAtDestination
                ?.substring(0, ele?.trip.arrivedAtDestination.length - 5)
                ?.replace("T", " ")
            : "N/A",
          EPODcompleteddate: ele?.trip?.EPODCompletedAtDestination
            ? ele?.trip?.EPODCompletedAtDestination?.substring(
                0,
                ele?.trip.EPODCompletedAtDestination.length - 5
              )?.replace("T", " ")
            : "N/A",
          damageCount: ele?.trip?.damageDetails.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.damageCount,
            0
          ),
          shortageCount: ele?.trip?.shortageDetails.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue.shortageCount,
            0
          ),
          shipmentNumbersText: shipmentNumbersText,
          lrNumbers: lrNumbers,
          cfc: CFCResult,
          damageShortageComment:
            ele?.trip?.comments?.length > 0 ? (
              <>
                <div className="tooltipIcon">
                  <div className="divWrap">
                    <span>Shortage Comments :</span>
                    <span className="noWrap">
                      &nbsp; {commentsArray?.shortComments.join(",")}
                    </span>
                  </div>
                  <div className="divWrap">
                    <span>Damaged Comment :</span>
                    <span className="noWrap">
                      &nbsp; {commentsArray?.damagedComments.join(",")}
                    </span>
                  </div>
                  <span className="tooltipIcontext2">
                    &nbsp;
                    <div
                      style={{
                        height: "200px",
                        overflowY: "auto",
                        marginRight: "20px",
                      }}
                    >
                      <div className="divWrap1">
                        <span className="commentH">Shortage Comments :</span>
                        &nbsp;
                        {commentsArray?.shortComments.map((e, i) => {
                          return (
                            <>
                              <div className="p-1">
                                &nbsp; &nbsp; {i + 1}.&nbsp; {e}
                              </div>
                            </>
                          );
                        })}
                        {/* <div className="p-1">
                          &nbsp; {commentsArray?.shortComments.join(",")}
                        </div> */}
                      </div>
                      <div className="divWrap1">
                        <span className="commentH">Damaged Comment :</span>{" "}
                        &nbsp;
                        {commentsArray?.damagedComments.map((e, i) => {
                          return (
                            <>
                              <div className="p-1">
                                &nbsp;&nbsp; {i + 1}.&nbsp; {e}
                              </div>
                            </>
                          );
                        })}
                        {/* <div className="p-1">
                          &nbsp; {commentsArray?.damagedComments.join(",")}{" "}
                        </div> */}
                      </div>
                    </div>
                    &nbsp;
                  </span>
                </div>
              </>
            ) : (
              "N/A"
            ),
          qualityCheckComment: ele?.trip?.qualityCheckComments ? (
            <div className="tooltipIconComment">
              <div className="ellipsis-text ">
                {ele?.trip?.qualityCheckComments}
              </div>
              <div className="tooltipIcontext">
                {ele?.trip?.qualityCheckComments}
              </div>
            </div>
          ) : (
            ""
          ),

          tripStatus: ele?.trip?.tripStatus ? ele?.trip?.tripStatus : "",
        });
      });

      // console.log("final data vehiclesReports ", vehiclesReports)
    }
    // console.log("vehiclePlacementExportData",this.props.vehiclePlacementExportData?.data?.docs)

    return (
      <React.Fragment>
        {/* {getLocalStorage()} */}
        <div>
          <QuickFilters
            searchFilter={this.searchFilter}
            transporterValues={this.state.transporterValues}
            reset={this.reset}
            selectedTranspoterValues={this.state.selectedTranspoterValues}
            transpoterOptions={this.state.transpoter}
            onChangeTranspoter={this.onChangeTranspoter}
            enterCheck={this.enterCheck}
            // exportToCSV={this.exportToCSV}
            onChangeExport={this.onChangeExport}
            handleChange={this.handleChange}
            mode={darkMode}
            filtersTranspoter={this.filtersTranspoter}
            selectedSourceHopValues={this.state.selectedSourceHopValues}
            hopOptions={this.state.hops}
            hopValues={this.state.hopValues}
            selectedDistinationHopValues={
              this.state.selectedDistinationHopValues
            }
            filtersHops={this.filtersHops}
            onChangeSourceHop={this.onChangeSourceHop}
            onChangeDestinationHop={this.onChangeDestinationHop}
            getAllExportData={this.getAllExportData}
          />
          <Container fluid>
            <Index
              {...this.props}
              headers={HeadersData}
              data={vehiclesReports}
              searchBar={searchBar}
              searchable={false}
              getAllData={this.getAllData}
              fetchId={this.props.fetchId}
              rowHeight={"70px"}
              dataLoading={this.state.dataLoading}
            />

            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{ color: darkMode ? "#9D9D9D" : "#0C0C0C" }}
                className="mb-2 paginationRow"
              >
                <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                  <div className="float-left">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={7}
                  md={8}
                >
                  <span>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
              </Row>
            ) : null}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    fetchId: state?.VehicleDetailPlacement?.vehicleDetailPlacement?.fetchId,
    vehicleDetailPlacementData:
      state?.VehicleDetailPlacement?.vehicleDetailPlacement
        ?.vehicleDetailPlacementData,
  };
};
export default connect(mapStateToProps, {
  fetchHops,
  fetchAllTransporterSearch,
  FetchVehiclePlacementDetailReports,
  FetchVehiclePlacementDetailReportsForExcel,
})(vehiclePlacementDetail);
