import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
  Input,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { createTransporter } from "../../store/transporter/actions";

import formatInput from "../InputFormatter";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class TransporterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onSubmitDisable: false,
      breadcrumbItems: [
        { title: userLang?.common?.TRANSPORTER, link: "transporter" },
        { title: userLang?.common?.ADD_TRANSPORTER, link: "#" },
      ],
      destinationDisable: false,
      form: {
        fullName: "",
        email: "",
        phone: "",
        zipCode: "",
        state: "",
        city: "",
      },
      formErrors: {
        fullName: "",
        email: "",
        phone: "",
        zipCode: "",
        state: "",
        city: "",
      },
    };

    this.statesList = [
      this.initialVal("state"),
      {
        label: "Andaman and Nicobar Islands",
        value: "Andaman and Nicobar Islands",
      },
      { label: "Andhra Pradesh", value: "Andhra Pradesh" },
      { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
      { label: "Assam", value: "Assam" },
      { label: "Bihar", value: "Bihar" },
      { label: "Chandigarh", value: "Chandigarh" },
      { label: "Chhattisgarh", value: "Chhattisgarh" },
      {
        label: "Dadra and Nagar Haveli and Daman and Diu",
        value: "Dadra and Nagar Haveli and Daman and Diu",
      },
      { label: "Delhi", value: "Delhi" },
      { label: "Goa", value: "Goa" },
      { label: "Gujarat", value: "Gujarat" },
      { label: "Haryana", value: "Haryana" },
      { label: "Himachal Pradesh", value: "Himachal Pradesh" },
      { label: "Jammu and Kashmir", value: "Jammu and Kashmir" },
      { label: "Jharkhand", value: "Jharkhand" },
      { label: "Karnataka", value: "Karnataka" },
      { label: "Kerala", value: "Kerala" },
      { label: "Ladakh", value: "Ladakh" },
      { label: "Lakshadweep", value: "Lakshadweep" },
      { label: "Madhya Pradesh", value: "Madhya Pradesh" },
      { label: "Maharashtra", value: "Maharashtra" },
      { label: "Manipur", value: "Manipur" },
      { label: "Meghalaya", value: "Meghalaya" },
      { label: "Mizoram", value: "Mizoram" },
      { label: "Nagaland", value: "Nagaland" },
      { label: "Odisha", value: "Odisha" },
      { label: "Puducherry", value: "Puducherry" },
      { label: "Punjab", value: "Punjab" },
      { label: "Rajasthan", value: "Rajasthan" },
      { label: "Sikkim", value: "Sikkim" },
      { label: "Tamil Nadu", value: "Tamil Nadu" },
      { label: "Telangana", value: "Telangana" },
      { label: "Tripura", value: "Tripura" },
      { label: "Uttar Pradesh", value: "Uttar Pradesh" },
      { label: "Uttarakhand", value: "Uttarakhand" },
      { label: "West Bengal", value: "West Bengal" },
    ];
  }

  initialVal = (val) => {
    return { label: "Select " + val, value: "", isDisabled: true };
  };

  reset = () => {
    let formData = {
      fullName: "",
      email: "",
      phone: "",
      zipCode: "",
      state: "",
      city: "",
    };

    this.setState({
      form: formData,
    });
  };

  validEmail = (val) => {
    return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(val);
  };

  validateField = (name, value, refValue) => {
    let errorMsg = null;
    // const { form } = this.state;

    switch (name) {
      case "fullName":
        if (!value) errorMsg = "Please Select Full Name";

        break;
      case "email":
        if (!value) errorMsg = "Please Enter Email.";
        else {
          if (!this.validEmail(value)) errorMsg = "Please Enter valid email.";
        }
        break;
      case "phone":
        if (!value) errorMsg = "Please Select phone.";

        break;

      // case "zipCode":
      //   if (!value) errorMsg = "Please Enter zipCode.";
      //   else if (value.length < 6 || value.length > 6)
      //     errorMsg = "Zip code should be of length 6";
      //   break;
      // case "state":
      //   if (!value) errorMsg = "Please Select state";

      //   break;
      // case "city":
      //   if (!value) errorMsg = "Please Select city";

      //   break;

      default:
        break;
    }
    return errorMsg;
  };
  handleChange = (e) => {
    const { name, value } = e.target;

    const { form, formErrors } = this.state;
    let formObj = {};

    formObj = {
      ...form,
      [name]: value,
    };
    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};

      const errorMsg = this.validateField(
        name,
        name === "language" ? this.state.form["language"] : value
      );
      formErrorsObj = { ...formErrors, [name]: errorMsg };
      this.setState({ formErrors: formErrorsObj });
    });
  };
  trimSpace = (event, name) => {
    const { form } = this.state;
    let formObj = formatInput(event, name, form);
    this.setState({ form: formObj });
  };

  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map((x) => {
      let refValue = null;
      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
      return true
    });
    return errorObj;
  };

  handleSubmit = () => {
    this.setState({ onSubmitDisable: true });
    const { form, formErrors } = this.state;
    const errorObj = this.validateForm(form, formErrors, this.validateField);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({
        onSubmitDisable: false,
        formErrors: { ...formErrors, ...errorObj },
      });
      return false;
    }

    let formVal = form;

    this.props.createTransporter(formVal, (res) => {
      if (
        res["message"] &&
        res["message"] === "Transporter has been created successfully"
      ) {
        NotificationManager.success(res["message"], "Success");
        window.history.replaceState(null, null, "/");
        this.props.history.push("/transporter");
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({ onSubmitDisable: false });
      }
    });
  };
  render() {
    const { form, formErrors, onSubmitDisable } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={userLang?.common?.ADD_TRANSPORTER}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <form>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">
                              {userLang?.common?.TRANSPORTER_NAME}
                            </Label>

                            <Input
                              className="form-control"
                              type="text"
                              value={form.fullName}
                              onBlur={(e) => this.trimSpace(e, "fullName")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "fullName",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder={userLang?.common?.FULL_NAME}
                            />
                            {formErrors.fullName && (
                              <span className="err">{formErrors.fullName}</span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">
                              {" "}
                              {userLang?.common?.EMAIL}
                            </Label>

                            <Input
                              className="form-control"
                              type="email"
                              value={form.email}
                              onBlur={(e) => this.trimSpace(e, "email")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "email",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder={userLang?.common?.EMAIL}
                            />
                            {formErrors.email && (
                              <span className="err">{formErrors.email}</span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">
                              {userLang?.common?.PHONE_NO}
                            </Label>

                            <Input
                              className="form-control"
                              type="number"
                              value={form.phone}
                              onBlur={(e) => this.trimSpace(e, "phone")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "phone",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder={userLang?.common?.PHONE_NO}
                            />
                            {formErrors.phone && (
                              <span className="err">{formErrors.phone}</span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>{userLang?.common?.STATE}</Label>
                            <Select
                              value={this.statesList.find(
                                (x) => x.value === form.state
                              )}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "state",
                                    value: e.value,
                                  },
                                })
                              }
                              options={this.statesList}
                              classNamePrefix="select2-selection"
                            />

                            {formErrors.state && (
                              <span className="err">{formErrors.state}</span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>{userLang?.common?.CITY}</Label>

                            <Input
                              className="form-control"
                              type="city"
                              value={form.city}
                              onBlur={(e) => this.trimSpace(e, "city")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "city",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder={userLang?.common?.CITY}
                            />
                            {formErrors.city && (
                              <span className="err">{formErrors.city}</span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>{userLang?.common?.ZIP_CODE}</Label>
                            <Input
                              className="form-control"
                              type="number"
                              value={form.zipCode}
                              onBlur={(e) => this.trimSpace(e, "zipCode")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "zipCode",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="ex: 560011"
                              onKeyPress={this.validateNumber}
                            />
                            {formErrors.zipCode && (
                              <span className="err">{formErrors.zipCode}</span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <FormGroup className="mb-0 text-center ">
                        <div>
                          <Button
                            disabled={onSubmitDisable}
                            type="reset"
                            color="secondary"
                            onClick={this.reset}
                          >
                            {userLang?.common?.RESET}
                          </Button>{" "}
                          <Button
                            disabled={onSubmitDisable}
                            type="button"
                            color="btn btn-primary"
                            className="mr-1"
                            onClick={this.handleSubmit}
                          >
                            {userLang?.common?.SUBMIT}
                          </Button>
                        </div>
                      </FormGroup>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  transporters: state.Transporter.transporters,
});

export default connect(mapStateToProps, { createTransporter })(TransporterForm);
