import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { NotificationManager } from "react-notifications";
import { MoonLoader } from "react-spinners";
import { connect } from "react-redux";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  createVehicle,
  editVehicleById,
  fetchVehicleById,
  // deleteVehicle,
} from "../../store/vehicle/actions";
import formatInput from "../InputFormatter";


class EditVehicleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onSubmitDisable: false,
      loading: false,
      breadcrumbItems: [
        { title: "Vehicle", link: "vehicles" },
        { title: "Edit Vehicle", link: "#" },
      ],

      form: {
        Id: "",
        VehicleOwner: "",
        VehicleOwnerContactNo: "",
        VehicleRegistrationState: "",
        RegistredRTO: "",
        VendorName: "",
        VehicleRegistrationNumber: "",
        vehicleStatus: "",
        VehicleAppearance: "",
        GPSEnabled: "",
      },
      formErrors: {
        VehicleOwner: "",
        VehicleOwnerContactNo: "",
        RegistredRTO: "",
        VendorName: "",
        VehicleRegistrationNumber: "",
        VehicleRegistrationState: "",
        vehicleStatus: "",
        VehicleAppearance: "",
        GPSEnabled: "",
      },
    };

    this.VehicleRegistrationStateList = [
      this.initialVal("state"),
      {
        label: "Andaman and Nicobar Islands",
        value: "Andaman and Nicobar Islands",
      },
      { label: "Andhra Pradesh", value: "Andhra Pradesh" },
      { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
      { label: "Assam", value: "Assam" },
      { label: "Bihar", value: "Bihar" },
      { label: "Chandigarh", value: "Chandigarh" },
      { label: "Chhattisgarh", value: "Chhattisgarh" },
      {
        label: "Dadra and Nagar Haveli and Daman and Diu",
        value: "Dadra and Nagar Haveli and Daman and Diu",
      },
      { label: "Delhi", value: "Delhi" },
      { label: "Goa", value: "Goa" },
      { label: "Gujarat", value: "Gujarat" },
      { label: "Haryana", value: "Haryana" },
      { label: "Himachal Pradesh", value: "Himachal Pradesh" },
      { label: "Jammu and Kashmir", value: "Jammu and Kashmir" },
      { label: "Jharkhand", value: "Jharkhand" },
      { label: "Karnataka", value: "Karnataka" },
      { label: "Kerala", value: "Kerala" },
      { label: "Ladakh", value: "Ladakh" },
      { label: "Lakshadweep", value: "Lakshadweep" },
      { label: "Madhya Pradesh", value: "Madhya Pradesh" },
      { label: "Maharashtra", value: "Maharashtra" },
      { label: "Manipur", value: "Manipur" },
      { label: "Meghalaya", value: "Meghalaya" },
      { label: "Mizoram", value: "Mizoram" },
      { label: "Nagaland", value: "Nagaland" },
      { label: "Odisha", value: "Odisha" },
      { label: "Puducherry", value: "Puducherry" },
      { label: "Punjab", value: "Punjab" },
      { label: "Rajasthan", value: "Rajasthan" },
      { label: "Sikkim", value: "Sikkim" },
      { label: "Tamil Nadu", value: "Tamil Nadu" },
      { label: "Telangana", value: "Telangana" },
      { label: "Tripura", value: "Tripura" },
      { label: "Uttar Pradesh", value: "Uttar Pradesh" },
      { label: "Uttarakhand", value: "Uttarakhand" },
      { label: "West Bengal", value: "West Bengal" },
    ];
    this.VehicleAppearanceList = [
      this.initialVal("any"),

      { label: "OPEN", value: "OPEN" },
      {
        label: "COVERED",
        value: "COVERED",
      },
    ];
    this.vehicleContributorList = [
      this.initialVal("any"),

      { label: "MASTER", value: "MASTER" },
      {
        label: "OTHER",
        value: "OTHER",
      },
    ];
    this.vehicleStatus = [
      this.initialVal("status"),
      { label: "AVAILABLE", value: "AVAILABLE" },
      { label: "ASSIGNED", value: "ASSIGNED" },
    ];
    this.GPSEnabledList = [
      this.initialVal("any"),
      { label: "YES", value: "YES" },
      { label: "NO", value: "NO" },
    ];
  }
  componentDidMount = () => {
    this.setState({ loading: true });
    const id = window.location.pathname.split("/edit-vehicle-")[1];
    this.props.fetchVehicleById(id, (res) => {
      const form = {
        Id: res._id,
        VehicleOwner: res.VehicleOwner,
        VehicleOwnerContactNo: res.VehicleOwnerContactNo,
        VehicleRegistrationState: res.VehicleRegistrationState.toUpperCase(),
        RegistredRTO: res.RegistredRTO,
        VendorName: res.VendorName,
        VehicleRegistrationNumber: res.VehicleRegistrationNumber,
        vehicleStatus: res.vehicleStatus.toUpperCase(),
        VehicleAppearance: res.VehicleAppearance.toUpperCase(),
        GPSEnabled: res.GPSEnabled.toUpperCase(),
      };
      this.setState({ form, loading: false });
    });
  };
  initialVal = (val) => {
    return { label: "Select " + val, value: "", isDisabled: true };
  };
  validateField = (name, value, refValue) => {
    let errorMsg = null;

    switch (name) {
      case "VehicleOwner":
        if (!value) errorMsg = "Please Enter VehicleOwner.";
        break;
      case "VehicleOwnerContactNo":
        if (!value) errorMsg = "Please Enter Vehicle Owner Contact No.";
        if (value.length === undefined) {
          if (JSON.stringify(value).length !== 10)
            errorMsg = "Please Enter Valid Number.";
        } else {
          if (value.length !== 10) errorMsg = "Please Enter Valid Number.";
        }
        break;

      case "VehicleRegistrationState":
        if (!value) errorMsg = "Please Enter Vehicle Registration State.";
        break;
      case "RegistredRTO":
        if (!value) errorMsg = "Please Enter Registred RTO.";
        break;
      // case "VendorName":
      //   if (!value) errorMsg = "Please Enter Vendor Name.";
      //   break;
      case "VehicleAppearance":
        if (!value) errorMsg = "Please select Vehicle Appearance.";
        break;
      case "VehicleRegistrationNumber":
        if (!value) errorMsg = "Please Enter Vehicle Registration Number.";
        break;

      case "GPSEnabled":
        if (!value) errorMsg = "Please Select GPS Enabled.";
        break;
      case "vehicleStatus":
        if (!value) errorMsg = "Please Select Status.";
        break;
      default:
        break;
    }
    return errorMsg;
  };

  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map((x) => {
      let refValue = null;

      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
      return true
    });
    return errorObj;
  };
  validateNumber = (evt) => {
    var theEvent = evt || window.event;

    // Handle paste
    if (theEvent.type === "paste") {
      var key = theEvent.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };
  handleSubmit = () => {
    this.setState({ onSubmitDisable: true });
    const { form, formErrors } = this.state;
    const errorObj = this.validateForm(form, formErrors, this.validateField);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({
        onSubmitDisable: false,
        formErrors: { ...formErrors, ...errorObj },
      });
      return false;
    }

    //console.log('Data: ', form);
    this.props.editVehicleById(form, (res) => {
      if (
        res["message"] &&
        res["message"] === "Vehicle has been successfully updated"
      ) {
        NotificationManager.success(res["message"], "Success");
        this.props.history.push("/vehicles");
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({ onSubmitDisable: false });
      }
    });
  };
  reset = () => {
    let formData = {
      VehicleOwner: "",
      VehicleOwnerContactNo: "",
      RegistredRTO: "",
      VendorName: "",
      VehicleAppearance: "",
      VehicleRegistrationNumber: "",
      VehicleRegistrationState: "",
      vehicleWt: "",
      vehicleLen: "",
      vehicleStatus: "",
      vehicleHeight: "",
      GPSEnabled: "",
    };

    this.setState({
      form: formData,
    });
    //console.log('form', this.state.form);
    this.props.history.push("/vehicles");

  };
  handleChange = (e) => {
    const { name, value } = e.target;
    const { form, formErrors } = this.state;
    let formObj = {};

    formObj = {
      ...form,
      [name]: value,
    };
    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};

      const errorMsg = this.validateField(
        name,
        name === "language" ? this.state.form["language"] : value
      );
      formErrorsObj = { ...formErrors, [name]: errorMsg };
      this.setState({ formErrors: formErrorsObj });
    });
  };


  trimSpace = (event, name) => {
    const { form } = this.state;
    let formObj = formatInput(event, name, form);
    this.setState({ form: formObj });
  };
  render() {
    const { form, formErrors, loading, onSubmitDisable } = this.state;
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Edit Vehicle"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <form>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">
                              Vehicle Owner
                            </Label>

                            <Input
                              className="form-control"
                              type="text"
                              value={form.VehicleOwner}
                              onBlur={(e)=>this.trimSpace(e,"VehicleOwner")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "VehicleOwner",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="vehicle Owner"
                            />
                            {formErrors.VehicleOwner && (
                              <span className="err">
                                {formErrors.VehicleOwner}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">
                              Vehicle Owner Contact No
                            </Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.VehicleOwnerContactNo}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "VehicleOwnerContactNo",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="97565643434"
                              onKeyPress={this.validateNumber}
                            />
                            {formErrors.VehicleOwnerContactNo && (
                              <span className="err">
                                {formErrors.VehicleOwnerContactNo}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">
                              Vehicle Registration No
                            </Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.VehicleRegistrationNumber}
                              onBlur={(e)=>this.trimSpace(e,"VehicleRegistrationNumber")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "VehicleRegistrationNumber",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="97565643434"
                            />
                            {formErrors.VehicleRegistrationNumber && (
                              <span className="err">
                                {formErrors.VehicleRegistrationNumber}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">
                              Vehicle Registration State
                            </Label>
                            <Select
                              value={this.VehicleRegistrationStateList.find(
                                (x) =>
                                  x.value.toUpperCase() ===
                                  form.VehicleRegistrationState.toUpperCase()
                              )}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "VehicleRegistrationState",
                                    value: e.value,
                                  },
                                })
                              }
                              options={this.VehicleRegistrationStateList}
                              classNamePrefix="select2-selection"
                            />

                            {formErrors.VehicleRegistrationState && (
                              <span className="err">
                                {formErrors.VehicleRegistrationState}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">
                              Registered RTO
                            </Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.RegistredRTO}
                              onBlur={(e)=>this.trimSpace(e,"RegistredRTO")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "RegistredRTO",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="ex: Bangalore"
                            />
                            {formErrors.RegistredRTO && (
                              <span className="err">
                                {formErrors.RegistredRTO}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>Vendor Name</Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.VendorName}
                              onBlur={(e)=>this.trimSpace(e,"VendorName")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "VendorName",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="ex: STAR GLOBAL"
                            />
                            {formErrors.VendorName && (
                              <span className="err">
                                {formErrors.VendorName}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">
                              Open/Closed Vehicle
                            </Label>
                            <Select
                              value={this.VehicleAppearanceList.find(
                                (x) => x.value === form.VehicleAppearance
                              )}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "VehicleAppearance",
                                    value: e.value,
                                  },
                                })
                              }
                              options={this.VehicleAppearanceList}
                              classNamePrefix="select2-selection"
                            />

                            {formErrors.VehicleAppearance && (
                              <span className="err">
                                {formErrors.VehicleAppearance}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">
                              Gps Enabled
                            </Label>
                            <Select
                              value={this.GPSEnabledList.find(
                                (x) => x.value === form.GPSEnabled
                              )}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "GPSEnabled",
                                    value: e.value,
                                  },
                                })
                              }
                              options={this.GPSEnabledList}
                              classNamePrefix="select2-selection"
                            />

                            {formErrors.GPSEnabled && (
                              <span className="err">
                                {formErrors.GPSEnabled}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">
                              Vehicle Status
                            </Label>
                            <Select
                              value={this.vehicleStatus.find(
                                (x) => x.value === form.vehicleStatus
                              )}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "vehicleStatus",
                                    value: e.value,
                                  },
                                })
                              }
                              options={this.vehicleStatus}
                            />

                            {formErrors.vehicleStatus && (
                              <span className="err">
                                {formErrors.vehicleStatus}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row></Row>
                      <FormGroup className="mb-0 text-center ">
                        <div>
                          <Button
                            type="reset"
                            color="secondary"
                            onClick={this.reset}
                          >
                            Cancel
                          </Button>{" "}
                          <Button
                            disabled={onSubmitDisable}
                            type="button"
                            color="btn btn-primary"
                            className="mr-1"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                      </FormGroup>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(null, {
  createVehicle,
  editVehicleById,
  fetchVehicleById,
})(EditVehicleForm);
