import { types } from "./actionTypes";
const initialState = {
  getproductDetails: null,
};
const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PRODUCTS_SUCCESS:
      state = {
        ...state,
        getproductDetails: action.payload,
      };
      return state;
    default:
      return state;
  }
};
export default productReducer;
