import React from 'react'
import { Button as MuiButton, makeStyles } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
  },
  label: {
    textTransform: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '16px',
    
  },
}))

export default function Button(props) {

    const { text, size, variant,icon, onClick, ...other } = props
    const classes = useStyles();

    return (
        <MuiButton
        variant={variant || "contained"}
        size={size || "large"}
        // color={color ||"white"}
        onClick={onClick}
        {...other}
        classes={{ root: classes.root, label: classes.label }}>
        {text}
    </MuiButton>
    )
}