import { types } from "../actionTypes";
const initialState = {
    FreighstMasterData: [],
    fetchId:"",
};
export default (state = { initialState }, action) => {
  switch (action.type) {
    case types.FREIGHTS_MASTER_SUCCESS:
      return { ...state, FreightsMasterData: action.payload ,fetchId: "id" + new Date().getTime()};
    default:
      state = { ...state };
      break;
  }

  return state;
};
