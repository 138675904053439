import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import { connect } from "react-redux";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { createHop } from "../../store/hop/actions";
import TimePicker from "react-time-picker";
import formatInput from "../InputFormatter";
class HopForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onSubmitDisable: false,
      breadcrumbItems: [
        { title: "Hop", link: "hops" },
        { title: "Add Hop", link: "#" },
      ],

      form: {
        hopName: "",
        contact: "",
        hopType: "",
        description: "",
        website: "",
        email: "",
        gstin: "",
        openingTime: "10:00",
        closingTime: "10:00",
        zipCode: "",
        city: "",
        state: "",
        address: "",
      },
      formErrors: {
        hopName: "",
        contact: "",
        description: "",
        website: "",
        email: "",
        gstin: "",
        hopType: "",
        zipCode: "",
        city: "",
        state: "",
        address: "",

        openingTime: "",
        closingTime: "",
      },
    };
    this.statesList = [
      this.initialVal("state"),
      {
        label: "Andaman and Nicobar Islands",
        value: "Andaman and Nicobar Islands",
      },
      { label: "Andhra Pradesh", value: "Andhra Pradesh" },
      { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
      { label: "Assam", value: "Assam" },
      { label: "Bihar", value: "Bihar" },
      { label: "Chandigarh", value: "Chandigarh" },
      { label: "Chhattisgarh", value: "Chhattisgarh" },
      {
        label: "Dadra and Nagar Haveli and Daman and Diu",
        value: "Dadra and Nagar Haveli and Daman and Diu",
      },
      { label: "Delhi", value: "Delhi" },
      { label: "Goa", value: "Goa" },
      { label: "Gujarat", value: "Gujarat" },
      { label: "Haryana", value: "Haryana" },
      { label: "Himachal Pradesh", value: "Himachal Pradesh" },
      { label: "Jammu and Kashmir", value: "Jammu and Kashmir" },
      { label: "Jharkhand", value: "Jharkhand" },
      { label: "Karnataka", value: "Karnataka" },
      { label: "Kerala", value: "Kerala" },
      { label: "Ladakh", value: "Ladakh" },
      { label: "Lakshadweep", value: "Lakshadweep" },
      { label: "Madhya Pradesh", value: "Madhya Pradesh" },
      { label: "Maharashtra", value: "Maharashtra" },
      { label: "Manipur", value: "Manipur" },
      { label: "Meghalaya", value: "Meghalaya" },
      { label: "Mizoram", value: "Mizoram" },
      { label: "Nagaland", value: "Nagaland" },
      { label: "Odisha", value: "Odisha" },
      { label: "Puducherry", value: "Puducherry" },
      { label: "Punjab", value: "Punjab" },
      { label: "Rajasthan", value: "Rajasthan" },
      { label: "Sikkim", value: "Sikkim" },
      { label: "Tamil Nadu", value: "Tamil Nadu" },
      { label: "Telangana", value: "Telangana" },
      { label: "Tripura", value: "Tripura" },
      { label: "Uttar Pradesh", value: "Uttar Pradesh" },
      { label: "Uttarakhand", value: "Uttarakhand" },
      { label: "West Bengal", value: "West Bengal" },
    ];
    this.hopTypeList = [
      this.initialVal("hop type"),
      { label: "Factory", value: "Factory" },
      { label: "Hub", value: "Hub" },
      { label: "Warehouse", value: "Warehouse" },
      { label: "ASO", value: "ASO" },

      // { label: "Dealer", value: "Dealer" }
    ];
    this.HopAppearanceList = [
      this.initialVal("any"),

      { label: "OPEN", value: "OPEN" },
      {
        label: "COVERED",
        value: "COVERED",
      },
    ];
    this.vehicleContributorList = [
      this.initialVal("any"),

      { label: "MASTER", value: "MASTER" },
      {
        label: "OTHER",
        value: "OTHER",
      },
    ];
    this.GPSEnabledList = [
      this.initialVal("any"),
      { label: "YES", value: "YES" },
      {
        label: "NO",
        value: "NO",
      },
    ];
  }
  initialVal = (val) => {
    return { label: "Select " + val, value: "", isDisabled: true };
  };
  validEmail = (val) => {
    return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(val);
  };
  validateField = (name, value, refValue) => {
    let errorMsg = null;
    //console.log('name', name, value);

    switch (name) {
      case "hopName":
        if (!value) errorMsg = "Please Enter Hop Name.";
        break;
      case "latitude":
        if (!value) errorMsg = "Please Enter Latitude.";
        break;
      case "longitude":
        if (!value) errorMsg = "Please Enter Longitude.";
        break;
      case "contact":
        if (!value) errorMsg = "Please Enter Contact.";
        if (value && value.length !== 10)
          errorMsg = "Please Enter valid contact no.";
        break;

      case "hopType":
        if (!value) errorMsg = "Please Select Hop Type.";
        break;
      // case "description":
      //   if (!value) errorMsg = "Please Enter Description.";
      //   break;
      // case "website":
      //   if (!value) errorMsg = "Please Enter Website.";
      //   break;
      case "zipCode":
        if (!value) errorMsg = "Please Enter zipCode.";
        else if (value.length < 6 || value.length > 6)
          errorMsg = "Zip code should be of length 6";
        break;
      case "city":
        if (!value) errorMsg = "Please Enter City.";
        break;
      case "state":
        if (!value) errorMsg = "Please Enter State.";
        break;
      case "address":
        if (!value) errorMsg = "Please Enter Address.";
        break;
      case "email":
        if (!value) errorMsg = "Please Enter Email.";
        else {
          if (!this.validEmail(value)) errorMsg = "Please Enter valid email.";
        }
        break;
      case "openingTime":
        if (!value) errorMsg = "Please Enter Opening Time.";
        break;
      case "closingTime":
        if (!value) errorMsg = "Please Enter Closing Time.";
        break;
      // case "gstin":
      //   if (!value) errorMsg = "Please Enter GSTIN.";
      //   break;
      default:
        break;
    }
    return errorMsg;
  };

  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map((x) => {
      let refValue = null;

      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
    });
    return errorObj;
  };
  validateNumber = (evt) => {
    var theEvent = evt || window.event;

    // Handle paste
    if (theEvent.type === "paste") {
      var key = theEvent.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  handleSubmit = () => {
    this.setState({ onSubmitDisable: true });
    const { form, formErrors } = this.state;
    const errorObj = this.validateForm(form, formErrors, this.validateField);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({
        onSubmitDisable: false,
        formErrors: { ...formErrors, ...errorObj },
      });
      return false;
    }

    let formVal = {
      contact: form.contact,
      description: form.description,
      email: form.email,
      GSTIN: form.gstin,
      hopName: form.hopName,
      hopType: form.hopType,
      openingTime: form.openingTime,
      closingTime: form.closingTime,
      website: form.website,
      zipCode: Number(form.zipCode),
      city: form.city,
      state: form.state,
      address: form.address,
    };

    this.props.createHop(formVal, (res) => {
      if (
        res["message"] &&
        res["message"] === "Hop has been created successfully"
      ) {
        NotificationManager.success(res["message"], "Success");
        this.props.history.push("/hops");
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({ onSubmitDisable: false });
      }
    });
  };
  reset = () => {
    let formData = {
      hopName: "",
      contact: "",
      hopType: "",
      description: "",
      website: "",
      email: "",
      gstin: "",
      openingTime: "10:00",
      closingTime: "10:00",
      zipCode: "",
      city: "",
      state: "",
      address: "",
    };

    this.setState({
      form: formData,
    });
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    const { form, formErrors } = this.state;
    let formObj = {};

    formObj = {
      ...form,
      [name]: value,
    };
    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};

      const errorMsg = this.validateField(
        name,
        name === "language" ? this.state.form["language"] : value
      );
      formErrorsObj = { ...formErrors, [name]: errorMsg };
      this.setState({ formErrors: formErrorsObj });
    });
  };

  trimSpace = (event, name) => {
    const { form } = this.state;
    let formObj = formatInput(event, name, form);
    this.setState({ form: formObj });
  };
  render() {
    const { form, formErrors, onSubmitDisable } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Add Hop"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <form>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">Hop Name</Label>

                            <Input
                              className="form-control"
                              type="text"
                              value={form.hopName}
                              onBlur={(e) => this.trimSpace(e, "hopName")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "hopName",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="Hop Name"
                            />
                            {formErrors.hopName && (
                              <span className="err">{formErrors.hopName}</span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">Contact No</Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.contact}
                              onBlur={(e) => this.trimSpace(e, "contact")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "contact",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="97565643434"
                              onKeyPress={this.validateNumber}
                            />
                            {formErrors.contact && (
                              <span className="err">{formErrors.contact}</span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">State</Label>
                            <Select
                              value={this.statesList.find(
                                (x) => x.value === form.state
                              )}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "state",
                                    value: e.value,
                                  },
                                })
                              }
                              options={this.statesList}
                              classNamePrefix="select2-selection"
                            />

                            {formErrors.state && (
                              <span className="err">{formErrors.state}</span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">Zip Code</Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.zipCode}
                              onBlur={(e) => this.trimSpace(e, "zipCode")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "zipCode",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="ex: 560011"
                              onKeyPress={this.validateNumber}
                            />
                            {formErrors.zipCode && (
                              <span className="err">{formErrors.zipCode}</span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>{" "}
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">
                              Hop Address
                            </Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.address}
                              onBlur={(e) => this.trimSpace(e, "address")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "address",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="Ex: 201,Street Name,city"
                            />
                            {formErrors.address && (
                              <span className="err">{formErrors.address}</span>
                            )}
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">City</Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.city}
                              onBlur={(e) => this.trimSpace(e, "city")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "city",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="ex: Mumbai"
                            />
                            {formErrors.city && (
                              <span className="err">{formErrors.city}</span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">Email</Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.email}
                              onBlur={(e) => this.trimSpace(e, "email")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "email",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="username@gmail.com"
                            />
                            {formErrors.email && (
                              <span className="err">{formErrors.email}</span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label className="required-field">Hop Type</Label>
                            <Select
                              value={this.hopTypeList.find(
                                (x) =>
                                  x.value.toUpperCase() ===
                                  form.hopType.toUpperCase()
                              )}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "hopType",
                                    value: e.value,
                                  },
                                })
                              }
                              options={this.hopTypeList}
                              classNamePrefix="select2-selection"
                            />

                            {formErrors.hopType && (
                              <span className="err">{formErrors.hopType}</span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>Description</Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.description}
                              onBlur={(e) => this.trimSpace(e, "description")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "description",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="ex: Bangalore"
                            />
                            {formErrors.description && (
                              <span className="err">
                                {formErrors.description}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="select2-container">
                            <Label>Website</Label>
                            <Input
                              className="form-control"
                              type="text"
                              onBlur={(e) => this.trimSpace(e, "website")}
                              value={form.website}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "website",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="ex: bhatindahub.com"
                            />
                            {formErrors.website && (
                              <span className="err">{formErrors.website}</span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="">
                            <Label>GSTIN</Label>
                            <Input
                              className="form-control"
                              type="text"
                              value={form.gstin}
                              onBlur={(e) => this.trimSpace(e, "gstin")}
                              onChange={(e) =>
                                this.handleChange({
                                  target: {
                                    name: "gstin",
                                    value: e.target.value,
                                  },
                                })
                              }
                              placeholder="ex: 24AADCB2230M1Z2"
                            />
                            {formErrors.gstin && (
                              <span className="err">{formErrors.gstin}</span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup className="select2-container">
                            <Label className="required-field">
                              Opening Time
                            </Label>
                            <TimePicker
                              className="inlineFormCustomSelectPref"
                              onChange={(e) => {
                                this.handleChange({
                                  target: {
                                    name: "openingTime",
                                    value: e,
                                  },
                                });
                              }}
                              value={form.openingTime}
                            />

                            {formErrors.openingTime && (
                              <span className="err">
                                {formErrors.openingTime}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup className="select2-container">
                            <Label className="required-field">
                              Closing Time
                            </Label>
                            <TimePicker
                              onChange={(e) => {
                                this.handleChange({
                                  target: {
                                    name: "closingTime",
                                    value: e,
                                  },
                                });
                              }}
                              value={form.closingTime}
                            />
                            {formErrors.closingTime && (
                              <span className="err">
                                {formErrors.closingTime}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup className="mb-0 text-center ">
                        <div>
                          <Button
                            disabled={onSubmitDisable}
                            type="reset"
                            color="secondary"
                            onClick={this.reset}
                          >
                            Reset
                          </Button>{" "}
                          <Button
                            disabled={onSubmitDisable}
                            type="button"
                            color="btn btn-primary"
                            className="mr-1"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                      </FormGroup>
                    </form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(null, { createHop })(HopForm);
