import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Label } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { MoonLoader } from "react-spinners";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";
import MapsGoogle from "../Maps/TrackTripMap";
import { connect } from "react-redux";
import { fetchTripById, trackTripById } from "../../store/trip/actions";
import BootstrapTable from "react-bootstrap-table-next";
import formatDate from "../DateFormatter";

var data = {
  columns: [
    {
      dataField: "id",
      text: "No..",
    },

    {
      dataField: "DealerName",
      text: "DealerName",
    },
    {
      dataField: "OrderStatus",
      text: "OrderStatus",
    },
    {
      dataField: "ProductCode",
      text: "ProductCode.",
    },
    {
      dataField: "ProductName",
      text: "ProductName",
    },
    {
      dataField: "Quantity",
      text: "Quantity",
    },
    {
      dataField: "ProductPrice",
      text: "ProductPrice",
    },
    {
      dataField: "SubTotal",
      text: "SubTotal",
    },
    {
      dataField: "ExternalOrderId",
      text: "Ext.OrderId",
    },

    {
      dataField: "deliveredTime",
      text: "Delivered Time",
    },
  ],
  rows: []
};
class TrackTripForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tripDetails: "",
      driverLocations: "",
      breadcrumbItems: [
        { title: "Trip", link: "trips" },
        { title: "Track Trip", link: "#" },
      ],
      showingInfoWindow: false, // Hides or shows the InfoWindow
      activeMarker: {}, // Shows the active marker upon click
      selectedPlace: {},
    };
    this.ordersList = [];
  }
  componentDidMount() {
    const id = window.location.pathname.split("/track-trip-")[1];
    this.props.fetchTripById(id, (res) => {
      this.setState({ tripDetails: res });
      if (res && Array.isArray(res.orderDetails)) {
        this.ordersList = [];
        const rows = [];
        res.orderDetails.forEach((ele, i) => {

          ele.deliveredDate = ele.DeliveredTime
            ? formatDate(ele.DeliveredTime)
            : ele.systemDeliveredAt?formatDate(ele.systemDeliveredAt):res.completedAt?formatDate(res.completedAt):"";
          let deliveredDate = ele.deliveredDate ? ele.deliveredDate : "-";
          let dealerName = ele.DealerCode?.userId?.fullName
            ? ele.DealerCode.userId.fullName
            : "-";
          rows.push({
            _id: ele._id,
            id: i + 1,
            ProductCode: ele.ProductCode,
            ProductDimension: ele.ProductDimension,
            ProductName: ele.ProductName,
            Quantity: ele.Quantity,
            DealerName: dealerName,
            OrderStatus: ele.OrderStatus,
            ProductPrice: ele.ProductPrice,
            SubTotal: ele.SubTotal,
            ExternalOrderId: ele.ExternalOrderId,
            deliveredTime: deliveredDate,
          });
        });
        data.rows = [...rows];
      }
      this.setState({ loading: false });
      if(res.tripStatus === "In Transit"){
        this.interval = setInterval(() => {
          this.trackTrip(id);
        },15000);
      }else{
        this.trackTrip(id)
      }
    });
  }

  trackTrip = (id) => {
    this.props.trackTripById(id, (res) => {
      let current = [];
      res.map((ele,i) =>{
        current.push({
          lat: ele.cordinates[0],
          lng: ele.cordinates[1],
        })
        return true
      }
      );
      // console.log(res,"res",current)
      this.setState({ driverLocations: current });
    });
  };
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    const { tripDetails, driverLocations, loading } = this.state;
    const tableRowEvents = {
      onClick: (e, row) => {
        this.props.history.push(`/order-detail/` + row._id);
      },
    };
    if (loading) {
      return (
        <div className="page-content">
          <Col xs={12} className="loader">
            <MoonLoader size={45} color={"#123abc"} />
          </Col>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Track trip"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col lg="5">
                <Card>
                  <CardBody>
                    <form>
                      <Row>
                        <Col lg="4">
                          <Label key={"sl"}>Starting Location:</Label>
                        </Col>
                        <Col lg="8">
                          <Label key={"slv"}>
                            {tripDetails && tripDetails.startingLocation
                              ? tripDetails.startingLocation.name
                              : "-"}
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <Label key={"dl"}>Destination:</Label>
                        </Col>
                        <Col lg="8">
                          <Label key="dlv">
                            {tripDetails && tripDetails.destination
                              ? tripDetails.destination.name
                              : "-"}
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <Label key={"st"}>Start Time:</Label>
                        </Col>
                        <Col lg="8">
                          <Label key="stv">
                            {tripDetails && tripDetails.startTime
                              ? moment(new Date(tripDetails.startTime)).format(
                                  "DD-MM-YYYY HH:mm A"
                                )
                              : "-"}
                          </Label>
                        </Col>
                      </Row>
                      {(/true/i).test(process.env.REACT_APP_Kurlon)?null:
                      <Row>
                        <Col lg="4">
                          <Label key="v">Vehicles:</Label>
                        </Col>
                        <Col lg="8">
                          <Label key="vv">
                            {tripDetails && tripDetails.vehicleRegNo
                              ? tripDetails.vehicleRegNo
                                  .VehicleRegistrationNumber
                              : "-"}
                          </Label>
                        </Col>
                      </Row>}
                      {/* <Row>
                        <Col lg="4">
                          <Label key="o">Orders:</Label>
                        </Col>
                        <Col lg="8">
                          {this.ordersList.map((item) => (
                            <div>
                              <Label key="ov">{item.label}</Label>
                              <Label key="d">
                                {item.deliveredDate ? item.deliveredDate : ""}
                              </Label>
                            </div>
                          ))}
                        </Col>
                      </Row> */}
                      <Row>
                        <Col lg="4">
                          <Label key="d">Driver:</Label>
                        </Col>
                        <Col lg="8">
                          <Label key="dv">
                            {tripDetails &&
                            tripDetails.driverLicNo &&
                            tripDetails.driverLicNo.userId
                              ? tripDetails.driverLicNo.userId.fullName
                              : "-"}
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <Label key="s">Status:</Label>
                        </Col>
                        <Col lg="8">
                          <Label key="sv">
                            {tripDetails ? tripDetails.tripStatus : "-"}
                          </Label>
                        </Col>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="7">
                <MapsGoogle
                  driverLocations={driverLocations}
                  trackPoints={tripDetails}
                />
              </Col>
            </Row>
            <Row>
              <Card>
                <CardBody>
                  <div className="table-responsive " >
                    <div className="tracking-table">
                    <BootstrapTable
                      keyField="_id"
                      data={data.rows}
                      columns={data.columns}
                      rowEvents={tableRowEvents}
                      // pagination={paginationFactory(options)}
                    />
                  </div>
                  </div>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  trips: state.Trip.trips,
  drivers: state.Driver.drivers.availDrivers,
  productNames: state.Order.orders,
  vehicles: state.Vehicle.vehicles.avail_vehicles,
});

export default connect(mapStateToProps, {
  fetchTripById,
  trackTripById,
})(TrackTripForm);
