let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: "Parent Code",
        field: "parentCode",
        sort: "asc",
        width: 1,
      },
      {
        label: "Customer Code",
        field: "customerCode",
        sort: "asc",
        width: 1,
      },
      {
        label: "Alpha Name",
        field: "customerName",
        sort: "asc",
        width: 1,
      },
      {
        label: "CFA",
        field: "cfa",
        sort: "asc",
        width: 1,
      },
      {
        label: "Customers",
        field: "customers",
        sort: "asc",
        width: 1,
      },
      {
        label: "Customers Type",
        field: "customerType",
        sort: "asc",
        width: 1,
      },
      {
        label: "Customers Type Description",
        field: "customerTypeDesc",
        sort: "asc",
        width: 1,
      },
      {
        label: "Regional Description",
        field: "regionalDesc",
        sort: "asc",
        width: 1,
      },
      {
        label: "Address line1",
        field: "addressLine1",
        sort: "asc",
        width: 1,
      },
      {
        label: "Address line2",
        field: "addressLine2",
        sort: "asc",
        width: 1,
      },
      {
        label: "Address line3",
        field: "addressLine3",
        sort: "asc",
        width: 1,
      },
      {
        label: "Address line4",
        field: "addressLine4",
        sort: "asc",
        width: 1,
      },
  
      {
        label: "Postal Code",
        field: "postalCode",
        sort: "asc",
        width: 1,
      },
      {
        label: "City",
        field: "city",
        sort: "asc",
        width: 1,
      },
      {
        label: "State Description",
        field: "stateDescription",
        sort: "asc",
        width: 1,
      },
      {
        label: "Country Description",
        field: "countryDescription",
        sort: "asc",
        width: 1,
      },
      {
        label: "Active/Inactive",
        field: "active_inactive",
        sort: "asc",
        width: 1,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var AdminHeaderData = {
  columns: [
    {
      label: "Parent Code",
      field: "parentCode",
      sort: "asc",
      width: 1,
    },
    {
      label: "Customer Code",
      field: "customerCode",
      sort: "asc",
      width: 1,
    },
    {
      label: "Alpha Name",
      field: "customerName",
      sort: "asc",
      width: 1,
    },
    {
      label: "CFA",
      field: "cfa",
      sort: "asc",
      width: 1,
    },
    {
      label: "Customers",
      field: "customers",
      sort: "asc",
      width: 1,
    },
    {
      label: "Customers Type",
      field: "customerType",
      sort: "asc",
      width: 1,
    },
    {
      label: "Customers Type Description",
      field: "customerTypeDesc",
      sort: "asc",
      width: 1,
    },
    {
      label: "Regional Description",
      field: "regionalDesc",
      sort: "asc",
      width: 1,
    },
    {
      label: "Address line1",
      field: "addressLine1",
      sort: "asc",
      width: 1,
    },
    {
      label: "Address line2",
      field: "addressLine2",
      sort: "asc",
      width: 1,
    },
    {
      label: "Address line3",
      field: "addressLine3",
      sort: "asc",
      width: 1,
    },
    {
      label: "Address line4",
      field: "addressLine4",
      sort: "asc",
      width: 1,
    },

    {
      label: "Postal Code",
      field: "postalCode",
      sort: "asc",
      width: 1,
    },
    {
      label: "City",
      field: "city",
      sort: "asc",
      width: 1,
    },
    {
      label: "State Description",
      field: "stateDescription",
      sort: "asc",
      width: 1,
    },
    {
      label: "Country Description",
      field: "countryDescription",
      sort: "asc",
      width: 1,
    },
    {
      label: "Active/Inactive",
      field: "active_inactive",
      sort: "asc",
      width: 1,
    },
  ],
};
export var searchBar;
searchBar = {
  searchBarData: [
    {
      label: "Parent Code",
      field: "parentCode",
      type: "text",
    },
    {
      label: "Customer Code",
      field: "customerCode",
      type: "text",
    },
    {
      label: "Alpha Name",
      field: "alphaName",
      type: "text",
    },
    {
      label: "cfa",
      field: "cfa",
      type: "text",
    },
    {
      label: "Customers",
      field: "customers",
      type: "text",
    },
    {
      label: "Customer Type",
      field: "customerType",
      type: "text",
    },
    {
      label: "Customer Type Description",
      field: "customerTypeDescription",
      type: "text",
    },
    {
      label: "Postal Code",
      field: "postalCode",
      type: "text",
    },
    {
      label: "City",
      field: "city",
      type: "text",
    },
    {
      label: "State Description",
      field: "stateDescription",
      type: "text",
    },
    {
      label: "Country Description",
      field: "countryDescription",
      type: "text",
    },
    {
      label: "Regional Description",
      field: "regionalDescription",
      type: "text",
    },

    {
      label: "Status",
      field: "status",
      type: "text",
    },
    {},
  ],
};