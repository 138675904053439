// import { mapKeys } from "lodash";
// import { retry } from "@redux-saga/core/effects";
// import { result } from "lodash";
// import { actions } from "../../../pages/MastersDriver/actionButtons";
import { types } from "../actionTypes";

const initialState = {
  agreementData: {
    charges: {
      vehicleNonPlacement: [],
      multipointPickup: [],
      multipointDrop: [],
      sourceDetention: [],
      destinationDetention: [],
      lateDelivery: [],
      cancellationUponArrivalByCompany: [],
    },
  },
  copiedAgreementValues: {
    id: "",
    charges: "",
    transporter: {},
    startDate: "",
    expiryDate: "",
  },
  agreements: {
    docs: [],
    offset: 0,
  },
  startDate: "",
  endDate: "",
  invoices: { docs: [], offset: 0, length: 0 },
  bills: { docs: [], offset: 0 },
  createInvoice: { docs: [] },
  invoice: {},
  fetchId: "",
  viewMode: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_AGREEMENT_VALUES:
      return {
        ...state,
        copiedAgreementValues: {
          id: "",
          charges: "",
          transporter: {},
          startDate: "",
          expiryDate: "",
          viewMode: false,
          endDate: "",
        },
        agreementData: {
          charges: {
            vehicleNonPlacement: [],
            multipointPickup: [],
            multipointDrop: [],
            sourceDetention: [],
            destinationDetention: [],
            lateDelivery: [],
            cancellationUponArrivalByCompany: [],
          },
        },
      };

    case types.FETCH_LAST_AGREEMENT_VALUES_SUCCESS:
      let data = {
        id: action.payload.result[0]._id,
        charges: action.payload.result[0].charges,
        transporter: action.payload.result[0].transporter,
        startDate: "",
        expiryDate: "",
      };
      //consolelog("charges", data);
      return {
        ...state,
        copiedAgreementValues: data,
        fetchId: "id" + new Date().getTime(),
        viewMode: false,
      };

    case types.DATE_CAHNGE:
      //consolelog("in reducer----", action.payload);
      // console.log("values:", action);
      if (action.values.key === "endDate")
        return {
          ...state,
          endDate: action.values,
          fetchId: "id" + new Date().getTime(),
        };
      else if (action.values.key === "startDate")
        return {
          ...state,
          startDate: action.values,
          fetchId: "id" + new Date().getTime(),
        };
        break;

    case types.VIEW_AGREEMENT_SUCCESS:
      //consolelog("inside reducer view agreemnt", action.payload);
      let viewData = {
        id: action.payload.result._id,
        charges: action.payload.result.charges,
        transporter: action.payload.result.transporter,
        startDate: action.payload.result.startDate,
        expiryDate: action.payload.result.expiryDate,
      };
      return { ...state, copiedAgreementValues: viewData, viewMode: true };

    case types.FETCH_AGREEMENTS_SUCCESS:
      return {
        ...state,
        agreements: {
          docs: action.payload.docs,
          offset: action.payload.offset,
          length: action.payload.length,
        },
        fetchId: "id" + new Date().getTime(),
      };

    case types.CREATE_AGREEMENT_SUCCESS:
      //consolelog(action.payload);
      break;

    case types.STORE_AGREEMENT:
      //consolelog(action.payload);
      break;

    case types.FETCH_INVOICES_SUCCESS:
      //consolelog(action.payload);
      return {
        ...state,
        invoices: {
          docs: action.payload.docs,
          offset: action.payload.offset,
          length: action.payload.length,
          fetchId: "id" + new Date().getTime(),
        },
      };

    case types.FETCH_BILLS_SUCCESS:
      //consolelog("fetched BILLS", action.payload);
      return {
        ...state,
        bills: {
          docs: action.payload.docs,
          offset: action.payload.offset,
          length: action.payload.length,
        },
        fetchId: "id" + new Date().getTime(),
      };
    case types.CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        createInvoice: action.payload.result,
      };
    case types.FETCH_INVOICE_BYID_SUCCESS:
      //consolelog("API RES", action.payload.result);
      return {
        ...state,
        invoice: action.payload.result,
      };
    case types.CLEAR_LOADED_VALUES:
      return {
        ...state,
        copiedAgreementValues: {
          id: "",
          charges: "",
          transporter: {},
          startDate: "",
          expiryDate: "",
        },
      };
    case types.ADD_NEW_BILL_SUCCESS:
      return { ...state, NewBill: action.payload };
    case types.CLEAR_BILLS:
      return { ...state, bills: { docs: [], offset: 0 } };
    default:
      state = { ...state };
      break;
  }

  return state;
};
