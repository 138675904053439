import React, { Component } from "react";
import { Card, Button } from "reactstrap";
import { InputGroup } from "rsuite";
// images
import exportIcon from "./assets/external-link.png";
import exportDarkIcon from "./assets/external-linkDark.png";
import refreshIcon from "./assets/refresh-cw.png";

// components
import Datepicker from "../../components/Common/DatePicker/DatePicker";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var darkMode;
class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLangs: undefined,
      dateValue: [null, null],
      dispatchDateValue: [null, null],
      showCalender: false,
      fromDate: null,
      toDate: null,
      dispatchFromDate: null,
      dispatchToDate: null,
    };
  }

  componentDidMount = () => {
    let storedData = localStorage.getItem("tripsFilterData")
      ? JSON.parse(localStorage.getItem("tripsFilterData"))
      : null;

    let dateDashboard = this.props.dashTopFilter;

    if (
      (dateDashboard?.startDate !== undefined &&
        dateDashboard?.endDate !== undefined) ||
      (storedData &&
        storedData["startTimeStart"] !== undefined &&
        storedData["startTimeEnd"] !== undefined)
    ) {
      let date1 = dateDashboard?.startDate
        ? new Date(dateDashboard.startDate)
        : null;
      let date2 = dateDashboard?.endDate
        ? new Date(dateDashboard.endDate)
        : null;

      if (!date1 || isNaN(date1.getTime())) {
        date1 = storedData?.startTimeStart
          ? new Date(storedData.startTimeStart)
          : null;
      }

      if (!date2 || isNaN(date2.getTime())) {
        date2 = storedData?.startTimeEnd
          ? new Date(storedData.startTimeEnd)
          : null;
      }

      this.setState({ fromDate: date1, toDate: date2 });
    }


    if (
    
      (storedData &&
        storedData["dispatchStartDate"] !== undefined &&
        storedData["dispatchEndDate"] !== undefined)
    ) {
      let date1 =  storedData["dispatchStartDate"]
        ? new Date(storedData["dispatchStartDate"])
        : null;
      let date2 = storedData["dispatchEndDate"]
        ? new Date(storedData["dispatchEndDate"])
        : null;

      if (!date1 || isNaN(date1.getTime())) {
        date1 = storedData?.dispatchStartDate ? new Date(storedData.dispatchStartDate) : null;
      }

      if (!date2 || isNaN(date2.getTime())) {
        date2 = storedData?.dispatchEndDate ? new Date(storedData.dispatchEndDate) : null;
      }

      this.setState({ dispatchFromDate: date1, dispatchToDate: date2 });
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  onChangeDatePicker = (value, label) => {
    // console.log(value, label);
    // debugger
    if (label === "fromDate") {
      this.setState({ fromDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.handleChange([this.state.fromDate, this.state.toDate]);
        }
      });
    }
    if (label === "toDate") {
      this.setState({ toDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.handleChange([this.state.fromDate, this.state.toDate]);
        }
      });
    }
  };
  onChangeDispatchDatePicker = (value, label) => {
    // console.log(value, label);
    // debugger
    if (label === "fromDate") {
      this.setState({ dispatchFromDate: value }, () => {
        if (
          this.state.dispatchFromDate !== null &&
          this.state.dispatchToDate !== null
        ) {
          this.props.handleDispatchChange([
            this.state.dispatchFromDate,
            this.state.dispatchToDate,
          ]);
        }
      });
    }
    if (label === "toDate") {
      this.setState({ dispatchToDate: value }, () => {
        if (
          this.state.dispatchToDate !== null &&
          this.state.dispatchFromDate !== null
        ) {
          this.props.handleDispatchChange([
            this.state.dispatchFromDate,
            this.state.dispatchToDate,
          ]);
        }
      });
    }
  };
  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let userPreferredLang = window.localStorage.getItem("userPreferredLang");
    const bodyElement = document.querySelector("body");
    const darkMode = bodyElement.classList.contains("dark__mode");

    const customStyles1 = {
      option: (styles, state) => ({
        ...styles,
        cursor: "pointer",
      }),
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      menu: ({ width, ...css }) => ({
        ...css,
        cursor: "pointer",
        width: "100%",
        backgroundColor: darkMode ? "#171722" : "#ffffff",
        border: darkMode
          ? "0.5px solid #616161"
          : "0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode
          ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
          : "0px 3px 12px rgba(0, 0, 0, 0.12)",
        // minWidth: "100%",
      }),
      control: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          width:
            this.props.selectedTranspoterValues?.length >= 2 ? "100%" : "100%",
          minWidth: "100%",
          minHeight: "26px",
          flexWrap: "nowrap",
          backgroundColor: darkMode ? "#2E2E46" : "",
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          cursor: "pointer",
          marginTop: "2px",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          cursor: "pointer",
          // backgroundColor: "#8e9af8",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "8px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          minHeight: "26px",
          // backgroundColor: "#0066cc",
        };
      },
    };
    return (
      <React.Fragment>
        <Card className={`${darkMode ? "darkBgQuickFilter" : "mt-1"}`}>
          <div
            className={`quickStylesBar trip-quick-filters ${
              darkMode ? "darkBgQuickFilter" : ""
            }`}
            style={{ marginTop: "10px" }}
          >
            <div className="QuickDiv">
              <div>
                <div className="fontRoboto15 tripCreatedFilter">Dispatch</div>

                <div className="dateickerGrid">
                  <div
                    className="date-picker-div RsuiteDateRange OrderDateRange"
                    style={{
                      width: "70%",
                      marginLeft: "7px",
                    }}
                  >
                    <div className="datepicker-selector orderDateContainer">
                      <InputGroup>
                        <Datepicker
                          mode={darkMode}
                          value={this.state.dispatchFromDate}
                          onChangeDatePicker={this.onChangeDispatchDatePicker}
                          fromDate={true}
                          toDate={false}
                          myPlaceholder={"Start Date"}
                          dashboard={false}
                        />
                      </InputGroup>
                      <InputGroup>
                        <Datepicker
                          mode={darkMode}
                          value={this.state.dispatchToDate}
                          onChangeDatePicker={this.onChangeDispatchDatePicker}
                          fromDate={false}
                          toDate={true}
                          myPlaceholder={"End Date"}
                          dashboard={false}
                          className="orderDateIcon"
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="fontRoboto15 tripCreatedFilter">Created</div>
                <div className="dateickerGrid">
                  <div
                    className="date-picker-div RsuiteDateRange OrderDateRange"
                    style={{
                      width: "70%",
                      marginLeft: "7px",
                    }}
                  >
                    <div className="datepicker-selector orderDateContainer">
                      <InputGroup>
                        <Datepicker
                          mode={darkMode}
                          value={this.state.fromDate}
                          onChangeDatePicker={this.onChangeDatePicker}
                          fromDate={true}
                          toDate={false}
                          myPlaceholder={"Start Date"}
                          dashboard={false}
                        />
                      </InputGroup>
                      <InputGroup>
                        <Datepicker
                          mode={darkMode}
                          value={this.state.toDate}
                          onChangeDatePicker={this.onChangeDatePicker}
                          fromDate={false}
                          toDate={true}
                          myPlaceholder={"End Date"}
                          dashboard={false}
                          className="orderDateIcon"
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Button
                  className="desktop-reset reset mt-4"
                  onClick={() => {
                    this.setState(
                      {
                        dateValue: [null, null],
                        dispatchDateValue: [null, null],
                        fromDate: null,
                        toDate: null,
                        dispatchFromDate: null,
                        dispatchToDate: null,
                      },
                      () => {
                        this.props.reset("topFilter");
                      }
                    );
                  }}
                >
                  {this.state.userLangs?.common?.RESET}
                </Button>
              </div>
              <div>
                <div>
                  <Button
                    className="mobile-reset reset"
                    onClick={() =>
                      this.setState(
                        {
                          dateValue: [null, null],
                          dispatchDateValue: [null, null],
                          fromDate: null,
                          toDate: null,
                          dispatchFromDate: null,
                          dispatchToDate: null,
                        },
                        () => {
                          this.props.reset();
                        }
                      )
                    }
                  >
                    <img
                      className="mobile-rest-img"
                      src={refreshIcon}
                      alt=""
                    ></img>
                  </Button>
                </div>
              </div>
              <div>
                <div className="tooltipIcon ml-2 mt-4">
                  <Button
                    disabled={!navigator.onLine}
                    onClick={() => this.props.toggleExcel()}
                    className={`iconButtons ${
                      darkMode ? "iconDarkButtons" : ""
                    }`}
                  >
                    <img
                      style={{ margin: "-4px" }}
                      src={darkMode ? exportDarkIcon : exportIcon}
                      alt="exp"
                    />
                  </Button>
                  <span className="tooltipIcontext" style={{ left: "10%" }}>
                    &nbsp;
                    {this.state.userLangs?.invoice?.DOWNLOAD_EXCEL ??
                      "Download Excel"}
                    &nbsp;
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default QuickFilters;
