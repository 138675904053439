import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { restrict } from "../.././restrict";
import { PERMS } from "../../../enum/perms.enum";
import {
  HeaderData,
  searchBar,
  getLocalStorage,
} from "./RowHeaders/RowHeaders";
import QuickFilters from "../LaneIndentReport/quckFilters";
import Index from "../../../components/Common/DataTableNeo/Index";
import { fetchIndentStatusReports } from "../../../store/Reports/actions";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Pagination from "@material-ui/lab/Pagination";
var totCnt = 0;
var totPages = 0;
var toVal = 0;
var fromVal = 0;
var offset = 0;

export class StatusIndent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFilters: {},
      export: false,
      dataLoading : false
    };
    if(process.env.REACT_APP_CLIENT != "3_BAXTER"){ 
    (async () => {
      let redirect = await restrict(PERMS.VEHICLE_TRANSPORTER_READ);

      if (redirect === false) {
        this.props.history.push("/operationsDashboard");
      }
    })();
  }
    // console.log(this.props.history.location.pathname, "props");
  }
  componentDidMount = () => {
    this.setState({ pathName: this.props.history.location.pathname , dataLoading : true});
    this.getAllData();
  };
  getAllData = () => {
    let offset = 0;
    this.props.fetchIndentStatusReports(offset, this.state.searchFilters , (response) => {
      this.setState({dataLoading : false})
    });
  };
  componentDidUpdate(prevProps) {}
  componentWillUnmount = () => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
  };

  handleChangePage = (event, val) => {
    this.setState({dataLoading : true})
    offset = (val - 1) * process.env.REACT_APP_PERPAGE;
    toVal = offset + process.env.REACT_APP_PERPAGE;

    this.getAllData();
    this.setState({ loading: true });
  };

  reset = (e) => {
    totCnt = 0;
    totPages = 0;
    toVal = 0;
    fromVal = 0;
    offset = 0;
    this.setState(
      {
        searchFilters: {},
        dataLoading : true
      },
      () => {
        this.getAllData();
      }
    );
  };
  quickFilters = (filterData, dateValue) => {
    this.setState({dataLoading : true})
    let searchFilters = this.state.searchFilters;

    if (filterData === "date") {
      let date = [];
      date[0] = dateValue[0]!== null ? moment(dateValue[0]).format("MM-DD-YYYY") : null
      date[1] = dateValue[1] !== null ? moment(dateValue[1]).format("MM-DD-YYYY") : null
      searchFilters.startTime = date;
    }
    this.setState({ searchFilters: searchFilters }, () => {
      this.getAllData();
    });
  };
  toggleExcel = async () => {
    setTimeout(async () => {
      const { statusIndent } = this.props;
      // console.log(statusIndent, "statusReports");
      let statusArr = [];
      if (Array.isArray(statusIndent)) {
        statusIndent.forEach((ele) => {
          if (ele) {
            statusArr.push({
              status: ele?._id?.status !== "NaN" ? ele?._id?.status : "",
              indentNo: ele?.total ? ele?.total : "",
            });
          }
        });
      }

      await this.exportToCSV([...statusArr], "STATUS_DATA_EXCEL");
    }, 1000);

    this.setState({ loading: true, export: true });
  };
  exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    this.setState({ export: false });
  };

  render() {
    const localStorageData = JSON.parse(
      localStorage.getItem("indentStatusData")
    );
    const { statusIndent, statusOffset, statusLength } = this.props;
    const statusIndentData = navigator.onLine
      ? statusIndent
      : localStorageData?.data?.result;
    let mode = localStorage.getItem('Theme')?localStorage.getItem('Theme'):null
    var darkMode = ((mode === "dark__mode") ? true : false);
    let rows = [];
    if (Array.isArray(statusIndentData)) {
      offset = navigator.onLine ? statusOffset : localStorageData?.data?.offset;
      totCnt = navigator.onLine ? statusLength : localStorageData?.data?.length;
      fromVal = totCnt !== 0 ? offset + 1 : fromVal;
      toVal = navigator.onLine
        ? statusOffset + statusLength
        : localStorageData?.data?.offset + localStorageData?.data?.length;
      totPages = Math.ceil(totCnt / process.env.REACT_APP_PERPAGE);
      if (statusIndentData.length > 0) {
        statusIndentData.forEach((ele) => {
          if (ele) {
            rows.push({
              status: ele?._id?.status !== "NaN" ? ele?._id?.status : "",
              indentNo: ele?.total ? ele?.total : "",
            });
          }
        });
      }
    }
    // console.log(rows, "rows");
    return (
      <>
        <div>
          {getLocalStorage()}
          <QuickFilters
            reset={this.reset}
            quickFilters={this.quickFilters}
            toggleExcel={this.toggleExcel}
            export={this.state.export}
            mode={darkMode}
          />
          <Container fluid>
            <Index
              {...this.props}
              headers={HeaderData}
              data={rows}
              searchBar={searchBar}
              fetchId={this.props.fetchId}
              getAllData={this.getAllData}
              rowHeight={"48px"}
              dataLoading = {this.state.dataLoading}
            />
            {totCnt !== 0 && !this.state.dataLoading ? (
              <Row
                xs={12}
                style={{color: darkMode?"#9d9d9d":"#0C0C0C"}}
                className="mb-2 paginationRow"
              >
                <Col xs={7} sm={5} md={4} className="span-col-pagenation">
                  <div className="float-left">
                    <Pagination
                      className="desktop-pagination"
                      size="medium"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                    <Pagination
                      className="mobile-pagination"
                      size="small"
                      shape="rounded"
                      page={offset / 10 + 1}
                      count={totPages}
                      color="primary"
                      onChange={this.handleChangePage}
                      defaultPage={1}
                      siblingCount={1}
                    />
                  </div>
                </Col>
                <Col
                  className="mt-2 spanPagenationentries span-col-pagenation"
                  xs={5}
                  sm={7}
                  md={8}
                >
                  <span>
                    Showing {fromVal} to {toVal} of {totCnt} entries
                  </span>
                </Col>
              </Row>
            ) : null}
          </Container>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    fetchId: state.Reports.reports.fetchId,
    statusIndent: state.Reports.reports?.statusIndentReport?.data?.result,
    statusOffset: state.Reports.reports?.statusIndentReport?.data?.offset,
    statusLength: state.Reports.reports?.statusIndentReport?.data?.length,
  };
};
export default connect(mapStateToProps, {
  fetchIndentStatusReports,
})(StatusIndent);
