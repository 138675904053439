import { PERMS } from "./enum/perms.enum";
const menu = [
  {
    title: "Dashboard",
    path: "/operationsDashboard",
    subOptions: [],
    isAllowed: false,
  },
  {
    title: "Indent",
    subOptions: [
      {
        title: "Indent Planning",
        path: "/indent-planning",
        permissions: [PERMS.INDENT_PLANNER_READ],
        isAllowed: false,
      },
      {
        title: "All Indents",
        path: "/assigned-indent",
        permissions: [PERMS.INDENT_TRANSPORTER_READ],
        isAllowed: false,
      },
    ],
    isAllowed: false,
  },
  {
    title: "Trips",
    subOptions: [
      {
        title: "View All Trips",
        path: "/trips",
        permissions: [PERMS.TRIP_READ],
        isAllowed: false,
      }, ///
    ],
    isAllowed: false,
  },
  {
    title: "Masters",
    subOptions: [
      {
        title: "Drivers",
        path: "/drivers",
        permissions: [PERMS.DRIVER_TRANSPORTER_READ],
        isAllowed: false,
      },
      {
        title: "Vehicles",
        path: "/vehicles",
        permissions: [PERMS.VEHICLE_TRANSPORTER_READ],
        isAllowed: false,
      },
      {
        title: "Users",
        path: "/user",
        permissions: [PERMS.DASHBOARD_PLANNER_READ],
        isAllowed: false,
      },
      {
        title: "Roles",
        path: "/roles",
        permissions: [PERMS.DASHBOARD_PLANNER_READ],
        isAllowed: false,
      },
    ],
    isAllowed: false,
  },
  {
    title: "Invoice",
    subOptions: [
      {
        title: "Agreement",
        path: "/BillingIndex",
        permissions: [PERMS.INDENT_PLANNER_READ],
        isAllowed: false,
      },

      {
        title: "Invoice Generation",
        path: "/invoiceGeneration",
        permissions: [PERMS.INDENT_TRANSPORTER_READ],
        isAllowed: false,
      },
      {
        title: "Invoice List",
        path: "/Billing/InvoiceList",
        permissions: [PERMS.INVOICE_READ],
        isAllowed: false,
      },
      {
        title: "Add Bill",
        path: "/tripBills",
        permissions: [PERMS.INDENT_PLANNER_READ],
        isAllowed: false,
      },
    ],
    isAllowed: false,
  },
  {
    title: "Reports",
    subOptions: [
      {
        title: "Transporter Performance",
        path: "/Reports",
        permissions: [PERMS.INDENT_PLANNER_READ],
        isAllowed: false,
      },
    ],
    isAllowed: false,
  },
];
export { menu };
