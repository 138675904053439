/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH";
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME";
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE";

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME";

/* RIGHT SIDEBAR */
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR";
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR";
export const HIDE_RIGHT_SIDEBAR = "HIDE_RIGHT_SIDEBAR";

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER";
export const types = {
    FETCH_EMPLOYEES: 'FETCH_EMPLOYEES',
    FETCH_EMPLOYEES_SUCCESS: 'FETCH_EMPLOYEES_SUCCESS',
    
    FETCH_AVAIL_EMPLOYEES: 'FETCH_AVAIL_EMPLOYEES',
    FETCH_AVAIL_EMPLOYEES_SUCCESS: 'FETCH_AVAIL_EMPLOYEES_SUCCESS',
    CREATE_EMPLOYEE: 'CREATE_EMPLOYEE',
    CREATE_EMPLOYEE_SUCCESS: 'CREATE_EMPLOYEE_SUCCESS',
    // FETCH_POSTS: 'FETCH_POSTS',
    // FETCH_POSTS_SUCCESS: 'FETCH_POSTS_SUCCESS',
    // CREATE_POST: 'CREATE_POST',
    // CREATE_POST_SUCCESS: 'CREATE_POST_SUCCESS',
    DELETE_EMPLOYEE: 'DELETE_EMPLOYEES',
    DELETE_EMPLOYEE_SUCCESS: 'DELETE_EMPLOYEES_SUCCESS'
};
//   export
// export const FETCH_EMPLOYEES = "FETCH_EMPLOYEES";


