import { types } from "./actionTypes";

// creating action
export const getInvoiceList = (
  filters,
  passSupervisor,
  topFilters,
  searchFilter,
  callback
) => {
  return {
    type: types.GET_INVOICELIST,
    filters,
    passSupervisor,
    topFilters,
    searchFilter,
    callback,
  };
};
export const getInvoiceListForExcel = (
  filters,
  passSupervisor,
  topFilters,
  searchFilter,
  callback
) => {
  return {
    type: types.GET_INVOICELIST_FOR_EXCEL,
    filters,
    passSupervisor,
    topFilters,
    searchFilter,
    callback,
  };
};

export const checkTripCreation = (orderIdArray, callback) => {
  return {
    type: types.CHECK_TRIP_CREATION,
    orderIdArray,
    callback,
  };
};

export const createTrip = (orderIdArray, callback) => {
  return {
    type: types.CREATE_TRIP_BAXTER,
    orderIdArray,
    callback,
  };
};

export const cancelInvoice = (payload, callback) => {
  return {
    type: types.CANCEL_INVOICE,
    payload,
    callback,
  };
};

export const AddCharges = (Values, callback) => {
  return {
    type: types.ADD_CHARGES,
    Values,
    callback,
  };
};

