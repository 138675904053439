import React, { } from "react";
import { MDBBtn } from "mdbreact";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import Select from "react-select";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

const VehicleDetails = ({ ...props }) => {
  const customStyles = {
    indicatorsContainer: (style) => {
      return {
        ...style,
        backgroundColor: "#5664D2",
        width: "54px",
        height: "37px",
        textAlign: "center",
        justifyContent: "center",
        borderRadius: "0px 4px 4px 0px",
      };
    },
    indicatorSeparator: (style) => {
      return {
        ...style,
        backgroundColor: "#5664D2",
      };
    },
    control: (style) => {
      return {
        ...style,
        // width: "241px",
        height: "37px",
        backgroundColor: "#F1F5F7",
      };
    },
  };
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  return (
    <Col lg="12" style={{ paddingBottom: "2px" }} className="jumbo addNewBg">
      <Row>
        <Col lg="5">
          <FormGroup>
            <Label className="control-label light__span ptL5">
              {userLang?.tracking?.VEHCILE_REG_NO}{" "}
              <span className="req">*</span>
            </Label>
            <Input
              value={props?.vehicleForm?.VehicleRegistrationNumber ?? ""}
              type="text"
              placeholder="Type here..."
              onChange={(e) =>
                props.onVehicleChange({
                  target: {
                    name: "VehicleRegistrationNumber",
                    value: e.target.value,
                  },
                })
              }
              maxLength="11"
            />
            {!props.isVehicleNoValid && (
              <span style={{ fontStyle: "12px", color: "red" }}>
                {userLang?.trip?.PLEASE_ENTER_FORMAT}: MH01AV4346, MH014465
              </span>
            )}
          </FormGroup>
        </Col>
        <Col lg="5">
          <FormGroup className="darkReportIncident">
            <Label className="control-label light__span">
              {userLang?.common?.VEHICLE_TYPE} <span className="req">*</span>
            </Label>
            <Select
              value={props.vehicleForm?.type}
              isMulti={false}
              onInputChange={props.vehicleType}
              styles={customStyles}
              onChange={(e) => {
                props.onVehicleChange({
                  target: {
                    name: "vehicleType",
                    value: e,
                  },
                });
              }}
              options={props.type}
              classNamePrefix="select2-selection"
              placeholder="select..."
            />
            {!props.isVehicleTypeValid && (
              <span style={{ fontStyle: "12px", color: "red" }}>
                {userLang?.trip?.PLEASE_SELECT_TYPE}:
                {props.toBeSelectedVehicleType?.label ??
                  "CONTAINER 32 FT MXL 14.5 MT"}
              </span>
            )}
          </FormGroup>
        </Col>
        <Col xs="2">
          <MDBBtn
            style={{
              backgroundColor: props.mode ? "#5E40FF" : "#05284E",
            }}
            color="primary"
            className="jumboButton"
            onClick={() => props.submitVehicleForm()}
            // disabled={props.disabled}
          >
            {userLang?.common?.SAVE ?? "Save"}
          </MDBBtn>
        </Col>
      </Row>
    </Col>
  );
};
export default VehicleDetails;
