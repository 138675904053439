import React, { Component } from "react";
import "./Loader.css";
import "./WaveLoader2.scss";
const WaveLoader = () => {
  return (
    <React.Fragment>
      <div className="WaveLoader WaveLoaderdiv">
        <span className="WaveLoaderSpan"></span>
        <span className="WaveLoaderSpan"></span>
        <span className="WaveLoaderSpan"></span>
        <span className="WaveLoaderSpan"></span>
        <span className="WaveLoaderSpan"></span>
        <span className="WaveLoaderSpan"></span>
      </div>
    </React.Fragment>
  );
};

const WaveLoader2 = () => {
  return (
    <React.Fragment>
      <div className="WaveLoader2 WaveLoader2div loader">
        <span className="WaveLoader2Span"></span>
        <span className="WaveLoader2Span"></span>
        <span className="WaveLoader2Span"></span>
        <span className="WaveLoader2Span"></span>
        <span className="WaveLoader2Span"></span>
        <span className="WaveLoader2Span"></span>
        <span className="WaveLoader2Span"></span>
        <span className="WaveLoader2Span"></span>
        <span className="WaveLoader2Span"></span>
        <span className="WaveLoader2Span"></span>
        <span className="WaveLoader2Span"></span>
      </div>
    </React.Fragment>
  );
};

export { WaveLoader, WaveLoader2 };
