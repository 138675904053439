import React from "react";

export const Icon1 = () => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1V7H11V1"
        stroke="#2800FC"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="2" cy="9" r="1" fill="#2800FC" />
      <circle cx="10" cy="9" r="1" fill="#2800FC" />
    </svg>
  );
};
export const Icon2 = () => {
  return (
    <>
      <svg
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 1V7H11V1"
          stroke="#2800FC"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="2" cy="9" r="1" fill="#2800FC" />
        <circle cx="10" cy="9" r="1" fill="#2800FC" />
      </svg>
    </>
  );
};

