import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import { WaveLoader } from "../../components/Common/Loader/LoaderIndex";
import moment from "moment";
import { tripHistory } from "../../store/baxter-trip/actions";
import { tripSubStatusMaster } from "../../components/Common/Status";
export class ViewTripDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewDetails: "",
      dataLoading: false,
      tripDetails: {},
    };
  }
  componentDidMount() {
    let tripId = this.props.history.location.state?.viewDetails?._id;
    this.setState({ dataLoading: true }, () => {
      this.props.tripHistory(tripId, (res) => {
        if (res?.success) {
          this.setState({
            tripDetails: res?.data?.docs?.[0],
            dataLoading: false,
          });
        }
      });
    });
    if (this.props.history.location.state?.viewDetails)
      this.setState({
        viewDetails: this.props.history.location.state?.viewDetails,
      });
  }
  render() {
    let darkState = this.props.darkTheme;
    const oddRowStyle = {
      minHeight: "27px",
      width: "101%",
      margin: "auto",
      marginTop: "4px",
      marginRight: "0px",
    };
    const evenRowStyle = {
      minHeight: "27px",
      width: "101%",
      margin: "auto",
      marginTop: "4px",
      marginRight: "0px",
    };

    const rowHeaderStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "15px",
      color: darkState ? "#9D9D9D" : "#05284E",
      lineHeight: "18px",
      margin: "auto",
      paddingLeft: "1.5vw",
    };
    const rowValueStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      color: darkState ? "#9D9D9D" : "#0C0C0C",
      margin: "auto",
      flex: "wrap",
      alignContent: "flex-start",
    };
    let tripDetails = this.state?.tripDetails;
    let orderDetails =
      tripDetails?.orderDetails?.[0] ?? tripDetails?.orderDetails;
    let source = tripDetails?.source?.[0] ?? tripDetails?.source;
    let destination = tripDetails?.destination?.[0] ?? tripDetails?.destination;
    let ordersDetails =
      this.state.viewDetails?.order?.[0] ?? this.state.viewDetails?.order;
    return (
      <React.Fragment>
        {this.state.dataLoading ? (
          <WaveLoader />
        ) : (
          <Container fluid={true} className="mt-2">
            <Row>
              <Col xs={9} className="d-flex flex-row bd-highlight mb-2 ">
                <Button
                  style={{
                    width: "60px",
                    height: "36px",
                    backgroundColor: "#3e3e3e",
                  }}
                  color="secondary"
                  size="sm"
                  onClick={() => this.props.history.goBack()}
                >
                  <i className="fas fa-arrow-circle-left fa-2x"></i>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xl="12" className="p-1">
                <div className="mt-4">
                  <section class="column-container">
                    <div class="col-1-3 pad color3">
                      <Card className="darkCardStyle orderCard viewTripDetailsCard">
                        <CardBody style={{ padding: "2%", height: "560px" }}>
                          <Grid
                            className=""
                            container
                            spacing={0}
                            style={oddRowStyle}
                          >
                            <Grid
                              // className=""
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                Trip Id <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {this.state.viewDetails?.tripId}
                            </Grid>
                          </Grid>
                          <Grid className="" container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Status <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {tripSubStatusMaster[
                                this.state.viewDetails?.status
                              ] || ""}
                            </Grid>
                          </Grid>

                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Sub Status <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {tripSubStatusMaster[
                                this.state.viewDetails?.subStatus
                              ] || ""}
                            </Grid>
                          </Grid>

                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127 "
                            >
                              <span className="light__span">
                                {" "}
                                Eway Bill Number{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 "
                            >
                              <span className="OrderReleaseCol dark__span">
                                {Array.isArray(
                                  this.state.viewDetails?.eWayBillNo
                                )
                                  ? this.state.viewDetails?.eWayBillNo?.[0]
                                  : this.state.viewDetails?.eWayBillNo}
                              </span>
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Transporter <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {Array.isArray(
                                this.state.viewDetails?.transporter
                              )
                                ? this.state.viewDetails?.transporter?.[0]
                                : this.state.viewDetails?.transporter}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Vehicle Number{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {this.state.viewDetails?.vehicle?.regNo}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Created At <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {moment(this.state.viewDetails?.createdAt).format(
                                "DD-MM-YYYY h:mm A"
                              )}
                            </Grid>
                          </Grid>

                          <Grid className=" " container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Latest Known Location{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {
                                this.state.viewDetails?.latestKnownLocation
                                  ?.locationDescription
                              }
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Trip Completion Remark{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {
                                this.state.viewDetails?.tripCompletionDetails
                                  ?.remark
                              }
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Trip Completion Date{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {
                                this.state.viewDetails?.tripCompletionDetails
                                  ?.tripCompletionDate
                              }
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Drive Name <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {this.state.viewDetails?.driver?.name}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Drive Phone Number{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {this.state.viewDetails?.driver?.phone}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Driver Consent Provided{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {this.state.viewDetails?.driverConsentProvided}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Driver Consent Status From Service Provider{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {
                                this.state.viewDetails
                                  ?.driverConsentStatusFromServiceProvider
                              }
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Legal Invoice Number{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {this.state.viewDetails?.legalInvoiceNo?.map(
                                (el) => (
                                  <div> {el} </div>
                                )
                              )}
                            </Grid>
                          </Grid>
                        </CardBody>
                      </Card>
                    </div>

                    <div class="col-1-3 pad">
                      <Card
                        style={{ backgroundColor: "#F1F5F7" }}
                        className="darkCardStyle orderCard"
                      >
                        <CardBody
                          style={{ padding: "2%", height: "545px" }}
                          className="mt-3"
                        >
                          <span className="fontRoboto177 text-center shipTo mt-4 light__span">
                            Source
                          </span>
                          <Grid className="mt-4" container style={oddRowStyle}>
                            <Grid
                              // className=""
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                Name <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {source?.name}
                            </Grid>
                          </Grid>
                          <Grid className="" container style={oddRowStyle}>
                            <Grid
                              // className=""
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                Co Name <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {source?.coName}
                            </Grid>
                          </Grid>

                          <Grid className="" container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                Address 1 <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {source?.addressLine1}
                            </Grid>
                          </Grid>

                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Address 2 <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {source?.addressLine2}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Address 3 <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {source?.addressLine3}
                            </Grid>
                          </Grid>
                          <Grid className="" container style={oddRowStyle}>
                            <Grid
                              // className=""
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                City <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {source?.city}
                            </Grid>
                          </Grid>

                          <Grid className="" container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                Country <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {source?.country}
                            </Grid>
                          </Grid>

                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Pincode <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {source?.pinCode}
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={4}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                State <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={8}
                              style={rowValueStyle}
                              className="MobilefontRoboto124 dark__span"
                            >
                              {source?.state}
                            </Grid>
                          </Grid>
                        </CardBody>
                      </Card>
                    </div>

                    {/* <div style={rowSeparaterStyle}></div> */}

                    <div class="col-1-3 pad color3">
                      <Card
                        style={{ backgroundColor: "#F1F5F7" }}
                        className="darkCardStyle orderCard"
                      >
                        <CardBody
                          style={{ padding: "2%", height: "545px" }}
                          className="mt-3"
                        >
                          <span className="fontRoboto177 light__span  shipTo text-center mt-4">
                            Destination
                          </span>
                          <div>
                            <Grid
                              className="mt-4"
                              container
                              style={oddRowStyle}
                            >
                              <Grid
                                // className=""
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                                className="MobilefontRoboto127"
                              >
                                <span className="light__span">
                                  Ship to Name{" "}
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124 dark__span"
                              >
                                {destination?.shipToName}
                              </Grid>
                            </Grid>

                            <Grid className="" container style={evenRowStyle}>
                              <Grid
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                                className="MobilefontRoboto127"
                              >
                                <span className="light__span">
                                  Ship to Address 1{" "}
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124 dark__span"
                              >
                                {destination?.shipToAddress1}
                              </Grid>
                            </Grid>

                            <Grid className=" " container style={oddRowStyle}>
                              <Grid
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                                className="MobilefontRoboto127"
                              >
                                <span className="light__span">
                                  {" "}
                                  Ship to Address 2
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124 dark__span"
                              >
                                {destination?.shipToAddress2}
                              </Grid>
                            </Grid>
                            <Grid className=" " container style={evenRowStyle}>
                              <Grid
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                                className="MobilefontRoboto127"
                              >
                                <span className="light__span">
                                  {" "}
                                  Ship to Address 3{" "}
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124 dark__span"
                              >
                                {destination?.shipToAddress3}
                              </Grid>
                            </Grid>
                            <Grid className=" " container style={oddRowStyle}>
                              <Grid
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                                className="MobilefontRoboto127"
                              >
                                <span className="light__span">
                                  {" "}
                                  Ship to Address 4{" "}
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124 dark__span"
                              >
                                {destination?.shipToAddress4}
                              </Grid>
                            </Grid>

                            <Grid className="" container style={oddRowStyle}>
                              <Grid
                                // className=""
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                                className="MobilefontRoboto127"
                              >
                                <span className="light__span">
                                  Ship to City
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124 dark__span"
                              >
                                {destination?.shipToCity}
                              </Grid>
                            </Grid>

                            <Grid className="" container style={evenRowStyle}>
                              <Grid
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                                className="MobilefontRoboto127"
                              >
                                <span className="light__span">
                                  Ship to Destination{" "}
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124 dark__span"
                              >
                                {destination?.shipToDestination}
                              </Grid>
                            </Grid>

                            <Grid className=" " container style={oddRowStyle}>
                              <Grid
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                                className="MobilefontRoboto127"
                              >
                                <span className="light__span">
                                  Ship to Zip{" "}
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124 dark__span"
                              >
                                {destination?.shipToZip}
                              </Grid>
                            </Grid>
                            <Grid className=" " container style={evenRowStyle}>
                              <Grid
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                                className="MobilefontRoboto127"
                              >
                                <span className="light__span">
                                  {" "}
                                  Ship to Country
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124 dark__span"
                              >
                                {destination?.shipToCountry}
                              </Grid>
                            </Grid>
                            <Grid className=" " container style={oddRowStyle}>
                              <Grid
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                                className="MobilefontRoboto127"
                              >
                                <span className="light__span">
                                  {" "}
                                  Ship to GSTN No{" "}
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124 dark__span"
                              >
                                {destination?.shipToGSTNNo}
                              </Grid>
                            </Grid>
                            <Grid className=" " container style={oddRowStyle}>
                              <Grid
                                item
                                xs={3}
                                md={4}
                                style={rowHeaderStyle}
                                className="MobilefontRoboto127"
                              >
                                <span className="light__span">
                                  {" "}
                                  Ship to State Name{" "}
                                  <span className="text-bold">:</span>
                                </span>
                              </Grid>

                              <Grid
                                item
                                xs={5}
                                md={8}
                                style={rowValueStyle}
                                className="MobilefontRoboto124 dark__span"
                              >
                                {destination?.shipToStateName}
                              </Grid>
                            </Grid>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl="12" className="p-1">
                <div className="">
                  <section class="column-container">
                    <div class="col-1-3 pad color3">
                      <Card className="darkCardStyle orderCard">
                        <CardBody style={{ padding: "2%", height: "445px" }}>
                          <span className="fontRoboto177 light__span text-center shipTo mt-4">
                            Order Details
                          </span>
                          <Grid
                            className="mt-4"
                            container
                            spacing={0}
                            style={oddRowStyle}
                          >
                            <Grid
                              // className=""
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                Order Number{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              <span className="OrderReleaseCol dark__span">
                                {ordersDetails?.orderNumber}
                              </span>
                            </Grid>
                          </Grid>
                          <Grid className="" container style={evenRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Order Co <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              <span className="OrderReleaseCol dark__span">
                                {ordersDetails?.orderCo}
                              </span>
                            </Grid>
                          </Grid>

                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Order Type <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              <span className="OrderReleaseCol dark__span">
                                {ordersDetails?.orderType}
                              </span>
                            </Grid>
                          </Grid>

                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Warehouse <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              <span className="OrderReleaseCol dark__span">
                                {ordersDetails?.businessUnit}
                              </span>
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Address Number{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              <span className="OrderReleaseCol dark__span">
                                {orderDetails?.addressNumber}
                              </span>
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Created At <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              <span className="OrderReleaseCol dark__span">
                                {moment(orderDetails?.orderDateObject).format(
                                  "DD-MM-YYYY h:mm A"
                                )}
                              </span>
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Status <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              <span className="OrderReleaseCol dark__span">
                                {orderDetails?.status}
                              </span>
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Total cost <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              <span className="OrderReleaseCol dark__span">
                                {orderDetails?.totalCost}
                              </span>
                            </Grid>
                          </Grid>
                          <Grid className=" " container style={oddRowStyle}>
                            <Grid
                              item
                              xs={3}
                              md={6}
                              style={rowHeaderStyle}
                              className="MobilefontRoboto127"
                            >
                              <span className="light__span">
                                {" "}
                                Ship To Number{" "}
                                <span className="text-bold">:</span>
                              </span>
                            </Grid>

                            <Grid
                              item
                              xs={5}
                              md={5}
                              style={rowValueStyle}
                              className="MobilefontRoboto124"
                            >
                              <span className="OrderReleaseCol dark__span">
                                {orderDetails?.shipToNumber}
                              </span>
                            </Grid>
                          </Grid>
                        </CardBody>
                      </Card>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

export default connect(null, {
  tripHistory,
})(ViewTripDetails);
