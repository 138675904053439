import React, { Component } from "react";
import { Label, FormGroup } from "reactstrap";
import Select from "react-select";
import { ErrorMessage } from "formik";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

var options = [
  { label: "Day 1", value: "1" },
  { label: "Day 2", value: "2" },
  { label: "Day 3", value: "3" },
  { label: "Day 4", value: "4" },
  { label: "Day 5", value: "5" },
];

class DaySelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      day: "",
    };
  }

  render() {
 userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

    const customStyles = {
      indicatorsContainer: (style) => {
        return {
          ...style,
          backgroundColor: "#8e9af8",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          backgroundColor: "#8e9af8",
        };
      },
    };
    return (
      <React.Fragment>
        <FormGroup className="select2-container required darkAgreementVehicleCharge">
          <Label className="col-form-label required-field light__span">
          {userLang?.invoice?.DAY}
          </Label>
          <Select
            value={this.props.val}
            name="day"
            styles={customStyles}
            onChange={(e) => {
              this.props.setFieldValue("day", e);
            }}
            options={options}
            classNamePrefix="select2-selection"
            id="day"
            onBlur={this.props.handleBlur}
            className={
              this.props.errors.day && this.props.touched.day
                ? "input-error"
                : null
            }
          />
          <ErrorMessage name="day" component="span" className="error" />
        </FormGroup>
      </React.Fragment>
    );
  }
}

export default DaySelection;
