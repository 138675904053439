import React, { Component } from "react";
import {
  Container,
  Card,
  CardTitle,
  CardText,
  CardBody,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import classnames from "classnames";
import ProductDetail from "./ProductDetail";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
class OrderProductViewModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
        mode: "",
        productData: "",
    };
    // this.toggleTab = this.toggleTab.bind(this);
  }


  componentDidMount = () => {
    this.getData();
  };
  getData = () => {
    // if (this.props.currentProduct) {
    //     alert("898")
    //   let data = this.props?.currentProduct;
    //   console.log(data,this.props?.currentProduct, "sanjeeta");
    //   this.setState(
    //     {
    //       productData: data,
    //     },
    //     () => {
    //     //   console.log(this.state.orderData?.businessUnit, "pp");
    //     }
    //   );
    // }
  };

  render() {

    let darkState = this.props.darkTheme;
    const modalStyle = {
      height: "100%",
      width: "100%",
      backgroundColor: darkState ? "#1B1C31" : "#ffffff",
      padding: "25px",
      position: "relative",
      border: darkState ? "border: 0.5px solid #616161" : "#ffffff",
    };
    const headerStyle = {
      position: "relative",
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "23px",
      color: darkState ? "#9D9D9D" : "#0C0C0C",
      left: "0px",
    };
    const closeStyle = {
      position: "relative",
      // color: darkState ? "#B7A9FF" : "#2800fc",
      width: "20px",
      marginRight: "0px",
    };
    const rowsTopSpaceStyle = { marginTop: "0px" };
    const dataBoxStyle = {
      height: "100%",
      overflowY: "scroll",
      overflowX: "clip",
      padding: "3%",
    };
    const oddRowStyle = {
      minHeight: "57px",
      width: "101%",
      backgroundColor: darkState ? "#24243C" : "#ffffff",
      border: "0.5px solid rgba(185, 185, 185, 0.5)",
      borderRadius: "5px",
      margin: "auto",
      marginTop: "4px",

      marginRight: "0px",
      //position:'relative',
    };
    const evenRowStyle = {
      minHeight: "57px",
      width: "101%",
      backgroundColor: darkState ? "#2E2E46" : "#F1F5F7",
      border: "0.5px solid rgba(185, 185, 185, 0.5)",
      borderRadius: "5px",
      margin: "auto",
      marginTop: "4px",

      marginRight: "0px",
      //position:'relative',
    };

    const rowHeaderStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "15px",
      color: darkState ? "#9D9D9D" : "#05284E",
      lineHeight: "18px",
      margin: "auto",
      paddingLeft: "1.5vw",
    };
    const rowSeparaterStyle = {
      borderLeft: "0.5px solid rgba(185, 185, 185, .5)",
      height: "100%",
    };
    const rowValueStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      color: darkState ? "#9D9D9D" : "#0C0C0C",
      margin: "auto",
      flex: "wrap",
    };
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          className="CustomerModal"
          size="lg"
        >
          
          <ModalHeader>
            <div className="Heading">
              <h3>Product  Detail</h3>
              <svg
                onClick={this.props.toggle}
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.9092 1.82141L1.90918 13.8214"
                  stroke="#2800FC"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.90918 1.82141L13.9092 13.8214"
                  stroke="#2800FC"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </ModalHeader>
          <Card className="px-0 mb-0">
            <CardBody className="px-0 py-0">
              <ModalBody className="indentModal">
                <ProductDetail currentProduct= {this.props.currentProduct}/>
              </ModalBody>
            </CardBody>
          </Card>
        </Modal>
      </React.Fragment>
    );
  }
}

export default OrderProductViewModel;
