import React, { Component } from 'react'
import { Card, Col, Row } from 'reactstrap'
import TransportCardDetails from './TransportCardDetails'

export default class TransportCard extends Component {
    constructor(props) {
        super(props)
    }
  render() {
    return (
        <>
        <Card className="darkCardStyle" style={{height:"541px"}}>
          <div className="mt-2 pl-3">
            <Row className="pt-2">
              <Col xs="9" className="pdt5">
                <span className="roboto-font-700 span-indent-name light__span">
                  {this.props?.userLang?.baxterdDashboard?. BIFURICATION_OF_TRANSPORT_MODE ??  "Bifurication of transport mode"}
                 
                </span>
              </Col>
              
            </Row>
          </div>
          <hr className="mt-3 transport-hr-line" />
          {<TransportCardDetails
            tripInformation = {this.props.tripInformation}
          /> }
        </Card>
      </>
    )
  }
}
