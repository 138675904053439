import React from "react";

export const CloseIcon = (props) => {
  // console.log(props);

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 1L1 13"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 1L13 13"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const KeyboardArrowDownIcon = () => {
  return (
    <svg
      width="13"
      height="9"
      viewBox="0 0 13 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.73999 1.50391L6.87012 6.63403L12 1.50391"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
export const KeyboardArrowUpIcon = () => {
  return (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6.63403L6.86987 1.50391L1.73999 6.63403"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
export const MoreHorizIcon = (props) => {
  return (
    <svg
      width="19"
      height="5"
      viewBox="0 0 19 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4087 2.8418C10.4087 2.28951 9.96098 1.8418 9.40869 1.8418C8.85641 1.8418 8.40869 2.28951 8.40869 2.8418C8.40869 3.39408 8.85641 3.8418 9.40869 3.8418C9.96098 3.8418 10.4087 3.39408 10.4087 2.8418Z"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.40869 2.8418C3.40869 2.28951 2.96098 1.8418 2.40869 1.8418C1.85641 1.8418 1.40869 2.28951 1.40869 2.8418C1.40869 3.39408 1.85641 3.8418 2.40869 3.8418C2.96098 3.8418 3.40869 3.39408 3.40869 2.8418Z"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4087 2.8418C17.4087 2.28951 16.961 1.8418 16.4087 1.8418C15.8564 1.8418 15.4087 2.28951 15.4087 2.8418C15.4087 3.39408 15.8564 3.8418 16.4087 3.8418C16.961 3.8418 17.4087 3.39408 17.4087 2.8418Z"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const ExportIcon = (props) => {
  return (
    <svg
      className="cursor-pointer exportDark"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6299 0.0490417H4.62988C2.42074 0.0490417 0.629883 1.8399 0.629883 4.04904V22.049C0.629883 24.2582 2.42074 26.049 4.62988 26.049H22.6299C24.839 26.049 26.6299 24.2582 26.6299 22.049V4.04904C26.6299 1.8399 24.839 0.0490417 22.6299 0.0490417Z"
        fill="#F1F5F7"
      />
      <path
        d="M22.6299 0.299042H4.62988C2.55882 0.299042 0.879883 1.97797 0.879883 4.04904V22.049C0.879883 24.1201 2.55882 25.799 4.62988 25.799H22.6299C24.701 25.799 26.3799 24.1201 26.3799 22.049V4.04904C26.3799 1.97797 24.701 0.299042 22.6299 0.299042Z"
        stroke={props.color}
        strokeWidth="0.5"
      />
      <path
        d="M18.7559 14.133V18.983C18.7559 19.4119 18.5855 19.8232 18.2823 20.1264C17.979 20.4297 17.5677 20.6 17.1389 20.6H8.24688C7.81803 20.6 7.40674 20.4297 7.10349 20.1264C6.80024 19.8232 6.62988 19.4119 6.62988 18.983V10.091C6.62988 9.66219 6.80024 9.2509 7.10349 8.94765C7.40674 8.64441 7.81803 8.47404 8.24688 8.47404H13.0969"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3311 6.04904H21.1811V10.899"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9058 15.325L20.9058 6.32504"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const Search = (props) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.7751 13.6C11.2545 13.6 14.0751 10.7794 14.0751 7.3C14.0751 3.82061 11.2545 1 7.7751 1C4.2957 1 1.4751 3.82061 1.4751 7.3C1.4751 10.7794 4.2957 13.6 7.7751 13.6Z"
        stroke="#2800FC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M15.475 15L13.375 12.9"
        stroke="#2800FC"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};
export const DateIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9985 2H2.99854C1.89397 2 0.998535 2.89543 0.998535 4V13C0.998535 14.1046 1.89397 15 2.99854 15H12.9985C14.1031 15 14.9985 14.1046 14.9985 13V4C14.9985 2.89543 14.1031 2 12.9985 2Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9985 1V3"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.99854 1V3"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.998535 6H14.9985"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const QualityCheck = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="#00A36C"
      className="bi bi-check-circle"
      viewBox="0 0 16 16"
    >
      <path
        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
        fill="white"
      />
      <path
        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
        fill="white"
      />
    </svg>
  );
};

export const QualityCheckTick = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path
        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
        fill="#00A36C"
      />
    </svg>
  );
};
export const ArrivedAtSource = (props) => {
  return (
    <svg
      width="23px"
      height="23px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#ffffff"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <circle
          cx="12"
          cy="10"
          r="4"
          stroke="#ffffff"
          stroke-width="1.5"
        ></circle>{" "}
        <path
          d="M10.5 10H13.5"
          stroke="#ffffff"
          stroke-width="1.5"
          stroke-linecap="round"
        ></path>{" "}
        <path
          d="M12 11.5L12 8.5"
          stroke="#ffffff"
          stroke-width="1.5"
          stroke-linecap="round"
        ></path>{" "}
        <path
          d="M5 15.2161C4.35254 13.5622 4 11.8013 4 10.1433C4 5.64588 7.58172 2 12 2C16.4183 2 20 5.64588 20 10.1433C20 14.6055 17.4467 19.8124 13.4629 21.6744C12.5343 22.1085 11.4657 22.1085 10.5371 21.6744C9.26474 21.0797 8.13831 20.1439 7.19438 19"
          stroke="#ffffff"
          stroke-width="1.5"
          stroke-linecap="round"
        ></path>{" "}
      </g>
    </svg>
  );
};

export const Completed = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="white"
      class="bi bi-check2-all"
      viewBox="0 0 16 16"
    >
      {" "}
      <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />{" "}
      <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />{" "}
    </svg>
  );
};
