import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class IndentDetailDonutChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      options: {
        tooltip: {
          shared: false,
          intersect: true,
          y: {
            formatter: (value) => {
              return value  +  ' %'
            },
          },
          
        },
        plotOptions: {
          pie: {
            // customScale: 0.5,
            donut: {
              size: '80%'
            }
          }
        },
        labels: [],
        colors: ["#28A34C", "#E53D2C", "#FFFFFF"],
        legend: {
          show: false,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          // fontSize: '2px',
          offsetX: 0,
          offsetY: 0,
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height:"100%",
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      },
    };
  }
  componentDidUpdate = (prevProps) => {
    // console.log("indentAcc ",this.props.indentAcc )
    if (prevProps.indentAcc !== this.props.indentAcc)  {
      let indentAcc = this.props.indentAcc;
      var data ={
        TotalIndents:0,
        IndentAcceptance:0
      };

      if(indentAcc !== undefined){
        indentAcc.map((ele)=>{
          if(ele?.performanceType === "acceptance"){
           data.TotalIndents=ele?.totalIndents;
            data.IndentAcceptance = Number((ele?.performancePercentage*100).toFixed(2));
          }
          return null;
        })
      }
      let rejectedIndent = data?.IndentAcceptance;
      let acceptedIndent = 100 - data?.IndentAcceptance;

  
      let Series = [acceptedIndent ,rejectedIndent];
      let Label = [
        "Indent Acceptance",
        "Indent Rejection"
      ];
      
      this.setState({ options: { ...this.state.options, labels: Label } });
      this.setState({ series: Series });
    }
  };

  render() {
    return (
      <React.Fragment>
        <ReactApexChart className="cursor-pointer IndentApexchartParent mgt5"
          options={this.state.options}
          series={this.state.series}
          type="donut"
          height="150"
        />
      </React.Fragment>
    );
  }
}

export default IndentDetailDonutChart;
