let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  HeaderData = {
    columns: [
      {
        label: userLang?.masters?.ROUTE_CODE,
        field: "routeCode",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.masters?.NAME,
        field: "name",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.masters?.SOURCE,
        field: "source",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.masters?.DESTINATION,
        field: "destination",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.masters?.VIA,
        field: "via",
        sort: "asc",
        width: 2,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var HeaderData = {
  columns: [
    {
      label: userLang?.masters?.ROUTE_CODE,
      field: "routeCode",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.masters?.NAME,
      field: "name",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.masters?.SOURCE,
      field: "source",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.masters?.DESTINATION,
      field: "destination",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.masters?.VIA,
      field: "via",
      sort: "asc",
      width: 2,
    },
  ],
};
export const searchBar = {
  searchBarData: [],
};
