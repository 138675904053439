let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  TranspoterHeaderData = {
    columns: [
      {
        label: "Transporter Name",
        field: "Name",
        sort: "asc",
        width: 2,
      },
      {
        label: "KMs",
        field: "KMs",
        sort: "asc",
        width: 1,
      },
      {
        label: "Lead Time",
        field: "LeadTime",
        sort: "asc",
        width: 1,
      },
      {
        label:"Lot No",
        field: "LotNo",
        sort: "asc",
        width: 1,
      },
      {
        label:"Actual",
        field: "Actual",
        sort: "asc",
        width: 1,
      },
      {
        label:"Target",
        field: "Target",
        sort: "asc",
        width: 1,
      },
      // {
      //   label:"laneLot",
      //   field: "laneLot",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label:"Lane_Lot",
        field: "Lane_Lot",
        sort: "asc",
        width: 1,
      },
      {
        label:"cost",
        field: "cost",
        sort: "asc",
        width: 1,
      },
      {
        label:"Status",
        field: "Status",
        sort: "asc",
        width: 1,
      },
      {
        label:"Validity",
        field: "Validity",
        sort: "asc",
        width: 1,
      },
      // {
      //   label:"disabled",
      //   field: "disabled",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label:"Action",
        field: "Action",
        sort: "asc",
        width: 1,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var TranspoterHeaderData = {
    columns: [
      {
        label: "Transporter Name",
        field: "Name",
        sort: "asc",
        width: 2,
      },
      {
        label: "KMs",
        field: "KMs",
        sort: "asc",
        width: 1,
      },
      {
        label: "Lead Time",
        field: "LeadTime",
        sort: "asc",
        width: 1,
      },
      {
        label:"Lot No",
        field: "LotNo",
        sort: "asc",
        width: 1,
      },
      {
        label:"Actual",
        field: "Actual",
        sort: "asc",
        width: 1,
      },
      {
        label:"Target",
        field: "Target",
        sort: "asc",
        width: 1,
      },
      // {
      //   label:"laneLot",
      //   field: "laneLot",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label:"Lane_Lot",
        field: "Lane_Lot",
        sort: "asc",
        width: 1,
      },
      {
        label:"cost",
        field: "cost",
        sort: "asc",
        width: 1,
      },
      {
        label:"Status",
        field: "Status",
        sort: "asc",
        width: 1,
      },
      {
        label:"Validity",
        field: "Validity",
        sort: "asc",
        width: 1,
      },
      // {
      //   label:"disabled",
      //   field: "disabled",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label:"Action",
        field: "Action",
        sort: "asc",
        width: 1,
      },
    ],
  };
export const searchBar = {
  searchBarData: [
    
  ],
};
