const restrict = async (perm) => {
  let data = window.localStorage.getItem("authUser")
    ? JSON.parse(window.localStorage.getItem("authUser"))
    : null;
  let permissions = data?.data?.permissions;
  return permissions?.includes(perm);
};

const restrictSync = (perm) => {
  let data = window.localStorage.getItem("authUser")
    ? JSON.parse(window.localStorage.getItem("authUser"))
    : null;
  let permissions = data?.data?.permissions;
  return permissions?.includes(perm);
};

const roleCheck = (roleString) => {
  let data = window.localStorage.getItem("authUser")
    ? JSON.parse(window.localStorage.getItem("authUser"))
    : null;
  let role = data?.data?.role?.label;
  if (roleString === role) return true;
  else return false;
};

export { restrict, restrictSync, roleCheck };
