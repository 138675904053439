import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Button,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { NotificationManager } from "react-notifications";
import { MDBDataTable } from "mdbreact";
import { uploadOrder } from "../../store/order/actions";
import { uploadDealer } from "../../store/dealer/actions";
import { uploadDriver } from "../../store/driver/actions";

// import axios from "axios";
// Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Link } from "react-router-dom";
import { connect } from "react-redux";

var rows = [];
var data = [];
var errRows = [];
var errData = [];

// const instance = axios.create();
class Upload extends Component {
  constructor(props) {
    super(props);
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
    this.state = {
      breadcrumbItems: [
        // { title : "Forms", link : "#" },
        // { title : "From Upload", link : "#" },
      ],
      selectedFiles: [],
      orderData: {},
      errorMsg: "",
    };
    // var fileCategory = "";
  }
  componentDidMount() {
    if (this.props.history.location.pathname) {
      if (this.props.history.location.pathname.includes("order")){
        this.fileCategory = "Order";
        this.csvUrl=process.env.REACT_APP_Order_CSV
      }
      if (this.props.history.location.pathname.includes("employee")){
        this.fileCategory = "Employee";
        this.csvUrl="#"
      }
      if (this.props.history.location.pathname.includes("dealer")){
        this.fileCategory = "Dealer";
        this.csvUrl=process.env.REACT_APP_Dealer_CSV
      }
      if (this.props.history.location.pathname.includes("driver")){
        this.fileCategory = "Driver";
        this.csvUrl=process.env.REACT_APP_Driver_CSV
      }
      if (this.props.history.location.pathname.includes("location")){
        this.fileCategory = "Location";
        this.csvUrl="#"
      }
      if (this.props.history.location.pathname.includes("vehicle")){
        this.fileCategory = "Vehicle";
        this.csvUrl="#"
      }
      if (this.props.history.location.pathname.includes("hop")){
        this.fileCategory = "Hop";
        this.csvUrl="#"
      }
    }
    this.setState({
      breadcrumbItems: [
        {
          title: this.fileCategory,
          link: this.fileCategory.toLowerCase() + "s",
        },
        { title: this.fileCategory + " Upload", link: "#" },
      ],
    });
  }
  handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: files, errorMsg: "" });
    //console.log('files', files);
  };

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  handleSubmit = async () => {
    if (this.state.selectedFiles.length === 0) {
      this.setState({ errorMsg: "Please upload file" });
      // console.log("files", this.state.selectedFiles);
    } else {
      const formData = new FormData();
      //  delete this.state.selectedFiles[0].type;
        // console.log(this.state.selectedFiles,"order")
      formData.append("csv", this.state.selectedFiles[0]);
      switch (this.fileCategory) {
        case "Order":
          this.props.uploadOrder(formData, (res) => {
            if (res.statusCode === 200 || res.statusCode === 201) {
              NotificationManager.success(
                "Order Uploaded Successfully",
                "Success"
              );
              rows = [];
              errRows = [];
              rows.push({
                totalRowCount: res.response.totalRowCount,
                successRowCount: res.response.successRowCount,
                errorRowCount: res.response.errorRowCount,
                successMessage: res.message,
              });
              data = {
                columns: [
                  {
                    label: "Total Rows",
                    field: "totalRowCount",
                    sort: "asc",
                    width: 100,
                  },
                  {
                    label: "Success Rows",
                    field: "successRowCount",
                    sort: "asc",
                    width: 300,
                  },
                  {
                    label: "Error Rows",
                    field: "errorRowCount",
                    sort: "asc",
                    width: 100,
                  },
                  {
                    label: "Message",
                    field: "successMessage",
                    sort: "asc",
                    width: 350,
                  },
                ],
                rows: rows,
              };
              res.response.errorRows.forEach((ele) => {
                errRows.push({
                  DealerCode: ele.DealerCode,
                  ExternalOrderId: ele.ExternalOrderId,
                  OrderStatus: ele.OrderStatus,
                  ProductCode: ele.ProductCode,
                  ProductDimension: ele.ProductDimension,
                  ProductName: ele.ProductName,
                  ProductPrice: ele.ProductPrice,
                  Quantity: ele.Quantity,
                  SubTotal: ele.SubTotal,
                  errorMessage: ele.errorMessage,
                });
              });
              errData = {
                columns: [
                  {
                    label: "Dealer Code",
                    field: "DealerCode",
                    sort: "asc",
                    width: 100,
                  },
                  {
                    label: "External OrderId",
                    field: "ExternalOrderId",
                    sort: "asc",
                    width: 300,
                  },

                  {
                    label: "Order Status",
                    field: "OrderStatus",
                    sort: "asc",
                    width: 100,
                  },
                  {
                    label: "Product Code",
                    field: "ProductCode",
                    sort: "asc",
                    width: 100,
                  },
                  {
                    label: "Product Dimension",
                    field: "ProductDimension",
                    sort: "asc",
                    width: 100,
                  },
                  {
                    label: "Product Name",
                    field: "ProductName",
                    sort: "asc",
                    width: 300,
                  },

                  {
                    label: "Product Price",
                    field: "ProductPrice",
                    sort: "asc",
                    width: 100,
                  },
                  {
                    label: "Quantity",
                    field: "Quantity",
                    sort: "asc",
                    width: 100,
                  },
                  {
                    label: "SubTotal",
                    field: "SubTotal",
                    sort: "asc",
                    width: 100,
                  },
                  {
                    label: "Error Message",
                    field: "errorMessage",
                    sort: "asc",
                    width: 500,
                  },
                ],
                rows: errRows,
              };
              this.setState({ orderData: res, selectedFiles: [] });
            } else {
              NotificationManager.error(res.message, "Error");
              this.setState({ orderData: [], selectedFiles: [] });
            }
          });
          break;
        case "Dealer":
          this.props.uploadDealer(formData, (res) => {
            if (res.statusCode === 200 || res.statusCode === 201) {
              NotificationManager.success(
                "Dealers Uploaded Successfully",
                "Success"
              );
              rows = [];
              errRows = [];
              rows.push({
                totalRowCount: res.response.totalRowCount,
                successRowCount: res.response.successRowCount,
                errorRowCount: res.response.errorRowCount,
                successMessage: res.message,
              });
              data = {
                columns: [
                  {
                    label: "Total Rows",
                    field: "totalRowCount",
                    sort: "asc",
                    width: 100,
                  },
                  {
                    label: "Success Rows",
                    field: "successRowCount",
                    sort: "asc",
                    width: 300,
                  },
                  {
                    label: "Error Rows",
                    field: "errorRowCount",
                    sort: "asc",
                    width: 100,
                  },
                  {
                    label: "Message",
                    field: "successMessage",
                    sort: "asc",
                    width: 350,
                  },
                ],
                rows: rows,
              };
              res.response.errorRows.forEach((ele) => {
                errRows.push({
                  DealerAddress: ele.DealerAddress,
                  DealerCity: ele.DealerCity,
                  DealerCode: ele.DealerCode,
                  DealerEmail: ele.DealerEmail,
                  DealerName: ele.DealerName,
                  DealerPhone: ele.DealerPhone,
                  DealerState: ele.DealerState,
                  DealerZipCode: ele.DealerZipCode,
                  errorMessage: ele.errorMessage,
                });
              });
              errData = {
                columns: [
                  {
                    label: "DealerAddress",
                    field: "DealerAddress",
                    sort: "asc",
                    width: 100,
                  },
                  {
                    label: "DealerCity",
                    field: "DealerCity",
                    sort: "asc",
                    width: 300,
                  },

                  {
                    label: "DealerCode",
                    field: "DealerCode",
                    sort: "asc",
                    width: 100,
                  },
                  {
                    label: "DealerEmail",
                    field: "DealerEmail",
                    sort: "asc",
                    width: 100,
                  },
                  {
                    label: "DealerName",
                    field: "DealerName",
                    sort: "asc",
                    width: 100,
                  },
                  {
                    label: "DealerPhone",
                    field: "DealerPhone",
                    sort: "asc",
                    width: 300,
                  },

                  {
                    label: "DealerState",
                    field: "DealerState",
                    sort: "asc",
                    width: 100,
                  },
                  {
                    label: "DealerZipCode",
                    field: "DealerZipCode",
                    sort: "asc",
                    width: 100,
                  },
                  
                  {
                    label: "Error Message",
                    field: "errorMessage",
                    sort: "asc",
                    width: 500,
                  },
                ],
                rows: errRows,
              };
              this.setState({ orderData: res, selectedFiles: [] });
            } else {
              NotificationManager.error(res.message, "Error");
            }
          });
          break;
          case "Driver":
            this.props.uploadDriver(formData, (res) => {
              if (res.statusCode === 200 || res.statusCode === 201) {
                NotificationManager.success(
                  "Order Uploaded Successfully",
                  "Success"
                );
                rows = [];
                errRows = [];
                rows.push({
                  totalRowCount: res.response.totalRowCount,
                  successRowCount: res.response.successRowCount,
                  errorRowCount: res.response.errorRowCount,
                  successMessage: res.message,
                });
                data = {
                  columns: [
                    {
                      label: "Total Rows",
                      field: "totalRowCount",
                      sort: "asc",
                      width: 100,
                    },
                    {
                      label: "Success Rows",
                      field: "successRowCount",
                      sort: "asc",
                      width: 300,
                    },
                    {
                      label: "Error Rows",
                      field: "errorRowCount",
                      sort: "asc",
                      width: 100,
                    },
                    {
                      label: "Message",
                      field: "successMessage",
                      sort: "asc",
                      width: 350,
                    },
                  ],
                  rows: rows,
                };
                res.response.errorRows.forEach((ele) => {
                  errRows.push({
                    BloodGroup: ele.BloodGroup,
                    DriverFullName: ele.DriverFullName,
                    DriverLicNo: ele.DriverLicNo,
                    DriverStatus: ele.DriverStatus,
                    EmergencyContactNo: ele.EmergencyContactNo,
                    EmergencyContactPerson: ele.EmergencyContactPerson,
                    errorMessage: ele.errorMessage,
                  });
                });
                errData = {
                  columns: [
                    {
                      label: "BloodGroup",
                      field: "BloodGroup",
                      sort: "asc",
                      width: 100,
                    },
                    {
                      label: "DriverFullName",
                      field: "DriverFullName",
                      sort: "asc",
                      width: 300,
                    },
  
                    {
                      label: "DriverLicNo",
                      field: "DriverLicNo",
                      sort: "asc",
                      width: 100,
                    },
                    {
                      label: "DriverPhoneNo",
                      field: "DriverPhoneNo",
                      sort: "asc",
                      width: 100,
                    },
                    {
                      label: "DriverStatus",
                      field: "DriverStatus",
                      sort: "asc",
                      width: 100,
                    },
                    {
                      label: "EmergencyContactNo",
                      field: "EmergencyContactNo",
                      sort: "asc",
                      width: 300,
                    },
  
                    {
                      label: "EmergencyContactPerson",
                      field: "EmergencyContactPerson",
                      sort: "asc",
                      width: 100,
                    },
                    {
                      label: "errorMessage",
                      field: "errorMessage",
                      sort: "asc",
                      width: 500,
                    }
                  ],
                  rows: errRows,
                };
                this.setState({ orderData: res, selectedFiles: [] });
              } else {
                NotificationManager.error(res.message, "Error");
              }
            });
            break;
        default:
          break;
      }
    }
  };

  render() {
    // console.log(this.csvUrl,"this.csvUrl")
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs
              title="Upload Excel File"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <CardTitle>Drop {this.fileCategory} File</CardTitle>
                    <CardSubtitle className="mb-3">
                      {" "}
                      Download the sample file, update it and upload it again.
                    </CardSubtitle>
                    <Form>
                      <p>
                        <a
                          href={this.csvUrl}
                          target="_self"
                          download
                        >
                          click here{" "}
                        </a>
                        to download the sample file
                      </p>

                      <Dropzone
                        accept=".csv,.xlsx"
                        onDrop={(acceptedFiles) =>
                          this.handleAcceptedFiles(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted ri-upload-cloud-2-line"></i>
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {this.state.selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                    </Form>

                    <div className="text-center mt-4" >
                      <Button
                        color="primary" 
                        onClick={()=>this.props.history.push(`${this.props.history.location.pathname.split('-')[1]}s`)}
                        type="button"
                        className="waves-effect waves-light"
                        style={{margin:"5px"}}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        onClick={this.handleSubmit}
                        type="button"
                        className="waves-effect waves-light"
                      >
                        Submit
                      </Button>
                    </div>
                    <span className="err">{this.state.errorMsg}</span>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {Object.keys(this.state.orderData).length !== 0 && (
              <>
                <Row>
                  <Col xs={12}>
                    <Card>
                      <CardBody>
                        <h4 className="card-title">Orders </h4>
                        <MDBDataTable
                          responsive
                          striped
                          bordered
                          data={data}
                          searching={false}
                          paging={false}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {this.state.orderData.response.errorRows &&
                  this.state.orderData.response.errorRows.length !== 0 && (
                    <Row>
                      <Col xs={12}>
                        <Card>
                          <CardBody>
                            <h4 className="card-title">Error Orders </h4>
                            <MDBDataTable
                              responsive
                              striped
                              bordered
                              data={errData}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  )}
              </>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(null, { uploadOrder, uploadDealer, uploadDriver })(Upload);
