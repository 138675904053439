import React, { Component } from "react";

export default class ACLRFrontCopy extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
    this.fileInput = React.createRef();
    // this.handleReset();
  }

  fileUploadTrigger = () => {
    this.fileInput.current.click();
  };
 
  render() {
    let error = this.props.errorFront;
    return (
      <>
        <div
          className="mt-2"
          style={{
            width: "98%",
            backgroundColor: "#0AB38C",
            height: "120px",
          }}
        >
          <div>
            <span
              style={{
                color: "#FFF",
              }}
              className=" fontRoboto15 d-flex justify-content-center"
            >
              Attach Photo Proof
            </span>
          </div>
          <input
            type="file"
            name="Image"
            multiple={false}
            style={{ display: "none" }}
            ref={this.fileInput}
            onChange={(e)=>{
              this.props.fileUpload({
                target:{
                  name:"FrontCopy",
                  files:e.target.files
                }
              })
            }}
          />
          <span
            style={{
              cursor: "pointer",
              color: this.props.mode ? "#B7A9FF" : "#5664D2",
            }}
            onClick={this.fileUploadTrigger}
          >
            <svg
              style={{
                marginTop: "10px",
                marginLeft: "20px",
              }}
              width="51"
              height="51"
              viewBox="0 0 51 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M50.7061 0H0.293948C0.131605 0 0 1.60235 0 3.57895V47.4211C0 49.3977 0.131605 51 0.293948 51H50.7061C50.8684 51 51 49.3977 51 47.4211V3.57895C51 1.60235 50.8684 0 50.7061 0Z"
                fill="#05284E"
                fillOpacity="0.59"
              />
              <path
                d="M50.7062 0.224609H0.294131C0.141934 0.224609 0.0185547 1.72681 0.0185547 3.57987V47.422C0.0185547 49.275 0.141934 50.7772 0.294131 50.7772H50.7062C50.8584 50.7772 50.9818 49.275 50.9818 47.422V3.57987C50.9818 1.72681 50.8584 0.224609 50.7062 0.224609Z"
                fill="#05284E"
                fillOpacity="0.59"
              />
              <path
                d="M26 21V31"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21 26H31"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          {this.props.baseImage?.length > 0
            ? this.props.baseImage?.map((item, i) => {
                return (
                  <>
                    <span  key={i} className="pl-2 pr-2">
                      <span className="EPODImangeClose cursor-pointer">
                        <svg
                          className="closeIconEPOD"
                          onClick={() => this.props.deleteImage(item,i,{name:"front"})}
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13 1L1 13"
                            stroke="#2800FC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1 1L13 13"
                            stroke="#2800FC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>

                      <img
                        className="mt-1"
                        src={item}
                        alt=""
                        width={70}
                        height={70}
                      />
                    </span>
                  </>
                );
              })
            : ""}
        </div>
        {error?.imagesFront && (
              <div className="error">
                {error?.imagesFront}{" "}
              </div>
            )}
      </>
    );
  }
}

