import React from "react";
import { Button } from "reactstrap";
import actionVerticleDots from "../Trips/assets/more-vertical.png";

export const actions = (ele, routingToAction) => {
  return (
    <div className="dropdown">
      <Button className="iconButtons dropbtn actionButtonsDark">
        <img
          style={{ margin: "-4px" }}
          src={actionVerticleDots}
          alt="actions"
        />
      </Button>
      <div className="dropdown-content contentDark">
        <div
          className="action-button actionDspan"
          onClick={() => {
            routingToAction.routingToEditdetail(ele);
          }}
        >
          Edit Details
        </div>

        <div
          className="action-button-cancel actionDspan"
          onClick={() => {
            routingToAction.routingToDelete(ele);
          }}
        >
          Delete
        </div>
      </div>
    </div>
  );
};
