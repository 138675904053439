import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
import { AxiosResponse } from "axios";
//medthods
import {
  deleteApi,
  getApi,
  patchApi,
  postApi,
  uploadExcel,
  putUploadApi,
  putUploadImagesApi,
} from "../../helpers/auth_helper";

// workers
function* workFetchCustomerPresignedUrl({ callback }) {
  try {
    const url =
      process.env.REACT_APP_SERVERURL + "/upload/getCustomerExcelPresignedURL";
    const response = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_CUSTOMER_PRESIGNED_URL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchLocationPresignedUrl({ callback }) {
  try {
    const url =
      process.env.REACT_APP_SERVERURL + "/upload/getLocationExcelPresignedURL";
    const response = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_LOCATION_PRESIGNED_URL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchProductPresignedUrl({ callback }) {
  try {
    const url =
      process.env.REACT_APP_SERVERURL + "/upload/productExcelPreSignedURL";
    const response = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_PRODUCT_PRESIGNED_URL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchLogbookPresignedUrl({ callback }) {
  try {
    const url =
      process.env.REACT_APP_SERVERURL + "/upload/getLogbookReportPresignedUrl";
    const response = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.GET_LOGBOOK_PRESIGNED_URL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workFetchUsersExcelPresignedUrl({ callback }) {
  try {
    const url =
      process.env.REACT_APP_SERVERURL + "/upload/userExcelPreSignedURL";
    const response = yield call(getApi, url);
    callback(response);
    yield put({
      type: types.UESER_EXCEL_PRESIGNED_URL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workUploadImages1({ uri, data, headers }) {
  try {
    // Try to call the API
    const response = yield call(putUploadImagesApi, uri, data, headers);
    // console.log("response",response)
    //   Dispatch the action to the reducers
    // callback(response);
    yield put({
      type: types.UPLOAD_IMAGE_SUCCESS1,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log(error);
  }
}
function* workUploadImagesCallback({ uri, data, headers, callback }) {
  try {
    // Try to call the API
    const response = yield call(putUploadImagesApi, uri, data, headers);
    // console.log("response",response)
    //   Dispatch the action to the reducers
    callback(response);
    yield put({
      type: types.UPLOAD_IMAGE_SUCCESS_CALLBACK,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.log(error);
  }
}

function* workerCompleteTripPresignedUrl({ fileType, callback }) {
  // console.log(fileType,"type")
  try {
    const uri =
      process.env.REACT_APP_SERVERURL +
      `/upload/tripCompletedFileUrl/${fileType}`;
    const response = yield call(getApi, uri);
    callback(response);
    yield put({
      type: types.COMPLETE_TRIP_PRESISNED_URL_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    // console.log("Request failed! Could not fetch driver.", error);
  }
}
// watchers
export function* watchFetchProfilePresignedUrl() {
  yield takeEvery(
    types.GET_CUSTOMER_PRESIGNED_URL,
    workFetchCustomerPresignedUrl
  );
}
export function* watchFetchLocationPresignedUrl() {
  yield takeEvery(
    types.GET_LOCATION_PRESIGNED_URL,
    workFetchLocationPresignedUrl
  );
}
export function* watchFetchProductPresignedUrl() {
  yield takeEvery(
    types.GET_PRODUCT_PRESIGNED_URL,
    workFetchProductPresignedUrl
  );
}
export function* watchFetchLogbookPresignedUrl() {
  yield takeEvery(
    types.GET_LOGBOOK_PRESIGNED_URL,
    workFetchLogbookPresignedUrl
  );
}
export function* watchFetchUserPresignedUrl() {
  yield takeEvery(
    types.GET_CUSTOMER_PRESIGNED_URL,
    workFetchUsersExcelPresignedUrl
  );
}
export function* watchUploadImages1() {
  yield takeEvery(types.UPLOAD_IMAGE1, workUploadImages1);
}
export function* watchUploadImagesCallback() {
  yield takeEvery(types.UPLOAD_IMAGE_CALLBACK, workUploadImagesCallback);
}
export function* watchCompleteTripPresignedUrl() {
  yield takeEvery(
    types.COMPLETE_TRIP_PRESISNED_URL,
    workerCompleteTripPresignedUrl
  );
}

function* uploadModule() {
  yield all([fork(watchFetchProfilePresignedUrl)]);
  yield all([fork(watchFetchLocationPresignedUrl)]);
  yield all([fork(watchFetchProductPresignedUrl)]);
  yield all([fork(watchUploadImages1)]);
  yield all([fork(watchFetchUserPresignedUrl)]);
  yield all([fork(watchCompleteTripPresignedUrl)]);
  yield all([fork(watchUploadImagesCallback)]);
  yield all([fork(watchFetchLogbookPresignedUrl)]);
}
export default uploadModule;
