export const types = {
  TRIP_COUNT_BY_STATUS: "TRIP_COUNT_BY_STATUS",
  TRIP_COUNT_BY_STATUS_SUCCESS: "TRIP_COUNT_BY_STATUS_SUCCESS",
  OPERATIONS_TRIP_INDENT_BY_STATUS: "OPERATION_TRIP_INDENT_BY_STATUS",
  OPERATIONS_TRIP_INDENT_BY_STATUS_SUCCESS:
    "OPERATIONS_TRIP_INDENT_BY_STATUS_SUCCESS",
  TRANSPORTER_TRIP_HISTORY_BY_STATUS: "TRANSPORTER_TRIP_HISTORY_BY_STATUS",
  TRANSPORTER_TRIP_HISTORY_BY_STATUS_SUCCESS:
    "TRANSPORTER_TRIP_HISTORY_BY_STATUS_SUCCESS",
  INVOICE_DASHBOARD_BY_STATUS: "INVOICE_DASHBOARD_BY_STATUS",
  INVOICE_DASHBOARD_BY_STATUS_SUCCESS: "INVOICE_DASHBOARD_BY_STATUS_SUCCESS",
};
