import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import TrackingMap from "./TrackingMap";
import DriverDetailsModal from "../pages/TrackingModals/DriverDetailsModal";
import ReportIncidentModal from "./TrackingModals/ReportIncidentModal";
import { fetchVehicles, createVehicle } from "../store/vehicle/actions";
import { fetchDrivers, createDriver } from "../store/driver/actions";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import { fetchVehicleTypesList } from "../store/vehicleType/actions";
import {
  createReportIncident,
  updateDriver,
  fetchTripById,
} from "../store/trip/actions";
import { subscribeConsent } from "../store/driver/actions";
import moment from "moment";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

const incidents = [
  { label: "Theft", value: "Theft" },
  { label: "Accident", value: "Accident" },
  { label: "Breakdown", value: "Breakdown" },
];
var data = {};
var offset = 0;
var darkMode;
class Tracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Trips", link: "#" },
        { title: "Tracking", link: "#" },
      ],
      loading: false,
      detailToggle: true,
      subDetailToggle1: true,
      subDetailToggle2: false,
      driverModal: false,
      errorMessage: {},
      errorMessageEdit: {},
      driverMsgErr: {},
      reportIncidentModal: false,
      tripId: "",
      vehicleForm: {
        VehicleOwner: "",
        VehicleRegistrationNumber: "",
        type: null,
      },
      driverForm: {
        fullName: "",
        phone: "",
        driverLicenseNo: "",
      },
      isSubmitting: false,
      incidentType: "",
      incidentdescription: "",
      detailchange: false,
      driverVehicleHistory: [],
      history: [],
      source: {},
      destination: {},
      transporterId: "",
      selectedTripId: "",
      vehicleLicensePlate: [],
      isPhoneNoValid: true,
      isVehicleNoValid: true,
      isVehicleTypeValid: true,
      transporter: "",
      driver: "",
      vehicle: "",
      openToggle: false,
      transporterVehicleOptions: [],
    };
  }

  menuToggle = (val) => {
    // console.log("clicked");
    this.setState({ detailToggle: val });
  };

  collapseToggle1 = () => {
    if (this.state.subDetailToggle1 === false)
      this.setState({ subDetailToggle1: !this.state.subDetailToggle1 });
    if (this.state.subDetailToggle2 === true)
      this.setState({ subDetailToggle2: false });
  };

  collapseToggle2 = () => {
    this.setState({
      subDetailToggle2: !this.state.subDetailToggle2,
      subDetailToggle1: !this.state.subDetailToggle1,
    });
  };
  driverModal = () => {
    this.setState({
      driverModal: !this.state.driverModal,
      isPhoneNoValid: true,
      driverForm: {
        fullName: "",
        phone: "",
        driverLicenseNo: "",
      },
    });
  };
  reportIncidentModal = () => {
    this.setState({
      reportIncidentModal: !this.state.reportIncidentModal,
      detailchange: false,
      incidentType: "",
      incidentdescription: "",
      driver: "",
      vehicle: "",
      isPhoneNoValid: true,
      isVehicleNoValid: true,
      isVehicleTypeValid: true,
      vehicleForm: {
        VehicleOwner: "",
        VehicleRegistrationNumber: "",
        type: null,
      },
      driverForm: {
        fullName: "",
        phone: "",
        driverLicenseNo: "",
      },
    });
  };
  toggle = () => {
    this.setState({
      openToggle: true,
    });
  };
  componentDidMount = () => {
    this.trackingHistory();
    this.fetchDrivers();
    this.vehicleLicense();

    this.props.fetchVehicleTypesList(data || {}, () => {});
  };
  trackingHistory = () => {
    let tripId = this.props.history.location.state?.tripId;
    this.setState({ tripId: tripId }, () => {
      this.props.fetchTripById(this.state.tripId, (res) => {
        let tripData = res?.data[0];

        let driverVehicleHistory = [
          { key: userLang?.trip?.TRIP_ID, value: tripData.tripId },
          {
            key: userLang?.tracking?.STARTING_LOCATION,
            value: tripData.source.label,
          },
          {
            key: userLang?.common?.DESTINATION,
            value: tripData.destination.label,
          },
          { key: userLang?.common?.ROUTE, value: tripData?.route?.name ?? "" },
          {
            key: userLang?.common?.START_TIME,
            value: moment(tripData.startTime).format("DD-MM-YYYY"),
          },
          {
            key: userLang?.common?.TRANSPORTER,
            value: tripData.transporter.name,
          },
          { key: userLang?.trip?.DRIVER, value: tripData.driver.name },
          { key: userLang?.tracking?.CONTACT_NO, value: tripData.driver.phone },
          {
            key: userLang?.tracking?.VEHICLE_NO,
            value: tripData.vehicle.regNo,
          },
          {
            key: userLang?.tracking?.LAST_STATUS,
            value: moment(tripData.updatedAt).format("DD-MM-YYYY"),
          },

          {
            key: userLang?.common?.STATUS,
            value: tripData.status.replace("_", " "),
          },
        ];

        this.setState(
          {
            driverVehicleHistory: driverVehicleHistory,
            source: res.data[0].source.location,
            destination: res.data[0].destination.location,
            transporterId: tripData.transporter["_id"],
            driverPhone: res.data[0].driver.phone,
            selectedTripId: res?.data[0]?.tripId ?? "",
          },
          () => {
            this.props.subscribeConsent(this.state.driverPhone, (res) => {
              driverVehicleHistory.push({
                key: userLang?.tracking?.CONSENT_STATUS,
                value:
                  res.data.data.status + " (" + res.data.data.operator + ")",
              });

              this.setState({ driverVehicleHistory: driverVehicleHistory });
            });
          }
        );
      });
    });
  };
  fetchDrivers = () => {
    let transpoterId = this.props?.history?.location?.state?.transporterId;
    let offset = 0;
    this.props.fetchDrivers(
      offset,
      "",
      "",
      {
        q: "",
        transporterId: transpoterId,
      },
      () => {}
    );
  };
  filterVehicleList = (e) => {
    let transpoterId = this.props?.history?.location?.state?.transporterId;

    if (e.length > 2) {
      this.props.fetchVehicles(
        null,
        {
          regNo: e,
          q: "",
          transporterId: transpoterId,
        },
        (res) => {
          this.setState({ transporterVehicleOptions: res.data.docs });
        }
      );
    }
    if (e.length === 0) {
      this.props.fetchVehicles(
        offset,
        {
          q: "",
          transporterId: transpoterId,
        },
        (res) => {
          this.setState({ transporterVehicleOptions: res.data.docs });
        }
      );
    }
  };
  vehicleLicense = () => {
    let transpoterId = this.props?.history?.location?.state?.transporterId;
    let type = [];
    this.props.fetchVehicles(0, { transporterId: transpoterId }, (res) => {
      if (Array.isArray(res.data.docs)) {
        if (res?.data?.docs?.length > 0) {
          res.data.docs.forEach((ele) => {
            type.push({
              label: ele.type.label + " - " + ele.registration.number,
              value: {
                // id: ele._id,
                id: ele.registration.number,
              },
            });
          });
        }
      }
    });
    this.setState({
      vehicleLicensePlate: type,
    });
  };
  handleVehicleHistory = (data) => {
    let historyArray = [];
    // console.log(data, "?history?");
    data.forEach((ele) => {
      let timeStamp = moment(ele.updatedAt).format("YYYY-MM-DD HH:mm:ss");
      let location = ele.locationDescription;
      historyArray.push({ timeStamp, location });
    });
    this.setState({ history: historyArray });
  };
  filterDriverList = (e) => {
    var regexNumber = /^[0-9]+$/;
    var regexString = /^[a-zA-Z]+$/;
    if (e.length > 2) {
      if (e.match(regexNumber)) {
        this.props.fetchDrivers(offset, "", "", { phone: e });
      }
      if (e.match(regexString))
        this.props.fetchDrivers(offset, "", "", { name: e });
    }
    if (e.length === 0) {
      // this.props.fetchDrivers(offset, "", "", { q: "" });
      this.fetchDrivers();
    }
  };
  handleChangeModal = (e) => {
    const { name, value } = e.target;
    // console.log(name, value ,"ewret")
    this.setState({ [name]: value });
    if (name === "driver") {
      this.setState({ driverPhone: value?.label?.split(" ")[0] });
    }
  };
  onReportIncident = (e) => {
    const { name, value } = e.target;
    //console.log(e.target,"jasjh")
    this.setState({ [name]: value });
  };

  handleSubmitSaveVehicle = () => {
    let transpoterId = this.props?.history?.location?.state?.transporterId;
    let {
      VehicleOwner,

      VehicleRegistrationNumber,
      type,
    } = this.state.vehicleForm;
    let formObj = {
      ownerName: VehicleOwner,
      // transporter: this.state.transporter,
      regNo: VehicleRegistrationNumber,
      type: type?.value,
      transporter: this.state.transporterId,
    };
    // return false;
    // if (
    //   this.state.vehicleForm?.type?.value !=
    //   this.state.toBeSelectedVehicleType?.value
    // ) {
    //   this.setState({
    //     isVehicleTypeValid: false,
    //   });
    // } else {
    //   this.setState({ isVehicleTypeValid: true });
    // }
    if (!this.state.vehicleForm?.type?.value) {
      this.setState({
        isVehicleTypeValid: false,
      });
    } else {
      this.setState({ isVehicleTypeValid: true });
    }
    if (
      !(
        this.state.vehicleForm?.VehicleRegistrationNumber?.match(
          /^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4}$/
        ) ||
        this.state.vehicleForm?.VehicleRegistrationNumber?.match(
          /^[A-Z]{2}[0-9]{2}[A-Z]{1}[0-9]{4}$/
        ) ||
        this.state.vehicleForm?.VehicleRegistrationNumber?.match(
          /^[A-Z]{2}[0-9]{6}$/
        ) ||
        this.state.vehicleForm?.VehicleRegistrationNumber?.match(
          /^[0-9]{3}[A-Z]{2}[0-9]{3}$/
        ) ||
        this.state.vehicleForm?.VehicleRegistrationNumber?.match(
          /^[0-9]{3}[A-Z]{2}[0-9]{1}$/
        ) ||
        this.state.vehicleForm?.VehicleRegistrationNumber?.match(
          /^[A-Z0-9]{10}$/
        ) ||
        this.state.vehicleForm?.VehicleRegistrationNumber?.match(
          /^[A-Z]{2}[0-9]{2}[A-Z]{3}[0-9]{4}$/
        )
      )
    ) {
      this.setState({ isVehicleNoValid: false });
    } else {
      this.setState(
        {
          isVehicleNoValid: true,
        },
        () => {
          this.setState({ onSubmitDisable: true });
          this.props.createVehicle(formObj, (res) => {
            if (res.success) {
              this.props.fetchVehicles(
                0,
                { transporterId: transpoterId },
                (res) => {
                  this.setState({ transporterVehicleOptions: res.data.docs });
                }
              );
              NotificationManager.success(res["message"], "Success");
            } else {
              NotificationManager.error(res["message"], "Error");
              this.setState({
                onSubmitDisable: false,
                vehicleForm: {
                  VehicleOwner: "",
                  VehicleRegistrationNumber: "",
                  type: null,
                },
                vehicle: "",
              });
            }
          });

          setTimeout(() => {
            let vh;
            if (this.state.vehicleForm.VehicleRegistrationNumber !== "") {
              if (Array.isArray(this.state.transporterVehicleOptions)) {
                vh = this.state.transporterVehicleOptions.filter((v) => {
                  return (
                    v.registration.number ===
                    this.state.vehicleForm.VehicleRegistrationNumber
                  );
                });
              }

              this.setState({
                vehicle: {
                  value: vh[0]._id,
                  label:
                    vh[0].registration.number + " (" + vh[0].type.label + ")",
                },
                vehicleForm: {
                  VehicleOwner: "",
                  VehicleRegistrationNumber: "",
                  type: null,
                },
              });
            }
          }, 4000);
        }
      );
    }
  };
  onVehicleChange = (e) => {
    let { name, value } = e.target;
    let vf = { ...this.state.vehicleForm };
    if (name === "vehicleType") {
      vf.type = value;
    }
    if (name === "VehicleOwner") {
      vf["VehicleOwner"] = value;
    }
    if (name === "VehicleRegistrationNumber") {
      vf["VehicleRegistrationNumber"] = value.toString().toUpperCase();
    }
    this.setState({ vehicleForm: vf });
  };

  handleSubmitSaveDriver = () => {
    // let transpoterId = this.props?.history?.location?.state?.transporterId;

    let { fullName, phone, driverLicenseNo } = this.state.driverForm;
    let formObj = {
      name: fullName,
      phone,
      licence: { number: driverLicenseNo },
      transporter: this.state.transporterId,
    };
    this.setState({ onSubmitDisable: true });
    if (!this.state.driverForm?.phone.match(/^[0-9]{10}$/)) {
      this.setState({ isPhoneNoValid: false });
      return false;
    } else {
      this.setState({
        isPhoneNoValid: true,
      });
    }
    this.props.createDriver(formObj, (res) => {
      if (res.message === "Driver has been created successfully") {
        // this.props.fetchDrivers(0, "", "", {
        //   q: "",
        //   transpoterId: transpoterId,
        // });
        this.fetchDrivers();

        NotificationManager.success(res["message"], "Success");
      } else {
        NotificationManager.error(res["message"], "Error");
        this.setState({
          onSubmitDisable: false,
          driverForm: {
            fullName: "",
            phone: "",
            driverLicenseNo: "",
          },
          driver: "",
        });
      }
    });
    setTimeout(() => {
      let dt;
      if (this.state.driverForm.phone !== "") {
        if (Array.isArray(this.props.drivers?.drivers?.data?.docs)) {
          dt = this.props.drivers?.drivers?.data?.docs.filter((v) => {
            return v.phone === this.state.driverForm.phone;
          });
        }
        this.setState({
          driver: {
            value: dt[0]?._id,
            label: dt
              ? dt[0]?.phone + " (" + (dt[0]?.name ? dt[0]?.name : "N/A") + ")"
              : this.state.driverShow.phone,
          },
          driverForm: {
            fullName: "",
            phone: "",
            driverLicenseNo: "",
          },
        });
      }
    }, 4000);
  };
  onDriverChange = (e) => {
    let { name, value } = e.target;
    let df = { ...this.state.driverForm };
    if (name === "phone") {
      value = e.target?.value;
      df.phone = value;
    }
    if (name === "driverLicenseNo") {
      df["driverLicenseNo"] = value;
    }
    if (name === "fullName") {
      df["fullName"] = value;
    }
    this.setState({ driverForm: df });
  };
  validateVal = () => {
    const errors = {};
    let incidentType = this.state.incidentType;
    let incidentdescription = this.state.incidentdescription;
    if (!incidentType) {
      errors.incidentType = "Incident Type is required!";
    }
    if (!incidentdescription) {
      errors.incidentdescription = "Description is required!";
    }
    return errors;
  };
  validateValEdit = () => {
    const errorsEdit = {};
    let driver = this.state.driver;
    let vehicle = this.state.vehicle;
    if (!driver) {
      errorsEdit.driver = "driver is required!";
    }
    if (!vehicle) {
      errorsEdit.vehicle = "vehicle is required!";
    }
    return errorsEdit;
  };
  detailchange = () => {
    if (!this.state.incidentdescription || !this.state.incidentType) {
      this.setState({ errorMessage: this.validateVal() });
      return;
    } else {
      this.setState({ detailchange: !this.state.detailchange });
    }
  };
  onReportInsidentSubmit = () => {
    let { tripId, incidentType, incidentdescription, driver, vehicle } =
      this.state;
    if (
      !tripId ||
      !incidentType ||
      !incidentdescription ||
      !driver ||
      !vehicle
    ) {
      this.setState({ errorMessageEdit: this.validateValEdit() });
    } else {
      let formVal = {
        tripId,
        incidentType: incidentType.value,
        incidentDescription: incidentdescription,
        driver: driver.value,
        vehicle: vehicle.value,
      };
      this.props.createReportIncident(formVal, (res) => {
        if (
          res["data"] &&
          res["message"] &&
          res["message"] === "Successfully updated"
        ) {
          NotificationManager.success(res["data"]["message"], "Success");
          window.history.replaceState(null, null, "/");
        } else {
          NotificationManager.error(res["data"]["message"], "Error");
          this.setState({ onSubmitDisable: false });
        }
      });
      this.setState(
        {
          isPhoneNoValid: true,
          isVehicleNoValid: true,
          isVehicleTypeValid: true,
        },
        () => {
          this.trackingHistory();
          this.reportIncidentModal();
        }
      );
    }
  };
  handleSubmitUpdateDriver = () => {
    if (this.state.driver?.value) {
      let values = {
        driver: this.state.driver.value,
        tripId: this.state.tripId,
      };
      this.props.updateDriver(values, (res) => {
        if (
          res["data"] &&
          res["message"] &&
          res["message"] === "Successfully updated"
        ) {
          this.props.subscribeConsent(this.state.driverPhone, (res) => {
            // console.log("CONSENT STATUS", res);
          });
          NotificationManager.success(res["data"]["message"], "Success");
          window.history.replaceState(null, null, "/");
          // this.props.history.push("/trips");
        } else {
          NotificationManager.error(res["data"]["message"], "Error");
          this.setState({ onSubmitDisable: false });
        }
        this.trackingHistory();
        this.driverModal();
      });
    } else {
      let errDriver = {};
      errDriver.driver = "Required";
      this.setState({ driverMsgErr: errDriver });
    }
  };
  handleReset = () => {
    this.setState({
      incidentdescription: "",
      incidentType: "",
      errorMessage: {},
    });
  };
  handleResetEdit = () => {
    this.setState({
      vehicle: "",
      vehicleForm: "",
      driver: "",
      driverForm: "",
      errorMessageEdit: {},
      driverMsgErr: {},
    });
  };
  render() {
    // console.log(
    //   "this.props.history.state",
    //   this.props?.history?.location?.state?.transporterId
    // );
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkMode = mode === "dark__mode" ? true : false;
    const { driverVehicleHistory } = this.state;
    const { vehicles, drivers } = this.props;
    const role = JSON.parse(localStorage.getItem("authUser"))?.data?.role
      ?.label;
    let vehicleType = this.props.vehicleType.vehicleType;
    let { detailToggle, subDetailToggle1, subDetailToggle2 } = this.state;
    let vehicleOptions = [];
    let driverOptions = [];
    let vehicleTypeOptions = [];
    if (Array.isArray(vehicleType?.data?.docs)) {
      vehicleType.data.docs.forEach((ele) => {
        vehicleTypeOptions.push({
          value: ele._id,
          label: ele.vehicleTypeCode,
        });
      });
    }
    if (Array.isArray(drivers?.drivers?.data?.docs)) {
      drivers.drivers.data.docs.forEach((ele) => {
        driverOptions.push({
          value: ele._id,
          label: ele.phone + " (" + ele.name + ")",
        });
      });
    }
    if (Array.isArray(this.state.transporterVehicleOptions)) {
      this.state.transporterVehicleOptions.forEach((ele) => {
        vehicleOptions.push({
          value: ele._id,
          label: ele.registration.number + " (" + ele.type.label + ")",
        });
      });
    }
    let active1, active2;
    active1 = "active";
    if (subDetailToggle1 === true) {
      active1 = "active";
      active2 = darkMode ? "inactiveDark" : "inactive";
    } else {
      active1 = darkMode ? "inactiveDark" : "inactive";
      active2 = "active";
    }
    // console.log("active1", active1);
    return (
      <div className="tracking">
        <DriverDetailsModal
          isOpen={this.state.driverModal}
          toggle={this.driverModal}
          optionsdriver={driverOptions}
          submitDriverForm={this.handleSubmitSaveDriver}
          onDriverChange={this.onDriverChange}
          driverForm={this.state.driverForm}
          driver={this.state.driver}
          handleSubmitUpdateDriver={this.handleSubmitUpdateDriver}
          filterDriverList={this.filterDriverList}
          onChange={this.handleChangeModal}
          isPhoneNoValid={this.state.isPhoneNoValid}
          mode={darkMode}
          handleResetEdit={this.handleResetEdit}
          driverMsgErr={this.state.driverMsgErr}
        />
        {this.state.reportIncidentModal && (
          <ReportIncidentModal
            id={this.state.selectedTripId}
            isOpen={this.state.reportIncidentModal}
            toggle={this.reportIncidentModal}
            incidents={incidents}
            filterVehicleList={this.filterVehicleList}
            filterDriverList={this.filterDriverList}
            options={vehicleOptions}
            onChange={this.handleChangeModal}
            driver={this.state.driver}
            vehicle={this.state.vehicle}
            optionsdriver={driverOptions}
            submitDriverForm={this.handleSubmitSaveDriver}
            onDriverChange={this.onDriverChange}
            driverForm={this.state.driverForm}
            submitVehicleForm={this.handleSubmitSaveVehicle}
            onVehicleChange={this.onVehicleChange}
            vehicleForm={this.state.vehicleForm}
            type={vehicleTypeOptions}
            incidentdescription={this.state.incidentdescription}
            incidentType={this.state.incidentType}
            onReportIncident={this.onReportIncident}
            onReportInsidentSubmit={this.onReportInsidentSubmit}
            detailchange={this.state.detailchange}
            detailchangefunc={this.detailchange}
            errorMessage={this.state.errorMessage}
            errorMessageEdit={this.state.errorMessageEdit}
            isPhoneNoValid={this.state.isPhoneNoValid}
            isVehicleNoValid={this.state.isVehicleNoValid}
            isVehicleTypeValid={this.state.isVehicleTypeValid}
            toBeSelectedVehicleType={this.state.toBeSelectedVehicleType}
            mode={darkMode}
            handleReset={this.handleReset}
            handleResetEdit={this.handleResetEdit}
          />
        )}
        <Container fluid className="TrackingPage">
          <Row>
            <Col xs={9} className="d-flex flex-row bd-highlight mb-2">
              <Button
                style={{
                  width: "60px",
                  height: "36px",
                  backgroundColor: "#3e3e3e",
                }}
                color="secondary"
                size="sm"
                onClick={() =>
                  this.props.history.push({
                    pathname: "/trips",
                  })
                }
              >
                <i className="fas fa-arrow-circle-left fa-2x"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card className="darkBgQuickFilter">
                <CardBody>
                  <Row className="darkBackgroundList">
                    <Col xs="12">
                      <Card style={{ height: "780px" }}>
                        <div>
                          <TrackingMap
                            detailToggler={this.menuToggle}
                            detailToggle={detailToggle}
                            id={
                              this.state.tripId ? this.state.tripId : undefined
                            }
                            handleVehicleHistory={this.handleVehicleHistory}
                            source={this.state.source}
                            destination={this.state.destination}
                          />
                        </div>
                        {this.state.openToggle === true ? (
                          " "
                        ) : (
                          <span
                            className="spanToggle1"
                            // style={{
                            //   position: "relative",
                            //   top: "5%",
                            //   left: "1%",
                            // }}
                            onClick={() => {
                              this.toggle();
                            }}
                          >
                            <svg
                              className="mt-1"
                              width="37"
                              height="37"
                              viewBox="0 0 37 37"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g filter="url(#filter0_d_4710_242)">
                                <path
                                  d="M6 35L31 35C33.2091 35 35 33.2091 35 31L35 6C35 3.79086 33.2091 2 31 2L6 2C3.79086 2 2 3.79086 2 6L2 31C2 33.2091 3.79086 35 6 35Z"
                                  fill={darkMode ? "#2E2E46" : "#05284E"}
                                />
                              </g>
                              <path
                                d="M21 13.7402L15.8699 18.8704L21 24.0002"
                                stroke={darkMode ? "#B7A9FF" : "white"}
                                strokeWidth="2"
                                strokeLinecap="round"
                              />
                              <defs>
                                <filter
                                  id="filter0_d_4710_242"
                                  x="0"
                                  y="0"
                                  width="37"
                                  height="37"
                                  filterUnits="userSpaceOnUse"
                                  colorInterpolationFilters="sRGB"
                                >
                                  <feFlood
                                    floodOpacity="0"
                                    result="BackgroundImageFix"
                                  />
                                  <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                  />
                                  <feOffset />
                                  <feGaussianBlur stdDeviation="1" />
                                  <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_4710_242"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_4710_242"
                                    result="shape"
                                  />
                                </filter>
                              </defs>
                            </svg>
                          </span>
                        )}
                        {this.state.openToggle ? (
                          <>
                            <div
                              style={{
                                position: "absolute",
                                top: "2%",
                              }}
                            >
                              <span
                                className="spanToggle2"
                                onClick={() => {
                                  this.setState({
                                    openToggle: false,
                                  });
                                }}
                              >
                                <svg
                                  className="mt-5"
                                  width="37"
                                  height="37"
                                  viewBox="0 0 37 37"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g filter="url(#filter0_d_4710_242)">
                                    <path
                                      d="M6 35L31 35C33.2091 35 35 33.2091 35 31L35 6C35 3.79086 33.2091 2 31 2L6 2C3.79086 2 2 3.79086 2 6L2 31C2 33.2091 3.79086 35 6 35Z"
                                      fill={darkMode ? "#2E2E46" : "#05284E"}
                                    />
                                  </g>
                                  <path
                                    d="M21 13.7402L15.8699 18.8704L21 24.0002"
                                    stroke={darkMode ? "#B7A9FF" : "white"}
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                  />
                                  <defs>
                                    <filter
                                      id="filter0_d_4710_242"
                                      x="0"
                                      y="0"
                                      width="37"
                                      height="37"
                                      filterUnits="userSpaceOnUse"
                                      colorInterpolationFilters="sRGB"
                                    >
                                      <feFlood
                                        floodOpacity="0"
                                        result="BackgroundImageFix"
                                      />
                                      <feColorMatrix
                                        in="SourceAlpha"
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha"
                                      />
                                      <feOffset />
                                      <feGaussianBlur stdDeviation="1" />
                                      <feColorMatrix
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
                                      />
                                      <feBlend
                                        mode="normal"
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow_4710_242"
                                      />
                                      <feBlend
                                        mode="normal"
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow_4710_242"
                                        result="shape"
                                      />
                                    </filter>
                                  </defs>
                                </svg>
                              </span>
                              <Card className="CardDetails darkCardStyle">
                                {detailToggle ? (
                                  <div>
                                    <Row>
                                      <Col xs={12}>
                                        <div>
                                          {/* <Button
                            onClick={() => this.menuToggle(false)}
                            style={{
                              lineHeight: "20px",
                              fontSize: "100%",
                              height: "40px",
                              width: "40px",
                            }}
                            className="float-right btn-tracking "
                          >
                            <i className="fas fa-times"></i>
                          </Button> */}
                                          <Row
                                            style={{
                                              position: "relative",
                                              padding: "0px 4px",
                                            }}
                                          >
                                            <Col xs="6">
                                              <Button
                                                className={` btn-tracking ${active1} fontRoboto137 p-0`}
                                                onClick={this.collapseToggle1}
                                              >
                                                {
                                                  userLang?.tracking
                                                    ?.DRIVER_VEHICLE
                                                }
                                              </Button>
                                            </Col>
                                            <Col xs="6">
                                              <Button
                                                className={`btn-tracking ${active2} fontRoboto137 pl-0 `}
                                                onClick={this.collapseToggle2}
                                              >
                                                {
                                                  userLang?.tracking
                                                    ?.VEHICLE_HISTORY
                                                }
                                              </Button>
                                            </Col>
                                          </Row>
                                          <div className="mt-3 ml-1 mr-1">
                                            {subDetailToggle1 ? (
                                              <div>
                                                <Card
                                                  className="darkInternalcard"
                                                  style={
                                                    {
                                                      // height:"50vh",
                                                      // overflow:"auto"
                                                    }
                                                  }
                                                >
                                                  <CardBody>
                                                    {driverVehicleHistory.map(
                                                      (item, index) => {
                                                        // console.log(
                                                        //   "ITEMVEHCICLE",
                                                        //   item
                                                        // );
                                                        return (
                                                          <Row key={index}>
                                                            <Col className="mb-3">
                                                              <span
                                                                className="fontRoboto127 dark__span"
                                                                style={{
                                                                  color:
                                                                    "05284E",
                                                                }}
                                                              >
                                                                {item.key} :{" "}
                                                              </span>
                                                            </Col>
                                                            <Col>
                                                              <span
                                                                className="fontRoboto12 light__span"
                                                                style={{
                                                                  color:
                                                                    "#0C0C0C",
                                                                }}
                                                              >
                                                                {item.value}
                                                              </span>
                                                              {item.key ===
                                                                "Consent Status" &&
                                                              item.value.substring(
                                                                0,
                                                                7
                                                              ) ===
                                                                "PENDING" ? (
                                                                <span>
                                                                  <Button
                                                                    className="tracking_page_subscribe_consent_btn"
                                                                    onClick={() => {
                                                                      this.props.subscribeConsent(
                                                                        this
                                                                          .state
                                                                          .driverPhone,
                                                                        (
                                                                          res
                                                                        ) => {
                                                                          NotificationManager.success(
                                                                            res[
                                                                              "message"
                                                                            ],
                                                                            "Success"
                                                                          );
                                                                        }
                                                                      );
                                                                    }}
                                                                  >
                                                                    <span>
                                                                      <i class="ri-drag-move-fill"></i>
                                                                    </span>
                                                                  </Button>
                                                                </span>
                                                              ) : null}
                                                            </Col>
                                                          </Row>
                                                        );
                                                      }
                                                    )}
                                                    {role !== "transporter" ? (
                                                      <Row>
                                                        <Col
                                                          xs="6"
                                                          className="pl-0"
                                                        >
                                                          <Button
                                                            style={{
                                                              width: "100%",
                                                              padding: "5px",
                                                              height: "26px",
                                                              backgroundColor:
                                                                "#0AB38C",
                                                              color: "#FFFFFF",
                                                              paddingBottom:
                                                                "24px",
                                                            }}
                                                            className="fontRoboto137"
                                                            onClick={() => {
                                                              this.reportIncidentModal();
                                                            }}
                                                          >
                                                            {
                                                              userLang?.tracking
                                                                ?.REPORT_INCIDENT
                                                            }
                                                          </Button>
                                                        </Col>
                                                        <Col
                                                          xs="6"
                                                          className="pl-0"
                                                        >
                                                          <Button
                                                            style={{
                                                              width: "100%",
                                                              padding: "5px",
                                                              height: "26px",
                                                              backgroundColor:
                                                                "#05284E",
                                                              color: "#FFFFFF",
                                                              paddingBottom:
                                                                "24px",
                                                            }}
                                                            className="fontRoboto137 
                                                            darkAgreementAddToList"
                                                            onClick={() => {
                                                              this.driverModal();
                                                            }}
                                                          >
                                                            {
                                                              userLang?.tracking
                                                                ?.CHANGE_DRIVER
                                                            }
                                                          </Button>
                                                        </Col>
                                                      </Row>
                                                    ) : null}
                                                  </CardBody>
                                                </Card>
                                              </div>
                                            ) : null}
                                            {subDetailToggle2 ? (
                                              <div>
                                                <Card
                                                  className="darkInternalcard"
                                                  style={{
                                                    // height: "57vh",
                                                    overflowY: "scroll",
                                                    height: "525px",
                                                  }}
                                                >
                                                  <CardBody>
                                                    {/* <span
                                                style={{
                                                  borderLeft:
                                                    "5px dashed black",
                                                  height: "100%",
                                                }}
                                              ></span> */}
                                                    {this.state.history.map(
                                                      (item, index) => {
                                                        // console.log(
                                                        //   "item sub",
                                                        //   item
                                                        // );
                                                        return (
                                                          <Row
                                                            key={index}
                                                            className="pb-3"
                                                          >
                                                            <Col
                                                              xs={5}
                                                              className="mb-3"
                                                            >
                                                              <b className="dark__span">
                                                                {item.timeStamp}
                                                              </b>
                                                            </Col>
                                                            <Col xs={2}>
                                                              <i
                                                                style={{
                                                                  color:
                                                                    "#5664D2",
                                                                }}
                                                                className="fas fa-circle iconSize trackDots"
                                                              ></i>
                                                              <div className="DashedLine darkDashed"></div>
                                                            </Col>
                                                            <Col xs={5}>
                                                              <span className="dark__span">
                                                                {item.location}
                                                              </span>
                                                            </Col>
                                                          </Row>
                                                        );
                                                      }
                                                    )}
                                                  </CardBody>
                                                </Card>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                ) : null}
                              </Card>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    indents: state.Indent.indents.assignedIndents,
    vehicles: state.Vehicle.vehicles,
    drivers: state.Driver.drivers,
    policies: state.AccessPolicy.accessPolicies.policies,
    vehicleType: state.VehicleType.vehicleType,
  };
};

export default connect(mapStateToProps, {
  fetchDrivers,
  fetchVehicles,
  fetchVehicleTypesList,
  createReportIncident,
  updateDriver,
  createDriver,
  createVehicle,
  fetchTripById,
  subscribeConsent,
})(Tracking);
