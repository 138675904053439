import React, { Component } from 'react'
import { Container, Card, CardBody, Row, Col, Button } from "reactstrap";
import { Grid } from "@material-ui/core";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export default class BillToDetails extends Component {

    constructor(props) {
        super();
        this.state = {
          
        };
      }
    
     
      render() {
        userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
        let darkState = this.props.darkTheme;
        const { currentInvoice } = this.props;
        const rowsTopSpaceStyle = { marginTop: "0px" };
        const dataBoxStyle = {
          height: "100%",
          overflowY: "scroll",
          overflowX: "clip",
          padding: "0%",
        };
        const oddRowStyle = {
          minHeight: "27px",
          width: "101%",
          margin: "auto",
          marginTop: "4px",
          marginRight: "0px",
        };
        const evenRowStyle = {
          minHeight: "27px",
          width: "101%",
          margin: "auto",
          marginTop: "4px",
          marginRight: "0px",
        };
    
        const rowHeaderStyle = {
          fontFamily: "Roboto",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "15px",
          color: darkState ? "#9D9D9D" : "#05284E",
          lineHeight: "18px",
          margin: "auto",
          paddingLeft: "1.5vw",
        };
        const rowSeparaterStyle = {
          borderLeft: "0.5px solid rgba(185, 185, 185, .5)",
          height: "172px",
          float: "left",
        };
        const rowSeparaterStyle1 = {
          borderLeft: "0.5px solid rgba(185, 185, 185, .5)",
          height: "355px",
          marginTop: "53px",
          float: "left",
        };
        const rowValueStyle = {
          fontFamily: "Roboto",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "13px",
          color: darkState ? "#9D9D9D" : "#0C0C0C",
          margin: "auto",
          flex: "wrap",
          alignContent: "flex-start",
        };
    
        return (
          <div>
           <Grid className="mt-4" container style={oddRowStyle}>
                      <Grid
                        // className=""
                        item
                        xs={3}
                        md={4}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        <span>
                          Bill to Name <span className="text-bold">:</span>
                        </span>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        md={8}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {currentInvoice?.orders?.billToDetails?.billedToName}
                      </Grid>
                    </Grid>

                    <Grid className="" container style={evenRowStyle}>
                      <Grid
                        item
                        xs={3}
                        md={4}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        <span>
                          Bill to Address 1 <span className="text-bold">:</span>
                        </span>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        md={8}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {currentInvoice?.orders?.billToDetails?.billedToAddress1}
                      </Grid>
                    </Grid>

                    <Grid className=" " container style={oddRowStyle}>
                      <Grid
                        item
                        xs={3}
                        md={4}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        <span>
                          {" "}
                          Bill to Address 2<span className="text-bold">:</span>
                        </span>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        md={8}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {currentInvoice?.orders?.billToDetails?.billedToAddress2}
                      </Grid>
                    </Grid>
                    <Grid className=" " container style={evenRowStyle}>
                      <Grid
                        item
                        xs={3}
                        md={4}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        <span>
                          {" "}
                          Bill to Address 3 <span className="text-bold">:</span>
                        </span>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        md={8}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {currentInvoice?.orders?.billToDetails?.billedToAddress3}
                      </Grid>
                    </Grid>
                    <Grid className=" " container style={oddRowStyle}>
                      <Grid
                        item
                        xs={3}
                        md={4}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        <span>
                          {" "}
                          Bill to Address 4 <span className="text-bold">:</span>
                        </span>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        md={8}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {currentInvoice?.orders?.billToDetails?.billedToAddress4}
                      </Grid>
                    </Grid>

                    <Grid className="mt-4" container style={oddRowStyle}>
                      <Grid
                        // className=""
                        item
                        xs={3}
                        md={4}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        <span>
                          Bill to City<span className="text-bold">:</span>
                        </span>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        md={8}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {currentInvoice?.orders?.billToDetails?.billedToCity}
                      </Grid>
                    </Grid>

                    <Grid className="" container style={evenRowStyle}>
                      <Grid
                        item
                        xs={3}
                        md={4}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        <span>
                          Bill to Destination{" "}
                          <span className="text-bold">:</span>
                        </span>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        md={8}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {currentInvoice?.orders?.billToDetails?.billedToDestination}
                      </Grid>
                    </Grid>

                    <Grid className=" " container style={oddRowStyle}>
                      <Grid
                        item
                        xs={3}
                        md={4}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                          <span>
                          Bill to Zip {" "}
                          <span className="text-bold">:</span>
                        </span>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        md={8}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {currentInvoice?.orders?.billDetails?.billedToZip}
                      </Grid>
                    </Grid>
                    <Grid className=" " container style={evenRowStyle}>
                      <Grid
                        item
                        xs={3}
                        md={4}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        <span>
                          {" "}
                          Bill to Country<span className="text-bold">:</span>
                        </span>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        md={8}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {currentInvoice?.orders?.billDetails?.billedToCountry}
                      </Grid>
                    </Grid>
                    <Grid className=" " container style={oddRowStyle}>
                      <Grid
                        item
                        xs={3}
                        md={4}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        <span>
                          {" "}
                          Bill to GSTN No <span className="text-bold">:</span>
                        </span>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        md={8}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {currentInvoice?.orders?.billToDetails?.billedToGSTNNo}
                      </Grid>
                    </Grid>
                    <Grid className=" " container style={oddRowStyle}>
                      <Grid
                        item
                        xs={3}
                        md={4}
                        style={rowHeaderStyle}
                        className="MobilefontRoboto127"
                      >
                        <span>
                          {" "}
                          Bill to State Name{" "}
                          <span className="text-bold">:</span>
                        </span>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        md={8}
                        style={rowValueStyle}
                        className="MobilefontRoboto124"
                      >
                        {currentInvoice?.orders?.billToDetails?.billedToStateName}
                      </Grid>
                    </Grid>
          </div>
        );
      }
}
