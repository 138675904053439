import React, { Component } from "react";
import { FormGroup, Label } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";

class Fields extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <FormGroup className="auth-form-group-custom mb-4">
        <i className="ri-user-2-line auti-custom-input-icon"></i>
        <Label htmlFor={this.props.name}>{this.props.Label}</Label>
        <AvField
          name={this.props.name}
          value={this.props.value}
          type={this.props.type ? this.props.type : ""}
          errorMessage={this.props.errorMessage}
          validate={this.props.validate}
          placeholder={this.props.placeholder}
        />
      </FormGroup>
    );
  }
}
export default Fields;
