import { types } from "./actionTypes";
const initialState = {
  getWeightOfInvoiceItemsData: null,
  fetchId: "",
};
const weightOfInvoiceItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_WEIGHTOFINVOICEITEMS_SUCCESS:
      state = {
        ...state,
        getWeightOfInvoiceItemsData: action.payload.data,
        fetchId: "id" + new Date().getTime()
      };
      return state;
      case types.LOAD_OPTIMIZATION_DATA_ACCESS_SUCCESS:
      return { ...state, loadOptimizationBaxData: action.payload };
    default:
      return state;
  }
};
export default weightOfInvoiceItemsReducer;
