import React, { Component } from "react";
import { CardBody } from "reactstrap";
import {Box, Grid , Card} from "@material-ui/core"
import { connect } from "react-redux";
import { trackLocationTripById } from "../../store/trip/actions";
import dotGroup from "./assets/dot.png";
import moment from "moment";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

class TrackingPath extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      TripAddress: [],
    };
  }
  componentDidMount = () => {
    // console.log("TRIP ID", this.props.tripTrackId, "====", this.props);
    let tripId = this.props.tripTrackId;
    let trackTripPath = [];

    this.props.trackLocationTripById(tripId, (res) => {
      if (Array.isArray(res?.data)) {
        var trackAddress = res?.data.map((ele) => {
          let date = moment(ele["createdAt"]).format("MM-DD-YYYY");
          let time = moment(ele["createdAt"]).format("HH:mm:ss");
          let address = ele?.locationDescription
            ? ele?.locationDescription
            : "N/A";
          //console.log(moment(ele['createdAt']).format('MM-DD-YYYY'),"elepathdATE")
          //console.log(moment(ele['createdAt']).format( "HH:mm:ss"),"elepathTIME")
          return { date: date, time: time, address: address };
        });

        // console.log(trackAddress, "trackTripPath");
        trackTripPath.push(trackAddress);

        this.setState({ TripAddress: trackTripPath });
      } else {
        // console.log("no data");
      }
    });
  };
  render() {
    let mode = localStorage.getItem("Theme");
    var darkState = mode === "dark__mode" ? true : false;
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

    return (
      <React.Fragment>
        <Card className="trackPathCard">
          <CardBody>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <span
                    className={`trackHeading ${darkState ? "light__span" : ""}`}
                  >
                    {userLang?.trip?.TRIP_ID}:{" "}
                  </span>

                  <span
                    className={`trackValuetext ${
                      darkState ? "dark__span" : ""
                    }`}
                  >
                    {this.props.tripIdHistory}
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <span
                    className={`trackHeading ${darkState ? "light__span" : ""}`}
                  >
                    {userLang?.trip?.DRIVER}:{" "}
                  </span>
                  <span
                    className={`trackValuetext ${
                      darkState ? "dark__span" : ""
                    }`}
                  >
                    {this.props.nameDriver}
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <span
                    className={`trackHeading ${darkState ? "light__span" : ""}`}
                  >
                    {userLang?.common?.TRANSPORTER} :{" "}
                  </span>
                  <span
                    className={`trackValuetext ${
                      darkState ? "dark__span" : ""
                    }`}
                  >
                    {this.props.nameTransporter}
                  </span>
                </Grid>
              </Grid>
            </Box>

            <Card
              className={`path-card mt-4 ${
                darkState ? "darkInternalcard dark__span" : ""
              }`}
            >
              <CardBody>
                <div className="container-fluid">
                  {/* <div className='row'>
                    <div className='col'> */}
                  {this.state.TripAddress[0]?.map((e, i) => (
                    <Grid container key={i} columnSpacing={3}>
                      <Grid item xs={4}>
                        <div>
                          <span
                            className={`dateTextStyle ${
                              darkState ? "dark__span" : ""
                            }`}
                          >
                            {e?.date}
                          </span>
                        </div>
                        <div>
                          <span
                            className={`dateTextStyle ${
                              darkState ? "dark__span" : ""
                            }`}
                          >
                            {e?.time}
                          </span>
                        </div>
                        <br></br>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        style={{
                          backgroundColor: darkState ? "#2E2E46" : "#F1F5F7",
                        }}
                      >
                        <div>
                          <img
                            src={dotGroup}
                            style={{
                              width: " 25%",
                              height: "80%",
                              float: "right",
                              marginRight: "8px",
                              marginTop: "6px",
                            }}
                            className=" shadow-1-strong rounded mb-4 "
                            alt=""
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div>
                          <span className="TextStyle">{e?.address}</span>
                        </div>
                        <br></br>
                      </Grid>
                    </Grid>
                  ))}
                  {/* </div>
                  </div> */}
                </div>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ...state,
    trips: state.Trip.trips.trips,
    locationtripById: state.Trip.trips.locationTrip,
  };
};
export default connect(mapStateToProps, {
  trackLocationTripById,
})(TrackingPath);
