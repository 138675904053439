import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
//medthods
import { getApi ,postApi} from "../../helpers/auth_helper";

// workers
function* workerGetWeightOfInvoiceItems({ legalInvoiceArray, callback }) {
    let legalInvoiceNumbers = legalInvoiceArray
    console.log('legalInvoiceNumbers', legalInvoiceNumbers.legalInvoiceArray)


    if (legalInvoiceArray.legalInvoiceArray.length && legalInvoiceArray.legalInvoiceArray !== undefined) {
        legalInvoiceArray.legalInvoiceArray.forEach((ele, Index) => {
          if (Index === 0) {
            legalInvoiceNumbers = ele;
          } else legalInvoiceNumbers = legalInvoiceNumbers + "," + ele;
        });
      }


  try {
    const url =
      process.env.REACT_APP_SERVERURL +
      "/order/getWeightOfInvoiceItems?legalInvoiceNumbers=" +
      legalInvoiceNumbers;

    const response = yield call(getApi, url);
    callback(response);
    localStorage.setItem(
      "getWeightOfInvoiceItems",
      response ? JSON.stringify(response) : null
    );
    yield put({
      type: types.GET_WEIGHTOFINVOICEITEMS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workBaxFetchLoadOptimizationData({
  values,
  callback
}) {
  try {
    const uri = "https://load-optimization.dev.cloudtrack.in/report";
    const response = yield call(postApi, uri, values);
    callback(response);
    yield put({
      type: types.LOAD_OPTIMIZATION_DATA_ACCESS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    // Act on the error
    console.countlog("Request failed! Could not fetch posts.", error);
  }
}
// watchers
export function* watchWeightOfInvoiceItem() {
  yield takeEvery(types.GET_WEIGHTOFINVOICEITEMS, workerGetWeightOfInvoiceItems);
}
export function* watchBaxFetchLoadOptimizationData() {
  yield takeEvery(
    types.LOAD_OPTIMIZATION_DATA_ACCESS,
    workBaxFetchLoadOptimizationData
  );
}

function* baxterLoadOptimizationSaga() {
  yield all([
    fork(watchWeightOfInvoiceItem),
    fork(watchBaxFetchLoadOptimizationData),
  ]);

 
}
export default baxterLoadOptimizationSaga;
