export const types = {
  CREATE_PRODUCT:"CREATE_PRODUCT",
  CREATE_PRODUCT_SUCCESS:"CREATE_PRODUCT_SUCCESS",
  CREATE_PRODUCT_VIA_EXCEL:"CREATE_PRODUCT_VIA_EXCEL",
  CREATE_PRODUCT_VIA_EXCEL_SUCCESS:"CREATE_PRODUCT_VIA_EXCEL_SUCCESS",
  GET_PRODUCTS:"GET_PRODUCTS",
  GET_PRODUCTS_SUCCESS:"GET_PRODUCTS_SUCCESS",
  GET_PRODUCTS_EXCEL:"GET_PRODUCTS_EXCEL",
  GET_PRODUCTS_SUCCESS_EXCEL:"GET_PRODUCTS_SUCCESS_EXCEL",
  UPDATE_PRODUCT:"UPDATE_PRODUCT",
  UPDATE_PRODUCT_SUCCESS:"UPDATE_PRODUCT_SUCCESS",
  DELETE_PRODUCT:"DELETE_PRODUCT",
  DELETE_PRODUCT_SUCCESS:"DELETE_PRODUCT_SUCCESS",
  UPDATE_OPTIMIZATION_VAlUES: "UPDATE_OPTIMIZATION_VAlUES",
  UPDATE_OPTIMIZATION_VAlUES_SUCCESS: "UPDATE_OPTIMIZATION_VAlUES_SUCCESS",   
}