import { types } from "./actionTypes";

// creating action
export const getOperationalDashboard = (filters, searchFilter, callback) => {
  return {
    type: types.GET_OPERATIONAL_DASHBOARD,
    filters,
    searchFilter,
    callback,
  };
};

