import React, { Component } from "react";
import { CloseIcon } from "../../components/Common/CommonIcons";
import { Grid } from "@material-ui/core";
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody } from "mdbreact";
import { Row, Col, Input } from "reactstrap";
import { connect } from "react-redux";
import { qualityCheckStart } from "../../store/baxter-trip/actions";
class QualityCheckStart extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
  }
  

  render() {
    let mode = localStorage.getItem("Theme");
    var darkState = mode === "dark__mode" ? true : false;
    const oddRowStyle = {
      minHeight: "57px",
      width: "101%",
      backgroundColor: darkState ? "#24243C" : "#ffffff",
      border: "0.5px solid rgba(185, 185, 185, 0.5)",
      borderRadius: "5px",
      margin: "auto",
      marginTop: "4px",

      marginRight: "3px",
    };
    const evenRowStyle = {
      minHeight: "57px",
      width: "101%",
      backgroundColor: darkState ? "#2E2E46" : "#F1F5F7",
      border: "0.5px solid rgba(185, 185, 185, 0.5)",
      borderRadius: "5px",
      margin: "auto",
      marginTop: "4px",
      marginRight: "3px",
    };

    const rowHeaderStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "15px",
      color: darkState ? "#9D9D9D" : "#05284E",
      lineHeight: "18px",
      margin: "auto",
      paddingLeft: "1.5vw",
    };
    const rowSeparaterStyle = {
      borderLeft: "0.5px solid rgba(185, 185, 185, .5)",
      height: "100%",
    };
    const rowValueStyle = {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      color: darkState ? "#9D9D9D" : "#0C0C0C",
      margin: "auto",
      flex: "wrap",
    };
    return (
      <>
        <Grid
          className="shadow indentData-hover"
          container
          style={this.props?.id % 2 === 0 ? oddRowStyle : evenRowStyle}
          key={this.props?.id}
        >
          <Grid className="" item xs={5} md={7} style={rowHeaderStyle}>
            <span className="fontRoboto15 dark__span">{this.props.action}</span>
          </Grid>
          <Grid
            item
            // xs={1}
            style={{
              padding: "2vh 0px",
              marginLeft: "-20px",
            }}
          >
            <div style={rowSeparaterStyle}></div>
          </Grid>
          <Grid
            item
            xs={4}
            md={2}
            style={rowValueStyle}
            justifyContent="center"
            alignItems="center"
          >
            <>
              <ul
                style={{
                  marginTop: "1rem",
                  marginLeft: "-13px",
                }}
              >
                <div className="form-check form-check-inline">
                  <Row>
                    <Col xs="12" sm={4} className="text-nowrap">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`question-${this.props.id}`}
                        value="Yes"
                        checked={this.props.answer === "Yes"}
                        onChange={(event) =>
                          this.props.handleAnswerChange(event, this.props.id)
                        }
                        id={this.props.id}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={this.props.id}
                      >
                        Yes
                      </label>
                     
                    </Col>
                    <Col xs="12" sm={4} className="text-nowrap">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`question-${this.props.id}`}
                        value="No"
                        checked={this.props.answer === "No"}
                        onChange={(event) =>
                          this.props.handleAnswerChange(event, this.props.id)
                        }
                      />
                      <label className="form-check-label"  htmlFor={this.props.id}>No</label>
                    
                    </Col>
                    <Col xs="12" sm={4} className="text-nowrap">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={`question-${this.props.id}`}
                        value="NA"
                        checked={this.props.answer === "NA"}
                        onChange={(event) =>
                          this.props.handleAnswerChange(event, this.props.id)
                        }
                      />
                      <label className="form-check-label"  htmlFor={this.props.id}>NA</label>
                    
                    </Col>
                  </Row>
                </div>
              </ul>
            </>
          </Grid>
          <Grid
            item
            // xs={1}
            style={{
              padding: "2vh 0px",
              marginLeft: "-20px",
            }}
          >
            <div style={rowSeparaterStyle}></div>
          </Grid>
          <Grid
            item
            xs={4}
            md={2}
            style={rowValueStyle}
            justifyContent="center"
            alignItems="center"
          >
            <>
              <ul
                style={{
                  marginTop: "1rem",
                  marginLeft: "-13px",
                }}
              >
                <div className="form-check form-check-inline">
                  <Row>
                    <Col xs="12" sm={12} className="text-nowrap">
                    <Input
                      name="comments"
                      placeholder="Comments"
                      type="text"
                      size="xs"
                      value={this.props.comments}
                      className="form-control-md mt-2"
                      id="exampleFormControlInput1"
                      onChange={(event) =>
                        this.props.handleCommentInput(event,this.props.id)
                      }
                    />
                     
                    </Col>
                   
                  </Row>
                </div>
              </ul>
            </>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default connect(null, {
  qualityCheckStart,
})(QualityCheckStart);
