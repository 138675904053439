import React, { Component } from 'react';
import { Card , Row, Col, Button} from 'reactstrap';

export default class LoadOptimizationHeader extends Component {
  render() {
    // console.log("this.props", this.props)

    if (this.props.location?.pathname === "/load-optimization") {
        var BgClr = "#0AB38C";
        var Clr = "#FFFFFF";
      } else {
        var bgClr ="#F1F5F7";
        var clr ="#05284E";
      }
    return (
        <React.Fragment>
        <Card 
        // className={`${mode === "dark__mode"?"darkBgQuickFilter":""}`}
        >
          <div className="quickStylesBar">
            <Row>
              <Col  xs={6} sm={3} md={2} lg={1} className="pr-2 mr-2">
              <Button
                  className="fontRoboto127"
                  variant="outlined"
                  style={{
                    backgroundColor: `${BgClr || bgClr}`,
                    color: `${Clr || clr}`,
                    width:"150px"
                  }}
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/load-optimization",
                    });
                  }}
                >
                    Optimizer
                  {/* {this.props?.userLang?.dashboard?.OPERATIONS} */}
                </Button>
              </Col> 
              <Col  xs={6} sm={3} md={2}  lg={1} className="pr-2 loadoptimizationButtons">
                  <Button
                    className="fontRoboto127 VehicleTypeButton"
                   >
                  Vehicle Type Master
                  </Button>
              </Col>
              <Col  xs={12} sm={6} md={8}  className="pr-2">
              </Col>
            </Row>  
          </div>
        </Card>
      </React.Fragment>
    )
  }
}
