import React, { Component } from "react";
import { Row, Col, Card, Button } from "reactstrap";
import exportDarkIcon from "./assets/external-linkDark.png";
import Control from "../../../components/Common/Controls/Button";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./reportListTheme.scss";
import exportIcon from "./assets/external-link.png";
import refreshIcon from "./assets/refresh-cw.png";
import { Grid } from "@material-ui/core";
import { InputGroup } from "rsuite";
import Datepicker from "../../../components/Common/DatePicker/DatePicker";

let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
var darkMode;
class QuickFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hops: [],
      vehicleTypes: [],
      // searchBar: [],
      searchFilters: {},
      dateValue: [null, null],
      showCalender: false,
      userLangs: undefined,
      fromDate: null,
      toDate: null,
    };
  }
  handleClickAway = () => {
    if (
      this.state?.dateValue[0] !== null &&
      this.state?.dateValue[1] !== null
    ) {
      this.setState({ showCalender: false });
    }
  };
  handleOpen = () => {
    this.setState({ showCalender: true });
  };
  handleClose = () => {
    this.setState({ showCalender: false });
  };
  componentDidUpdate() {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  }
  onChangeDatePicker = (value, label) => {
    if (label === "fromDate") {
      this.setState({ fromDate: value }, () => {
        this.props.quickFilters("date", [
          this.state.fromDate,
          this.state.toDate,
        ]);
      });
    }
    if (label === "toDate") {
      this.setState({ toDate: value }, () => {
        this.props.quickFilters("date", [
          this.state.fromDate,
          this.state.toDate,
        ]);
      });
    }
  };
  render() {
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : "";
    darkMode = mode === "dark__mode" ? true : false;

    if (this.props.location?.pathname === "/indentReport-analysis") {
      var OperationsBgClr = "#0AB38C";
      var operationsClr = "#FFFFFF";
      var border = "";
    } else {
      OperationsBgClr = `${darkMode ? "#171722" : "#F1F5F7"}`;
      operationsClr = `${darkMode ? "#ffffff" : "#05284E"}`;
      border = `${darkMode ? "0.5px solid #616161" : " "}`;
    }
    if (this.props.location?.pathname === "/statusIndent") {
      var statusIndentBgClr = "#0AB38C";
      var statusIndentClr = "#FFFFFF";
      var borderStat = "";
    } else {
      statusIndentBgClr = `${darkMode ? "#171722" : "#F1F5F7"}`;
      statusIndentClr = `${darkMode ? "#ffffff" : "#05284E"}`;
      borderStat = `${darkMode ? "0.5px solid #616161" : " "}`;
    }
    if (this.props.location?.pathname === "/transporterIndent") {
      var transpoterBgClr = "#0AB38C";
      var transpoterClr = "#FFFFFF";
      var borderTrans = "";
    } else {
      transpoterBgClr = `${darkMode ? "#171722" : "#F1F5F7"}`;
      transpoterClr = `${darkMode ? "#ffffff" : "#05284E"}`;
      borderTrans = `${darkMode ? "0.5px solid #616161" : " "}`;
    }

    return (
      <React.Fragment>
        <Card className="darkBgQuickFilter">
          <div className="quickStylesBar reportStyle repotCal">
            <>
              <div className="">
                <Row>
                  <Col md="8">
                    <div className="d-flex align-items-center example-parent cardStyleR">
                      <div className="p-1 col-example filterR">
                        <Control
                          variant="outlined"
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/indentReport-analysis",
                            });
                          }}
                          style={{
                            backgroundColor: `${OperationsBgClr}`,
                            color: `${operationsClr}`,
                            border: `${border}`,
                            width: "130px",
                          }}
                          text={this.state.userLangs?.report?.LANE}
                        />
                      </div>
                      <div className="p-1 col-example filterR ">
                        <Control
                          variant="outlined"
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/statusIndent",
                            });
                          }}
                          style={{
                            backgroundColor: `${statusIndentBgClr}`,
                            color: `${statusIndentClr}`,
                            border: `${borderStat}`,
                            width: "130px",
                          }}
                          text={this.state.userLangs?.common?.STATUS}
                        />
                      </div>
                      <div className="p-1 col-example filterR">
                        {" "}
                        <Control
                          className="operations-dashboard-btn-operations"
                          variant="outlined"
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/transporterIndent",
                            });
                          }}
                          style={{
                            backgroundColor: `${transpoterBgClr}`,
                            color: `${transpoterClr}`,
                            border: `${borderTrans}`,
                          }}
                          text={this.state.userLangs?.common?.TRANSPORTER}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md="4">
                    <Grid
                      container
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        className="datePickerGrid"
                      >
                        <div
                          className="date-picker-div RsuiteDateRange dashboardDateRange"
                          style={{
                            width: "100%",
                            marginLeft: "20px",
                          }}
                        >
                          <div className="datepicker-selector">
                            <InputGroup>
                              <Datepicker
                                mode={darkMode}
                                value={this.state.fromDate}
                                onChangeDatePicker={this.onChangeDatePicker}
                                fromDate={true}
                                toDate={false}
                                placeholder={"Start Date"}
                                dashboard={true}
                              />
                              <Datepicker
                                mode={darkMode}
                                value={this.state.toDate}
                                onChangeDatePicker={this.onChangeDatePicker}
                                fromDate={false}
                                toDate={true}
                                placeholder={"End Date"}
                                dashboard={true}
                              />
                            </InputGroup>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <Button
                          onClick={() => {
                            this.setState(
                              {
                                dateValue: [null, null],
                                fromDate: null,
                                toDate: null,
                              },
                              () => {
                                this.props.reset();
                              }
                            );
                          }}
                          className="reset"
                        >
                          {this.state.userLangs?.common?.RESET}
                        </Button>
                      </Grid>

                      <Grid item xs>
                        <div>
                          <div className="tooltipIcon">
                            <Button
                              disabled={!navigator.onLine}
                              onClick={() => this.props.toggleExcel()}
                              className={`iconButtons ${
                                darkMode ? "iconDarkButtons" : ""
                              }`}
                            >
                              <img
                                style={{ margin: "-4px" }}
                                src={darkMode ? exportDarkIcon : exportIcon}
                                alt="exp"
                              />
                            </Button>
                            <span
                              className="tooltipIcontext"
                              style={{ width: "55px" }}
                            >
                              &nbsp;{this.state.userLangs?.invoice?.DOWNLOAD_EXCEL??'Download Excel'}
                              &nbsp;
                            </span>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Col>
                </Row>
              </div>
            </>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

export default connect(null)(withRouter(QuickFilters));
