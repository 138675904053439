let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: "Item Number",
        field: "itemNumber",
        
        width: 2,
      },
      {
        label: "Description",
        field: "description",
        
        width: 3,
      },
      {
        label: "Description Line2",
        field: "descriptionLine2",
        
        width: 3,
      },
      {
        label: "QV SubDivision",
        field: "qvSubDivision",
        
        width: 2,
      },
      {
        label: "Case In Size",
        field: "caseInSize",
        
        width: 2,
      },
      {
        label: "Case Per Pallet",
        field: "casePerPallet",
        
        width: 2,
      },
      {
        label: "Weight Per Case(kg)",
        field: "weightPerCaseInKGS",
        
        width: 2,
      },
      {
        label: "Length(cm)",
        field: "lInCMS",
        
        width: 2,
      },
      {
        label: "Width(cm)",
        field: "wInCMS",
        
        width: 2,
      },
      {
        label: "Height(cm)",
        field: "hInCMS",
        
        width: 2,
      },
      {
        label: userLang?.common?.ACTIONS,
        field: "actions",
      
        width: 1.5,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var AdminHeaderData = {
  columns: [
    {
      label: "Item Number",
      field: "itemNumber",
      width: 1,
    },
    {
      label: "Description",
      field: "description",
      
      width: 1,
    },
    {
      label: "Description Line2",
      field: "descriptionLine2",
      
      width: 2,
    },
    {
      label: "QV SubDivision",
      field: "qvSubDivision",
      
      width: 2,
    },
    {
      label: "Case In Size",
      field: "caseInSize",
      
      width: 2,
    },
    {
      label: "Case Per Pallet",
      field: "casePerPallet",
      
      width: 2,
    },
    {
      label: "Weight Per Case(kg)",
      field: "weightPerCaseInKGS",
      
      width: 2,
    },
    {
      label: "Length(cm)",
      field: "lInCMS",
      
      width: 1,
    },
    {
      label: "Width(cm)",
      field: "wInCMS",
      
      width: 1,
    },
    {
      label: "Height(cm)",
      field: "hInCMS",
      
      width: 1,
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "actions",
      
      width: 2,
    },
    // {
    //   label: "Action",
    //   field: "action",
    //   sort: "asc",
    //   width: 1,
    // },
  ],
};
export var searchBar;
searchBar = {
  searchBarData: [
    {
      label: "Item Number",
      field: "Item Number",
      type: "text",
    },
    {
      label: "Description",
      field: "description",
      type: "text",
    },
    {
      label: "Description Line2",
      field: "Description Line2",
      type: "text",
    },
    {
      label: "QV SubDivision",
      field: "QV SubDivision",
      type: "text",
    },
    {
      label: "Case In Size",
      field: "Case In Size",
      type:"text"
    },
    {
      label: "Case Per Pallet",
      field: "Case Per Pallet",
      type:"text"
    },
    {
      label: "Weight Per Case",
      field: "Weight Per Case",
      type:"text"
    },
    {
      label: "Length",
      field: "Length",
      type:"text"
    },
    {
      label: "Width",
      field: "Width",
      type:"text"
    },
    {
      label: "Height",
      field: "Height",
      type:"text"
    },
    {
      label: "sort By",
      field: "sortBy",
      type: "text",
    },
  ],
};
