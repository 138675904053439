import { types } from "./actionTypes";

// creating action
export const getZoneToStateMap = (filters, searchFilter, callback) => {
  return {
    type: types.ZONE_TO_STATE_MAP,
    filters,
    searchFilter,
    callback,
  };
};

export const AddZoneToStateMapping = (Values, callback) => {
  return {
    type: types.ADD_ZONE_TO_STATE_MAP,
    Values,
    callback,
  };
};


export const deleteZoneToStateMapping = (Id, callback) => {
  return {
    type: types.DELETE_ZONE_TO_STATE_MAP,
    Id,
    callback,
  };
};


