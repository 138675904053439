
export const types = {
  FETCH_ROLES: "FETCH_ROLES",
  FETCH_ROLES_SUCCESS: "FETCH_ROLES_SUCCESS",
  FETCH_ROLES_EXCEL: "FETCH_ROLES_EXCEL",
  FETCH_ROLES_SUCCESS_EXCEL: "FETCH_ROLES_SUCCESS_EXCEL",
  FETCH_ROLEBYID: "FETCH_ROLEBYID",
  FETCH_ROLEBYID_SUCCESS: "FETCH_ROLEBYID_SUCCESS",
  FETCH_ROLE_GRP_POLICIES: "FETCH_ROLE_GRP_POLICIES",
  FETCH_ROLE_GRP_POLICIES_SUCCESS:"FETCH_ROLE_GRP_POLICIES_SUCCESS",
  FETCH_POLICIES_LIST: "FETCH_POLICIES_LIST",
  FETCH_POLICIES_LIST_SUCCESS: "FETCH_POLICIES_LIST_SUCCESS",

  FETCH_AVAIL_USER_ACCESS_LIST: "FETCH_AVAIL_USER_ACCESS_LIST",
  FETCH_AVAIL_USER_ACCESS_LIST_SUCCESS: "FETCH_AVAIL_USER_ACCESS_LIST_SUCCESS",
  CREATE_ROLE_POLICY: "CREATE_ROLE_POLICY",
  CREATE_ROLE_POLICY_SUCCESS: "CREATE_ROLE_POLICY_SUCCESS",
  FETCH_ACCESS_POLICY_BYID: "FETCH_ACCESS_POLICY_BYID",
  FETCH_ROLE_BYID_SUCCESS: "FETCH_ROLE_BYID_SUCCESS",
  EDIT_ROLE: "EDIT_ROLE",
  DELETE_ROLE: "DELETE_ROLE",
  DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
  CREATE_ROLE_POLICY:"CREATE_ROLE_POLICY",
  CREATE_ROLE_POLICY_SUCCESS: "CREATE_ROLE_POLICY_SUCCESS",
  FETCH_FILTER_ROLES: "FETCH_FILTER_ROLES",
  FETCH_FILTER_ROLES_SUCCESS: "FETCH_FILTER_ROLES_SUCCESS",
};
