import React, { Component } from "react";

import { Row, Col, Card, CardBody, Container } from "reactstrap";
// Editable
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

const products = [
  { id: 1, age: 25, qty: 1500, cost: 1000 },
  { id: 2, age: 34, qty: 1900, cost: 1300 },
  { id: 3, age: 67, qty: 1300, cost: 1300 },
  { id: 4, age: 23, qty: 1100, cost: 6400 },
  { id: 5, age: 78, qty: 1400, cost: 4000 },
];

const columns = [
  {
    dataField: "id",
    text: userLang?.common?.ID,
    sort: true,
  },
  {
    dataField: "age",
    text: `${userLang?.common?.AGE} (${userLang?.common?.AUTOFILL})`,
    sort: true,
  },
  {
    dataField: "qty",
    text: `${userLang?.common?.QTY}
      (${
        userLang?.common?.AUTOFILL +
        userLang?.common?.AND +
        userLang?.common?.EDITABLE
      })`,
    sort: true,
  },
  {
    dataField: "cost",
    text: `${userLang?.common?.QTY} (${userLang?.common?.EDITABLE})`,
    sort: true,
  },
];

class EditableTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: userLang?.common?.TABLES, link: "#" },
        { title: userLang?.common?.EDITABLE_TABLE, link: "#" },
      ],
    };
  }

  render() {
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={userLang?.common?.EDITABLE_TABLE}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title">
                      {userLang?.common?.DATA_TABLE}
                    </h4>

                    <div className="table-responsive">
                      <BootstrapTable
                        keyField="id"
                        data={products}
                        columns={columns}
                        cellEdit={cellEditFactory({ mode: "click" })}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default EditableTables;
