import React, { Component } from "react";
import { Card, Col, Row } from "reactstrap";
import Select, { components } from "react-select";
import "../../pages/Dashboard/OperationsDashboard/Styles/dashboard.css";
import { Grid, Button, Input } from "@material-ui/core";
import refreshIcon from "../../pages/Dashboard/assets/reset icon.jpg";
import { InputGroup } from "rsuite";
import Datepicker from "../../components/Common/DatePicker/DatePicker";
import { tripTypeEnum, warehouseValueEnum } from "../../components/Common/Status";

var darkMode;
var dashInvoiceBorder;
var dashTransBorder;
var dashBorder;
var invoiceBgClr;
var invoiceClr;
var transpoterBgClr;
var transpoterClr;
var OperationsBgClr;
var operationsClr;

const DropdownIndicator = (props) => {
  const iconSvg = props.selectProps.selectProps;
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {iconSvg}
      </components.DropdownIndicator>
    )
  );
};


class OptimizationHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: null,
      toDate: null,
      selectValue: '',
      sortTripTypeVal: tripTypeEnum[0],
      warehouse: [],
      filterValue: {},
    };
  }
  onChangeDatePicker = (value, label) => {
    // console.log(value, label);
    // debugger
    if (label === "fromDate") {
      this.setState({ fromDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.handleChange([this.state.fromDate, this.state.toDate]);
        }
      });
    }
    if (label === "toDate") {
      this.setState({ toDate: value }, () => {
        if (this.state.fromDate !== null && this.state.toDate !== null) {
          this.props.handleChange([this.state.fromDate, this.state.toDate]);
        }
      });
    }
  };

  handleWareHouseValue = (sortData) => {
    console.log('sortData', sortData);
    this.setState({
      warehouse: { label: sortData?.label, value: sortData?.value },
    },
      (() => {
        this.props.onSelectValue('businessUnit', sortData?.value)
      }));

  };

  render() {
    let mode = localStorage.getItem("Theme")
      ? localStorage.getItem("Theme")
      : null;
    darkMode = mode === "dark__mode" ? true : false;
    const customStyles1 = {
      option: (styles, state) => ({
        ...styles,
        cursor: "pointer",
      }),
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      menu: ({ width, ...css }) => ({
        ...css,
        cursor: "pointer",
        width: "100%",
        backgroundColor: darkMode ? "#171722" : "#ffffff",
        border: darkMode
          ? "0.5px solid #616161"
          : "0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode
          ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
          : "0px 3px 12px rgba(0, 0, 0, 0.12)",
        // minWidth: "100%",
      }),
      control: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "28px",
          width:
            this.props.selectedTranspoterValues?.length >= 2 ? "100%" : "100%",
          minWidth: "100%",
          minHeight: "28px",
          flexWrap: "nowrap",
          backgroundColor: this.props?.opCardStyle ? "#2E2E46" : "",
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          cursor: "pointer",
          marginTop: "0px",
          height: "26px",
          minHeight: "28px",
          fontSize: "13px",
          fontFamily: "Roboto",
          fontWeight: 400,
          fontStyle: "normal",
          paddingLeft: "10px",
          paddingTop: "5px",
          color: this.props?.opCardStyle ? "#9D9D9D" : "#0C0C0C",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          cursor: "pointer",
          // backgroundColor: "#8e9af8",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "8px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "29px",
          minHeight: "29px",
          // backgroundColor: "#0066cc",
        };
      },
    };
    const customStyles2 = {
      option: (styles, state) => ({
        ...styles,
        cursor: "pointer",
      }),
      input: (base) => ({
        ...base,
        color: darkMode ? "#9d9d9d" : "#505d69",
      }),
      menu: ({ width, ...css }) => ({
        ...css,
        zIndex: 9999,
        width: "100%",
        minWidth: "100%",
        cursor: "pointer ",
        backgroundColor: darkMode ? "#171722" : "#ffffff",
        border: darkMode
          ? "0.5px solid #616161"
          : "0.5px solid rgb(204, 204, 204)",
        boxShadow: darkMode
          ? "0px 3px 12px rgba(0, 0, 0, 0.12)"
          : "0px 3px 12px rgba(0, 0, 0, 0.12)",
        bordeRadius: darkMode ? "4px" : "4px",
      }),
      control: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "28px",
          width: this.props.selectedHopValues?.length >= 2 ? "100%" : "100%",
          minWidth: "100%",
          minHeight: "28px",
          flexWrap: "nowrap",
          backgroundColor: this.props?.opCardStyle ? "#2E2E46" : "",
        };
      },
      placeholder: (style) => {
        return {
          ...style,
          cursor: "pointer",
          marginTop: "2px",
          height: "26px",
          minHeight: "28px",
          fontSize: "13px",
          fontFamily: "Roboto",
          fontWeight: 400,
          fontStyle: "normal",
          paddingLeft: "10px",
          paddingTop: "5px",
          color: this.props?.opCardStyle ? "#9D9D9D" : "#0C0C0C",
        };
      },
      indicatorsContainer: (style) => {
        return {
          ...style,
          cursor: "pointer",
          // backgroundColor: "#8e9af8",
          height: "26px",
          minHeight: "26px",
        };
      },
      indicatorSeparator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "10px",
          minHeight: "10px",
          backgroundColor: "#cccccc",
          marginTop: "8px",
        };
      },
      dropdownIndicator: (style) => {
        return {
          ...style,
          cursor: "pointer",
          height: "26px",
          minHeight: "26px",
          // backgroundColor: "#0066cc",
        };
      },
    };
    if (this.props.location?.pathname === "/operationalDashboard") {
      OperationsBgClr = "#0AB38C";
      operationsClr = "#FFFFFF";
      dashBorder = " ";
    } else {
      OperationsBgClr = `${darkMode ? "#171722" : "#F1F5F7"}`;
      operationsClr = `${darkMode ? "#ffffff" : "#05284E"}`;
      dashBorder = `${darkMode ? "0.5px solid #616161" : " "}`;
    }

    if (this.props.location?.pathname === "/invoice-dashboard") {
      invoiceBgClr = "#0AB38C";
      invoiceClr = "#FFFFFF";
      dashInvoiceBorder = " ";
    } else {
      invoiceBgClr = `${darkMode ? "#171722" : "#F1F5F7"}`;
      invoiceClr = `${darkMode ? "#ffffff" : "#05284E"}`;
      dashInvoiceBorder = `${darkMode ? "0.5px solid #616161" : " "}`;
    }
    if (this.props.location?.pathname === "/transporterDashboard") {
      transpoterBgClr = "#0AB38C";
      transpoterClr = "#FFFFFF";
      dashTransBorder = " ";
    } else {
      transpoterBgClr = `${darkMode ? "#171722" : "#F1F5F7"}`;
      transpoterClr = `${darkMode ? "#ffffff" : "#05284E"}`;
      dashTransBorder = `${darkMode ? "0.5px solid #616161" : " "}`;
    }
    return (
      <>
        <div className="dashboard-header-div darkBgQuickFilter">
          <Card
            className=""
            // fluid
            style={{
              backgroundColor: `${darkMode ? "#222238" : "#FFFFFF"}`,
              height: "auto",
              width: "100%",
            }}
          >
            <Grid
              className="grid-align pt-3 pb-3 pl-1 pr-1 ml-2"
              container
              spacing={2}
              // justifyContent="center"
              alignItems="center"
              style={{
                paddingBottom: "25px !important",
              }}
            >
              <Grid item xs={10} sm={10} md={1} lg={1} className="">
              </Grid>
              <Grid item xs={10} sm={10} md={1} lg={1}>
              </Grid>
              <Grid item xs={10} sm={10} md={1} lg={1}>
              </Grid>
              <Grid item xs={10} sm={10} md={4} lg={5}>
                <Row>
                  <Col></Col>

                  <Col>
                    <div className="RenderInput baxterCapitalize">
                      <Select
                        className="consent-react_selector pr-2"
                        classNamePrefix="react-select"
                        placeholder="Select Warehouse"
                        isMulti={false}
                        // autoFocus={true}
                        styles={customStyles1}
                        onChange={this.handleWareHouseValue}
                        options={warehouseValueEnum}
                        value={this.state.warehouse}
                      // defaultValue={this.state.warehouse}
                      />
                    </div>
                  </Col>

                </Row>
              </Grid>


              <Grid
                item
                xs={10}
                sm={10}
                md={4}
                lg={4}
                className="datePickerGrid"
              >
                <div className="d-flex flex-row" style={{ gap: "30px" }} >
                  <div
                    className="date-picker-div RsuiteDateRange OrderDateRange"
                    style={{
                      width: "61%",
                      marginLeft: "56px",
                    }}
                  >
                    <div className="datepicker-selector orderDateContainer">
                      <InputGroup>
                        <Datepicker
                          mode={darkMode}
                          value={this.state.fromDate}
                          onChangeDatePicker={this.onChangeDatePicker}
                          fromDate={true}
                          toDate={false}
                          myPlaceholder={"Start Date"}
                          dashboard={false}
                        />
                      </InputGroup>
                      <InputGroup>
                        <Datepicker
                          mode={darkMode}
                          value={this.state.toDate}
                          onChangeDatePicker={this.onChangeDatePicker}
                          fromDate={false}
                          toDate={true}
                          myPlaceholder={"End Date"}
                          dashboard={false}
                          className="orderDateIcon"
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div>
                    <Button
                      className="operations-dashboard-btn-reset ml-2"
                      variant="outlined"
                      style={{
                        height: "28px",
                        width: "100%",
                      }}
                      onClick={() => {
                        this.setState(
                          {
                            fromDate: null,
                            toDate: null,
                            warehouse: []
                          },
                          () => {
                            this.props.onReset();
                          }
                        );
                      }}
                    >
                      {/* {this.props?.userLang?.common?.RESET} */}
                      Reset
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div>
              <Grid
                className="grid-align-mobile pt-3 pb-3 pl-1 pr-1"
                container
                spacing={2}
                // justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <div className="d-flex  flex-wrap justify-content-between align-items-center">
                    <ul className="d-flex flex-row mobile-dashboard-header-buttons">


                    </ul>
                    <div>
                      <img
                        onClick={() => {
                          this.setState(
                            {
                              fromDate: null,
                              toDate: null,
                            },
                            () => {
                              this.props.onReset();
                            }
                          );
                        }}
                        style={{
                          width: "36px",
                          height: "36px",
                          cursor: "pointer",
                        }}
                        src={refreshIcon}
                        alt="refreshicon"
                      />
                    </div>
                  </div>
                </Grid>

                {this.props.location?.pathname === "/operationalDashboard" ? (
                  <Grid item xs={12} className="ptd16">
                  </Grid>
                ) : (
                  ""
                )}

                <Grid item xs={12} className="ptd16">
                  <Row>
                    <Col></Col>
                    <Col>
                      <div className="RenderInput baxterCapitalize">
                        <Select
                          className="consent-react_selector pr-2"
                          classNamePrefix="react-select"
                          placeholder="Select Warehouse"
                          isMulti={false}
                          autoFocus={true}
                          styles={customStyles1}
                          onChange={this.handleWareHouseValue}
                          options={warehouseValueEnum}
                          value={this.state.warehouse}
                          defaultValue={this.state.warehouse}
                        />
                      </div>
                    </Col>
                    {/* <Col>
                    {
                      <Select
                        className="consent-react_selector pr-2"
                        classNamePrefix="react-select"
                        placeholder="Select Trip Type"
                        isMulti={false}
                        autoFocus={true}
                        styles={customStyles1}
                        onChange={this.handleSortOrder}
                        options={tripTypeEnum}
                        value={this.state.sortTripTypeVal}
                        defaultValue={this.state.sortTripTypeVal}
                      />
                    }
                  </Col> */}
                  </Row>
                </Grid>
                <Grid item xs={12} className="ptd16">
                  <div
                    className="date-picker-div RsuiteDateRange OrderDateRange"
                    style={{
                      width: "70%",
                      marginLeft: "90px",
                    }}
                  >
                    <div className="datepicker-selector orderDateContainer">
                      <InputGroup style={{ zIndex: 0 }}>
                        <Datepicker
                          mode={darkMode}
                          value={this.state.fromDate}
                          onChangeDatePicker={this.onChangeDatePicker}
                          fromDate={true}
                          toDate={false}
                          myPlaceholder={"Start Date"}
                          dashboard={false}
                        />
                      </InputGroup>
                      <InputGroup style={{ zIndex: 0 }}>
                        <Datepicker
                          mode={darkMode}
                          value={this.state.toDate}
                          onChangeDatePicker={this.onChangeDatePicker}
                          fromDate={false}
                          toDate={true}
                          myPlaceholder={"End Date"}
                          dashboard={false}
                          className="orderDateIcon"
                        />
                      </InputGroup>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Card>
        </div>
      </>
    );
  }
}
export default OptimizationHeader;
