let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
export const getLocalStorage = () => {
  userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
  AdminHeaderData = {
    columns: [
      {
        label: userLang?.masters?.NAME,
        field: "fullName",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.masters?.PHONE,
        field: "phone",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.masters?.LIC_NO,
        field: "driverLicenseNumber",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.common?.TRANSPORTER,
        field: "transporter",
        sort: "asc",
        width: 2,
      },
      {
        label: userLang?.masters?.CONSENT_STATUS,
        field: "consentProvided",
        sort: "asc",
        width: 1,
      },
      {
        label: userLang?.common?.ACTIONS,
        field: "consentUpdate",
        sort: "asc",
        width: 1,
      },
    ],
  };
};
if (localStorage.getItem("userLangDetail") === null) {
  getLocalStorage();
}
export var AdminHeaderData = {
  columns: [
    {
      label: userLang?.masters?.NAME,
      field: "fullName",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.masters?.PHONE,
      field: "phone",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.masters?.LIC_NO,
      field: "driverLicenseNumber",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.common?.TRANSPORTER,
      field: "transporter",
      sort: "asc",
      width: 2,
    },
    {
      label: userLang?.masters?.CONSENT_STATUS,
      field: "consentProvided",
      sort: "asc",
      width: 1,
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "consentUpdate",
      sort: "asc",
      width: 1,
    },
  ],
};
export const searchBar = {
  searchBarData: [
    {
      label: userLang?.masters?.NAME,
      field: "fullName",
      type: "text",
    },
    {
      label: userLang?.masters?.PHONE,
      field: "phone",
      type: "text",
    },
    {
      label: userLang?.masters?.LIC_NO,
      field: "driverLicenseNumber",
      type: "text",
    },
    {
      label: userLang?.masters?.TRANSPORTER,
      field: "transporter",
     type:"text"
    },
    {
      label: userLang?.masters?.CONSENT_STATUS,
      field: "consentProvided",
      type: "text",
    },
    {
      label: userLang?.common?.ACTIONS,
      field: "consentUpdate",
      type: "text",
    },
  ],
};
