import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import { memo } from "react";
import {
  CloseIcon,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
} from "../Common/CommonIcons";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
// import InvoiceMenu from "./InvoiceMenu";
import "./style.css";
import AtFoodPdf from "./Manual.pdf";
// import MobileMenuBar from "./MobileMenuBar";
// const logoImage = require("../../assets/images/navigation/logo.png");
const isBaxter = process.env.REACT_APP_CLIENT === "3_BAXTER";
const logoImage = process.env.REACT_APP_DASHBOARD_LOGO_IMAGE
  ? require("../../assets/images/" + process.env.REACT_APP_DASHBOARD_LOGO_IMAGE)
  : "";
const dashboardImage = require("../../assets/images/navigation/DASHBOARD.png");
// const logoMiniImage = require("../../assets/images/Spinclabs-Thumbnails.png");
const logoMiniImage = process.env.REACT_APP_DASHBOARD_LOGO_MINI_IMAGE
  ? isBaxter
    ? require("../../assets/images/" +
      process.env.REACT_APP_DASHBOARD_LOGO_IMAGE)
    : require("../../assets/images/" +
      process.env.REACT_APP_DASHBOARD_LOGO_MINI_IMAGE)
  : "";
const routeOptimization = process.env.REACT_APP_ROUTE_OPTIMIZATION;
const loadOptimization = process.env.REACT_APP_LOAD_OPTIMIZATION;
const planningImage = require("../../assets/images/navigation/PLANNING.png");
const tripsImage = require("../../assets/images/navigation/TRIPS.png");
const mastersImage = require("../../assets/images/navigation/MASTERS.png");
const billingImage = require("../../assets/images/navigation/BILLING.png");
const reportsImage = require("../../assets/images/navigation/REPORTS.png");
const ControlTowerImage = require("../../assets/images/navigation/control tower icon.svg");
//const dashboardImage = require("../../assets/images/navigation/DASHBOARD.png");
//const dashboardImage = require("../../assets/images/navigation/DASHBOARD.png");
let MAIN_DASHBOARD = "MAIN_DASHBOARD";
let MAIN_INDENT_PLANNING = "MAIN_INDENT_PLANNING";
let MAIN_TRIPS = "MAIN_TRIPS";
let MAIN_MASTERS = "MAIN_MASTERS";
let MAIN_INVOICE = "MAIN_INVOICE";
let MAIN_REPORTS = "MAIN_REPORTS";
let MAIN_CONTROL_TOWER = "MAIN_CONTROL_TOWER";
let MAIN_ORDERS = "MAIN_ORDERS";
// let HELP = "HELP";
// let DASHBOARD = "DASHBOARD";
// let INDENT_PLANNINGS = "INDENT_PLANNINGS";
// let ADD_INDENT = "ADD_INDENT";
// let TRIPS = "TRIPS";
let DRIVERS = "DRIVERS";
// let VEHICLES = "VEHICLES";
// let USERS = "USERS";
// let ROLES = "ROLES";
// let AGGREMENTS = "AGGREMENTS";
// let BILLS = "BILLS";
// let TRANSPORTER_PERFORMANCE = "TRANSPORTER_PERFORMANCE";

var usrInfo;
var darkMode;
let userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));

export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainLink: "",
      subLink: {},
      userInfo: {},
      userLangs: undefined,
      zoomVal: "",
      closeIcon: false,
    };
  }

  componentDidMount() {
    this.resize();
  }

  removeFilterItemFromLocalStorageTrip = () => {
    localStorage.removeItem("filters");
    localStorage.removeItem("offset");
  };
  removeFilterItemFromLocalStorageIndent = () => {
    localStorage.removeItem("IndentFilters");
    localStorage.removeItem("offset");
  };
  removeFilterItemExceptCurrentRoute = () => {
    //need to optimize code of filter(pending)
    const pathName = window.location.pathname;
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      // Remove keys starting with "myStatusFilter_" but not matching the current path
      if (key.startsWith("myStatusFilter_") && !key.endsWith(pathName)) {
        localStorage.removeItem(key);
      }
      if (key.startsWith("myOffsetFilter_")) {
        localStorage.removeItem(key);
      }

      if (key.startsWith("mySearchFilter_") && !key.endsWith(pathName)) {
        localStorage.removeItem(key);
      }

      // Remove "tripsFilterData" and "tripOffset" keys if the current path is not "/tripList"
      if (
        (key === "tripsFilterData" || key === "tripOffset") &&
        pathName !== "/tripList"
      ) {
        localStorage.removeItem("tripsFilterData");
        localStorage.removeItem("tripOffset");
      }

      // Remove "myFilterData" and "offset" keys if the current path is not "/orderList"
      if (
        (key === "myFilterData" || key === "tripOffset") &&
        pathName !== "/orderList"
      ) {
        localStorage.removeItem("myFilterData");
        localStorage.removeItem("offset");
      }
    }
  };

  removeFromLocalStorage = (key) => {
    window.localStorage.removeItem(key);
  };

  resize = () => {
    if (window.innerWidth <= 1500)
      this.setState({ zoomVal: 0.8 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    // else if( window.innerWidth >= 1500 && window.innerWidth <= 1600  ){
    //   this.setState({ zoomVal: 0.84 }, () => {
    //     document.body.style.zoom = this.state.zoomVal;
    //   });
    // }
    else {
      this.setState({ zoomVal: 0.9 }, () => {
        document.body.style.zoom = this.state.zoomVal;
      });
    }
  };

  componentDidUpdate = () => {
    if (
      this.state.userLangs === undefined &&
      (this.state.userLangs !== userLang || userLang === null)
    ) {
      this.setState({
        userLangs: JSON.parse(window.localStorage.getItem("userLangDetail")),
      });
    }
  };
  onResumePdfClick = () => {
    window.open(AtFoodPdf);
  };

  componentWillUnmount(prevProps, prevState) {
    // console.log(prevState, "component willunmount");
  }

  handleActiveLink = (mainLinkval) => {
    // console.log(mainLinkval);

    this.props.changeActiveTheme(mainLinkval);
    this.setState(
      {
        mainLink: mainLinkval,
        show: !this.state.show,
      },
      () => {
        // console.log(this.state.mainLink);
      }
    );
  };

  handleActiveSubLink = (subLink) => {
    this.setState(
      {
        subLink: subLink,
      },
      () => {
        // console.log(this.state.subLink);
      }
    );
  };

  render() {
    let authUser = JSON.parse(localStorage.getItem("authUser"));
    let userType = authUser?.data?.role?.label;
    userLang = JSON.parse(window.localStorage.getItem("userLangDetail"));
    let initStorage = localStorage.getItem("Theme");
    if (initStorage === "dark__mode") {
      darkMode = true;
    } else if (initStorage === "light__mode") {
      darkMode = false;
    } else {
      darkMode = false;
    }
    const isBaxter = process.env.REACT_APP_CLIENT === "3_BAXTER";
    const logoHeight = isBaxter ? "200px" : "93px";
    const logoTop = isBaxter ? "-1%" : "5%";
    let userInfo = authUser;
    const keyboardArrowIconStyle = {
      position: "absolute",
      fontSize: 20,
      marginLeft: "10px",
      color: "#FFFFFF",
      right: "14%",
      marginTop: "3%",
    };

    return (
      <>
        {/* {this.props.show ? (
          <MobileMenuBar
            closeMenuBar={this.props?.closeMenuBar}
            closeIcon={this.props?.show}
            // activeState={this.props.activeState}
            handleActiveLink={this.handleActiveLink}
            handleActiveSubLink={this.handleActiveSubLink}
            userInfo={this.state.userInfo}
            show={this.state.show}
            sublink={this.state.a}
            mainLink={this.state.mainLink}
            userLangs={this.state.userLangs}
            onResumePdfClick={this.onResumePdfClick}
          />
        ) : (
          ""
        )} */}
        {!this.props.mini ? (
          <div
            id={this.props.show ? "my-sidebar-mobile" : "mySidebar"}
            className={`mainExpandedStyle ${darkMode ? "verticalMenuDark" : ""
              }`}
            onMouseEnter={this.props.menuBarIn}
            onMouseLeave={this.props.menuBarOut}
          >
            <div>
              <div>
                {this.props.show ? (
                  <span className="closeIconStyles">
                    <i
                      onClick={() =>
                        this.props?.closeMenuBar(this.state.closeIcon)
                      }
                    >
                      <CloseIcon color="white" />
                    </i>
                  </span>
                ) : (
                  ""
                )}
                <img
                  className="mainLogoStyleMobile"
                  src={logoImage}
                  alt="logo"
                  style={{
                    height: logoHeight,
                    top: logoTop,
                  }}
                />
              </div>
              {/* <img className="mainLogoStyle" src={logoImage} alt="logo" /> */}
            </div>
            <div className="logoSepartorStyleMobile"></div>
            <div className="linksSection">
              {/* DASHBOARD */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label !== "transporter" ||
                  userInfo?.data?.userType === "internal" ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${this.props.activeState === MAIN_DASHBOARD &&
                        (this.props.location.pathname ===
                          "/operationsDashboard" ||
                          this.props.location.pathname ===
                          "/transporterDashboard" ||
                          this.props.location.pathname === "/invoice-dashboard")
                        ? "activeLinkSection"
                        : null
                        }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_DASHBOARD);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFromLocalStorage("invoiceListFilters");
                        this.removeFromLocalStorage("filters");
                        this.removeFromLocalStorage("IndentFilters");
                      }}
                    >
                      <Link to="/operationsDashboard">
                        <div>
                          <img src={dashboardImage} alt="dashImg" />
                          <span className="mainLinkName">
                            {this.state.userLangs?.dashboard?.DASHBOARD}
                          </span>
                          {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                        </div>
                      </Link>
                    </div>
                    <div
                      className={`subLinks ${this.state.a === 1 ? "displaySubMenu" : "hideSubMenu"
                        }`}
                    ></div>
                  </div>
                ) : (
                  ""
                ))}
              {/* BAXTER DASHBOARD */}
              {process.env.REACT_APP_CLIENT === "3_BAXTER" &&
                ["admin", "supervisor"].includes(userType) &&
                (userInfo ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${this.props.activeState === MAIN_DASHBOARD &&
                        this.props.location.pathname === "/operationalDashboard"
                        ? "activeLinkSection"
                        : null
                        }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_DASHBOARD);
                        this.removeFilterItemFromLocalStorageTrip();
                        this.removeFilterItemExceptCurrentRoute();
                      }}
                    >
                      <Link to="/operationalDashboard">
                        <div>
                          <img src={dashboardImage} alt="dashImg" />
                          <span className="mainLinkName">
                            {this.state.userLangs?.dashboard?.DASHBOARD}
                          </span>
                          {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                        </div>
                      </Link>
                    </div>
                    <div
                      className={`subLinks ${this.state.a === 1 ? "displaySubMenu" : "hideSubMenu"
                        }`}
                      onClick={() => {
                        this.removeFilterItemExceptCurrentRoute();
                      }}
                    ></div>
                  </div>
                ) : (
                  ""
                ))}

              {/* INDENT PLANNING */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label !== "transporter" ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${this.props.activeState === MAIN_INDENT_PLANNING
                        ? "activeLinkSection"
                        : null
                        }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_INDENT_PLANNING);
                        this.removeFilterItemFromLocalStorageTrip();
                        this.removeFromLocalStorage("invoiceListFilters");
                        this.removeFromLocalStorage("filters");
                        this.removeFromLocalStorage("IndentFilters");
                        this.removeFromLocalStorage("invoiceGenerationFiliter");
                      }}
                    >
                      <div>
                        <img src={planningImage} alt="" />
                        <span className="mainLinkName">
                          {this.state.userLangs?.planning?.PLANNING_CAPS}
                        </span>
                        {this.props.activeState === MAIN_INDENT_PLANNING &&
                          this.state.show === true ? (
                          // <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                          <i style={keyboardArrowIconStyle}>
                            <KeyboardArrowUpIcon />
                          </i>
                        ) : (
                          // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                          <i style={keyboardArrowIconStyle}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                      </div>
                    </div>
                    <div
                      className={`subMenu ${this.props.activeState === MAIN_INDENT_PLANNING &&
                        this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                        }`}
                    // onClick={() => this.setState({mainLink:MAIN_INDENT_PLANNING})}
                    >
                      <Link to="/indent-planning">
                        <div className="subMenuLinkDiv">
                          <span className="subMenuLinkName">
                            {this.state.userLangs?.planning?.INDENT_PLANNING}
                          </span>
                        </div>
                      </Link>
                      <Link to="/add-indent">
                        <div className="subMenuLinkDiv">
                          <span className="subMenuLinkName">
                            {this.state.userLangs?.planning?.ADD_INDENT}
                          </span>
                        </div>
                      </Link>
                      {loadOptimization === "true" ? (
                        <Link to="/load-optimization">
                          <div className="subMenuLinkDiv">
                            <span className="subMenuLinkName">
                              Load Optimization
                            </span>
                          </div>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {routeOptimization === "true" ? (
                        <Link to="/Route-Optimization">
                          <div className="subMenuLinkDiv">
                            <span className="subMenuLinkName">
                              {"Route Optimization"}
                              {/* {this.state.userLangs?.planning?.ADD_INDENT} */}
                            </span>
                          </div>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {routeOptimization === "true" ? (
                        <Link to="/Route-Optimization">
                          <div className="subMenuLinkDiv">
                            <span className="subMenuLinkName">
                              {"Co-Ordinates"}
                              {/* {this.state.userLangs?.planning?.ADD_INDENT} */}
                            </span>
                          </div>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${this.props.activeState === MAIN_INDENT_PLANNING
                        ? "activeLinkSection"
                        : null
                        }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_INDENT_PLANNING);
                        this.removeFilterItemFromLocalStorageTrip();
                        this.removeFromLocalStorage("invoiceGenerationFiliter");
                      }}
                    >
                      <div>
                        <img src={planningImage} alt="" />
                        <span className="mainLinkName">
                          {" "}
                          {this.state.userLangs?.planning?.PLANNING}
                        </span>
                        {this.props.activeState === MAIN_INDENT_PLANNING &&
                          this.state.show === true ? (
                          // <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                          <i style={keyboardArrowIconStyle}>
                            <KeyboardArrowUpIcon />
                          </i>
                        ) : (
                          // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                          <i style={keyboardArrowIconStyle}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                      </div>
                    </div>
                    <div
                      className={`subMenu ${this.props.activeState === MAIN_INDENT_PLANNING &&
                        this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                        }`}
                    // onClick={() => this.setState({mainLink:MAIN_INDENT_PLANNING})}
                    >
                      <Link to="/assigned-indent">
                        <div className="subMenuLinkDiv">
                          <span className="subMenuLinkName">
                            {" "}
                            {this.state.userLangs?.planning?.INDENT_PLANNING}
                          </span>
                        </div>
                      </Link>
                      {routeOptimization === "true" ? (
                        <Link to="/Route-Optimization">
                          <div className="subMenuLinkDiv">
                            <span className="subMenuLinkName">
                              {"Route-Optimization "}
                              {/* {this.state.userLangs?.planning?.INDENT_PLANNING} */}
                            </span>
                          </div>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ))}

              {process.env.REACT_APP_CLIENT === "3_BAXTER" && (
                <>
                  {/* ORDERS */}
                  <div className="linkSection">
                    <div
                      className={`mainLink ${this.props.activeState === MAIN_ORDERS
                        ? "activeLinkSection"
                        : null
                        }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_ORDERS);
                        this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <div>
                        <svg
                          width="29"
                          height="28"
                          viewBox="0 0 29 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.00201 2.33333L3.50201 7V23.3333C3.50201 23.9522 3.74785 24.5457 4.18543 24.9832C4.62302 25.4208 5.21651 25.6667 5.83535 25.6667H22.1687C22.7875 25.6667 23.381 25.4208 23.8186 24.9832C24.2562 24.5457 24.502 23.9522 24.502 23.3333V7L21.002 2.33333H7.00201Z"
                            stroke="white"
                            strokeWidth="2.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3.50201 7H24.502"
                            stroke="white"
                            strokeWidth="2.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.6687 11.6667C18.6687 12.9043 18.177 14.0913 17.3018 14.9665C16.4267 15.8417 15.2397 16.3333 14.002 16.3333C12.7643 16.3333 11.5773 15.8417 10.7022 14.9665C9.82699 14.0913 9.33533 12.9043 9.33533 11.6667"
                            stroke="white"
                            strokeWidth="2.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span className="mainLinkName">
                          {" "}
                          {this.state.userLangs?.orders?.ORDERS ?? "Orders"}
                        </span>
                        {this.props.activeState === MAIN_ORDERS &&
                          this.state.show === true ? (
                          // <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                          <i style={keyboardArrowIconStyle}>
                            <KeyboardArrowUpIcon />
                          </i>
                        ) : (
                          // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                          <i style={keyboardArrowIconStyle}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                      </div>
                    </div>
                    <div
                      className={`subMenu ${this.props.activeState === MAIN_ORDERS &&
                        this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                        }`}
                    >
                      <Link to="/ordersList">
                        <div
                          className="subMenuLinkDiv"
                          onClick={() => {
                            this.removeFilterItemExceptCurrentRoute();
                          }}
                        >
                          <span className="subMenuLinkName">
                            {" "}
                            {this.state.userLangs?.orders?.ORDERS ?? "Orders"}
                          </span>
                        </div>
                      </Link>
                      {userType !== "customer" && userType !== "sales" ? (
                        <>
                          <Link to="/InvoiceList">
                            <div
                              className="subMenuLinkDiv"
                              onClick={() => {
                                this.removeFilterItemExceptCurrentRoute();
                              }}
                            >
                              <span className="subMenuLinkName">
                                {this.state.userLangs?.invoice?.INVOICE_LIST}
                              </span>
                            </div>
                          </Link>
                          <Link to="/order/products">
                            <div
                              className="subMenuLinkDiv"
                              onClick={() => {
                                this.removeFilterItemExceptCurrentRoute();
                              }}
                            >
                              <span className="subMenuLinkName">
                                {this.state.userLangs?.orders?.PRODUCT ??
                                  "Product"}
                              </span>
                            </div>
                          </Link>
                          <Link to="/optimization">
                            <div
                              className="subMenuLinkDiv"
                              onClick={() => {
                                this.removeFilterItemExceptCurrentRoute();
                              }}
                            >
                              <span className="subMenuLinkName">
                                {" "}
                                {this.state.userLangs?.masters?.OPTIMIZATION ??
                                  "Optimization"}
                              </span>
                            </div>
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* TRIPS */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" && (
                <div className="linkSection">
                  <div
                    className={`mainLink ${this.props.activeState === MAIN_TRIPS
                      ? "activeLinkSection"
                      : null
                      }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_TRIPS);
                      this.removeFilterItemFromLocalStorageIndent();
                      this.removeFromLocalStorage("invoiceListFilters");
                      this.removeFromLocalStorage("filters");
                      this.removeFromLocalStorage("IndentFilters");
                      this.removeFromLocalStorage("invoiceGenerationFiliter");

                    }}
                  >
                    <div>
                      <img src={tripsImage} alt="" />
                      <span className="mainLinkName">
                        {" "}
                        {this.state.userLangs?.trip?.TRIPS}
                      </span>
                      {this.props.activeState === MAIN_TRIPS &&
                        this.state.show === true ? (
                        // <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                        <i style={keyboardArrowIconStyle}>
                          <KeyboardArrowUpIcon />
                        </i>
                      ) : (
                        // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                        <i style={keyboardArrowIconStyle}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                    </div>
                  </div>
                  <div
                    className={`subMenu ${this.props.activeState === MAIN_TRIPS &&
                      this.state.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                      }`}
                  >
                    <Link to="/trips">
                      <div className="subMenuLinkDiv">
                        <span className="subMenuLinkName">
                          {" "}
                          {this.state.userLangs?.trip?.VIEW_ALL_TRIPS}
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              )}
              {/*BAXTER TRIPS */}
              {process.env.REACT_APP_CLIENT === "3_BAXTER" &&
                userType !== "sales" &&
                userType !== "customer" && (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${this.props.activeState === MAIN_TRIPS
                        ? "activeLinkSection"
                        : null
                        }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_TRIPS);
                        this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <div>
                        <img src={tripsImage} alt="" />
                        <span className="mainLinkName">
                          {" "}
                          {this.state.userLangs?.trip?.TRIPS}
                        </span>
                        {this.props.activeState === MAIN_TRIPS &&
                          this.state.show === true ? (
                          // <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                          <i style={keyboardArrowIconStyle}>
                            <KeyboardArrowUpIcon />
                          </i>
                        ) : (
                          // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                          <i style={keyboardArrowIconStyle}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                      </div>
                    </div>
                    <div
                      className={`subMenu ${this.props.activeState === MAIN_TRIPS &&
                        this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                        }`}
                    >
                      <Link to="/tripList">
                        <div
                          className="subMenuLinkDiv"
                          onClick={() => {
                            this.removeFilterItemExceptCurrentRoute();
                          }}
                        >
                          <span className="subMenuLinkName">
                            {" "}
                            {this.state.userLangs?.trip?.VIEW_ALL_TRIPS}
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
              {/* MASTERS */}
              {userType !== "customer" && userType !== "sales" && (
                <div className="linkSection">
                  <div
                    className={`mainLink ${this.props.activeState === MAIN_MASTERS
                      ? "activeLinkSection"
                      : null
                      }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_MASTERS);
                      this.removeFilterItemFromLocalStorageIndent();
                      this.removeFilterItemFromLocalStorageTrip();
                      this.removeFilterItemExceptCurrentRoute();
                      this.removeFromLocalStorage("invoiceListFilters");
                      this.removeFromLocalStorage("filters");
                      this.removeFromLocalStorage("IndentFilters");
                      this.removeFromLocalStorage("invoiceGenerationFiliter");

                    }}
                  >
                    <div>
                      <img src={mastersImage} alt="" />
                      <span className="mainLinkName">
                        {this.state.userLangs?.masters?.MASTERS}
                      </span>
                      {this.props.activeState === MAIN_MASTERS &&
                        this.state.show === true ? (
                        // <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                        <i style={keyboardArrowIconStyle}>
                          <KeyboardArrowUpIcon />
                        </i>
                      ) : (
                        // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                        <i style={keyboardArrowIconStyle}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                    </div>
                  </div>
                  <div
                    className={`subMenu ${this.props.activeState === MAIN_MASTERS &&
                      this.state.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                      }`}
                  >
                    {userInfo?.data?.role?.label !== "indent_planner" ? (
                      <Link to="/drivers">
                        <div
                          className="subMenuLinkDiv"
                          onClick={() => this.handleActiveSubLink(DRIVERS)}
                        >
                          <span className="subMenuLinkName">
                            {" "}
                            {this.state.userLangs?.masters?.DRIVERS}
                          </span>
                        </div>
                      </Link>
                    ) : (
                      ""
                    )}
                    <Link to="/vehicles">
                      <div className="subMenuLinkDiv">
                        <span className="subMenuLinkName">
                          {" "}
                          {this.state.userLangs?.masters?.VEHICLES}
                        </span>
                      </div>
                    </Link>
                    {userInfo?.data?.role?.label === "Admin" ||
                      process.env.REACT_APP_CLIENT == "3_BAXTER" ||
                      userInfo?.data?.role?.label === "loading_unloading" ? (
                      <Link to="/vehicle-type">
                        <div className="subMenuLinkDiv">
                          <span className="subMenuLinkName">
                            {this.state.userLangs?.masters?.VEHICLE_TYPE}
                          </span>
                        </div>
                      </Link>
                    ) : (
                      ""
                    )}
                    {userInfo?.data?.role?.label !== "transporter" ? (
                      <Link to="/user">
                        <div className="subMenuLinkDiv">
                          <span className="subMenuLinkName">
                            {" "}
                            {this.state.userLangs?.masters?.USERS}
                          </span>
                        </div>
                      </Link>
                    ) : (
                      ""
                    )}
                    {userInfo?.data?.role?.label !== "transporter" ? (
                      <Link to="/roles">
                        <div className="subMenuLinkDiv">
                          <span className="subMenuLinkName">
                            {" "}
                            {this.state.userLangs?.masters?.ROLES}
                          </span>
                        </div>
                      </Link>
                    ) : (
                      ""
                    )}
                    {process.env.REACT_APP_CLIENT != "3_BAXTER" ? (
                      <>
                        <Link to="/hops">
                          <div className="subMenuLinkDiv">
                            <span className="subMenuLinkName">
                              {this.state.userLangs?.masters?.HOPS ?? "Hops"}
                            </span>
                          </div>
                        </Link>
                        <Link to="/freight">
                          <div className="subMenuLinkDiv">
                            <span className="subMenuLinkName">
                              {this.state.userLangs?.masters?.FREIGHT ??
                                "Freight"}
                            </span>
                          </div>
                        </Link>
                      </>
                    ) : (
                      ""
                    )}
                    {process.env.REACT_APP_CLIENT == "3_BAXTER" ? (
                      <>
                        <Link to="/customers">
                          <div className="subMenuLinkDiv">
                            <span className="subMenuLinkName">
                              {" "}
                              {this.state.userLangs?.masters?.CUSTOMER ??
                                " Customers"}
                            </span>
                          </div>
                        </Link>
                        <Link to="/locations">
                          <div className="subMenuLinkDiv">
                            <span className="subMenuLinkName">
                              {" "}
                              {this.state.userLangs?.masters?.LOCATIONS ??
                                "Locations"}
                            </span>
                          </div>
                        </Link>
                        <Link to="/products">
                          <div className="subMenuLinkDiv">
                            <span className="subMenuLinkName">
                              {" "}
                              {this.state.userLangs?.masters?.PRODUCTS ??
                                "Products"}
                            </span>
                          </div>
                        </Link>
                        <Link to="/zoneToState">
                          <div className="subMenuLinkDiv">
                            <span className="subMenuLinkName">
                              {" "}
                              {/* {this.state.userLangs?.masters?.PRODUCTS ?? "Products"} */}
                              Zone to State Mapping
                            </span>
                          </div>
                        </Link>
                      </>
                    ) : (
                      ""
                    )}
                    {process.env.REACT_APP_CLIENT !== "3_BAXTER" ? (
                      <>
                        <Link to="/routes">
                          <div className="subMenuLinkDiv">
                            <span className="subMenuLinkName">
                              {" "}
                              {this.state.userLangs?.masters?.ROUTES
                                ? this.state.userLangs?.masters?.ROUTES
                                : "Routes"}
                            </span>
                          </div>
                        </Link>
                        <Link to="/indents">
                          <div className="subMenuLinkDiv">
                            <span className="subMenuLinkName">
                              {" "}
                              {this.state.userLangs?.masters?.INDENTS
                                ? this.state.userLangs?.masters?.INDENTS
                                : "Indents"}
                            </span>
                          </div>
                        </Link>
                        <Link to="/shipments">
                          <div className="subMenuLinkDiv">
                            <span className="subMenuLinkName">
                              {" "}
                              {this.state.userLangs?.masters?.SHIPMENTS
                                ? this.state.userLangs?.masters?.SHIPMENTS
                                : "Shipments"}
                            </span>
                          </div>
                        </Link>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
              {/* INVOICE */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label === "transporter" ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${this.props.activeState === MAIN_INVOICE
                        ? "activeLinkSection"
                        : null
                        }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_INVOICE);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFilterItemFromLocalStorageTrip();
                        this.removeFromLocalStorage("invoiceListFilters");
                        this.removeFromLocalStorage("filters");
                        this.removeFromLocalStorage("IndentFilters");
                      }}
                    >
                      <div>
                        <img src={billingImage} alt="" />
                        <span className="mainLinkName">
                          {this.state.userLangs?.dashboard?.INVOICE}
                        </span>
                        {this.props.activeState === MAIN_INVOICE &&
                          this.state.show === true ? (
                          // <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                          <i style={keyboardArrowIconStyle}>
                            <KeyboardArrowUpIcon />
                          </i>
                        ) : (
                          // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                          <i style={keyboardArrowIconStyle}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                      </div>
                    </div>
                    <div
                      className={`subMenu ${this.props.activeState === MAIN_INVOICE &&
                        this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                        }`}
                    >
                      <Link to="/Billing/InvoiceList">
                        <div className="subMenuLinkDiv"
                          onClick={() => {
                            this.removeFromLocalStorage("invoiceGenerationFiliter");
                          }}
                        >
                          <span className="subMenuLinkName">
                            {this.state.userLangs?.invoice?.INVOICE_LIST}
                          </span>
                        </div>
                      </Link>
                      <Link to="/invoiceGeneration">
                        <div className="subMenuLinkDiv">
                          <span className="subMenuLinkName">
                            {this.state.userLangs?.invoice?.INVOICE_GENERATION}
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                ))}

              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label === "Admin" ||
                  userInfo?.data?.role?.label === "billing_manager" ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${this.props.activeState === MAIN_INVOICE
                        ? "activeLinkSection"
                        : null
                        }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_INVOICE);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <div>
                        <img src={billingImage} alt="" />
                        <span className="mainLinkName">
                          {this.state.userLangs?.dashboard?.INVOICE}
                        </span>
                        {this.state.activeState === MAIN_INVOICE &&
                          this.state.show === true ? (
                          // <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                          <i style={keyboardArrowIconStyle}>
                            <KeyboardArrowUpIcon />
                          </i>
                        ) : (
                          // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                          <i style={keyboardArrowIconStyle}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                      </div>
                    </div>
                    <div
                      className={`subMenu ${this.props.activeState === MAIN_INVOICE &&
                        this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                        }`}
                    >
                      <Link to="/BillingIndex">
                        <div className="subMenuLinkDiv">
                          <span className="subMenuLinkName">
                            {this.state.userLangs?.invoice?.AGREEMENT}
                          </span>
                        </div>
                      </Link>
                      <Link to="/Billing/InvoiceList">
                        <div className="subMenuLinkDiv">
                          <span className="subMenuLinkName">
                            {this.state.userLangs?.invoice?.INVOICE_LIST}
                          </span>
                        </div>
                      </Link>
                      <Link to="/tripBills">
                        <div className="subMenuLinkDiv">
                          <span className="subMenuLinkName">
                            {this.state.userLangs?.invoice?.ADD_BILL}
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                ))}

              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label === "billing_team_member" ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${this.props.activeState === MAIN_INVOICE
                        ? "activeLinkSection"
                        : null
                        }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_INVOICE);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <div>
                        <img src={billingImage} alt="" />
                        <span className="mainLinkName">
                          {this.state.userLangs?.dashboard?.INVOICE}
                        </span>
                        {this.props.activeState === MAIN_INVOICE &&
                          this.state.show === true ? (
                          // <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                          <i style={keyboardArrowIconStyle}>
                            <KeyboardArrowUpIcon />
                          </i>
                        ) : (
                          // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                          <i style={keyboardArrowIconStyle}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                      </div>
                    </div>
                    <div
                      className={`subMenu ${this.props.activeState === MAIN_INVOICE &&
                        this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                        }`}
                    >
                      <Link to="/BillingIndex">
                        <div className="subMenuLinkDiv">
                          <span className="subMenuLinkName">
                            {this.state.userLangs?.invoice?.AGREEMENT}
                          </span>
                        </div>
                      </Link>
                      <Link to="/Billing/InvoiceList">
                        <div className="subMenuLinkDiv">
                          <span className="subMenuLinkName">
                            {this.state.userLangs?.invoice?.INVOICE_LIST}
                          </span>
                        </div>
                      </Link>
                      <Link to="/tripBills">
                        <div className="subMenuLinkDiv">
                          <span className="subMenuLinkName">
                            {this.state.userLangs?.invoice?.ADD_BILL}
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                ))}

              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label === "loading_unloading" ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${this.props.activeState === MAIN_INVOICE
                        ? "activeLinkSection"
                        : null
                        }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_INVOICE);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <div>
                        <img src={billingImage} alt="" />
                        <span className="mainLinkName">
                          {this.state.userLangs?.dashboard?.INVOICE}
                        </span>
                        {this.props.activeState === MAIN_INVOICE &&
                          this.state.show === true ? (
                          // <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                          <i style={keyboardArrowIconStyle}>
                            <KeyboardArrowUpIcon />
                          </i>
                        ) : (
                          // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                          <i style={keyboardArrowIconStyle}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                      </div>
                    </div>
                    <div
                      className={`subMenu ${this.props.activeState === MAIN_INVOICE &&
                        this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                        }`}
                    >
                      <Link to="/BillingIndex">
                        <div className="subMenuLinkDiv">
                          <span className="subMenuLinkName">
                            {this.state.userLangs?.invoice?.AGREEMENT}
                          </span>
                        </div>
                      </Link>
                      <Link to="/tripBills">
                        <div className="subMenuLinkDiv">
                          <span className="subMenuLinkName">
                            {this.state.userLangs?.invoice?.ADD_BILL}
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                ))}

              {/* INVOICE */}
              {/* <InvoiceMenu
                userRole={userInfo?.data?.role?.label}
                handleActiveLink={this.handleActiveLink}
                show={this.state.show}
                keyboardArrowIconStyle={keyboardArrowIconStyle}
              /> */}

              {/* REPORTS */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label !== "transporter" ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${this.props.activeState === MAIN_REPORTS
                        ? "activeLinkSection"
                        : null
                        }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_REPORTS);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFilterItemFromLocalStorageTrip();
                        this.removeFromLocalStorage("invoiceListFilters");
                        this.removeFromLocalStorage("filters");
                        this.removeFromLocalStorage("IndentFilters");
                      }}
                    >
                      <div>
                        <img src={reportsImage} alt="" />
                        <span className="mainLinkName">
                          {" "}
                          {this.state.userLangs?.report?.REPORTS}
                        </span>
                        {this.state.mainLink === MAIN_REPORTS &&
                          this.state.show === true ? (
                          // <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                          <i style={keyboardArrowIconStyle}>
                            <KeyboardArrowUpIcon />
                          </i>
                        ) : (
                          // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                          <i style={keyboardArrowIconStyle}>
                            <KeyboardArrowDownIcon />
                          </i>
                        )}
                      </div>
                    </div>
                    <div
                      className={`subMenuReport ${this.props.activeState === MAIN_REPORTS &&
                        this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                        }`}
                    >
                      <Link to="/Reports">
                        <div className="subMenuLinkDiv">
                          <div className="subMenuLinkName">
                            <span className="subMenuLinkName">
                              {
                                this.state.userLangs?.report
                                  ?.TRANSPORTER_PERFORMANCE
                              }
                            </span>
                          </div>
                        </div>
                      </Link>
                      <div></div>
                      <Link to="/indentReport-analysis">
                        <div className="subMenuLinkDiv">
                          <div className="subMenuLinkName">
                            <span className="subMenuLinkName">
                              {" "}
                              {
                                this.state.userLangs?.report
                                  ?.INDENT_ANALYSIS_REPORT
                              }
                            </span>
                          </div>
                        </div>
                      </Link>
                      <Link to="/vehicle-placement">
                        <div className="subMenuLinkDiv">
                          <div className="subMenuLinkName">
                            <span className="subMenuLinkName">
                              {this.state.userLangs?.report
                                ?.VEHICLE_PLACEMENT_REPORT
                                ? this.state.userLangs?.report
                                  ?.VEHICLE_PLACEMENT_REPORT
                                : "Vehicle Placement Report"}
                            </span>
                          </div>
                        </div>
                      </Link>

                      <Link to="/vehicle-placement-detail">
                        <div className="subMenuLinkDiv">
                          <div className="subMenuLinkName">
                            <span className="subMenuLinkName">
                              {this.state.userLangs?.report
                                ?.VEHICLE_ANALYSIS_REPORT
                                ? this.state.userLangs?.report
                                  ?.VEHICLE_ANALYSIS_REPORT
                                : "Vehicle  Analysis Report"}
                            </span>
                          </div>
                        </div>
                      </Link>
                      <Link to="/ontime-delivery-report">
                        <div className="subMenuLinkDiv">
                          <div className="subMenuLinkName">
                            <span className="subMenuLinkName">
                              {this.state.userLangs?.report
                                ?.ON_TIME_DELIVERY_REPORT1 ??
                                "On Time Delivery Report  "}
                            </span>
                          </div>
                        </div>
                      </Link>
                      <Link to="/share-of-business">
                        <div className="subMenuLinkDiv">
                          <div className="subMenuLinkName">
                            <span className="subMenuLinkName">
                              Share Of Business
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                ))}

              {/* BAXTER REPORTS */}
              {process.env.REACT_APP_CLIENT === "3_BAXTER" &&
                userType !== "customer" &&
                userType !== "sales" ? (
                <div className="linkSection">
                  <div
                    className={`mainLink ${this.props.activeState === MAIN_REPORTS
                      ? "activeLinkSection"
                      : null
                      }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_REPORTS);
                      this.removeFilterItemFromLocalStorageTrip();
                    }}
                  >
                    <div>
                      <img src={reportsImage} alt="" />
                      <span className="mainLinkName">
                        {" "}
                        {this.state.userLangs?.report?.REPORTS}
                      </span>
                      {this.state.mainLink === MAIN_REPORTS &&
                        this.state.show === true ? (
                        // <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} />
                        <i style={keyboardArrowIconStyle}>
                          <KeyboardArrowUpIcon />
                        </i>
                      ) : (
                        // <KeyboardArrowDownIcon sx={keyboardArrowIconStyle} />
                        <i style={keyboardArrowIconStyle}>
                          <KeyboardArrowDownIcon />
                        </i>
                      )}
                    </div>
                  </div>
                  <div
                    className={`subMenuReport ${this.props.activeState === MAIN_REPORTS &&
                      this.state.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                      }`}
                  >
                    {" "}
                    <Link to="/logbook">
                      <div className="subMenuLinkDiv">
                        <div
                          className="subMenuLinkName"
                          onClick={() => {
                            this.removeFilterItemExceptCurrentRoute();
                          }}
                        >
                          <span className="subMenuLinkName">
                            {this.state.userLangs?.report?.LOGBOOK ?? "Logbook"}
                          </span>
                        </div>
                      </div>
                    </Link>
                    <Link to="/DispatchLeadReports">
                      <div className="subMenuLinkDiv">
                        <div
                          className="subMenuLinkName"
                          onClick={() => {
                            this.removeFilterItemExceptCurrentRoute();
                          }}
                        >
                          <span className="subMenuLinkName">
                            {this.state.userLangs?.report?.DISPATCH_LEAD_TIME ??
                              "Dispatch Lead Time"}
                          </span>
                        </div>
                      </div>
                    </Link>
                    <Link to="/BifuricationVolumeReports">
                      <div className="subMenuLinkDiv">
                        <div
                          className="subMenuLinkName"
                          onClick={() => {
                            this.removeFilterItemExceptCurrentRoute();
                          }}
                        >
                          <span className="subMenuLinkName">
                            {this.state.userLangs?.report
                              ?.BIFURICATION_VOLUME_REPORT ??
                              "Bifurication Volume Report"}
                          </span>
                        </div>
                      </div>
                    </Link>
                    <Link to="/BifuricationRevenueReports">
                      <div
                        className="subMenuLinkDiv"
                        onClick={() => {
                          this.removeFilterItemExceptCurrentRoute();
                        }}
                      >
                        <div className="subMenuLinkName">
                          <span className="subMenuLinkName">
                            {this.state.userLangs?.report
                              ?.BIFURICATION_VOLUME_REPORT ??
                              "Bifurication Revenue Report"}
                          </span>
                        </div>
                      </div>
                    </Link>
                    <Link to="/TruckUtilizationReports">
                      <div className="subMenuLinkDiv">
                        <div className="subMenuLinkName">
                          <span className="subMenuLinkName">
                            {this.state.userLangs?.report
                              ?.TRUCK_UTILIZATION_REPORT ??
                              "Truck Utilization Report"}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* Control Tower */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label !== "transporter" ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${this.props.activeState === MAIN_CONTROL_TOWER &&
                        this.props.location.pathname === "/ControlTower"
                        ? "activeLinkSection"
                        : null
                        }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_CONTROL_TOWER);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFilterItemFromLocalStorageTrip();
                        this.removeFromLocalStorage("invoiceListFilters");
                        this.removeFromLocalStorage("filters");
                        this.removeFromLocalStorage("IndentFilters");
                      }}
                    >
                      <Link to="/ControlTower">
                        <div>
                          <img src={ControlTowerImage} alt="controlImg" />
                          <span className="mainLinkName">
                            {" "}
                            {this.state.userLangs?.controlTower?.CONTROL_TOWER}
                          </span>
                          {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                        </div>
                      </Link>
                    </div>
                    <div
                      className={`subLinks ${this.state.a === 1 ? "displaySubMenu" : "hideSubMenu"
                        }`}
                    ></div>
                  </div>
                ) : (
                  ""
                ))}
              {/* HELP */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" && (
                <div className="helpMenuStyle " onClick={this.onResumePdfClick}>
                  <div className="mainLinkHelp">
                    {/* <img src={ControlTowerImage} alt="controlImg" /> */}
                    <a
                      href={AtFoodPdf}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="helpName">
                        {this.state.userLangs?.common?.HELP}
                      </span>
                    </a>
                  </div>
                </div>
              )}
            </div>

            <div></div>
          </div>
        ) : (
          <div
            id="mySidebar"
            className={`mainExpandedStyles ${darkMode ? "verticalMenuDark" : ""
              }`}
            onMouseEnter={this.props.menuBarIn}
            onMouseLeave={this.props.menuBarOut}
          >
            <div>
              <img className="mainLogoStyleMini" src={logoMiniImage} alt="" />
            </div>
            <div className="logoSepartorStyle"></div>
            <div className="linksSection">
              {/* DASHBOARD */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label !== "transporter" ||
                  userInfo?.data?.userType === "internal" ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${this.props.activeState === MAIN_DASHBOARD &&
                        (this.props.location.pathname ===
                          "/operationsDashboard" ||
                          this.props.location.pathname ===
                          "/transporterDashboard" ||
                          this.props.location.pathname === "/invoice-dashboard")
                        ? "activeLinkSection"
                        : null
                        }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_DASHBOARD);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFilterItemFromLocalStorageTrip();
                        this.removeFilterItemExceptCurrentRoute();
                      }}
                    >
                      <Link to="/dashboard">
                        <div>
                          <img src={dashboardImage} alt="" />
                          <span className="mainLinkName"></span>
                          {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                ))}
              {/* BAXTER DASHBOARD */}
              {process.env.REACT_APP_CLIENT === "3_BAXTER" &&
                ["admin", "supervisor"].includes(userType) &&
                (userInfo ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${this.props.activeState === MAIN_DASHBOARD &&
                        this.props.location.pathname === "/operationalDashboard"
                        ? "activeLinkSection"
                        : null
                        }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_DASHBOARD);
                        this.removeFilterItemExceptCurrentRoute();
                        // this.removeFilterItemFromLocalStorageIndent();
                        // this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <Link to="/operationalDashboard">
                        <div>
                          <img src={dashboardImage} alt="" />
                          <span className="mainLinkName"></span>
                          {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                ))}

              {/* INDENT PLANNING */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label !== "transporter" ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${this.props.activeState === MAIN_INDENT_PLANNING ||
                        this.props.location.pathname === "/indent-planning"
                        ? "activeLinkSection"
                        : null
                        }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_INDENT_PLANNING);
                        this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <div>
                        <img src={planningImage} alt="" />
                        <span className="mainLinkName"></span>
                      </div>
                    </div>
                    <div
                      className={`subMenu ${this.props.activeState === MAIN_INDENT_PLANNING &&
                        this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                        }`}
                    // onClick={() => this.setState({mainLink:MAIN_INDENT_PLANNING})}
                    >
                      <Link to="/indent-planning">
                        <div>
                          <span className="subMenuLinkName"></span>
                        </div>
                      </Link>
                      <Link to="/add-indent">
                        <div>
                          <span className="subMenuLinkName"></span>
                        </div>
                      </Link>
                      {routeOptimization ? (
                        <Link to="/Route-Optimization">
                          <div>
                            <span className="subMenuLinkName"></span>
                          </div>
                        </Link>
                      ) : (
                        <></>
                      )}
                      {loadOptimization ? (
                        <Link to="/load-optimization">
                          <div>
                            <span className="subMenuLinkName"></span>
                          </div>
                        </Link>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${this.props.activeState === MAIN_INDENT_PLANNING ||
                        this.props.location.pathname === "/assigned-indent"
                        ? "activeLinkSection"
                        : null
                        }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_INDENT_PLANNING);
                        this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <div>
                        <img src={planningImage} alt="" />
                        <span className="mainLinkName"></span>
                      </div>
                    </div>
                    <div
                      className={`subMenu ${this.props.activeState === MAIN_INDENT_PLANNING &&
                        this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                        }`}
                    // onClick={() => this.setState({mainLink:MAIN_INDENT_PLANNING})}
                    >
                      <Link to="/indent-planning">
                        <div>
                          <span className="subMenuLinkName"></span>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              {process.env.REACT_APP_CLIENT === "3_BAXTER" && (
                <>
                  {/* ORDERS */}
                  <div className="linkSection">
                    <div
                      className={`mainLink ${this.props.activeState === MAIN_ORDERS
                        ? "activeLinkSection"
                        : null
                        }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_ORDERS);
                        // this.removeFilterItemFromLocalStorageIndent();
                      }}
                    >
                      <div>
                        <svg
                          width="29"
                          height="28"
                          viewBox="0 0 29 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.00201 2.33333L3.50201 7V23.3333C3.50201 23.9522 3.74785 24.5457 4.18543 24.9832C4.62302 25.4208 5.21651 25.6667 5.83535 25.6667H22.1687C22.7875 25.6667 23.381 25.4208 23.8186 24.9832C24.2562 24.5457 24.502 23.9522 24.502 23.3333V7L21.002 2.33333H7.00201Z"
                            stroke="white"
                            strokeWidth="2.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3.50201 7H24.502"
                            stroke="white"
                            strokeWidth="2.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.6687 11.6667C18.6687 12.9043 18.177 14.0913 17.3018 14.9665C16.4267 15.8417 15.2397 16.3333 14.002 16.3333C12.7643 16.3333 11.5773 15.8417 10.7022 14.9665C9.82699 14.0913 9.33533 12.9043 9.33533 11.6667"
                            stroke="white"
                            strokeWidth="2.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        <span className="mainLinkName"></span>
                      </div>
                    </div>
                    <div
                      className={`subMenu ${this.props.activeState === MAIN_ORDERS &&
                        this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                        }`}
                    >
                      <Link to="/ordersList">
                        <div>
                          <span className="subMenuLinkName"></span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </>
              )}
              {/* TRIPS */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" && (
                <div className="linkSection">
                  <div
                    className={`mainLink ${this.props.activeState === MAIN_TRIPS
                      ? "activeLinkSection"
                      : null
                      }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_TRIPS);
                      this.removeFilterItemFromLocalStorageIndent();
                    }}
                  >
                    <div>
                      <img src={tripsImage} alt="" />
                      <span className="mainLinkName"></span>
                    </div>
                  </div>
                  <div
                    className={`subMenu ${this.props.activeState === MAIN_TRIPS &&
                      this.state.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                      }`}
                  >
                    <Link to="/trips">
                      <div>
                        <span className="subMenuLinkName"></span>
                      </div>
                    </Link>
                  </div>
                </div>
              )}
              {/*BAXTER TRIPS */}
              {process.env.REACT_APP_CLIENT === "3_BAXTER" &&
                userType !== "sales" &&
                userType !== "customer" && (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${this.props.activeState === MAIN_TRIPS
                        ? "activeLinkSection"
                        : null
                        }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_TRIPS);
                        this.removeFilterItemFromLocalStorageIndent();
                      }}
                    >
                      <div>
                        <img src={tripsImage} alt="" />
                        <span className="mainLinkName"></span>
                      </div>
                    </div>
                    <div
                      className={`subMenu ${this.props.activeState === MAIN_TRIPS &&
                        this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                        }`}
                    >
                      <Link to="/tripList">
                        <div>
                          <span className="subMenuLinkName"></span>
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
              {/* MASTERS */}
              {userType !== "customer" && userType !== "sales" && (
                <div className="linkSection">
                  <div
                    className={`mainLink ${this.props.activeState === MAIN_MASTERS
                      ? "activeLinkSection"
                      : null
                      }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_MASTERS);
                      this.removeFilterItemFromLocalStorageIndent();
                      this.removeFilterItemFromLocalStorageTrip();
                    }}
                  >
                    <div>
                      <img src={mastersImage} alt="" />
                      <span className="mainLinkName"></span>
                    </div>
                  </div>
                  <div
                    className={`subMenu ${this.props.activeState === MAIN_MASTERS &&
                      this.state.show === true
                      ? "displaySubMenu"
                      : "hideSubMenu"
                      }`}
                  >
                    <Link to="/drivers">
                      <div onClick={() => this.handleActiveSubLink(DRIVERS)}>
                        <span className="subMenuLinkName"></span>
                      </div>
                    </Link>
                    <Link to="/vehicles">
                      <div>
                        <span className="subMenuLinkName"></span>
                      </div>
                    </Link>
                    {userInfo?.data?.role?.label === "Admin" ? (
                      <Link to="/vehicle-type">
                        <div>
                          <span className="subMenuLinkName"></span>
                        </div>
                      </Link>
                    ) : (
                      ""
                    )}

                    <Link to="/user">
                      <div>
                        <span className="subMenuLinkName"></span>
                      </div>
                    </Link>
                    <Link to="/roles">
                      <div>
                        <span className="subMenuLinkName"></span>
                      </div>
                    </Link>
                    <Link to="/routes">
                      <div>
                        <span className="subMenuLinkName"></span>
                      </div>
                    </Link>
                    <Link to="/routes">
                      <div>
                        <span className="subMenuLinkName"></span>
                      </div>
                    </Link>
                  </div>
                </div>
              )}
              {/* INVOICE */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label === "indent_planner" ? (
                  <></>
                ) : (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${this.props.activeState === MAIN_INVOICE
                        ? "activeLinkSection"
                        : null
                        }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_INVOICE);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <div>
                        <img src={billingImage} alt="" />
                        <span className="mainLinkName"></span>
                      </div>
                    </div>
                  </div>
                ))}

              {/* REPORTS */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label !== "transporter" ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${this.props.activeState === MAIN_REPORTS
                        ? "activeLinkSection"
                        : null
                        }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_REPORTS);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <div>
                        <img src={reportsImage} alt="" />
                        <span className="mainLinkName"></span>
                      </div>
                    </div>
                    <div
                      className={`subMenuReport ${this.props.activeState === MAIN_REPORTS &&
                        this.state.show === true
                        ? "displaySubMenu"
                        : "hideSubMenu"
                        }`}
                    >
                      <Link to="/Reports">
                        <div>
                          <span className="subMenuLinkName"></span>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                ))}

              {/* BAXTER REPORTS */}
              {process.env.REACT_APP_CLIENT === "3_BAXTER" &&
                userType !== "customer" &&
                userType !== "sales" ? (
                <div className="linkSection">
                  <div
                    className={`mainLink ${this.props.activeState === MAIN_REPORTS
                      ? "activeLinkSection"
                      : null
                      }`}
                    onClick={() => {
                      this.handleActiveLink(MAIN_REPORTS);
                    }}
                  >
                    <div>
                      <img src={reportsImage} alt="" />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* CONTROL_TOWER */}
              {process.env.REACT_APP_CLIENT !== "3_BAXTER" &&
                (userInfo?.data?.role?.label !== "transporter" ? (
                  <div className="linkSection">
                    <div
                      className={`mainLink ${this.props.activeState === MAIN_CONTROL_TOWER &&
                        this.props.location.pathname === "/ControlTower"
                        ? "activeLinkSection"
                        : null
                        }`}
                      onClick={() => {
                        this.handleActiveLink(MAIN_CONTROL_TOWER);
                        this.removeFilterItemFromLocalStorageIndent();
                        this.removeFilterItemFromLocalStorageTrip();
                      }}
                    >
                      <Link to="/ControlTower">
                        <div>
                          <img src={ControlTowerImage} alt="controlImg" />
                          <span className="mainLinkName"></span>
                          {/* <KeyboardArrowUpIcon sx={keyboardArrowIconStyle} /> */}
                        </div>
                      </Link>
                    </div>
                    <div
                      className={`subLinks ${this.state.a === 1 ? "displaySubMenu" : "hideSubMenu"
                        }`}
                    ></div>
                  </div>
                ) : (
                  ""
                ))}
            </div>
            <div></div>
          </div>
        )}
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
    activeState: state.Layout.activeTheme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeActiveTheme: (name) => {
      dispatch({ type: "CHANGE_ACTIVE_THEME", payload: name });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchToProps)(withRouter(index));
