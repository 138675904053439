import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class TripStatusDountChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // series: [20,8,0,3,0,7,0,1],
      series:[],
      options: {
        labels: [],
        colors: [
          "#E19600",
          "#FF6F00",
          "#28A34C",
          "#AC1000",
          "#BC51FF",
          "#8F00FF",
          "#FF6F00",
          "#006400",
        ],
        legend: {
          show: false,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          // fontSize: '2px',
          offsetX: 0,
          offsetY: 0,
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                // height: "115%",
              },
              // legend: {
              //   show: false,
              // },
            },
          },
        ],
      },
    };
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.tripData !== this.props.tripData) {
      let tripData = this.props.tripData;
      // console.log("tripData",this.props.tripData)
      var tripInfo ={
        planned:0,
        rejected:0,
        cancelled:0,
        completed:0,
        arrivedAtVia:0,
        epodInProgress:0,
        loadingInProgress:0,
        inTransit:0,
        arrivedAtSource:0,
        shipmentVerified:0,
        qualityCheckCompleted:0
      };
      if(tripData !==  undefined)
      {tripData.map((trip)=>{
          if(trip?.subStatus === "planned"){
            tripInfo.planned = trip?.total;
          }
         else if(trip?.subStatus === "rejected"){
          tripInfo.rejected = trip?.total;
          }
          else if(trip?.subStatus === "cancelled"){
            tripInfo.cancelled = trip?.total;
          }
          else if(trip?.subStatus === "completed"){
            tripInfo.completed = trip?.total;
          }
          else if(trip?.subStatus === "arrived_at_via"){
            tripInfo.arrivedAtVia = trip?.total;
          }
          else if(trip?.subStatus === "epod_in_progress"){
            tripInfo.epodInProgress = trip?.total;
          }
          else if(trip?.subStatus === "loading_in_progress"){
            tripInfo.loadingInProgress = trip?.total;
          }
          else if(trip?.subStatus === "in_transit"){
            tripInfo.inTransit = trip?.total;  
          }
          else if(trip?.subStatus === "arrived_at_source"){
            tripInfo.arrivedAtSource = trip?.total;
          }
          else if(trip?.subStatus === "shipment_verified"){
            tripInfo.shipmentVerified = trip?.total;
          }
          else if(trip?.subStatus === "quality_check_completed"){
            tripInfo.qualityCheckCompleted = trip?.total;
          }
          return null
        })}
      let planned = tripInfo?.planned;
      let arrivedAtSource = tripInfo?.arrivedAtSource;
      let qualityCheck = tripInfo?.qualityCheckCompleted;
      let LoadingProgress = tripInfo?.loadingInProgress;
      let shipmentVerified = tripInfo?.shipmentVerified;
      let inTransit = tripInfo?.inTransit;
      let arrivedAtVia = tripInfo?.arrivedAtVia;
      let completed = tripInfo?.completed;
      let Series = [
        planned,
        arrivedAtSource, 
        qualityCheck, 
        LoadingProgress, 
        shipmentVerified, 
        inTransit,
        arrivedAtVia,
        completed
      ];
      let Label = [
        // "Planned",
        this.props?.userLang?.dashboard?.PLANNED,
        // "Arrived at source",
        this.props?.userLang?.dashboard?.ARRIVED_AT_SOURCE,
        // "Quality check completed",
        this.props?.userLang?.dashboard?.QUALITY_CHECK_COMPLETED,
        // "Loading in progress",
        this.props?.userLang?.dashboard?.LOADING_IN_PROGRESS,
        // "Shipment verified",
        this.props?.userLang?.dashboard?.SHIPMENT_VERIFIED,
        // "In transit",
        this.props?.userLang?.dashboard?.IN_TRANSIT,
        // "Arrived at Via point",
        this.props?.userLang?.dashboard?.ARRIVED_AT_VIA_POINT,
        // "Completed",
        this.props?.userLang?.dashboard?.COMPLETED,

      ];
      this.setState({ options: { ...this.state.options, labels: Label } });
      this.setState({ series: Series });
    }
  };

  render() {
    // console.log("series trip", this.state.series)
    return (
      <React.Fragment>
        <ReactApexChart className="cursor-pointer TripApexChartParent"
      
          options={this.state.options}
          series={this.state.series}
          type="donut"
          height="290"
        />
      </React.Fragment>
    );
  }
}

export default TripStatusDountChart;
