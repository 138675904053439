import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import DealersReducer from './reducer';
const Dealer = combineReducers({
  dealers: DealersReducer,
  form: formReducer
});


export default Dealer;
