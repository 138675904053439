import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { types } from "./actionTypes";
//medthods
import {
  deleteApi,
  getApi,
  patchApi,
  postApi,
  uploadExcel,
  putApi
} from "../../helpers/auth_helper";

// workers
function* workerGetCustomers({ filters, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 10;
  let searchFilters = ''
  for(let key in filters){
    if(!['offset','limit'].includes(key))
    searchFilters += `&${key}=${filters[key]}`   
  }
  try {
    const url =
    `${process.env.REACT_APP_SERVERURL}/customer/customers?offset=${offset}&limit=${limit}${searchFilters}` ;
    const response = yield call(getApi, url);
    callback(response);
    localStorage.setItem("CustomersData", response ? JSON.stringify(response):null);
    yield put({
      type: types.GET_CUSTOMERS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}

function* workerGetCustomersExcel({ filters, callback }) {
  let offset = filters?.offset ? filters?.offset : 0;
  let limit = filters?.limit ? filters?.limit : 100000;
  let searchFilters = ''
  for(let key in filters){
    if(!['offset','limit'].includes(key))
    searchFilters += `&${key}=${filters[key]}`   
  }
  try {
    const url =
    `${process.env.REACT_APP_SERVERURL}/customer/customers?offset=${offset}&limit=${limit}${searchFilters}` ;
    const response = yield call(getApi, url);
    callback(response);
    // localStorage.setItem("CustomersData", response ? JSON.stringify(response):null);
    yield put({
      type: types.GET_CUSTOMERS_SUCCESS_EXCEL,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}


function* workerCreateCustomer({ payload, callback }) {
  try {
    const url = process.env.REACT_APP_SERVERURL + "/customer";
    const response = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CREATE_CUSTOMER_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerCreateCustomerViaExcel({ payload, callback }) {
  try {
    const url = process.env.REACT_APP_SERVERURL + "/customer/addViaExcel";
    const response = yield call(postApi, url, payload);
    callback(response);
    yield put({
      type: types.CREATE_CUSTOMER_VIA_EXCEL_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerUpdateCustomer({ payload, callback }) {
  try {
    const url = process.env.REACT_APP_SERVERURL + "/customer/update";
    const response = yield call(putApi, url, payload);
    callback(response);
    yield put({
      type: types.UPDATE_CUSTOMER_SUCCESS,
      payload: response,
    });
  } catch (e) {
    console.log(e);
  }
}
function* workerDeleteCustomer({ payload, callback }) {
    try {
      const url = process.env.REACT_APP_SERVERURL + "/customer/delete";
      const response = yield call(deleteApi, url, payload);
      callback(response);
      yield put({
        type: types.DELETE_CUSTOMER_SUCCESS,
        payload: response,
      });
    } catch (e) {
      console.log(e);
    }
  }

// watchers
export function* watchGetCustomers() {
  yield takeEvery(types.GET_CUSTOMERS, workerGetCustomers);
}
export function* watchGetCustomersExcel() {
  yield takeEvery(types.GET_CUSTOMERS_EXCEL, workerGetCustomersExcel);
}
export function* watchCreateCustomer() {
  yield takeEvery(types.CREATE_CUSTOMER, workerCreateCustomer);
}
export function* watchCreateCustomerViaExcel() {
  yield takeEvery(types.CREATE_CUSTOMER_VIA_EXCEL, workerCreateCustomerViaExcel);
}
export function* watchUpdateCustomer() {
  yield takeEvery(types.UPDATE_CUSTOMER, workerUpdateCustomer);
}
export function* watchDeleteCustomer() {
    yield takeEvery(types.DELETE_CUSTOMER, workerDeleteCustomer);
  }

function* customerSaga() {
  yield all([fork(watchGetCustomers)]);
  yield all([fork(watchGetCustomersExcel)]);
  yield all([fork(watchCreateCustomer)]);
  yield all([fork(watchCreateCustomerViaExcel)]);
  yield all([fork(watchUpdateCustomer)]);
  yield all([fork(watchDeleteCustomer)]);
}
export default customerSaga;
